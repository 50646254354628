import React from "react";

// Customizable Area Start
import {  
  Dialog,
  Typography,
  Grid,
  Box,
} from '@material-ui/core';
import ButtonDisplay from "./ButtonDisplay";

// Customizable Area End

export default class ViewDetailsDialog extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Dialog maxWidth="xl" style={newStyles.dialog} open={this.props.open}>
          <Typography style={newStyles.modalHeading} variant='h5'>{this.props.heading}</Typography>
            <Grid container>
              {this.props.data.map((item: any, index: number) => {
                return <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>{item.displayName}</Typography>
                      <Typography style={newStyles.textFieldBox}>{item.displayData}</Typography>
                  </Box>
                </Grid>
              })}
            </Grid> 
            <Box>
            {this.props.buttonsInfo.map((item: any, index: number) => {
              return <ButtonDisplay
                name={item.name}
                disabled={item.disabled}
                variant={item.variant}
                onClickHandle={item.onClickHandle}
                styling={item.styling}
              />
            })}
          </Box>   
        </Dialog>
    )

  }
}

// Customizable Area Start
const newStyles = {
    paginationStyle:{
      marginLeft: '1%',
    },
    menuListContainer: {
      position: 'relative' as 'relative',
      backgroundColor: '#FFFFFF',
      height: '86px',
      width: '96vw',
      borderRadius: '15px',
      margin: 'auto',
      marginTop: '35px',
      display: 'flex',
      boxSizing: 'border-box' as 'border-box',
  },
  menuListItems: {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      paddingTop: '30px',
      lineSpacing: '19',
      letterSpacing: '-0.33',
      marginRight: '30px',
      justifyContent: 'space-between'
  },
  dropDown: {
      minWidth: '328px',
      maxHeight: '56px',
      marginLeft: '20px',
      marginTop: '15px',
      backgroundColor: '#F2F2EC',
      fontSize: '16',
      margin: 'dense',
      fontFamily: 'Plus Jakarta Display',
      fontWeight: 400,
  },
  dropDownItems: {
      lineSpacing: '19',
      letterSpacing: '-0.33',
      fontSize: '16',
      fontFamily: 'Plus Jakarta Display',
      fontWeight: 400,
  },
  divider: {
      width: '1px',
      height: '30px',
      marginLeft: '70px',
      marginTop: '28px',
  },
  tableContainer: {
      //height: '300px',
      position: 'relative' as 'relative',
      backgroundColor: '#FFFFFF',
      //height: '443px',
      width: '96vw',
      borderRadius: '15px',
      margin: 'auto',
      marginBottom: '50px',
      marginTop: '25px',
      paddingTop: '50px',
      paddingBottom: '50px',
      paddingLeft: '20px',
      paddingRight: '20px',
      boxSizing: 'border-box' as 'border-box',
  },
  tableContainerSkeleton: {
      //height: '300px',
      position: 'relative' as 'relative',
      backgroundColor: 'grey.900',
      //height: '443px',
      width: '96vw',
      borderRadius: '15px',
      margin: 'auto',
      marginBottom: '50px',
      marginTop: '25px',
      paddingTop: '50px',
      paddingBottom: '50px',
      paddingLeft: '20px',
      paddingRight: '20px',
      boxSizing: 'border-box' as 'border-box',
  },
  tableHeaderRows: {
      lineSpacing: '17',
      letterSpacing: '-0.29',
      fontSize: '14',
      fontFamily: 'Plus Jakarta Display',
      fontWeight: 400,
  },
  tableBodyRows: {
      lineSpacing: '19',
      letterSpacing: '0',
      fontSize: '16',
      fontFamily: 'Plus Jakarta Display',
      fontWeight: 400,
  },
  icons: {
      fontSize: 'medium',
      paddingRight: '10px',
      cursor: 'pointer',
  },
  iconColumn: {
      whiteSpace: 'nowrap' as 'nowrap',
  },
  button: {
      //position: 'absolute',
      position: 'relative' as 'relative',
      width: '328px',
      height: '56px',
      // marginLeft: '556px',
      // marginTop: '645px',
      marginLeft: '81vw',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      // fontStyle: 'normal',
      // fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  createButton1: {
      marginLeft: '10px',
      //justifyContent: 'flexEnd',
      position: 'relative' as 'relative',
      width: '289px',
      height: '56px',
      // marginLeft: 'auto',
      // marginRight: '0px',
      //float: 'right' as 'right',
      marginRight: '2%',
      marginBottom: '20px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      //justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      //padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      //textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  createButton: {
      marginLeft: 'auto',
      //justifyContent: 'flexEnd',
      position: 'relative' as 'relative',
      width: '289px',
      height: '56px',
      // marginLeft: 'auto',
      // marginRight: '0px',
      //float: 'right' as 'right',
      marginRight: '2%',
      marginBottom: '20px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      //justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      //padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      //textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',
      //justifySelf: 'flexEnd',
      //alignContent: 'flexEnd',
  },
  createButtonSkeleton: {
      marginLeft: 'auto',
      position: 'relative' as 'relative',
      width: '289px',
      height: '56px',
      marginRight: '2%',
      marginBottom: '20px',
      opacity: '1',
      backgroundColor: 'grey.900',
      borderRadius: '10px',
      boxSizing: 'border-box' as 'border-box',
      color: '#FFFFFF',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',
  },
  // createPOButton: {
  //     marginLeft: 'auto',
  //     position: 'relative' as 'relative',
  //     width: '289px',
  //     height: '56px',
  //     // marginRight: 'auto',
  //     marginBottom: '20px',
  //     opacity: '1',
  //     backgroundColor: 'rgba(255, 255, 0, 1)',
  //     borderRadius: '10px',
  //     boxSizing: 'border-box' as 'border-box',
  //     color: 'rgba(28, 28, 26, 1)',
  //     textAlignVertical: 'top',
  //     fontFamily: 'Plus Jakarta Display',
  //     fontSize: '18',	
  // },
  createButtonIcon: {
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      marginRight: '70px',
      boxSizing: 'border-box' as 'border-box',
  },
  fileAttachmentButtonIcon: {
      //color: 'rgba(28, 28, 26, 1)',
      //textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      color: '#FD6533',
      textAlign: 'center' as 'center',
      fontWeight: 600,
      //marginRight: '70px',
      boxSizing: 'border-box' as 'border-box',
  },
  createLongTextButtonIcon: {
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      marginRight: '20px',
      boxSizing: 'border-box' as 'border-box',
  },
  modalButton: {
      position: 'relative' as 'relative',
      width: '160px',
      height: '56px',
      margin: '20px',
      // marginLeft: '556px',
      // marginTop: '645px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      // fontStyle: 'normal',
      // fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  errorModalButton: {
      position: 'relative' as 'relative',
      //width: '160px',
      width: '100px',
      //height: '56px',
      height: '45px',
      //margin: '20px',
      margin: 'auto 0px',
      marginTop: '15px',
      marginBottom: '15px',
      // marginLeft: '556px',
      // marginTop: '645px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      // fontStyle: 'normal',
      // fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  modalCancelButton: {
      position: 'relative' as 'relative',
      width: '160px',
      height: '56px',
      margin: '20px',
      // marginLeft: '556px',
      // marginTop: '645px',
      opacity: '1',
      //backgroundColor: 'rgba(255, 255, 0, 1)',
      backgroundColor: '#FAEBE6',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      // fontStyle: 'normal',
      // fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: '#FD6533',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  attachmentButton: {
      width: '210px',
      height: '56px',
      opacity: '1',
      backgroundColor: '#FAEBE6',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
      includeFontPadding: false,
      padding: '0',
      color: '#FD6533',
      textAlign: 'center' as 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
  },
  buttonText: {
      position: 'absolute',
      width: '104',
      height: '16',
      marginLeft: '16',
      marginTop: '14',
      opacity: '1',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center',
      textAlignVertical: 'top',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      boxSizing: 'border-box' as 'border-box',	
  },
  dialog: {
      margin: '0 auto',
      //height: '431px',
      //width: '1360px',
      boxSizing: 'border-box' as 'border-box',	
  },
  errorDialog: {
      padding: '100px',
      width: '1200px',
      marginTop: '100px',
      marginBottom: '100px',
  },
  errorBox:{
      padding: '0 30px 0 30px',
      marginTop: '30px',
      //marginBottom: '30px',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      
  },
  textFieldBox: {
      //padding: '20px',
      //zIndex: 4,
      //marginTop: '30px',
      backgroundColor: '#FFFFFF', 
      width: '400px',
      // minHeight: '40px',
      minHeight: '60px',
      borderRadius: '10px',
      //paddingBottom: '10px',
      //paddingTop: '10px',
      //margin: '0 auto',
      position: 'relative' as 'relative',
      //marginLeft: '0px',
      //marginTop: '16px',
      marginBottom: '10px',
      opacity: '1',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
    },
    textFieldBoxSmall: {
      cursor: 'pointer',
      backgroundColor: '#FFFFFF', 
      width: '182px',
      minHeight: '60px',
      borderRadius: '10px',
      position: 'relative' as 'relative',
      marginBottom: '10px',
      opacity: '1',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
    },
    DateBox: {
      //padding: '20px',
      marginTop: '15px',
      backgroundColor: '#FFFFFF', 
      width: '400px',
      // minHeight: '40px',
      minHeight: '60px',
      borderRadius: '10px',
      paddingBottom: '10px',
      //paddingTop: '10px',
      margin: '0 auto',
      position: 'relative' as 'relative',
      //marginLeft: '0px',
      //marginTop: '16px',
      opacity: '1',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
    },
    modalHeading: {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '24',
      fontWeight: 600,
      padding: '20px',
      marginBottom: '20px',
      boxSizing: 'border-box' as 'border-box',
    },
    gridItem: {
        paddingLeft: '20px',
        //paddingRight: '20px',
        paddingBottom: '20px',
    },
    gridItemLabel: {
      marginBottom: '10px',
      fontFamily: 'Plus Jakarta Display',
    },
    selectBox: {
      //backgroundColor: '#F2F2EC', 
      width: '400px', //added
      minHeight: '56px', 
      borderRadius: '10px',
      border: '#B1B1AE 0.005px solid',
      marginBottom: '10px',
      //paddingLeft: '7px',
      boxSizing: 'border-box' as 'border-box',
    },
  //   selectBoxLabel: {
  //     fontFamily: 'Plus Jakarta Display',
  //     color: '#B1B1AE',
  //     backgroundColor: '#F2F2EC', 
  //     minHeight: '60px', 
  //     borderRadius: '10px',
  //     paddingLeft: '7px',
  //     boxSizing: 'border-box' as 'border-box',
  //   },
  textFieldAdornment: {
      color: '#B1B1AE',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 300,
  },
  selectBoxLabel: {
      color: '#B1B1AE',
      width: '400px', //added
      minHeight: '56px', 
      borderRadius: '10px',
      border: '#B1B1AE 0.005px solid',
      marginBottom: '10px',
      //paddingLeft: '7px',
      boxSizing: 'border-box' as 'border-box',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 350,
  },
  modalLabel: {
      //zIndex: 3,
      position: 'relative' as 'relative',
      // left: 0px;
      // top: 0px;
      bottom: '10px',
      //width: '101px',
      height: '15px',
      color: '#808080',
      fontFamily: "Plus Jakarta Display",
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '15px',
      // marginBottom: '10px',
  },
  validationText: {
      textAlign: 'right' as 'right',
      position: 'relative' as 'relative',
      width: '325px',
      //height: '16px',
      margin: '0 auto',
      // marginLeft: '30px',
      //justifyContent: 'flexEnd',
      opacity: '1',
      backgroundColor: 'transparent',
      padding: '0px',
      color: 'rgba(28, 28, 26, 1)',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Italic',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '12',
      fontStyle: 'italic' ,
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
  },
  termsHeadingBottom: {
      //width: '138px',
      height: '22px',
      color: '#1c1c1a',
      fontFamily: "Plus Jakarta Display",
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '-0.375px',
      marginTop: '5px',
  },
  termsHeadingTop: {
      //width: '138px',
      height: '22px',
      color: '#1c1c1a',
      fontFamily: "Plus Jakarta Display",
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '-0.375px',
      //marginTop: '0px',
  },
  termsBodyTop: {
      height: '17px',
      color: '#808080',
      fontFamily: "Plus Jakarta Display",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '-0.29166666px',
      marginTop: '15px',
      marginBottom: '20px',
  },
  termsBodyBottom: {
      height: '17px',
      color: '#808080',
      fontFamily: "Plus Jakarta Display",
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '-0.29166666px',
      marginTop: '15px',
      marginBottom: '15px',
  },
  productText: {
      //position: 'relative' as 'relative',
      marginTop: '30px',
      marginLeft: '25px',
      //top: '840px',
      width: '82px',
      height: '24px',
      color: '#1c1c1a',
      fontFamily: "Plus Jakarta Display",
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '-0.41666666px',
  }

}
// Customizable Area End
