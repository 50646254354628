import React from "react";

// Customizable Area Start
import {
  Box,
} from '@material-ui/core';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';



// Customizable Area End

interface Props {
  totalData: any[],
  dataKeyX: any,
  dataKeyY:any,
  dataKeyBlackY?: any,
  isBudgetDisplayed?: boolean,
}
interface S {
}

export default class Charts extends React.Component<Props, S> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
    
  }


  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <Box>
        <ResponsiveContainer width="100%" height={'100%'}>
          <BarChart margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }} width={730} height={550} data={this.props.totalData} barCategoryGap={10} barGap={5} barSize={35} style={newStyles.barChart}>
            <CartesianGrid strokeDasharray="1" style={newStyles.cartesianGrid} />
            <XAxis dataKey={this.props.dataKeyX} />
            <YAxis />
            <Tooltip />
            {
              this.props.isBudgetDisplayed ? <Bar dataKey={this.props.dataKeyBlackY} fill="black" style={newStyles.bar} /> : ''
            }
            <Bar dataKey={this.props.dataKeyY} fill="yellow" style={newStyles.bar} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }
}

const newStyles = {
  barChart: {
    position: "unset",
    width: "808px",
    height: "425px",
    display: "flex",
    flexDirection: "column-reverse"
  },
  legendWrapper: {
    position: "unset" as "unset",
    height: "100px",
    width: "800px",
  },
  cartesianGrid: {
    height: "100%",
  },
  bar: {
    position: 'unset' as 'unset',
    width: '798px',
    height: '100px',
  }
  /* position: absolute; */
  // width: 798px;
  // height: 100px;
  // left: 5px;
  // bottom: 5px;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}// Customizable Area Start
// Customizable Area End
