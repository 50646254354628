import React from "react";

// Customizable Area Start
import {
  Box,
  TextField,
  Input,
  Button,
  Typography,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Divider,
  Dialog,
  Chip,
  CircularProgress,
  Paper,
  Radio,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import AddSharpIcon from '@material-ui/icons/AddSharp'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Header from './Header';
import TableDisplay from "./TableDisplay";
import DateIcon from '@material-ui/icons/DateRangeOutlined';
import ToastMessageDisplay from "./ToastMessageDisplay";


const StyledTextField = withStyles({
  root: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      '& fieldset': {
        borderRadius: "10px",
      },
    },

    "& .MuiOutlinedInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "transparent",
      border: "0.05px solid black"
    },
    '&:focus': {
      backgroundColor: 'white',
      borderColor: 'black'
    },
    "& .Mui-focused": {
      border: "0.05px solid black",
      borderRadius: '10px',
      maxHeight: '57px',
    }
  }
})((props: any) => <TextField {...props} />);

const StyledSelect = withStyles({
  root: {
    paddingLeft: 15,
    '&:focus': {
      backgroundColor: 'transparent',
      fontWeight: 'bold'
    }
  },
})((props: any) => <Select {...props} />);

const StyledPagination = withStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#FFFF00',
      fontWeight: 'bold',
      color: 'black',
    },
  },
}))((props: any) => <Pagination {...props} />);
// Customizable Area End



import DashboardController, {
  Props,
} from "./DashboardController";
import ButtonDisplay from "./ButtonDisplay";
import Loading from "./Loading";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  PurchaseOrderNum = Math.floor((Math.random() * 1000000000) + 1);

  defaultSelectedMenuTabs = (RecievedselectedValue: string) => {
    if (RecievedselectedValue === "Request for Proposals") {
      return this.setState({
        dashboardMenuSelection: 'Pending RFP',
        subTabValue: 0,
      });
    } else if (RecievedselectedValue === "Purchase Order") {
      return this.setState({
        dashboardMenuSelection: 'My Purchase Orders',
        subTabValue: 0,
      });
    } else {
      return this.setState({ dashboardMenuSelection: 'My Requisitions', subTabValue: 0, })
    }
  }

  updateDashboardDisplay = (selectedValue: string, type: string): void => {
    console.log('selectedValue ', selectedValue);

    this.setState({
      dashboardRecordResults: [],
      dashboardNoRecordMessage: '',
      isDashBtnDisabled: false
    })

    if (type === "menuBar") {
      this.setState({
        dashboardMenuSelection: selectedValue
      });
    } else if (type === "dropDownFields") {
      this.setState({
        dropDownItemValue: selectedValue,
      });
      this.defaultSelectedMenuTabs(selectedValue)
    }
    this.setState({
      dashboardShowHeader: false,
      currentPage: 1,
      dashboardNoOfPages: 1
    }, () => this.handleDashboardAllRecordsRequests());

  };


  dashboardSelectedSubTabMenuReturned = () => {

    if (this.state.dropDownItemValue === 'Request for Proposals') {
      return (
        <Tabs value={this.state.subTabValue}
          data-testid={"dash_sub_tab_change"}
          onChange={this.handleSubTabValueChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            }
          }}>
          <Tab
            data-testid={"tab1_value"}
            style={newStyles.menuListItems}
            label="Pending RFPs"
            onClick={() => this.updateDashboardDisplay('Pending RFP', 'menuBar')}
            wrapped
          />
          <Tab
            data-testid={"tab2_value"}
            style={newStyles.menuListItems}
            label="Closed RFP"
            onClick={() => this.updateDashboardDisplay('Closed RFP', 'menuBar')}
            wrapped
          />
        </Tabs>
      )
    }
    else if (this.state.dropDownItemValue === 'Purchase Order') {
      return (
        <Tabs value={this.state.subTabValue}
          data-testid={"dash_sub_tab_change"}
          onChange={this.handleSubTabValueChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            }
          }}>
          <Tab
            data-testid={"tab1_value"}
            style={newStyles.menuListItems}
            label="My Purchase orders"
            onClick={() => this.updateDashboardDisplay('My Purchase Orders', 'menuBar')}
            wrapped
          />
          <Tab
            data-testid={"tab2_value"}
            style={newStyles.menuListItems}
            label="My approvals"
            onClick={() => this.updateDashboardDisplay('My Approval', 'menuBar')}
            wrapped
          />
        </Tabs>
      )
    }
    else {
      return (
        <Tabs value={this.state.subTabValue}
          data-testid={"dash_sub_tab_change"}
          onChange={this.handleSubTabValueChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            }
          }}>
          <Tab
            data-testid={"tab1_value"}
            style={newStyles.menuListItems}
            label="My Requisitions"
            onClick={() => this.updateDashboardDisplay('My Requisitions', 'menuBar')}
            wrapped
          />
          <Tab
            data-testid={"tab2_value"}
            style={newStyles.menuListItems}
            label="My Approvals"
            onClick={() => this.updateDashboardDisplay('My Approval', 'menuBar')}
            wrapped
          />
        </Tabs>
      )
    }
  }

  dashboardSelectedSubMenuCreateNewBtn = () => {
    if (this.state.dropDownItemValue === "Purchase Requisition") {
      return (
        <ButtonDisplay
          disabled={this.state.isDashBtnDisabled}
          id="pr_button"
          name="Create New"
          startIcon={AddSharpIcon}
          iconStyling={newStyles.createButtonIcon}
          variant="contained"
          onClickHandle={
            this.handleBtnOnclickDisabled.bind(this,
              'purchase requisitions',
              'to create Purchase Requisition',
              this.handleAddRequisitionOpenModal
            )}
          styling={newStyles.createButton}
        />)
    } else if (this.state.dropDownItemValue === "Purchase Order") {
      return (<ButtonDisplay
        disabled={this.state.isDashBtnDisabled}
        id="po_button"
        name="Create New"
        startIcon={AddSharpIcon}
        iconStyling={newStyles.createButtonIcon}
        variant="contained"
        onClickHandle={
          this.handleBtnOnclickDisabled.bind(this, 'purchase orders',
            'to create purchase order',
            this.handleAddPurchaseOrderOpenModal
          )}
        styling={newStyles.createButton}
      />)
    }
  }

  dashboardPurchaseOrderEditActionRendered = (statusValue: string, subTabValue: string, result: any) => {
    if (subTabValue === "My Purchase Orders" && statusValue === "draft" || statusValue === "open") {
      return <EditOutlinedIcon data-testid={"dash_po_edit_btn"} style={newStyles.icons}
        onClick={
          this.handleBtnOnclickDisabled.bind(this,
            'purchase orders',
            'to Edit Purchase Order',
            this.handleDashboardEditPurchaseOrderOpenModal,
            result
          )}
      />
    }
    else {
      return ""
    }
  }

  dashboardPurchaseOrderMyApprovalAcceptActionRendered = (statusValue: string,selectedDropdownValueForSuccess: string, subTabValueForAccept: string, data: any) => {
    if (selectedDropdownValueForSuccess === "Purchase Order" && subTabValueForAccept === "My Approval" && statusValue === 'open') {
      return <CheckCircleOutlineOutlinedIcon data-testid={"accept_po_icon"}
        onClick={
          this.handleBtnOnclickDisabled.bind(this,
            'purchase requisitions approval',
            'to Show & Approve Purchase Requisition',
            this.handleAcceptPurchaseOrderMyApprovalApiCall,
            data
          )}
        style={newStyles.icons} />
    } else {
      return ""
    }
  }

  dashboardPurchaseOrderMyApprovalRejectActionRendered = (statusValue: string, selectedDropdownValueForReject: string, subTabValueForReject: string, data: any) => {
    if (selectedDropdownValueForReject === "Purchase Order" && subTabValueForReject === "My Approval" && statusValue === 'open') {
      return <CloseOutlinedIcon data-testid={"reject_po_icon"}
        onClick={
          this.handleBtnOnclickDisabled.bind(this,
            'purchase requisitions approval',
            'to Show & Reject Purchase Requisition',
            this.handleRejectPurchaseOrderMyApprovalApiCall,
            data
          )}
        style={newStyles.icons} />
    } else {
      return ""
    }
  }

  dashboardEditRFPCurrencyValidationError = () => {
    if (this.state.showIsEditRFPValidation && this.state.editedRfpCurrency == "") {
      return this.state.editedRfpCurrencyRequiredError
    }
    else { return "" }
  }
  dashboardEditRFPCreationDateValidationError = () => {
    if (this.state.showIsEditRFPValidation && this.state.editedRfpCreationDate == "") {
      return this.state.editedRfpCreationDateRequiredError
    }
    else { return "" }
  }
  dashboardEditRFPDueDateValidationError = () => {
    if (this.state.showIsEditRFPValidation && this.state.editedRfpDueDate == "") {
      return this.state.editedRfpDueDateRequiredError
    }
    else { return "" }
  }

  editedRfpAddressIconRendered = () => {
    if (!this.state.editedRfpAddress) {
      return <AddSharpIcon />
    }
    else { return "" }
  }

  editedFileAttachmentRendered = () => {
    if (!this.state.isFileEdited) {
      return this.state.editedRfpFileAttachment?.split('/').pop().trim()
    }
    else {
      return this.state.editedRfpFileAttachment?.name
    }
  }

  editedTextDateTypeConditionCheckRendred = (selectedType: any) => {
    if (selectedType == "text") {
      return "text"
    }
    else {
      return "date"
    }
  }

  newStylesSelectBoxCondition = () => {
    if (this.state.editedRfpCurrency === "") {
      return newStyles.selectBoxLabel
    } else {
      return newStyles.selectBox
    }
  }

  purchaseRequisitionTable = () => {
    if (this.state.dropDownItemValue === 'Purchase Requisition') {
      return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>PR Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Requester</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Creation Date</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Delivery Date</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Location</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Department</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Status</TableCell>
              </TableRow>
            </TableHead>
            {this.state?.dashboardRecordResults?.length !== 0 ?
              <TableBody>
                {this.state.dashboardRecordResults?.slice(
                  this.state.dashboardItemsPerPage *
                  (this.state.currentPage - 1),
                  this.state.dashboardItemsPerPage *
                  this.state.currentPage
                )?.map((result, index) => (
                  <TableRow key={result?.id} >
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.purchase_requisition_number}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.account?.user_name}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.pr_creation_date}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.delivery_date}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.location_setting_id?.name_of_location}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.department_id?.name}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.tableBodyRows}>{result.attributes?.status}</TableCell>
                    <TableCell className={"pr_records"} style={newStyles.iconColumn}>
                      {result.attributes.status === 'draft' ?
                      <>
                          <VisibilityOutlinedIcon data-testid={"show_pr_icon"}
                            onClick={
                              this.handleBtnOnclickDisabled.bind(this,
                                'purchase requisitions approval',
                                'to Show & Approve Purchase Requisition',
                                this.handleShowRequisitionOpenModal,
                                result?.id
                              )}
                            style={newStyles.icons} />
                            <EditOutlinedIcon data-testid={"edit_pr_icon1"} onClick={
                          this.handleBtnOnclickDisabled.bind(this,
                            'purchase requisitions',
                            'to Edit Purchase Requisition',
                            this.handleDashboardEditRequisitionOpenModal,
                            result
                          )} style={newStyles.icons} /> 
                      </>
                          : <VisibilityOutlinedIcon data-testid={"show_pr_icon"}
                            onClick={
                              this.handleBtnOnclickDisabled.bind(this,
                                'purchase requisitions approval',
                                'to Show & Approve Purchase Requisition',
                                this.handleShowRequisitionOpenModal,
                                result?.id
                              )}
                            style={newStyles.icons} />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              : <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />}
          </Table>
        </>
      )
    }
  }

  requestForProposalsTable = () => {
    if (this.state.dropDownItemValue === 'Request for Proposals') {
      return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>RFP Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>RFP Name</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Creation Date</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Due Date</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Number of Suppliers</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.dashboardRecordResults?.length !== 0 ?
                  this.state.dashboardRecordResults?.slice((this.state.currentPage - 1) * this.state.dashboardItemsPerPage, this.state.currentPage * this.state.dashboardItemsPerPage).map(result =>
                    <TableRow key={result?.id}>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.rfp_number}</TableCell>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.account?.user_name}</TableCell>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.creation_date}</TableCell>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.due_date}</TableCell>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.number_of_supplier}</TableCell>
                      <TableCell className={"rfp_records"} style={newStyles.tableBodyRows}>{result?.attributes?.status}</TableCell>
                      <TableCell style={newStyles.iconColumn} align='right'>
                        {
                          this.dashboardRFPEditActionRendered(result.attributes?.status, result)
                        }
                        <VisibilityOutlinedIcon data-testid={"show_rfp_icon1"} style={newStyles.icons} onClick={this.handleRfpShowActionTriggered.bind(this, result)}
                        />

                      </TableCell>
                    </TableRow>
                  )
                  : <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />
              }
            </TableBody>
          </Table>
        </>
      )
    }
  }



  purchaseOrderTable = () => {
    if (this.state.dropDownItemValue === 'Purchase Order') {
      return (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={newStyles.tableHeaderRows}>PO Number</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Supplier</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Requester</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Order Date</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Location</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Department</TableCell>
              <TableCell style={newStyles.tableHeaderRows}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.dashboardRecordResults?.length !== 0 ?
                this.state.dashboardRecordResults?.slice((this.state.currentPage - 1) * this.state.dashboardItemsPerPage, this.state.currentPage * this.state.dashboardItemsPerPage).map(result =>
                  <TableRow key={result.id}>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.purchase_order_number}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.supplier?.data?.attributes?.supplier_name}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.account?.user_name}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.order_date}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.location}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.department?.name}</TableCell>
                    <TableCell className={"po_records"} style={newStyles.tableBodyRows}>{result.attributes?.status}</TableCell>
                    <TableCell style={newStyles.iconColumn}>
                      <VisibilityOutlinedIcon data-testid={"show_po_icon"} style={newStyles.icons} onClick={this.handleShowPurchaseOrderOpenModal.bind(this, result?.id)} />
                      {
                        result.attributes?.status === "draft" && this.dashboardPurchaseOrderEditActionRendered(result.attributes?.status, this.state.dashboardMenuSelection, result)
                      }
                      {
                        this.dashboardPurchaseOrderMyApprovalAcceptActionRendered(result.attributes?.status,this.state.dropDownItemValue, this.state.dashboardMenuSelection, result)
                      }
                      {
                        this.dashboardPurchaseOrderMyApprovalRejectActionRendered(result.attributes?.status,this.state.dropDownItemValue, this.state.dashboardMenuSelection, result)
                      }
                    </TableCell>
                  </TableRow>
                ) : <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />
            }
          </TableBody>
        </Table>
      )
    }
  }

  styledSelectStyle = () => {
    if (this.state.editedLocation === "" || this.state.poDepartment === "" || this.state.poBudget === "" || this.state.poSupplier === "" || this.state.paymentTermsName === "" || this.state.products.length > 0 || this.state.location === "" || this.state.department === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  }

  poPlaceHolder = () => {
    if (this.state.editedPoShippingAddress !== "") {
      return "Fetching Shipping Address...";
    } else if (this.state.poBillingAddress !== "") {
      return "Fetching Billing Address..."
    }
  }

  poValidationError = (itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  poValidationErrorDate = (itemsToBeValidated: any,
    fieldOrderDateState: any,
    fieldDeliveryDateState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && new Date(fieldDeliveryDateState)
      .getDate() < new Date(fieldOrderDateState)
        .getDate()) {
      return errorToBeShow
    } else {
      return '';
    }
  }

  handleTabsPoActiveStyleChange = (activeValue: number) => {
    if (this.state.poCreateScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }
  handleTabsPoViewActiveStyleChange = (activeValue: number) => {
    if (this.state.poViewScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }


  handleTabsEditPoActiveStyleChange = (activeValue: number) => {
    if (this.state.poEditScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  circularProgressShippingAddress = () => {
    if (this.state.poShippingAddress === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    }
  };

  handleNewPoProductsListUpdation = (stateValue: any) => {
    if (stateValue) {
      return [...this.state.productsList, { attributes: { name: this.state.poNonCatalougeCollectedItem, theInputIsNonCatalog: true } }]
    } else {
      return this.state.productsList
    }
  }

  handleEditPoProductsListUpdation = (stateValue: any) => {
    if (stateValue) {
      return [...this.state.productsList, { attributes: { name: this.state.editedPoNonCatalougeCollectedItem, theInputIsNonCatalog: true } }]
    } else {
      return this.state.productsList
    }
  }

  handlePoNonCatelogItemsRendered = (option: any) => {
    if (option?.attributes?.theInputIsNonCatalog) {
      return <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '5px', alignItems: 'center', boxSizing: 'border-box' as 'border-box' }} > <span>{option?.attributes?.name}</span><span style={{ color: "#e61717", fontSize: '12px' }}>Non Catalogue Item</span></Box>
    } else {
      return option?.attributes?.name
    }
  }

  getPoCatalougeItemLabel = (product: any) => {
    if (this.state.productsList?.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  getEditedPoCatalougeItemLabel = (product: any) => {
    if (this.state.productsList?.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  handlePoConditionalTextRendered = (textValue: any) => {
    if (textValue) {
      return 'Non catalouge Item'
    } else {
      return '';
    }
  }

  addRFPSelectProductFieldValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length === 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  editPOSelectProductFieldValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length === 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addPRDateTypeConditionCheckRendered = (selectedType: any) => {
    if (selectedType == "text") {
      return "text"
    }
    else {
      return "date"
    }
  }

  addPRDatesColorStyleConditionalRendered = (selectedValue: any) => {
    if (selectedValue == "date" || selectedValue) {
      return "black"
    }
    else {
      return '#a6a6a5'
    }
  }

  addUpdatePRInputFieldsValidationErrorRendered = (itemsToBeValidated: any, fieldState: any, errorToBeShow: any) => {
    if (itemsToBeValidated && fieldState === "") {
      return errorToBeShow
    }
    else {
      return ''
    }
  }

  addUpdatePRProductsFieldsValidationErrorRendered = (itemsToBeValidated: any, fieldState: any, errorToBeShow: any) => {
    if (itemsToBeValidated && fieldState?.length === 0) {
      return errorToBeShow
    }
    else {
      return ""
    }
  }


  addPRNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue) {
      return newStyles.selectBoxLabel
    }
    else {
      return newStyles.selectBox
    }
  }

  addPRNewStylesSelectBoxLabelRenderedForProducts = (setValue: any) => {
    if (setValue.length > 0) {
      return newStyles.selectBoxLabel
    }
    else {
      return newStyles.selectBox
    }
  }

  addPRBtnConditionalDisabledDuringLoadingState = () => {
    if (this.state.createPurchaseRequisitionLoading) {
      return true
    }
    else {
      return false
    }
  }

  addPRBtnConditionalTextRendered = () => {
    if (this.state.createPurchaseRequisitionLoading) {
      return 'Loading'
    }
    else {
      return 'Create New'
    }
  }

  updatePRBtnConditionalTextRendered = () => {
    if (this.state.editPurchaseRequisitionLoading) {
      return 'Loading'
    }
    else {
      return 'Update'
    }
  }

  getCatalougeItemLabel = (product: any) => {
    if (this.state.productsList.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  getOptionsList = () => {
    if (this.state.prNonCatalougeItems) {
      return [...this.state.productsList, { attributes: { name: this.state.prNonCatalougeItems, isNew: true } }]
    } else {
      return this.state.productsList
    }
  }

  getEditedCatalougeItemLabel = (product: any) => {
    if (this.state.productsList.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  getOptionsListEditedType = () => {
    if (this.state.prEditNonCatalougeItems) {
      return [...this.state.productsList, { attributes: { name: this.state.prEditNonCatalougeItems, isNew: true } }]
    } else {
      return this.state.productsList
    }
  }

  renderCatalougeItemsName = (option: any) => {
    if (option?.attributes?.isNew === true) {
      return <Box id="prOptionName1" display={"flex"} width={"100%"} justifyContent={"space-between"}>{option?.attributes?.name} <span style={{ color: "#FF8C00", fontWeight: 'bolder' }}> Non Catalouge Item </span></Box>
    } else if (!option?.attributes?.isNew) {
      return <Box display={"flex"} id="prOptionName2" width={"100%"}>{option?.attributes?.name}</Box>
    }
  }

  prProductNameRender = (pro: any) => {
    if (pro?.attributes?.isNew === true) return 'Non catalouge Item';
  }

  handleAllProductChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value?.attributes?.is_non_catalogue === false) {
      this.handleProductsChangeCatalouge(event, value);
    } else {
      this.handleProductsChangeNonCatalouge(event, value);
    }
  }

  editedProductName = (pro: any) => {
    if (pro?.attributes?.name) {
      return (pro?.attributes?.name);
    } else {
      return pro?.name
    }
  }

  editedCatalougeItemName = (pro: any) => {
    if (pro?.is_non_catalague_item === true) return 'Non catalouge Item';
  }

  editedNonCatalogeItemName = (pro: any) => {
    if (pro?.attributes?.isNew === true || pro?.is_non_catalague_item === true) {
      return 'Non catalouge Item'
    } else {
      return ''
    }
  }

  prButtonRenderedRequisitionApproval = () => {
    if (this.state.dashboardMenuSelection === "My Requisitions") {
      return <Button onClick={this.handleShowRequisitionCloseModal} style={newStyles.modalCancelButton} variant="contained">Close</Button>
    } else {
      return <>
        <Button id="acceptRequisitionMyApproval" onClick={this.handleAcceptRequisitionMyApprovalApiCall} style={newStyles.modalApproveButton} variant="contained">Approve</Button>
        <Button id="rejectRequisitionMyApproval" onClick={this.handleRejectRequisitionMyApprovalApiCall} style={newStyles.modalCancelButton} variant="contained">Rejected</Button>
      </>
    }
  }

  getPrFilteredOptionName = (product: any) => {
    if (product?.attributes?.name !== null || undefined) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }


  // dash rfp new function

  dashboardRFPEditActionRendered = (statusValue: string, result: any) => {
    if (statusValue === "draft") {
      return <EditOutlinedIcon data-testid={"edit_rfp_icon"}
        onClick={
          this.handleBtnOnclickDisabled.bind(this,
            'request for proposal',
            'to Edit Request For Proposal',
            this.handleEditRFPOpenModal,
            result
          )}
        style={newStyles.icons} />
    }
    else {
      return ''
    }
  }

  handleTabsActiveStyleChange = (activeValue: number) => {
    if (this.state.editedRfpScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  handleReadOnlyFields = (stateValue: any) => {
    if (stateValue) {
      return true
    } else {
      return false
    }
  }

  addRfpProductsNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue.length > 0) {
      return newStyles.rfpItemsSelectBox
    }
    else {
      return newStyles.rfpItemsSelectBoxElse
    }
  }

  handleRfpValidEmailError = (stateValue: any) => {
    if (stateValue) {
      return "Enter a valid email"
    } else {
      return ""
    }
  }

  handleDashboardFieldTextError = (itemsToBeValidated: any, fieldState: any, errorToBeShow: any) => {
    if (itemsToBeValidated && fieldState === "") {
      return errorToBeShow
    }
    else {
      return ''
    }
  }

  addRFPNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue === "") {
      return newStyles.selectBoxLabel
    }
    else {
      return newStyles.selectBox
    }
  }

  rfpFileAttachmentAcceptCondition = (stateValue: any) => {
    if (stateValue === "") {
      return `.pdf, .doc`
    } else {
      return `.${stateValue}`
    }
  }

  handleNewProductsListUpdation = (stateValue: any) => {
    if (stateValue) {
      return [...this.state.productsList, { attributes: { name: stateValue, theInputIsNonCatalog: true } }]
    } else {
      return this.state.productsList
    }
  }

  getRfpCatalougeItemLabel = (product: any) => {
    if (this.state.editedRfpCatelogItems.length > 0 && product?.attributes?.name) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }

  handleAllEditPoProductChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (value?.attributes?.is_non_catalogue === false) {
      this.handleProductsEditPoChangeCatalouge(event, value);
    } else {
      this.handleProductsEditPoChangeNonCatalouge(event, value);
    }
  }

  rfpFilteredOptionsNestedConditionalFunc = (product: any) => {
    if (product?.attributes?.name) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }

  handleEditedRfpProductsConditionalShow = (event: any, value: any) => {

    console.log("handleEditedRfpProductsConditionalShow check : ", value)

    if (value?.attributes?.is_non_catalogue === false) {
      this.handleEditedRfpCatelogProductChange(event, value);
    } else {
      this.handleEditedRfpNonCatelogProductChange(event, value)
    }
  }

  handleEditedRfpNonCatelogItemsRendered = (option: any) => {
    if (option?.attributes?.theInputIsNonCatalog === true) {
      return <Box id="rfpNonCataItem" style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '5px', alignItems: 'center', boxSizing: 'border-box' as 'border-box' }} > <span>{option?.attributes?.name}</span><span style={{ color: "#e61717", fontSize: '12px' }}>Non Catalogue Item</span></Box>
    } else {
      return option?.attributes?.name
    }
  }

  handleDashboardFieldArrayListError = (itemsToBeValidated: any, fieldState: any, errorToBeShow: any) => {
    if (itemsToBeValidated && fieldState.length === 0) {
      return errorToBeShow
    }
    else {
      return ''
    }
  }

  handleEditedProductsConditionalTextRendered = (textValue: any) => {
    if (textValue) {
      return 'Non catalouge Item'
    } else {
      return '';
    }
  }

  handleEditedProductsConditionalTextRenderedForTwo = (specs1: any, specs2: any) => {
    if (specs1 || specs2) {
      return 'Non catalouge Item'
    } else {
      return '';
    }
  }

  editedPoProductName = (pro: any) => {
    if (pro?.attributes?.name) {
      return (pro?.attributes?.name);
    } else {
      return pro?.name
    }
  }

  editedRfpProductName = (pro: any) => {
    if (pro?.attributes?.name) {
      return (pro?.attributes?.name);
    } else if (pro.product_name) {
      return pro?.product_name
    } else {
      return pro?.name
    }
  }

  editedPoProductPrice = (pro: any) => {
    if (pro?.product_price) {
      return pro?.product_price;
    } else if (pro?.attributes?.unit_price !== undefined) {
      return pro?.attributes?.unit_price.replace(/[$]/g, '')
    }
  }



  handleopenEditedRfpPdfSupplierScreenModal = () => {
    if (this.state.openEditedRfpPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper} id="supplierDownloadPdf">
          <Button style={newStyles.rfpModalButton} onClick={this.handleEditedDownloadRFP.bind(this)} variant="contained">{this.state.isEditedRFPFileDownloaded ? <CircularProgress size={24} color="secondary" /> : `Download as PDF`}</Button>
          <Button style={newStyles.rfpModalButton} onClick={this.handleEditedRfpMailSendConfirmation} variant="contained">
            Send To Supplier
          </Button>
        </Box>
      )
    } else if (!this.state.openEditedRfpPdfSupplierScreenModal && !this.state.openEditedRfpSupplierMailSeccessModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button
            id="dash_rfp_edit_form"
            disabled={this.state.isEditedRfpUpdationApiSuccessDone ? true : false}
            onClick={this.handleDashboardEditRFP} style={newStyles.rfpModalButton} variant="contained">
            {this.state.isRfpUpdating ? <CircularProgress size={24} color="inherit" /> : 'Update'}
          </Button>
          <Button onClick={this.handleEditedRequestForProposalsCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
        </Box>
      )
    } else {
      return ''
    }
  }

  handleOpenPoPdfSupplierScreenModal = () => {
    if (this.state.openPoPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button id="getDownloadPdf" style={newStyles.modalButton} onClick={this.handleGetDownloadPdfPo} variant="contained">
            {this.state.isPdfDownloaded ? <CircularProgress size={24} color="inherit" /> : 'Download as PDF'}
          </Button>
          <Button style={newStyles.modalButton} onClick={this.handlePoMailSendConfirmation} variant="contained">
            {this.state.isPoMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>
        </Box>
      )
    } else if (!this.state.openPoPdfSupplierScreenModal && !this.state.openPoSupplierMailSeccessModal) {
      return (
        <Box display={"flex"} justifyContent={"space-between"} width="100%">
          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              onClick={this.handleSaveAsDraftPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.state.poSaveDraft ? <CircularProgress size={24} color="inherit" /> : 'Save as Draft'}
            </Button>
          </Box>
          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              disabled={this.state.isPoCreationAndApiSuccessDone ? true : false}
              onClick={this.handleCreatePurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.state.poCreating ? <CircularProgress size={24} color="inherit" /> : 'Create'}
            </Button>
            <Button onClick={this.handleAddPurchaseOrderCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
          </Box>
        </Box>
      )
    } else {
      return ''
    }
  }

  handleOpenEditedPoPdfSupplierScreenModal = () => {
    if (this.state.openEditedPoPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper} id="editPoSupplierPdfDownload">
          <Button style={newStyles.modalButton} onClick={this.handleEditedGetDownloadPdfPo.bind(this)} variant="contained">
            {this.state.isEditedPdfDownloaded ? <CircularProgress size={24} color="inherit" /> : 'Download as PDF'}
          </Button>
          <Button style={newStyles.modalButton} onClick={this.handleEditedPoMailSendConfirmation.bind(this)} variant="contained">
            {this.state.isPoMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>
        </Box>
      )
    } else if (!this.state.openEditedPoSupplierMailSeccessModal && !this.state.openEditedPoPdfSupplierScreenModal) {
      return (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Button
              disabled={this.state.isEditedPoCreationAndApiSuccessDone ? true : false}
              onClick={this.handleSaveAsDraftPurchaseOrder} style={newStyles.modalButton} variant="contained">
              Save as Draft
            </Button>
          </Box>
          <Box>
            <Button
              onClick={this.handleDashboardEditPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.state.poEditing ? <CircularProgress size={24} color="inherit" /> : 'Update'}
            </Button>
            <Button onClick={this.handleEditPurchaseOrderCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
          </Box>
        </Box>
      )
    } else {
      return ""
    }

  }

  purchaseOrderFilteredOptionsNestedConditionalFunc = (product: any) => {
    if (product?.attributes?.name) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }



  handleModulePermissionExistOrNotNestedFunc = (response: any) => {
    if (response) {
      return false
    } else {
      return true
    }
  }


  handleUserPermissionAccessModules = (moduleKey: string) => {
    const userLoginDetails = this.state.userLoginDetailsCatch?.data?.attributes;

    if (userLoginDetails?.update_account_type === "User Login") {
      const userPermission = userLoginDetails?.all_user?.[0]?.permission;

      if (userPermission) {
        const permissions = JSON.parse(userPermission);
        const containsPurchaseRequisition = permissions?.includes(moduleKey);

        return this.handleModulePermissionExistOrNotNestedFunc(containsPurchaseRequisition);
      }
    }

    return false;
  }


  handleBtnOnclickDisabled = (moduleKey: string, textKey: string, clickFunction: any, metaData?: any) => {
    const hasPermissions = this.handleUserPermissionAccessModules(moduleKey)

    if (hasPermissions) {
      this.setState({
        isDashBtnDisabled: true,
        noDashModulePermitMessage: `Not Permitted ${textKey}`
      });
    } else {
      if (metaData) {
        return clickFunction(metaData);
      } else {
        return clickFunction()
      }
    }
  }


  handleRfpPdfStyleLableChange = () => {
    if (this.state.rfpFileAttachment) {
      return newStyles.rfpPdfLabelHighlight
    } else {
      return newStyles.rfpPdfLabel
    }
  }
  handleMinEditedPoOrderDate = (myDate: any) => {
    return myDate || this.state.editedPoOrderDate
  }

  minEditedPoOrderDate = () => {
    let myDate = new Date()
      .getFullYear()
      .toString()
      .padStart(4, "0") +
      "-" +
      (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      new Date()
        .getDate()
        .toString()
        .padStart(2, "0")
   return this.handleMinEditedPoOrderDate(myDate)
  }

  handleMinPrDeliveryDate = (myDate: any) => {
    return myDate || this.state.durationDate
  }

  minPrDeliveryDate = () => {
    let myDate = new Date()
      .getFullYear()
      .toString()
      .padStart(4, "0") +
      "-" +
      (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      new Date()
        .getDate()
        .toString()
        .padStart(2, "0")

    this.handleMinPrDeliveryDate(myDate)
  }

  minPoDeliveryDate = () => {
    let demoDate = new Date()
      .getFullYear()
      .toString()
      .padStart(4, "0") +
      "-" +
      (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      (new Date()
        .getDate())
        .toString()
        .padStart(2, "0")
    return demoDate || this.state.poOrderDate
  }


  minPoOrderDate = () => {
    let myDate = new Date()
      .getFullYear()
      .toString()
      .padStart(4, "0") +
      "-" +
      (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0") +
      "-" +
      new Date()
        .getDate()
        .toString()
        .padStart(2, "0")

    this.handleMinPoOrderDate(myDate)
  }

  handleMinPoOrderDate = (myDate: any) => {
    if (myDate) {
      return myDate;
    } else {
      return this.state.poOrderDate
    }
  }

  checkOnlyShownProducts = (prod: any) => {
    if (prod?._destroy === 1) {
      return !prod?._destroy;
    } else {
      return prod?.id
    }
  }

  unitFilterPoPrice = (prod: any) => {
    if (prod?.attributes?.unit_price !== undefined) {
      return prod?.attributes?.unit_price.replace(/[$]/g, '')
    } else {
      return ''
    }
  }


  quantityHandleDefault = (pro: any) => {
    if (pro?.quantity > 0) {
      return pro?.quantity;
    } else {
      return '';
    }
  }
  handleEditSelectProdPr = (option: any, { selected }: any) => {
    return (
      <React.Fragment>
        <Radio
          style={{ marginRight: 8, color: "black" }}
          checked={selected}
        />
        {this.renderCatalougeItemsName(option)}
      </React.Fragment>
    )
  }
  handleEditSelectProdPrRender = (params: any) => {
    return (
      <TextField {...params} data-testid="getPrEditNonCataItem" value={this.state.prEditNonCatalougeItems} onChange={this.getSearchedEditedNonCatalougeItems} variant="outlined" placeholder="Find or Select Item" />
    )
  }
  handleRfpProductSelectChange = (option: any, { selected }: any) => {

    return (
      <React.Fragment>
        <Radio
          style={{ marginRight: 12, color: "black" }}
          checked={selected}
        />
        {this.handleEditedRfpNonCatelogItemsRendered(option)}
      </React.Fragment>
    )
  }

  handleRfpProductSelectRender = (params: any) => (
    <TextField {...params} value={this.state.editedRfpNonCatalougeCollectedItems} onChange={this.getEditedAndSearchedNonCatelogProductChange} variant="outlined" placeholder="Find or Select Item" style={{ marginBottom: '10px', border: '0.005px solid #B1B1AE', borderRadius: '10px' }} />
  )
  handlePoProductSelectChange = (option: any, { selected }: any) => (
    <React.Fragment>
      <Radio
        style={{ marginRight: 8, color: "black" }}
        checked={selected}
      />
      {this.handlePoNonCatelogItemsRendered(option)}
    </React.Fragment>
  )
  handlePoProductSelectRender = (params: any) => (
    <TextField {...params} value={this.state.editedPoNonCatlogItems} onChange={this.getEditedSearchedPoNonCatelogProductChange} variant="outlined" placeholder="Find or Select Item" />
  )
  handleNewPoProductSelectChange = (option: any, { selected }: any) => (
    <React.Fragment>
      <Radio
        style={{ marginRight: 8, color: "black" }}
        checked={selected}
      />
      {this.handlePoNonCatelogItemsRendered(option)}
    </React.Fragment>
  )
  handleNewPoProductSelectRender = (params: any) => (
    <TextField {...params} value={this.state.poNonCatalougeCollectedItem} onChange={this.getSearchedPoNonCatelogProductChange} variant="outlined" placeholder="Find or Select Item" />
  )

  handleFilterDemoSelectChange = (option: any, { selected }: any) => (
    <React.Fragment>
      <Radio
        style={{ marginRight: 8, color: "black" }}
        checked={selected}
      />
      {this.renderCatalougeItemsName(option)}
    </React.Fragment>
  )

  handleFilterDemoSelectRender = (params: any) => (
    <TextField {...params} value={this.state.prNonCatalougeItems} onChange={this.getSearchedNonCatalougeItems} variant="outlined" placeholder="Find or Select Item" />
  )

  isEditedRfpMailSendingOrNot = () =>{
    if(this.state.isEditedRfpMailSending){
      return <CircularProgress size={24} color="inherit" />
    }else{
      return 'Yes'
    }
  }

  // Customizable Area End


  render() {
    const myProfile = this.state.userProfile;
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: this.getPrFilteredOptionName.bind(this),
    });


    const rfpFilterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: this.rfpFilteredOptionsNestedConditionalFunc.bind(this)
    })

    const poFilterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: this.purchaseOrderFilteredOptionsNestedConditionalFunc.bind(this)
    })


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 10;
    const rfpMenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 100,
          marginTop: 40
        },
      },
    };

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Box style={newStyles.mainContainer}>
          <Header navigation={this.props.navigation} active={0} profile={myProfile} id="" permissionLoginUserDetailsState={this.state.userLoginDetailsCatch} />
          <ToastMessageDisplay message={this.state.noDashModulePermitMessage} handleClose={this.handleNoDashModulePermitMessageSnackbarClose} />
          {this.state.tabValue === 0 && <Box>
            <Box style={newStyles.menuListContainer}>
              <StyledSelect
                data-testid={"dashboard_desplay_options"}
                disableUnderline
                defaultValue="Purchase Requisition"
                variant="filled"
                input={<Input margin="none" />} style={newStyles.dropDown}>
                {this.state.dashboardOptions?.map((option: any, index: number) => {
                  for (const i in option) {
                    return <MenuItem id={"dashboard_menuItems"} style={newStyles.dropDownItems} key={i} value={option[i]}
                      onClick={() => this.updateDashboardDisplay(option[i], 'dropDownFields')}>
                      {option[i]}
                    </MenuItem>

                  }
                })}
              </StyledSelect>
              <Divider style={newStyles.divider} orientation="vertical" variant="middle" flexItem />
              <Box>
                <ToastMessageDisplay message={this.state.dashboardNoRecordMessage} handleClose={this.handleDashboardNoRecordMessageSnackbarClose} />
                {
                  this.dashboardSelectedSubTabMenuReturned()
                }
              </Box>
              {this.dashboardSelectedSubMenuCreateNewBtn()}
            </Box>

            <TableContainer style={newStyles.tableContainer}>
              {this.purchaseRequisitionTable()}
              {this.requestForProposalsTable()}
              {this.purchaseOrderTable()}
            </TableContainer>
            {
              this.state.dashboardNoOfPages > 1 &&
              <div className="pagination" style={{ display: 'flex', justifyContent: 'center' }}>
                <StyledPagination
                  data-testid={"pagination_record"}
                  count={this.state.dashboardNoOfPages}
                  page={this.state.currentPage}
                  siblingCount={1}
                  boundaryCount={1}
                  onChange={(event: Event, value: number) => {
                    this.setState({ currentPage: value })
                  }} />

              </div>
            }
          </Box>}
          <Dialog PaperProps={{ style: { height: "100%", width: "100%", maxWidth: '100%' } }} open={this.state.openEditRequisitionModal}>
            <ToastMessageDisplay message={this.state.purchaseRequisitionEditMessage} handleClose={this.handlePurchaseRequisitionEditSnackbarClose} />
            <Typography style={newStyles.modalHeading} variant='h5'>
              PR Number: {this.state.editedPrNumberDefault}
            </Typography>
            <Grid container >
              <Box display={"flex"} width={"100%"} mx={'0rem'}>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                    <StyledTextField
                      required
                      data-testid="isEditPRDurationDateValidationError"
                      type={'date'}
                      value={this.state.editedDurationDate}
                      onFocus={this.handleDurationDateTypeFocus}
                      style={newStyles.textFieldBox}
                      onBlur={this.handleEditDurationDateTypeBlur}
                      onChange={this.handleEditDurationDate}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: 'Plus Jakarta Display',
                          color: this.addPRDatesColorStyleConditionalRendered(this.state.durationDateType),
                          backgroundColor: 'transparent',
                        }
                      }}
                      placeholder="Enter Creation Date"
                      variant="outlined"
                      InputProps={{
                        inputProps: { min: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') },
                        disableUnderline: true,
                      }}
                    />
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsEditPurchaseRequisitionValidation, this.state.editedDurationDate, this.state.editedDurationDateRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Expected Delivery Date</Typography>
                    <StyledTextField
                      required
                      data-testid="isEditPRDeliveryDateValidationError"
                      type={'date'}
                      value={this.state.editedDeliveryDate}
                      onChange={this.handleEditDeliveryDate}
                      onBlur={this.handleEditDeliveryDateTypeBlur}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: 'Plus Jakarta Display',
                          color: this.addPRDatesColorStyleConditionalRendered(this.state.deliveryDateType)
                        }
                      }}
                      placeholder="Enter Delivery Date"
                      onFocus={this.handleDeliveryDateTypeFocus}
                      variant="outlined"
                      InputProps={{ inputProps: { min: this.state.editedDurationDate } }}
                    />
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsEditPurchaseRequisitionValidation, this.state.editedDeliveryDate, this.state.editedDeliveryDateRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Location</Typography>
                    <StyledSelect
                      id={"edit_location_select"}
                      MenuProps={{ autoFocus: false, maxHeight: '100px' }}
                      variant="outlined"
                      label="Select Product"
                      value={this.state.editedLocation}
                      onChange={this.handleEditLocation}
                      onClose={() => this.setState({ purchaseRequisitionSearchLocation: '' })}
                      disableUnderline
                      displayEmpty
                      style={this.addPRNewStylesSelectBoxLabelRendered(this.state.location)}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>Select Location</MenuItem>
                      {this.state.locationDropdownList?.map((location: any, index: any) => {
                        return <MenuItem className={'edited_location_items'} key={location?.id} value={location?.id}>{location?.attributes?.name_of_location}</MenuItem>
                      })}
                    </StyledSelect>
                    <Typography data-testid="isEditPRLocationValidationError" style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsEditPurchaseRequisitionValidation, this.state.editedLocation, this.state.editedLocationRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Department</Typography>
                    <StyledSelect
                      id={"pr_edit_department_select"}
                      variant="outlined"
                      label="Select Product"
                      value={this.state.editedDepartment}
                      onChange={this.handleEditDepartment}
                      disableUnderline
                      displayEmpty
                      style={this.addPRNewStylesSelectBoxLabelRendered(this.state.department)}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>Select Department</MenuItem>
                      {this.state.departmentList?.map((department: any) => {
                        return <MenuItem className="department_items" key={department?.id} value={department?.id}>{department?.name}</MenuItem>
                      })}
                    </StyledSelect>
                    <Typography data-testid="isEditPRDepartmentValidationError" style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsEditPurchaseRequisitionValidation, this.state.editedDepartment, this.state.editedDepartmentRequiredError)}</Typography>
                  </Box>
                </Grid>
              </Box>
              <Grid item lg={3} style={{ marginLeft: '0rem' }}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Catalouge Items</Typography>
                  <Autocomplete
                    multiple={false}
                    id="edit_select_products_pr"
                    options={this.getOptionsListEditedType()}
                    getOptionLabel={this.getEditedCatalougeItemLabel.bind(this)}
                    filterOptions={filterOptions}
                    style={this.addPRNewStylesSelectBoxLabelRenderedForProducts(this.state.prProduct)}
                    onChange={(event, value) => {
                      console.log('value212121', value);
                      this.handleAllProductChange(event, value)
                    }}
                    renderOption={this.handleEditSelectProdPr.bind(this)}
                    renderInput={this.handleEditSelectProdPrRender.bind(this)}
                  />
                  <Typography data-testid={'product_required_error'} style={newStyles.validationText}>{this.addUpdatePRProductsFieldsValidationErrorRendered(this.state.showIsEditPurchaseRequisitionValidation, this.state.editedCatalougeItemPr, this.state.editedCatalougeItemPrRequiredError)}</Typography>
                </Box>
              </Grid>
              <TableContainer component={Paper} style={{
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                margin: '20px',
                border: '2px solid lightgrey',
                borderRadius: '15px',
                width: '100%',
                marginLeft: '1.5rem',
                marginTop: '1rem',
                marginBottom: '1rem',
                marginRight: '1.5rem',
                maxWidth: '100%',
              }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}>Product Id</TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}>Product Name</TableCell>
                      <TableCell align="left" style={{ width: '10%', padding: '16px 15rem 25px 30px' }}>Quantity</TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}></TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflowY: "scroll" }}>
                    {this.state.editedCatalougeItemPr?.map((pro: any, index: number) => {
                      return (<TableRow key={pro?.id}>
                        <TableCell className="editedCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{pro?.id}</TableCell>
                        <TableCell className="editedCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.editedProductName(pro)}</TableCell>
                        <TableCell className="editedCatalougeItemPr" align="left" style={{ width: '10%', padding: '16px 65rem 25px 30px;', border: "none", outline: "none", borderBottom: '1px solid lightgrey' }}><TextField value={this.quantityHandleDefault(pro)} type="text" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} onChange={(e) => this.handleEditPrQuantityCatalouge(e, pro, index)} id="editedQuantityCatalougePr" style={{
                          border: '2px solid rgb(128 128 128 / 20%)',
                          borderRadius: '10px',
                          padding: '10px 15px',
                          width: '80px',
                        }} /></TableCell>
                        <TableCell className="editedCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.editedCatalougeItemName(pro)}</TableCell>
                        <TableCell className="editedCatalougeItemPr" align="left" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "25px", padding: '2px 80px', height: '58px', }}>
                          <Chip
                            id="editedCatalougeItemPrDelete"
                            size="medium"
                            onDelete={this.handleRemoveTableCellPrCatalouge.bind(this, index)}
                            style={{ width: '0px' }}
                          /></TableCell>
                      </TableRow>
                      )
                    })}
                    {this.state.editedNonCatalougeItemPr?.map((pro: any, index: number) => {
                      return (<TableRow key={pro?.id}>
                        <TableCell className="editedNonCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{""}</TableCell>
                        <TableCell className="editedNonCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.editedProductName(pro)}</TableCell>
                        <TableCell className="editedNonCatalougeItemPr" align="left" style={{ width: '10%', padding: '16px 65rem 25px 30px;', border: "none", outline: "none", borderBottom: '1px solid lightgrey' }}><TextField value={this.quantityHandleDefault(pro)} type="number" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} onChange={(e) => this.handleEditPrQuantityNonCatalouge(e, pro, index)} style={{
                          border: '2px solid rgb(128 128 128 / 20%)',
                          borderRadius: '10px',
                          padding: '10px 15px',
                          width: '80px',
                        }} /></TableCell>
                        <TableCell className="editedNonCatalougeItemPr" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.editedNonCatalogeItemName(pro)}</TableCell>
                        <TableCell className="editedNonCatalougeItemPr" align="left" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "25px", padding: '2px 80px', height: '58px', }}>
                          <Chip
                            id="chip-delete"
                            size="medium"
                            onDelete={this.handleRemoveTableCellPrNonCatalouge.bind(this, index)}
                            style={{ width: '0px' }}
                          /></TableCell>
                      </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                <Button data-testid={"dash_pr_update_btn"} onClick={this.handleDashboardEditPurchaseRequisition} style={newStyles.modalButton} variant="contained">{this.updatePRBtnConditionalTextRendered()}</Button>
                <Button data-testid="dash_pr_cancel_btn" onClick={this.handleEditRequisitionCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
              </Box>
              <Box>
              </Box>
            </Grid>
          </Dialog>

          <Dialog maxWidth="lg" PaperProps={{ style: { height: "100%", width: "100%" } }} open={this.state.openShowRequisitionModal}>
            <Box
              display={"flex"}
              width={'100%'}
              justifyContent={"space-between"}
              alignItems={"baseline"}
            >
              <Typography data-testid="showPrNumberId" style={newStyles.modalHeading} variant='h5'>
                PR Number: {this.state.showPurchaseRequisition?.attributes?.purchase_requisition_number}
              </Typography>
              <span data-testid="close_requisition_modal" style={newStyles.cursorPointer} onClick={this.handleShowRequisitionCloseModal}>
                <CloseOutlinedIcon />
              </span>
            </Box>
            <Grid container>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                  <StyledTextField
                    required
                    id="show_pr_creation_date"
                    disabled
                    type={"text"}
                    value={this.state.showPurchaseRequisition?.attributes?.pr_creation_date}
                    style={newStyles.textFieldBox}
                    inputProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Plus Jakarta Display',
                        color: this.addPRDatesColorStyleConditionalRendered(this.state.durationDateType),
                        backgroundColor: 'transparent',
                      }
                    }}
                    variant="outlined"
                    InputProps={{ inputProps: { min: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') } }}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Delivery Date</Typography>
                  <StyledTextField
                    required
                    disabled
                    type={'text'}
                    value={this.state.showPurchaseRequisition?.attributes?.delivery_date}
                    style={newStyles.textFieldBox}
                    inputProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Plus Jakarta Display',
                        color: this.addPRDatesColorStyleConditionalRendered(this.state.deliveryDateType)
                      }
                    }}
                    variant="outlined"
                    InputProps={{ inputProps: { min: this.state.durationDate } }}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Location</Typography>

                  <StyledTextField
                    required
                    disabled
                    type={'text'}
                    value={this.state.showPurchaseRequisition?.attributes?.location_setting_id?.name_of_location}
                    style={newStyles.textFieldBox}
                    inputProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Plus Jakarta Display',
                      }
                    }}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Department</Typography>
                  <StyledTextField
                    required
                    disabled
                    type={'text'}
                    value={this.state.showPurchaseRequisition?.attributes?.department_id?.name}
                    style={newStyles.textFieldBox}
                    inputProps={{
                      style: {
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: 'Plus Jakarta Display',
                      }
                    }}
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography style={{ marginLeft: '2rem' }}>Catalouge Items</Typography>
              <TableContainer component={Paper} style={newStyles.productsTable}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Product Id</TableCell>
                      <TableCell align="left">Product Name</TableCell>
                      <TableCell align="left">Quantity</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {this.state.showPurchaseRequisition?.attributes?.requested_items_purchase_requisition?.map((prod: any, index: number) => {
                      return (<TableRow key={prod?.id}>
                        <TableCell align="left">{prod?.id}</TableCell>
                        <TableCell align="left">{prod?.name}</TableCell>
                        <TableCell align="left">
                          <TextField
                            disabled={true}
                            inputProps={{
                              disabled: true,
                              readOnly: true,
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: "Plus Jakarta Display",
                              },
                            }}
                            required
                            value={prod?.quantity}
                            style={newStyles.rfpQtyTextFieldBoxSmall}
                            InputProps={{ inputProps: { min: 1 } }}
                            variant="outlined"
                            placeholder="Quantity"
                          />
                        </TableCell>
                      </TableRow>)
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box>
              <Typography style={{ marginLeft: '2rem' }}>Non Catalouge Items</Typography>
              <TableContainer component={Paper} style={newStyles.productsTable}>
                <Table >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Product Id</TableCell>
                      <TableCell align="left">Product Name</TableCell>
                      <TableCell align="left">Quantity</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {this.state.showPurchaseRequisition?.attributes?.non_catalogue_items?.map((prod: any, index: number) => {
                      return (<TableRow key={prod?.id}>
                        <TableCell align="left">{prod?.id}</TableCell>
                        <TableCell align="left">{prod?.name}</TableCell>
                        <TableCell align="left">
                          <TextField
                            disabled={true}
                            inputProps={{
                              disabled: true,
                              readOnly: true,
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: "Plus Jakarta Display",
                              },
                            }}
                            required
                            value={prod?.quantity}
                            style={newStyles.rfpQtyTextFieldBoxSmall}
                            InputProps={{ inputProps: { min: 1 } }}
                            variant="outlined"
                            placeholder="Quantity"
                          />
                        </TableCell>
                      </TableRow>)
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
              {this.prButtonRenderedRequisitionApproval()}
            </Box>
          </Dialog>




          {/* ===> Dashboard RFP Flow started */}

          <Dialog maxWidth="lg" style={newStyles.rfpShowDialog} open={this.state.openShowRFPModal}>
            <Typography style={newStyles.modalHeading} variant='h5'>RFP Number : {this.state.rfpData?.attributes?.rfp_number}</Typography>
            <Grid container>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Currency</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.currency}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.creation_date}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Due Date</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.due_date}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Location</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.location_setting?.adderss}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Desired Delivery Date</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.desired_delivery_date}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Email of Supplier</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.email_of_supplier}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Selected Existing Supplier</Typography>
                  <Typography style={newStyles.textFieldBox}>{this.state.rfpData?.attributes?.supplier?.supplier_name}</Typography>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>File Name</Typography>
                  <Typography style={newStyles.textFieldBox}><a href={`${this.state.rfpData?.attributes?.file}`} target="_blank" rel="noopener noreferrer external">{this.state.rfpData?.attributes?.file?.split('/').pop().trim()}</a></Typography>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Grid item md={12}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Selected Catelogue Products</Typography>
                  <TableContainer>
                    <Table>
                      {this.state.rfpData?.attributes?.requested_items?.map((data: any, index: number) => {
                        return <TableDisplay
                          key={data?.id}
                          className={"rfp_records"}
                          data={data}
                          index={index}
                          headerData={['Sr.No', 'Product Id', 'Product Name', 'Quantity']}
                          displayData={[index + 1, data?.id, data?.name, data?.quantity]}
                        />
                      })}
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Box>
            <Box marginTop={8}>
              {
                this.state.rfpData?.attributes?.rfp_non_catelogues?.length > 0 && <Grid item md={12}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Selected Non catelogue Products</Typography>
                    <TableContainer>
                      <Table>
                        {this.state.rfpData?.attributes?.rfp_non_catelogues?.map((data: any, index: number) => {
                          return <TableDisplay
                            key={data?.id}
                            className={"rfp_records"}
                            data={data}
                            index={index}
                            headerData={['Sr.No', 'Product Id', 'Product Name', 'Quantity']}
                            displayData={[index + 1, data?.id, data?.name, data?.quantity]}
                          />
                        })}
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              }

            </Box>
            <Box>
              <Button onClick={this.handleShowRFPCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
            </Box>
          </Dialog>

          {/* new edited rfp*/}

          <Dialog maxWidth="xl"
            PaperProps={{ style: { width: '96%', height: "100%" } }}
            style={newStyles.rfpDialog}
            open={this.state.openEditRFPModal}>
            <Box style={newStyles.rfpScreensContainer} >
              <Box style={newStyles.modalHeadingWrapper}>
                <Typography style={newStyles.rfpModalHeading} variant='h5'>{`RFP Number: ${this.state.editedExistingRfpNumber}`}</Typography>
                <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleEditedRfpTabsScreenClose} />
              </Box>
              <Box style={newStyles.rfpTabsScreenWrapper}>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    }
                  }}
                  style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                  value={this.state.editedRfpScreenActiveTabs}
                  onChange={this.handleEditedRfpScreenTabsChange}>
                  <Tab style={this.handleTabsActiveStyleChange(0)}
                    label="Details" />
                  <Tab style={this.handleTabsActiveStyleChange(1)}
                    label="Add items" />
                </Tabs>
              </Box>

              {/* screen 1 ***** */}
              <Box style={{ width: '100%' }}>
                {
                  this.state.editedRfpScreenActiveTabs === 0 && <Box>
                    <Grid container>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem} >
                          <Typography style={newStyles.modalLabel}>Currency</Typography>
                          <StyledSelect
                            id={'currency_select'}
                            variant="outlined"
                            label="Select Currency"
                            readOnly={
                              this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)
                            }
                            value={this.state.editedRfpCurrency}
                            onChange={this.handleEditedRfpCurrencyChange}
                            disableUnderline
                            displayEmpty
                            style={this.newStylesSelectBoxCondition()}
                            input={<Input />}
                          >
                            <MenuItem value="" disabled>Select Currency</MenuItem>
                            {this.state.currencyList?.map((currency: any) => {
                              return <MenuItem className="currency_items" key={currency?.iso} value={`${currency?.name} - ${currency?.currency}`}>{`${currency?.name} - ${currency?.currency}`}</MenuItem>
                            })}
                          </StyledSelect>
                          <Typography data-testid={"isEditRfpCurrencyValidationError"} style={newStyles.validationText}>
                            {this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpCurrency, this.state.editedRfpCurrencyRequiredError)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                          <StyledTextField
                            id='edited_creationDate_select'
                            required
                            type={this.editedTextDateTypeConditionCheckRendred(this.state.editedRfpCreationDateType)}
                            value={this.state.editedRfpCreationDate}
                            onChange={this.handleEditedRfpCreationDateChange}
                            onFocus={this.handleEditRfpCreationDateTypeFocus}
                            onBlur={this.handleEditRfpCreationDateTypeBlur}
                            disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            style={newStyles.textFieldBox}
                            variant="outlined"
                            placeholder="Enter Creation Date"
                            inputProps={{
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'Plus Jakarta Display'
                              }
                            }}
                            InputProps={{
                              inputProps: { min: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') },
                              disableUnderline: true,
                              endAdornment: <InputAdornment
                                style={newStyles.textFieldEndAdornment}
                                position="end">
                                <DateIcon />
                              </InputAdornment>,
                            }}
                          />
                          <Typography data-testid={"isEditRfpCreationDateValidationError"} style={newStyles.validationText}>
                            {this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpCreationDate, this.state.editedRfpCreationDateRequiredError)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Due Date</Typography>
                          <StyledTextField
                            required
                            type={this.editedTextDateTypeConditionCheckRendred(this.state.editedRfpDueDateType)}
                            value={this.state.editedRfpDueDate}
                            onChange={this.handleEditedRfpDueDateChange}
                            onFocus={this.handleEditRfpDueDateTypeFocus}
                            onBlur={this.handleEditRfpDueDateTypeBlur}
                            disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            style={newStyles.textFieldBox}
                            variant="outlined"
                            placeholder="Enter Due Date"
                            inputProps={{
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'Plus Jakarta Display'
                              }
                            }}
                            InputProps={{
                              inputProps: { min: this.state.editedRfpCreationDate },
                              disableUnderline: true,
                              endAdornment: <InputAdornment
                                style={newStyles.textFieldEndAdornment}
                                position="end">
                                <DateIcon />
                              </InputAdornment>,
                            }}
                          />
                          <Typography data-testid={"isEditRfpDueDateValidationError"} style={newStyles.validationText}>
                            {
                              this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpDueDate, this.state.editedRfpDueDateRequiredError)
                            }
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Desired Delivery Date</Typography>
                          <StyledTextField
                            required
                            disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            type={this.editedTextDateTypeConditionCheckRendred(this.state.editedRfpDesiredDeliveryDateType)}
                            value={this.state.editedRfpDesiredDeliveryDate}
                            onChange={this.handleEditedRfpDesiredDeliveryDateChange}
                            onFocus={this.handleEditRfpDesiredDeliveryDateTypeFocus}
                            onBlur={this.handleEditRfpDesiredDeliveryDateTypeBlur}
                            style={newStyles.textFieldBox}
                            variant="outlined"
                            placeholder="Enter Desired Delivery Date"
                            inputProps={{
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'Plus Jakarta Display'
                              }
                            }}
                            InputProps={{
                              inputProps: { min: this.state.editedRfpCreationDate },
                              disableUnderline: true,
                              endAdornment: <InputAdornment
                                style={newStyles.textFieldEndAdornment}
                                position="end">
                                <DateIcon />
                              </InputAdornment>,
                            }}
                          />
                          <Typography data-testid={"editedRfpDesiredDeliveryDateRequiredError"} style={newStyles.validationText}>
                            {this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpDesiredDeliveryDate, this.state.editedRfpDesiredDeliveryDateRequiredError)}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Location</Typography>
                          <StyledSelect
                            id={"rfp_location_select"}
                            MenuProps={
                              rfpMenuProps
                            }
                            variant="outlined"
                            label="Select Location"
                            value={this.state.editedRfpLocation}
                            onChange={this.handleEditedRfpLocationChange}
                            readOnly={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            disableUnderline
                            displayEmpty
                            style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.editedRfpLocation)}
                            input={<Input />}
                          >
                            <MenuItem value="" disabled>Select Location</MenuItem>
                            {this.state.locationDropdownList?.map((location: any, index: any) => {
                              return <MenuItem className={'rfp_location_items'} key={location?.id} value={location?.id}>{location?.attributes?.name_of_location}</MenuItem>
                            })}
                          </StyledSelect>
                          <Typography style={newStyles.validationText}>{this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpLocation, this.state.editedRfpLocationRequiredError)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Department</Typography>
                          <StyledSelect
                            disableUnderline
                            variant="outlined"
                            label="Select Department"
                            displayEmpty
                            MenuProps={
                              rfpMenuProps
                            }
                            value={this.state.editedRfpDepartment}
                            onChange={this.handleEditedRfpDepartmentChange}
                            readOnly={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.editedRfpDepartment)}
                            input={<Input />}
                          >
                            <MenuItem value="" disabled>Select Department</MenuItem>
                            {this.state.departmentList?.map((department: any) => {
                              return <MenuItem key={department?.id} value={department?.id}>{department?.name}</MenuItem>
                            })}
                          </StyledSelect>

                          <Typography style={newStyles.validationText}>{this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpDepartment, this.state.editedRfpDepartmentRequiredError)}</Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Suppliers</Typography>
                          <StyledSelect
                            disableUnderline
                            variant="outlined"
                            label="Select Existing Supplier"
                            value={this.state.editedRfpSelectExistingSupplier}
                            onChange={this.handleEditedRfpExistingSuppliersChange}
                            readOnly={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            displayEmpty
                            MenuProps={
                              rfpMenuProps
                            }
                            style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.editedRfpSelectExistingSupplier)}
                            input={<Input />}
                          >
                            <MenuItem value="" disabled>Select Existing Supplier</MenuItem>
                            {this.state.suppliersList?.filter((supp: any) => supp?.attributes?.supplier_name).map((supplier: any) => {
                              return <MenuItem key={supplier?.id} value={supplier?.id}>{supplier?.attributes?.supplier_name}</MenuItem>
                            })}
                          </StyledSelect>
                          <Typography style={newStyles.validationText}>
                            {this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpSelectExistingSupplier, this.state.editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={6} md={4} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={newStyles.rfpGridItem}>
                          <Typography style={newStyles.modalLabel}>Email of Supplier</Typography>
                          <StyledTextField
                            value={this.state.editedRfpEmailOfSupplier}
                            onChange={this.handleEditedRfpEmailOfSupplierChange}
                            style={newStyles.textFieldBox}
                            inputProps={{
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'Plus Jakarta Display'
                              }
                            }}
                            variant="outlined"
                            placeholder="Enter Email"
                          />
                          <Typography style={newStyles.validationText}>{
                            this.handleRfpValidEmailError(this.state.editedRfpEmailOfSupplierEmailPatternError)
                          }</Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={12} md={12} lg={4} style={{ marginBottom: '10px' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', textAlign: 'right', paddingRight: '20px', boxSizing: 'border-box' as 'border-box' }}>
                          <Typography style={this.handleRfpPdfStyleLableChange()}>{this.editedFileAttachmentRendered()}</Typography>
                          <Button
                            disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                            style={newStyles.attachmentButton}
                            startIcon={<AddSharpIcon style={newStyles.fileAttachmentButtonIcon} />}
                            variant="contained"
                            component="label">
                            <input
                              type="file"
                              hidden
                              accept={this.rfpFileAttachmentAcceptCondition(this.state.editedRfpFileAttachment)}
                              onChange={this.handleEditedRfpFileAttachmentChange}
                            />
                            Add Attachment
                          </Button>
                          <Typography style={newStyles.validationText}>
                            {this.handleDashboardFieldTextError(this.state.showIsEditRFPValidation, this.state.editedRfpFileAttachment, this.state.editedRfpFileAttachmentRequiredError)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                      <Button style={newStyles.modalButton} onClick={this.handleEditedRFPScreenFieldsOpenModal} variant="contained">Next</Button>
                      <Button onClick={this.handleEditedRFPProductsScreenCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                  </Box>
                }
              </Box>

              {/* screen 2 ***** */}
              <Box style={{ width: '100%' }}>
                {
                  this.state.editedRfpScreenActiveTabs === 1 && <Box>
                    <Box style={newStyles.rfpProductGridItem}>
                      <ToastMessageDisplay message={this.state.rfpUpdateMessage} handleClose={this.handleRfpUpdateSnackbarClose} />
                      <Typography style={newStyles.modalLabel}>Select Item</Typography>
                      <Autocomplete
                        disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                        multiple={false}
                        id="edit_rfp_products_select"
                        options={this.handleNewProductsListUpdation(this.state.editedRfpNonCatalougeCollectedItems)}
                        getOptionLabel={this.getRfpCatalougeItemLabel.bind(this)}
                        filterOptions={rfpFilterOptions}
                        style={this.addRfpProductsNewStylesSelectBoxLabelRendered(this.state.editedRfpCatelogItems)}
                        autoHighlight
                        onChange={this.handleEditedRfpProductsConditionalShow}
                        renderOption={this.handleRfpProductSelectChange.bind(this)}
                        renderInput={this.handleRfpProductSelectRender.bind(this)}

                      />
                      <Typography style={newStyles.validationText}>{this.handleDashboardFieldArrayListError(this.state.showIsEditRFPValidation, this.state.editedRfpCatelogItems, this.state.editedRfpExistingProductRequiredError)}</Typography>
                    </Box>
                    <Box>
                      <TableContainer component={Paper} style={newStyles.productsTable}>
                        <Table >
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Product Id</TableCell>
                              <TableCell align="left">Product Name</TableCell>
                              <TableCell align="left">Quantity</TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody >
                            {this.state.editedRfpCatelogItems?.map((prod: any, index: number) => {
                              return (<TableRow key={prod?.id}>
                                <TableCell className="editRfpCatalogItem" align="left">{prod?.id}</TableCell>
                                <TableCell className="editRfpCatalogItem" align="left">{this.editedRfpProductName(prod)}</TableCell>
                                <TableCell className="editRfpCatalogItem" align="left">
                                  <StyledTextField
                                    type={'number'}
                                    className='edited_rfp_catlog'
                                    disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                                    required
                                    id="rfpCataQuantity"
                                    value={prod?.quantity}
                                    onChange={(e: any) => this.handleEditedRfpCatelogQuantityChange(e, prod, index)}
                                    style={newStyles.rfpQtyTextFieldBoxSmall}
                                    inputProps={{
                                      style: {
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: 'Plus Jakarta Display'
                                      },
                                      // inputMode: "numeric",
                                      // pattern: "[0-9]*",
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="outlined"
                                    placeholder="Add Quantity"
                                  />
                                </TableCell>
                                <TableCell className="editRfpCatalogItem" align="left">{
                                  this.handleEditedProductsConditionalTextRendered(prod?.attributes?.is_non_catalague_item)
                                }</TableCell>
                                <TableCell className="editRfpCatalogItem" align="left">
                                  <Chip
                                    id="deleteChipIconRFP"
                                    disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                                    size="medium"
                                    onDelete={this.editedRfpCatelogRecDeleteHandler.bind(this, index)}
                                    style={{ width: '0px' }}
                                  />
                                </TableCell>
                              </TableRow>)
                            })}

                            {this.state.editedRfpNonCatelogItems?.map((prod: any, index: number) => {
                              return (<TableRow key={prod?.id}>
                                <TableCell className="editedRfpNonCata" align="left">{prod?.id}</TableCell>
                                <TableCell className="editedRfpNonCata" align="left">{this.editedRfpProductName(prod)}</TableCell>
                                <TableCell className="editedRfpNonCata" align="left">
                                  <StyledTextField
                                    disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                                    required
                                    id="rfpCataQuantity"
                                    value={prod?.quantity}
                                    onChange={(e: any) => {
                                      this.handleEditedRfpNonCatelogItemsQuantityChange(e, prod, index)
                                    }
                                    }
                                    style={newStyles.rfpQtyTextFieldBoxSmall}
                                    inputProps={{
                                      style: {
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: 'Plus Jakarta Display'
                                      }
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="outlined"
                                    placeholder="Add Quantity"
                                  />
                                </TableCell>
                                <TableCell className="editedRfpNonCata" align="left">{
                                  this.handleEditedProductsConditionalTextRenderedForTwo(prod?.attributes?.theInputIsNonCatalog, prod?.is_non_catalague_item)
                                }</TableCell>
                                <TableCell className="editedRfpNonCata" align="left">
                                  <Chip
                                    id="rfpNonCataChipDel"
                                    disabled={this.handleReadOnlyFields(this.state.isEditedRfpUpdationApiSuccessDone)}
                                    size="medium"
                                    onDelete={this.editedRfpNonCatelogItemsRecDelete.bind(this, index)}
                                    style={{ width: '0px' }}
                                  />
                                </TableCell>
                              </TableRow>)
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box>
                      <ToastMessageDisplay message={this.state.editedRfpEmailToSupplierSentOrNotErrorMessage} handleClose={this.handleEditedRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose} />
                    </Box>
                    <Box>
                      <ToastMessageDisplay message={this.state.editedRfpFinalPdfDownloadOrNotMessage} handleClose={this.handleEditedRfpFinalPdfDownloadOrNotMessageSnackbarClose} />
                    </Box>
                    {
                      this.handleopenEditedRfpPdfSupplierScreenModal()
                    }
                  </Box>
                }
              </Box>
            </Box>
          </Dialog>


          <Dialog maxWidth="xl" style={newStyles.rfpErrorDialog} open={this.state.openEditedRfpShowErrorModal}>
            <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
              <Typography style={{ color: 'red' }}>All fields are required **</Typography>
              <Typography>Please check Details and Add Items section again</Typography>
              <Box>
                <Button style={newStyles.errorModalButton} onClick={this.handleCloseEditedRfpErrorModal}>OK</Button>
              </Box>
            </Box>
          </Dialog>

          <Dialog
            open={this.state.isEditedRfpMailSendConfirmed}
            onClose={this.handleEditedRfpMailSendConfirmationCloseBar}
            aria-describedby="updated Rfp mail send confirmation"
          >
            <DialogTitle>{"Are you sure to send a updated mail?"}</DialogTitle>
            <DialogActions>
              <Button style={newStyles.rfpAlertBoxBtn} id="editedRfpRecordSupplierMailSend" onClick={this.handleEditedRfpRecordSupplierMailSend}>
                {this.isEditedRfpMailSendingOrNot()}
              </Button>
              <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handleEditedRfpMailSendConfirmationCloseBar}>No</Button>
            </DialogActions>
          </Dialog>

          <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openEditedRfpSupplierMailSeccessModal}>

            <Box style={{ textAlign: 'center', padding: '20px' }}>
              <Typography>An email with updated Request for Proposals : {this.state.editedExistingRfpNumber}</Typography>
              <Typography>has been successfully sent to the supplier</Typography>
              <Box>
                <Button onClick={this.handleEditedRfpSupplierEditedMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
              </Box>
            </Box>
          </Dialog>

          {/* ===> Dashboard RFP Flow Ended */}





          <Dialog maxWidth="xl" PaperProps={{ style: { width: "100%" } }} style={newStyles.dialog} open={this.state.openShowPurchaseOrderModal}>
            <Box style={newStyles.modalHeadingWrapper}>
              <Typography style={newStyles.modalHeading} variant="h5">
                Purchase Order Number : {this.state.showPurchaseOrder?.attributes?.purchase_order_number}
              </Typography>
              <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleShowPurchaseOrderCloseModal} />
            </Box>
            <Box style={newStyles.rfpTabsScreenWrapper}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  }
                }}
                style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                value={this.state.rfpCreateScreenActiveTabs}
                onChange={this.handlePoViewScreenTabsChange}>
                <Tab style={this.handleTabsPoViewActiveStyleChange(0)}
                  label="Details" />
                <Tab style={this.handleTabsPoViewActiveStyleChange(1)}
                  label="Items View" />
              </Tabs>
            </Box>
            <Grid container>
              {this.state.poViewScreenActiveTabs === 0 &&
                <>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Order Date</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.order_date}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Delivery Date</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.delivery_date}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Department</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.department?.name}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Budget</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.budget?.name}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Location</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.location}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Supplier</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.supplier?.data?.attributes?.supplier_name}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Shipping Address</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.shipping_address}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Billing Address</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.billing_address}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Payment Terms</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.supplier?.data?.attributes?.payment_terms?.data?.map((payment: any)=>{return payment?.attributes?.payment_term_name})}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Taxation</Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        disabled
                        value={this.state.showPurchaseOrder?.attributes?.taxation?.tax_name}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </>
              }
              {this.state.poViewScreenActiveTabs === 1 && <>
                <Grid item md={12}>
                  <TableContainer component={Paper} style={newStyles.productsTable}>
                    <Table >
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Product Id</TableCell>
                          <TableCell align="left">Product Name</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {this.state.showPurchaseOrder?.attributes?.product_purchase_order?.map((prod: any, index: number) => {
                          return (
                            <TableRow key={prod?.id}>
                              <TableCell align="left">{prod?.id}</TableCell>
                              <TableCell align="left">{prod?.name}</TableCell>
                              <TableCell align="left">
                                {prod?.quantity}
                              </TableCell>
                              <TableCell align="left">{prod?.product_price}</TableCell>
                            </TableRow>
                          )
                        })}
                        {this.state.showPurchaseOrder?.attributes?.non_catalogue_items?.map((prod: any, index: number) => {
                          return (
                            <TableRow key={prod?.id}>
                              <TableCell align="left">{prod?.id}</TableCell>
                              <TableCell align="left">{prod?.name}</TableCell>
                              <TableCell align="left">
                                {prod?.quantity}
                              </TableCell>
                              <TableCell align="left">{prod?.product_price}</TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item md={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Comment</Typography>
                    <StyledTextField
                      id={"po_shipping_address"}
                      disabled
                      value={this.state.showPurchaseOrder?.attributes?.comment}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: newStyles.inputPropsStyle
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { paddingRight: '98px' },
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
              </>}
            </Grid>
            <Box display={"flex"} justifyContent={"flex-end"}>
              {this.state.poViewScreenActiveTabs === 0 && <Button
                data-testid={"dash_pr_create_next_btn"}
                onClick={
                  this.handleOpenNextPurchaseModal.bind(this)
                }
                style={newStyles.modalButton}
                variant="contained"
              >
                Next
              </Button>}
              <Button
                onClick={this.handleShowPurchaseOrderCloseModal}
                style={newStyles.modalCancelButton}
                variant="contained"
              >
                Cancel
              </Button>
            </Box>
          </Dialog>

          <Dialog maxWidth="md" PaperProps={{ style: { width: '96%', height: '100%', maxWidth: '100%' } }} open={this.state.openEditPurchaseOrderModal}>
            <Box style={newStyles.modalHeadingWrapper}>
              <Typography style={newStyles.modalHeading} variant="h5">
                Purchase Order Number : {this.state.editedPoNumber}
              </Typography>
              <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleEditPurchaseOrderCloseModal} />
            </Box>
            <Box style={newStyles.rfpTabsScreenWrapper}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  }
                }}
                style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                value={this.state.rfpCreateScreenActiveTabs}
                onChange={this.handlePoEditScreenTabsChange}>
                <Tab style={this.handleTabsEditPoActiveStyleChange(0)}
                  label="Details" />
                <Tab style={this.handleTabsEditPoActiveStyleChange(1)}
                  label="Add items" />
              </Tabs>
            </Box>
            {
              this.state.poEditScreenActiveTabs === 0 &&
              <>
                <Grid container>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Order Date
                      </Typography>
                      <StyledTextField
                        required
                        type={"date"}
                        value={this.state.editedPoOrderDate}
                        onChange={this.handleEditPoOrderDateChange}
                        onFocus={this.handlePoOrderDateTypeFocus}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          inputProps: {
                            min: this.minEditedPoOrderDate(),
                            max: this.state.editedPoDeliveryDate
                          },
                        }}
                        variant="outlined"
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoOrderDate, this.state.editedPurchaseOrderOrderDateRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Delivery Date
                      </Typography>
                      <StyledTextField
                        required
                        type={"date"}
                        value={this.state.editedPoDeliveryDate}
                        onChange={this.handleEditPoDeliveryDateChange}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          inputProps: {
                            min: this.minEditedPoOrderDate(),
                          },
                        }}
                        variant="outlined"
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoDeliveryDate, this.state.editedPurchaseOrderDeliveryDateRequiredField)}
                      </Typography>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationErrorDate(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoOrderDate, this.state.editedPoDeliveryDate, this.state.editedPurchaseOrderDeliveryDateErrorFormat)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Department
                      </Typography>
                      <StyledSelect
                        id={"po_department_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.editedPoDepartment}
                        onChange={this.handleEditPoDepartmentChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Department</MenuItem>
                        {this.state.departmentList?.map((department: any) => {
                          return (
                            <MenuItem key={department?.id} value={department?.id}>
                              {department?.name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography data-testid="editPODepartmentValidationErrors" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoDepartment, this.state.editedPurchaseOrderDepartmentRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Select Budget
                      </Typography>
                      <StyledSelect
                        className={"edited_po_budget_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.editedPoBudget}
                        onChange={this.handleEditPoBudgetChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Budget</MenuItem>
                        {this.state.budgetList?.map((budget: any) => {
                          return (
                            <MenuItem key={budget?.id} value={budget?.id}>
                              {budget?.attributes?.name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography data-testid="editPOBudgetValidationErrors" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoBudget, this.state.editedPurchaseOrderBudgetRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Suppliers
                      </Typography>
                      <StyledSelect
                        id={"edit_po_supplier_change"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.editedPoSupplier}
                        onChange={this.handleEditPoSupplierChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Supplier</MenuItem>
                        {this.state.supplierList?.map((supplier: any) => {
                          return (
                            <MenuItem id="editPoSupplierName" key={supplier?.id} value={supplier.id}>
                              {supplier?.attributes?.supplier_name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography data-testid="editPOSupplierValidationErrors" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoSupplier, this.state.editedPurchaseOrderSupplierRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Payment Terms
                      </Typography>
                      <StyledSelect
                        variant="outlined"
                        multiple
                        label="Select Category"
                        value={this.state.editedPoPaymentTermList}
                        onChange={this.handleEditedSupplierPaymentTerms}
                        onClick={this.checkFetchPaymentTermsOrNot}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input style={{ flexWrap: "wrap" }} />}
                      >
                        <MenuItem value="" selected disabled>Select Payment Terms</MenuItem>
                        {
                          this.state.getPaymentTermListSupplier?.map((w: any) => {
                            return (<MenuItem key={w?.id} value={w?.id}>{w?.attributes?.payment_term_name}</MenuItem>)
                          })
                        }
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoPaymentTermList?.length===0, this.state.editedPurchaseOrderPaymentTermsRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Taxation
                      </Typography>
                      <StyledSelect
                        id={"po_taxation_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.editedPoTaxId}
                        onChange={this.handleEditPoTaxIdChange}
                        disableUnderline
                        displayEmpty
                        style={newStyles.selectBox}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Taxation</MenuItem>
                        {this.state.taxations?.map((tax: any) => {
                          return (
                            <MenuItem key={tax?.id} value={tax?.id}>
                              {tax?.attributes?.tax_name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoTaxId, this.state.editedPurchaseOrderTaxIdRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Location & Shipping Address
                      </Typography>
                      <StyledTextField
                        data-testid={"edit_po_shipping_address"}
                        required
                        disabled
                        value={this.state.editedPoShippingAddress}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                          endAdornment: (
                            <InputAdornment
                              id={"po_shipping_address_inputs"}
                              style={newStyles.textFieldEndAdornment}
                              position="end"
                            >
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        placeholder={this.poPlaceHolder()}
                      />
                      <Typography data-testid="editPOShippingAddressValidationErrors" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoShippingAddress, this.state.editedPurchaseOrderShippingAddressRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Location & Shipping Address Optional
                      </Typography>
                      <StyledTextField
                        id={"edit_po_shipping_address_optional"}
                        required
                        value={this.state.editedPoShippingAddressOptional}
                        onChange={this.handleEditPoShippingAddressOptionalChange}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' }
                        }}
                        variant="outlined"
                        placeholder={"Enter Location & Shipping Address Optional"}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Billing Address
                      </Typography>
                      <StyledTextField
                        required
                        data-testid="editPoBillingAddress"
                        value={this.state.editedPoBillingAddress}
                        onChange={this.handleEditPoBillingAddressChange}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px' },
                        }}
                        variant="outlined"
                        placeholder={"Enter the Billing Address"}
                      />
                      <Typography data-testid="editPOBillingAddressValidationErrors" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoBillingAddress, this.state.editedPurchaseOrderBillingAddressRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    data-testid={"dash_po_edit_next_btn"}
                    onClick={this.handleOpenNextPurchaseModal.bind(this)}
                    style={newStyles.modalButton}
                    variant="contained"
                  >
                    Next
                  </Button>
                  <Button
                    data-testid={"dash_po_cancel_btn"}
                    onClick={this.handleEditPurchaseOrderCloseModal}
                    style={newStyles.modalCancelButton}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            }

            {/* screen 2 ***** */}
            <Box style={{ width: '100%' }}>
              {
                this.state.poEditScreenActiveTabs === 1 && <Box>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Select Item</Typography>
                    <Autocomplete
                      multiple={false}
                      id="edit_po_products_select"
                      options={this.handleEditPoProductsListUpdation(this.state.editedPoNonCatlogItems)}
                      getOptionLabel={this.getEditedPoCatalougeItemLabel.bind(this)}
                      filterOptions={poFilterOptions}
                      style={newStyles.rfpItemsSelectBox}
                      onChange={(event, value) => { this.handleAllEditPoProductChange(event, value) }}
                      renderOption={this.handlePoProductSelectChange.bind(this)}
                      renderInput={this.handlePoProductSelectRender.bind(this)}
                    />
                  </Box>
                  <Typography style={newStyles.validationText}>
                    {this.editPOSelectProductFieldValidationErrorRendered(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoProductCatalouge, this.state.editedPurchaseOrderProductsRequiredField)}
                  </Typography>
                  <Box>
                    <TableContainer component={Paper} style={newStyles.productsTable}>
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Product Id</TableCell>
                            <TableCell align="left">Product Name</TableCell>
                            <TableCell align="left">Quantity</TableCell>
                            <TableCell align="left">Price</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {this.state.editedPoProductCatalouge?.filter((prod: any, index: number) => (this.checkOnlyShownProducts(prod))).map((prod, index) => {
                              console.log('prodbvole', prod);
                            return (
                              <TableRow key={prod?.id}>
                                <TableCell className="editedCatalougeItemPo" align="left">{prod?.id}</TableCell>
                                <TableCell className="editedCatalougeItemPo" align="left">{this.editedPoProductName(prod)}</TableCell>
                                <TableCell align="left" className="editedCatalougeItemPo">
                                  <StyledTextField
                                    required
                                    value={this.quantityHandleDefault(prod)}
                                    onChange={(e: any) => this.handleEditedPoProductsCatalougeQuantityChange(e, prod, index)}
                                    style={newStyles.rfpQtyTextFieldBoxSmall}
                                    inputProps={{
                                      style: newStyles.inputPropsStyle
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="outlined"
                                    placeholder="Add Quantity"
                                  />
                                </TableCell>
                                <TableCell className="editedCatalougeItemPo" align="left">{this.editedPoProductPrice(prod)}</TableCell>
                                <TableCell className="editedCatalougeItemPo" align="left">{
                                  this.handlePoConditionalTextRendered(prod?.attributes?.theInputIsNonCatalog)
                                }</TableCell>
                                <TableCell className="editedCatalougeItemPo" align="left">
                                  <Chip
                                    size="medium"
                                    id="editCataProductDelete"
                                    onDelete={this.editedPoProductDeleteHandler.bind(this, prod, index)}
                                    style={{ width: '0px' }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                          {this.state.editedPoProductNonCatalouge?.filter((prod: any, index: number) => (!prod?._destroy))?.map((prod: any, index: number) => {
                            console.log('prodbvole', prod);
                            
                            return (
                              <TableRow key={prod?.id}>
                                <TableCell align="left" className="editedPoProductNonCatalouge">{prod?.id}</TableCell>
                                <TableCell align="left" className="editedPoProductNonCatalouge">{this.editedPoProductName(prod)}</TableCell>
                                <TableCell align="left" className="editedPoProductNonCatalouge">
                                  <StyledTextField
                                    required
                                    value={this.quantityHandleDefault(prod)}
                                    onChange={(e: any) => this.handleEditedPoProductsNonCatalougeQuantityChange(e, prod, index)}
                                    style={newStyles.rfpQtyTextFieldBoxSmall}
                                    inputProps={{
                                      style: newStyles.inputPropsStyle
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="outlined"
                                    placeholder="Add Quantity"
                                  />

                                </TableCell>
                                <TableCell align="left" className="editedPoProductNonCatalouge">{this.editedPoProductPrice(prod)}</TableCell>
                                <TableCell align="left" className="editedPoProductNonCatalouge">{
                                  this.handlePoConditionalTextRendered(prod?.attributes?.theInputIsNonCatalog)
                                }</TableCell>
                                <TableCell align="left" className="editedPoProductNonCatalouge">
                                  <Chip
                                    size="medium"
                                    onDelete={(e) => this.handleEditedPoNonCataProductsDelete(e, prod, index)}
                                    style={{ width: '0px' }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box width="30%" mt="1rem" ml="1.5rem">
                      <Typography style={newStyles.modalLabel}>
                        Notes
                      </Typography>
                      <StyledTextField
                        required
                        type={"text"}
                        value={this.state.editedPoComment}
                        onChange={this.handleEditPoCommentChange}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: { width: "100%" }
                        }}
                        placeholder="Add Notes"
                        variant="outlined"
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsEditPurchaseOrderValidation, this.state.editedPoComment, this.state.editedPurchaseOrderCommentRequiredField)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <ToastMessageDisplay message={this.state.editedPoEmailToSupplierSentOrNotErrorMessage} handleClose={this.handleEditedPoEmailToSupplierSendOrNotErrorMessageSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.editedPoFinalPdfDownloadOrNotMessage} handleClose={this.handleEditedPoFinalPdfDownloadOrNotMessageSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.editedPoSuccessOrNotMessage} handleClose={this.handleEditedPoSuccessOrNotSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.supplierFoundOrNotMessage} handleClose={this.handleSupplierFoundNotSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.purchaseOrderDraftMessage} handleClose={this.handleSupplierFoundNotSnackbarClose} />
                  </Box>
                  {
                    this.handleOpenEditedPoPdfSupplierScreenModal()
                  }
                </Box>
              }

            </Box>
          </Dialog>

          <Dialog
            open={this.state.isEditedPoMailSendConfirmed}
            onClose={this.handleEditedPoMailSendConfirmationCloseBar}
            aria-describedby="Po mail send confirmation"
          >
            <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
            <DialogActions>
              <Button style={newStyles.rfpAlertBoxBtn} data-testid="editedPorecordMailSend" onClick={this.handleEditedPoRecordSupplierMailSend}>Yes</Button>
              <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handleEditedPoMailSendConfirmationCloseBar}>No</Button>
            </DialogActions>
          </Dialog>

          <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openEditedPoSupplierMailSeccessModal}>
            <Box style={{ textAlign: 'center', padding: '20px' }}>
              <Typography>An email with Purchase Order Number : {this.state.editedPoNumber}</Typography>
              <Typography>has been successfully sent to the supplier</Typography>
              <Box>
                <Button onClick={this.handleEditedPoSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog
            maxWidth="xl"
            PaperProps={{ style: { width: '96%', height: "100%" } }}
            open={this.state.openAddPurchaseOrderModal}
          >
            <ToastMessageDisplay
              message={this.state.poCreateMessage}
              handleClose={this.handlePoCreateSnackbarClose}
            />
            <Box style={newStyles.modalHeadingWrapper}>
              <Typography style={newStyles.modalHeading} variant="h5">
                New Purchase Order Number : {this.state.poNumberUnique}
              </Typography>
              <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handlePoTabsScreenClose} />
            </Box>
            <Box style={newStyles.rfpTabsScreenWrapper}>
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  }
                }}
                style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                value={this.state.rfpCreateScreenActiveTabs}
                onChange={this.handlePoScreenTabsChange}>
                <Tab style={this.handleTabsPoActiveStyleChange(0)}
                  label="Details" />
                <Tab style={this.handleTabsPoActiveStyleChange(1)}
                  label="Add items" />
              </Tabs>
              <Box>
                <ToastMessageDisplay
                  message={this.state.paymentTermsFetchMessage}
                  handleClose={this.handlePaymentTermsFetchSnackbarClose}
                />
              </Box>
            </Box>
            {
              this.state.poCreateScreenActiveTabs === 0 &&
              <>
                <Grid container>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Order Date
                      </Typography>
                      <StyledTextField
                        required
                        type={"date"}
                        value={this.state.poOrderDate}
                        onChange={this.handlePoOrderDateChange}
                        onFocus={this.handlePoOrderDateTypeFocus}
                        onBlur={this.handlePoOrderDateTypeBlur}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          inputProps: {
                            min: new Date()
                              .getFullYear()
                              .toString()
                              .padStart(4, "0") +
                              "-" +
                              (new Date().getMonth() + 1)
                                .toString()
                                .padStart(2, "0") +
                              "-" +
                              new Date()
                                .getDate()
                                .toString()
                                .padStart(2, "0"),
                            max: this.state.deliveryDate,
                          },
                        }}
                        variant="outlined"
                      />
                      <Typography data-testid="poOrderDateError" style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poOrderDate, this.state.purchaseOrderOrderDateRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Delivery Date
                      </Typography>
                      <StyledTextField
                        required
                        type={"date"}
                        value={this.state.deliveryDate}
                        onChange={this.handleDeliveryDateChange}
                        onFocus={this.handleDeliveryDateTypeFocus}
                        onBlur={this.handleDeliveryDateTypeBlur}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          inputProps: {
                            min: (this.state.poOrderDate || new Date()
                            .getFullYear()
                            .toString()
                            .padStart(4, "0") +
                            "-" +
                            (new Date().getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            (new Date()
                              .getDate())
                              .toString()
                              .padStart(2, "0")),
                          },
                        }}
                        variant="outlined"
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.deliveryDate, this.state.purchaseOrderDeliveryDateRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Department
                      </Typography>
                      <StyledSelect
                        id={"po_department_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.poDepartment}
                        onChange={this.handlePoDepartmentChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Department</MenuItem>
                        {this.state.departmentList?.map((department: any) => {
                          return (
                            <MenuItem key={department?.id} value={department?.id}>
                              {department?.name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poDepartment, this.state.purchaseOrderDepartmentRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Select Budget
                      </Typography>
                      <StyledSelect
                        className={"po_budget_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.poBudget}
                        onChange={this.handlePoBudgetChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Budget</MenuItem>
                        {this.state.budgetList?.map((budget: any) => {
                          return (
                            <MenuItem className="budgetListItems" key={budget?.id} value={budget?.id}>
                              {budget?.attributes?.name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poBudget, this.state.purchaseOrderBudgetRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Suppliers
                      </Typography>
                      <StyledSelect
                        id={"po_supplier_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.poSupplier}
                        onChange={this.handlePoSupplierChange}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Supplier</MenuItem>
                        {this.state.supplierList?.map((supplier: any) => {
                          return (
                            <MenuItem key={supplier?.id} value={supplier.id}>
                              {supplier?.attributes?.supplier_name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poSupplier, this.state.purchaseOrderSupplierRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Payment Terms
                      </Typography>
                      <StyledSelect
                        variant="outlined"
                        multiple
                        label="Select Category"
                        value={this.state.paymentTermsNameSuppliers}
                        onChange={this.handleSupplierPaymentTerms}
                        onClick={this.checkFetchPaymentTermsOrNot}
                        disableUnderline
                        displayEmpty
                        style={this.styledSelectStyle()}
                        input={<Input style={{ flexWrap: "wrap" }} />}
                      >
                        <MenuItem value="" selected disabled>Select Payment Terms</MenuItem>
                        {
                          this.state.getPaymentTermListSupplier?.map((w: any) => {
                            return (<MenuItem key={w?.id} value={w?.id}>{w?.attributes?.payment_term_name}</MenuItem>)
                          })
                        }
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.paymentTermsName, this.state.purchaseOrderPaymentTermsRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Taxation
                      </Typography>
                      <StyledSelect
                        id={"po_taxation_select"}
                        variant="outlined"
                        label="Select Product"
                        value={this.state.taxName}
                        onChange={this.handleTaxNameChange}
                        disableUnderline
                        displayEmpty
                        style={newStyles.selectBox}
                        input={<Input />}
                      >
                        <MenuItem value="" selected disabled>Select Taxation</MenuItem>
                        {this.state.taxations?.map((tax: any) => {
                          return (
                            <MenuItem key={tax?.id} value={tax?.id}>
                              {tax?.attributes?.tax_name}
                            </MenuItem>
                          );
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.taxName, this.state.purchaseOrderTaxationNameRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Location & Shipping Address
                      </Typography>
                      <StyledTextField
                        id={"po_shipping_address"}
                        required
                        disabled
                        value={this.state.poShippingAddress}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          disableUnderline: true,
                          style: { paddingRight: '98px', color: 'black' },
                          endAdornment: (
                            <InputAdornment
                              id={"po_shipping_address_inputs"}
                              style={newStyles.textFieldEndAdornment}
                              position="end"
                            >
                              {this.circularProgressShippingAddress()}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        placeholder={this.poPlaceHolder()}
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poShippingAddress, this.state.purchaseOrderShippingAddressRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Location & Shipping Address Optional
                        </Typography>
                        <StyledTextField
                          id={"po_shipping_address_optional"}
                          style={newStyles.textFieldBox}
                          value={this.state.poShippingAddressOptional}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          onChange={this.handlePoShippingAddressOptionalChange}
                          required
                          placeholder={"Enter the Location & Shipping Address Optional"}
                          InputProps={{
                            style: { paddingRight: '98px', color: 'black' },
                            disableUnderline: true,
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  <Grid item lg={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>
                        Billing Address
                      </Typography>
                      <StyledTextField
                        required
                        data-testid="poBillingAddress"
                        value={this.state.poBillingAddress}
                        onChange={this.handlePoBillingAddressChange}
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        InputProps={{
                          style: { paddingRight: '98px', color: 'black' },
                        }}
                        variant="outlined"
                        placeholder={"Enter the Billing Address"}
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poBillingAddress, this.state.purchaseOrderBillingAddressRequiredField)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    data-testid={"dash_pr_create_next_btn"}
                    onClick={
                      this.handleOpenNextPurchaseModal.bind(this)
                    }
                    style={newStyles.modalButton}
                    variant="contained"
                  >
                    Next
                  </Button>
                  <Button
                    data-testid={"dash_add_po_cancel_btn"}
                    onClick={this.handleAddPurchaseOrderCloseModal}
                    style={newStyles.modalCancelButton}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            }

            {/* screen 2 ***** */}
            <Box style={{ width: '100%' }}>
              {
                this.state.poCreateScreenActiveTabs === 1 && <Box>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Select Item</Typography>
                    <Autocomplete
                      multiple={false}
                      id="po_products_select"
                      options={this.handleNewPoProductsListUpdation(this.state.poNonCatalougeCollectedItem)}
                      getOptionLabel={this.getPoCatalougeItemLabel.bind(this)}
                      filterOptions={poFilterOptions}
                      style={newStyles.rfpItemsSelectBox}
                      onChange={this.handlePoDropdownProductsChange}
                      renderOption={this.handleNewPoProductSelectChange.bind(this)}
                      renderInput={this.handleNewPoProductSelectRender.bind(this)}
                    />
                    <Typography style={newStyles.productsValidationTxt}>{this.addRFPSelectProductFieldValidationErrorRendered(this.state.showIsCreatePurchaseOrderValidation, this.state.filteredPoProducts, this.state.purchaseOrderProductsRequiredField)}</Typography>
                  </Box>
                  <Box>
                    <TableContainer component={Paper} style={newStyles.productsTable}>
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Product Id</TableCell>
                            <TableCell align="left">Product Name</TableCell>
                            <TableCell align="left">Quantity</TableCell>
                            <TableCell align="left">Price</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {this.state.filteredPoProducts?.map((prod: any, index: number) => {
                            return (
                              <TableRow key={prod?.id}>
                                <TableCell className="filteredPo" align="left">{prod?.id}</TableCell>
                                <TableCell className="filteredPo" align="left">{prod?.attributes?.name}</TableCell>
                                <TableCell className="filteredPo" align="left">
                                  <StyledTextField
                                    required
                                    id="poProductsQuantityChange"
                                    value={this.quantityHandleDefault(prod)}
                                    onChange={(e: any) => this.handlePoProductsQuantityChange(e, prod, index)}
                                    style={newStyles.rfpQtyTextFieldBoxSmall}
                                    inputProps={{
                                      style: newStyles.inputPropsStyle
                                    }}
                                    InputProps={{ inputProps: { min: 1 } }}
                                    variant="outlined"
                                    placeholder="Add Quantity"
                                  />
                                </TableCell>
                                <TableCell className="filteredPo" align="left">{this.unitFilterPoPrice(prod)}</TableCell>
                                <TableCell className="filteredPo" align="left">{
                                  this.handlePoConditionalTextRendered(prod?.attributes?.theInputIsNonCatalog)
                                }</TableCell>
                                <TableCell className="filteredPo" align="left">
                                  <Chip
                                    id="filteredPoDelete"
                                    disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                                    size="medium"
                                    onDelete={this.CommonPoProductDelete.bind(this, index)}
                                    style={{ width: '0px' }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box width="30%" mt="1rem" ml="1.5rem">
                      <Typography style={newStyles.modalLabel}>
                        Notes
                      </Typography>
                      <StyledTextField
                        required
                        type={"text"}
                        value={this.state.poComment}
                        onChange={this.handlePoCommentChange}
                        style={this.styledSelectStyle()}
                        inputProps={{
                          style: { width: "100%" }
                        }}
                        placeholder="Add Notes"
                        variant="outlined"
                      />
                      <Typography style={newStyles.validationText}>
                        {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poComment, this.state.purchaseOrderCommentRequiredField)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.poEmailToSupplierSentOrNotErrorMessage} handleClose={this.handlePoEmailToSupplierSendOrNotErrorMessageSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.poFinalPdfDownloadOrNotMessage} handleClose={this.handlePoFinalPdfDownloadOrNotMessageSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.supplierFoundOrNotMessage} handleClose={this.handleSupplierFoundNotSnackbarClose} />
                  </Box>
                  <Box>
                    <ToastMessageDisplay message={this.state.purchaseOrderDraftMessage} handleClose={this.handlePurchaseOrderDraftSnackbarClose} />
                  </Box>
                  {
                    this.handleOpenPoPdfSupplierScreenModal()
                  }
                </Box>
              }
            </Box>
          </Dialog>

          <Dialog maxWidth="xl" style={newStyles.rfpErrorDialog} open={this.state.openCreatePoShowErrorModal}>
            <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
              <Typography style={{ color: 'red' }}>All fields are required **</Typography>
              <Typography>Please check Details and Add Items section again</Typography>
              <Box>
                <Button style={newStyles.errorModalButton} onClick={this.handleCloseCreatePoErrorModal}>OK</Button>
              </Box>
            </Box>
          </Dialog>

          <Dialog
            open={this.state.isPoMailSendConfirmed}
            onClose={this.handlePoMailSendConfirmationCloseBar}
            aria-describedby="Po mail send confirmation"
          >
            <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
            <DialogActions>
              <Button style={newStyles.rfpAlertBoxBtn} id="poSendSupplierMail" onClick={this.handlePoRecordSupplierMailSend}>Yes</Button>
              <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handlePoMailSendConfirmationCloseBar}>No</Button>
            </DialogActions>
          </Dialog>

          <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openPoSupplierMailSeccessModal}>
            <Box style={{ textAlign: 'center', padding: '20px' }}>
              <Typography>An email with Purchase Order : {this.state.poNumberUnique}</Typography>
              <Typography>has been successfully sent to the supplier</Typography>
              <Box>
                <Button onClick={this.handlePoSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
              </Box>
            </Box>
          </Dialog>

          <Dialog maxWidth="xl" PaperProps={{ style: { height: "100%" } }} open={this.state.openAddRequisitionModal} >
            <ToastMessageDisplay message={this.state.locationCreateMessagePr} handleClose={this.handleLocationPrCreateSnackbarClose} />
            <ToastMessageDisplay message={this.state.productsFoundOrNotMessage} handleClose={this.handleProductFoundNotSnackbarClose} />
            <ToastMessageDisplay message={this.state.purchaseRequisitionCreateMessage} handleClose={this.handlePurchaseRequisitionCreateSnackbarClose} />
            <Typography style={newStyles.modalHeading} variant='h5'>PR Number: {this.state.prNumberUnique}</Typography>
            <Grid container >
              <Box display={"flex"} width={"100%"} mx={'0rem'}>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                    <StyledTextField
                      required
                      type={"date"}
                      value={this.state.durationDate}
                      onFocus={this.handleDurationDateTypeFocus}
                      style={newStyles.textFieldBox}
                      onBlur={this.handleDurationDateTypeBlur}
                      onChange={this.handleDurationDateChange}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: 'Plus Jakarta Display',
                          color: this.addPRDatesColorStyleConditionalRendered(this.state.durationDateType),
                          backgroundColor: 'transparent',
                        }
                      }}
                      placeholder="Enter Creation Date"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: new Date()
                            .getFullYear()
                            .toString()
                            .padStart(4, "0") +
                            "-" +
                            (new Date().getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            new Date()
                              .getDate()
                              .toString()
                              .padStart(2, "0"),
                          max: this.state.deliveryDate,
                        },
                      }}
                    />
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.durationDate, this.state.durationDateRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Expected Delivery Date</Typography>
                    <StyledTextField
                      required
                      type={"date"}
                      value={this.state.deliveryDate}
                      onChange={this.handlePrDeliveryDateChange}
                      onBlur={this.handleDeliveryDateTypeBlur}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: 'Plus Jakarta Display',
                          color: this.addPRDatesColorStyleConditionalRendered(this.state.deliveryDateType),
                          backgroundColor: 'transparent',
                        }
                      }}
                      placeholder="Enter Delivery Date"
                      onFocus={this.handleDeliveryDateTypeFocus}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min: (this.state.durationDate||new Date()
                            .getFullYear()
                            .toString()
                            .padStart(4, "0") +
                            "-" +
                            (new Date().getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            (new Date()
                              .getDate())
                              .toString()
                              .padStart(2, "0")),
                        }
                      }}
                    />
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.deliveryDate, this.state.deliveryDateRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Location</Typography>
                    <StyledSelect
                      id={"create_location_select"}
                      MenuProps={{ autoFocus: false, maxHeight: '100px' }}
                      variant="outlined"
                      label="Select Product"
                      value={this.state.location}
                      onChange={this.handlePrLocationChange}
                      onClose={() => this.setState({ purchaseRequisitionSearchLocation: '' })}
                      disableUnderline
                      displayEmpty
                      style={this.addPRNewStylesSelectBoxLabelRendered(this.state.location)}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>Select Location</MenuItem>
                      {this.state.locationDropdownList?.map((location: any, index: any) => {
                        return <MenuItem className={'location_items'} key={location?.id} value={location?.id}>{location?.attributes?.name_of_location}</MenuItem>
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.location, this.state.locationRequiredError)}</Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Department</Typography>
                    <StyledSelect
                      id={"pr_create_department_select"}
                      variant="outlined"
                      label="Select Product"
                      value={this.state.department}
                      onChange={this.handleDepartmentChange}
                      disableUnderline
                      displayEmpty
                      style={this.addPRNewStylesSelectBoxLabelRendered(this.state.department)}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>Select Department</MenuItem>
                      {this.state.departmentList?.map((department: any) => {
                        return <MenuItem className="department_items" key={department?.id} value={department?.id}>{department?.name}</MenuItem>
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.department, this.state.departmentRequiredError)}</Typography>
                  </Box>
                </Grid>
              </Box>
              <Grid item lg={3} style={{ marginLeft: '0rem' }}>
                <Box style={newStyles.gridItem}>
                  <Typography style={newStyles.modalLabel}>Catalouge Items</Typography>
                  <Autocomplete
                    multiple={false}
                    id="filter-demo"
                    options={this.getOptionsList()}
                    getOptionLabel={this.getCatalougeItemLabel.bind(this)}
                    filterOptions={filterOptions}
                    style={this.addPRNewStylesSelectBoxLabelRenderedForProducts(this.state.prProduct)}
                    onChange={this.handleProductsChange}
                    renderOption={this.handleFilterDemoSelectChange.bind(this)}
                    renderInput={this.handleFilterDemoSelectRender.bind(this)}
                  />
                  <Typography data-testid={'product_required_error'} style={newStyles.validationText}>{this.addUpdatePRProductsFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.prProduct, this.state.productsRequiredError)}</Typography>
                </Box>
              </Grid>
              <TableContainer component={Paper} style={{
                boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                margin: '20px',
                border: '2px solid lightgrey',
                borderRadius: '15px',
                width: '100%',
                marginLeft: '2.5rem',
                marginTop: '1rem',
                marginBottom: '1rem',
                marginRight: '1.5rem',
              }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}>Product Id</TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}>Product Name</TableCell>
                      <TableCell align="left" style={{ width: '10%', padding: '16px 15rem 25px 30px' }}>Quantity</TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}></TableCell>
                      <TableCell align="left" style={{ width: '100px', padding: '16px 15rem 25px 30px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflowY: "scroll" }}>
                    {this.state.prProduct?.map((pro: any, index: number) => {
                      return (<TableRow key={pro?.id}>
                        <TableCell className="tableCellPrProduct" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{pro?.attributes?.item_id}</TableCell>
                        <TableCell className="tableCellPrProduct" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{pro?.attributes?.name}</TableCell>
                        <TableCell className="tableCellPrProduct" align="left" style={{ width: '10%', padding: '16px 65rem 25px 30px;', border: "none", outline: "none", borderBottom: '1px solid lightgrey' }}><TextField id="tableCellPrQuantity" value={this.quantityHandleDefault(pro)} type="text" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }}
                          onChange={(e) => this.handlePrQuantityChange(e, pro, index)} style={{
                            border: '2px solid rgb(128 128 128 / 20%)',
                            borderRadius: '10px',
                            padding: '10px 15px',
                            width: '80px',
                          }} />
                        </TableCell>
                        <TableCell className="tableCellPrProduct" align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.prProductNameRender(pro)}</TableCell>
                        <TableCell className="tableCellPrProduct" align="left" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "25px", padding: '2px 80px', height: '58px', }}>
                          <Chip
                            size="medium"
                            onDelete={this.prTableCellRemoveHandler.bind(this, index)}
                            style={{ width: '0px' }}
                          /></TableCell>
                      </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                <Button
                  data-testid={"createPRBtn"}
                  disabled={this.addPRBtnConditionalDisabledDuringLoadingState()}
                  onClick={this.handleCreatePurchaseRequisition}
                  style={newStyles.modalButton}
                  variant="contained">
                  {this.addPRBtnConditionalTextRendered()}
                </Button>
                <Button
                  onClick={this.handleAddRequisitionCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained">
                  Cancel
                </Button>
              </Box>
              <Box>
              </Box>
            </Grid>

          </Dialog>
        </Box>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    },
    button: {
      textTransform: 'none',
    }
  },

});

const newStyles = {
  inputPropsStyle: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Plus Jakarta Display'
  },
  productsValidationTxt: {
    maxWidth: '328px',
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    padding: '6px 0px',
    color: 'rgba(255, 0, 0, 1)',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  mainContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(242, 242, 236, 1)',
    width: '100%',
    flex: '1',
    alignItems: "flex-start",
    boxSizing: 'border-box' as 'border-box',
  },
  headerContainer: {
    display: 'flex',
    displayDirection: 'column',
    flex: '1',
    position: 'relative' as 'relative',
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    height: '120px',
    width: '100vw',
    boxSizing: 'border-box' as 'border-box',
  },
  logoImg: {
    width: '129.63px',
    height: '40px',
    paddingLeft: '20px',
    paddingTop: '40px',
  },
  avatarContainer: {
    display: 'flex',
    displayDirection: 'column',
    //width: '76px',
    height: '40px',
    //marginLeft: 'auto',
    paddingRight: '20px',
    paddingTop: '40px',
    justifyContent: 'baseline',
  },
  avatar: {
    color: 'white',
    backgroundColor: 'blue',
    flex: '1',
    //paddingLeft: '40px',
  },
  avatarDropdownMenu: {
    margin: 0,
    paddingLeft: '50px',
    flex: '1',
  },
  tabsContainer: {
    position: 'relative' as 'relative',
    backgroundColor: '#F8F8F5',
    margin: 'auto',
    height: '96px',
    width: '100vw',
    boxShadow: '#F0F0ED 0px 60px 60px -80px inset',
    boxSizing: 'border-box' as 'border-box',
  },
  tabMenu: {
    //height: '29px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '24',
    fontWeight: 400,
    paddingTop: '35px',
    marginLeft: '40px',
    lineSpacing: '29',
    letterSpacing: '-0.5',
  },
  menuListContainer: {
    position: 'relative' as 'relative',
    backgroundColor: '#FFFFFF',
    height: '86px',
    width: '96vw',
    borderRadius: '15px',
    margin: 'auto',
    marginTop: '35px',
    display: 'flex',
    //textAlign: 'left' as 'left',
    boxSizing: 'border-box' as 'border-box',
  },
  menuListItems: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    paddingTop: '30px',
    lineSpacing: '19',
    letterSpacing: '-0.33',
    marginRight: '30px',
    justifyContent: 'space-between',
  },
  dropDown: {
    minWidth: '328px',
    maxHeight: '56px',
    marginLeft: '20px',
    marginTop: '15px',
    backgroundColor: '#F2F2EC',
    fontSize: '16',
    margin: 'dense',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  dropDownItems: {
    lineSpacing: '19',
    letterSpacing: '-0.33',
    fontSize: '16',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  divider: {
    width: '1px',
    height: '30px',
    marginLeft: '70px',
    marginTop: '28px',
  },
  tableContainer: {
    position: 'relative' as 'relative',
    backgroundColor: '#FFFFFF',
    //height: '443px',
    width: '96%',
    borderRadius: '15px',
    margin: 'auto',
    marginBottom: '50px',
    marginTop: '25px',
    paddingTop: '50px',
    paddingBottom: '50px',
    paddingLeft: '20px',
    paddingRight: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  tableHeaderRows: {
    lineSpacing: '17',
    letterSpacing: '-0.29',
    fontSize: '14',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  tableBodyRows: {
    lineSpacing: '19',
    letterSpacing: '0',
    fontSize: '16',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  iconColumn: {
    whiteSpace: 'nowrap' as 'nowrap',
  },
  icons: {
    fontSize: 'medium',
    paddingRight: '10px',
    cursor: 'pointer',
  },
  button: {
    position: 'relative' as 'relative',
    width: '328px',
    height: '56px',
    marginLeft: '81vw',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  createButton1: {
    marginLeft: '10px',
    position: 'relative' as 'relative',
    width: '289px',
    height: '56px',
    marginRight: '2%',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  createButton: {
    justifyContent: 'flexEnd',
    marginLeft: 'auto',
    marginRight: '20px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    marginTop: '15px'
  },
  createFormButton: {
    justifyContent: 'flexEnd',
    position: 'relative' as 'relative',
    width: '180px',
    height: '40px',
    marginLeft: 'auto',
    marginRight: '20px',
    marginTop: "25px",
    float: 'right' as 'right',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    justifySelf: 'flexEnd',
    alignContent: 'flexEnd',
  },
  createButtonIcon: {
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    marginRight: '70px',
    boxSizing: 'border-box' as 'border-box',
  },
  fileAttachmentButtonIcon: {
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    color: '#FD6533',
    textAlign: 'center' as 'center',
    fontWeight: 600,
    boxSizing: 'border-box' as 'border-box',
  },
  createLongTextButtonIcon: {
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    marginRight: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  modalButton: {
    position: 'relative' as 'relative',
    width: '160px',
    height: '56px',
    margin: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  errorModalButton: {
    position: 'relative' as 'relative',
    width: '100px',
    height: '45px',
    margin: 'auto 0px',
    marginTop: '15px',
    marginBottom: '15px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  modalCancelButton: {
    position: 'relative' as 'relative',
    width: '160px',
    height: '56px',
    margin: '20px',
    opacity: '1',
    backgroundColor: '#FAEBE6',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: '#FD6533',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  modalApproveButton: {
    position: 'relative' as 'relative',
    width: '160px',
    height: '56px',
    margin: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: 'black',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  attachmentButton: {
    width: '210px',
    height: '56px',
    opacity: '1',
    backgroundColor: '#FAEBE6',
    borderRadius: '10px',
    justifyContent: 'center',
    boxSizing: 'border-box' as 'border-box',
    includeFontPadding: false,
    padding: '0',
    color: '#FD6533',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
  },
  buttonText: {
    position: 'absolute',
    width: '104',
    height: '16',
    marginLeft: '16',
    marginTop: '14',
    opacity: '1',
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'normal',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    boxSizing: 'border-box' as 'border-box',
  },
  dialog: {
    margin: '0 auto',
    width: '1360px',
    boxSizing: 'border-box' as 'border-box',
  },
  errorDialog: {
    padding: '100px',
    width: '1200px',
    marginTop: '100px',
    marginBottom: '100px',
  },
  errorBox: {
    padding: '0 30px 0 30px',
    marginTop: '30px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,

  },
  textFieldBox: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    minHeight: '60px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    marginBottom: '10px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  textFieldBoxSmall: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    width: '8rem',
    minHeight: '60px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    marginBottom: '10px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  DateBox: {
    marginTop: '15px',
    backgroundColor: '#FFFFFF',
    width: '400px',
    minHeight: '60px',
    borderRadius: '10px',
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    //marginLeft: '0px',
    //marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  modalHeading: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '24',
    fontWeight: 600,
    padding: '20px',
    marginBottom: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  gridItem: {
    paddingLeft: '40px',
    paddingRight: '40px',
    paddingBottom: '20px',
  },
  gridItemLabel: {
    marginBottom: '10px',
    fontFamily: 'Plus Jakarta Display',
  },
  selectBox: {
    //backgroundColor: '#F2F2EC', 
    width: '100%',
    minHeight: '56px',
    borderRadius: '10px',
    border: '#B1B1AE 0.005px solid',
    marginBottom: '10px',
    //paddingLeft: '7px',
    color: 'Black',
    boxSizing: 'border-box' as 'border-box',
  },
  //   selectBoxLabel: {
  //     fontFamily: 'Plus Jakarta Display',
  //     color: '#B1B1AE',
  //     backgroundColor: '#F2F2EC', 
  //     minHeight: '60px', 
  //     borderRadius: '10px',
  //     paddingLeft: '7px',
  //     boxSizing: 'border-box' as 'border-box',
  //   },
  textFieldEndAdornment: {
    color: '#B1B1AE',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 300,
  },
  selectBoxLabel: {
    color: 'black',
    width: '100%',
    minHeight: '56px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    border: ' 0.5px  solid #B1B1AE',
    marginBottom: '10px',
    boxSizing: 'border-box' as 'border-box',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    opacity: '1',
  },
  modalLabel: {
    //zIndex: 3,
    position: 'relative' as 'relative',
    // left: 0px;
    // top: 0px;
    bottom: '10px',
    //width: '101px',
    height: '15px',
    color: '#808080',
    fontFamily: "Plus Jakarta Display",
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '15px',
    // marginBottom: '10px',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    // marginLeft: '30px',
    //justifyContent: 'flexEnd',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(255, 0, 0, 1)',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  termsHeadingBottom: {
    //width: '138px',
    height: '22px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.375px',
    marginTop: '5px',
  },
  termsHeadingTop: {
    //width: '138px',
    height: '22px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '-0.375px',
    //marginTop: '0px',
  },
  termsBodyTop: {
    height: '17px',
    color: '#808080',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '-0.29166666px',
    marginTop: '15px',
    marginBottom: '20px',
  },
  termsBodyBottom: {
    height: '17px',
    color: '#808080',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: '-0.29166666px',
    marginTop: '15px',
    marginBottom: '15px',
  },
  productText: {
    //position: 'relative' as 'relative',
    marginTop: '30px',
    marginLeft: '25px',
    //top: '840px',
    width: '82px',
    height: '24px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.41666666px',
  },
  cursorPointer: {
    cursor: 'pointer',
    marginRight: "20px"
  },



  // rfp styles
  rfpShowDialog: {
    margin: '0 auto',
    //height: '431px',
    width: '85%',
    boxSizing: 'border-box' as 'border-box',
  },

  rfpDialog: {
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpScreensContainer: {
    position: 'relative' as 'relative',
    height: '500px',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box' as 'border-box',
  },
  modalHeadingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rfpModalHeading: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '24',
    fontWeight: 600,
    padding: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  modalClose: {
    padding: '20px',
    cursor: 'pointer',
  },
  rfpTabsScreenWrapper: {
    padding: '20px',
    marginBottom: '25px',
  },
  tabsActiveChange: {
    backgroundColor: 'rgba(255, 255, 0, 1)',
    color: 'black',
    fontWeight: 600,
    borderRadius: '15px',
  },
  tabsDefaultStyle: {
    backgroundColor: 'lightgrey',
  },
  fileAttachLable: {
    marginTop: '8px',
    color: '#808080',
    fontFamily: "Plus Jakarta Display",
    fontSize: '12px',
    fontWeight: 400,
  },
  rfpItemsSelectBox: {
    color: '#B1B1AE',
    maxWidth: '328px',
    maxHeight: '56px',
    borderRadius: '10px',
    border: '#B1B1AE 0.005px solid',
    boxSizing: 'border-box' as 'border-box',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 350,
  },
  rfpItemsSelectBoxElse: {
    color: '#B1B1AE',
    maxWidth: '328px',
    maxHeight: '56px',
    marginBottom: '10px',
    border: '#B1B1AE 0.005px solid',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
  },
  productsTable: {
    width: '96%',
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
    border: '1px solid lightgrey',
    borderRadius: '15px',
    margin: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpQtyTextFieldBoxSmall: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    width: '200px',
    minHeight: '40px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },

  rfpScreenBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpAlertBoxBtn: {
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '8px',
    justifyContent: 'center',
    padding: '2px',
  },
  emailScreenDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpErrorDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  rfpProductGridItem: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  rfpModalButton: {
    position: 'relative' as 'relative',
    width: '170px',
    height: '56px',
    margin: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    justifyContent: 'center',
    includeFontPadding: false,
    padding: '4px',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center' as 'center',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    boxSizing: 'border-box' as 'border-box',
  },
  activeMenuListItems: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    color: 'black',
    fontWeight: 500,
    paddingTop: '30px',
    lineSpacing: '19',
    letterSpacing: '-0.33',
    marginRight: '30px',
    justifyContent: 'space-between',
  },
  rfpGridItem: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },


  rfpPdfLabel: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },
  rfpPdfLabelHighlight: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "black",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    lineHeight: "15px",
  },





}
// Customizable Area End