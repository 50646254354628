import React from "react";

// Customizable Area Start
import { 
  Paper,
  Container, 
  Box, 
  TextField,
  Input,  
  Button,
  InputLabel,
  Typography, 
  InputAdornment, 
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock'
import Link from '@material-ui/core/Link'
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import PhoneIcon from '@material-ui/icons/Phone';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PeopleIcon from '@material-ui/icons/People';
//import MonitorIcon from '@material-ui/icons/Monitor';
import AdjustIcon from '@material-ui/icons/Adjust';
import AirplayIcon from '@material-ui/icons/Airplay';
import { imgLogo } from "../../email-account-login/src/assets";

const StyledTextField = withStyles({
  root: {
    //fontFamily: 'PlusJakartaDisplay-Regular',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent",
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      fontWeight: 400,
    }
  }
})((props: any) => <TextField {...props} />);

// Customizable Area End

import EmailAccountLoginController, {
    Props, 
    configJSON
  } from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    //const { navigation } = this.props;
    return (
      // Customizable Area Start
        <Box style={newStyles.mainContainer}>
          <Box style={newStyles.imageContainer}>
            <img src={imgLogo} style={newStyles.image}/>
          </Box>
          <Box>
            {this.state.showLogin && <Box style={newStyles.contentContainer}>
              <StyledTextField 
                  testID="txtInputEmail"
                  required
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.email} 
                  onChange={this.handlePhoneOrEmailChange}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.email==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><PersonIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderPhoneOrEmail}
              />
              <Typography 
                style={newStyles.validationText}>
                  {this.state.email==="" && this.state.showLoginValidation ? this.state.requiredEmailFieldError : ""}
              </Typography>
              <Typography 
                style={newStyles.validationText}>
                  {this.state.email!=="" && !this.state.email.match(/^\d+$/) && !this.state.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) && this.state.emailPatternError}
              </Typography>
              <StyledTextField
                  required
                  test-ID="txtInputPassword"
                  style={styles.textFieldBox}
                  //InputLabelProps={{style : {color : 'black'}}} 
                  inputProps={{style: {fontSize: 14,
                                      fontFamily: 'Plus Jakarta Display'
                  }}}
                  type={this.state.inputTypePassword==="password" ? "password" : "text"}
                  placeholder={configJSON.placeHolderPassword} 
                  value={this.state.password} 
                  variant="filled" 
                  onChange={this.handlePasswordChange}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: 
                      <InputAdornment 
                        style={this.state.password==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                        position="start">
                          <LockIcon/>
                      </InputAdornment>,
                    endAdornment: 
                    <InputAdornment 
                      test-Id="btnPasswordShowHide"
                      style={styles.textFieldSuffixIcon}
                      position="end" 
                      onClick={this.handlePasswordVisibilityIcon}>
                        {this.state.inputTypePassword==="password" ? <Visibility/> : <VisibilityOff/>}
                    </InputAdornment>
                  
                  }}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.password==="" && this.state.showLoginValidation ? this.state.requiredPasswordFieldError : ""}
              </Typography>
              <Typography style={styles.validationText}><Button test-ID="btnForgotPassword" style={newStyles.forgotPasswordButton} href="/ForgotPassword">Forgot Password</Button></Typography>
              <Button test-ID="btnEmailLogIn" style={styles.button} onClick={this.handleLogin} variant="contained">{configJSON.btnTxtLogin}</Button>
              <Typography style={newStyles.signUpText}>Don't have an account? <Button style={newStyles.signUp} href="/EmailAccountRegistration">Sign Up</Button></Typography>
            </Box>}

            {this.state.showProfileCreation && <Box style={newStyles.contentContainer}>
              {/* <Box onClick={this.handleGoBackToSignUp} style={newStyles.backWithArrow}>
                  <ArrowBackIcon style={newStyles.backIcon}/>
                  <Typography style={newStyles.backText}>Back</Typography> 
              </Box> */}
              <Box onClick={this.handleGoBackToLogin} style={newStyles.backWithArrow}>
                  <ArrowBackIcon style={newStyles.backIcon}/>
                  <Typography style={newStyles.backText}>Back</Typography> 
              </Box>
              <StyledTextField 
                  data-test-id="txtInputPosition"
                  required
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.position} 
                  onChange={this.handleChangePosition}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.position==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><PersonIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderPosition}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.position==="" && this.state.profileValidation ? this.state.requiredPositionFieldError : ""}
              </Typography>

              <StyledTextField 
                  required
                  data-test-id="txtInputOrganizationSize"
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.organizationSize} 
                  onChange={this.handleOrganizationSize}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.organizationSize==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><PeopleIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderOrganizationSize}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.organizationSize==="" && this.state.profileValidation ? this.state.requiredOrganizationSizeFieldError : ""}
              </Typography>
          
              <StyledTextField 
                  required
                  data-test-id="txtInputNoOfPeople"
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.noOfPeopleAccess} 
                  onChange={this.handleNoOfPeopleAccess}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.noOfPeopleAccess==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><PeopleIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderNoOfPeopleAccess}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.noOfPeopleAccess==="" && this.state.profileValidation ? this.state.requiredNoOfPeopleAccessFieldError : ""}
              </Typography>
              <Typography
                style={newStyles.validationText}>
                  {this.state.noOfPeopleAccess!=="" && !this.state.noOfPeopleAccess.match(/^\d+$/) && "Enter a number"}
              </Typography>

              <StyledTextField 
                  required
                  data-test-id="txtInputGoal"
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.goal} 
                  onChange={this.handleGoal}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.organizationSize==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><AdjustIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderGoal}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.goal==="" && this.state.profileValidation ? this.state.requiredGoalFieldError : ""}
              </Typography>

              <StyledTextField 
                  required
                  data-test-id="txtInputERPSoftware"
                  style={styles.textFieldBox}
                  // InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
                  inputProps={{style: {fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                  }}}
                  variant="filled" 
                  value={this.state.ERPSoftware} 
                  onChange={this.handleERPSoftware}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment style={this.state.organizationSize==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><AirplayIcon/></InputAdornment>,
                  }}
                  placeholder={configJSON.placeHolderERPSoftware}
              />
              <Typography
                style={newStyles.validationText}>
                  {this.state.ERPSoftware==="" && this.state.profileValidation ? this.state.requiredERPSoftwareFieldError : ""}
              </Typography>
              
              <Button data-test-id="btnViewProfile" onClick={this.handleViewProfile} style={styles.button} variant="contained">{configJSON.btnTextNext}</Button>
            </Box>}

            {this.state.showProfileDetails && <Box style={newStyles.contentContainer}>
              <Box data-test-id="btnGoBack" onClick={this.handleGoBackToProfileCreation} style={newStyles.backWithArrowProfileDetails}>
                  <ArrowBackIcon style={newStyles.backIcon}/>
                  <Typography style={newStyles.backText}>Back</Typography> 
              </Box>
              <TableContainer>
                <Table style={newStyles.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.tableDataName}</TableCell>
                      <TableCell data-testid={"profileNameShow"} style={newStyles.dataCell}>{this.state.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.tableDataEmail}</TableCell>
                      <TableCell data-testid={"profileEmailShow"} style={newStyles.dataCell}>{this.state.emailFromLogin}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.tableDataPhone}</TableCell>
                      <TableCell data-testid={"profilePhoneShow"} style={newStyles.dataCell}>{this.state.phoneNumberFromLogin}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.positionInCompany}</TableCell>
                      {/* <TableCell style={newStyles.dataCell}>{this.state.selectedPosition}</TableCell> */}
                      <TableCell data-testid={"profilePositionShow"} style={newStyles.dataCell}>{this.state.position}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.organizationSize}</TableCell>
                      <TableCell data-testid={"profileOrgSizeShow"} style={newStyles.dataCell}>{this.state.organizationSize}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.tableDataNoOfPeopleAccess}</TableCell>
                      <TableCell data-testid={"profileNoOfPeopleShow"} style={newStyles.dataCell}>{this.state.noOfPeopleAccess}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.tableDataGoal}</TableCell>
                      <TableCell data-testid={"profileGoalShow"} style={newStyles.dataCell}>{this.state.goal}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={newStyles.placeholderCell}>{configJSON.ERPSoftware}</TableCell>
                      <TableCell data-testid={"profileERPSoftwareShow"} style={newStyles.dataCell}>{this.state.ERPSoftware}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Button data-testid={"btnSubmitProfileNext2"} onClick={this.handleSubmitProfile} style={newStyles.tableButton} variant="contained">{configJSON.btnTextSubmit}</Button>
            </Box>}
          </Box>
        </Box>   
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
  },
  palette: {
    primary: {
      // main: '#fff',
      // contrastText: '#fff',
      main: '#FFFF00',
    },
  },
});

const webStyle = {
  button: {
    color: '#ffff'
  },
  mainWrapper: {
    display: 'flex', 
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column', 
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  buttonStyle: { 
    width: '100%',
    height: '45px',  
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)', 
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
};

const styles = {
  container: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    bgColor: '#F2F2EC',
  },
  box: {
    backgroundColor: '#F2F2EC',
  },
  selectBox: {
    backgroundColor: '#F2F2EC', 
    borderRadius: '10px',
    paddingLeft: '7px',
    minHeight: '60px', 
  },
  selectBoxLabel: {
    minHeight: '60px', 
    paddingLeft: '7px',
    color: '#B1B1AE',
    borderRadius: '10px',
    backgroundColor: '#F2F2EC', 
  },
  textFieldBox: {
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    backgroundColor: '#F2F2EC', 
    width: '328px',
    minHeight: '56px',
    borderRadius: '10px',
    // width: '224px',
    // height: '24px',
    //marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: '400',
    boxSizing: 'border-box' as 'border-box',
  },
  button: {
    backgroundColor: '#FFFF00', 
    width: '328px',
    height: '56px', 
    padding: '5px', 
    margin: 'auto',
    marginTop: '16px',  
    //marginLeft: '0px',
    marginBottom: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    //marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic' ,
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  textFieldIconInitially: {
    fontSize: 'small', 
    paddingRight: '12px',
    color: 'black',
  },
  textFieldIcon: {
    fontSize: 'small', 
    paddingRight: '12px',
  },
  textFieldSuffixIcon: {
    fontSize: 'small',
    paddingTop: '12px',
  },
}

const newStyles = {
  mainContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(242, 242, 236, 1)',
    width: '100%',
    top: '50px',
    //width: '0 auto',
    height: 'auto',
    flex: '1',
    alignItems: "flex-start",
    boxSizing: 'border-box' as 'border-box',
  },
  imageContainer: {
      position: 'relative' as 'relative',
      width: '264px',
      margin: 'auto',
      height: '130px',
      marginTop: '10px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      border: 'rgba(242, 242, 236, 1) 10px solid',
      borderRadius: '15px',
      //borderWidth: '10px',
      zIndex: 1,
      boxSizing: 'border-box' as 'border-box',
      flex: '1',
      flexWrap: 'nowrap' as 'nowrap',
      flexDirection: 'row' as 'row',
  },
  contentContainer: {
      // position: 'relative' as 'relative',
      // width: '700px',
      // margin: '0 auto',
      // //height: '425px',
      // //marginLeft: '370px',
      // //marginTop: '332px',
      // marginTop: '-70px',
      // marginBottom: '20px',
      // opacity: '1',
      // backgroundColor: 'rgba(255, 255, 255, 1)',
      // borderRadius: '10px',
      // //borderWidth: '0px',
      // zIndex: 0,
      // boxSizing: 'border-box' as 'border-box',
      // paddingTop: '90px',
      // paddingLeft: '190px',
      // display: 'flex',
      // flex: '1',
      // flexWrap: 'nowrap' as 'nowrap',
      // flexDirection: 'column' as 'column',
      // alignItems: 'center',
      // justifyContent: 'center',

      position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    //height: '600px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '15px',
    //borderWidth: '0px',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    // paddingRight: '50px',
    // paddingLeft: '50px',
    //paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    justifyContent: 'center',
  },
  image: {
      position: 'relative' as 'relative',
      width: '176px',
      height: '54px',
      //marginLeft: '632px',
      //marginTop: '305px',
      marginLeft: '32px',
      marginTop: '30px',
      opacity: '1',
      resizeMode: 'contain',
      boxSizing: 'border-box' as 'border-box',
  },
  textFields: {
      position: 'absolute',
      width: '224',
      height: '24',
      marginLeft: '58',
      marginTop: '16',
      opacity: '1',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 400,
      includeFontPadding: false,
      //padding: '0',
      padding: '10px',
      color: 'rgba(128, 128, 128, 1)',
      textAlign: 'left',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Regular',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    //height: '16px',
    margin: '0 auto',
    //marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  button: {
      //position: 'absolute',
      position: 'relative' as 'relative',
      width: '328px',
      height: '56px',
      // marginLeft: '556px',
      margin: 'auto 0',
      marginTop: '30px',
      marginBottom: '20px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 0, 1)',
      borderRadius: '10px',
      justifyContent: 'center',
      boxSizing: 'border-box' as 'border-box',
  },
  buttonText: {
      position: 'absolute',
      width: '104',
      height: '16',
      marginLeft: '16',
      marginTop: '14',
      opacity: '1',
      backgroundColor: 'transparent',
      fontStyle: 'normal',
      fontWeight: 'normal',
      includeFontPadding: false,
      padding: '0',
      color: 'rgba(28, 28, 26, 1)',
      textAlign: 'center',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Medium',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '18',	
      boxSizing: 'border-box' as 'border-box',	
  },
  signUpText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    width: '325px',
    margin: '0 auto',
    //marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
    //marginBottom: '10px',
  },
  signUp: {
    backgroundColor: 'transparent',
    position: 'relative' as 'relative',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14px',
    fontStyle: 'normal' as 'normal',
    fontWeight: 300,
    textDecoration: 'underline',
    boxSizing: 'border-box' as 'border-box',
  },
  forgotPasswordButton: {
    backgroundColor: 'transparent',
    position: 'relative' as 'relative',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    textDecoration: 'underline',
    textTransform: 'none' as 'none',
    boxSizing: 'border-box' as 'border-box',
  },
  placeholderCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',	
    color: '#ACACAC',
    paddingLeft: '80px',
  },
  dataCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',	
    color: 'black',
    fontWeight: 'bolder' as 'bolder', 
    textAlign: 'right' as 'right',
    paddingRight: '80px',
  },
  table: {
    marginTop: '-40px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',	
  },
  tableButton: {
    backgroundColor: '#FFFF00', 
    width: '324px',
    height: '56px', 
    padding: '5px', 
    margin: 'auto',
    marginTop: '16px', 
    //marginLeft: '60px',
    marginBottom: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  backWithArrow: {
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    alignItems: 'baseline',
    marginRight: 'auto',
    paddingLeft: '180px',
    cursor: 'pointer',
  },
  backWithArrowProfileDetails: {
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    alignItems: 'baseline',
    marginRight: 'auto',
    paddingLeft: '75px',
    cursor: 'pointer',
  },
  arrow: {
    //float: 'right' as 'right',
    display: 'flex',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',	
    fontWeight: 600,
  },
  backIcon: {
    width: '20px',
    height: '14px',
  },
  backText: {
    width: '32px',
    height: '17px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
}

// Customizable Area End
