import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    TextField,
    Input,
    Button,
    Typography,
    Grid,
    Select,
    MenuItem,
    TableContainer,
    Table,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Dialog,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';
import { checkedRadioButton,uncheckedRadioButton,checkedUserPermissionIcon,uncheckedUserPermissionIcon } from '../src/assets'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import { DeleteOutline, EditOutlined,  } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Header from "./Header";
import ToastMessageDisplay from "./ToastMessageDisplay";
import TableDisplay from "./TableDisplay";
import PaginationDisplay from "./PaginationDisplay";
import Loading from "./Loading";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const StyledTextField = withStyles({
    root: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            '& fieldset': {
                borderRadius: "10px",
            },
        },
        "& input::placeholder": {
            fontFamily: 'Plus Jakarta Display',
            fontSize: '16',
            fontWeight: 400,
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: "transparent",
            border: "0.05px solid black"
        },
        "& .Mui-focused": {
            border: "0.05px solid black",
            borderRadius: '10px',
            maxHeight: '57px',
        }
    }
})((props: any) => <TextField {...props} />);

const StyledTextField1 = withStyles({
    root: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        "& .MuiFilledInput-root": {
            backgroundColor: "transparent",
        },
        "& .MuiInputBase-input": {
            height: '0px'
        },
        "& .MuiFilledInput-root:hover": {
            backgroundColor: "transparent",
            "@media (hover: none)": {
                backgroundColor: "transparent",
            }
        },
        "& .MuiFilledInput-root.Mui-focused": {
            backgroundColor: "transparent",
        },
        "& input::placeholder": {
            fontFamily: 'Plus Jakarta Display',
            fontSize: '14',
            fontWeight: 400,
        }
    }
})((props: any) => <TextField {...props} />);

const StyledSelect = withStyles({
    root: {
        paddingLeft: 15,
        "& .MuiOutlinedInput-root": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE"
        },
        "& .MuiOutlinedInput-root:hover": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE",
            "@media (hover: none)": {
                backgroundColor: "transparent",
                border: "0.05px solid #B1B1AE"
            }
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
            backgroundColor: "transparent",
            border: "0.05px solid #B1B1AE"
        },
        '&:focus': {
            backgroundColor: 'white',
            borderColor: '#B1B1AE'
        },
        '&:before': {
            borderColor: '#B1B1AE',
        },
        '&:after': {
            borderColor: '#B1B1AE',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
    },
})((props: any) => <Select {...props} />);

// Customizable Area End

import DashboardController, {
    Props,
    configJSON
} from "./DashboardController";
import Loader from "../../../components/src/Loader";
import ViewDetailsDialog from "./ViewDetailsDialog";

export default class Settings extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    addInformationSettingsValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && fieldState==""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }


    paymentTermSettingsUpdateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && fieldState !=="" && !fieldState?.match(/^\d+$/) && errorToBeShow !== ""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }
    
    paymentTermSettingsCreateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && fieldState !=="" && !fieldState?.match(/^\d+$/) && errorToBeShow !== ""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }

    taxationSettingsUpdateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : string) => {
      if(itemsToBeValidated && fieldState ==="" && errorToBeShow !== ""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }

    taxationSettingsCreateValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : string) => {
      if(itemsToBeValidated === true && fieldState ==="" && errorToBeShow !== ""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }

    
    handleInformationSettingsPinCodeRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsPinCode != "" && !this.state.informationSettingsPinCode.match(/^\d+$/)){
            return this.state.informationSettingsPinCodeMatchError
        }
        else {
            return ""
        }
    }

    saveLocationsValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
    const pattern = /^[A-Za-z]+$/;
      if(itemsToBeValidated && fieldState==="" ){
          return errorToBeShow
      }else if(itemsToBeValidated &&  !pattern.test(fieldState)){
        return errorToBeShow
      }
      else{
          return ''
      }
    }

    checkAllReadyCreatedUser = (user: any, index: number) => {
      if(this.state.alreadyCreatedUsers?.length - 1 === index){
        return `${user} already exists`
      }else{
        return `${user}, `;
      }
    }

    styledSelectStyle = (style: any) => {
      if(style === ""){
          return newStyles.selectBoxLabel; 
      }else{
          return newStyles.selectBox;
      }
    }

    userListPermissionStyleSelectAll = () => {
      if(Object.values(this.state.userPermissionsList1).every(value => value)){
        return newStyles.userPermissionTextSelectAll
      }else{
        return newStyles.userPermissionsUncheckedSelectAll
      }
    }
    
    userListPermissionChecked = (permissionChecked: any) => {
      if(permissionChecked){
        return true;
      }else{
        return false;
      }
    }

    userListPermissionStyle = (permissionStyle: any) => {
      if(permissionStyle){
        return newStyles.userPermissionText
      }else{
        return newStyles.userPermissionsUnchecked
      }
    }

    addCurrencySettingCurrency = (currency: any) => {
      if(!currency){
        return "";
      }else{
        return currency;
      }
    }

    addCurrencySettingValidationErrorRendered = (itemsToBeValidated:any,  fieldState:any, errorToBeShow : any) => {
      if(itemsToBeValidated && fieldState===""){
          return errorToBeShow
      }
      else{
          return ''
      }
    }
    handleCategorySettingsClick = ()=>{
        this.handleShowCategorySettings()
        this.handleGetDataCategorySettings()
    }
    
    measurementsClickHandle = ()=>{
        this.handleShowUnitMeasurementSettings()
        this.handleGetDataUnitMeasurementSettings()
    }
    handleSelectedRole = ()=>{
      return this.state.rolesList?.map((role: any) => {
        return <MenuItem value={role?.name} key={role?.id}>{role.name}</MenuItem>
    })}
    showSettingsButtonRendered = () => {
      if(this.state.showSettings){
        return (
            <Box  style={{width: '96%', margin: '0 auto',  marginBottom: '20px'}}>
                <Grid container>
                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowCurrencySettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Currency Settings"
                            onClick={this.handleShowCurrencySettings}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                      <MonetizationOnOutlinedIcon />
                                    </InputAdornment>
                                  ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                        testID="btnShowLocationSettings"
                        style={newStyles.textFieldBox1}
                        inputProps={{
                            style: {
                                fontSize: 14,
                                fontFamily: 'Plus Jakarta Display'
                            },
                            disabled: true,
                        }}
                        variant="filled"
                        value="Location Settings"
                        onClick={()=>{this.handleShowLocationSettings()
                                this.handleGetSettingsLocation()}}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                <LocationOnOutlinedIcon />
                              </InputAdornment>
                            ),
                            endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                        }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowUnitMeasurementSettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Unit of Measurement Settings"
                            onClick={this.measurementsClickHandle.bind(this)}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <AssessmentOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowCategorySettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Category Settings"
                            onClick={this.handleCategorySettingsClick.bind(this)}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <PieChartOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowPaymentTermsSettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Payment Terms Settings"
                            onClick={this.handleShowPaymentTermsSettings}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <PaymentOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowUserSettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="User Settings"
                            onClick={this.handleShowUserSettings}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <PermIdentityOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowInformationSettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display',
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Information Settings"
                            onClick={this.handleShowInformationSettings}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <InfoOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>

                    <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                        <StyledTextField1
                            required
                            testID="btnShowTaxationSettings"
                            style={newStyles.textFieldBox1}
                            inputProps={{
                                style: {
                                    fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                disabled: true,
                            }}
                            variant="filled"
                            value="Taxation Settings"
                            onClick={this.handleShowTaxationSettings}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginTop:'18.5px'}}>
                                    <NoteOutlinedIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: <ChevronRightIcon style={{ paddingTop: '18px' }} />
                            }}
                            placeholder={configJSON.placeHolderPhoneOrEmail}
                        />
                    </Grid>
                </Grid>
            </Box>
        )
      }
    }

    handleShowIsCreateInformationValidationRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsPhoneNumber == ""){
          return  this.state.informationSettingsPhoneNumberRequiredField
        }
        else {
            return ""
        }
    }

    handleshowIsCreateInformationValidationRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsEmail == ""){
            return this.state.informationSettingsEmailRequiredField
        }
        else {
            return ""
        }
    }

    handleshowIsCreateInformationValidationRegexRender = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsEmail != "" && !this.state.informationSettingsEmail.match(/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/)) {
            return this.state.informationSettingsEmailMatchError
        }
        else {
            return ""
        }
    }

    handleInformationSettingsWebsite = () => {
        if(this.state.showIsCreateInformationValidation && this.state.informationSettingsWebsite != "" && !this.state.informationSettingsWebsite.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
            return this.state.informationSettingsWebsiteMatchError
        }
        else {
            return ""
        }
    }

    paymentTermsInputFieldRendered = () => {
      if(this.state.showEditPaymentTermsSettingField === true){
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Payment Terms Name</Typography>
                      <StyledTextField
                        required
                        id="editBtnpaymentTermName"
                        value={this.state.editPaymentTermsName}
                        onChange={this.handleEditPaymentTermsChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Payment Terms Name"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermSettingsUpdateValidationErrorRendered(this.state.showIsEditPaymentTermsValidation, this.state.editPaymentTermsName, this.state.paymentTermNameRequiredFieldEdit)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Credit Days</Typography>
                      <StyledTextField
                        required
                        id="editCreditDays"
                        value={this.state.editCreditDays}
                        onChange={this.handleEditCreditDaysChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Number of Credit Days"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermSettingsUpdateValidationErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.editCreditDays, this.state.paymentTermCreditDaysRequiredFieldEdit)}</Typography>
                </Box>
            </Grid>
          </>
        )
      }else{
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Payment Terms Name</Typography>
                    <StyledTextField
                        required
                        id="addPaymentTermName"
                        value={this.state.paymentTermsName}
                        onChange={this.handlePaymentTermsChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Payment Terms Name"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermSettingsCreateValidationErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.paymentTermsName, this.state.paymentTermNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
            <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Credit Days</Typography>
                    <StyledTextField
                        required
                        id="addCreditDays"
                        value={this.state.creditDays}
                        onChange={this.handleCreditDaysChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Number of Credit Days"
                    />
                    <Typography style={newStyles.validationText}>{this.paymentTermSettingsCreateValidationErrorRendered(this.state.showIsCreatePaymentTermsValidation, this.state.creditDays, this.state.paymentTermCreditDaysRequiredField)}</Typography>
                </Box>
            </Grid>
          </>
        )
      }
    }

    paymentTermSendBtn = () => {
      if(this.state.showEditPaymentTermsSettingBtn === false){
        return (
          <Button id="btnAddPaymentSettings" onClick={this.handleCreatePaymentTermSettings} style={newStyles.modalButton} variant="contained">Add Payment Terms</Button>
        )
      }else{
        return (
          <Button id="btnEditPaymentSettings" onClick={this.handleEditPaymentTermsSettings} style={newStyles.modalButton} variant="contained">Update Tax</Button>
        )
      }
    }

    paymentTermListTableRendered = () => {
      if(this.state?.paymentTermsList?.length > 0){
        return(
          <TableContainer style={newStyles.tableContainer}>
            <Table> 
            {this.state.paymentTermsList?.slice((this.state.itemsPerPage * (this.state.currentPagePaymentTerms - 1)), (this.state.itemsPerPage * (this.state.currentPagePaymentTerms)))
                        .map((paymentTerms: any, index: number) => {
                        return <TableDisplay 
                            key={paymentTerms?.id}
                            className={'suppliers_records'}
                            data={paymentTerms} 
                            index={index} 
                            headerData={['Serial Number', 'Payment Terms Name', 'Credit Days', '']} 
                            displayData={[paymentTerms?.attributes?.serial_number, paymentTerms?.attributes?.payment_term_name, paymentTerms?.attributes?.credit_days,]}
                            icons={[
                                {
                                    icon: EditOutlined,
                                    handleOnClick: this.handleEditPaymentTerms,
                                    parameter: paymentTerms,
                                },
                                {
                                    icon:DeleteOutline,
                                    handleOnClick: this.handleDeletePaymentTermList,
                                    parameter:paymentTerms?.id
                                }
                            ]}

                        />
            })}
            </Table>
          </TableContainer>
        )
      }else{
        return <Loading data-testid={'loader_active'} variant="rect" animation="wave" styling={newStyles.tableContainer}/>
      }
    }

    taxationInputFieldRendered = () => {
      if(this.state.showEditTaxationEditField === true){
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Name</Typography>
                      <StyledTextField
                        required
                        id="editTaxNameSettings"
                        value={this.state.editTaxName}
                        onChange={this.handleEditTaxNameChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Tax Name"
                    />
                    <Typography style={newStyles.validationText}>{this.taxationSettingsUpdateValidationErrorRendered(this.state.showIsUpdateTaxationValidation,this.state.editTaxName,this.state.editTaxNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
              <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Tax Percentage%</Typography>
                        <StyledTextField
                          required
                          id="editTaxPercentageSettings"
                          value={this.state.editTaxPercentage}
                          onChange={this.handleEditTaxPercentageChange}
                          style={newStyles.textFieldBox}
                          inputProps={
                              {
                                  style:
                                  {
                                      fontSize: 16,
                                      fontWeight: 400,
                                      fontFamily: 'Plus Jakarta Display'
                                  },
                              }}
                          variant="outlined"
                          placeholder="Enter Tax %"
                      />
                      <Typography style={newStyles.validationText}>{this.taxationSettingsUpdateValidationErrorRendered(this.state.showIsUpdateTaxationValidation,this.state.editTaxPercentage,this.state.editTaxPercentageRequiredField)}</Typography>
              </Box>
            </Grid>
          </>
        )
      }else{
        return (
          <>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Name</Typography>
                      <StyledTextField
                        required
                        id="createTaxNameSettings"
                        value={this.state.taxName}
                        onChange={this.handleTaxNameChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                            }}
                        variant="outlined"
                        placeholder="Enter Tax Name"
                    />
                    <Typography style={newStyles.validationText}>{this.taxationSettingsCreateValidationErrorRendered(this.state.showIsCreateTaxationValidation,this.state.taxName,this.state.taxNameRequiredField)}</Typography>
                </Box>
            </Grid>
            <Grid item lg={4}>
                <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Tax Percentage%</Typography>
                      <StyledTextField
                        required
                        id="createTaxPercentageSettings"
                        value={this.state.taxPercentage}
                        onChange={this.handleTaxPercentageChange}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                },
                                input:{

                                }
                            }}
                        variant="outlined"
                        placeholder="Enter Tax %"
                    />
                    <Typography data-test-id="createTaxPerValidation" style={newStyles.validationText}>{this.taxationSettingsCreateValidationErrorRendered(this.state.showIsCreateTaxationValidation,this.state.taxPercentage,this.state.taxPercentageRequiredField)}</Typography>
                </Box>
            </Grid> 
          </>
        )
      }
    }

    taxationSendBtnRender = () => {
      if(this.state.showEditTaxationSettingBtn){
        return (
          <Button id="btnCreateTaxationSettings" onClick={this.createTaxationSettings} style={newStyles.modalButton} variant="contained">Add Tax</Button>
        )
      }else{
        return <Button id="btnEditTaxationSettings" onClick={this.editTaxationSettings} style={newStyles.modalButton} variant="contained">Update Tax</Button>
      }
    }

    taxationListTableRendered = () => {
      if(this.state?.taxations?.length > 0){
        return <>
          <TableContainer style={newStyles.tableContainer}>
            <Table> 
            {this.state.taxations?.slice((this.state.itemsPerPage * (this.state.currentPageTaxations - 1)), (this.state.itemsPerPage * (this.state.currentPageTaxations)))
                        .map((taxation: any, index: number) => {
                        return <TableDisplay 
                            key={taxation?.id}
                            className={'taxations_record'}
                            data={taxation} 
                            index={index} 
                            headerData={['Serial Number', 'Tax Name', 'Tax Percentage%', '']} 
                            displayData={[taxation?.attributes?.serial_number, taxation?.attributes?.tax_name, taxation?.attributes?.tax_percentage,]}
                            icons={[
                                {
                                    icon: EditOutlined,
                                    handleOnClick: this.handleEditTaxationModal,
                                    parameter: taxation,
                                },
                            ]}

                        />
            })}
            </Table>
          </TableContainer> 
        </>
      }else{
        return <Loading data-testid={'loader_active'} variant="rect" animation="wave" styling={newStyles.tableContainer}/>
      }
    }

    showPaymentTermsRenderedFields = () => {
      if(this.state.showPaymentTermsSettings){
        return (
          <Box style={newStyles.settingsListContainer}>
              <ToastMessageDisplay id="btnPaymentTermsCreateSnackbar" message={this.state.paymentTermsCreateMessage} handleClose={this.handlePaymentTermsSettingsCreateSnackbarClose} />
              <ToastMessageDisplay id="btnPaymentTermsEditSnackbar" message={this.state.paymentTermsEditMessage} handleClose={this.handlePaymentTermsSettingsEditSnackbarClose} />
              <ToastMessageDisplay id="btnPaymentTermsDeletSnackbar" message={this.state.PaymentTermDeleteMessage} handleClose={this.handlePaymentTermsSettingsDeletSnackbarClose} />
              <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                  <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackCurrencySettings" onClick={this.handleGoBackFromPaymentTermsSettings} /></Typography>
                  <Typography style={newStyles.modalHeading} variant='h5'>Payment Terms</Typography>
              </Box>
              <Box>
                  <Grid container>
                    {this.paymentTermsInputFieldRendered()}
                      <Grid item lg={4}>
                          <Box style={newStyles.gridItem}>
                              <Button
                                  id="btnCancelPaymentSetting"
                                  style={newStyles.modalCancelButton}
                                  onClick={this.handleCancelPaymentTermsSettings}
                                  variant="contained">
                                  Cancel
                              </Button>
                              {this.paymentTermSendBtn()}
                          </Box>
                      </Grid>
                  </Grid>
                  {this.paymentTermListTableRendered()}
              <PaginationDisplay
                  data-test-id="btnPageChangeDisp1"
                  totalPages={this.state.noOfPagesPaymentTerms}
                  currentPage={this.state.currentPagePaymentTerms}
                  handlePageChange={this.handlePaymentTermsPageChange}
                  styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
              />
              </Box>
          </Box>
        )
      }
    }

    showTaxationAllFieldsRendered = () => {
      if(this.state.showTaxationSettings){
        return (
          <Box style={newStyles.settingsListContainer}>
              <ToastMessageDisplay id="btnTaxationsSettingsCreateSnackbar" message={this.state.taxationsCreateMessage} handleClose={this.handleTaxationSettingsCreateSnackbarClose} />
              <ToastMessageDisplay id="taxationEditMessageSnackbar" message={this.state.taxationEditMessage} handleClose={this.handleTaxationSettingsEditSnackbarClose} />
              <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                  <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackTaxationSettings" onClick={this.handleGoBackFromTaxationSettings} /></Typography>
                  <Typography style={newStyles.modalHeading} variant='h5'>Taxation Settings</Typography>
              </Box>
              <Box>
                  <Grid container>
                      {this.taxationInputFieldRendered()}
                      <Grid item lg={4}>
                          <Box style={newStyles.gridItem}>
                              <Button
                                  id="btnCancelTaxationSettings"
                                  style={newStyles.modalCancelButton}
                                  onClick={this.handleCancelTaxationsSettings}
                                  variant="contained">
                                  Cancel
                              </Button>
                              {this.taxationSendBtnRender()}
                          </Box>
                      </Grid>
                  </Grid>
                  {this.taxationListTableRendered()}
              <PaginationDisplay
                  data-test-id="btnPageChangeDisp2"
                  totalPages={this.state.noOfPagesTaxations}
                  currentPage={this.state.currentPageTaxations}
                  handlePageChange={this.handleTaxationsPageChange}
                  styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
              />
              </Box>
          </Box>
        )
      }
    }

    // Customizable Area End


    handleShowInformationTableRender = () => {
        if(this.state.showInformation){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay testID="btnInformationSettingsSnackbar" message={this.state.informationSettingsEditMessage} handleClose={this.handleInformationSettingsEditSnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography data-test-id="btnGoBack" style={newStyles.modalHeadingArrow}><ArrowBackIosIcon onClick={this.handleGoBackFromInformationSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Information Settings</Typography>
            </Box>
            <Box>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Company name</Typography>
                            <StyledTextField
                                required
                                testID="btnInformationSettingCompanyName"
                                value={this.state.informationSettingsCompanyName}
                                onChange={this.handleInformationSettingsCompanyName}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display',
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Company Name"
                            />
                            <Typography style={newStyles.validationText}>
                            {this.infoSettingsInputFieldsValidationErrorRendered(
                                this.state.showIsCreateInformationValidation,
                                this.state.informationSettingsCompanyName,
                                this.state.informationSettingsComapanyNameRequiredField
                            )}
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Phone Number</Typography>
                            <StyledTextField
                                required
                                testID="btnInformationSettingsPhoneNo"
                                value={this.state.informationSettingsPhoneNumber}
                                onChange={this.handleInformationSettingsPhoneNumber}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Phone Number"
                            />
                            <Typography style={newStyles.validationText}>
                                {this.handleIsInfoSettingsFieldCheckValidTrueFalseError(this.state.infoSettingContactNumberErrorMessage, "Contact number is wrong *")}
                            </Typography>

                            <Typography style={newStyles.validationText}>{this.handleShowIsCreateInformationValidationRender()}</Typography>
                            <Typography style={newStyles.validationText}>
                                {this.infoSettingsContactNumbersValidationErrorDisplay(
                                    this.state.showIsCreateInformationValidation,
                                    this.state.informationSettingsPhoneNumber,
                                    this.state.informationSettingsPhoneNumberMatchError
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Address</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsAddress"
                                value={this.state.informationSettingsAddress}
                                onChange={this.handleInformationSettingsAddress}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Address"
                            />
                            <Typography style={newStyles.validationText}>
                            {this.infoSettingsInputFieldsValidationErrorRendered(
                                this.state.showIsCreateInformationValidation,
                                this.state.informationSettingsAddress,
                                this.state.informationSettingsAddressRequiredField
                            )}
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Email</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsEmail"
                                value={this.state.informationSettingsEmail}
                                onChange={this.handleInformationSettingsEmail}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Email"
                            />
                            <Typography style={newStyles.validationText}>{this.handleshowIsCreateInformationValidationRender()}</Typography>
                            <Typography style={newStyles.validationText}>{this.handleshowIsCreateInformationValidationRegexRender()}</Typography>
                            <Typography style={newStyles.validationText}>
                      {
                        this.handleInformationSettingsVaidEmailErrorMessage()
                      }
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {
                        this.handleInformationSettingsFieldCheckValidTrueFalseError(this.state.informationSettingEmailInvalidPatternError, "Invalid Email *")
                      }
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Pincode</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsPinCode"
                                value={this.state.informationSettingsPinCode}
                                onChange={this.handleInformationSettingsPinCode}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Pincode"
                            />
                            <Typography style={newStyles.validationText}>
                                {this.infoSettingsInputFieldsValidationErrorRendered(
                                    this.state.showIsCreateInformationValidation,
                                    this.state.informationSettingsPinCode,
                                    this.state.informationSettingsPinCodeRequiredField
                                )}
                            </Typography>
                            <Typography style={newStyles.validationText}>{this.handleInformationSettingsPinCodeRender()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Website</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsWebsite"
                                value={this.state.informationSettingsWebsite}
                                onChange={this.handleInformationSettingsWebsiteInpChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Website"
                            />
                            
                            <Typography style={newStyles.validationText}>{this.handleInformationSettingsWebsite()}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>GST Number</Typography>
                            <StyledTextField
                                required
                                testID="btnInfoSettingsGstNumber"
                                value={this.state.informationSettingsGstNumber}
                                onChange={this.handleInformationSettingsGstNumber}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter GST Number"
                            />
                            <Typography style={newStyles.validationText}>
                            {this.infoSettingsInputFieldsValidationErrorRendered(
                                this.state.showIsCreateInformationValidation,
                                this.state.informationSettingsGstNumber,
                                this.state.informationSettingsGSTNumberRequiredField
                            )}
                    </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Button data-test-id="btnCancel" style={newStyles.modalCancelButton} onClick={() => this.setState({ showInformation: false, showSettings: true, showIsCreateInformationValidation: false, })} variant="contained">Cancel</Button>
                            <Button data-test-id="btnCreateInfoSettings" onClick={this.handleCreateInformationSettings} style={newStyles.modalButton} variant="contained">Update</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowCurrencySettingsRender = () => {
        if(this.state.showCurrencySettings){
           return <Box style={newStyles.settingsListContainer}>
                                <ToastMessageDisplay testID="btnCurrencySettingsEdit" message={this.state.currencySettingsEditMessage} handleClose={this.handleCurrencySettingsEditSnackbarClose} />
                                <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                                    <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackCurrencySettings" onClick={this.handleGoBackFromCurrencySettings} /></Typography>
                                    <Typography style={newStyles.modalHeading} variant='h5'>Currency Settings</Typography>
                                </Box>
                                <Box>
                                    <Grid container>
                                        <Grid item lg={4}>
                                            <Box style={newStyles.gridItem}>
                                                <Typography style={newStyles.modalLabel}>Currency</Typography>
                                                <StyledSelect
                                                    testID="txtCurrencySettings"
                                                    variant="outlined"
                                                    label="Select Currency"
                                                    value={this.addCurrencySettingCurrency(this.state.currencySettingsCurrency)}
                                                    onChange={this.handleCurrencySettingsCurrencyChange}
                                                    disableUnderline
                                                    displayEmpty
                                                    style={this.styledSelectStyle(this.state.currencySettingsCurrency)}
                                                    input={<Input />}
                                                >
                                                    <MenuItem value="" disabled>Select Currency</MenuItem>
                                                    {this.state.currencyList?.map((currency: any) => {
                                                        return <MenuItem data-test-id="txtCurrencyItem" key={currency?.iso} value={`${currency?.name} - ${currency?.currency}`} onChange={(e)=>{return (e)}}>{`${currency?.name} - ${currency?.currency}`}</MenuItem>
                                                    })}
                                                </StyledSelect>
                                                <Typography style={newStyles.validationText}>{this.addCurrencySettingValidationErrorRendered(this.state.showIsCreateCurrencyValidation,this.state.currencySettingsCurrency,this.state.currencySettingsCurrencyRequiredField)}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item lg={4}>
                                        </Grid>
                                        <Grid item lg={4}>
                                            <Box style={newStyles.gridItem}>
                                                <Button
                                                    data-test-id="btnCancelCurrencySettings"
                                                    style={newStyles.modalCancelButton}
                                                    onClick={this.handleCancelCurrencySettings}
                                                    variant="contained">
                                                    Cancel
                                                </Button>
                                                <Button data-test-id="btnCreateCurrencySettings" onClick={this.handleCreateCurrencySettings} style={newStyles.modalButton} variant="contained">Save Details</Button>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Box>
                            </Box>
        }
        else {
            return ""
        }
    }

    handleShowLocationSettingsRender = () => {
        if(this.state.showLocationSettings){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay testID="btnLocationSettingsSnackbar" message={this.state.locationCreateMessage} handleClose={this.handleLocationSettingsCreateSnackbarClose} />
            <ToastMessageDisplay testID="btnLocationSettingsDeleteSnackbar" message={this.state.locationDeleteMessage} handleClose={this.handleLocationSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="btnLocationSettingsUpdateSnackbar" message={this.state.locationUpdateMessage} handleClose={this.handleLocationSettingsUpdateSnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackFromLocationSettings" onClick={this.handleGoBackFromLocationSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Location</Typography>
            </Box>
                <Box style={{ marginRight: '20px' }}>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Location Name</Typography>
                            <StyledTextField
                                type="text"
                                required
                                testID="btnLocationSettingsName"
                                value={this.state.location}
                                onChange={this.handleLocationChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Location Name"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.location, this.state.locationRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Address</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsAddress"
                                value={this.state.settingsAddress}
                                onChange={this.handleSettingsAddress}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                        //disabled: this.state.enableEditInformationSettings ? true : false,
                                    }}
                                variant="outlined"
                                placeholder="Enter Address"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsAddress, this.state.locationAddressRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Location Identification Code</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsIdentifyCode"
                                value={this.state.settingsIdentificationCode}
                                onChange={this.handleSettingsIdentificationCode}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Location Identification Code"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsIdentificationCode, this.state.locationIdentityCodeRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>GST</Typography>
                            <StyledTextField
                                required
                                id="btnLocationSettingsGST"
                                value={this.state.settingsGst}
                                onChange={this.handleSettingsGst}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter GST Number"
                            />
                            <Typography style={newStyles.validationText}>{this.saveLocationsValidationErrorRendered(this.state.saveLocationsValidation, this.state.settingsGst, this.state.locationGstRequiredError)}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Button data-testID="btnCloseLocationModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromLocationSettings} variant="contained">Cancel</Button>
            <Button data-test-id="btnOpenLocationsModal" onClick={()=>{this.handleLocationSettings()}} style={newStyles.modalButtonRight}>Add Location</Button>
            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Location Name</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Address</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Identification Code</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>GST Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this?.state?.showSettingsLocation && this.state.showSettingsLocation?.slice((this.state.itemsPerPage * (this.state.currentPageSettingsLocation - 1)), (this.state.itemsPerPage * (this.state.currentPageSettingsLocation))).map((data: any) => {
            return <TableRow key={data?.id} >
                <TableCell className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.name_of_location}</TableCell>
                <TableCell className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.adderss}</TableCell>
                <TableCell className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.location_identification_code}</TableCell>
                <TableCell className="location_items_row" style={newStyles.tableBodyRows}>{data?.attributes?.gst_number}</TableCell>
                <TableCell className="location_items_row" style={newStyles.iconColumn}><VisibilityOutlinedIcon onClick={this.handleShowSettingLocationOpenModal.bind(this,data?.id)}  style={newStyles.icons}/><EditOutlinedIcon id="editLocationOpenModal" onClick={() => {this.handleEditSettingLocationOpenModal(data)}} style={newStyles.icons}/> <DeleteOutline id="deleteLocationOpenModal" onClick={() => {this.handleDeleteLocation(data.id)}} style={newStyles.icons} /></TableCell>
            </TableRow>
            })}   
        </TableBody>
    </Table>
</TableContainer>
            <PaginationDisplay
                data-test-id="btnPageChangeDisp3"
                totalPages={this.state.noOfPagesSettingsLocation}
                currentPage={this.state.currentPageSettingsLocation}
                handlePageChange={this.handlePageChangeSettingsLocation}
                styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowCategorySettingsRender = () => {
        if(this.state.showCategorySettings){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay id="btnCategorySettingsAddSnackbar" message={this.state.categoryAddMessage} handleClose={this.handleCategorySettingsAddSnackbarClose} />
            <ToastMessageDisplay id="btnCategorySettingsDeleteSnackbar" message={this.state.categoryDeleteMessage} handleClose={this.handleCategorySettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="btnCategorySettingsUpdateSnackbar" message={this.state.categoryUpdateMessage} handleClose={this.handleCategorySettingsUpdateSnackbarClose} />
            <ToastMessageDisplay message={this.state.categoryDuplicasyErrorMessage} handleClose={this.handleCategorySettingsDuplicasyErrorSnackbarClose} />

            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackFromCategorySettings" onClick={this.handleGoBackFromCategorySettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Category Settings</Typography>
            </Box>
   
            <Box style={{ marginRight: '20px' }}>
              
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Category</Typography>
                            <StyledTextField
                                required
                                id="btnCategorySettingsName"
                                value={this.state.settingsCategory}
                                onChange={this.handleSettingsCategory}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Name of Category"
                            />
                            <Typography style={newStyles.validationText}>{this.state.settingsCategoryFeildError}</Typography>
                        </Box>
                        <Typography style={newStyles.validationText}>{this.state.categorySettingCreatePost && this.state.settingsCategory === "" && this.state.settingsCategoryRequiredField}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Button data-testID="btnCloseCategoryModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromCategorySettings} variant="contained">Cancel</Button>
            <Button id="btnOpenCategoryModalPostAPI" onClick={this.handlePostCategorySettings} style={newStyles.modalButtonRight}>Add Category</Button>
            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Serial Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Name of Category</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this.state.showSettingsCategory?.slice((this.state.itemsPerPage * (this.state.currentPageCategory - 1)), (this.state.itemsPerPage * (this.state.currentPageCategory))).map((data: any) => {
            return <TableRow key={data?.id} className="show_category_table">
                <TableCell className="tableCategoryCell" style={newStyles.tableBodyRows}>{data?.attributes?.serial_number}</TableCell>
                <TableCell className="tableCategoryCell" style={newStyles.tableBodyRows}>{data?.attributes?.name}</TableCell>
                <TableCell className="tableCategoryCell" style={newStyles.iconColumn}><EditOutlinedIcon id="editCategory" onClick={this.handleEditSettingCategoryOpenModal.bind(this,data)} style={newStyles.icons}/></TableCell>
                <TableCell className="tableCategoryCell" style={newStyles.iconColumn}><DeleteOutline id="deleteCategory" onClick={this.handleDeleteCategory.bind(this,data?.id)} style={newStyles.icons}/></TableCell>
            </TableRow>
            })}   
        </TableBody>
    </Table>
            </TableContainer>
            <PaginationDisplay
                data-test-id="btnPageChangeDisp4"
                totalPages={this.state.noOfPagesCategory}
                currentPage={this.state.currentPageCategory}
                handlePageChange={this.handlePageChangeCategory}
                styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
        </Box>
        }
        else {
            return ""
        }
    }

    handleShowUnitMeasureSettingsRender = () => {
        if(this.state.showUnitMeasureSettings) {
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay id="unitMeasurementDeleteMess" message={this.state.unitMeasurementDeleteMessage} handleClose={this.handleUnitMeasurementDeleteSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay id="unitMeasurementCreateMess" message={this.state.unitMeasurementCreateMessage} handleClose={this.handleUnitMeasurementDeleteSettingsCreateSnackbarClose} />
            <ToastMessageDisplay id="unitMeasurementUpdateMess" message={this.state.unitMeasurementUpdateMessage} handleClose={this.handleUnitMeasurementDeleteSettingsUpdateSnackbarClose} />
            <ToastMessageDisplay message={this.state.unitMeasurementSettingsDuplicasyMessage} handleClose={this.handleUnitMeasurementSettingsDuplicasySnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-testID="btnGoBackFromUnitMeasureSettings" onClick={this.handleGoBackFromUnitMeasurementSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>Unit of Measurement</Typography>
            </Box>
   
            <Box style={{ marginRight: '20px' }}>
              
              <Grid container style={{justifyContent:"space-between"}}>
                <Box display={"flex"} flexDirection={"column"}  sx={{mt:'1.5rem', ml:'30px'}}>
                    <Typography style={newStyles.modalLabel}>Unit</Typography>
                    <StyledTextField
                        required
                        id="btnUnitMeasureSettings"
                        value={this.state.settingsUnitMeasurement}
                        onChange={this.handleSettingsUnitMeasurement}
                        style={newStyles.textFieldBox}
                        inputProps={
                            {
                                style:
                                {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                }
                            }}
                        variant="outlined"
                        placeholder="Add Unit of Measurement"
                    />
                  <Typography style={newStyles.validationText}>{this.state.unitMeasurementSettingPost &&this.state.settingsUnitMeasurement === '' && this.state.settingsUnitMeasurementRequiredField}</Typography>
                </Box>
                <Box display={"flex"} alignItems={'baseline'}>

                  <Button id="btnCloseUnitMeasureModal" style={newStyles.modalCancelButton} onClick={this.handleGoBackFromUnitMeasurementSettings} variant="contained">Cancel</Button>
                  <Button id="btnOpenUnitMeasureModal" onClick={this.handlePostUnitMeasurementSettings} style={newStyles.modalButtonRight}>Add Unit</Button>
                </Box>
              </Grid>
            </Box>
            <TableContainer style={newStyles.tableContainer} >
    <Table>
        <TableHead>
            <TableRow>
                <TableCell style={newStyles.tableHeaderRows}>Serial Number</TableCell>
                <TableCell style={newStyles.tableHeaderRows}>Unit of Measurement</TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {this.state.showSettingsUnitMeasurement && this.state.showSettingsUnitMeasurement?.slice(
                  this.state.itemsPerPage *
                  (this.state.currentPageUnitMeasure - 1),
                  this.state.itemsPerPage *
                  this.state.currentPageUnitMeasure
                ).map((data: any) => {
                return <TableRow key={data?.id}>
                <TableCell className="tableUnitMeasurement" style={newStyles.tableBodyRows}>{data?.attributes?.sr_number}</TableCell>
                <TableCell className="tableUnitMeasurement" style={newStyles.tableBodyRows}>{data?.attributes?.unit_of_measurment}</TableCell>
                <TableCell className="tableUnitMeasurement" style={newStyles.iconColumn}><EditOutlinedIcon id="editUnitMesaurement" onClick={this.handleEditSettingUnitMeasurementOpenModal.bind(this,data)} style={newStyles.icons}/> <DeleteOutline id="deleteUnitMesaurementr" onClick={() => {this.handleDeleteSettingUnitMeasurement(data?.id)}} style={newStyles.icons}/></TableCell>
            </TableRow>
            })}   
        </TableBody>
    </Table>
</TableContainer>
            <PaginationDisplay
                data-test-id="btnPageChangeDisp5"
                totalPages={this.state.noOfPagesUnitMeasure}
                currentPage={this.state.currentPageUnitMeasure}
                handlePageChange={this.handleUnitMeasurePageChange}
                styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
            />
        </Box>
        }
        else {
            return ""
        }
    }


    // users permissions
    handleShowUserSettingsRender = () => {
        if(this.state.showUserSettings){
            return <Box style={newStyles.settingsListContainer}>
            <ToastMessageDisplay id="btnUserSettingsSnackBar" message={this.state.userCreateMessage} handleClose={this.handleUserSettingsCreateSnackbarClose} />
            <ToastMessageDisplay id="btnCreatePermissionMessage" message={this.state.userPermissionsCreatedMessage} handleClose={this.handleUserSettingsPermissionsCreatedSnackbarClose} />
            <ToastMessageDisplay id="btnUserSettingsDeleteSnackbar" message={this.state.userDeleteMessage} handleClose={this.handleUserSettingsDeleteSnackbarClose} />
            <ToastMessageDisplay testID="btnCloseAlreadyUsersSnackBar" style={{ margin: '15px' }} message={this.state.alreadyCreatedUsers.length > 0 && this.state.alreadyCreatedUsers.map((user: string, index: number) => <span key={user}>{this.state.alreadyCreatedUsers?.length - 1 === index ? `${user} already exists` : `${user}, `} </span>)} handleClose={this.handleCloseAlreadyCreatedUsersSnackbar} />
            <ToastMessageDisplay id="btnUserUpdateSettingsSnackBar" message={this.state.userSettingPermissionUpdatedMessage} handleClose={this.handleUserSettingsPermissionsUpdationSnackbarClose} />
            <Box style={{ float: 'left', display: 'flex', padding: '20px' }}>
                <Typography style={newStyles.modalHeadingArrow}><ArrowBackIosIcon data-test-id="btnGoBackFromUser" onClick={this.handleGoBackFromUserSettings} /></Typography>
                <Typography style={newStyles.modalHeading} variant='h5'>User Settings</Typography>
            </Box>

            <Box>
                <Grid container>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Name</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsName"
                                value={this.state.userSettingAddUserName}
                                onChange={this.handleUserSettingsNameChange}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Name"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserName, this.state.userSettingNameRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Designation</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsDesignation"
                                value={this.state.userSettingAddUserDesignation}
                                onChange={this.handleUserSettingsDesignation}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Designation"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserDesignation, this.state.userSettingDesignationRequiredError)}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Email Id</Typography>
                            <StyledTextField
                                required
                                testID="btnUserSettingsEmail"
                                value={this.state.userSettingAddUserEmailId}
                                onChange={this.handleUserSettingsEmail}
                                style={newStyles.textFieldBox}
                                inputProps={
                                    {
                                        style:
                                        {
                                            fontSize: 16,
                                            fontWeight: 400,
                                            fontFamily: 'Plus Jakarta Display'
                                        },
                                    }}
                                variant="outlined"
                                placeholder="Enter Email"
                            />
                            <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserEmailId,  this.state.userSettingEmailRequiredError)}</Typography>
                            <Typography style={newStyles.validationText}>{ this.state.userSettingAddUserEmailId != "" && !this.state.userSettingAddUserEmailId.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) ? "Email Not Valid" : ''}</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4}>
                        <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Roles</Typography>
                            <StyledSelect
                                testID="txtUserSettingsRole"
                                variant="outlined"
                                label="Select Role"
                                value={this.state.userSettingAddUserRole}
                                onChange={this.handleUserSettingsRole}
                                disableUnderline
                                displayEmpty
                                style={this.styledSelectStyle(this.state.userSettingAddUserRole)}
                                input={<Input />}
                            >
                                <MenuItem value="" disabled>Select Role</MenuItem>
                                {this.handleSelectedRole()}
                            </StyledSelect>
                        </Box>
                        <Typography style={newStyles.validationText}>{this.addInformationSettingsValidationErrorRendered(this.state.saveUsersValidation, this.state.userSettingAddUserRole, this.state.userSettingRoleRequiredError)}</Typography>
                    </Grid>
                    <Grid item lg={4} ></Grid>
                    <Grid item lg={4}>
                        <Box style={{display: 'flex', justifyContent: 'flex-end' }}>
                            <Button data-test-id="btnCloseUserModal" style={newStyles.modalCancelButton} onClick={this.handleCloseUserModal} variant="contained">Clear</Button>
                            <Button style={newStyles.modalButton} data-test-id="btnAddUser" onClick={this.handleSaveUsers} variant="contained">{this.handleCircularProgressBarr(this.state.isUserCreating, "Add User")}</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        }
        else {
            return ""
        }
    }


    handleCircularProgressBarr = (stateValue : any, textContent: any) => {
        if(stateValue){
            return <CircularProgress style={{ color: "black" }} />
        } else {
            return textContent
        }
    }


    handleInformationSettingsVaidEmailErrorMessage = () => {
        if (this.state.showIsCreateInformationValidation &&
          this.state.informationSettingsEmail !== "" &&
          !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.informationSettingsEmail)
        ) {
          return this.state.informationSettingEmailInvalidPatternError
        } else {
          return ""
        }
    }

    handleInformationSettingsFieldCheckValidTrueFalseError  = (stateValue: boolean, errorToBeShow: string) => {
        if (stateValue) {
          return errorToBeShow
        } else {
          return '';
        }
      }



      handleIsInfoSettingsFieldCheckValidTrueFalseError = (stateValue: boolean, errorToBeShow: string) => {
        if (stateValue) {
          return errorToBeShow
        } else {
          return '';
        }
      }

      infoSettingsContactNumbersValidationErrorDisplay = (validState: boolean, inpValueState: any, errorToBeShow: string) => {
        if ((validState && inpValueState !== "" && inpValueState.length > 10) || (validState && inpValueState !== "" && inpValueState.length < 10)) {
          return errorToBeShow
        } else {
          return '';
        }
      }



      infoSettingsInputFieldsValidationErrorRendered = (
        itemsToBeValidated: any,
        fieldState: any,
        errorToBeShow: any
      ) => {
        if (itemsToBeValidated && fieldState == "") {
          return errorToBeShow;
        } else {
          return "";
        }
      };
    
    


    // Customizable Area End


    render() {

        const USER_PERMISSIONS_ARRAY : any = [
            { name: 'purchase requisitions', label: 'Purchase Requisitions' },
            { name: 'purchase requisitions approval', label: 'Purchase Requisitions Approval' },
            { name: 'request for proposal', label: 'Request For Proposal' },
            { name: 'purchase orders', label: 'Purchase Orders' },
            { name: 'purchase orders approval', label: 'Purchase Orders Approval' },
            { name: 'receipts', label: 'Receipts' },
            { name: 'settings', label: 'Settings' },
            { name: 'reports', label: 'My Reports' },
            { name: 'budgets', label: 'Budgets' },
            { name: 'supplier and items', label: 'Items & Suppliers' },
        ];

        return (
            // Customizable Area Start

            <>
                <ThemeProvider theme={theme} >
                    <Container maxWidth="xl" style={{ position: 'relative', width: '100%', margin: '0px', padding: '0px' }}>

                        <Box style={newStyles.mainContainer}>
                            <Header navigation={this.props.navigation} active={3} profile={this.state.userProfile} id="" permissionLoginUserDetailsState ={this.state.userLoginDetailsCatch} />
                            <Box style={newStyles.settingsListContainer}>

                                {this.showSettingsButtonRendered()}

                                {this.handleShowInformationTableRender()}

                                {this.handleShowCurrencySettingsRender()}

                                {this.handleShowLocationSettingsRender()}

                                {this.showTaxationAllFieldsRendered()}

                                {this.showPaymentTermsRenderedFields()}

                                {this.handleShowCategorySettingsRender()}

                                {this.handleShowUnitMeasureSettingsRender()}

                            </Box>
                        </Box>
                            <ViewDetailsDialog
                            id={'show_budget_dialog'}
                                heading = "Show Location"
                                open = {this.state.openShowSettingLocationModal}
                                data = {
                                    [
                                        {
                                            displayName: 'Location Name',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.name_of_location,
                                        },
                                        {
                                            displayName: 'Address',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.adderss,
                                        },
                                        {
                                            displayName: 'Identification Code',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.location_identification_code,
                                        },
                                        {
                                            displayName: 'GST Number',
                                            displayData: this.state.showSettingsLocationSingleData?.attributes?.gst_number,
                                        },
                                    ]
                                }
                                buttonsInfo = {
                                    [
                                        {
                                            name: 'Cancel',
                                            disabled: false,
                                            variant: 'contained',
                                            onClickHandle: this.handleShowSettingLocationCloseModal,
                                            styling: newStyles.modalCancelButton,
                                        },

                                    ]
                                }
                            />

                            {this.handleShowUserSettingsRender()}


                <Dialog maxWidth="xl" style={newStyles.dialog} open={this.state.openEditSettingLocationModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Location</Typography> 
                    <Grid container>
                    <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Name of Location</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationName"
                                                        value={this.state.editSettingsLocation}
                                                        onChange={this.handleEditSettingsLocation}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                                //disabled: this.state.enableEditInformationSettings ? true : false,
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Address"
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Address</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnEditLocationSettingsAddress"
                                                        value={this.state.editSettingsAddress}
                                                        onChange={this.handleEditSettingsAddress}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Address"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Location Identification Code</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationSettingsIdentifyCode"
                                                        value={this.state.editSettingsIdentificationCode}
                                                        onChange={this.handleEditSettingsIdentificationCode}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Location Identification Code"
                                                    />
                                                    
                                                </Box>
                                            </Grid>
                                            <Grid item lg={4}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>GST</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnLocationSettingsIdentifyCode"
                                                        value={this.state.editSettingsGst}
                                                        onChange={this.handleEditSettingsGst}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter GST Number"
                                                    />
                                                </Box>
                                            </Grid>
                    </Grid>
                    <Box>
                        <Button id="updateLocationSetting" onClick={this.handleUpdateSettingsLocation} style={newStyles.modalButton} variant="contained">Update Location</Button>
                        <Button onClick={this.handleEditSettingsLocationCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                </Dialog>
                <Dialog maxWidth="xl" style={newStyles.dialog} open={this.state.openEditSettingCategoryModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Category</Typography> 
                    <Grid container >
                                            <Grid item lg={6}>
                                                <Box style={newStyles.gridItem}>
                                                    <Typography style={newStyles.modalLabel}>Serial Number</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnEditCategorySettings"
                                                        value={this.state.editSettingsCategorySL}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display'
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Serial Number"
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item lg={6} >
                                                <Box style={newStyles.gridItem} marginRight={15}>
                                                    <Typography style={newStyles.modalLabel}>Category Name</Typography>
                                                    <StyledTextField
                                                        required
                                                        testID="btnEditLocationSettings"
                                                        value={this.state.editSettingsCategoryName}
                                                        onChange={this.handleEditSettingsCategoryName}
                                                        style={newStyles.textFieldBox}
                                                        inputProps={
                                                            {
                                                                style:
                                                                {
                                                                    fontSize: 16,
                                                                    fontWeight: 400,
                                                                    fontFamily: 'Plus Jakarta Display',
                                                                    
                                                                },
                                                            }}
                                                        variant="outlined"
                                                        placeholder="Enter Category Name"
                                                    />
                                                </Box>
                                            </Grid>
                    </Grid>
                    <Box>
                        <Button id="updateSettingCategoryData" onClick={this.handleUpdateSettingsCategoryData} style={newStyles.modalUpdateCatButton} variant="contained">Update Category</Button>
                        <Button onClick={this.handleEditSettingsCategoryCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                </Dialog>
                
                <Dialog maxWidth="xl" PaperProps={{style:{width:"70%"}}} style={newStyles.dialog} open={this.state.openEditSettingUnitMeasurementModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Update Unit</Typography> 
                    <Grid container>
                      <Grid item lg={4}>
                          <Box style={newStyles.gridItem}>
                              <Typography style={newStyles.modalLabel}>Serial Number</Typography>
                              <StyledTextField
                                  disabled={true}
                                  required
                                  value={this.state.editSettingsUnitMeasurementSL}
                                  style={newStyles.textFieldBoxUnitMeasurement}
                                  inputProps={
                                      {
                                          style:
                                          {
                                              fontSize: 16,
                                              fontWeight: 400,
                                              fontFamily: 'Plus Jakarta Display',
                                          },
                                      }}
                                  variant="outlined"
                                  placeholder="Serial Number"
                              />
                          </Box>
                      </Grid>
                      <Grid item lg={4}>
                          <Box style={newStyles.gridItem}>
                              <Typography style={newStyles.modalLabel}>Unit</Typography>
                              <StyledTextField
                                  required
                                  testID="btnEditUnitMeasurement"
                                  value={this.state.editSettingsUnitMeasurement}
                                  onChange={this.handleEditSettingsUnitMeasurement}
                                  style={newStyles.textFieldBoxUnitMeasurement}
                                  inputProps={
                                      {
                                          style:
                                          {
                                              fontSize: 16,
                                              fontWeight: 400,
                                              fontFamily: 'Plus Jakarta Display'
                                          },
                                          //disabled: this.state.enableEditInformationSettings ? true : false,
                                      }}
                                  variant="outlined"
                                  placeholder="Enter Unit of Measurement"
                              />
                          </Box>
                      </Grid>
                    </Grid>
                    <Box>
                        <Button id="updateUnitMeasurementData" onClick={this.handleUpdateSettingsUnitMeasurementData} style={newStyles.modalButton} variant="contained">Update Unit</Button>
                        <Button onClick={this.handleEditSettingsUnitMeasurementCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                    </Box>
                </Dialog>

                <Dialog maxWidth="md" style={newStyles.userAccessPermissionDialog} open={this.state.openUserPermissionsModal}>
                    <Typography style={newStyles.modalHeading} variant='h5'>Add User Permissions</Typography>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                            <Box style={newStyles.gridItemforUserPermissions}>
                                <FormControl>
                                    <FormGroup>
                                        <FormControlLabel
                                            label={<Typography style={this.userListPermissionStyleSelectAll()}>Select All</Typography>}
                                            control={<Checkbox
                                                name="selectAll"
                                                id="userPermissionAll"
                                                checked={this.state.selectAllPermissions}
                                                checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                icon={<img src={uncheckedUserPermissionIcon} />}
                                                onChange={this.handleUsersSettingSelectAllCheckboxChange.bind(this,USER_PERMISSIONS_ARRAY)}
                                            />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </Grid>

                        <Grid style={newStyles.userPermissionGrid} item xs={12} md={6}  lg={6} sm={12}  >
                        </Grid>

                        {USER_PERMISSIONS_ARRAY?.map((permission : any) => (
                            <Grid  sm={12} xs={12}  item lg={6} style={newStyles.userPermissionGrid} md={6}   key={permission?.name}>
                                <Box style={newStyles.gridItemforUserPermissions}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel
                                            className="btnPermissionChange"
                                                label={<Typography style={this.userListPermissionStyle(permission?.label)}>{permission?.label}</Typography>}
                                                control={<Checkbox
                                                    icon={<img src={uncheckedUserPermissionIcon} />}
                                                    onChange={this.handleUsersSettingCheckboxChange.bind(this,permission.name)}
                                                    checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                    checked={this.state.selectedUserPermissions.includes(permission?.name)}
                                                    name={permission?.name}
                                                />}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12}  md={12} lg={6} sm={12} >
                        </Grid>
                        <Grid  md={12} sm={12} xs={12} item lg={6}>
                            <Box style={newStyles.gridItem}>
                                <Button id="saveUserAccess" style={newStyles.modalButton} onClick={this.handleSaveUserAccessPermissions} variant="contained">{this.handleCircularProgressBarr(this.state.isUserPermissionsCreating, "Add Permissions")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Dialog>
                    </Container>
                </ThemeProvider>


                {
                    this.state.showUserSettings && <ThemeProvider theme={theme} >
                    <Box>
                        {this.state.usersList?.length > 0 ? <TableContainer style={newStyles.tableContainer}>
                        <Table>
                            {this.state.usersList?.slice((this.state.itemsPerPage * (this.state.currentPage - 1)), (this.state.itemsPerPage * (this.state.currentPage)))
                                .map((data: any, index: number) => { 
                                    return <TableDisplay
                                        data-testid="tableDisplay"
                                        data={data}
                                        index={index}
                                        key={data?.id}
                                        //selectedRow={this.state.selectedRow} 
                                        checkedIcon={checkedRadioButton}
                                        uncheckedIcon={uncheckedRadioButton}
                                        radio={true}
                                        headerData={['', 'Name', 'Designation', 'Email', 'Role', '']}
                                        displayData={[data?.attributes?.name, data?.attributes?.designation, data?.attributes?.email, data?.attributes?.role]}
                                        icons={[
                                            {
                                                icon: 'User Permissions',
                                                dataTestId:"btnUserAccessPermissions",
                                                handleOnClick: this.handleEditedUserAccessPermissions,
                                                parameter: data,
                                                styling: newStyles.tableBodyRowsUnderline,
                                            },
                                            {
                                                icon: DeleteOutline,
                                                dataTestId:"btnDeleteUser",
                                                handleOnClick: this.handleDeleteUser,
                                                parameter: data?.id,
                                                styling: newStyles.iconsSpacing,
                                            },
                                        ]}

                                    />
                                })}
                        </Table>
                        </TableContainer> : <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />}

                        <PaginationDisplay
                            data-test-id="btnPageChangeDisp6"
                            totalPages={this.state.noOfPages}
                            currentPage={this.state.currentPage}
                            handlePageChange={this.handlePageChange}
                            styling={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
                        />
                    </Box>

                    <Dialog maxWidth="md" style={newStyles.userAccessPermissionDialog} open={this.state.openEditedUserPermissionsModal}>
                        <ToastMessageDisplay id="editedUserPermissionModalMessage" message={this.state.userPermissionMissingIdErrorMessage} handleClose={this.handleUserPermissionMissingIdErrorMessageSnackbarClose} />
                        <Typography style={newStyles.modalHeading} variant='h5'>Update User Permissions</Typography>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                                <Box style={newStyles.gridItemforUserPermissions}>
                                    <FormControl>
                                        <FormGroup>
                                            <FormControlLabel 
                                                label={<Typography style={this.userListPermissionStyleSelectAll()}>Select All</Typography>}
                                                className="editUserPermission"
                                                control={<Checkbox
                                                    checked={this.state.editedSelectAllPermissions}
                                                    indeterminate={!this.state.editedSelectAllPermissions && this.state.editedSelectedUserPermissions.length > 0}
                                                    onChange={(e)=>this.handleEditedUsersSettingSelectAllCheckboxUpdationChange(e, USER_PERMISSIONS_ARRAY)}
                                                    name="selectAll"
                                                    checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                    icon={<img src={uncheckedUserPermissionIcon} />}
                                                />}
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                            </Grid>

                            {USER_PERMISSIONS_ARRAY?.map((permission : any) => (
                                <Grid key={permission?.name} item lg={6} md={6} sm={12} xs={12} style={newStyles.userPermissionGrid}>
                                    <Box style={newStyles.gridItemforUserPermissions}>
                                        <FormControl>
                                            <FormGroup>
                                                <FormControlLabel
                                                    className="btnEditPermissionChange"
                                                    label={<Typography style={this.userListPermissionStyle(permission?.label)}>{permission?.label}</Typography>}
                                                    control={<Checkbox
                                                        checked={this.state.editedSelectedUserPermissions.includes(permission.name)}
                                                        onChange={this.handleEditedUsersSettingCheckboxUpdationChange.bind(this,permission?.name, USER_PERMISSIONS_ARRAY)}
                                                        checkedIcon={<img src={checkedUserPermissionIcon} />}
                                                        icon={<img src={uncheckedUserPermissionIcon} />}
                                                        name={permission?.name}
                                                    />}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                            </Grid>
                            <Grid item lg={6} md={12} sm={12} xs={12}>
                                <Box style={newStyles.gridItem}>
                                    <Button data-test-id="btnCloseUserPermissionsModal" style={newStyles.modalCancelButton} onClick={this.handleEditedCloseUserAccessPermissionsModal} variant="contained">Cancel</Button>
                                    <Button id="editUserAccessPermiss" disabled={this.state.createUserPermissionsValidation} style={newStyles.modalButton} onClick={this.getEditedUserAccessPermissionUpdation.bind(this,this.state.editedCatchIdForUserPermissionsUpdation)} variant="contained">{this.handleCircularProgressBarr(this.state.isUserSettingPermissionsUpdating, "Update Permissions")}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Dialog>

                    </ThemeProvider>
                }

            </>
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#fff',
        },
    },
    typography: {
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: '20px 0px',
        },
        button: {
            textTransform: 'none',
        }
    },

});

const newStyles = {
    logoImg: {
        width: '129.63px',
        height: '40px',
        paddingLeft: '20px',
        paddingTop: '40px',
    },
    textFieldBox1: {
        backgroundColor: '#F2F2EC',
        width: '328px',
        height: '56px',
        borderRadius: '10px',
        //paddingBottom: '30px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        // width: '224px',
        // height: '24px',
        //marginLeft: '0px',
        marginTop: '16px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        fontWeight: '400',
        boxSizing: 'border-box' as 'border-box',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
    },
    headerContainer: {
        display: 'flex',
        displayDirection: 'column',
        flex: '1',
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        margin: 'auto',
        height: '120px',
        width: '100vw',
        boxSizing: 'border-box' as 'border-box',
    },
    mainContainer: {
        //position: 'absolute' as 'absolute',
        backgroundColor: 'rgba(242, 242, 236, 1)',
        width: '100%',
        flex: '1',
        alignItems: "flex-start",
        boxSizing: 'border-box' as 'border-box',
    },
    
    avatar: {
        color: 'white',
        backgroundColor: 'blue',
        flex: '1',
        //paddingLeft: '40px',
    },
    avatarDropdownMenu: {
        margin: 0,
        paddingLeft: '50px',
        flex: '1',
    },
    tabsContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#F8F8F5',
        margin: 'auto',
        height: '96px',
        width: '100vw',
        boxShadow: '#F0F0ED 0px 60px 60px -80px inset',
        boxSizing: 'border-box' as 'border-box',
    },
    tabMenu: {
        //height: '29px',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 400,
        paddingTop: '35px',
        marginLeft: '40px',
        lineSpacing: '29',
        letterSpacing: '-0.5',
    },
    divider: {
        width: '1px',
        height: '30px',
        marginLeft: '70px',
        marginTop: '28px',
    },
    avatarContainer: {
        display: 'flex',
        displayDirection: 'column',
        height: '40px',
        //marginLeft: 'auto',
        paddingRight: '20px',
        paddingTop: '40px',
        justifyContent: 'baseline',
    },
    dropDown: {
        minWidth: '328px',
        maxHeight: '56px',
        marginLeft: '20px',
        marginTop: '15px',
        backgroundColor: '#F2F2EC',
        fontSize: '16',
        margin: 'dense',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    dropDownItems: {
        lineSpacing: '19',
        letterSpacing: '-0.33',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    menuListItems: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        paddingTop: '30px',
        lineSpacing: '19',
        letterSpacing: '-0.33',
        marginRight: '30px',
        justifyContent: 'space-between'
    },
    menuListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        height: '86px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '35px',
        display: 'flex',
        boxSizing: 'border-box' as 'border-box',
    },
    tableContainer: {
        //height: '300px',
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        //height: '443px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginBottom: '20px',
        marginTop: '5px',
        //paddingTop: '5px',
        paddingBottom: '50px',
        paddingLeft: '20px',
        paddingRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    tableBodyRows: {
        lineSpacing: '19',
        letterSpacing: '0',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
    },
    tableBodyRowsUnderline: {
        lineSpacing: '19',
        letterSpacing: '0',
        fontSize: '16',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
        textDecoration: 'underline',
    },
    icons: {
        fontSize: 'medium',
        paddingRight: '10px',
        cursor: 'pointer',
    },
    tableHeaderRows: {
        lineSpacing: '17',
        letterSpacing: '-0.29',
        fontFamily: 'Plus Jakarta Display',
        fontWeight: 400,
        fontSize: '14',
    },
    iconColumn: {
        whiteSpace: 'nowrap' as 'nowrap',
    },
    iconsSpacing: {
        fontSize: 'medium',
        paddingRight: '10px',
        cursor: 'pointer',
        marginLeft: '30px',
    },
    button: {
        //position: 'absolute',
        position: 'relative' as 'relative',
        width: '328px',
        height: '56px',
        // marginLeft: '556px',
        // marginTop: '645px',
        marginLeft: '81vw',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    createButton: {
        marginLeft: 'auto',
        //justifyContent: 'flexEnd',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        // marginLeft: 'auto',
        // marginRight: '0px',
        //float: 'right' as 'right',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        //justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        //padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        //textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        //justifySelf: 'flexEnd',
        //alignContent: 'flexEnd',
    },
    // createPOButton: {
    //     marginLeft: 'auto',
    //     position: 'relative' as 'relative',
    //     width: '289px',
    //     height: '56px',
    //     // marginRight: 'auto',
    //     marginBottom: '20px',
    //     opacity: '1',
    //     backgroundColor: 'rgba(255, 255, 0, 1)',
    //     borderRadius: '10px',
    //     boxSizing: 'border-box' as 'border-box',
    //     color: 'rgba(28, 28, 26, 1)',
    //     textAlignVertical: 'top',
    //     fontFamily: 'Plus Jakarta Display',
    //     fontSize: '18',	
    // },
    createButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '70px',
        boxSizing: 'border-box' as 'border-box',
    },
    createButton1: {
        marginLeft: '10px',
        //justifyContent: 'flexEnd',
        position: 'relative' as 'relative',
        width: '289px',
        height: '56px',
        // marginLeft: 'auto',
        // marginRight: '0px',
        //float: 'right' as 'right',
        marginRight: '2%',
        marginBottom: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        boxSizing: 'border-box' as 'border-box',
        //justifyContent: 'center',
        //padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        //textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontSize: '18',
        fontFamily: 'Plus Jakarta Display',
    },
    createLongTextButtonIcon: {
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        marginRight: '20px',
        boxSizing: 'border-box' as 'border-box',
    },
    modalButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        margin: '20px',
        // marginLeft: '556px',
        // marginTop: '645px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },

    modalUpdateCatButton: {
        position: 'relative' as 'relative',
        width: '180px',
        height: '56px',
        margin: '20px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '6px',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    fileAttachmentButtonIcon: {
        //color: 'rgba(28, 28, 26, 1)',
        //textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        fontWeight: 600,
        //marginRight: '70px',
        boxSizing: 'border-box' as 'border-box',
    },
    errorModalButton: {
        //width: '160px',
        position: 'relative' as 'relative',
        width: '100px',
        //height: '56px',
        height: '45px',
        //margin: '20px',
        margin: 'auto 0px',
        marginTop: '15px',
        marginBottom: '15px',
        // marginLeft: '556px',
        // marginTop: '645px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    modalButtonRight: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        margin: '20px',
        marginTop: '40px',
        marginRight: '30px',
        // marginLeft: '556px',
        // marginTop: '645px',
        opacity: '1',
        backgroundColor: 'rgba(255, 255, 0, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        float: 'right' as 'right'
    },
    modalCancelButton: {
        position: 'relative' as 'relative',
        width: '160px',
        height: '56px',
        margin: '20px',
        // marginLeft: '556px',
        // marginTop: '645px',
        opacity: '1',
        //backgroundColor: 'rgba(255, 255, 0, 1)',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        // fontStyle: 'normal',
        // fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    attachmentButton: {
        width: '210px',
        height: '56px',
        opacity: '1',
        backgroundColor: '#FAEBE6',
        borderRadius: '10px',
        justifyContent: 'center',
        boxSizing: 'border-box' as 'border-box',
        includeFontPadding: false,
        padding: '0',
        color: '#FD6533',
        textAlign: 'center' as 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
    },
    buttonText: {
        position: 'absolute',
        width: '104',
        height: '16',
        marginLeft: '16',
        marginTop: '14',
        opacity: '1',
        backgroundColor: 'transparent',
        fontStyle: 'normal',
        fontWeight: 'normal',
        includeFontPadding: false,
        padding: '0',
        color: 'rgba(28, 28, 26, 1)',
        textAlign: 'center',
        textAlignVertical: 'top',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '18',
        boxSizing: 'border-box' as 'border-box',
    },
    dialog: {
        margin: '0 auto',
        //height: '431px',
        width: '1360px',
        boxSizing: 'border-box' as 'border-box',
    },
    userAccessPermissionDialog: {
        margin: '0 auto',
        //width: '965px',
        boxSizing: 'border-box' as 'border-box',
    },
    errorDialog: {
        padding: '100px',
        width: '1200px',
        marginTop: '100px',
        marginBottom: '100px',
    },
    errorBox: {
        padding: '0 30px 0 30px',
        marginTop: '30px',
        //marginBottom: '30px',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,

    },
    textFieldBox: {
        //padding: '20px',
        //zIndex: 4,
        //marginTop: '30px',
        backgroundColor: '#FFFFFF',
        width: '400px',
        // minHeight: '40px',
        minHeight: '60px',
        borderRadius: '10px',
        //paddingBottom: '10px',
        //paddingTop: '10px',
        //margin: '0 auto',
        position: 'relative' as 'relative',
        //marginLeft: '0px',
        //marginTop: '16px',
        marginBottom: '10px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box'
    },
    textFieldBoxUnitMeasurement: {
      //padding: '20px',
      //zIndex: 4,
      //marginTop: '30px',
      backgroundColor: '#FFFFFF',
      width: '270px',
      // minHeight: '40px',
      minHeight: '60px',
      borderRadius: '10px',
      //paddingBottom: '10px',
      //paddingTop: '10px',
      //margin: '0 auto',
      position: 'relative' as 'relative',
      //marginLeft: '0px',
      //marginTop: '16px',
      marginBottom: '10px',
      opacity: '1',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '16',
      fontWeight: 400,
      boxSizing: 'border-box' as 'border-box',
  },
    textFieldBoxSmall: {
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        width: '182px',
        minHeight: '60px',
        borderRadius: '10px',
        position: 'relative' as 'relative',
        marginBottom: '10px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    DateBox: {
        //padding: '20px',
        marginTop: '15px',
        backgroundColor: '#FFFFFF',
        width: '400px',
        // minHeight: '40px',
        minHeight: '60px',
        borderRadius: '10px',
        paddingBottom: '10px',
        //paddingTop: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        //marginLeft: '0px',
        //marginTop: '16px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    modalHeading: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 600,
        padding: '20px',
        marginBottom: '30px',
        boxSizing: 'border-box' as 'border-box',
        color: 'black',
    },
    modalHeadingArrow: {
        fontFamily: 'Plus Jakarta Display',
        fontSize: '24',
        fontWeight: 600,
        marginBottom: '30px',
        boxSizing: 'border-box' as 'border-box',
        color: 'black',
        paddingTop: '24px',
    },
    gridItem: {
        paddingLeft: '20px',
        //paddingRight: '20px',
        paddingBottom: '20px',
        marginLeft: '20px'
    },
    gridItemforUserPermissions: {
        paddingLeft: '20px',
        //paddingRight: '20px',
        paddingBottom: '20px',
        marginBottom: '5%',
    },
    gridItemLabel: {
        marginBottom: '10px',
        fontFamily: 'Plus Jakarta Display',
    },
    selectBox: {
        //backgroundColor: '#F2F2EC', 
        width: '400px', //added
        minHeight: '56px',
        borderRadius: '10px',
        border: '#B1B1AE 0.005px solid',
        marginBottom: '10px',
        //paddingLeft: '7px',
        boxSizing: 'border-box' as 'border-box',
    },
    //   selectBoxLabel: {
    //     fontFamily: 'Plus Jakarta Display',
    //     color: '#B1B1AE',
    //     backgroundColor: '#F2F2EC', 
    //     minHeight: '60px', 
    //     borderRadius: '10px',
    //     paddingLeft: '7px',
    //     boxSizing: 'border-box' as 'border-box',
    //   },
    textFieldEndAdornment: {
        color: '#B1B1AE',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 300,
    },
    selectBoxLabel: {
        color: '#B1B1AE',
        width: '400px', //added
        minHeight: '56px',
        borderRadius: '10px',
        border: '#B1B1AE 0.005px solid',
        marginBottom: '10px',
        //paddingLeft: '7px',
        boxSizing: 'border-box' as 'border-box',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '16',
        fontWeight: 350,
    },
    modalLabel: {
        //zIndex: 3,
        position: 'relative' as 'relative',
        // left: 0px;
        // top: 0px;
        bottom: '10px',
        //width: '101px',
        height: '15px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '15px',
        // marginBottom: '10px',
    },
    validationText: {
        textAlign: 'right' as 'right',
        position: 'relative' as 'relative',
        width: '325px',
        //height: '16px',
        margin: '0 auto',
        // marginLeft: '30px',
        //justifyContent: 'flexEnd',
        opacity: '1',
        backgroundColor: 'transparent',
        padding: '0px',
        color: 'rgba(28, 28, 26, 1)',
        textAlignVertical: 'top',
        //fontFamily: 'PlusJakartaDisplay-Italic',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '12',
        fontStyle: 'italic',
        fontWeight: 400,
        boxSizing: 'border-box' as 'border-box',
    },
    termsHeadingBottom: {
        //width: '138px',
        height: '22px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.375px',
        marginTop: '5px',
    },
    termsHeadingTop: {
        //width: '138px',
        height: '22px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '-0.375px',
        //marginTop: '0px',
    },
    termsBodyTop: {
        height: '17px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '20px',
    },
    termsBodyBottom: {
        height: '17px',
        color: '#808080',
        fontFamily: "Plus Jakarta Display",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '17px',
        letterSpacing: '-0.29166666px',
        marginTop: '15px',
        marginBottom: '15px',
    },
    productText: {
        //position: 'relative' as 'relative',
        marginTop: '30px',
        marginLeft: '25px',
        //top: '840px',
        width: '82px',
        height: '24px',
        color: '#1c1c1a',
        fontFamily: "Plus Jakarta Display",
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '-0.41666666px',
    },
    settingsListContainer: {
        position: 'relative' as 'relative',
        backgroundColor: '#FFFFFF',
        //height: '100px',
        width: '96vw',
        borderRadius: '15px',
        margin: 'auto',
        marginTop: '35px',
        //display: 'flex',
        boxSizing: 'border-box' as 'border-box',
        marginBottom: '20px',
        paddingBottom: '15px'
    },
    settingsTextField: {
        backgroundColor: '#F2F2EC',
        width: '328px',
        minHeight: '56px',
        borderRadius: '10px',
        paddingBottom: '10px',
        margin: '0 auto',
        position: 'relative' as 'relative',
        marginLeft: '0px',
        marginTop: '16px',
        opacity: '1',
        fontFamily: 'Plus Jakarta Display',
        fontSize: '14',
        fontWeight: 300,
        boxSizing: 'border-box' as 'border-box',
    },
    backWithArrow: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'baseline',
        marginRight: 'auto',
        paddingLeft: '180px',
        cursor: 'pointer',
    },
    userPermissionText: {
        fontSize: '24px',
        marginLeft: '10px',
        lineSpacing: 29,
        letterSpacing: -0.5,
    },
    userPermissionsUnchecked: {
        fontSize: '24px',
        marginLeft: '12px',
        marginTop: '1.5px',
        lineSpacing: 29,
        letterSpacing: -0.5,
    },
    userPermissionTextSelectAll: {
        fontSize: '24px',
        marginLeft: '10px',
        lineSpacing: 29,
        letterSpacing: -0.5,
        fontWeight: 600,
    },
    userPermissionsUncheckedSelectAll: {
        fontSize: '24px',
        marginLeft: '12px',
        marginTop: '1.5px',
        lineSpacing: 29,
        letterSpacing: -0.5,
        fontWeight: 600,
    },
    userPermissionGrid: {
        height: '70px'
    }
    // backIcon: {
    //     width: '20px',
    //     height: '14px',
    // },
    // backText: {
    //     width: '32px',
    //     height: '17px',
    //     color: '#1c1c1a',
    //     fontFamily: "Plus Jakarta Display",
    //     fontSize: '14px',
    //     fontWeight: 600,
    //     lineHeight: '17px',
    //     letterSpacing: '-0.15px',
    // },

}
// Customizable Area End
