// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import CreateOrFind from "../../blocks/dashboard/src/CreateOrFind.web";
import Settings from "../../blocks/dashboard/src/Settings.web";
import MyReports from "../../blocks/dashboard/src/MyReports.web";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import MultipleCurrencySupport from "../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import ForgotPassword from "../../blocks/email-account-login/src/ForgotPassword.web";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import SaveAsPdf from "../../blocks/SaveAsPdf/src/SaveAsPdf";
import CfCustomForm24 from "../../blocks/CfCustomForm24/src/CfCustomForm24";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Customform from "../../blocks/customform/src/Customform";
import CreateComment from "../../blocks/comments/src/CreateComment";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import BeeFreeIntegration2 from "../../blocks/BeeFreeIntegration2/src/BeeFreeIntegration2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Notifications from "../../blocks/notifications/src/Notifications";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import QuoteManagement from "../../blocks/QuoteManagement/src/QuoteManagement";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
//import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
//import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web.tsx";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";



const routeMap = {
MultipleCurrencySupport:{
 component:MultipleCurrencySupport,
path:"/MultipleCurrencySupport"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
// EmailAccountLoginBlock:{
//  component:EmailAccountLoginBlock,
// path:"/EmailAccountLoginBlock"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
CfCustomForm24:{
 component:CfCustomForm24,
path:"/CfCustomForm24"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Customform:{
 component:Customform,
path:"/Customform"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
BeeFreeIntegration2:{
 component:BeeFreeIntegration2,
path:"/BeeFreeIntegration2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
CreateOrFind:{
  component:CreateOrFind,
path:"/CreateOrFind"},
Settings:{
  component:Settings,
path:"/Settings"},
MyReports:{
  component:MyReports,
path:"/MyReports"},
QuoteManagement:{
 component:QuoteManagement,
path:"/QuoteManagement"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  EmailAccountLoginBlock:{
    component:EmailAccountLoginBlock,
   path:"/",
   exact: true
  },
   
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
