import React from "react";

// Customizable Area Start
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Radio,
} from '@material-ui/core';

// Customizable Area End

export default class TableDisplay extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
        <>
          {this.props.index===0 && <TableHead>
            <TableRow>
            {this.props.headerData?.map((item: any, index: number) => {
              return <TableCell className="itemCell" style={newStyles.tableHeaderRows} key={index}>{item}</TableCell>
            })}
            </TableRow>
          </TableHead>}
            
          <TableBody>
            <TableRow>
              {this.props.displayData?.map((item: any, index: number) => {
                return <>
                  {index===0 && this.props.radio && <TableCell>
                    <Radio
                        checked={this.props.data?.id === this.props.selectedRow ? true : false}
                        value={item?.id}
                        checkedIcon={<img src={this.props.checkedIcon}/>}
                        icon={<img src={this.props.uncheckedIcon}/>}
                    />
                  </TableCell>}
                  <TableCell style={newStyles.tableBodyRows}>{item}</TableCell>
                  
                </>
              })}
              <TableCell style={newStyles.iconColumn}>
              {this.props.icons?.map((item: any, index: number) => {
                return <span className={item.dataTestId} style={typeof(item.icon) === "string" ? item.styling : null} onClick={() => item.handleOnClick && item.parameter && item.handleOnClick(item.parameter)} key={index}>{typeof(item.icon) === "string" ? item.icon : <item.icon style={item.styling ? item.styling : newStyles.icons}/>}</span>
              })}
              </TableCell> 
            </TableRow>
          </TableBody>
        </>    
    );

  }
}

// Customizable Area Start
const newStyles = {
  tableContainer: {
      //height: '300px',
      position: 'relative' as 'relative',
      backgroundColor: '#FFFFFF',
      //height: '443px',
      width: '96vw',
      borderRadius: '15px',
      margin: 'auto',
      marginBottom: '50px',
      marginTop: '25px',
      paddingTop: '50px',
      paddingBottom: '50px',
      paddingLeft: '20px',
      paddingRight: '20px',
      boxSizing: 'border-box' as 'border-box',
  },
  tableHeaderRows: {
    lineSpacing: '17',
    letterSpacing: '-0.29',
    fontSize: '14',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  tableBodyRows: {
    lineSpacing: '19',
    letterSpacing: '0',
    fontSize: '16',
    fontFamily: 'Plus Jakarta Display',
    fontWeight: 400,
  },
  icons: {
    fontSize: 'medium',
    paddingRight: '10px',
    cursor: 'pointer',
    //marginLeft: '50px', //added
  },
  iconColumn: {
      whiteSpace: 'nowrap' as 'nowrap',
  },

}
// Customizable Area End
