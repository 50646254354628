import React from "react";

// Customizable Area Start
import {
  Paper,
  Container,
  Box,
  TextField,
  Input,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Tabs,
  Tab,
  Radio,
  Dialog,
  Chip,
  CircularProgress,
  DialogTitle,
  DialogActions,
  InputBase,
  Divider, 
  Menu,
  Checkbox
} from "@material-ui/core";
import { checkedRadioButton, uncheckedRadioButton } from "../src/assets";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {
  VisibilityOutlined,
  EditOutlined,
  AddSharp,
} from "@material-ui/icons";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Header from "./Header";
import ToastMessageDisplay from "./ToastMessageDisplay";
import TableDisplay from "./TableDisplay";
import PaginationDisplay from "./PaginationDisplay";
import Loading from "./Loading";
import ViewDetailsDialog from "./ViewDetailsDialog";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DateIcon from '@material-ui/icons/DateRangeOutlined';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';


const StyledTextField: any = withStyles({
  root: {
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      "& fieldset": {
        borderRadius: "10px",
      },
    },

    "& .MuiOutlinedInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "& input::placeholder": {
      fontFamily: "Plus Jakarta Display",
      fontSize: "16",
      fontWeight: 400,
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "transparent",
      border: "0.05px solid black",
    },
    "&:focus": {
      backgroundColor: "white",
      borderColor: "black",
    },
    "& .Mui-focused": {
      border: "0.05px solid black",
      borderRadius: "10px",
      maxHeight: "57px",
    },
  },
})((props: any) => <TextField {...props} />);

const StyledSelect = withStyles({
  root: {
    paddingLeft: 15,
    "& .MuiOutlinedInput-root": {
      backgroundColor: "transparent",
      border: "0.05px solid #B1B1AE",
    },
    "& .MuiOutlinedInput-root:hover": {
      backgroundColor: "transparent",
      border: "0.05px solid #B1B1AE",
      "@media (hover: none)": {
        backgroundColor: "transparent",
        border: "0.05px solid #B1B1AE",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "transparent",
      border: "0.05px solid #B1B1AE",
    },
    "&:focus": {
      backgroundColor: "transparent",
      borderColor: "#B1B1AE",
    },
    "&:before": {
      borderColor: "#B1B1AE",
    },
    "&:after": {
      borderColor: "#B1B1AE",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
})((props: any) => <Select {...props} />);

// Customizable Area End

import DashboardController, { Props } from "./DashboardController";
import ButtonDisplay from "./ButtonDisplay";

export default class CreateOrFind extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  action = () => {
    return (
      <React.Fragment>
        <Button
          data-testid={"undo_click"}
          color="secondary"
          size="small"
          onClick={this.handlePurchaseRequisitionCreateSnackbarClose}
        >
          UNDO
        </Button>
        <IconButton
          data-testid={"prcreate_icon_click"}
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handlePurchaseRequisitionCreateSnackbarClose}
        >
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
  };

  //
  // createOrFind table data records start
  //
  creatOrFindCreateActionBtnRendered = (selectedSubTabValue: number) => {
    if (selectedSubTabValue === 0) {
      return (
        <ButtonDisplay
          data-testid='pr_mainBtn'
          disabled={this.state.isCreateOrFindTabsDisabled}
          id="pr_create_button"
          name="Create Requisition"
          startIcon={AddSharp}
          iconStyling={newStyles.createButtonIcon}
          variant="contained"
          onClickHandle={this.handleBtnOnclickDisabled.bind(
            this,
            'purchase requisitions',
            'to Purchase Requisitions',
            this.handleAddRequisitionOpenModal
          )}
          styling={newStyles.createButton}
        />
      );
    } else if (selectedSubTabValue === 1) {
      return (
        <ButtonDisplay
          disabled={this.state.isCreateOrFindTabsDisabled}
          data-testid={"rfp_mainBtn"}
          name="Create RFP"
          startIcon={AddSharp}
          iconStyling={newStyles.createButtonIcon}
          variant="contained"
          onClickHandle={this.handleAddRequestForProposalsOpenModal}
          styling={newStyles.createButton}
        />
      )
    } else if (selectedSubTabValue === 2) {
      return (
        <Box style={{ width: "35%" }}>
          <StyledSelect
            disabled={this.state.isCreateOrFindTabsDisabled}
            disableUnderline
            id="selectOptionPo"
            value={this.state.selectPoOptions}
            onChange={this.handleSelectPoOptions}
            onClose={() => this.setState({ selectPoOptions: '' })}
            displayEmpty
            input={<Input />}
            variant="outlined"
            style={newStyles.createPOButton}
            autoFocus={false}
          >
            <MenuItem value="" selected disabled>Create Purchase Order</MenuItem>
            <MenuItem onClick={this.handleAddPurchaseOrderOpenModal} value="Create New PO" autoFocus={false}>
              Create New PO
            </MenuItem>
            <MenuItem onClick={this.handlePurchaseRequisitionListOpenModal} value="Custom from Requisition" autoFocus={false}>
              Custom from Requisition
            </MenuItem>
          </StyledSelect>
        </Box>
      );
    }
    else if (selectedSubTabValue === 3) {
      return (
        <ButtonDisplay
          disabled={this.state.isCreateOrFindTabsDisabled}
          name="Create Receipt"
          id="selectGetReceipt"
          startIcon={AddSharp}
          iconStyling={newStyles.createButtonIcon}
          variant="contained"
          onClickHandle={this.handleAddReceiptOpenModal}
          styling={newStyles.createButton}
        />
      )
    }
    else if (selectedSubTabValue === 4) {
      return (
        <ButtonDisplay
          disabled={this.state.isCreateOrFindTabsDisabled}
          data-testid={"supplier_mainBtn"}
          name="Add Supplier"
          startIcon={AddSharp}
          iconStyling={newStyles.createButtonIcon}
          variant="contained"
          onClickHandle={this.handleAddSupplierOpenModal}
          styling={newStyles.createButton}
        />
      );
    } else if (selectedSubTabValue === 5) {
      return <ButtonDisplay
        disabled={this.state.isCreateOrFindTabsDisabled}
        name="Budget"
        startIcon={AddSharp}
        iconStyling={newStyles.createButtonIcon}
        variant="contained"
        onClickHandle={this.handleAddBudgetOpenModal}
        styling={newStyles.createButton}
      />
    }
  };

  handlePurchaseRequisitionListDataRenderedInTableFormat = () => {
    if (
      this.state.purchaseRequisitionList &&
      this.state.purchaseRequisitionList?.length > 0
    ) {
      return (
        <>
          <TableContainer style={newStyles.tableContainer}>
            <Table>
              {this.state.purchaseRequisitionList?.slice(
                this.state.itemsPerPage *
                (this.state.currentPagePurchaseRequisition - 1),
                this.state.itemsPerPage *
                this.state.currentPagePurchaseRequisition
              )
                ?.map((data: any, index: number) => {
                  return (
                    <TableDisplay
                      key={data.id}
                      className={"pr_records"}
                      data={data}
                      index={index}
                      selectedRow={this.state.selectedRow}
                      checkedIcon={checkedRadioButton}
                      uncheckedIcon={uncheckedRadioButton}
                      radio={true}
                      headerData={[
                        "",
                        "PR Number",
                        "Requester",
                        "Creation Date",
                        "Delivery Date",
                        "Location",
                        "Department",
                        "Status",
                        "",
                      ]}
                      displayData={[
                        data?.attributes?.purchase_requisition_number,
                        data?.attributes?.account?.user_name,
                        data?.attributes?.pr_creation_date,
                        data?.attributes?.delivery_date,
                        data?.attributes?.location_setting_id?.name_of_location,
                        data?.attributes?.department_id?.name,
                        data?.attributes?.status,
                      ]}
                      handleShowOpenModal={this.handleShowRequisitionOpenModal}
                      handleEditOpenModal={this.handleEditRequisitionOpenModal}
                      icons={[
                        {
                          icon: VisibilityOutlined,
                          handleOnClick: this.handleShowRequisitionOpenModal,
                          parameter: data?.id,
                        },
                      ]}
                    />
                  );
                })}
            </Table>
          </TableContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <PaginationDisplay
              totalPages={this.state.totalPagesPurchaseRequisition}
              currentPage={this.state.currentPagePurchaseRequisition}
              handlePageChange={this.handlePurchaseRequisitionPageChange}
            />
          </Box>
        </>
      );
    } else {
      return (
        <Loading
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  handleRequestForProposalsListDataRenderedInTableFormat = () => {
    if (this.state.rfpList?.length > 0) {
      return <TableContainer id={"rfp_table_container"} style={newStyles.tableContainer}>
        <Table >
          {this.state.rfpList?.slice((this.state.itemsPerPage * (this.state.currentPageRfp - 1)), (this.state.itemsPerPage * (this.state.currentPageRfp)))
            .map((rfpData: any, index: number) => {
              return <TableDisplay
                key={rfpData?.id}
                className={"rfp_records"}
                data={rfpData}
                index={index}
                selectedRow={this.state.rfpSelectedRow}
                checkedIcon={checkedRadioButton}
                uncheckedIcon={uncheckedRadioButton}
                radio={true}
                headerData={['', 'RFP Number', 'RFP Name', 'Creation Date', 'Due Date', 'Number of Suppliers', 'Status', '']}
                displayData={[rfpData?.attributes?.rfp_number, rfpData?.attributes?.account?.user_name, rfpData?.attributes?.creation_date, rfpData?.attributes?.due_date, rfpData?.attributes?.number_of_supplier, rfpData?.attributes?.status, '']}
                icons={[
                  {
                    icon: VisibilityOutlined,
                    handleOnClick: this.handleShowRFPOpenModal,
                    parameter: rfpData?.id,
                  }
                ]}
              />
            })}
        </Table>
      </TableContainer>
    }
    else {
      return <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />
    }
  }


  handlePurchaseOrdersListDataRenderedInTableFormat = () => {
    if (this.state.purchaseOrdersList?.length > 0) {
      return (
        <TableContainer style={newStyles.tableContainer}>
          <Table>
            {this.state.purchaseOrdersList
              ?.slice(
                this.state.itemsPerPage *
                (this.state.currentPagePurchaseOrder - 1),
                this.state.itemsPerPage * this.state.currentPagePurchaseOrder
              )
              .map((purchaseOrderData: any, index: number) => {
                return (
                  <TableDisplay
                    key={purchaseOrderData?.id}
                    className={"po_records"}
                    data={purchaseOrderData}
                    index={index}
                    selectedRow={this.state.purchaseOrderSelectedRow}
                    checkedIcon={checkedRadioButton}
                    uncheckedIcon={uncheckedRadioButton}
                    radio={true}
                    headerData={[
                      "",
                      "PO Number",
                      "Supplier",
                      "Requester",
                      "Due Date",
                      "Location",
                      "Department",
                      "Status",
                      "",
                    ]}
                    displayData={[
                      purchaseOrderData?.attributes?.purchase_order_number,
                      purchaseOrderData?.attributes?.supplier?.data?.attributes
                        ?.supplier_name,
                      purchaseOrderData?.attributes?.account?.user_name,
                      purchaseOrderData?.attributes?.order_date,
                      purchaseOrderData?.attributes?.shipping_address,
                      purchaseOrderData?.attributes?.department?.name,
                      purchaseOrderData?.attributes?.status,
                    ]}
                    icons={[
                      {
                        icon: VisibilityOutlined,
                        handleOnClick: this.handleShowPurchaseOrderOpenModal,
                        parameter: purchaseOrderData?.id,
                      },
                    ]}
                  />
                );
              })}
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <Loading
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  handleRecieptsDataRenderedInTableFormat = () => {
    if (this.state.receiptsList?.length > 0) {
      return;
    } else {
      return (
        <Loading
          data-testid={'reciept_loading1'}
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  // suppliers management
  handleSuppliersListRenderedInTableFormat = () => {
    if (this.state.suppliersList?.length > 0) {
      return (
        <TableContainer style={newStyles.tableContainer}>
          <Table>
            {this.state.suppliersList
              ?.slice(
                this.state.itemsPerPage * (this.state.currentPageSupplier - 1),
                this.state.itemsPerPage * this.state.currentPageSupplier
              )
              .map((supplierData: any, index: number) => {
                return (
                  <TableDisplay
                    key={supplierData?.id}
                    className={"suppliers_records"}
                    data={supplierData}
                    index={index}
                    selectedRow={this.state.supplierSelectedRow}
                    checkedIcon={checkedRadioButton}
                    uncheckedIcon={uncheckedRadioButton}
                    radio={true}
                    headerData={[
                      "",
                      "Supplier Name",
                      "Unique Code",
                      "Terms of Payment",
                      "Address of Supplier Company",
                      "",
                    ]}
                    displayData={[
                      supplierData?.attributes?.supplier_name,
                      supplierData?.attributes?.unique_code,
                      supplierData?.attributes?.payment_terms?.data.map((payment: any)=>(payment?.attributes?.payment_term_name))?.join(", "),
                      supplierData?.attributes?.address_of_supplier_company,
                    ]}
                    icons={[
                      {
                        icon: VisibilityOutlined,
                        handleOnClick: this.handleShowSupplierOpenModal,
                        parameter: supplierData?.id,
                      },
                      {
                        icon: EditOutlined,
                        handleOnClick: this.handleEditSupplierOpenModal,
                        parameter: supplierData,
                      },
                    ]}
                  />
                );
              })}
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <Loading
          data-testid={"loader_active"}
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  handleProductsListRenderedInTableFormat = () => {
    if (this.state.productsList?.length > 0) {
      return (
        <TableContainer style={newStyles.tableContainer}>
          <Table>
            {this.state.productsList
              ?.slice(
                this.state.itemsPerPageProduct *
                (this.state.currentPageProduct - 1),
                this.state.itemsPerPageProduct * this.state.currentPageProduct
              )
              .map((productData: any, index: number) => {
                const suppliers_data = productData?.attributes?.supplier?.data?.map((supplier: any) => supplier.attributes.supplier_name)
                const jointNames = suppliers_data?.join(', ')
                return (
                  <TableDisplay
                    key={productData?.id}
                    className={"product_records"}
                    data={productData}
                    index={index}
                    selectedRow={this.state.productSelectedRow}
                    checkedIcon={checkedRadioButton}
                    uncheckedIcon={uncheckedRadioButton}
                    radio={true}
                    headerData={[
                      "",
                      "Item ID",
                      "Supplier",
                      "Unit Measurement",
                      "Unit Price",
                      "Product Category",
                      "Type",
                      "",
                    ]}
                    displayData={[
                      productData?.attributes?.item_id,
                      jointNames,
                      productData?.attributes?.unit_measurement,
                      productData?.attributes?.unit_price,
                      productData?.attributes?.product_category?.name,
                      productData?.attributes?.item_type,
                    ]}
                    icons={[
                      {
                        icon: VisibilityOutlined,
                        handleOnClick: this.handleShowProductOpenModal,
                        parameter: productData?.id,
                      },
                      {
                        icon: EditOutlined,
                        handleOnClick: this.handleEditProductOpenModal,
                        parameter: productData,
                      }
                    ]}
                  />
                );
              })}
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <Loading
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  handleBudgetsListRenderedDataRenderedInTableFormat = () => {
    if (this.state.budgetList?.length > 0) {
      return;
    } else {
      return (
        <Loading
          data-testid={"budgets_loader_active"}
          variant="rect"
          animation="wave"
          styling={newStyles.tableContainer}
        />
      );
    }
  };

  handleMainPurchaseRequisitionDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 0) {
      return (
        <Box>
          <ToastMessageDisplay
            data-testid={"close_pr_snackbar"}
            message={this.state.purchaseRequisitionCreateMessage}
            handleClose={this.handlePurchaseRequisitionCreateSnackbarClose}
          />
          <ToastMessageDisplay
            data-testid={"close_pr_snackbar2"}
            message={this.state.purchaseRequisitionEditMessage}
            handleClose={this.handlePurchaseRequisitionEditSnackbarClose}
          />
          {this.handlePurchaseRequisitionListDataRenderedInTableFormat()}
        </Box>
      );
    }
  };

  handleMainRequestForProposalsDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 1) {
      return <Box>
        <ToastMessageDisplay message={this.state.rfpCreateMessage} handleClose={this.handleRfpCreateSnackbarClose} />
        <ToastMessageDisplay message={this.state.rfpEditMessage} handleClose={this.handleRfpEditSnackbarClose} />
        {
          this.handleRequestForProposalsListDataRenderedInTableFormat()
        }
        <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: "center" }}>
          <PaginationDisplay
            totalPages={this.state.totalPagesRfp}
            currentPage={this.state.currentPageRfp}
            handlePageChange={this.handleRfpPageChange}
          />
        </Box>
      </Box>
    }
  }


  handleMainPurchaseOrdersDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 2) {
      return (
        <Box>
          <ToastMessageDisplay
            message={this.state.poCreateMessage}
            handleClose={this.handlePoCreateSnackbarClose}
          />
          <ToastMessageDisplay
            message={this.state.poEditMessage}
            handleClose={this.handlePoEditSnackbarClose}
          />
          {this.handlePurchaseOrdersListDataRenderedInTableFormat()}
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <PaginationDisplay
              totalPages={this.state.totalPagesPurchaseOrder}
              currentPage={this.state.currentPagePurchaseOrder}
              handlePageChange={this.handlePurchaseOrderPageChange}
            />
          </Box>
        </Box>
      );
    }
  };

  handleSuccessCreateReceiptSnackBarFunc = () => {
    if (this.state.receiptCreateMessage === "Receipt is created") {
      return (<ToastMessageDisplay
        message={this.state.receiptCreateMessage}
        handleClose={this.handleReceiptCreateSnackbarClose}
      />)
    }
  }

  handleErrorCreateReceiptSnackBarFunc = () => {
    if (this.state.receiptCreateMessage === "Receipt is not created") {
      return (<ToastMessageDisplay
        message={this.state.receiptCreateMessage}
        handleClose={this.handleReceiptCreateSnackbarClose}
      />)
    }else if(this.state.receiptAttachment === ''){
      return (<ToastMessageDisplay
        message={this.state.receiptSelectFileTypeMessage}
        handleClose={this.handleReceiptFileTypeSnackbarClose}
      />)
    }
  }



  subhandleMainRecieptsAllDataContainerCatelogNestedFunc = (data: any) => {
    if (data?.attributes?.receipt_purchase_order_products) {
      return (
        data?.attributes?.receipt_purchase_order_products
          ?.map((elem: any, index: any) => {
            return (
              <TableRow
                onClick={() => this.handleReceiptSelectedRow(data?.id)}
                className="show_receipt_row"
                key={data?.id}
              >
                <TableCell>
                  <Radio
                    icon={<img src={uncheckedRadioButton} />}
                    checkedIcon={<img src={checkedRadioButton} />}
                    checked={
                      data?.id == this.state.receiptSelectedRow
                        ? true
                        : false
                    }
                    value={data?.id}
                  />
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.receipt_number}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.purchase_order?.purchase_order_number}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.id}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.quantity_required}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.quantity_received}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}
                >
                  {
                    data?.attributes?.supplier_invoice_number
                  }
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.receipt_date}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.comments}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.status}
                </TableCell>
                <TableCell style={newStyles.iconColumn}>
                  <VisibilityOutlinedIcon
                    className="show_reciepts_iconbtn"
                    onClick={
                      this.handleShowReceiptOpenModal.bind(this, data?.id, elem?.id, 'catelog_show')
                    }
                  />
                </TableCell>
              </TableRow>
            )
          })
      )
    }
  }

  subhandleMainRecieptsAllDataContainerNonCatelogNestedFunc = (data: any) => {
    if (data?.attributes?.receipt_non_catalogue_products) {
      return (

        data?.attributes?.receipt_non_catalogue_products
          ?.map((elem: any, index: any) => {
            return (
              <TableRow
                className='noncat_receiptSelectedRow'
                key={data.id}
                onClick={() => this.handleReceiptSelectedRow(data?.id)}
              >
                <TableCell>
                  <Radio
                    checked={
                      data?.id == this.state.receiptSelectedRow
                        ? true
                        : false
                    }
                    value={data?.id}
                    checkedIcon={<img src={checkedRadioButton} />}
                    icon={<img src={uncheckedRadioButton} />}
                  />
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.receipt_number}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.purchase_order?.purchase_order_number}
                </TableCell>
                {/* items number */}
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.id}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.quantity}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.quantity_received}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {
                    data?.attributes?.supplier_invoice_number
                  }
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {data?.attributes?.receipt_date}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.comments}
                </TableCell>
                <TableCell style={newStyles.tableBodyRows}>
                  {elem?.status}
                </TableCell>
                <TableCell style={newStyles.iconColumn}>
                  <VisibilityOutlinedIcon
                    className="noncat_show_receipt"
                    onClick={
                      this.handleShowReceiptOpenModal.bind(this, data?.id, elem?.id, 'non_catelog_show')
                    }
                  />
                </TableCell>
              </TableRow>
            )
          })

      )
    }
  }


  hadnleMainRecieptsDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 3) {
      return (
        <Box>
          {
            this.handleSuccessCreateReceiptSnackBarFunc()
          }

          <TableContainer style={newStyles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={newStyles.tableHeaderRows}></TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Receipt Number
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    PO Number
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Item Number
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Quantity Ordered
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Quantity Received
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Supplier Invoice Number
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Creation Date
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Remark
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Status
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.receiptsList
                  ?.slice(
                    this.state.itemsPerPage *
                    (this.state.currentPageReceipt - 1),
                    this.state.itemsPerPage * this.state.currentPageReceipt
                  )
                  .map((data: any) => {
                    return (
                      <>
                        {this.subhandleMainRecieptsAllDataContainerCatelogNestedFunc(data)}
                        {this.subhandleMainRecieptsAllDataContainerNonCatelogNestedFunc(data)}
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {this.handleRecieptsDataRenderedInTableFormat()}
          <Box style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
            <PaginationDisplay
              totalPages={this.state.totalPagesReceipt}
              currentPage={this.state.currentPageReceipt}
              handlePageChange={this.handleReceiptPageChange}
            />
          </Box>
        </Box>
      );
    }
  };

  handleSuppliersAndProductsDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 4) {
      return (
        <Box>
          <ToastMessageDisplay
            message={this.state.supplierCreateMessage}
            handleClose={this.handleSupplierCreateSnackbarClose}
          />
          <ToastMessageDisplay
            message={this.state.supplierEditMessage}
            handleClose={this.handleSupplierEditSnackbarClose}
          />
          <ToastMessageDisplay
            message={this.state.productCreateMessage}
            handleClose={this.handleProductCreateSnackbarClose}
          />
          <ToastMessageDisplay
            message={this.state.productEditMessage}
            handleClose={this.handleProductEditSnackbarClose}
          />
          {this.handleSuppliersListRenderedInTableFormat()}
          <PaginationDisplay
            totalPages={this.state.totalPagesSupplier}
            currentPage={this.state.currentPageSupplier}
            handlePageChange={this.handleSupplierPageChange}
            styling={{ display: "flex", justifyContent: "center" }}
          />
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
            px={1}
          >
            <Typography style={newStyles.productText}>Products</Typography>
            <ButtonDisplay
              data-testid='add_items_btn'
              name="Add Item"
              startIcon={AddSharp}
              iconStyling={newStyles.createButtonIcon}
              variant="contained"
              onClickHandle={this.handleAddProductOpenModal}
              styling={newStyles.createButton}
            />
          </Box>
          <Box style={newStyles.searchItemsListContainer}>
                <Box style={newStyles.searchBoxWrapper}>
                {this.handleCOFItemsFilterMultiItemsState()}
                </Box>
                <Box style = {{marginTop: '20px'}}>
                  <Paper component="form" style={newStyles.searchPaperStyle}>
                    <IconButton
                      aria-label="choose category for search.."
                      onClick={this.handleCOFItemsMenuOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                    <InputBase
                    value={this.state.itemsCofSearchBySpecificValue}
                      placeholder="Search by value.."
                      inputProps={{ 'aria-label': 'search by value according to category selected..' }}
                      onChange={this.handleCOFItemsInputChangeForSearchValue}
                    />
                    <IconButton aria-label="search">
                      <SearchIcon onClick={this.getAllProductsList} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton aria-label="clear">
                      <ClearIcon onClick={this.handleCOFItemsClearSearchFunc}/>
                    </IconButton>
                  </Paper>
                  <Menu
                  data-testid='cof_search_menu'
                  style={{marginTop:"2.4rem"}}
                    anchorEl={this.state.anchorElForCOFItems}
                    open={this.state.isCOFItemsMenuForSearchOpen}
                    onClose={this.handleCOFItemsMenuClose}
                  >
                    {this.state.mainSearchCOFItemsKeysData?.map((item) => (
                      <MenuItem
                      className="cof_search_options"
                        key={item?.key}
                        value={item?.key}
                        onClick={() => this.handleCOFItemsMenuItemSelect(item?.key)}
                      >
                        <Checkbox checked={item.selected} color="secondary"/>
                        {item?.lable}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>



          {this.handleProductsListRenderedInTableFormat()}
          <PaginationDisplay
            totalPages={this.state.totalPagesProduct}
            currentPage={this.state.currentPageProduct}
            handlePageChange={this.handleProductPageChange}
            styling={{ display: "flex", justifyContent: "center" }}
          />
        </Box>
      );
    }
  };

  handleBudgetsDataContainerSubTabSection = () => {
    if (this.state.subTabValue === 5) {
      return (
        <Box>
          <ToastMessageDisplay
            message={this.state.budgetCreateMessage}
            handleClose={this.handleBudgetCreateSnackbarClose}
          />
          <ToastMessageDisplay
            message={this.state.budgetEditMessage}
            handleClose={this.handleBudgetEditSnackbarClose}
          />
          <TableContainer style={newStyles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={newStyles.tableHeaderRows}></TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Starting From
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Name of Budget
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Budget Period
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Budget Amount
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}>
                    Comment
                  </TableCell>
                  <TableCell style={newStyles.tableHeaderRows}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.budgetList
                  ?.slice(
                    this.state.itemsPerPage *
                    (this.state.currentPageBudget - 1),
                    this.state.itemsPerPage * this.state.currentPageBudget
                  )
                  .map((data: any) => {
                    return (
                      <TableRow
                        key={data.id}
                        className={"custom_pr_selectedrow"}
                        onClick={() => this.handleBudgetSelectedRow(data?.id)}
                      >
                        <TableCell>
                          <Radio
                            checked={
                              data?.id ==
                                this.state.customFromRequisitionSelectedRow
                                ? true
                                : false
                            }
                            value={data?.id}
                            checkedIcon={<img src={checkedRadioButton} />}
                            icon={<img src={uncheckedRadioButton} />}
                          />
                        </TableCell>
                        <TableCell style={newStyles.tableBodyRows}>
                          {data?.attributes?.starting_from}
                        </TableCell>
                        <TableCell style={newStyles.tableBodyRows}>
                          {data?.attributes?.name}
                        </TableCell>
                        <TableCell style={newStyles.tableBodyRows}>
                          {data?.attributes?.period}
                        </TableCell>
                        <TableCell style={newStyles.tableBodyRows}>
                          {data?.attributes?.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell style={newStyles.tableBodyRows}>
                          {data?.attributes?.comment}
                        </TableCell>
                        <TableCell style={newStyles.iconColumn}>
                          <VisibilityOutlinedIcon
                            className="show_budget_iconbtn"
                            onClick={
                              this.handleShowBudgetOpenModal.bind(this, data?.id)
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {this.handleBudgetsListRenderedDataRenderedInTableFormat()}
          <Box style={{ display: "flex", alignItems: "baseline", justifyContent: "center" }}>
            <PaginationDisplay
              totalPages={this.state.totalPagesBudget}
              currentPage={this.state.currentPageBudget}
              handlePageChange={this.handleBudgetPageChange}
            />
          </Box>
        </Box>
      );
    }
  };

  //
  // createOrFind table data records end
  //

  addPRDateTypeConditionCheckRendered = (selectedType: any) => {
    if (selectedType == "text") {
      return "text";
    } else {
      return "date";
    }
  };

  addPRDatesColorStyleConditionalRendered = (selectedValue: any) => {
    if (selectedValue == "date" || selectedValue) {
      return "black";
    } else {
      return "#a6a6a5";
    }
  };

  addUpdatePRInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  updateSupplierInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addUpdatePRProductsFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length == 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addPRNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue) {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  addPRNewStylesSelectBoxLabelRenderedForProducts = (setValue: any) => {
    if (setValue.length > 0) {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  addPRProductsFilteredValueRendered = () => {
    if (this.state.products?.length > 0) {
      return this.state.products?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  addPRBtnConditionalDisabledDuringLoadingState = () => {
    if (this.state.createPurchaseRequisitionLoading) {
      return true;
    } else {
      return false;
    }
  };

  addPRBtnConditionalTextRendered = () => {
    if (this.state.createPurchaseRequisitionLoading) {
      return "Loading";
    } else {
      return "Create New";
    }
  };

  saveAsDraftPRBtnConditionalTextRendered = () => {
    if (this.state.createPurchaseRequisitionLoading) {
      return "Loading";
    } else {
      return "Save As Draft";
    }
  };

  // RFP

  createRFPProductsListValue = () => {
    if (this.state.addRfpProducts?.length > 0) {
      return this.state.addRfpProducts?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  createRFPDateTypeConditionCheckRendered = (selectedType: any) => {
    if (selectedType == "text") {
      return "text";
    } else {
      return "date";
    }
  };

  addRFPNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  addRFPSelectProductFieldValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length === 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  createUpdateRFPInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addSharpIconForRFPCreateInputRendered = () => {
    if (!this.state.rfpAddress) {
      return <AddSharpIcon />;
    } else {
      return "";
    }
  };

  rfpEmailOfSupplierEmailPatternErrorRendered = () => {
    if (this.state.rfpEmailOfSupplierEmailPatternError) {
      return "Enter a valid email";
    } else {
      return "";
    }
  };

  rfpEmailOfSupplierEmailDuplicateErrorRendered = () => {
    if (this.state.rfpEmailOfSupplierEmailDuplicateError) {
      return "This email already exists";
    } else {
      return "";
    }
  };

  rfpAttachmentTypeDropDownValueDisplay = () => {
    if (!this.state.rfpAttachmentDropdownDisplay) {
      return "Select Attachment Type";
    } else {
      return "None";
    }
  };

  rfpAttachmentTypeMatchErrorRendered = () => {
    if (
      this.state.showIsCreateRFPValidation &&
      this.state.rfpAttachment !== "" &&
      this.state.rfpAttachmentTypeMatchError
    ) {
      return this.state.rfpAttachmentTypeMatchError;
    } else {
      return "";
    }
  };

  rfpSelectExistingSuppliersFilteredValueShow = () => {
    if (this.state.rfpSelectExistingSuppliers?.length > 0) {
      return this.state.rfpSelectExistingSuppliers?.filter(
        (i: any) => i !== "No value"
      );
    } else {
      return ["No value"];
    }
  };

  rfpExistingSuppliersNewStyleLableBoxDisplay = () => {
    if (this.state.addItemSupplier !== "") {
      return newStyles.selectBox;
    } else {
      return newStyles.selectBoxLabel;
    }
  };

  handleShowRfpEmailOfSuppliers = () => {
    if (this.state.showRfp?.attributes?.email_of_suppliers?.length > 0) {
      this.state.showRfp?.attributes?.email_of_suppliers?.map(
        (email: any, index: number) => {
          return (
            <span
              className="show_rfp_email_records"
              key={email?.id}
              style={newStyles.textFieldBox}
            >
              {this.state.showRfp?.attributes?.email_of_suppliers?.length -
                1 ===
                index
                ? `${email}`
                : `${email}, `}
            </span>
          );
        }
      );
    } else {
      return "-";
    }
  };

  handleShowRfpPdfAttachment = () => {
    if (this.state.showRfp?.attributes?.pdf_attach) {
      return this.state.showRfp?.attributes?.pdf_attach;
    } else {
      return "-";
    }
  };

  handleShowRfpSelectedExistingEmailOfSuppliers = () => {
    if (this.state.showRfp?.attributes?.supplier?.length > 0) {
      this.state.showRfp?.attributes?.supplier?.map(
        (supplier: any, index: number) => {
          return (
            <span key={supplier?.id} style={newStyles.textFieldBox}>
              {this.state.showRfp?.attributes?.supplier?.length - 1 === index
                ? `${supplier?.supplier_name}`
                : `${supplier?.supplier_name}, `}
            </span>
          );
        }
      );
    } else {
      return <Typography style={newStyles.textFieldBox}>-</Typography>;
    }
  };

  addSharpIconForEditedRFPInputAddress = () => {
    if (!this.state.editedRfpAddress) {
      return <AddSharpIcon />;
    } else {
      return "";
    }
  };

  editedRfpEmailOfSupplierEmailPatternErrorRendered = () => {
    if (this.state.editedRfpEmailOfSupplierEmailPatternError) {
      return "Enter a valid email";
    } else {
      return "";
    }
  };

  editedRfpEmailOfSupplierEmailDuplicateErrorRendered = () => {
    if (this.state.editedRfpEmailOfSupplierEmailDuplicateError) {
      return "This email already exists";
    } else {
      return "";
    }
  };

  editedRfpTypeRequiredErrorRendered = () => {
    if (
      this.state.showIsEditRFPValidation &&
      this.state.editedRfpAttachmentTypeRequiredError
    ) {
      return this.state.editedRfpAttachmentTypeRequiredError;
    } else {
      return "";
    }
  };



  editedRfpExistingSuppliersStyleSelectBox = (stateValue: any) => {
    if (stateValue?.length === 0) {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  isRfpFileEditedConditionalCheckFunc = () => {
    if (!this.state.isFileEdited) {
      return this.state.editedRfpFileAttachment
        ?.split("/")
        .pop()
        .trim();
    } else {
      return this.state.editedRfpFileAttachment?.name;
    }
  };

  // Purchase Orders

  purchaseOrdersInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  purchaseOrdersStyleSelectBoxLableDisplay = (stateValue: any) => {
    if (stateValue == "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  purchaseOrdersDateTypeConditionCheckRendered = (selectedType: any) => {
    if (selectedType == "text") {
      return "text";
    } else {
      return "date";
    }
  };

  purchaseOrdersInputFeildsPlaceHolderRendered = (
    stateValue: any,
    primaryTextToBeShow: any,
    secondTextToBeShow: any
  ) => {
    if (stateValue === "") {
      return primaryTextToBeShow;
    } else {
      return secondTextToBeShow;
    }
  };

  pucrhaseOrdersCircularProgressBarRendered = (
    inputStateValue: any,
    secondInputStateValue: any
  ) => {
    if (inputStateValue !== "" && secondInputStateValue === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    } else {
      return "";
    }
  };

  purchaseOrdersProductsFilteredValueRendered = () => {
    if (this.state.poProduct?.length > 0) {
      return this.state.poProduct?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  purchaseOrdersProductsStyleSelectBoxLableRendered = () => {
    if (this.state.poProduct?.length > 0) {
      return newStyles.selectBox;
    } else {
      return newStyles.selectBoxLabel;
    }
  };

  purchaseOrdersProductsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length == 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  purchaseOrdersEditedProductsFilteredValueRendered = () => {
    if (this.state.editedPoProduct?.length > 0) {
      return this.state.editedPoProduct?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  purchaseOrdersEditedProductsStyleSelectBoxLableRendered = () => {
    if (this.state.editedPoProduct?.length > 0) {
      return newStyles.selectBox;
    } else {
      return newStyles.selectBoxLabel;
    }
  };

  // Reciept


  recieptsNewStyleSelectsBoxLableRendered = (stateValue: any) => {
    if (stateValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  recieptsInputFeildsPlaceHolderRendered = (
    stateValue: any,
    primaryTextToBeShow: any,
    secondTextToBeShow: any
  ) => {
    if (stateValue === "") {
      return primaryTextToBeShow;
    } else {
      return secondTextToBeShow;
    }
  };

  recieptsCircularProgressBarRendered = (
    inputStateValue: any,
    secondInputStateValue: any
  ) => {
    if (inputStateValue !== "" && secondInputStateValue === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    } else {
      return "";
    }
  };

  recieptsDateTypeConditionCheckRendered = (selectedType: any) => {
    if (selectedType == "text") {
      return "text";
    } else {
      return "date";
    }
  };

  recieptsAttachmentDropDownDisplay = () => {
    if (!this.state.receiptAttachmentDropdownDisplay) {
      return "Select Attachment Type";
    } else {
      return "None";
    }
  };

  recieptsAttachmentFileDisplay = () => {
    if (this.state.receiptAttachmentFile) {
      return this.state.receiptAttachmentFile?.name;
    } else {
      return "Attach PDF/DOC/CSV";
    }
  };

  recieptsFileTypeAccept = () => {
    if (this.state.receiptAttachment === "") {
      return `.csv, .pdf, .doc`;
    } else {
      return `.${this.state.receiptAttachment}`;
    }
  };

  recieptsQuantityCircularProgressBarRendered = () => {
    if (this.state.editedReceiptQuantityRequested) {
      return "";
    } else {
      return <CircularProgress style={{ color: "yellow" }} />;
    }
  };

  recieptAttachmentTypeMatchError = () => {
    if (
      this.state.showIsEditReceiptValidation &&
      this.state.editedReceiptAttachmentTypeMatchError
    ) {
      return this.state.editedReceiptAttachmentTypeMatchError;
    } else {
      return "";
    }
  };

  isRecieptFileEditedCheck = () => {
    if (!this.state.isReceiptFileEdited) {
      return this.state.editedReceiptAttachmentFile
        ?.split("/")
        .pop()
        .trim();
    } else {
      return this.state.editedReceiptAttachmentFile?.name;
    }
  };

  recieptFileAttachmentBtnTypeAccept = () => {
    if (this.state.editedReceiptAttachment === "") {
      return `.csv, .pdf, .doc`;
    } else {
      return `.${this.state.editedReceiptAttachment}`;
    }
  };

  customFromRequisitionStyleSelectBoxLable = (stateValue: any) => {
    if (stateValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  customFromRequisitionInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  unitPriceRender = (price: string) => {
    if (price !== undefined) {
      return price.replace(/[$]/g, '')
    } else {
      return '';
    }
  }

  customFromRequisitionDatesTypeValidationErrorRendered = (
    selectedValue: any
  ) => {
    if (selectedValue == "text") {
      return "text";
    } else {
      return "date";
    }
  };

  customFromRequisitionCircularBarRendered = (
    primaryStateValue: any,
    secondaryStateValue: any
  ) => {
    if (primaryStateValue !== "" && secondaryStateValue === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    }
    return "";
  };

  customFromRequisitionInputFeildsPlaceHolderRendered = (
    stateValue: any,
    primaryTextToBeShow: any,
    secondTextToBeShow: any
  ) => {
    if (stateValue === "") {
      return primaryTextToBeShow;
    } else {
      return secondTextToBeShow;
    }
  };

  addSuppliersInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addSuppliersSelectFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState.length === 0) {
      return errorToBeShow;
    } else {
      return "";
    }
  };



  addProductsInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && (fieldState == "" || fieldState?.length === 0)) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  addProductsUnitPriceMatchValidationErrorRendered = () => {
    if (
      this.state.showIsCreateProductValidation &&
      this.state.productUnitPrice != "" &&
      !this.state.productUnitPrice.match(/^\d+$/)
    ) {
      return this.state.productUnitPriceMatchError;
    } else {
      return "";
    }
  };

  addProductsStyleSelectBoxLableRendered = (stateValue: any) => {
    if (stateValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  addProductsStyleTypeRendered = (index: any) => {
    if (index === 0) {
      return "Type";
    } else {
      return "";
    }
  };

  addProductsCheckedUncheckedRadioBtnRendered = (product: any) => {
    if (this.state.productTypeSelectedId === product.id) {
      return <img src={checkedRadioButton} />;
    } else {
      return <img src={uncheckedRadioButton} />;
    }
  };

  editedProductsUnitPriceMatchErrorRendered = () => {
    if (
      this.state.showIsEditProductValidation &&
      this.state.editedProductUnitPrice != "" &&
      !this.state.editedProductUnitPrice.match(/^\d+$/)
    ) {
      return this.state.editedProductUnitPriceMatchError;
    } else {
      return "";
    }
  };

  editedAddProductsCheckedUncheckedRadioBtnRendered = (product: any) => {
    if (this.state.editedProductTypeSelectedId === product.id) {
      return <img src={checkedRadioButton} />;
    } else {
      return <img src={uncheckedRadioButton} />;
    }
  };

  createNewPODateTypeErrorRendered = (stateValue: any) => {
    if (stateValue === "text") {
      return "text";
    } else {
      return "date";
    }
  };

  createNewPOAutoNumsRendered = (PurchaseOrderNum: any) => {
    if (this.state.openCreateNewModal === true) {
      return PurchaseOrderNum;
    } else {
      return "";
    }
  };

  createNewPOInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  createNewPOStyleSelectBoxLableRendered = (stateValue: any) => {
    if (stateValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  createNewPOSuppliersCircularBarRendered = (
    primaryStateValue: any,
    secondaryStateValue: any
  ) => {
    if (primaryStateValue !== "" && secondaryStateValue === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    } else {
      return "";
    }
  };

  createNewPOPlaceHolderTextRendered = (
    stateValue: any,
    primaryPlaceHolder: string,
    secondaryPlaceHolder: string
  ) => {
    if (stateValue === "") {
      return primaryPlaceHolder;
    } else {
      return secondaryPlaceHolder;
    }
  };

  createNewPOProductsFilteredValueRendered = () => {
    if (this.state.products?.length > 0) {
      return this.state.products?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  createNewPOProductsStyleSelectBoxLableRendered = (stateValue: any) => {
    if (stateValue.length > 0) {
      return newStyles.selectBox;
    } else {
      return newStyles.selectBoxLabel;
    }
  };

  createBudgetsDateTypeDisplay = (selectedType: any) => {
    if (selectedType === "text") {
      return "text";
    } else {
      return "date";
    }
  };

  updateBudgetsDateTypeDisplay = (selectedType: any) => {
    if (selectedType === "text") {
      return "text";
    } else {
      return "date";
    }
  };

  createBudgetsInputFieldsValidationErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  createBudgetsStyleSelectBoxLableRendered = (stateValue: any) => {
    if (stateValue === "") {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  createBudgetAmountMatchErrorRendered = () => {
    if (
      this.state.showIsCreateBudgetValidation &&
      this.state.budgetAmount !== ""
    ) {
      return this.state.budgetAmountMatchError;
    } else {
      return "";
    }
  };

  createBudgetIndexTypeRendered = (index: any) => {
    if (index === 0) {
      return "Type";
    } else {
      return "";
    }
  };

  userListPermissionChecked = (permissionChecked: any) => {
    if (permissionChecked) {
      return true;
    } else {
      return false;
    }
  };

  createBudgetCheckedUncheckedRadioBtnRendered = (budget: any) => {
    if (this.state.budgetForSelectedId === budget.id) {
      return <img src={checkedRadioButton} />;
    } else {
      return <img src={uncheckedRadioButton} />;
    }
  };

  editedBudgetAmountMatchInvalidErrorRendered = () => {
    if (
      this.state.showIsEditBudgetValidation &&
      this.state.editedBudgetAmount != "" &&
      !this.state.editedBudgetAmount.match(/^\d+$/)
    ) {
      return this.state.editedBudgetAmountMatchError;
    } else {
      return "";
    }
  };

  editedBudgetsProductInputValidationErrorRendered = () => {
    if (
      this.state.showIsEditBudgetValidation &&
      this.state.editedBudgetProductCategories
    ) {
      return this.state.editedBudgetProductCategoriesRequiredField;
    } else {
      return "";
    }
  };

  editedBudgetsForIndexRendered = (index: any) => {
    if (index === 0) {
      return "Budget For";
    } else {
      return " ";
    }
  };

  editedBudgetsForCheckdeUncheckedBtnRendered = (budget: any) => {
    if (this.state.editedBudgetForSelectedId === budget.id) {
      return <img src={checkedRadioButton} />;
    } else {
      return <img src={uncheckedRadioButton} />;
    }
  };

  poProductListValue = () => {
    if (this.state.products?.length > 0) {
      return this.state.products?.filter((i: any) => i !== "No value");
    } else {
      return ["No value"];
    }
  };

  styledSelectStyle = () => {
    if (
      this.state.editedLocation === "" ||
      this.state.poDepartment === "" ||
      this.state.poBudget === "" ||
      this.state.poSupplier === "" ||
      this.state.paymentTermsName === "" ||
      this.state.products.length > 0 ||
      this.state.location === "" ||
      this.state.department === "" ||
      this.state.prStatusList.length > 0 ||
      this.state.editedPayment === "" ||
      this.state.poBudget ||
      this.state.poOrderDate ||
      this.state.poDepartment ||
      this.state.poShippingAddress === "" ||
      this.state.poBillingAddress === "" ||
      this.state.prPoSelectedRequisitionDepartment?.name === "" ||
      this.state.prPoSelectedRequisitionDeliveryDate === "" ||
      this.state.prPoSelectedRequisitionCreationDate === "" ||
      this.state.paymentTermsNameSuppliers.length > 0
    ) {
      return newStyles.selectBoxLabel;
    } else {
      return newStyles.selectBox;
    }
  };

  showPurchaseOrder = (product: any, index: number) => {
    if (
      this.state.showPurchaseOrder?.attributes?.products?.data?.length - 1 ===
      index
    ) {
      return `${product?.attributes?.name}`;
    } else {
      return `${product?.attributes?.name}, `;
    }
  };

  prValidationErrorStatus = () => {
    if (
      this.state.showIsCreatePurchaseRequisitionValidation &&
      this.state.status === ""
    ) {
      return this.state.prStatusListRequiredError;
    } else {
      return "";
    }
  };

  gstNumberValidationError = () => {
    if (
      this.state.showIsCreateSupplierValidation &&
      this.state.supplierGSTNumber === ""
    ) {
      return this.state.supplierGstNumberRequiredField;
    } else {
      return "";
    }
  };

  poPlaceHolder = () => {
    if (this.state.poShippingAddress === "") {
      return "No Data";
    } else if (this.state.paymentTermsName !== "") {
      return "Fetching PaymentTerm Name...";
    }else{
      return ''
    }
  };

  prPoPlaceHolder = () => {
    if (this.state.poShippingAddress === "") {
      return "No Data";
    }else{
      return ''
    }
  };

  poPlaceHolderColor = () =>{
    if(this.state.poShippingAddress === ""){
      return 'red'
    }else{
      return 'black'
    }
  }

  poValidationError = (itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };
  poValidationErrorDate = (itemsToBeValidated: any,
    fieldOrderDateState: any,
    fieldDeliveryDateState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && new Date(fieldDeliveryDateState)
      .getDate() < new Date(fieldOrderDateState)
        .getDate()) {
      return errorToBeShow
    } else {
      return '';
    }
  }

  purchaseRequisitionPOValidationError = (itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && fieldState == "") {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  circularProgressShippingAddress = () => {
    if (this.state.poShippingAddress === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    }
  };

  circularProgressBillingAddress = () => {
    if (this.state.poSupplier !== "" && this.state.poBillingAddress === "") {
      return <CircularProgress style={{ color: "yellow" }} />;
    }
  };

  showPurchaseRequisition = (product: any, index: number) => {
    if (
      this.state.showPurchaseRequisition?.attributes?.products?.data?.length -
      1 ===
      index
    ) {
      return `${product?.attributes?.name}`;
    } else {
      return `${product?.attributes?.name}, `;
    }
  };

  getCatalougeItemLabel = (product: any) => {
    if (product?.attributes?.name !== null || undefined) {
      return product?.attributes?.name;
    } else {
      return "";
    }
  };

  getOptionsList = () => {
    if (this.state.prNonCatalougeItems) {
      return [...this.state.productsList, { attributes: { name: this.state.prNonCatalougeItems, isNew: true } }]
    } else {
      return this.state.productsList
    }
  }

  renderCatalougeItemsName = (option: any) => {
    if (option?.attributes?.isNew === true) {
      return <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>{option?.attributes?.name} <span style={{ color: "#FF8C00", fontWeight: 'bolder' }}> Non Catalouge Item </span></Box>
    } else {
      return option?.attributes?.name
    }
  }


  //new rfp functions

  handleTabsActiveStyleChange = (activeValue: number) => {
    if (this.state.rfpCreateScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  createRFPLocationExistOrNotErrorRendered = (
    itemsToBeValidated: any,
    fieldState: any,
    errorToBeShow: any
  ) => {
    if (itemsToBeValidated && !fieldState) {
      return errorToBeShow;
    } else {
      return "";
    }
  };

  // New PO Functions

  handleTabsPoActiveStyleChange = (activeValue: number) => {
    if (this.state.poCreateScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  handleTabsPrPoActiveStyleChange = (activeValue: number) => {
    if (this.state.prPoCreateScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  handleReadOnlyFields = (stateValue: any) => {
    if (stateValue) {
      return true
    } else {
      return false
    }
  }

  handleNewProductsListUpdation = (stateValue: any) => {
    if (this.state.productsList) {
      if (stateValue) {
        return [...this.state.productsList, { attributes: { name: stateValue, theInputIsNonCatalog: true } }]
      } else {
        return this.state.productsList
      }
    }
    else {
      return ["No Value"]
    }
  }

  handleNonCatelogItemsRendered = (option: any) => {
    if (option?.attributes?.theInputIsNonCatalog) {
      return <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '5px', alignItems: 'center', boxSizing: 'border-box' as 'border-box' }} > <span>{option?.attributes?.name}</span><span style={{ color: "#e61717", fontSize: '12px' }}>Non Catalogue Item</span></Box>
    } else {
      return option?.attributes?.name
    }
  }

  getRfpCatalougeItemLabel = (product: any) => {
    if (this.state.productsList.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  handleConditionalTextRendered = (textValue: any) => {
    if (textValue) {
      return 'Non catalouge Item'
    } else {
      return '';
    }
  }

  rfpFileAttachmentAcceptCondition = () => {
    if (this.state.rfpAttachment === "") {
      return `.pdf, .doc`
    } else {
      return `.${this.state.rfpAttachment}`
    }
  }

  rfpFileAttachmentNameDisplay = () => {
    if (this.state.rfpFileAttachment) {
      return this.state.rfpFileAttachment?.name
    } else {
      return "Supported Files - PDF/DOC"
    }
  }

  handleRfpPdfStyleLableChange = () => {
    if (this.state.rfpFileAttachment) {
      return newStyles.rfpPdfLabelHighlight
    } else {
      return newStyles.rfpPdfLabel
    }
  }

  handleOpenRfpPdfSupplierScreenModal = () => {
    if (this.state.openRfpPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button style={newStyles.modalButton} onClick={this.handleDownloadRFP} variant="contained">Download as PDF</Button>
          <Button style={newStyles.modalButton} onClick={this.handleRfpMailSendConfirmation} variant="contained">
            {this.state.isRfpMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>
        </Box>
      )
    } else if (!this.state.openRfpPdfSupplierScreenModal && !this.state.openRfpSupplierMailSeccessModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button
            disabled={this.state.isRfpCreationAndApiSuccessDone ? true : false}
            onClick={this.handleCreateRFP} style={newStyles.modalButton} variant="contained">
            {this.state.isRfpCreating ? <CircularProgress size={24} color="inherit" /> : 'Create'}
          </Button>
          <Button onClick={this.handleAddRequestForProposalsCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
        </Box>
      )
    } else {
      return ''
    }
  }

  subhandleOpenPoMainPdfSupplierScreenModalFunc = () => {
    if (this.state.openPoPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button onClick={this.handleGetDownloadPdfPo} style={newStyles.modalButton} variant="contained">
            {this.state.isPdfDownloaded ? <CircularProgress size={24} color="inherit" /> : 'Download as PDF'}
          </Button>
          <Button style={newStyles.modalButton} onClick={this.handlePoMailSendConfirmation} variant="contained">
            {this.state.isPoMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>
        </Box>
      )
    } else if (!this.state.openPoPdfSupplierScreenModal && !this.state.openPoSupplierMailSeccessModal) {
      return (
        <Box justifyContent={"space-between"} display={"flex"} width="100%">
          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              style={newStyles.modalButton} onClick={this.handleSaveAsDraftPurchaseOrder} variant="contained">
              {this.state.poSaveDraft ? <CircularProgress size={24} color="inherit" /> : 'Save as Draft'}
            </Button>
          </Box>
          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              onClick={this.handleCreatePurchaseOrder} style={newStyles.modalButton} variant="contained"
              disabled={this.state.isPoCreationAndApiSuccessDone ? true : false}>
              Create
            </Button>
            <Button onClick={this.handleAddPurchaseOrderCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
          </Box>
        </Box>
      )
    } else {
      return ''
    }
  }

  handleOpenPrPoPdfSupplierScreenModal = () => {
    if (this.state.openPrPoPdfSupplierScreenModal) {
      return (
        <Box style={newStyles.rfpScreenBtnWrapper}>
          <Button style={newStyles.modalButton} onClick={this.handleGetDownloadPdfPrPo} variant="contained">
            {this.state.isPdfDownloaded ? <CircularProgress size={24} color="inherit" /> : 'Download as PDF'}
          </Button>
          <Button style={newStyles.modalButton} onClick={this.handlePrPoMailSendConfirmation} variant="contained">
            {this.state.isPrPoMailSending ? <CircularProgress size={24} color="inherit" /> : 'Send to Supplier'}
          </Button>
        </Box>
      )
    } else if (!this.state.openPrPoPdfSupplierScreenModal && !this.state.openPrPoSupplierMailSeccessModal) {
      return (
        <Box display={"flex"} justifyContent={"space-between"} width="100%">
          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              onClick={this.handleSaveAsDraftPrPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.state.prPoSaveDraft ? <CircularProgress size={24} color="inherit" /> : 'Save as Draft'}
            </Button>
          </Box>

          <Box style={newStyles.rfpScreenBtnWrapper}>
            <Button
              disabled={this.state.isPrPoCreationAndApiSuccessDone ? true : false}
              onClick={this.handleCreateRequisitionPurchaseOrder} style={newStyles.modalButton} variant="contained">
              {this.state.prPoCreating ? <CircularProgress size={24} color="inherit" /> : 'Create'}
            </Button>
            <Button onClick={this.handleClosePurchaseFromRequisitionCreatePO} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
          </Box>
        </Box>
      )
    } else {
      return ''
    }
  }

  purchaseRequisitionPoTableRendered = () => {
    if (this.state.purchaseRequisitionList?.length !== 0) {
      return (
        <TableContainer style={newStyles.tableContainer}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
                <TableCell style={newStyles.tableHeaderRows}>
                  PR Number
                </TableCell>
                <TableCell style={newStyles.tableHeaderRows}>
                  Requester
                </TableCell>
                <TableCell style={newStyles.tableHeaderRows}>
                  Creation Date
                </TableCell>
                <TableCell style={newStyles.tableHeaderRows}>
                  Delivery Date
                </TableCell>
                <TableCell style={newStyles.tableHeaderRows}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.purchaseRequisitionList
                ?.slice(
                  this.state.itemsPerPage *
                  (this.state.currentPagePurchaseRequisitionPO - 1),
                  this.state.itemsPerPage *
                  this.state.currentPagePurchaseRequisitionPO
                )
                ?.map((data: any) => {
                  return (
                    <TableRow
                      key={data.id}
                      className={"custom_pr_selectedrow"}
                      onClick={
                        this.handleCustomFromRequisitionSelectedRow.bind(
                          this,
                          data
                        )
                      }
                    >
                      <TableCell>
                        <Radio
                          checked={
                            data?.id ==
                              this.state.customFromRequisitionSelectedRow
                              ? true
                              : false
                          }
                          value={data?.id}
                          checkedIcon={<img src={checkedRadioButton} />}
                          icon={<img src={uncheckedRadioButton} />}

                        />
                      </TableCell>
                      <TableCell style={newStyles.tableBodyRows}>
                        {data?.attributes?.purchase_requisition_number}
                      </TableCell>
                      <TableCell style={newStyles.tableBodyRows}>
                        {data?.attributes?.account?.user_name}
                      </TableCell>
                      <TableCell style={newStyles.tableBodyRows}>
                        {data?.attributes?.pr_creation_date}
                      </TableCell>
                      <TableCell style={newStyles.tableBodyRows}>
                        {data?.attributes?.delivery_date}
                      </TableCell>
                      <TableCell style={newStyles.iconColumn}>
                        <Button
                          onClick={
                            this.handleCustomFromRequisitionCreatePO
                          }
                          style={newStyles.modalButtonPO}
                          variant="contained"
                        >
                          Create Purchase Order
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else {
      return <Loading variant="rect" animation="wave" styling={newStyles.tableContainer} />
    }
  }

  handleNewPoProductsListUpdation = (stateValue: any) => {
    if (stateValue) {
      return [...this.state.productsList, { attributes: { name: this.state.poNonCatalougeCollectedItem, theInputIsNonCatalog: true } }]
    } else {
      return this.state.productsList
    }
  }

  subhandlePoNonCatelogAllItemsRenderedFunc = (option: any) => {
    if (option?.attributes?.theInputIsNonCatalog) {
      return <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", gap: '5px', alignItems: 'center', boxSizing: 'border-box' as 'border-box' }} > <span>{option?.attributes?.name}</span><span style={{ color: "#e61717", fontSize: '12px' }}>Non Catalogue Item</span></Box>
    } else {
      return option?.attributes?.name
    }
  }

  subgetPoCatalougeAllItemLabelFunc = (product: any) => {
    if (this.state.productsList?.length > 0 && product?.attributes?.name) {
      return product.attributes.name
    } else {
      return '';
    }
  }

  handlePoConditionalTextRendered = (textValue: any) => {
    if (textValue) {
      return 'Non catalouge Item'
    } else {
      return '';
    }
  }
  rfpFilteredOptionsNestedConditionalFunc = (product: any) => {
    if (product?.attributes?.name) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }

  purchaseOrderFilteredOptionsNestedConditionalFunc = (product: any) => {
    if (product?.attributes?.name) {
      return product?.attributes?.name
    } else {
      return '';
    }
  }


  addRfpProductsNewStylesSelectBoxLabelRendered = (setValue: any) => {
    if (setValue.length > 0) {
      return newStyles.rfpItemsSelectBox
    }
    else {
      return newStyles.rfpItemsSelectBoxElse
    }
  }

  handleRfpValidEmailError = (stateValue: any) => {
    if (stateValue) {
      return "Enter a valid email"
    } else {
      return '';
    }
  }

  poFileAttachmentAcceptCondition = () => {
    if (this.state.poAttachment === "") {
      return `.pdf, .doc`
    } else {
      return `.${this.state.poAttachment}`
    }
  }

  poFileAttachmentNameDisplay = () => {
    if (this.state.rfpFileAttachment) {
      return this.state.rfpFileAttachment?.name
    } else {
      return "Supported Files - PDF/DOC"
    }
  }

  handleShowOptionalEmailDynamic = (showRfpState: any) => {
    if (showRfpState?.attributes?.email_of_supplier) {
      return showRfpState?.attributes?.email_of_supplier
    } else {
      return ""
    }
  }

  handleCheckedCustomFromRequisitionSelectedRow = (id: any, stateValue: any) => {
    if (id === stateValue) {
      return true
    }
    else {
      return false
    }
  }


  handleRfpNoLocationTypoError = (stateValue: string) => {
    if (stateValue) {
      return "Existing Location Not Available.. first create this from location setting"
    } else {
      return ""
    }
  }

  getRceiptItemsLabel = (items: any) => {
    if (this.state.recieptsPOSelectionDataArr?.length > 0 && items?.attributes?.purchase_order_number) {
      return items?.attributes?.purchase_order_number
    } else {
      return '';
    }
  }

  handleReceiptsPONumbersDataSelectionRendered = (stateValue: any) => {
    if (stateValue) {
      return stateValue
    }
    else {
      return ["No Value"]
    }
  }


  handlePRNonCatelogFunc = (term: any) => {
    if (term) {
      return 'Non catalouge Item'
    }
    else {
      return ''
    }
  }


  handleCustomReqSelectedRowFunc = (term: any) => {
    if (term == this.state.customFromRequisitionSelectedRow) {
      return true
    } else {
      return false
    }
  }


  handleCreateReceiptLoadersFunc = (stateValue: any) => {
    if (stateValue) {
      return <CircularProgress size={24} color="inherit" />
    } else {
      return 'Create'
    }
  }



  handleShowReceiptItemsNumber = () => {
    if (this.state.receiptCatProductsIdCatch) {
      return this.state.showReceipt?.attributes?.receipt_purchase_order_products?.filter((item: any) => item?.id === this.state.receiptCatProductsIdCatch)
    } else if (this.state.receiptNonCatProductsIdCatch) {
      return this.state.showReceipt?.attributes?.receipt_non_catalogue_products?.filter((item: any) => item?.id === this.state.receiptNonCatProductsIdCatch)
    } else {
      return []
    }
  }


  subpurchaseRequisitionMainOrderAllFieldsRenderFunc = () => {
    if (this.state.prPoCreateScreenActiveTabs === 0) {
      return <>
        <Grid container>
          <Grid item lg={4} md={6} sm={6}>

            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}
              >
                Creation Date
              </Typography>
              <StyledTextField
                disabled
                variant="outlined"
                type={"date"}
                value={this.state.prPoSelectedRequisitionCreationDate}
                style={newStyles.textFieldBox}
                InputProps={{
                  inputProps: {
                    min:
                      new Date()
                        .getFullYear()
                        .toString()
                        .padStart(4, "0") +
                      "-" +
                      (new Date().getMonth() + 1)
                        .toString()
                        .padStart(2, "0") +
                      "-" +
                      new Date()
                        .getDate()
                        .toString()
                        .padStart(2, "0"),
                  },
                }}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Delivery Date
              </Typography>
              <StyledTextField
                disabled
                type={"date"}
                value={this.state.prPoSelectedRequisitionDeliveryDate}
                onChange={this.handleDeliveryDateChange}
                // onFocus={this.handleDeliveryDateTypeFocus}
                // onBlur={this.handleDeliveryDateTypeBlur}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  inputProps: { min: this.state.durationDate },
                }}
                variant="outlined"
              />
              <Typography style={newStyles.validationText}>
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Department
              </Typography>
              <StyledTextField
                disabled
                type={"text"}
                value={this.state.prPoSelectedRequisitionDepartment?.name}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                variant="outlined"
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Select Budget
              </Typography>
              <StyledSelect
                id={"po_pr_budget_select"}
                disableUnderline
                variant="outlined"
                value={this.state.poBudget}
                input={<Input />}
                displayEmpty
                style={this.styledSelectStyle()}
                label="Select Product"
                onChange={this.handlePoBudgetChange}
              >
                <MenuItem disabled selected value="">Select Budget</MenuItem>
                {this.state.budgetList?.map((budget: any) => {
                  return (
                    <MenuItem key={budget?.id} value={budget?.id}>
                      {budget?.attributes?.name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.poBudget, this.state.prPoSelectedRequisitionBudgetError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Supplier
              </Typography>
              <StyledSelect
                disableUnderline
                displayEmpty
                label="Select Product"
                id={"po_supplier_select"}
                input={<Input />}
                variant="outlined"
                value={this.state.poSupplier}
                onChange={this.handlePoSupplierChange}
                style={this.styledSelectStyle()}
              >
                <MenuItem disabled value="" selected>Select Supplier</MenuItem>
                {this.state.supplierList?.map((supplier: any) => {
                  return (
                    <MenuItem key={supplier?.id} value={supplier.id}>
                      {supplier?.attributes?.supplier_name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.poSupplier, this.state.prPoSelectedRequisitionSupplierError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Payment Terms
              </Typography>
              <StyledSelect
                variant="outlined"
                className={"pr_po_paymentterm_select"}
                multiple
                label="Select Category"
                value={this.state.paymentTermsNameSuppliers}
                onChange={this.handleSupplierPaymentTerms}
                onClick={this.checkFetchPaymentTermsOrNot}
                disableUnderline
                displayEmpty
                style={this.styledSelectStyle()}
                input={<Input style={{ flexWrap: "wrap" }} />}
              >
                <MenuItem value="" selected disabled>Select Payment Terms</MenuItem>
                {
                  this.state.getPaymentTermListSupplier?.map((w: any) => {
                    return (<MenuItem key={w?.id} value={w?.id}>{w?.attributes?.payment_term_name}</MenuItem>)
                  })
                }
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.paymentTermsName, this.state.prPoSelectedRequisitionPaymentError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Taxation
              </Typography>
              <StyledSelect
                displayEmpty
                input={<Input />}
                variant="outlined"
                style={newStyles.selectBox}
                label="Select Product"
                onChange={this.handleTaxNameChange}
                id={"po_taxation_select"}
                disableUnderline
                value={this.state.taxName}
              >
                <MenuItem disabled={true} selected value="">Select Taxation</MenuItem>
                {this.state.taxations?.map((tax: any) => {
                  return (
                    <MenuItem key={tax?.id} value={tax?.id}>
                      {tax?.attributes?.tax_name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.taxName, this.state.prPoSelectedRequisitionTaxationError)}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Location & Shipping Address
              </Typography>
              <StyledTextField
                id={"po_shipping_address"}
                required
                disabled
                value={this.state.poShippingAddress}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { paddingRight: '98px', color: this.poPlaceHolderColor() },
                }}
                variant="outlined"
                placeholder={this.prPoPlaceHolder()}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Location & Shipping Address Optional
              </Typography>
              <StyledTextField
                id={"po_shipping_address_optional"}
                required
                value={this.state.prPoShippingAddressOptional}
                onChange={this.handlePrPoShippingAddressOptionalChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { paddingRight: '98px', color: 'black' },
                }}
                variant="outlined"
                placeholder={"Enter the Location & Shipping Address Optional"}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} sm={6}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Billing Address
              </Typography>
              <StyledTextField
                required
                value={this.state.poBillingAddress}
                onChange={this.handlePoBillingAddressChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                variant="outlined"
                placeholder={"Enter the Billing Address"}
              />
              <Typography style={newStyles.validationText}>
                {this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.poBillingAddress, this.state.prPoSelectedRequisitionBillingError)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Button
            data-testid={"dash_pr_create_next_btn"}
            onClick={() => {
              this.handleCustomFromRequisitionCreatePONextModal();
            }}
            style={newStyles.modalButton}
            variant="contained"
          >
            Next
          </Button>
          <Button
            data-testid={"dash_pr_cancel_btn"}
            onClick={this.handleClosePurchaseFromRequisitionCreatePO}
            style={newStyles.modalCancelButton}
            variant="contained"
          >
            Cancel
          </Button>
        </Box>
      </>
    } else {
      return <Box>
        <ToastMessageDisplay message={this.state.prPoItemsNotFoundMessage} handleClose={this.handlePrPoNotItemsFoundMessageSnackbarClose} />
        <Grid container>
          <TableContainer
            // component={Paper}
            style={newStyles.productsTable}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Product Id
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Product Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "10%",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "10%",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  >
                    Price
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: "100px",
                      padding: "16px 65rem 25px 30px;",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowY: "scroll" }}>
                {this.state.customFromRequisitionCataItemData?.map((pro: any, index: number) => {
                  return (
                    <TableRow key={pro?.id}>
                      <TableCell
                        style={{
                          width: "100px",
                          padding: "16px 65rem 25px 30px;",
                        }}
                        align="left"
                      >
                        {pro?.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "16px 65rem 25px 30px;",
                          width: "100px",
                        }}
                      >
                        {pro?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          outline: "none",
                          padding: "16px 65rem 25px 30px;",
                          width: "100px",
                          border: "none",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <TextField
                          value={this.quantityHandleDefault(pro)}
                          onChange={(e) =>
                            this.handlePrPoProductsCataQuantityChange(e, pro, index)
                          }
                          type="number"
                          style={{
                            borderRadius: "10px",
                            padding: "10px 15px",
                            width: "80px",
                            border: "2px solid rgb(128 128 128 / 20%)",
                          }}
                          InputProps={{
                            disableUnderline: true,
                            inputProps: { min: 1 },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "100px",
                          paddingTop: '16px',
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                        }}
                      >
                        {this.unitPriceRender(pro?.unit_price)}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "2px 80px",
                          justifyContent: "flex-end",
                          marginTop: "25px",
                          height: "58px",
                          display: "flex",
                        }}
                      >
                        <Chip
                          style={{ width: "0px" }}
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {this.state.customFromRequisitionNonCataItemData?.map((pro: any, index: number) => {
                  return (
                    <TableRow key={pro?.id}>
                      <TableCell
                        align="left"
                        style={{
                          paddingTop: '16px',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          width: "100px",
                          paddingRight: '65rem',
                        }}
                      >
                        {pro?.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          width: "100px",
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          paddingTop: '16px',
                        }}
                      >
                        {pro?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: "1px solid lightgrey",
                          paddingTop: '16px',
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',
                          border: "none",
                          outline: "none",
                          width: "100px",
                        }}
                      >
                        <TextField
                          onChange={(e) =>
                            this.handlePrPoProductsNonCataQuantityChange(e, pro, index)
                          }
                          InputProps={{
                            disableUnderline: true,
                            inputProps: { min: 1 },
                          }}
                          type="number"
                          style={{
                            width: "80px",
                            borderRadius: "10px",
                            padding: "10px 15px",
                            border: "2px solid rgb(128 128 128 / 20%)",
                          }}
                          value={pro?.quantity}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          paddingLeft: '30px',
                          paddingTop: '16px',
                          paddingRight: '65rem',
                          paddingBottom: '25px',
                          width: "100px",
                        }}
                        align="left"
                      >
                        {this.unitPriceRender(pro?.unit_price)}
                      </TableCell>
                      <TableCell
                        style={{
                          marginTop: "25px",
                          justifyContent: "flex-end",
                          display: "flex",
                          height: "58px",
                          padding: "2px 80px",
                        }}
                        align="left"
                      >
                        <Chip
                          style={{
                            width: "0px"
                          }}
                          size="medium"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ marginTop: "20px" }}>
            <Box style={newStyles.gridItem}>
              <Typography style={newStyles.modalLabel}>
                Add notes
              </Typography>
              <StyledTextField
                required
                value={this.state.poComment}
                onChange={this.handlePoCommentChange}
                style={newStyles.textFieldBox}
                inputProps={{
                  style: {
                    fontSize: 16,
                    fontWeight: 400,
                    fontFamily: "Plus Jakarta Display",
                  },
                }}
                variant="outlined"
                placeholder="Add notes"
              />
              <Typography style={newStyles.validationText}>{this.purchaseRequisitionPOValidationError(this.state.showIsCreatePrPurchaseOrderValidation, this.state.poComment, this.state.purchaseOrderCommentRequiredField)}</Typography>
            </Box>
          </div>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            width={"100%"}
          >
            {this.handleOpenPrPoPdfSupplierScreenModal()}
          </Box>
        </Grid>
      </Box>
    }
  }


  handleBtnOnclickDisabled = (moduleKey: string, textKey: string, clickFunction?: any, metaData?: any) => {
    this.setState({ isCreateOrFindTabsDisabled: false })
    const hasPermissions = this.handleUserPermissionAccessModules(moduleKey)

    if (hasPermissions) {
      this.setState({
        isCreateOrFindTabsDisabled: true,
        noCreateOrFindTabModulesPermitMessage: `Not Permitted ${textKey}`
      });
    } else {
      if (metaData && clickFunction) {
        return clickFunction(metaData);
      } else if (clickFunction) {
        return clickFunction()
      } else {
        return;
      }
    }
  }

  handleIsSupplierFieldCheckValidTrueFalseError = (stateValue: boolean, errorToBeShow: string) => {
    if (stateValue) {
      return errorToBeShow
    } else {
      return '';
    }
  }

  addSuppliersContactNumbersValidationErrorDisplay = (validState: boolean, inpValueState: any, errorToBeShow: string) => {
    if ((validState && inpValueState !== "" && inpValueState.length > 10) || (validState && inpValueState !== "" && inpValueState.length < 10)) {
      return errorToBeShow
    } else {
      return '';
    }
  }

  handleModulePermissionExistOrNotNestedFunc = (response: any) => {
    if (response) {
      return false
    } else {
      return true
    }
  }

  handleUserPermissionAccessModules = (moduleKey: string) => {
    const userLoginDetails = this.state.userLoginDetailsCatch?.data?.attributes;

    if (userLoginDetails?.update_account_type === "User Login") {
      const userPermission = userLoginDetails?.all_user?.[0]?.permission;

      if (userPermission) {
        const permissions = JSON.parse(userPermission);
        const containsPurchaseRequisition = permissions?.includes(moduleKey);

        return this.handleModulePermissionExistOrNotNestedFunc(containsPurchaseRequisition);
      }
    }

    return false;
  }


  handleSupplierVaidEmailErrorMessage = () => {
    if (this.state.showIsCreateSupplierValidation &&
      this.state.supplierEmailAddress !== "" &&
      !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.supplierEmailAddress)
    ) {
      return this.state.supplierEmailAddressEmailMatchError
    } else {
      return ""
    }
  }


  handleRfpRenderFunctionOptionsNestedFunc = (option: any, { selected }: any) => {
    console.log(option)
    return (

      <React.Fragment>
        <Radio
          style={{ marginRight: 8, color: "black" }}
          checked={selected}
        />
        {this.handleNonCatelogItemsRendered(option)}
      </React.Fragment>
    )
  }


  handleRfpRenderInputFunctionNestedFunc = (params: any) => (
    <TextField {...params} value={this.state.rfpNonCatalougeCollectedItems} onChange={this.getSearchedNonCatelogProductChange} variant="outlined" placeholder="Find or Select Item" style={{ marginBottom: '10px', border: '0.005px solid #B1B1AE', borderRadius: '10px' }} />
  )

  quantityHandleDefault = (pro: any) => {
    if (pro?.quantity > 0) {
      return pro?.quantity;
    } else {
      return '';
    }
  }


  handleBudgetForFunc = ()=>{
    return this.state.budgetForList?.map((elem: any) => {
      return <MenuItem value={elem?.id} key={elem?.id}>{elem.name}</MenuItem>
  })}
  handleTabsPoViewActiveStyleChange = (activeValue: number) => {
    if (this.state.poViewScreenActiveTabs === activeValue) {
      return newStyles.tabsActiveChange
    }
    else {
      return newStyles.tabsDefaultStyle
    }
  }

  handleFindLabelByKeySubNested = (keyToFind: string)=>{
    const foundKeyData = this.state.mainSearchKeysData?.find((data) => data.key === keyToFind);
    return foundKeyData ? foundKeyData.lable : '';
  }

  handleFilterMultiItemsState = () => {
    if(this.state.filterMultiItemsState){
      return this.handleFindLabelByKeySubNested(this.state.filterMultiItemsState)
    } else {
      return "All Records"
    }
  }

  handleCOFItemsFindLabelByKeySubNested = (keyToFind: string)=>{
    const itemsFoundKeyData = this.state.mainSearchCOFItemsKeysData?.find((data) => data.key === keyToFind);
    return itemsFoundKeyData ? itemsFoundKeyData.lable : '';
  }

  handleCOFItemsFilterMultiItemsState = () => {
    if(this.state.cofItemsFilterMultiState){
      return this.handleCOFItemsFindLabelByKeySubNested(this.state.cofItemsFilterMultiState)
    } else {
      return "All Records"
    }
  }

  showReceiptCatalougeItems = (key: string, data: any) =>{
    if(key){
      return data
    }
  }

  showReceiptNonCatalougeItems = (key: string, data: any) =>{
    if(key){
      return data
    }
  }

  showReceiptCatalougeAndNonCatalougeItems = () =>{
    if(this.state.showReceipt?.attributes?.receipt_purchase_order_products){
      return  <ViewDetailsDialog
      id="show_receipt_dialog_catalouge"
      heading="Show Receipt"
      open={this.state.openShowReceiptModal}
      data={[
        {
          displayName: "Receipt Number",
          displayData: this.showReceiptCatalougeItems('Receipt Number', this.state.showReceipt?.attributes?.receipt_number),
        },
        {
          displayName: "PO Number",
          displayData: this.showReceiptCatalougeItems('PO Number',this.state.showReceipt?.attributes?.purchase_order?.purchase_order_number),
        },
        {
          displayName: "Item Number",
          displayData: this.showReceiptCatalougeItems('Item Number',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.id))
        },
        {
          displayName: "Quantity Ordered",
          displayData: this.showReceiptCatalougeItems('Quantity Ordered', this.handleShowReceiptItemsNumber()?.map((item:any) => item?.quantity_required || item?.quantity))
        },
        {
          displayName: "Quantity Received",
          displayData: this.showReceiptCatalougeItems('Quantity Received',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.quantity_received))
        },
        {
          displayName: "Supplier Invoice Number",
          displayData: this.showReceiptCatalougeItems('Supplier Invoice Number',this.state.showReceipt?.attributes?.supplier_invoice_number),
        },
        {
          displayName: "Creation Date",
          displayData: this.showReceiptCatalougeItems('Creation Date',this.state.showReceipt?.attributes?.receipt_date),
        },
        {
          displayName: "Remark",
          displayData: this.showReceiptCatalougeItems('Remark',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.comments))
        },
        {
          displayName: "Status",
          displayData: this.showReceiptCatalougeItems('Status', this.handleShowReceiptItemsNumber()?.map((item:any) => item?.status)),
        }
      ]}
      buttonsInfo={[
        {
          name: "Cancel",
          disabled: false,
          variant: "contained",
          onClickHandle: this.handleShowReceiptCloseModal,
          styling: newStyles.modalCancelButton,
        },
      ]}
    />
    }else{
      return <ViewDetailsDialog
      id="show_receipt_dialog_non_catalouge"
      heading="Show Receipt"
      open={this.state.openShowReceiptModal}
      data={[
        {
          displayName: "Receipt Number",
          displayData: this.showReceiptNonCatalougeItems('Receipt Number',this.state.showReceipt?.attributes?.receipt_number),
        },
        {
          displayName: "PO Number",
          displayData: this.showReceiptNonCatalougeItems('PO Number',this.state.showReceipt?.attributes?.purchase_order?.purchase_order_number),
        },
        {
          displayName: "Item Number",
          displayData: this.showReceiptNonCatalougeItems('Item Number',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.id))
        },
        {
          displayName: "Quantity Ordered",
          displayData: this.showReceiptNonCatalougeItems('Quantity Ordered',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.quantity_required || item?.quantity))
        },
        {
          displayName: "Quantity Received",
          displayData: this.showReceiptNonCatalougeItems('Quantity Received',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.quantity_received))
        },
        {
          displayName: "Supplier Invoice Number",
          displayData: this.showReceiptNonCatalougeItems('Supplier Invoice Number',this.state.showReceipt?.attributes?.supplier_invoice_number),
        },
        {
          displayName: "Creation Date",
          displayData: this.showReceiptNonCatalougeItems('Creation Date',this.state.showReceipt?.attributes?.receipt_date),
        },
        {
          displayName: "Remark",
          displayData: this.showReceiptNonCatalougeItems('Remark',this.handleShowReceiptItemsNumber()?.map((item:any) => item?.comments))
        },
        {
          displayName: "Status",
          displayData: this.showReceiptCatalougeItems('Status', this.handleShowReceiptItemsNumber()?.map((item:any) => item?.status)),
        }
      ]}
      buttonsInfo={[
        {
          name: "Cancel",
          disabled: false,
          variant: "contained",
          onClickHandle: this.handleShowReceiptCloseModal,
          styling: newStyles.modalCancelButton,
        },
      ]}
    />
    }
  }



  // Customizable Area End

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: "start",
      stringify: (product: any) =>
      (
        this.purchaseOrderFilteredOptionsNestedConditionalFunc(product)
      )
    });


    const rfpFilterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (product: any) => (
        this.rfpFilteredOptionsNestedConditionalFunc(product)
      ),
    })

    const poFilterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (product: any) => (
        this.purchaseOrderFilteredOptionsNestedConditionalFunc(product)
      ),
    })


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 10;
    const RfpMenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 100,
          marginTop: 40
        },
      },
    };


    

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          maxWidth="xl"
          style={{
            position: "relative",
            width: "100%",
            margin: "0px",
            padding: "0px",
          }}
        >
          <Box style={newStyles.mainContainer}>
            <Header
              navigation={this.props.navigation}
              profile={this.state.userProfile}
              active={1}
              permissionLoginUserDetailsState={this.state.userLoginDetailsCatch}
            />
            <ToastMessageDisplay message={this.state.noCreateOrFindTabModulesPermitMessage} handleClose={this.handleNoCreateOrFindTabModulesPermitMessageSnackbarClose} />
            <Box>
              <Box style={newStyles.menuListContainer}>
                <Box>
                  <Tabs
                    disabled={this.state.isCreateOrFindTabsDisabled}
                    id={"tab_value_change"}
                    value={this.state.subTabValue}
                    onChange={this.handleSubTabValueChange}
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                  >
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      label="Purchase Requisition"
                      wrapped
                    />
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      onClick={
                        () =>
                          this.handleBtnOnclickDisabled(
                            'request for proposal',
                            'to request for proposals',
                            this.getAllRFP
                          )}
                      label="Request for Proposals"
                      wrapped
                    />
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      onClick={
                        () =>
                          this.handleBtnOnclickDisabled(
                            'purchase orders',
                            'to purchase orders',
                            this.handleGetAllPurchaseOrders
                          )
                      }
                      label="Purchase Order"
                      wrapped
                    />
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      onClick={
                        () =>
                          this.handleBtnOnclickDisabled(
                            'receipts',
                            'to Receipts',
                            this.handleGetAllReceipts
                          )
                      }

                      label="Receipt"
                      wrapped
                    />
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      onClick={
                        () =>
                          this.handleBtnOnclickDisabled(
                            'supplier and items',
                            'to Supplier and Items Management',
                          )
                      }
                      label="Items & Suppliers"
                      wrapped
                    />
                    <Tab
                      className="tabValue"
                      style={newStyles.menuListItems}
                      onClick={
                        () =>
                          this.handleBtnOnclickDisabled(
                            'budgets',
                            'to Budgets',
                            this.getBudgetList
                          )
                      }
                      label="Budget"
                      wrapped
                    />
                  </Tabs>
                </Box>
                
                {this.creatOrFindCreateActionBtnRendered(
                  this.state.subTabValue
                )}
              </Box>
              <Box style={newStyles.searchItemsListContainer}>
                <Box style={newStyles.searchBoxWrapper}>
                {this.handleFilterMultiItemsState()}
                </Box>
                <Box style = {{marginTop: '10px'}}>
                  <Paper component="form" style={newStyles.searchPaperStyle}>
                    <IconButton
                      aria-label="choose category for search"
                      onClick={this.handleMenuOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                    <InputBase
                    value={this.state.itemsSearchBySpecificValue}
                      placeholder="Search by value ..."
                      inputProps={{ 'aria-label': 'search by value according to category selected' }}
                      onChange={this.handleInputChangeForSearchValue}
                    />
                    <IconButton aria-label="search">
                      <SearchIcon onClick={this.handleMainSearchClickFuncCall} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton aria-label="clear">
                      <ClearIcon onClick={this.handleMainSearchClearAllClickFuncCall}/>
                    </IconButton>
                  </Paper>
                  <Menu
                  data-testid = "main_search_menus"
                  style={{marginTop:"2.4rem"}}
                    anchorEl={this.state.anchorEl}
                    open={this.state.isMenuForSearchOpen}
                    onClose={this.handleMenuClose}
                  >
                    {this.state.mainSearchKeysData?.map((name) => (
                      <MenuItem
                      className="main_search_options"
                        key={name?.key}
                        value={name?.key}
                        onClick={() => this.handleMenuItemSelect(name?.key)}
                      >
                        <Checkbox checked={name.selected} color="secondary"/>
                        {name?.lable}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </Box>

              {this.handleMainPurchaseRequisitionDataContainerSubTabSection()}
              {this.handleMainRequestForProposalsDataContainerSubTabSection()}
              {this.handleMainPurchaseOrdersDataContainerSubTabSection()}
              {this.hadnleMainRecieptsDataContainerSubTabSection()}
              {this.handleSuppliersAndProductsDataContainerSubTabSection()}
              {this.handleBudgetsDataContainerSubTabSection()}
            </Box>

            <Dialog maxWidth="xl" PaperProps={{ style: { height: "100%" } }} open={this.state.openAddRequisitionModal} >
              <ToastMessageDisplay message={this.state.locationCreateMessagePr} handleClose={this.handleLocationPrCreateSnackbarClose} />
              <ToastMessageDisplay message={this.state.deliveryOrDurationDateCheckMessagePr} handleClose={this.handleDeliveryOrDurationDateCheckPrSnackbarClose} />
              <ToastMessageDisplay message={this.state.purchaseRequisitionCreateMessage} handleClose={this.handlePurchaseRequisitionCreateSnackbarClose} />
              <Typography style={newStyles.modalHeading} variant='h5'>PR Number: {this.state.prNumberUnique}</Typography>
              <Grid container >
                  <Grid item lg={3} md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                      <StyledTextField
                        required
                        type={"date"}
                        value={this.state.durationDate}
                        onFocus={this.handleDurationDateTypeFocus}
                        style={newStyles.textFieldBox}
                        onBlur={this.handleDurationDateTypeBlur}
                        onChange={this.handleDurationDateChange}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        placeholder="Enter Creation Date"
                        variant="outlined"
                        InputProps={{ inputProps: { min: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') } }}
                      />
                      <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.durationDate, this.state.durationDateRequiredError)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Expected Delivery Date</Typography>
                      <StyledTextField
                        InputProps={{ inputProps: { min: this.state.durationDate } }}
                        required
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        type={"date"}
                        onBlur={this.handleDeliveryDateTypeBlur}
                        value={this.state.deliveryDate}
                        onFocus={this.handleDeliveryDateTypeFocus}
                        style={newStyles.textFieldBox}
                        variant="outlined"
                        placeholder="Enter Delivery Date"
                        onChange={this.handleDeliveryDateChange}
                      />
                      <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.deliveryDate, this.state.deliveryDateRequiredError)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Location</Typography>
                      <StyledSelect
                        onClose={() => this.setState({ purchaseRequisitionSearchLocation: '' })}
                        displayEmpty
                        onChange={this.handlePrLocationChange}
                        variant="outlined"
                        disableUnderline
                        label="Select Product"
                        value={this.state.location}
                        id={"create_location_select"}
                        MenuProps={{ autoFocus: false, maxHeight: '100px' }}
                        input={<Input />}
                        style={this.addPRNewStylesSelectBoxLabelRendered(this.state.location)}
                      >
                        <MenuItem value="" disabled selected={true}>Select Location</MenuItem>
                        {this.state.locationDropdownList?.map((location: any, index: any) => {
                          return <MenuItem value={location?.id} key={location?.id} className={'location_items'} >{location?.attributes?.name_of_location}</MenuItem>
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.location, this.state.locationRequiredError)}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Department</Typography>
                      <StyledSelect
                        variant="outlined"
                        style={this.addPRNewStylesSelectBoxLabelRendered(this.state.department)}
                        id={"pr_department_select"}
                        value={this.state.department}
                        input={<Input />}
                        displayEmpty
                        label="Select Product"
                        onChange={this.handleDepartmentChange}
                        disableUnderline={true}
                      >
                        <MenuItem disabled={true} value="" selected={true}>Select Department</MenuItem>
                        {this.state.departmentList?.map((department: any) => {
                          return <MenuItem className="department_items" key={department?.id} value={department?.id}>{department?.name}</MenuItem>
                        })}
                      </StyledSelect>
                      <Typography style={newStyles.validationText}>{this.addUpdatePRInputFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.department, this.state.departmentRequiredError)}</Typography>
                    </Box>
                  </Grid>
                <Grid item lg={3} md={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Catalouge Items</Typography>
                    <Autocomplete
                      options={this.getOptionsList()}
                      renderInput={(params) => (
                        <TextField {...params} value={this.state.prNonCatalougeItems} onChange={this.getSearchedNonCatalougeItems} variant="outlined" placeholder="Find or Select Item" />
                      )}
                      id="filter-demo"
                      renderOption={(option: any, { selected }: any) => (
                        <React.Fragment>
                          <Radio
                            checked={selected}
                            style={{
                              color: "black",
                              marginRight: 8,
                            }}
                          />
                          {this.renderCatalougeItemsName(option)}
                        </React.Fragment>
                      )}
                      getOptionLabel={(product) => (this.getCatalougeItemLabel(product))}
                      filterOptions={filterOptions}
                      style={this.addPRNewStylesSelectBoxLabelRenderedForProducts(this.state.prProduct)}
                      onChange={this.handleProductsChange}
                      multiple={false}
                    />
                    <Typography data-testid={'product_required_error'} style={newStyles.validationText}>{this.addUpdatePRProductsFieldsValidationErrorRendered(this.state.showIsCreatePurchaseRequisitionValidation, this.state.prProduct, this.state.productsRequiredError)}</Typography>
                  </Box>
                </Grid>
                <TableContainer component={Paper} style={{
                  width: '100%',
                  marginLeft: '2.5rem',
                  marginBottom: '1rem',
                  margin: '20px',
                  border: '2px solid lightgrey',
                  borderRadius: '15px',
                  marginTop: '1rem',
                  marginRight: '1.5rem',
                  boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
                }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={{
                          padding: '16px 15rem 25px 30px',
                          width: '100px',
                        }}>Product Id</TableCell>
                        <TableCell align="left" style={{
                          width: '100px',
                          paddingTop: '16px',
                          paddingRight: '15rem',
                          paddingBottom: '25px',
                          paddingLeft: '30px',

                        }}>Product Name</TableCell>
                        <TableCell align="left" style={{
                          paddingLeft: '30px',
                          paddingRight: '15rem',
                          paddingTop: '16px',
                          width: '10%',
                          paddingBottom: '25px',
                        }}>Quantity</TableCell>
                        <TableCell align="left" style={{
                          paddingRight: '15rem',
                          paddingLeft: '30px',
                          paddingTop: '16px',
                          paddingBottom: '25px',
                          width: '100px',
                        }}></TableCell>
                        <TableCell align="left" style={{
                          width: '100px',
                          paddingRight: '15rem',
                          paddingTop: '16px',
                          paddingLeft: '30px',
                          paddingBottom: '25px',
                        }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ overflowY: "scroll" }}>
                      {this.state.prProduct?.map((pro: any, index: number) => {
                        return (<TableRow key={pro?.id}>
                          <TableCell align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{pro?.attributes?.item_id}</TableCell>
                          <TableCell align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{pro?.attributes?.name}</TableCell>
                          <TableCell align="left" style={{ width: '10%', padding: '16px 65rem 25px 30px;', border: "none", outline: "none", borderBottom: '1px solid lightgrey' }}><TextField value={this.quantityHandleDefault(pro)} type="number" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} onChange={(e) => this.handlePrQuantityChange(e, pro, index)} style={{
                            border: '2px solid rgb(128 128 128 / 20%)',
                            borderRadius: '10px',
                            padding: '10px 15px',
                            width: '80px',
                          }} />
                          </TableCell>
                          <TableCell align="left">{this.unitPriceRender(pro?.attributes?.unit_price)}</TableCell>
                          <TableCell align="left" style={{ width: '100px', padding: '16px 65rem 25px 30px;' }}>{this.handlePRNonCatelogFunc(pro?.attributes?.isNew)}</TableCell>
                          <TableCell align="left" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "25px", padding: '2px 80px', height: '58px', }}>
                            <Chip
                              style={{ width: '0px' }}
                              onDelete={(e) => { this.removeTableCellPR(e, pro, index) }}
                              size="medium"
                            /></TableCell>
                        </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
                  <Button
                    onClick={this.handleCreatePurchaseRequisition}
                    disabled={this.addPRBtnConditionalDisabledDuringLoadingState()}
                    data-testid={"createPRBtn"}
                    style={newStyles.modalButton}
                    variant="contained"
                  >
                    {this.addPRBtnConditionalTextRendered()}
                  </Button>
                  <Button
                    variant="contained"
                    style={newStyles.modalCancelButton}
                    onClick={this.handleAddRequisitionCloseModal}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                </Box>
              </Grid>

            </Dialog>

            <ViewDetailsDialog
              id={"show_pr_dialog"}
              heading={`PR Number: ${this.state.showPurchaseRequisition?.attributes?.purchase_requisition_number}`}
              open={this.state.openShowRequisitionModal}
              data={[
                {
                  displayName: "Creation Date",
                  displayData: this.state.showPurchaseRequisition?.attributes
                    ?.pr_creation_date,
                },
                {
                  displayName: "Delivery Date",
                  displayData: this.state.showPurchaseRequisition?.attributes
                    ?.delivery_date,
                },
                {
                  displayName: "Location",
                  displayData: this.state.showPurchaseRequisition?.attributes
                    ?.location_setting_id?.name_of_location,
                },
                {
                  displayName: "Department",
                  displayData: this.state.showPurchaseRequisition?.attributes
                    ?.department_id?.name,
                },
              ]}
              buttonsInfo={[
                {
                  name: "Cancel",
                  disabled: false,
                  variant: "contained",
                  onClickHandle: this.handleShowRequisitionCloseModal,
                  styling: newStyles.modalCancelButton,
                },
              ]}
            />

            {/* PR flow end */}



            {/* ===> RFP Flow start */}

            <Dialog maxWidth="xl"
              PaperProps={{ style: { width: '96%', height: "100%" } }}
              style={newStyles.dialog}
              open={this.state.openAddRFPModal}>
              <Box style={newStyles.rfpScreensContainer} >
                <ToastMessageDisplay message={this.state.locationExistOrNotErrorMessage} handleClose={this.handleLocationExistOrNotErrorMessageSnackbarClose} />
                <ToastMessageDisplay message={this.state.supplierFoundOrNotMessage} handleClose={this.handleSupplierFoundNotSnackbarClose} />
                <Box style={newStyles.modalHeadingWrapper}>
                  <Typography style={newStyles.rfpModalHeading} variant='h5'>{`RFP Number: ${this.state.rfpNumberUnique}`}</Typography>
                  <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleRfpTabsScreenClose} />
                </Box>
                <Box>
                  <Typography style={newStyles.rfpNumValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpNumberUnique, this.state.rfpUniqNumberRequiredError)}</Typography>
                </Box>
                <Box style={newStyles.rfpTabsScreenWrapper}>
                  <Tabs
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      }
                    }}
                    style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                    value={this.state.rfpCreateScreenActiveTabs}
                    onChange={(event: React.ChangeEvent<{}>, value: any) => this.handleRfpScreenTabsChange(event, value)}>
                    <Tab style={this.handleTabsActiveStyleChange(0)}
                      label="Details" />
                    <Tab style={this.handleTabsActiveStyleChange(1)}
                      label="Add items" />
                  </Tabs>
                </Box>

                {/* screen 1 ***** */}
                <Box style={{ width: '100%' }}>
                  {
                    this.state.rfpCreateScreenActiveTabs === 0 && <Box>
                      <Grid container >
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem} >
                            <Typography style={newStyles.modalLabel}>Currency</Typography>
                            <StyledSelect
                              id={'currency_select'}
                              variant="outlined"
                              label="Select Currency"
                              readOnly={
                                this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)
                              }
                              value={this.state.rfpCurrency}
                              onChange={this.handleRfpCurrencyChange}
                              disableUnderline
                              displayEmpty
                              style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.rfpCurrency)}
                              input={<Input />}
                            >
                              <MenuItem value="" disabled>Select Currency</MenuItem>
                              {this.state.currencyList?.map((currency: any) => {
                                return <MenuItem className="currency_items" key={currency?.iso} value={`${currency?.name} - ${currency?.currency}`}>{`${currency?.name} - ${currency?.currency}`}</MenuItem>
                              })}
                            </StyledSelect>
                            <Typography data-testid={"currency_required_error"} style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpCurrency, this.state.rfpCurrencyRequiredError)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Creation Date</Typography>
                            <StyledTextField
                              id='creationDate_select'
                              required
                              type={this.createRFPDateTypeConditionCheckRendered(this.state.rfpCreationDateType)}
                              value={this.state.rfpCreationDate}
                              onChange={this.handleRfpCreationDateChange}
                              onFocus={this.handleRfpCreationDateTypeFocus}
                              onBlur={this.handleRfpCreationDateTypeBlur}
                              disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              style={newStyles.textFieldBox}
                              variant="outlined"
                              placeholder="Enter Creation Date"
                              inputProps={{
                                style: newStyles.inputPropsStyle
                              }}
                              InputProps={{
                                inputProps: { min: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') },
                                endAdornment: <InputAdornment
                                style={newStyles.textFieldEndAdornment}
                                position="end">
                                  <DateIcon />
                                </InputAdornment>,
                              disableUnderline: true,
                              }}
                              />
                            <Typography style={newStyles.rfpInpFieldsValidationText}>
                              {
                                this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpCreationDate, this.state.rfpCreationDateRequiredError)
                              }
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Due Date</Typography>
                            <StyledTextField
                              required
                              type={this.createRFPDateTypeConditionCheckRendered(this.state.rfpDueDateType)}
                              value={this.state.rfpDueDate}
                              onChange={this.handleRfpDueDateChange}
                              disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              onFocus={this.handleRfpDueDateTypeFocus}
                              onBlur={this.handleRfpDueDateTypeBlur}
                              style={newStyles.textFieldBox}
                              variant="outlined"
                              placeholder="Enter Due Date"
                              inputProps={{
                                style: {
                                  fontSize: 16,
                                  fontWeight: 400,
                                  fontFamily: 'Plus Jakarta Display'
                                }
                              }}
                              InputProps={{
                                inputProps: { min: this.state.rfpCreationDate },
                                disableUnderline: true,
                                endAdornment: <InputAdornment
                                  style={newStyles.textFieldEndAdornment}
                                  position="end">
                                  <DateIcon />
                                </InputAdornment>,
                              }}
                            />
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpDueDate, this.state.rfpDueDateRequiredError)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Desired Delivery Date</Typography>
                            <StyledTextField
                              required
                              type={this.createRFPDateTypeConditionCheckRendered(this.state.rfpDesiredDeliveryDateType)}
                              value={this.state.rfpDesiredDeliveryDate}
                              onChange={this.handleRfpDesiredDeliveryDateChange}
                              onFocus={this.handleRfpDesiredDeliveryDateTypeFocus}
                              onBlur={this.handleRfpDesiredDeliveryDateTypeBlur}
                              disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              style={newStyles.textFieldBox}
                              variant="outlined"
                              placeholder="Enter Desired Delivery Date"
                              inputProps={{
                                style: {
                                  fontSize: 16,
                                  fontWeight: 400,
                                  fontFamily: 'Plus Jakarta Display'
                                }
                              }}
                              InputProps={{
                                inputProps: { min: this.state.rfpCreationDate },
                                disableUnderline: true,
                                endAdornment: <InputAdornment
                                  style={newStyles.textFieldEndAdornment}
                                  position="end">
                                  <DateIcon />
                                </InputAdornment>,
                              }}
                            />
                            <Typography style={newStyles.rfpInpFieldsValidationText}>
                              {
                                this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpDesiredDeliveryDate, this.state.rfpDesiredDeliveryDateRequiredError)
                              }
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Location</Typography>
                            <StyledSelect
                              id={"rfp_location_select"}
                              MenuProps={
                                RfpMenuProps
                              }
                              variant="outlined"
                              label="Select Location"
                              value={this.state.rfpLocation}
                              onChange={this.handleRfpLocationChange}
                              readOnly={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              disableUnderline
                              displayEmpty
                              style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.rfpLocation)}
                              input={<Input />}
                            >
                              <MenuItem value="" disabled>Select Location</MenuItem>
                              {this.state.locationDropdownList?.map((location: any, index: any) => {
                                return <MenuItem className={'rfp_location_items'} key={location?.id} value={location?.id}>{location?.attributes?.name_of_location}</MenuItem>
                              })}
                            </StyledSelect>
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpLocation, this.state.rfpLocationRequiredError)}</Typography>
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createRFPLocationExistOrNotErrorRendered(this.state.showIsCreateRFPValidation, this.state.locationDropdownList, this.state.locationExistOrNotCheckingRequiredError)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Department</Typography>
                            <StyledSelect
                              disableUnderline
                              variant="outlined"
                              label="Select Department"
                              displayEmpty
                              MenuProps={
                                RfpMenuProps
                              }
                              value={this.state.rfpDepartments}
                              onChange={this.handleRfpDepartmentChange}
                              readOnly={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.rfpDepartments)}
                              input={<Input />}
                            >
                              <MenuItem value="" disabled>Select Department</MenuItem>
                              {this.state.departmentList?.map((department: any) => {
                                return <MenuItem key={department?.id} value={department?.id}>{department?.name}</MenuItem>
                              })}
                            </StyledSelect>
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpDepartments, this.state.rfpDepartmentRequiredError)}</Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={newStyles.gridItem}>
                            <Typography style={newStyles.modalLabel}>Suppliers</Typography>
                            <StyledSelect
                              variant="outlined"
                              multiple
                              label="Select Existing Supplier"
                              value={this.state.rfpSelectExistingSupplier}
                              onChange={this.handleRfpExistingSuppliersChange}
                              readOnly={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              disableUnderline
                              displayEmpty
                              MenuProps={
                                RfpMenuProps
                              }
                              style={this.addRFPNewStylesSelectBoxLabelRendered(this.state.rfpSelectExistingSupplier)}
                              input={<Input style={{ flexWrap: "wrap" }} />}
                            >
                              <MenuItem value="" disabled>Select Existing Supplier</MenuItem>
                              {this.state.suppliersList?.map((supplier: any) => {
                                return <MenuItem key={supplier?.id} value={supplier?.id}>{supplier?.attributes?.supplier_name}</MenuItem>
                              })}
                            </StyledSelect>
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpSelectExistingSupplier, this.state.rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError)}</Typography>
                          </Box>
                        </Grid>

                        <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                          <Box>
                            <Box style={newStyles.gridItem}>
                              <Typography style={newStyles.modalLabel}>Email of Supplier</Typography>
                              <StyledTextField
                                value={this.state.rfpEmailOfSupplier}
                                onChange={this.handleRfpEmailOfSupplierChange}
                                style={newStyles.textFieldBox}
                                inputProps={{
                                  style: {
                                    fontSize: 16,
                                    fontWeight: 400,
                                    fontFamily: 'Plus Jakarta Display'
                                  }
                                }}
                                variant="outlined"
                                placeholder="Enter Email"
                                onKeyDown={this.handleRfpEmailOfSupplierKeydown}
                              />
                              <Typography style={newStyles.rfpInpFieldsValidationText}>{this.handleRfpValidEmailError(this.state.rfpEmailOfSupplierEmailPatternError)}</Typography>
                              <Typography style={newStyles.rfpInpFieldsValidationText}>{this.state.showIsCreateRFPValidation && this.state.rfpEmailOfSupplier != "" && !this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) ? this.state.rfpSupplierEmailMatchError : ''}</Typography>
                            </Box>
                            <Box style={newStyles.rfpSupplierListStyle} >
                              {this.state.rfpEmailOfSuppliers.map((item: any) => (
                                <>
                                  <Chip
                                    key={item}
                                    tabIndex={-1}
                                    label={item}
                                    onDelete={() => this.handleDeleteEmailOfSuppliers(item)}
                                    onClick={() => this.handleEmailItemEdit(item)}
                                    style={{ marginTop: '4px', marginBottom: '4px', backgroundColor: 'yellow' }}
                                  />
                                </>
                              ))}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={12} lg={4} style={{ marginBottom: '10px' }}>
                          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', textAlign: 'right', paddingRight: '20px', boxSizing: 'border-box' as 'border-box' }}>
                            <Typography style={this.handleRfpPdfStyleLableChange()}>{this.rfpFileAttachmentNameDisplay()}</Typography>
                            <Button
                              disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                              style={newStyles.attachmentButton}
                              startIcon={<AddSharpIcon style={newStyles.fileAttachmentButtonIcon} />}
                              variant="contained"
                              component="label">
                              <input
                                type="file"
                                hidden
                                accept={this.rfpFileAttachmentAcceptCondition()}
                                onChange={this.handleRfpFileAttachmentChange}
                              />
                              Add Attachment
                            </Button>
                            <Typography style={newStyles.rfpInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.rfpFileAttachment, this.state.rfpFileAttachmentRequiredError)}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                        <Button style={newStyles.modalButton} onClick={this.handleNextRFPMainCreateFieldsOpenModal} variant="contained">Next</Button>
                        <Button onClick={this.handleAddRFPProductsScreenCloseModal} style={newStyles.modalCancelButton} variant="contained">Cancel</Button>
                      </Box>
                    </Box>
                  }
                </Box>

                {/* screen 2 ***** */}
                <Box style={{ width: '100%' }}>
                  {
                    this.state.rfpCreateScreenActiveTabs === 1 && <Box>
                      <Box style={newStyles.gridItem}>
                        <ToastMessageDisplay message={this.state.rfpCreateMessage} handleClose={this.handleRfpCreateSnackbarClose} />
                        <ToastMessageDisplay message={this.state.rfpCreateMessagedDueDate} handleClose={this.handleRfpCreateDueDateSnackbarClose} />
                        <Typography style={newStyles.modalLabel}>Select Item</Typography>
                        <Autocomplete
                          disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                          multiple={false}
                          id="rfp_products_select"
                          options={this.handleNewProductsListUpdation(this.state.rfpNonCatalougeCollectedItems)}
                          getOptionLabel={(product) => (this.getRfpCatalougeItemLabel(product))}
                          filterOptions={rfpFilterOptions}
                          style={this.addRfpProductsNewStylesSelectBoxLabelRendered(this.state.filteredRfpProducts)}
                          onChange={this.handleRfpDropdownProductsChange}
                          renderOption={this.handleRfpRenderFunctionOptionsNestedFunc.bind(this)}
                          renderInput={this.handleRfpRenderInputFunctionNestedFunc.bind(this)}
                        />
                        <Typography style={newStyles.productsValidationTxt}>{this.addRFPSelectProductFieldValidationErrorRendered(this.state.showIsCreateRFPValidation, this.state.filteredRfpProducts, this.state.rfpExistingProductRequiredError)}</Typography>
                      </Box>
                      <Box>
                        <TableContainer component={Paper} style={newStyles.productsTable}>
                          <Table >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Product Id</TableCell>
                                <TableCell align="left">Product Name</TableCell>
                                <TableCell align="left">Quantity</TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody >
                              {this.state.filteredRfpProducts?.map((prod: any, index: number) => {
                                return (<TableRow key={prod?.id}>
                                  <TableCell align="left">{prod?.id}</TableCell>
                                  <TableCell align="left">{prod?.attributes?.name}</TableCell>
                                  <TableCell align="left">
                                    <StyledTextField
                                      className='rfpAutocomplete'
                                      disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                                      required
                                      value={prod?.quantity}
                                      onChange={(event: any) =>
                                        this.handlerfpProductsQuantityChange(event, prod, index)
                                      }
                                      style={newStyles.rfpQtyTextFieldBoxSmall}
                                      inputProps={{
                                        style: newStyles.inputPropsStyle,
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                      }}
                                      InputProps={{ inputProps: { min: 1 } }}
                                      variant="outlined"
                                      placeholder="Add Quantity"
                                    />
                                  </TableCell>
                                  <TableCell align="left">{
                                    this.handleConditionalTextRendered(prod?.attributes?.theInputIsNonCatalog)
                                  }</TableCell>
                                  <TableCell align="left">
                                    <Chip
                                      disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                                      size="medium"
                                      onDelete={(e) => this.handleRfpProductsDelet(e, prod, index)}
                                      style={{ width: '0px' }}
                                    />
                                  </TableCell>
                                </TableRow>)
                              })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      <Box>
                        <ToastMessageDisplay message={this.state.rfpEmailToSupplierSentOrNotErrorMessage} handleClose={this.handleRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose} />
                      </Box>
                      <Box>
                        <ToastMessageDisplay message={this.state.rfpFinalPdfDownloadOrNotMessage} handleClose={this.handleRfpFinalPdfDownloadOrNotMessageSnackbarClose} />
                      </Box>
                      {
                        this.handleOpenRfpPdfSupplierScreenModal()
                      }
                    </Box>
                  }
                </Box>
              </Box>
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.rfpErrorDialog} open={this.state.openCreateRfpShowErrorModal}>
              <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
                <Typography style={{ color: 'red' }}>All fields are required **</Typography>
                <Typography>Please check Details and Add Items section again</Typography>
                <Box>
                  <Button style={newStyles.errorModalButton} onClick={this.handleCloseCreateRfpErrorModal}>OK</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={this.state.isRfpMailSendConfirmed}
              onClose={this.handleRfpMailSendConfirmationCloseBar}
              aria-describedby="Rfp mail send confirmation"
            >
              <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
              <DialogActions>
                <Button id="handleRfpRecordSupplierMailSend" style={newStyles.rfpAlertBoxBtn} onClick={this.handleRfpRecordSupplierMailSend}>Yes</Button>
                <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handleRfpMailSendConfirmationCloseBar}>No</Button>
              </DialogActions>
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openRfpSupplierMailSeccessModal}>
              <Box style={{ textAlign: 'center', padding: '20px' }}>
                <Typography>An email with Request for Proposals : {this.state.rfpNumberUnique}</Typography>
                <Typography>has been successfully sent to the supplier</Typography>
                <Box>
                  <Button onClick={this.handleRfpSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
                </Box>
              </Box>
            </Dialog>


            <ViewDetailsDialog
              id={'show_rfp_dialog'}
              heading={`RFP Number: ${this.state.showRfp?.attributes?.rfp_number}`}
              open={this.state.openShowRFPModal}
              data={
                [
                  {
                    displayName: 'Currency',
                    displayData: this.state.showRfp?.attributes?.currency,
                  },
                  {
                    displayName: 'Creation Date',
                    displayData: this.state.showRfp?.attributes?.creation_date,
                  },
                  {
                    displayName: 'Due Date',
                    displayData: this.state.showRfp?.attributes?.due_date,
                  },
                  {
                    displayName: 'Location',
                    displayData: this.state.showRfp?.attributes?.location_setting?.adderss,
                  },
                  {
                    displayName: 'Desired Delivery Date',
                    displayData: this.state.showRfp?.attributes?.desired_delivery_date,
                  },
                  {
                    displayName: 'Existing Email of Supplier',
                    displayData: this.state.showRfp?.attributes?.existing_supplier_email_address
                  },
                  {
                    displayName: 'File Name',
                    displayData: <a href={`${this.state.showRfp?.attributes?.file}`} target="_blank" rel="noopener noreferrer external">{this.state.showRfp?.attributes?.file?.split('/').pop().trim()}</a>,
                  },

                  {
                    displayData: this.handleShowOptionalEmailDynamic(this.state.showRfp),
                  },
                ]
              }
              buttonsInfo={
                [
                  {
                    name: 'Cancel',
                    disabled: false,
                    variant: 'contained',
                    onClickHandle: this.handleShowRFPCloseModal,
                    styling: newStyles.modalCancelButton,
                  },

                ]
              }
            />


            {/*============>>>>>>> RFP Flow end */}





            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openEditPurchaseOrderModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Update Purchase Order
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Location
                    </Typography>
                    <StyledTextField
                      required
                      variant="outlined"
                      type="text"
                      value={this.state.editedPoLocation}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      placeholder="Enter Location"
                      onChange={this.handleEditPoLocationChange}
                    />
                    <Typography
                      data-testid={"edited_po_required_locations"}
                      style={newStyles.validationText}
                    >
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoLocation,
                        this.state.editedPurchaseOrderLocationRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Supplier
                    </Typography>
                    <StyledSelect
                      input={<Input />}
                      label="Select Supplier"
                      displayEmpty
                      value={this.state.editedPoSupplier}
                      onChange={this.handleEditPoSupplierChange}
                      disableUnderline
                      style={this.purchaseOrdersStyleSelectBoxLableDisplay(
                        this.state.editedPoSupplier
                      )}
                    >
                      {this.state.suppliersList?.map((supplier: any) => {
                        return (
                          <MenuItem key={supplier?.id} value={supplier?.id}>
                            {supplier?.attributes?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoSupplier,
                        this.state.editedPurchaseOrderSupplierRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Order Date
                    </Typography>
                    <StyledTextField
                      style={newStyles.textFieldBox}
                      required
                      type={this.purchaseOrdersDateTypeConditionCheckRendered(
                        this.state.editedPoOrderDateType
                      )}
                      value={this.state.editedPoOrderDate}
                      onChange={this.handleEditPoOrderDateChange}
                      placeholder="Enter Order Date"
                      onFocus={this.handleEditPoOrderDateTypeFocus}
                      onBlur={this.handleEditPoOrderDateTypeBlur}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoOrderDate,
                        this.state.editedPurchaseOrderOrderDateRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      FOB Point
                    </Typography>
                    <StyledTextField
                      value={this.state.editedPoFobPoint}
                      onChange={this.handleEditPoFobPointChange}
                      placeholder="Enter FOB Point"
                      variant="outlined"
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoFobPoint,
                        this.state.editedPurchaseOrderFobPointRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Shipping Address
                    </Typography>
                    <StyledTextField
                      variant="outlined"
                      disabled
                      value={this.state.editedPoShippingAddress}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      onChange={this.handlePoShippingAddressChange}
                      style={newStyles.textFieldBox}
                      required
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoShippingAddress,
                        this.state
                          .editedPurchaseOrderShippingAddressRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Billing Address
                    </Typography>
                    <StyledTextField
                      variant="outlined"
                      placeholder="Enter Billing Address"
                      required
                      disabled
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      value={this.state.editedPoBillingAddress}
                      onChange={this.handleEditPoBillingAddressChange}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoBillingAddress,
                        this.state
                          .editedPurchaseOrderBillingAddressRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      GST Number
                    </Typography>
                    <StyledTextField
                      required
                      variant="outlined"
                      placeholder="Enter GST Number"
                      //value={this.state.editedPoGstNumber}
                      disabled
                      value={this.state.poGstNumber}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoGstNumber,
                        this.state.editedPurchaseOrderGstNumberRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Mode of Transit
                    </Typography>
                    <StyledTextField
                      required
                      variant="outlined"
                      placeholder="Enter Mode of Transit"
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      value={this.state.editedPoModeOfTransit}
                      onChange={this.handleEditPoModeOfTransitChange}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoModeOfTransit,
                        this.state.editedPurchaseOrderModeOfTransitRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Products
                    </Typography>
                    <StyledSelect
                      value={this.purchaseOrdersEditedProductsFilteredValueRendered()}
                      onChange={this.handleEditPoProductChange}
                      multiple
                      variant="outlined"
                      disableUnderline
                      displayEmpty
                      style={this.purchaseOrdersEditedProductsStyleSelectBoxLableRendered()}
                      input={<Input />}
                    >
                      <MenuItem value="No value" disabled>
                        Select Product
                      </MenuItem>
                      {this.state.productsList?.map((product: any) => {
                        return (
                          <MenuItem key={product?.id} value={product?.id}>
                            {product?.attributes?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersProductsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoProduct,
                        this.state.editedPurchaseOrderProductsRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Comments for PO
                    </Typography>
                    <StyledTextField
                      onChange={this.handleEditPoCommentForPoChange}
                      placeholder="Enter Comments for PO"
                      style={newStyles.textFieldBox}
                      value={this.state.editedPoCommentForPO}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      required
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoCommentForPO,
                        this.state.editedPurchaseOrderCommentsForPoRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Amount Calculation
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedPoAmountCalculation}
                      placeholder="Enter Amount Calculation"
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      onChange={this.handleEditPoAmountCalculationChange}
                      variant="outlined"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoAmountCalculation,
                        this.state
                          .editedPurchaseOrderAmountCalculationRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Department
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      label="Select Department"
                      value={this.state.editedPoDepartment}
                      onChange={this.handleEditPoDepartmentChange}
                      disableUnderline
                      displayEmpty
                      style={this.purchaseOrdersStyleSelectBoxLableDisplay(
                        this.state.editedPoDepartment
                      )}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Select Department
                      </MenuItem>
                      {this.state.departmentList?.map((department: any) => {
                        return (
                          <MenuItem key={department?.id} value={department?.id}>
                            {department?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoDepartment,
                        this.state.editedPurchaseOrderDepartmentRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>Budget</Typography>
                    <StyledTextField
                      required
                      value={this.state.editedPoBudget}
                      onChange={this.handleEditPoBudgetChange}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Budget"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoBudget,
                        this.state.editedPurchaseOrderBudgetRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Comment
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedPoComment}
                      onChange={this.handleEditPoCommentChange}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Comment"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.purchaseOrdersInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseOrderValidation,
                        this.state.editedPoComment,
                        this.state.editedPurchaseOrderCommentRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item style={{ marginLeft: "20px" }} lg={12}>
                  <Typography style={newStyles.termsHeadingTop}>
                    Payment Terms*
                  </Typography>
                  <Typography style={newStyles.termsBodyTop}>
                    lorem ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </Typography>
                </Grid>
                <Grid style={{ marginLeft: "20px" }} item lg={12}>
                  <Typography style={newStyles.termsHeadingBottom}>
                    Freight Terms*
                  </Typography>
                  <Typography style={newStyles.termsBodyBottom}>
                    lorem ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </Typography>
                </Grid>
              </Grid>
              <Box>
                <Button
                  onClick={this.handleEditPurchaseOrder}
                  style={newStyles.modalButton}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={this.handleEditPurchaseOrderCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openPurchaseRequisitionListModal}
            >
              <ToastMessageDisplay
                data-testid={"toast_msg_close"}
                message={this.state.customFromRequisitionSelectMessage}
                handleClose={this.handleCustomFromRequisitionSnackbarClose}
              />
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography style={newStyles.modalHeading} variant="h5">
                  Create from Requisition
                </Typography>
                <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleCustomFromRequisitionPOCloseModal} />
              </Box>
              {this.purchaseRequisitionPoTableRendered()}
              <PaginationDisplay
                totalPages={this.state.totalPagesPurchaseRequisitionPO}
                currentPage={this.state.currentPagePurchaseRequisitionPO}
                handlePageChange={this.handlePurchaseRequisitionPoPageChange}
              />
            </Dialog>




            {/* receipt flow started */}
            <Dialog
              maxWidth="xl"
              PaperProps={{ style: { width: '96%', height: "100%" } }}
              style={newStyles.dialog}
              open={this.state.openAddReceiptModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Receipt Number : {this.state.receiptNumberUnique}
              </Typography>
              <Box>
                {
                  this.handleErrorCreateReceiptSnackBarFunc()
                }
              </Box>
              <Box>
                <Typography style={newStyles.receiptsNumValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation, this.state.receiptNumberUnique, this.state.receiptNumberUniqueRequiredField)}</Typography>
              </Box>
              <Grid container>
                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Purchase Order
                    </Typography>
                    <Autocomplete
                      id="receipt_selected_po"
                      options={this.handleReceiptsPONumbersDataSelectionRendered(this.state.recieptsPOSelectionDataArr)}
                      getOptionLabel={(product) => (this.getRceiptItemsLabel(product))}
                      onChange={(event, value) => (this.handleReceiptSelectedPo(event, value))}
                      renderOption={(option: any, { selected }: any) => (
                        <React.Fragment>
                          {option?.attributes?.purchase_order_number}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} value={this.state.receiptSelectedPOtSearchTerm} variant="outlined" placeholder="Find or Select Purchase Order" style={{ marginBottom: '10px', border: '0.005px solid #B1B1AE', borderRadius: '10px' }} />
                      )}
                    />
                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation, this.state.receiptSelectedPo, this.state.receiptSelectedPoRequiredField)}</Typography>
                  </Box>
                </Grid>

                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Receipt Date
                    </Typography>
                    <StyledTextField
                      required
                      type={this.recieptsDateTypeConditionCheckRendered(
                        this.state.receiptDateType
                      )}
                      value={this.state.receiptReceiptDate}
                      onChange={this.handleReceiptReceiptDate}
                      onFocus={this.handleReceiptReceiptDateTypeFocus}
                      onBlur={this.handleReceiptReceiptDateTypeBlur}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Receipt Date"
                    />

                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation, this.state.receiptReceiptDate, this.state.receiptReceiptDateRequiredField)}</Typography>

                  </Box>
                </Grid>

                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Suppliers
                    </Typography>
                    <StyledTextField
                      id={"reciepts_supplier"}
                      disabled
                      required
                      type="text"
                      value={this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.attributes?.supplier_name}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment
                            style={newStyles.textFieldEndAdornment}
                            position="end"
                          >
                            {this.recieptsCircularProgressBarRendered(
                              this.state.receiptSelectedPo,
                              this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.attributes?.supplier_name
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      placeholder={'Supplier Name'}
                    />

                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation,
                      this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.attributes?.supplier_name,
                      this.state.receiptSupplierNameRequiredField

                    )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Invoice Number
                    </Typography>
                    <StyledTextField
                      id={"reciepts_invoice_number"}
                      required
                      type="text"
                      value={this.state.receiptInvoiceNumber}
                      onChange={this.handleReceiptInvoiceNumberChange}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      variant="outlined"
                      placeholder={'Enter Invoice Number'}
                    />

                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation,
                      this.state.receiptInvoiceNumber,
                      this.state.receiptInvoiceNumberRequiredField

                    )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Attachment
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      label="Select Attachment Type"
                      value={this.state.receiptAttachment}
                      onFocus={this.handleReceiptAttachmentDropdownDisplay}
                      onChange={this.handleReceiptFileTypeAttachmentChange}
                      disableUnderline
                      displayEmpty
                      style={this.recieptsNewStyleSelectsBoxLableRendered(
                        this.state.receiptAttachment
                      )}
                      input={<Input />}
                    >
                      <MenuItem value="" selected disabled>
                        Select Attachment Type
                      </MenuItem>
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="doc">DOC</MenuItem>
                      <MenuItem value="csv">CSV</MenuItem>
                    </StyledSelect>
                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation,
                      this.state.receiptAttachment,
                      this.state.receiptAttachmentRequiredField
                    )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item sm={6} md={6} lg={4} style={{ marginBottom: '10px' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', textAlign: 'right', paddingRight: '20px', boxSizing: 'border-box' as 'border-box' }}>
                    <Typography style={newStyles.modalLabel}>
                      {this.recieptsAttachmentFileDisplay()}
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={
                        <AddSharpIcon
                          style={newStyles.fileAttachmentButtonIcon}
                        />
                      }
                      style={newStyles.attachmentButton}
                      component="label"
                    >
                      Add Attachment
                      <input
                        onChange={this.handleReceiptAttachmentChange}
                        accept={this.recieptsFileTypeAccept()}
                        hidden
                        type="file"
                      />
                    </Button>

                    <Typography style={newStyles.receiptsInpFieldsValidationText}>{this.createUpdateRFPInputFieldsValidationErrorRendered(this.state.showIsCreateReceiptValidation,
                      this.state.receiptAttachmentFile,
                      this.state.receiptAttachmentFileRequiredField

                    )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              {/* receipt items table */}

              <Box>
                <TableContainer component={Paper} style={newStyles.productsTable}>
                  <Table >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Item Number</TableCell>
                        <TableCell align="left">Quantity Required</TableCell>
                        <TableCell align="left">Quantity Recieved</TableCell>
                        <TableCell align="left">Comments</TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      {this.state.receiptCatelogueItemsStore?.map((prod: any, index: number) => {
                        return (
                          <TableRow key={prod?.id}>
                            <TableCell align="left">{prod?.product_id}</TableCell>
                            <TableCell align="left">{prod?.quantity}</TableCell>
                            <TableCell align="left">
                              <Box style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box' as 'border-box' }}>
                                <StyledTextField
                                  onChange={(e: any) =>
                                    this.handleReceiptQuantityRecievedChange(e, prod, index)
                                  }
                                  required
                                  InputProps={{ inputProps: { min: 1 } }}
                                  variant="outlined"
                                  placeholder="Add Quantity"
                                  inputProps={{
                                    style: {
                                      fontFamily: 'Plus Jakarta Display',
                                      fontWeight: 400,
                                      fontSize: 16,
                                    }
                                  }}
                                  value={prod?.quantity_received}
                                  style={{ maxWidth: '50%' }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Box style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box' as 'border-box' }} >
                                <StyledTextField
                                  value={prod?.comment_received}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  variant="outlined"
                                  inputProps={{
                                    style: {
                                      fontWeight: 400,
                                      fontFamily: 'Plus Jakarta Display',
                                      fontSize: 16,
                                    }
                                  }}
                                  placeholder="Comments"
                                  style={{ maxWidth: '80%' }}
                                  onChange={(event: any) =>
                                    this.handleReceiptCommentsChange(event, prod, index)
                                  }
                                  required
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="left">{''}</TableCell>
                          </TableRow>
                        )
                      })}
                      {this.state.receiptNonCatelogueItemsStore?.map((prod: any, index: number) => {
                        return (
                          <TableRow key={prod?.id}>
                            <TableCell align="left">{prod?.id}</TableCell>
                            <TableCell align="left">{prod?.quantity}</TableCell>
                            <TableCell align="left">
                              <Box style={{ display: 'flex', flexDirection: 'column', boxSizing: 'border-box' as 'border-box' }}>
                                <StyledTextField
                                  style={{ maxWidth: '50%' }}
                                  required
                                  value={prod?.quantity_received}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  inputProps={{
                                    style: {
                                      fontWeight: 400,
                                      fontFamily: 'Plus Jakarta Display',
                                      fontSize: 16,
                                    }
                                  }}
                                  onChange={(event: any) =>
                                    this.handleReceiptNonCatelogQuantityRecievedChange(event, prod, index)
                                  }
                                  variant="outlined"
                                  placeholder="Add Quantity"
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="left">
                              <Box style={{
                                boxSizing: 'border-box' as 'border-box',
                                flexDirection: 'column',
                                display: 'flex',
                              }} >
                                <StyledTextField
                                  onChange={(event: any) =>
                                    this.handleReceiptNonCatelogCommentsChange(event, prod, index)
                                  }
                                  required
                                  value={prod?.comment_received}
                                  InputProps={{ inputProps: { min: 1 } }}
                                  placeholder="Comments"
                                  style={{ maxWidth: '80%' }}
                                  inputProps={{
                                    style: {
                                      fontWeight: 400,
                                      fontFamily: 'Plus Jakarta Display',
                                      fontSize: 16,
                                    }
                                  }}
                                  variant="outlined"
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="left">{'Non Catalogue'}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box style={newStyles.receiptsScreenBtnWrapper}>
                <Button
                  data-testid={"createReceiptBtn"}
                  onClick={this.handleCreateReceipt}
                  style={newStyles.modalButton}
                  variant="contained"
                >
                  {this.handleCreateReceiptLoadersFunc(this.state.isReceiptCreating)}
                </Button>
                <Button
                  onClick={this.handleAddReceiptCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            {this.showReceiptCatalougeAndNonCatalougeItems()}

            <Dialog maxWidth="xl" style={newStyles.rfpErrorDialog} open={this.state.openReceiptCreateShowErrorModal}>
              <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
                <Typography style={{ color: 'red' }}>All fields are required **</Typography>
                <Typography>Please check all fields again</Typography>
                <Box>
                  <Button style={newStyles.errorModalButton} onClick={this.handleReceiptCreateShowErrorModalClose}>OK</Button>
                </Box>
              </Box>
            </Dialog>

            {/* receipt flow ended */}

            <Dialog
              maxWidth="xl"
              PaperProps={{ style: { width: '96%', height: "100%" }, }}
              style={newStyles.dialog}
              open={this.state.openCustomFromRequisitionCreatePoModal}
            >
              <ToastMessageDisplay
                message={this.state.prPoCreateMessage}
                handleClose={this.handlePrPoCreateSnackbarClose}
              />
              <ToastMessageDisplay message={this.state.prPoEmailToSupplierSentOrNotErrorMessage} handleClose={this.handlePrPoEmailToSupplierSendOrNotErrorMessageSnackbarClose} />
              <ToastMessageDisplay message={this.state.prPoFinalPdfDownloadOrNotMessage} handleClose={this.handlePrPoFinalPdfDownloadOrNotMessageSnackbarClose} />
              <ToastMessageDisplay
                message={this.state.paymentTermsFetchMessage}
                handleClose={this.handlePaymentTermsFetchSnackbarClose}
              />

              <Box display={"flex"} justifyContent="space-between" width={"100%"}>
                <Typography style={newStyles.modalHeading} variant="h5">
                  Create From Purchase Requisition No : {this.state.prPoSelectedRequisitionNumber}
                </Typography>
                <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleClosePurchaseFromRequisitionCreatePO} />
              </Box>

              <Box style={newStyles.rfpTabsScreenWrapper}>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    }
                  }}
                  style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                  value={this.state.prPoCreateScreenActiveTabs}
                  onChange={(event: React.ChangeEvent<{}>, value: any) => this.handlePrPoScreenTabsChange(event, value)}>
                  <Tab style={this.handleTabsPrPoActiveStyleChange(0)}
                    label="Details" />
                  <Tab style={this.handleTabsPrPoActiveStyleChange(1)}
                    label="Review Items" />
                </Tabs>
              </Box>
              {this.subpurchaseRequisitionMainOrderAllFieldsRenderFunc()}
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.rfpErrorDialog} open={this.state.openCreatePrPoShowErrorModal}>
              <Box style={{ display: 'flex', flexDirection: 'column', rowGap: '15px', textAlign: 'center', padding: '20px' }}>
                <Typography style={{ color: 'red' }}>All fields are required **</Typography>
                <Typography>Please check Details and Add Items section again</Typography>
                <Box>
                  <Button style={newStyles.errorModalButton} onClick={this.handleCloseCreatePrPoErrorModal}>OK</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={this.state.isPrPoMailSendConfirmed}
              onClose={this.handlePrPoMailSendConfirmationCloseBar}
              aria-describedby="Po mail send confirmation"
            >
              <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
              <DialogActions>
                <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handlePrPoRecordSupplierMailSend}>Yes</Button>
                <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handlePrPoMailSendConfirmationCloseBar}>No</Button>
              </DialogActions>
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openPrPoSupplierMailSeccessModal}>
              <Box style={{ textAlign: 'center', padding: '20px' }}>
                <Typography>An email with Purchase Order : {this.state.prPoNumberUnique}</Typography>
                <Typography>has been successfully sent to the supplier</Typography>
                <Box>
                  <Button onClick={this.handlePrPoSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openAddSupplierModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Add Suppliers
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Supplier Name
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierSupplierName}
                      onChange={this.handleSupplierSupplierName}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Supplier Name"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierSupplierName,
                        this.state.supplierNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Terms of Payment
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      multiple
                      label="Select Category"
                      value={this.state.paymentTermsNameSuppliers}
                      onChange={this.handleSupplierPaymentTerms}
                      disableUnderline
                      displayEmpty
                      style={this.styledSelectStyle()}
                      input={<Input style={{ flexWrap: "wrap" }} />}
                    >
                      <MenuItem value="" disabled selected>Select PaymentTerms</MenuItem>
                      {this.state.paymentTermsList?.map((payment: any) => {
                        return <MenuItem key={payment?.id} value={payment?.id}>{payment?.attributes?.payment_term_name}</MenuItem>
                      })}
                    </StyledSelect>

                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersSelectFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierTermsOfPayment,
                        this.state.supplierTermsOfPaymentRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Email Address
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierEmailAddress}
                      onChange={this.handleSupplierEmailAddress}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Email Address"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierEmailAddress,
                        this.state.supplierEmailAddressRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {
                        this.handleSupplierVaidEmailErrorMessage()
                      }
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {
                        this.handleIsSupplierFieldCheckValidTrueFalseError(this.state.supplierEmailInvalidPatternError, "Invalid Email *")
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Contact Number
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierContactNumber}
                      onChange={this.handleSupplierContactNumber}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Contact Number"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierContactNumber,
                        this.state.supplierContactNumberRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.handleIsSupplierFieldCheckValidTrueFalseError(this.state.supplierContactNumberErrorMessage, "Contact number is wrong *")}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersContactNumbersValidationErrorDisplay(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierContactNumber,
                        this.state.supplierContactNumberValidationError
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}  >
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Point of Contact
                    </Typography>
                    <StyledTextField
                      id="supplier_pointcontact"
                      required
                      value={this.state.supplierPointOfContact}
                      onChange={this.handleSupplierPointOfContact}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Point of Contact"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.handleIsSupplierFieldCheckValidTrueFalseError(this.state.supplierPointOfContactNumberErrorMessage, "Point of contact number is wrong *")}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierPointOfContact,
                        this.state.supplierPointOfContactRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersContactNumbersValidationErrorDisplay(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierPointOfContact,
                        this.state.supplierPointContactNumberValidationError
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      GST Number
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierGSTNumber}
                      onChange={this.handleSupplierGstNumber}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter GST Number"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.gstNumberValidationError()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Address of Supplier Company
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierAddressOfSupplierCompany}
                      onChange={this.handleSupplierAddressOfSupplierCompany}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Address of Supplier Company"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierAddressOfSupplierCompany,
                        this.state.supplierAddressOfSupplierCompanyRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      State / Province
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierStateProvince}
                      onChange={this.handleSupplierStateProvince}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter State"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierStateProvince,
                        this.state.supplierStateProvinceRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Zip code/ Pin Code
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.supplierZipCodePinCode}
                      onChange={this.handleSupplierZipCodePinCode}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Zip code/ Pin Code"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsCreateSupplierValidation,
                        this.state.supplierZipCodePinCode,
                        this.state.supplierZipCodePinCodeRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <Box pr={"10px"}>
                  <Button
                    onClick={this.handleCreateSupplier}
                    style={newStyles.modalButton}
                    variant="contained"
                  >
                    Add Supplier
                  </Button>
                  <Button
                    onClick={this.handleAddSupplierCloseModal}
                    style={newStyles.modalCancelButton}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openEditSupplierModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Update Supplier
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Supplier Name
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierSupplierName}
                      onChange={this.handleEditedSupplierSupplierName}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Supplier Name"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierSupplierName,
                        this.state.editedSupplierNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Terms of Payment
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      multiple
                      label="Select Category"
                      value={this.state.paymentTermsNameSuppliers}
                      onChange={this.handleSupplierPaymentTerms}
                      disableUnderline
                      displayEmpty
                      style={this.styledSelectStyle()}
                      input={<Input style={{ flexWrap: "wrap" }} />}
                    >
                      <MenuItem value="" disabled selected>Select PaymentTerms</MenuItem>
                      {this.state.paymentTermsList?.map((payment: any) => {
                        return <MenuItem key={payment?.id} value={payment?.id}>{payment?.attributes?.payment_term_name}</MenuItem>
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierTermsOfPayment,
                        this.state.editedSupplierTermsOfPaymentRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Email Address
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierEmailAddress}
                      onChange={this.handleEditedSupplierEmailAddress}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Email Address"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierEmailAddress,
                        this.state.editedSupplierEmailAddressRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.state.showIsEditSupplierValidation &&
                        this.state.editedSupplierEmailAddress != "" &&
                        !this.state.editedSupplierEmailAddress.match(
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/
                        )
                        ? this.state.editedSupplierEmailAddressEmailMatchError
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Contact Number
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierContactNumber}
                      onChange={this.handleEditedSupplierContactNumber}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Contact Number"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierContactNumber,
                        this.state.editedSupplierContactNumberRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.state.showIsEditSupplierValidation &&
                        this.state.editedSupplierContactNumber != "" &&
                        !this.state.editedSupplierContactNumber.match(/^\d+$/)
                        ? this.state.editedSupplierContactNumberMatchError
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Point of Contact
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierPointOfContact}
                      onChange={this.handleEditedSupplierPointOfContact}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Point of Contact"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierPointOfContact,
                        this.state.editedSupplierPointOfContactRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Address of Supplier Company
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierAddressOfSupplierCompany}
                      onChange={
                        this.handleEditedSupplierAddressOfSupplierCompany
                      }
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Address of Supplier Company"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierAddressOfSupplierCompany,
                        this.state
                          .editedSupplierAddressOfSupplierCompanyRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      GST Number
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierGSTNumber}
                      onChange={this.handleEditedSupplierGstNumber}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter GST Number"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierGSTNumber,
                        this.state.editedSupplierGstNumberRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.updateSupplierInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierGSTNumber,
                        this.state.editedSupplierGSTMatchError
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      State / Province
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierStateProvince}
                      onChange={this.handleEditedSupplierStateProvince}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter State"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierStateProvince,
                        this.state.editedSupplierStateProvinceRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Zip code/ Pin Code
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedSupplierZipCodePinCode}
                      onChange={this.handleEditedSupplierZipCodePinCode}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Zip code/ Pin Code"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addSuppliersInputFieldsValidationErrorRendered(
                        this.state.showIsEditSupplierValidation,
                        this.state.editedSupplierZipCodePinCode,
                        this.state.editedSupplierZipCodePinCodeRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Button
                  onClick={this.handleEditSupplierapi}
                  style={newStyles.modalButton}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={this.handleEditSupplierCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            <ViewDetailsDialog
              id="show_supplier_dialog"
              heading="Show Supplier"
              open={this.state.openShowSupplierModal}
              data={[
                {
                  displayName: "Supplier Name",
                  displayData: this.state.showSupplier?.attributes
                    ?.supplier_name,
                },
                {
                  displayName: "Unique Code",
                  displayData: this.state.showSupplier?.attributes?.unique_code,
                },
                {
                  displayName: "Terms of Payment",
                  displayData: this.state.showSupplier?.attributes
                    ?.payment_terms?.data.map((payment: any)=>(payment?.attributes?.payment_term_name))?.join(", "),
                },
                {
                  displayName: "Email Address",
                  displayData: this.state.showSupplier?.attributes
                    ?.email_address,
                },
                {
                  displayName: "Contact Number",
                  displayData: this.state.showSupplier?.attributes
                    ?.contact_number,
                },
                {
                  displayName: "Point of Contact",
                  displayData: this.state.showSupplier?.attributes
                    ?.point_of_contact,
                },
                {
                  displayName: "Address of Supplier Company",
                  displayData: this.state.showSupplier?.attributes
                    ?.address_of_supplier_company,
                },
                {
                  displayName: "GST Number",
                  displayData: this.state.showSupplier?.attributes?.gst_number,
                },
                {
                  displayName: "State / Province",
                  displayData: this.state.showSupplier?.attributes?.state,
                },
                {
                  displayName: "Zip code/ Pin Code",
                  displayData: this.state.showSupplier?.attributes?.zip_code,
                },
              ]}
              buttonsInfo={[
                {
                  name: "Cancel",
                  disabled: false,
                  variant: "contained",
                  onClickHandle: this.handleShowSupplierCloseModal,
                  styling: newStyles.modalCancelButton,
                },
              ]}
            />

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openAddProductModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Item id: {this.state.addItemSupplierNoUnique}
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Item Name
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.productName}
                      onChange={this.handleProductName}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Item Name"
                    />
                    <Typography
                      data-testid={"create_product_error"}
                      style={newStyles.validationText}
                    >
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateProductValidation,
                        this.state.productName,
                        this.state.productNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Unit Measurement
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.productUnitMeasurement}
                      onChange={this.handleProductUnitMeasurement}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Unit Measurement"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateProductValidation,
                        this.state.productUnitMeasurement,
                        this.state.productUnitMeasurementRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Unit Price
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.productUnitPrice}
                      onChange={this.handleProductUnitPrice}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Unit Price"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateProductValidation,
                        this.state.productUnitPrice,
                        this.state.productUnitPriceRequiredField
                      )}
                    </Typography>
                    <Typography style={newStyles.validationText}>
                      {this.addProductsUnitPriceMatchValidationErrorRendered()}
                    </Typography>
                  </Box>
                </Grid>
                {this.state.itemTypesList?.map(
                  (product: any, index: number) => {
                    return (
                      <Grid
                        item
                        lg={2}
                        className="products_type_grid"
                        key={product?.id}
                        style={{ paddingLeft: '16px' }}
                      >
                        <Typography style={newStyles.modalLabel}>
                          {this.addProductsStyleTypeRendered(index)}
                        </Typography>
                        <StyledTextField
                          className={"add_product_types"}
                          required
                          value={product.name}
                          onClick={() => this.handleProductType(product.id)}
                          style={newStyles.textFieldBoxSmall}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {this.addProductsCheckedUncheckedRadioBtnRendered(
                                  product
                                )}
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            disabled: true,
                            readOnly: true,
                            style: {
                              fontSize: 16,
                              fontWeight: 400,
                              fontFamily: "Plus Jakarta Display",
                            },
                          }}
                          variant="outlined"
                        />
                        <Typography
                          data-testid={"product_type_error_display"}
                          style={newStyles.productsTypevalidationText}
                        >
                          {this.addProductsInputFieldsValidationErrorRendered(
                            this.state.showIsCreateProductValidation,
                            this.state.productTypeSelectedId,
                            this.state.productTypeRequiredField
                          )}
                        </Typography>
                      </Grid>
                    );
                  }
                )}
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Item Category
                    </Typography>
                    <StyledSelect
                      id={"add_products_Category"}
                      variant="outlined"
                      label="Select Category"
                      value={this.state.productCategory}
                      onChange={this.handleProductCategory}
                      disableUnderline
                      displayEmpty
                      style={this.addProductsStyleSelectBoxLableRendered(
                        this.state.productCategory
                      )}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Select Category
                      </MenuItem>
                      {this.state.showSettingsCategory?.map((productCategory: any) => {
                        return (
                          <MenuItem
                            className={"add_product_items"}
                            key={productCategory?.id}
                            value={productCategory?.id}
                          >
                            {productCategory?.attributes?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateProductValidation,
                        this.state.productCategory,
                        this.state.productCategoryRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Suppliers
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      label="Select Suppliers"
                      multiple
                      value={this.state.addItemSupplierMultiple}
                      onChange={this.handleAddItemMultipleSuppliersChange}
                      disableUnderline
                      displayEmpty
                      style={this.rfpExistingSuppliersNewStyleLableBoxDisplay()}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled selected>
                        Select Existing Suppliers
                      </MenuItem>
                      {this.state.suppliersList?.map((supplier: any) => {
                        return (
                          <MenuItem key={supplier?.id} value={supplier?.id}>
                            {supplier?.attributes?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateProductValidation,
                        this.state.addItemSupplierMultiple,
                        this.state.productSupplierRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                width="100%"
              >
                <Box>
                  <Button
                    onClick={this.handleCreateProduct}
                    style={newStyles.modalButton}
                    variant="contained"
                  >
                    Add Item
                  </Button>
                  <Button
                    onClick={this.handleAddProductCloseModal}
                    style={newStyles.modalCancelButton}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openEditProductModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Update Item
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Item Name
                    </Typography>
                    <StyledTextField
                      requireds
                      value={this.state.editedProductName}
                      onChange={this.handleEditedProductName}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Product Name"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsEditProductValidation,
                        this.state.editedProductName,
                        this.state.editedProductNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Unit Measurement
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedProductUnitMeasurement}
                      onChange={this.handleEditedProductUnitMeasurement}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Unit Measurement"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsEditProductValidation,
                        this.state.editedProductUnitMeasurement,
                        this.state.editedProductUnitMeasurementRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Unit Price
                    </Typography>
                    <StyledTextField
                      required
                      value={this.state.editedProductUnitPrice}
                      onChange={this.handleEditedProductUnitPrice}
                      style={newStyles.textFieldBox}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      variant="outlined"
                      placeholder="Enter Unit Price"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsEditProductValidation,
                        this.state.editedProductUnitPrice,
                        this.state.editedProductUnitPriceRequiredField
                      )}
                    </Typography>
                    <Typography
                      data-testid={"product_price_error_display"}
                      style={newStyles.validationText}
                    >
                      {this.editedProductsUnitPriceMatchErrorRendered()}
                    </Typography>
                  </Box>
                </Grid>
                {this.state.productTypesList?.map(
                  (product: any, index: number) => {
                    return (
                      <Grid item lg={2} key={product?.id}>
                        <Box style={newStyles.gridItem}>
                          <Typography style={newStyles.modalLabel}>
                            {this.addProductsStyleTypeRendered(index)}
                          </Typography>
                          <StyledTextField
                            required
                            value={product.name}
                            onClick={(event: any) =>
                              this.handleEditedProductType(event, product.id)
                            }
                            style={newStyles.textFieldBoxSmall}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {this.editedAddProductsCheckedUncheckedRadioBtnRendered(
                                    product
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              disabled: true,
                              readOnly: true,
                              style: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: "Plus Jakarta Display",
                              },
                            }}
                            variant="outlined"
                          />
                          <Typography
                            data-testid={"edited_product_types_error_display"}
                            style={newStyles.validationText}
                          >
                            {this.addProductsInputFieldsValidationErrorRendered(
                              this.state.showIsEditProductValidation,
                              this.state.editedProductTypeSelectedId,
                              this.state.editedProductTypeRequiredField
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  }
                )}
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Product Category
                    </Typography>
                    <StyledSelect
                      variant="outlined"
                      label="Select Product"
                      value={this.state.editedProductCategory}
                      onChange={this.handleEditedProductCategory}
                      disableUnderline
                      displayEmpty
                      style={this.addProductsStyleSelectBoxLableRendered(
                        this.state.editedProductCategory
                      )}
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Select Product
                      </MenuItem>
                      {this.state.productCategoriesList?.map((product: any) => {
                        return (
                          <MenuItem key={product?.id} value={product?.id}>
                            {product?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsEditProductValidation,
                        this.state.editedProductCategory,
                        this.state.editedProductCategoryRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Suppliers
                    </Typography>
                    <StyledSelect
                      style={this.rfpExistingSuppliersNewStyleLableBoxDisplay()}
                      label="Select Suppliers"
                      displayEmpty
                      input={<Input />}
                      variant="outlined"
                      multiple
                      value={this.state.editedItemSupplierMultiple}
                      onChange={this.handleEditedItemMultipleSuppliersChange}
                      disableUnderline
                    >
                      <MenuItem value="" disabled selected>
                        Select Existing Suppliers
                      </MenuItem>
                      {this.state.suppliersList?.map((supplier: any) => {
                        return (
                          <MenuItem key={supplier?.id} value={supplier?.id}>
                            {supplier?.attributes?.supplier_name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.addProductsInputFieldsValidationErrorRendered(
                        this.state.showIsEditProductValidation,
                        this.state.editedItemSupplierMultiple,
                        this.state.editedSupplierMultipleRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={this.handleEditProduct}
                  style={newStyles.modalButton}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={this.handleEditProductCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              PaperProps={{ style: { width: '96%', height: "100%" } }}
              style={newStyles.dialog}
              open={this.state.openAddPurchaseOrderModal}
            >
              <Box style={newStyles.modalHeadingWrapper}>
                <Typography style={newStyles.modalHeading} variant="h5">
                  New Purchase Order Number : {this.state.poNumberUnique}
                </Typography>
                <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handlePoTabsScreenClose} />
              </Box>
              <Box style={newStyles.rfpTabsScreenWrapper}>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    }
                  }}
                  style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                  value={this.state.rfpCreateScreenActiveTabs}
                  onChange={(event: React.ChangeEvent<{}>, value: any) => this.handlePoScreenTabsChange(event, value)}>
                  <Tab style={this.handleTabsPoActiveStyleChange(0)}
                    label="Details" />
                  <Tab style={this.handleTabsPoActiveStyleChange(1)}
                    label="Add items" />
                </Tabs>
                <Box>
                  <ToastMessageDisplay
                    message={this.state.paymentTermsFetchMessage}
                    handleClose={this.handlePaymentTermsFetchSnackbarClose}
                  />
                </Box>
              </Box>
              {
                this.state.poCreateScreenActiveTabs === 0 &&
                <>
                  <Grid container>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Order Date
                        </Typography>
                        <StyledTextField
                          required
                          value={this.state.poOrderDate}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          onChange={this.handlePoOrderDateChange}
                          onBlur={this.handlePoOrderDateTypeBlur}
                          style={this.styledSelectStyle()}
                          type={"date"}
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: new Date()
                              .getFullYear()
                              .toString()
                                .padStart(4, "0") +
                                "-" +
                                (new Date().getMonth() + 1)
                                .toString()
                                  .padStart(2, "0") +
                                  "-" +
                                  new Date()
                                  .getDate()
                                  .toString()
                                  .padStart(2, "0"),
                                  max: this.state.deliveryDate,
                                },
                              }}
                              onFocus={this.handlePoOrderDateTypeFocus}
                        />
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poOrderDate, this.state.purchaseOrderOrderDateRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Delivery Date
                        </Typography>
                        <StyledTextField
                          required
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          onFocus={this.handleDeliveryDateTypeFocus}
                          value={this.state.deliveryDate}
                          onBlur={this.handleDeliveryDateTypeBlur}
                          style={this.styledSelectStyle()}
                          onChange={this.handleDeliveryDateChange}
                          type={"date"}
                          variant="outlined"
                          InputProps={{
                            inputProps: {
                              min: (this.state.poOrderDate || new Date()
                                .getFullYear()
                                .toString()
                                .padStart(4, "0") +
                                "-" +
                                (new Date().getMonth() + 1)
                                  .toString()
                                  .padStart(2, "0") +
                                "-" +
                                (new Date()
                                  .getDate())
                                  .toString()
                                  .padStart(2, "0")),
                            },
                          }}
                        />
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.deliveryDate, this.state.purchaseOrderDeliveryDateRequiredField)}
                        </Typography>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationErrorDate(this.state.showIsCreatePurchaseOrderValidation, this.state.poOrderDate, this.state.deliveryDate, this.state.purchaseOrderDeliveryDateErrorFormat)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Department
                        </Typography>
                        <StyledSelect
                          input={<Input />}
                          displayEmpty
                          style={this.styledSelectStyle()}
                          onChange={this.handlePoDepartmentChange}
                          variant="outlined"
                          disableUnderline
                          id={"po_department_select"}
                          value={this.state.poDepartment}
                          label="Select Product"
                        >
                          <MenuItem value="" selected disabled>Select Department</MenuItem>
                          {this.state.departmentList?.map((department: any) => {
                            return (
                              <MenuItem key={department?.id} value={department?.id}>
                                {department?.name}
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poDepartment, this.state.purchaseOrderDepartmentRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Select Budget
                        </Typography>
                        <StyledSelect
                          style={this.styledSelectStyle()}
                          input={<Input />}
                          disableUnderline
                          id={"po_budget_select"}
                          label="Select Product"
                          variant="outlined"
                          displayEmpty
                          value={this.state.poBudget}
                          onChange={this.handlePoBudgetChange}
                        >
                          <MenuItem value="" disabled selected>Select Budget</MenuItem>
                          {this.state.budgetList?.map((budget: any) => {
                            return (
                              <MenuItem key={budget?.id} value={budget?.id}>
                                {budget?.attributes?.name}
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poBudget, this.state.purchaseOrderBudgetRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Suppliers
                        </Typography>
                        <StyledSelect
                          value={this.state.poSupplier}
                          disableUnderline
                          style={this.styledSelectStyle()}
                          id={"po_supplier_select"}
                          displayEmpty
                          variant="outlined"
                          label="Select Product"
                          onChange={this.handlePoSupplierChange}
                          input={<Input />}
                        >
                          <MenuItem value="" selected={true} disabled={true}>Select Supplier</MenuItem>
                          {this.state.supplierList?.map((supplier: any) => {
                            return (
                              <MenuItem key={supplier?.id} value={supplier.id}>
                                {supplier?.attributes?.supplier_name}
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poSupplier, this.state.purchaseOrderSupplierRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Payment Terms
                        </Typography>
                        <StyledSelect
                          variant="outlined"
                          className={"po_paymentterm_select"}
                          multiple
                          label="Select Category"
                          value={this.state.paymentTermsNameSuppliers}
                          onChange={this.handleSupplierPaymentTerms}
                          onClick={this.checkFetchPaymentTermsOrNot}
                          disableUnderline
                          displayEmpty
                          style={this.styledSelectStyle()}
                          input={<Input style={{ flexWrap: "wrap" }} />}
                        >
                          <MenuItem value="" selected disabled>Select Payment Terms</MenuItem>
                          {
                            this.state.getPaymentTermListSupplier?.map((w: any) => {
                              return (<MenuItem key={w?.id} value={w?.id}>{w?.attributes?.payment_term_name}</MenuItem>)
                            })
                          }
                        </StyledSelect>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.paymentTermsName, this.state.purchaseOrderPaymentTermsRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Taxation
                        </Typography>
                        <StyledSelect
                          onChange={this.handleTaxNameChange}
                          id={"po_taxation_select"}
                          displayEmpty
                          value={this.state.taxName}
                          label="Select Product"
                          style={newStyles.selectBox}
                          variant="outlined"
                          disableUnderline
                          input={<Input />}
                        >
                          <MenuItem value="" selected disabled>Select Taxation</MenuItem>
                          {this.state.taxations?.map((tax: any) => {
                            return (
                              <MenuItem key={tax?.id} value={tax?.id}>
                                {tax?.attributes?.tax_name}
                              </MenuItem>
                            );
                          })}
                        </StyledSelect>
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.taxName, this.state.purchaseOrderTaxationNameRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Location & Shipping Address
                        </Typography>
                        <StyledTextField
                          id={"po_shipping_address"}
                          style={newStyles.textFieldBox}
                          disabled
                          value={this.state.poShippingAddress}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          required
                          placeholder={this.poPlaceHolder()}
                          InputProps={{
                            style: { paddingRight: '98px', color: this.poPlaceHolderColor() },
                            disableUnderline: true,
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Location & Shipping Address Optional
                        </Typography>
                        <StyledTextField
                          id={"po_shipping_address_optional"}
                          style={newStyles.textFieldBox}
                          value={this.state.poShippingAddressOptional}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          onChange={this.handlePoShippingAddressOptionalChange}
                          required
                          placeholder={"Enter the Location & Shipping Address Optional"}
                          InputProps={{
                            style: { paddingRight: '98px', color: 'black' },
                            disableUnderline: true,
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          Billing Address
                        </Typography>
                        <StyledTextField
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          required
                          InputProps={{
                            style: { paddingRight: '98px', color: 'black' },
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          value={this.state.poBillingAddress}
                          onChange={this.handlePoBillingAddressChange}
                          style={newStyles.textFieldBox}
                          placeholder={"Enter the Billing Address"}
                        />
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poBillingAddress, this.state.purchaseOrderBillingAddressRequiredField)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <Button
                      onClick={() => {
                        this.handleOpenNextPurchaseModal();
                      }}
                      variant="contained"
                      data-testid={"dash_pr_create_next_btn"}
                      style={newStyles.modalButton}
                    >
                      Next
                    </Button>
                    <Button
                      variant="contained"
                      style={newStyles.modalCancelButton}
                      onClick={this.handleAddPurchaseOrderCloseModal}
                      data-testid={"dash_pr_cancel_btn"}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              }

              {/* screen 2 ***** */}
              <Box style={{ width: '100%' }}>
                {
                  this.state.poCreateScreenActiveTabs === 1 && <Box>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Select Item</Typography>
                      <Autocomplete
                        id="po_products_select"
                        getOptionLabel={(product) => (this.subgetPoCatalougeAllItemLabelFunc(product))}
                        style={newStyles.rfpItemsSelectBox}
                        onChange={this.handlePoDropdownProductsChange}
                        options={this.handleNewPoProductsListUpdation(this.state.poNonCatalougeCollectedItem)}
                        filterOptions={poFilterOptions}
                        multiple={false}
                        renderOption={(option: any, { selected }: any) => (
                          <React.Fragment>
                            <Radio
                              checked={selected}
                              style={{
                                color: "black",
                                marginRight: 8,
                              }}
                            />
                            {this.subhandlePoNonCatelogAllItemsRenderedFunc(option)}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField {...params}
                            variant="outlined"
                            value={this.state.poNonCatalougeCollectedItem}
                            onChange={this.getSearchedPoNonCatelogProductChange}
                            placeholder="Find or Select Item"
                          />
                        )}
                      />
                      <Typography style={newStyles.productsValidationTxt}>{this.addRFPSelectProductFieldValidationErrorRendered(this.state.showIsCreatePurchaseOrderValidation, this.state.filteredPoProducts, this.state.purchaseOrderProductsRequiredField)}</Typography>
                    </Box>
                    <Box>
                      <TableContainer component={Paper} style={newStyles.productsTable}>
                        <Table >
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Product Id</TableCell>
                              <TableCell align="left">Product Name</TableCell>
                              <TableCell align="left">Quantity</TableCell>
                              <TableCell align="left">Price</TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody >
                            {this.state.filteredPoProducts?.map((prod: any, index: number) => {
                              return (
                                <TableRow key={prod?.id}>
                                  <TableCell align="left">{prod?.id}</TableCell>
                                  <TableCell align="left">{prod?.attributes?.name}</TableCell>
                                  <TableCell align="left">
                                    <StyledTextField
                                      onChange={(event: any) =>
                                        this.handlePoProductsQuantityChange(event, prod, index)
                                      }
                                      placeholder="Add Quantity"
                                      inputProps={{
                                        style: newStyles.inputPropsStyle
                                      }}
                                      value={this.quantityHandleDefault(prod)}
                                      style={newStyles.rfpQtyTextFieldBoxSmall}
                                      required
                                      variant="outlined"
                                      InputProps={{ inputProps: { min: 1 } }}
                                    />

                                  </TableCell>
                                  <TableCell align="left">{this.unitPriceRender(prod?.attributes?.unit_price)}</TableCell>
                                  <TableCell align="left">{
                                    this.handlePoConditionalTextRendered(prod?.attributes?.theInputIsNonCatalog)
                                  }</TableCell>
                                  <TableCell align="left">
                                    <Chip
                                      style={{ width: '0px' }}
                                      onDelete={(e) => this.handlePoProductsDelete(e, prod, index)}
                                      size="medium"
                                      disabled={this.handleReadOnlyFields(this.state.isRfpCreationAndApiSuccessDone)}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box mt="1rem" width="30%" ml="1.5rem">
                        <Typography style={newStyles.modalLabel}>
                          Notes
                        </Typography>
                        <StyledTextField
                          value={this.state.poComment}
                          onChange={this.handlePoCommentChange}
                          inputProps={{
                            style: { width: "100%" }
                          }}
                          required
                          placeholder="Add Notes"
                          style={this.styledSelectStyle()}
                          variant="outlined"
                          type={"text"}
                        />
                        <Typography style={newStyles.validationText}>
                          {this.poValidationError(this.state.showIsCreatePurchaseOrderValidation, this.state.poComment, this.state.purchaseOrderCommentRequiredField)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <ToastMessageDisplay  handleClose={this.handlePoEmailToSupplierSendOrNotErrorMessageSnackbarClose} message={this.state.poEmailToSupplierSentOrNotErrorMessage}/>
                    </Box>
                    <Box>
                      <ToastMessageDisplay  handleClose={this.handlePoFinalPdfDownloadOrNotMessageSnackbarClose}   message={this.state.poFinalPdfDownloadOrNotMessage}/>
                    </Box>
                    <Box>
                      <ToastMessageDisplay handleClose={this.handleSupplierFoundNotSnackbarClose}     message={this.state.supplierFoundOrNotMessage} />
                    </Box>
                    <Box>
                      <ToastMessageDisplay       message={this.state.purchaseOrderDraftMessage}     handleClose={this.handlePurchaseOrderDraftSnackbarClose} />
                    </Box>
                    <Box>
                      <ToastMessageDisplay
                        message={this.state.poCreateMessage}
                        handleClose={this.handlePoCreateSnackbarClose}
                      />
                    </Box>
                    {
                      this.subhandleOpenPoMainPdfSupplierScreenModalFunc()
                    }
                  </Box>
                }
              </Box>
            </Dialog>

            <Dialog open={this.state.openCreatePoShowErrorModal} maxWidth="xl" style={newStyles.rfpErrorDialog}>
              <Box style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '15px',
                textAlign: 'center',
                padding: '20px'
              }}>
                <Typography style={{ color: 'red' }}>All fields are required **</Typography>
                <Typography>Please check Details and Add Items section again</Typography>
                <Box>
                  <Button style={newStyles.errorModalButton} onClick={this.handleCloseCreatePoErrorModal}>OK</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={this.state.isPoMailSendConfirmed}
              onClose={this.handlePoMailSendConfirmationCloseBar}
              aria-describedby="Po mail send confirmation"
            >
              <DialogTitle>{"Are you sure to send a mail?"}</DialogTitle>
              <DialogActions>
                <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handlePoRecordSupplierMailSend}>Yes</Button>
                <Button style={newStyles.rfpAlertBoxBtn} onClick={this.handlePoMailSendConfirmationCloseBar}>No</Button>
              </DialogActions>
            </Dialog>

            <Dialog maxWidth="xl" style={newStyles.emailScreenDialog} open={this.state.openPoSupplierMailSeccessModal}>
              <Box style={{
                padding: '20px',
                textAlign: 'center',
              }}>
                <Typography>An email with Purchase Order : {this.state.poNumberUnique}</Typography>
                <Typography>has been successfully sent to the supplier</Typography>
                <Box>
                  <Button onClick={this.handlePoSupplierMailSeccessCloseModal} style={newStyles.modalButton} variant="contained">Close</Button>
                </Box>
              </Box>
            </Dialog>

            <ViewDetailsDialog
              id={"show_product_dialog"}
              heading="Show Product"
              open={this.state.openShowProductModal}
              data={[
                {
                  displayName: "Product Name",
                  displayData: this.state.showProduct?.attributes?.name,
                },
                {
                  displayName: "Unit Measurement",
                  displayData: this.state.showProduct?.attributes
                    ?.unit_measurement,
                },
                {
                  displayName: "Unit Price",
                  displayData: this.state.showProduct?.attributes?.unit_price,
                },
                {
                  displayName: "Product Categories",
                  displayData: this.state.showProduct?.attributes
                    ?.product_category?.name,
                },
                {
                  displayName: "Suppliers",
                  displayData: this.state.showProduct?.attributes
                    ?.supplier?.data?.map((supplier: any)=>(supplier?.attributes?.supplier_name))?.join(", "),
                },
                {
                  displayName: "Type",
                  displayData: this.state.showProduct?.attributes?.item_type
                },
              ]}
              buttonsInfo={[
                {
                  variant: "contained",
                  styling: newStyles.modalCancelButton,
                  onClickHandle: this.handleShowProductCloseModal,
                  name: "Cancel",
                  disabled: false,
                },
              ]}
            />

            <Dialog maxWidth="xl" PaperProps={{ style: { width: "90%" } }} style={newStyles.dialog} open={this.state.openShowPurchaseOrderModal}>
              <Box style={newStyles.modalHeadingWrapper}>
                <Typography style={newStyles.modalHeading} variant="h5">
                  Purchase Order Number : {this.state.showPurchaseOrder?.attributes?.purchase_order_number}
                </Typography>
                <CloseOutlinedIcon style={newStyles.modalClose} onClick={this.handleShowPurchaseOrderCloseModal} />
              </Box>
              <Box style={newStyles.rfpTabsScreenWrapper}>
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    }
                  }}
                  style={{ display: 'inline-block', backgroundColor: 'lightgrey', padding: '5px', borderRadius: '15px' }}
                  value={this.state.rfpCreateScreenActiveTabs}
                  onChange={this.handlePoViewScreenTabsChange}>
                  <Tab style={this.handleTabsPoViewActiveStyleChange(0)}
                    label="Details" />
                  <Tab style={this.handleTabsPoViewActiveStyleChange(1)}
                    label="Items View" />
                </Tabs>
              </Box>
              <Grid container>
                {this.state.poViewScreenActiveTabs === 0 &&
                  <>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Order Date</Typography>
                        <StyledTextField
                          id={"po_shipping_address"}
                          disabled
                          value={this.state.showPurchaseOrder?.attributes?.order_date}
                          style={newStyles.textFieldBox}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: { paddingRight: '98px' },
                          }}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Delivery Date</Typography>
                        <StyledTextField
                          id={"po_shipping_address"}
                          disabled
                          variant="outlined"
                          value={this.state.showPurchaseOrder?.attributes?.delivery_date}
                          style={newStyles.textFieldBox}
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Department</Typography>
                        <StyledTextField
                          id={"po_shipping_address"}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          disabled
                          value={this.state.showPurchaseOrder?.attributes?.department?.name}
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          style={newStyles.textFieldBox}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Budget</Typography>
                        <StyledTextField
                          disabled
                          id={"po_shipping_address"}
                          style={newStyles.textFieldBox}
                          value={this.state.showPurchaseOrder?.attributes?.budget?.name}
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          variant="outlined"
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Location</Typography>
                        <StyledTextField
                          disabled
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          id={"po_shipping_address"}
                          variant="outlined"
                          value={this.state.showPurchaseOrder?.attributes?.location}
                          style={newStyles.textFieldBox}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Supplier</Typography>
                        <StyledTextField
                          variant="outlined"
                          style={newStyles.textFieldBox}
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          id={"po_shipping_address"}
                          value={this.state.showPurchaseOrder?.attributes?.supplier?.data?.attributes?.supplier_name}
                          disabled
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Shipping Address</Typography>
                        <StyledTextField
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          disabled={true}
                          value={this.state.showPurchaseOrder?.attributes?.shipping_address}
                          style={newStyles.textFieldBox}
                          id={"po_shipping_address"}
                          variant="outlined"
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Billing Address</Typography>
                        <StyledTextField
                          variant="outlined"
                          id={"po_shipping_address"}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          value={this.state.showPurchaseOrder?.attributes?.billing_address}
                          style={newStyles.textFieldBox}
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          disabled
                        />
                      </Box>
                    </Grid>

                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Payment Terms</Typography>
                        <StyledTextField
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          disabled
                          InputProps={{
                            style: { paddingRight: '98px' },
                            disableUnderline: true,
                          }}
                          multiple
                          value={this.state.showPurchaseOrder?.attributes?.supplier?.data?.attributes?.payment_terms?.data?.map((payment: any)=>{return payment?.attributes?.payment_term_name})}
                          style={newStyles.textFieldBox}
                          id={"po_shipping_address"}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={4}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>Taxation</Typography>
                        <StyledTextField
                          id={"po_shipping_address"}
                          value={this.state.showPurchaseOrder?.attributes?.taxation?.tax_name}
                          inputProps={{
                            style: newStyles.inputPropsStyle
                          }}
                          style={newStyles.textFieldBox}
                          InputProps={{
                            disableUnderline: true,
                            style: { paddingRight: '98px' },
                          }}
                          disabled={true}
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                  </>
                }
                {this.state.poViewScreenActiveTabs === 1 && <>
                  <Grid item md={12}>
                    <TableContainer style={newStyles.productsTable} component={Paper}>
                      <Table >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Product Id</TableCell>
                            <TableCell align="left">Product Name</TableCell>
                            <TableCell align="left">Quantity</TableCell>
                            <TableCell align="left">Price</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {this.state.showPurchaseOrder?.attributes?.product_purchase_order?.map((prod: any, index: number) => {
                            return (
                              <TableRow key={prod?.id}>
                                <TableCell align="left">{prod?.id}</TableCell>
                                <TableCell align="left">{prod?.name}</TableCell>
                                <TableCell align="left">
                                  {prod?.quantity}
                                </TableCell>
                                <TableCell align="left">{prod?.product_price}</TableCell>
                              </TableRow>
                            )
                          })}
                          {this.state.showPurchaseOrder?.attributes?.non_catalogue_items?.map((prod: any, index: number) => {
                          return (
                            <TableRow key={prod?.id}>
                              <TableCell align="left">{prod?.id}</TableCell>
                              <TableCell align="left">{prod?.name}</TableCell>
                              <TableCell align="left">
                                {prod?.quantity}
                              </TableCell>
                              <TableCell align="left">{prod?.product_price}</TableCell>
                            </TableRow>
                          )
                        })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item md={4}>
                    <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Comment</Typography>
                      <StyledTextField
                        style={newStyles.textFieldBox}
                        inputProps={{
                          style: newStyles.inputPropsStyle
                        }}
                        value={this.state.showPurchaseOrder?.attributes?.comment}
                        disabled
                        id={"po_shipping_address"}
                        InputProps={{
                          style: { paddingRight: '98px' },
                          disableUnderline: true,
                        }}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                </>}
              </Grid>
              <Box display={"flex"} justifyContent={"flex-end"}>
                {this.state.poViewScreenActiveTabs === 0 && <Button
                  data-testid={"dash_pr_create_next_btn"}
                  onClick={
                    this.handleOpenNextPurchaseModal.bind(this)
                  }
                  style={newStyles.modalButton}
                  variant="contained"
                >
                  Next
                </Button>}
                <Button
                  onClick={this.handleShowPurchaseOrderCloseModal}
                  style={newStyles.modalCancelButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            <Dialog
              maxWidth="xl"
              style={newStyles.dialog}
              open={this.state.openAddBudgetModal}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Create Budget
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Starting Date
                    </Typography>
                    <StyledTextField
                      inputProps={{
                        style: {
                          fontFamily: "Plus Jakarta Display",
                          fontWeight: 400,
                          fontSize: 16,
                        },
                      }}
                      type={"date"}
                      value={this.state.startingFromDate}
                      onChange={this.handleStartingFromDate}
                      required
                      style={newStyles.selectBoxLabel}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min:
                            new Date()
                              .getFullYear()
                              .toString()
                              .padStart(4, "0") +
                            "-" +
                            (new Date().getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            new Date()
                              .getDate()
                              .toString()
                              .padStart(2, "0"),
                        },
                      }}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.startingFromDate,
                        this.state.startingFromDateRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Name of Budget
                    </Typography>
                    <StyledTextField
                      variant="outlined"
                      inputProps={{
                        style: {
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                          fontSize: 16,
                        },
                      }}
                      required
                      value={this.state.budgetName}
                      onChange={this.handleBudgetName}
                      style={newStyles.textFieldBox}
                      placeholder="Enter Budget Name"
                    />
                    <Typography
                      style={newStyles.validationText}
                      data-testid={"show_budget_name_error_display"}
                    >
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.budgetName,
                        this.state.budgetNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Item Category
                    </Typography>
                    <StyledSelect
                      value={this.state.budgetProductCategories}
                      style={this.createBudgetsStyleSelectBoxLableRendered(
                        this.state.budgetProductCategories
                      )}
                      onChange={this.handleBudgetProductCategories}
                      variant="outlined"
                      label="Select Product"
                      displayEmpty
                      disableUnderline
                      input={<Input />}
                    >
                      <MenuItem value="" disabled>
                        Select Item Category
                      </MenuItem>
                      {this.state.productCategoriesList?.map((product: any) => {
                        return (
                          <MenuItem key={product?.id} value={product?.id}>
                            {product?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.budgetProductCategories,
                        this.state.budgetProductCategoriesRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Budget Period
                    </Typography>

                    <StyledSelect
                      style={this.createBudgetsStyleSelectBoxLableRendered(
                        this.state.budgetPeriod
                      )}
                      displayEmpty
                      input={<Input />}
                      onChange={this.handleBudgetPeriod}
                      variant="outlined"
                      disableUnderline
                      value={this.state.budgetPeriod}
                    >
                      <MenuItem value="" disabled>
                        Select Budget Period
                      </MenuItem>
                      <MenuItem value="Annually">Annually</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </StyledSelect>
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.budgetPeriod,
                        this.state.budgetPeriodRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Amount
                    </Typography>
                    <StyledTextField
                      required
                      placeholder="Enter Budget Amount"
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontFamily: "Plus Jakarta Display",
                          fontWeight: 400,
                        },
                      }}
                      value={this.state.budgetAmount}
                      onChange={this.handleBudgetAmount}
                      style={newStyles.textFieldBox}
                      variant="outlined"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.budgetAmount,
                        this.state.budgetAmountRequiredField
                      )}
                    </Typography>
                    <Typography
                      data-testid={"show_budget_amount_invalid"}
                      style={newStyles.validationText}
                    >
                      {this.createBudgetAmountMatchErrorRendered()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Comment
                    </Typography>
                    <StyledTextField
                      onChange={this.handleBudgetComment}
                      required
                      value={this.state.budgetComment}
                      inputProps={{
                        style: {
                          fontFamily: "Plus Jakarta Display",
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                      style={newStyles.textFieldBox}
                      variant="outlined"
                      placeholder="Enter Comment"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsCreateBudgetValidation,
                        this.state.budgetComment,
                        this.state.budgetCommentRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                      <Typography style={newStyles.modalLabel}>Budget For</Typography>
                      <StyledSelect
                          id="budgetForTC"
                          variant="outlined"
                          label="Select Budget For"
                          value={this.state.budgetFor}
                          onChange={this.handleBudgetFor}
                          displayEmpty
                          disableUnderline
                          style={this.createBudgetsStyleSelectBoxLableRendered(
                            this.state.budgetFor
                          )}
                          input={<Input />}
                      >
                          <MenuItem value="" disabled>Select Budget For</MenuItem>
                          {
                            this.handleBudgetForFunc()
                          }
                      </StyledSelect>
                  </Box>
                  <Typography style={newStyles.validationText}>{
                    this.createBudgetsInputFieldsValidationErrorRendered(
                      this.state.showIsCreateBudgetValidation,
                      this.state.budgetFor,
                      this.state.budgetForRequiredField)
                    }</Typography>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent={"flex-end"} width={"100%"}>
                <Box display={"flex"}>
                  <Button
                    variant="contained"
                    style={newStyles.modalButton}
                    onClick={this.handleCreateBudget}
                  >
                    Add
                  </Button>
                  <Button
                    style={newStyles.modalCancelButton}
                    variant="contained"
                    onClick={this.handleAddBudgetCloseModal}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <Dialog
              maxWidth="xl"
              open={this.state.openEditBudgetModal}
              style={newStyles.dialog}
            >
              <Typography style={newStyles.modalHeading} variant="h5">
                Update Budget
              </Typography>
              <Grid container>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Starting Date
                    </Typography>
                    <StyledTextField
                      required
                      onFocus={this.handleEditDurationDateTypeFocus}
                      value={this.state.editedBudgetStartingDate}
                      onChange={this.handleEditBudgetStartingDate}
                      onBlur={this.handleEditDurationDateTypeBlur}
                      inputProps={{
                        style: {
                          fontFamily: "Plus Jakarta Display",
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                      type={this.updateBudgetsDateTypeDisplay(
                        this.state.editedDurationDateType
                      )}
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          min:
                            new Date()
                              .getFullYear()
                              .toString()
                              .padStart(4, "0") +
                            "-" +
                            (new Date().getMonth() + 1)
                              .toString()
                              .padStart(2, "0") +
                            "-" +
                            new Date()
                              .getDate()
                              .toString()
                              .padStart(2, "0"),
                        },
                      }}
                      style={newStyles.selectBoxLabel}
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsEditPurchaseRequisitionValidation,
                        this.state.editedDurationDate,
                        this.state.editedDurationDateRequiredError
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Name of Budget
                    </Typography>
                    <StyledTextField
                      value={this.state.editedBudgetName}
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontFamily: "Plus Jakarta Display",
                          fontWeight: 400,
                        },
                      }}
                      required
                      onChange={this.handleEditBudgetName}
                      style={newStyles.textFieldBox}
                      placeholder="Enter Budget Name"
                      variant="outlined"
                    />
                    <Typography
                      data-testid={"edit_budget_name_error_display"}
                      style={newStyles.validationText}
                    >
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsEditBudgetValidation,
                        this.state.editedBudgetName,
                        this.state.editedBudgetNameRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Budget Period
                    </Typography>
                    <StyledSelect
                      style={this.createBudgetsStyleSelectBoxLableRendered(
                        this.state.editedBudgetPeriod
                      )}
                      value={this.state.editedBudgetPeriod}
                      variant="outlined"
                      disableUnderline
                      displayEmpty
                      onChange={this.handleEditBudgetPeriod}
                      input={<Input />}
                    >
                      <MenuItem value="Annually">Annually</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </StyledSelect>
                    <Typography
                      data-testid={"edit_budget_period_error_display"}
                      style={newStyles.validationText}
                    >
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsEditBudgetValidation,
                        this.state.editedBudgetPeriod,
                        this.state.editedBudgetPeriodRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Budget Amount
                    </Typography>
                    <StyledTextField
                      required
                      inputProps={{
                        style: {
                          fontSize: 16,
                          fontWeight: 400,
                          fontFamily: "Plus Jakarta Display",
                        },
                      }}
                      value={this.state.editedBudgetAmount}
                      onChange={this.handleEditBudgetAmount}
                      style={newStyles.textFieldBox}
                      variant="outlined"
                      placeholder="Enter Budget Amount"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsEditBudgetValidation,
                        this.state.editedBudgetAmount,
                        this.state.editedBudgetAmount
                      )}
                    </Typography>
                    <Typography
                      data-testid={"edit_budget_amount_invalid_display"}
                      style={newStyles.validationText}
                    >
                      {this.editedBudgetAmountMatchInvalidErrorRendered()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Comment
                    </Typography>
                    <StyledTextField
                      required
                      style={newStyles.textFieldBox}
                      value={this.state.editedBudgetComment}
                      inputProps={{
                        style: {
                          fontFamily: "Plus Jakarta Display",
                          fontSize: 16,
                          fontWeight: 400,
                        },
                      }}
                      onChange={this.handleEditBudgetComment}
                      variant="outlined"
                      placeholder="Enter Comment"
                    />
                    <Typography style={newStyles.validationText}>
                      {this.createBudgetsInputFieldsValidationErrorRendered(
                        this.state.showIsEditBudgetValidation,
                        this.state.editedBudgetComment,
                        this.state.editedBudgetAmountRequiredField
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box style={newStyles.gridItem}>
                    <Typography style={newStyles.modalLabel}>
                      Product Category
                    </Typography>
                    <StyledSelect
                      disableUnderline={true}
                      displayEmpty={true}
                      variant="outlined"
                      input={<Input />}
                      value={this.state.editedBudgetProductCategories}
                      onChange={this.handleEditedBudgetProductCategories}
                      style={this.createBudgetsStyleSelectBoxLableRendered(
                        this.state.editedBudgetProductCategories
                      )}
                      label="Select Product"
                    >
                      <MenuItem value="" disabled>
                        Select Product
                      </MenuItem>
                      {this.state.productCategoriesList?.map((product: any) => {
                        return (
                          <MenuItem key={product?.id} value={product?.id}>
                            {product?.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                    <Typography
                      data-testid={"edit_budget_product_category_error"}
                      style={newStyles.validationText}
                    >
                      {this.editedBudgetsProductInputValidationErrorRendered()}
                    </Typography>
                  </Box>
                </Grid>
                {this.state.budgetForList?.map((budget: any, index: number) => {
                  return (
                    <Grid item lg={2} key={budget?.id}>
                      <Box style={newStyles.gridItem}>
                        <Typography style={newStyles.modalLabel}>
                          {this.editedBudgetsForIndexRendered(index)}
                        </Typography>
                        <StyledTextField
                          style={newStyles.textFieldBoxSmall}
                          value={budget.name}
                          onClick={() => this.handleEditedBudgetFor(budget.id)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {this.editedBudgetsForCheckdeUncheckedBtnRendered(
                                  budget
                                )}
                              </InputAdornment>
                            ),
                          }}
                          className={"handle_edited_budgetforlist_name"}
                          inputProps={{
                            style: newStyles.inputPropsStyle,
                            disabled: true,
                            readOnly: true,
                          }}
                          variant="outlined"
                          required
                        />
                        <Typography style={newStyles.validationText}>
                          {this.createBudgetsInputFieldsValidationErrorRendered(
                            this.state.showIsEditBudgetValidation,
                            this.state.editedBudgetForSelectedId,
                            this.state.editedBudgetForRequiredField
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Box>
                <Button
                  style={newStyles.modalButton}
                  onClick={this.handleEditBudget}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={this.handleEditBudgetCloseModal}
                  variant="contained"
                  style={newStyles.modalCancelButton}
                >
                  Cancel
                </Button>
              </Box>
            </Dialog>

            <ViewDetailsDialog
              heading="Show Budget"
              id={"show_budget_dialog"}
              buttonsInfo={[
                {
                  name: "Cancel",
                  disabled: false,
                  variant: "contained",
                  onClickHandle: this.handleShowBudgetCloseModal,
                  styling: newStyles.modalCancelButton,
                },
              ]}
              data={[
                {
                  displayName: "Starting Date",
                  displayData: this.state.showBudget?.attributes?.starting_from,
                },
                {
                  displayName: "Name of Budget",
                  displayData: this.state.showBudget?.attributes?.name,
                },
                {
                  displayName: "Item Category",
                  displayData: this.state.showBudget?.attributes
                    ?.product_category_id,
                },
                {
                  displayName: "Budget Period",
                  displayData: this.state.showBudget?.attributes?.period,
                },
                {
                  displayName: "Amount",
                  displayData: this.state.showBudget?.attributes?.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                },
                {
                  displayName: "Comment",
                  displayData: this.state.showBudget?.attributes?.comment,
                },
              ]}
              open={this.state.openShowBudgetModal}
            />
          </Box>
        </Container>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    button: {
      textTransform: "none",
    },
  },
});

const newStyles = {
  inputPropsStyle: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Plus Jakarta Display'
  },
  createRfpShowError: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    rowGap: '15px',
    textAlign: 'center' as 'center',
    padding: '20px'
  },
  rfpSupplierListStyle: {
    overflowY: "auto" as 'auto',
    overflowX: "hidden" as 'hidden',
    width: "17.8rem",
    marginLeft: '1rem',
    position: 'relative' as 'relative',
    bottom: '7rem',
    left: '38rem',
  },
  mainContainer: {
    backgroundColor: "rgba(242, 242, 236, 1)",
    width: "100%",
    flex: "1",
    alignItems: "flex-start",
    boxSizing: "border-box" as "border-box",
  },
  menuListContainer: {
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    height: "86px",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginTop: "35px",
    display: "flex",
    placeItems: "center",
    boxSizing: "border-box" as "border-box",
  },
  searchItemsListContainer: {
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    height: "60px",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginTop: "10px",
    display: "flex",
    alignItems: 'center', 
    justifyContent: 'end',
    paddingRight : '20px',
    boxSizing: "border-box" as "border-box",
  },
  searchBoxWrapper : {
    backgroundColor: '#e0e0e0',
    color: 'rgba(28, 28, 26, 1)',
    height: 20,
    padding: '10px',
    marginRight: '15px',
    lineHeight: '15px',
    borderRadius: 25,
  },
  searchPaperStyle : {height : "40px", display: 'flex', alignItems: "center"},
  menuListItems: {
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    lineSpacing: "19",
    letterSpacing: "-0.33",
    marginRight: "10px",
    justifyContent: "space-evenly",
  },
  createPOButton: {
    justifyContent: "flexEnd",
    position: "relative" as "relative",
    width: "250px",
    height: "50px",
    marginLeft: "-50px",
    marginRight: "0px",
    marginTop: "25px",
    float: "right" as "right",
    marginBottom: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    padding: "0",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
    justifySelf: "flexEnd",
    alignContent: "flexEnd",
  },
  createFormButton: {
    justifyContent: "flexEnd",
    position: "relative" as "relative",
    width: "250px",
    height: "50px",
    marginLeft: "-50px",
    marginRight: "0px",
    marginTop: "25px",
    float: "right" as "right",
    marginBottom: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    padding: "0",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
    justifySelf: "flexEnd",
    alignContent: "flexEnd",
  },

  dropDownItems: {
    lineSpacing: "19",
    letterSpacing: "-0.33",
    fontSize: "16",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400,
  },

  dropDown: {
    minWidth: "328px",
    maxHeight: "56px",
    marginLeft: "20px",
    marginTop: "15px",
    backgroundColor: "#F2F2EC",
    fontSize: "16",
    margin: "dense",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400,
  },
  tableContainer: {
    position: "relative" as "relative",
    backgroundColor: "#FFFFFF",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginBottom: "50px",
    marginTop: "25px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxSizing: "border-box" as "border-box",
  },
  divider: {
    width: "1px",
    height: "30px",
    marginLeft: "70px",
    marginTop: "28px",
  },

  tableHeaderRows: {
    lineSpacing: "17",
    letterSpacing: "-0.29",
    fontSize: "14",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400,
  },
  tableContainerSkeleton: {
    position: "relative" as "relative",
    backgroundColor: "grey.900",
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginBottom: "50px",
    marginTop: "25px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxSizing: "border-box" as "border-box",
  },

  icons: {
    fontSize: "medium",
    paddingRight: "10px",
    cursor: "pointer",
  },
  tableBodyRows: {
    lineSpacing: "19",
    letterSpacing: "0",
    fontSize: "16",
    fontFamily: "Plus Jakarta Display",
    fontWeight: 400,
  },

  button: {
    position: "relative" as "relative",
    width: "328px",
    height: "56px",
    marginLeft: "81vw",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "0",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  iconColumn: {
    whiteSpace: "nowrap" as "nowrap",
  },

  createButton: {
    position: "relative" as "relative",
    width: "160px",
    height: "56px",
    marginLeft: "auto",
    marginRight: "2%",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    color: "rgba(28, 28, 26, 1)",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
  },
  createButton1: {
    marginLeft: "10px",
    position: "relative" as "relative",
    width: "289px",
    height: "56px",
    marginRight: "2%",
    marginBottom: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    color: "rgba(28, 28, 26, 1)",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },

  createButtonIcon: {
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    boxSizing: "border-box" as "border-box",
  },
  createButtonSkeleton: {
    marginLeft: "auto",
    position: "relative" as "relative",
    width: "289px",
    height: "56px",
    marginRight: "2%",
    marginBottom: "20px",
    opacity: "1",
    backgroundColor: "grey.900",
    borderRadius: "10px",
    boxSizing: "border-box" as "border-box",
    color: "#FFFFFF",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },

  createLongTextButtonIcon: {
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
    marginRight: "20px",
    boxSizing: "border-box" as "border-box",
  },
  fileAttachmentButtonIcon: {
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
    color: "#FD6533",
    textAlign: "center" as "center",
    fontWeight: 600,
    boxSizing: "border-box" as "border-box",
  },

  modalPdfSuccessContainer: {
    padding: "8px",
  },
  modalBtnContainer: {
    padding: "20px",
  },

  modalButtonPO: {
    position: "relative" as "relative",
    width: "230px",
    height: "56px",
    margin: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "8px",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  modalButton: {
    position: "relative" as "relative",
    width: "180px",
    height: "56px",
    margin: "20px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "8px",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },

  modalCancelButton: {
    position: "relative" as "relative",
    width: "160px",
    height: "56px",
    margin: "20px",
    opacity: "1",
    backgroundColor: "#FAEBE6",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "0",
    color: "#FD6533",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  errorModalButton: {
    position: "relative" as "relative",
    width: "100px",
    height: "45px",
    margin: "auto 0px",
    marginTop: "15px",
    marginBottom: "15px",
    opacity: "1",
    backgroundColor: "rgba(255, 255, 0, 1)",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "0",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },

  buttonText: {
    position: "absolute",
    width: "104",
    height: "16",
    marginLeft: "16",
    marginTop: "14",
    opacity: "1",
    backgroundColor: "transparent",
    fontStyle: "normal",
    fontWeight: "normal",
    includeFontPadding: false,
    padding: "0",
    color: "rgba(28, 28, 26, 1)",
    textAlign: "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
    boxSizing: "border-box" as "border-box",
  },
  attachmentButton: {
    width: "210px",
    height: "56px",
    opacity: "1",
    backgroundColor: "#FAEBE6",
    borderRadius: "10px",
    justifyContent: "center",
    boxSizing: "border-box" as "border-box",
    includeFontPadding: false,
    padding: "0",
    color: "#FD6533",
    textAlign: "center" as "center",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18",
  },
  dialog: {
    margin: "0 auto",
    boxSizing: "border-box" as "border-box",
  },

  errorBox: {
    padding: "0 30px 0 30px",
    marginTop: "30px",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
  },
  prDialog: {
    margin: "0 auto",
    boxSizing: "border-box" as "border-box",
    height: "100%",
    width: "100%",
  },

  textFieldBoxSmall: {
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
    width: "180px",
    minHeight: "60px",
    borderRadius: "10px",
    position: "relative" as "relative",
    marginBottom: "10px",
    opacity: "1",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },
  textFieldBox: {
    backgroundColor: "#FFFFFF",
    width: "100%",
    minHeight: "60px",
    borderRadius: "10px",
    position: "relative" as "relative",
    marginBottom: "10px",
    opacity: "1",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },

  modalHeading: {
    fontFamily: "Plus Jakarta Display",
    fontSize: "24",
    fontWeight: 600,
    padding: "20px",
    marginBottom: "20px",
    boxSizing: "border-box" as "border-box",
  },
  DateBox: {
    marginTop: "15px",
    backgroundColor: "#FFFFFF",
    width: "400px",
    minHeight: "60px",
    borderRadius: "10px",
    paddingBottom: "10px",
    margin: "0 auto",
    position: "relative" as "relative",
    opacity: "1",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },

  gridItemLabel: {
    marginBottom: "10px",
    fontFamily: "Plus Jakarta Display",
  },
  gridItem: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },

  textFieldEndAdornment: {
    color: "#B1B1AE",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 300,
  },
  selectBox: {
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    border: "#B1B1AE 0.1px solid",
    marginBottom: "10px",
    color: "Black",
    boxSizing: "border-box" as "border-box",
  },

  modalLabel: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },
  selectBoxLabel: {
    color: 'Black',
    opacity: '1',
    width: "100%",
    minHeight: "56px",
    borderRadius: "10px",
    border: "#B1B1AE 0.1px solid",
    marginBottom: "10px",
    boxSizing: "border-box" as "border-box",
    fontFamily: "Plus Jakarta Display",
    fontSize: "16",
    fontWeight: 350,
  },

  termsHeadingBottom: {
    height: "22px",
    color: "#1c1c1a",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.375px",
    marginTop: "5px",
  },
  validationText: {
    textAlign: "right" as "right",
    position: "relative" as "relative",
    width: "325px",
    margin: "0 auto",
    opacity: "1",
    backgroundColor: "transparent",
    padding: "0px",
    color: "rgba(28, 28, 26, 1)",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12",
    fontStyle: "italic",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },

  termsBodyTop: {
    height: "17px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    letterSpacing: "-0.29166666px",
    marginTop: "15px",
    marginBottom: "20px",
  },
  termsHeadingTop: {
    height: "22px",
    color: "#1c1c1a",
    fontFamily: "Plus Jakarta Display",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "-0.375px",
  },
  termsBodyBottom: {
    height: "17px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17px",
    letterSpacing: "-0.29166666px",
    marginTop: "15px",
    marginBottom: "15px",
  },
  productText: {
    marginTop: "30px",
    marginLeft: "25px",
    width: "82px",
    height: "24px",
    color: "#1c1c1a",
    fontFamily: "Plus Jakarta Display",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    letterSpacing: "-0.41666666px",
  },


  // rfp styles


  tabsDefaultStyle: {
    backgroundColor: 'lightgrey',
  },
  tabsActiveChange: {
    backgroundColor: 'rgba(255, 255, 0, 1)',
    color: 'black',
    fontWeight: 600,
    borderRadius: '15px',
  },


  rfpScreensContainer: {
    position: 'relative' as 'relative',
    height: '500px',
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpMainCreateButton: {
    position: 'relative' as 'relative',
    maxWidth: '20%',
    height: '56px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '10px',
    color: 'rgba(28, 28, 26, 1)',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    padding: '2px',
    boxSizing: 'border-box' as 'border-box',
  },

  rfpModalHeading: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '24',
    fontWeight: 600,
    padding: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  modalHeadingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  modalClose: {
    padding: '20px',
    cursor: 'pointer',
  },

  fileAttachLable: {
    marginTop: '8px',
    color: '#808080',
    fontFamily: "Plus Jakarta Display",
    fontSize: '12px',
    fontWeight: 400,
  },
  rfpTabsScreenWrapper: {
    padding: '20px',
    marginBottom: '25px',
  },


  productsTable: {
    width: '96%',
    boxShadow: "0px 0px 0px 0px rgba(0,0,0,0.2)",
    border: '1px solid lightgrey',
    borderRadius: '15px',
    margin: '20px',
    boxSizing: 'border-box' as 'border-box',
  },
  productsValidationTxt: {
    maxWidth: '328px',
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    padding: '6px 0px',
    color: 'rgba(255, 0, 0, 1)',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },


  rfpScreenBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpQtyTextFieldBoxSmall: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    width: '200px',
    minHeight: '40px',
    borderRadius: '10px',
    position: 'relative' as 'relative',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },

  emailScreenDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpAlertBoxBtn: {
    backgroundColor: 'rgba(255, 255, 0, 1)',
    borderRadius: '8px',
    justifyContent: 'center',
    padding: '2px',
  },

  rfpInpFieldsValidationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    padding: '3px 0px',
    color: 'rgba(255, 0, 0, 1)',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  rfpNumValidationText: {
    position: "relative" as "relative",
    width: "325px",
    opacity: "1",
    backgroundColor: "transparent",
    padding: "0 0 0 20px",
    color: "rgba(255, 0, 0, 1)",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12",
    fontStyle: "italic",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },

  rfpItemsSelectBox: {
    color: '#B1B1AE',
    maxWidth: '328px',
    maxHeight: '56px',
    marginBottom: '10px',
    border: '0.005px solid #B1B1AE',
    borderRadius: '10px',
    borderBottom: '0.005px solid #B1B1AE',
    boxSizing: 'border-box' as 'border-box',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 350,
  },
  rfpErrorDialog: {
    margin: '0 auto',
    borderRadius: '15px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  rfpItemsSelectBoxElse: {
    color: '#B1B1AE',
    maxWidth: '328px',
    maxHeight: '56px',
    marginBottom: '10px',
    border: '#B1B1AE 0.005px solid',
    borderRadius: '10px',
    boxSizing: 'border-box' as 'border-box',
  },
  rfpLoadersStyle: {
    //height: '300px',
    position: "relative" as "relative",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    //height: '443px',
    width: "96vw",
    borderRadius: "15px",
    margin: "auto",
    marginBottom: "50px",
    marginTop: "25px",
    paddingTop: "50px",
    paddingBottom: "50px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxSizing: "border-box" as "border-box",
  },


  receiptGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textAlign: 'right',
    paddingRight: '20px',
    boxSizing: 'border-box' as 'border-box'
  },

  receiptsScreenBtnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    boxSizing: 'border-box' as 'border-box',
  },

  receiptsInpFieldsValidationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    padding: '3px 0px',
    color: 'rgba(255, 0, 0, 1)',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12',
    fontStyle: 'italic',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  receiptsNumValidationText: {
    position: "relative" as "relative",
    width: "325px",
    opacity: "1",
    backgroundColor: "transparent",
    padding: "0 0 0 20px",
    color: "rgba(255, 0, 0, 1)",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12",
    fontStyle: "italic",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },
  rfpPdfLabel: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "#808080",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },
  receiptShowDialog: {
    margin: '0 auto',
    width: '100%',
    height: '500px',
    boxSizing: 'border-box' as 'border-box',
  },


  productsTypevalidationText: {
    textAlign: "center" as const,
    position: "relative" as "relative",
    width: "200px",
    margin: "0 auto",
    opacity: "1",
    backgroundColor: "transparent",
    padding: "0px",
    color: "rgba(28, 28, 26, 1)",
    textAlignVertical: "top",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12",
    fontStyle: "italic",
    fontWeight: 400,
    boxSizing: "border-box" as "border-box",
  },

  rfpPdfLabelHighlight: {
    position: "relative" as "relative",
    bottom: "10px",
    height: "15px",
    color: "black",
    fontFamily: "Plus Jakarta Display",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
  },





};
// Customizable Area End
