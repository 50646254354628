import React from "react";

// Customizable Area Start
import { 
  Container, 
  Box, 
  TextField,
  Input,  
  Button,
  InputLabel,
  Typography, 
  InputAdornment, 
  IconButton,
  Select,
  MenuItem,
  FormControl,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  OutlinedInput
} from '@material-ui/core';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import LockIcon from '@material-ui/icons/Lock'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PeopleIcon from '@material-ui/icons/People';
//import MonitorIcon from '@material-ui/icons/Monitor';
import AdjustIcon from '@material-ui/icons/Adjust';
import AirplayIcon from '@material-ui/icons/Airplay';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import { imgLogo } from "../../email-account-registration/src/assets";

const StyledSelect = withStyles({
  root: {
      //color: '#B1B1AE',
      paddingLeft: 15,
      '&:focus':{
          backgroundColor: 'transparent',
      }
  },
})((props: any) => <Select {...props} />);

const StyledTextField = withStyles({
  root: {
    "& .MuiFilledInput-root": {
      backgroundColor: "transparent",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "transparent",
      "@media (hover: none)": {
        backgroundColor: "transparent"
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "transparent"
    },
    "& input::placeholder": {
      fontFamily: 'Plus Jakarta Display',
      fontSize: '14',
      fontWeight: 400,
    }
  }
})((props: any) => <TextField {...props} />);
// Customizable Area End

import EmailAccountRegistrationController, {
    Props, 
    configJSON
  } from "./EmailAccountRegistrationController";
import { colors } from "react-select/src/theme";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <Box style={newStyles.mainContainer}>
        <Box style={newStyles.imageContainer}>
          <img src={imgLogo} style={newStyles.image}/>
        </Box>
        <Box style={this.state.showSignUp? newStyles.contentContainer1 : (this.state.showSignUpNext? newStyles.contentContainer2 : newStyles.contentContainer3)}>
          {this.state.showSignUp && <Box>
            <StyledTextField
              required
              testID="txtInputName"
              InputLabelProps={{required: true, style : {color : 'black', fontSize: 'medium'}, shrink: true}} 
              placeholder={configJSON.placeHolderName}
              variant="filled" 
              value={this.state.name}
              onChange={this.handleNameChange}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                   fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment 
                                  style={this.state.name==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                                  position="start">
                                    <PersonIcon/>
                                </InputAdornment>,
              }}
            />
            <Typography 
              style={newStyles.validationText}>
              {this.state.name==="" && this.state.showSignUpValidation ? this.state.requiredNameFieldError : ""}
            </Typography>
            <StyledTextField
              required 
              testID="txtInputEmail"
              InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
              placeholder={configJSON.placeHolderEmail} 
              variant="filled" 
              value={this.state.email} 
              onChange={this.handleEmailChange}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment 
                                  style={this.state.email==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                                  position="start">
                                    <PersonIcon/>
                                </InputAdornment>,
              }}
            />
            <Typography 
              style={newStyles.validationText}>
              {this.state.email==="" && this.state.showSignUpValidation ? this.state.requiredEmailFieldError : ""}
            </Typography>
            <Typography 
              style={newStyles.validationText}>
              {this.state.email!=="" && !this.state.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) && "Enter valid Email"}
            </Typography>
            <StyledTextField
              required 
              testID="txtInputPhone"
              InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
              placeholder={configJSON.placeHolderPhone} 
              variant="filled" 
              value={this.state.phone} 
              onChange={this.handlePhoneChange}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment 
                                  style={this.state.phone==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                                  position="start">
                                    <PhoneIcon/>
                                </InputAdornment>,
              }}
            />
            <Typography 
              style={newStyles.validationText}>
              {this.state.phone==="" && this.state.showSignUpValidation ? this.state.requiredPhoneFieldError : ""}
            </Typography>
            <Typography 
              style={newStyles.validationText}>
              {this.state.phone!=="" && !this.state.phone.match(/^\d+$/) && "Enter valid phone number"}
            </Typography>
            <StyledTextField
              required
              testID="txtInputPassword"
              InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
              type={this.state.inputTypePassword==="password" ? "password" : "text"}
              placeholder={configJSON.placeHolderPassword} 
              value={this.state.password} 
              variant="filled" 
              onChange={this.handlePasswordChange}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment 
                                  style={this.state.password==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                                  position="start">
                                    <LockIcon/>
                                </InputAdornment>,
                endAdornment: <InputAdornment 
                                data-test-id="btnTogglePasswordIcon"
                                style={this.state.password==="" ? styles.textFieldSuffixIconInitially : styles.textFieldSuffixIcon}
                                position="end" 
                                onClick={this.handlePasswordVisibilityIcon}>
                                  {this.state.inputTypePassword==="password" ? <Visibility/> : <VisibilityOff/>}
                              </InputAdornment>
                
              }}
            />
            <Typography 
              style={newStyles.validationText}>
              {this.state.password.length<6 ? "Password must be of at least 6 characters long*" : ""}
            </Typography>
            <StyledTextField
              required
              testID="txtInputConfirmPassword"
              InputLabelProps={{style : {color : 'black', fontSize: 'medium'}}} 
              type={this.state.inputTypeConfirmPassword==="password" ? "password" : "text"}
              placeholder={configJSON.placeHolderConfirmPassword} 
              value={this.state.confirmPassword} 
              variant="filled" 
              onChange={this.handleConfirmPasswordChange}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment 
                                  style={this.state.confirmPassword==="" ? styles.textFieldIconInitially : styles.textFieldIcon} 
                                  position="start">
                                    <LockIcon/>
                                </InputAdornment>,
                endAdornment: <InputAdornment 
                                data-test-id="btnToggleConfirmPasswordIcon"
                                style={this.state.confirmPassword==="" ? styles.textFieldSuffixIconInitially : styles.textFieldSuffixIcon}
                                position="end" 
                                onClick={this.handleConfirmPasswordVisibilityIcon}>
                                  {this.state.inputTypeConfirmPassword==="password" ? <Visibility/> : <VisibilityOff/>}
                              </InputAdornment>
              }}
            />
            <Typography 
              style={newStyles.validationText}>
              {this.state.confirmPassword==="" && this.state.showSignUpValidation ? this.state.requiredConfirmPasswordFieldError : ""}
            </Typography>
            <Typography 
              style={newStyles.validationText}>
              {this.state.showSignUpValidation ? this.state.matchingConfirmPasswordFieldError : ""}
            </Typography>
            <Button data-test-id="btnSignUp" onClick={this.handleSignUp} style={newStyles.button} variant="contained">{configJSON.btnTextSignUp}</Button>
          </Box>}


          {this.state.showSignUpNext && <Box>
            <Box data-test-id="btnGoSignUp" onClick={this.handleGoBackToSignUp} style={newStyles.backWithArrow}>
                <ArrowBackIcon style={newStyles.backIcon}/>
                <Typography style={newStyles.backText}>Back</Typography> 
            </Box>
                  
            <StyledSelect
              testID="txtInputPosition"
              value={this.state.position} 
              onChange={this.handleChangePosition} 
              variant="filled"
              disableUnderline
              displayEmpty
              style={this.state.position==="" ? newStyles.selectBoxLabel : newStyles.selectBox}
              startAdornment={
                <InputAdornment 
                  style={styles.selectIcon}
                  position="start">
                  <PersonIcon/>
                </InputAdornment>
              }
              input={<Input/>}
              >
              <MenuItem value="" disabled>{configJSON.placeHolderPosition}</MenuItem>
              {this.state.allPositions?.map((option: any, index: number) => {
                return <MenuItem data-test-id="txtYourPosition" key={index} value={option.id}>{option.your_position}</MenuItem>
              })}
            </StyledSelect>
            <Typography
              style={newStyles.validationText}>
                {this.state.position==="" && this.state.profileValidation ? this.state.requiredPositionFieldError : ""}
            </Typography>
            
            <StyledSelect
              testID="txtInputOrganizationSize"
              value={this.state.organizationSize} 
              onChange={this.handleOrganizationSize}
              displayEmpty
              disableUnderline
              style={this.state.organizationSize==="" ? newStyles.selectBoxLabel : newStyles.selectBox}
              startAdornment={
                <InputAdornment 
                  style={styles.selectIcon}
                  position="start">
                  <PersonIcon/>
                </InputAdornment>
              }
              input={<Input/>}
              >
              <MenuItem value="" disabled>{configJSON.placeHolderOrganizationSize}</MenuItem>
              {this.state.allOrganizationSize?.map((option: any, index: number) => {
                return <MenuItem key={index} value={option.id}>{option.select_organization}</MenuItem>
              })}
            </StyledSelect>
            <Typography
              style={newStyles.validationText}>
                {this.state.organizationSize==="" && this.state.profileValidation ? this.state.requiredOrganizationSizeFieldError : ""}
            </Typography>
        
            <StyledTextField
              testID="txtInputNoOfPeopleAccess"
              placeholder={configJSON.placeHolderNoOfPeopleAccess} 
              variant="filled" 
              value={this.state.noOfPeopleAccess} 
              onChange={this.handleNoOfPeopleAccess}
              style={newStyles.textFields}
              inputProps={{style: {fontSize: 14,
                                    fontFamily: 'Plus Jakarta Display'
              }}}
              InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment style={this.state.noOfPeopleAccess==="" ? styles.textFieldIconInitially : styles.textFieldIcon} position="start"><PeopleIcon/></InputAdornment>,
              }}
            />
            <Typography
              style={newStyles.validationText}>
                {this.state.noOfPeopleAccess==="" && this.state.profileValidation ? this.state.requiredNoOfPeopleAccessFieldError : ""}
            </Typography>100
            <Typography
              style={newStyles.validationText}>
                {this.state.noOfPeopleAccess!=="" && !this.state.noOfPeopleAccess.match(/^\d+$/) && "Enter a number"}
            </Typography>

            <StyledSelect 
              testID="txtInputGoal"
              value={this.state.goal} 
              onChange={this.handleGoal}
              displayEmpty
              disableUnderline
              style={this.state.goal==="" ? newStyles.selectBoxLabel : newStyles.selectBox}
              startAdornment={
                <InputAdornment 
                  style={styles.selectIcon}
                  position="start">
                  <AdjustIcon/>
                </InputAdornment>
              }
              input={<Input/>} 
            >
              <MenuItem value="" disabled>{configJSON.placeHolderGoal}</MenuItem>
              {this.state.allGoals?.map((option: any, index: number) => {
                return <MenuItem key={index} value={option.id}>{option.sourceeasy_goal}</MenuItem>
              })}
            </StyledSelect>
            <Typography
              style={newStyles.validationText}>
                {this.state.goal==="" && this.state.profileValidation ? this.state.requiredGoalFieldError : ""}
            </Typography>
            
            <StyledSelect
              testID="txtInputERPSoftware"
              value={this.state.ERPSoftware} 
              onChange={this.handleERPSoftware}
              displayEmpty
              disableUnderline
              style={this.state.ERPSoftware==="" ? newStyles.selectBoxLabel : newStyles.selectBox}
              startAdornment={
                <InputAdornment 
                  style={styles.selectIcon}
                  position="start">
                  <AirplayIcon/>
                </InputAdornment>
              }
              input={<Input/>} 
            >
              <MenuItem value="" disabled>{configJSON.placeHolderERPSoftware}</MenuItem>
              {this.state.allErpSoftware?.map((option: any, index: number) => {
                return <MenuItem key={index} value={option.id}>{option.erp_software}</MenuItem>
              })}
            </StyledSelect>
            <Typography
              style={newStyles.validationText}>
                {this.state.ERPSoftware==="" && this.state.profileValidation ? this.state.requiredERPSoftwareFieldError : ""}
            </Typography>
            
            <Button data-test-id="btnViewProfile" onClick={this.handleViewProfile} style={newStyles.button} variant="contained">{configJSON.btnTextNext}</Button>
          </Box>}

          {this.state.showProfile && <Box>
            <Box data-test-id="btnGoBack" onClick={this.handleGoBack} style={newStyles.backWithArrow}>
                <ArrowBackIcon style={newStyles.backIcon}/>
                <Typography style={newStyles.backText}>Back</Typography> 
            </Box>
            <TableContainer>
              <Table style={newStyles.table}>
                <TableBody>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.emailOrPhone}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.emailOrPhone}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.positionInCompany}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.selectedPosition}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.organizationSize}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.selectedOrganizationSize}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.placeHolderNoOfPeopleAccess}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.noOfPeopleAccess}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.placeHolderGoal}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.selectedGoal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={newStyles.placeholderCell}>{configJSON.ERPSoftware}</TableCell>
                    <TableCell style={newStyles.dataCell}>{this.state.selectedErpSoftware}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Button data-test-id="btnSubmitProfile" onClick={this.handleSubmitProfile} style={newStyles.tableButton} variant="contained">{configJSON.btnTextSubmit}</Button>
          </Box>}

        </Box>
     </Box> 
         
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      // main: '#fff',
      // contrastText: '#fff',
      main: '#FFFF00',
    },
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131',
        },
      },
    },
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: '20px 0px',
    }
}});

const styles = {
  box: {
    //fontFamily: 'myFirstFont',
    backgroundColor: '#F2F2EC',
  },
  selectBoxLabel: {
    fontFamily: 'myFirstFont',
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC', 
    minHeight: '60px', 
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  selectBox: {
    backgroundColor: '#F2F2EC', 
    minHeight: '60px', 
    borderRadius: '10px',
    paddingLeft: '7px',
  },
  textFieldBox: {
    fontFamily: 'myFirstFont',
    backgroundColor: '#F2F2EC', 
    minHeight: '40px', 
    borderRadius: '10px',
    paddingBottom: '10px',
  },
  textFieldIconInitially: {
    fontSize: 'small', 
    paddingRight: '12px',
    //color: '#B1B1AE',
  },
  textFieldIcon: {
    fontSize: 'small', 
    paddingRight: '12px',
  },
  selectIcon: {
    fontSize: 'small', 
    //paddingRight: '12px',
    paddingLeft: '3px',
    color: 'black',
  },
  textFieldSuffixIconInitially: {
    fontSize: 'small',
    paddingTop: '12px',
    //color: '#B1B1AE',
  },
  textFieldSuffixIcon: {
    fontSize: 'small',
    paddingTop: '12px',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
      width: '103px',
      height: '16px',
      opacity: '1',
      margin: 'auto',
      backgroundColor: 'transparent',
      padding: '0px',
      color: 'rgba(28, 28, 26, 1)',
      textAlignVertical: 'top',
      //fontFamily: 'PlusJakartaDisplay-Italic',
      fontFamily: 'Plus Jakarta Display',
      fontSize: '12',
      boxSizing: 'border-box' as 'border-box',
  },
}

const newStyles = {
  imageContainer: {
    position: 'relative' as 'relative',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    //border: 'rgba(242, 242, 236, 1) 10px solid',
    border: '10px solid #f2f2ec',
    borderRadius: '15px',
    //borderWidth: '10px',
    zIndex: 1,
    boxSizing: 'border-box' as 'border-box',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'row' as 'row',
    overflow: 'hidden',
    margin: 'auto',
    height: '130px',
    marginTop: '10px',
    opacity: '1',
    width: '264px',
  },
  mainContainer: {
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(242, 242, 236, 1)',
    flex: '1',
    alignItems: "flex-start",
    boxSizing: 'border-box' as 'border-box',
    width: '100%',
    top: '25px',
    //width: '0 auto',
    height: 'auto',
  },
  contentContainer1: {
      position: 'relative' as 'relative',
      width: '700px',
      margin: '0 auto',
      //height: '510px',
      //marginLeft: '370px',
      //marginTop: '332px',
      marginTop: '-70px',
      marginBottom: '20px',
      opacity: '1',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: '10px',
      //borderWidth: '0px',
      zIndex: 0,
      boxSizing: 'border-box' as 'border-box',
      paddingTop: '90px',
      //paddingLeft: '190px',
      paddingBottom: '50px',
      display: 'flex',
      flex: '1',
      flexWrap: 'nowrap' as 'nowrap',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
  },
  contentContainer2: {
    position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    height: '650px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '10px',
    //borderWidth: '0px',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    //paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    justifyContent: 'center',
  },
  contentContainer3: {
    position: 'relative' as 'relative',
    width: '700px',
    margin: '0 auto',
    height: '600px',
    //marginLeft: '370px',
    //marginTop: '332px',
    marginTop: '-70px',
    marginBottom: '20px',
    opacity: '1',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: '15px',
    //borderWidth: '0px',
    zIndex: 0,
    boxSizing: 'border-box' as 'border-box',
    paddingTop: '90px',
    // paddingRight: '50px',
    // paddingLeft: '50px',
    //paddingLeft: '190px',
    display: 'flex',
    flex: '1',
    flexWrap: 'nowrap' as 'nowrap',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 42px 94px rgba(128,128,128,0.08)',
    justifyContent: 'center',
  },
  image: {
      position: 'relative' as 'relative',
      width: '176px',
      height: '54px',
      //marginLeft: '632px',
      //marginTop: '305px',
      marginLeft: '32px',
      marginTop: '30px',
      opacity: '1',
      resizeMode: 'contain',
      boxSizing: 'border-box' as 'border-box',
  },
  textFields: {
    backgroundColor: '#F2F2EC', 
    width: '328px',
    minHeight: '56px',
    borderRadius: '10px',
    paddingBottom: '10px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  selectBox: {
    backgroundColor: '#F2F2EC', 
    width: '324px',
    //minHeight: '56px', 
    minHeight: '60px',
    borderRadius: '10px',
    paddingLeft: '7px',
    paddingBottom: '10px',
    paddingTop: '15px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  selectBoxLabel: {
    color: '#B1B1AE',
    backgroundColor: '#F2F2EC', 
    //width: '324px',
    width: '328px',
    //minHeight: '56px',
    minHeight: '60px', 
    borderRadius: '10px',
    paddingLeft: '7px',
    paddingBottom: '10px',
    paddingTop: '15px',
    margin: '0 auto',
    position: 'relative' as 'relative',
    marginLeft: '0px',
    marginTop: '16px',
    opacity: '1',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  validationText: {
    textAlign: 'right' as 'right',
    position: 'relative' as 'relative',
    //width: '325px',
    width: '328px',
    //height: '16px',
    margin: '0 auto',
    marginLeft: '0px',
    opacity: '1',
    backgroundColor: 'transparent',
    padding: '0px',
    color: 'rgba(28, 28, 26, 1)',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Italic',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '12px',
    fontStyle: 'italic' as 'italic',
    fontWeight: 300,
    boxSizing: 'border-box' as 'border-box',
  },
  button: {
    backgroundColor: '#FFFF00', 
    width: '328px',
    height: '56px', 
    padding: '5px', 
    margin: 'auto',
    marginTop: '16px', 
    marginLeft: '0px',
    marginBottom: '20px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  tableButton: {
    backgroundColor: '#FFFF00', 
    width: '324px',
    height: '56px', 
    padding: '5px', 
    margin: 'auto',
    marginTop: '16px', 
    marginLeft: '60px',
    boxShadow: 'none',
    borderRadius: '10px',
    justifyContent: 'center',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    textTransform: 'none' as 'none',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',
  },
  buttonText: {
    position: 'absolute',
    width: '104',
    height: '16',
    marginLeft: '16',
    marginTop: '14',
    opacity: '1',
    backgroundColor: 'transparent',
    // fontStyle: 'normal',
    includeFontPadding: false,
    padding: '0',
    color: 'rgba(28, 28, 26, 1)',
    textAlign: 'center',
    textAlignVertical: 'top',
    //fontFamily: 'PlusJakartaDisplay-Medium',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',	
    fontWeight: 400,
    boxSizing: 'border-box' as 'border-box',	
  },
  table: {
    marginTop: '-40px',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '18',	
  },
  dataCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',	
    color: 'black',
    fontWeight: 'bolder' as 'bolder', 
    textAlign: 'right' as 'right',
  },
  placeholderCell: {
    fontFamily: 'Plus Jakarta Display',
    fontSize: '16',	
    color: '#ACACAC',
  },
  backWithArrow: {
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    alignItems: 'baseline',
  },
  arrow: {
    //float: 'right' as 'right',
    display: 'flex',
    fontFamily: 'Plus Jakarta Display',
    fontSize: '14',	
    fontWeight: 600,
  },
  backIcon: {
    width: '20px',
    height: '14px',
  },
  backText: {
    width: '32px',
    height: '17px',
    color: '#1c1c1a',
    fontFamily: "Plus Jakarta Display",
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
    letterSpacing: '-0.15px',
  },
}
// Customizable Area End
