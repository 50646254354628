import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  emailForForgotPasswordPatternError: string,
  requiredNewPasswordFieldError: string,
  requiredConfirmNewPasswordFieldError: string,
  showForgotPasswordValidation: boolean,
  confirmNewPasswordNotMatchError: string,
  newPasswordType: string,
  confirmNewPasswordType: string,
  labelOr: string;
  showLoginValidation: boolean;
  newPassword: string;
  confirmNewPassword: string,
  showSetNewPasswordScreen: boolean,
  emailForForgotPassword: string,
  requiredEmailForForgotPasswordFieldError: string,
  otp: string,
  showOtpScreen: boolean,
  otpEntered: string,
  showResetPasswordScreen: boolean,
  showForgotPasswordEmailScreen: boolean,
  showForgotPasswordEmailSubmitValidation: boolean,
  showOtpSubmitValidation: boolean,
  requiredOtpFieldError: string,
  showResetPasswordValidation: boolean,
  position: string,
  showProfile: boolean,
  ERPSoftware: string,
  requiredNameFieldError: string,
  requiredEmailFieldError: string,
  requiredPhoneFieldError: string,
  organizationSize: string,
  noOfPeopleAccess: string,
  goal: string,
  requiredPasswordFieldError: string,
  requiredConfirmPasswordFieldError: string,
  emailPatternError: string,
  phoneNumberPatternError: string,
  allOrganizationSize: any[],
  selectedPosition: string,
  selectedGoal: string,
  selectedErpSoftware: string,
  showSignUpValidation: boolean,
  matchingConfirmPasswordFieldError: string,
  allPositions: any[],
  allGoals: any[],
  allErpSoftware: any[],
  selectedOrganizationSize: string,
  requiredPositionFieldError: string,
  requiredGoalFieldError: string,
  requiredOrganizationSizeFieldError: string,
  validOrganizationSizeInputError: string,
  inputTypePassword: string,
  inputTypeConfirmPassword: string,
  emailAlreadyExistError: string,
  phoneNumberAlreadyExistError: string,
  requiredNoOfPeopleAccessFieldError: string,
  requiredERPSoftwareFieldError: string,
  profileValidation: boolean,
  name: string,
  phoneNumber: string,
  showLogin: boolean,
  showProfileCreation: boolean,
  showProfileDetails: boolean,
  phoneNumberFromLogin: string,
  emailFromLogin: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  emailLoginAPICallId: string = "";
  validationApiCallId: string = "";
  forgotPasswordAPICallId: string = "";
  otpSubmitAPICallId: string = "";
  resetPasswordAPICallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  submitProfileAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
  
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ]
    
    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      showLoginValidation: false,
      requiredEmailFieldError: "",
      requiredPasswordFieldError: "",
      emailPatternError: "",
      newPassword: "",
      confirmNewPassword: "",
      inputTypePassword: "password",
      showSetNewPasswordScreen: false,
      emailForForgotPassword: "",
      requiredEmailForForgotPasswordFieldError: '',
      emailForForgotPasswordPatternError: '',
      requiredNewPasswordFieldError: '',
      requiredConfirmNewPasswordFieldError: '',
      showForgotPasswordValidation: false,
      confirmNewPasswordNotMatchError: "",
      newPasswordType: 'password',
      confirmNewPasswordType: 'password',
      otp: '',
      showOtpScreen: false,
      otpEntered: '',
      showResetPasswordScreen: false,
      showForgotPasswordEmailScreen: true,
      showForgotPasswordEmailSubmitValidation: false,
      showOtpSubmitValidation: false,
      requiredOtpFieldError: '',
      showResetPasswordValidation: false,
      position: "",
      showProfile: false,
      organizationSize: "",
      noOfPeopleAccess: "",
      goal: "",
      ERPSoftware: "",
      requiredNameFieldError: "",
      requiredPhoneFieldError: "",
      requiredConfirmPasswordFieldError: "",
      phoneNumberPatternError: "",
      showSignUpValidation: false,
      matchingConfirmPasswordFieldError: "",
      allPositions: [],
      allGoals: [],
      allErpSoftware: [],
      allOrganizationSize: [],
      selectedPosition: "",
      selectedGoal: "",
      selectedErpSoftware: "",
      selectedOrganizationSize: "",
      requiredPositionFieldError: "",
      requiredGoalFieldError: "",
      requiredOrganizationSizeFieldError: "",
      validOrganizationSizeInputError: "",
      requiredNoOfPeopleAccessFieldError: "",
      requiredERPSoftwareFieldError: "",
      profileValidation: false,
      inputTypeConfirmPassword: "password",
      emailAlreadyExistError: "",
      phoneNumberAlreadyExistError: "",
      name: "",
      phoneNumber: "",
      showLogin: true,
      showProfileCreation: false,
      showProfileDetails: false,
      phoneNumberFromLogin: '',
      emailFromLogin: '',
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin()
  };

  btnEmailLogInProps = {
    color: "#6200EE",
    onPress: () => this.doEmailLogIn()
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword()
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    }
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    }
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handlePhoneOrEmailChange = (event: any): void => {
    this.setState({
      email: event.target.value
    })
  }

  handlePasswordChange = (event: any): void => {
    this.setState({
      password: event.target.value
    })
  }

  handleNewPasswordChange = (event: any): void => {
    this.setState({
      newPassword: event.target.value
    })
  }

  handleConfirmNewPasswordChange = (event: any): void => {
    this.setState({
      confirmNewPassword: event.target.value
    })
  }

  handleShowSetNewPasswordScreen = (): void => {
    this.setState({
      showSetNewPasswordScreen: true,
    })
  }

  handlePasswordVisibilityIcon = () => {
    if(this.state.inputTypePassword === "password"){
      this.setState({
        inputTypePassword: "text"
      })
    }
    if(this.state.inputTypePassword === "text"){
      this.setState({
        inputTypePassword: "password"
      })
    }
  }

  handleNewPasswordVisibilityIcon = () => {
    if(this.state.newPasswordType === "password"){
      this.setState({
        newPasswordType: "text"
      })
    }
    if(this.state.newPasswordType === "text"){
      this.setState({
        newPasswordType: "password"
      })
    }
  }

  handleConfirmNewPasswordVisibilityIcon = () => {
    if(this.state.confirmNewPasswordType === "password"){
      this.setState({
        confirmNewPasswordType: "text"
      })
    }
    if(this.state.confirmNewPasswordType === "text"){
      this.setState({
        confirmNewPasswordType: "password"
      })
    }
  }

  handlePhoneOrEmailForForgotPasswordChange = (event: any) => {
    this.setState({
      emailForForgotPassword: event.target?.value
    })
  }

  handleGotoLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  handleOtpChange = (event: any) => {
    this.setState({
      otpEntered: event.target?.value                
    })
  }

  handleEnterForgotPassword = () => {
    this.setState({
      showOtpScreen: false,
      showForgotPasswordEmailScreen: true,
    })
  }

  handleGotoEnterOtp = () => {
    this.setState({
      showOtpScreen: true,
      showResetPasswordScreen: false,
    })
  }

  handleChangePosition = (event: any) => {
    this.setState({
      position: event.target.value
    })
  }

  handleOrganizationSize = (event: any) => {
    this.setState({
      organizationSize: event.target.value
    })
  }

  handleNoOfPeopleAccess = (event: any) => {
    this.setState({
      noOfPeopleAccess: event.target.value
    })
  }

  handleGoal = (event: any) => {
    this.setState({
      goal: event.target.value
    })
  }

  handleERPSoftware = (event: any) => {
    this.setState({
      ERPSoftware: event.target.value
    })
  }

  handleGoBackToLogin = () => {
    this.setState({
      showProfileCreation: false,
      showLogin: true,
    })
  }

  handleGoBackToProfileCreation = () => {
    this.setState({
      showProfileDetails: false,
      showProfileCreation: true,
    })
  }

  isResetPasswordValidCheck = () => {
    let flag = true;
    // const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/;
    // if(this.state.emailForForgotPassword === ""){
    //   this.setState({
    //     requiredEmailForForgotPasswordFieldError: "Required Field *"
    //   })
    //   flag=false;
    // }
    // if(!this.state.emailForForgotPassword.match(validEmailRegex)){
    //   this.setState({
    //     emailForForgotPasswordPatternError: "Enter valid email"
    //   })
    //   flag=false;
    // }
    if(this.state.newPassword === ""){
      this.setState({
        requiredNewPasswordFieldError: "Required Field *"
      })
      flag=false;
    }
    if(this.state.confirmNewPassword === ""){
      this.setState({
        requiredConfirmNewPasswordFieldError: "Required Field *"
      })
      flag=false;
    }
    if(this.state.confirmNewPassword !== this.state.newPassword){
      this.setState({
        confirmNewPasswordNotMatchError: "Password did not match"
      })
      flag=false;
    }
    if(this.state.newPassword.length<6){
      flag=false;
    }
    return flag;
  }

  handleResetPassword = () => {
    if(!this.isResetPasswordValidCheck()){
      this.setState({
        showResetPasswordValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken')
    let headers = { 
      'Content-Type': 'application/json' 
    }
    
    let httpBody = {
      
      data: {
        attributes: {
          new_password: this.state.newPassword,
          password_confirmation: this.state.confirmNewPassword,
          token: token_localStorage
        }
      }
    };

    const resetPasswordApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resetPasswordAPICallId = resetPasswordApiMsg.messageId;

    resetPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/passwords"
    );

    resetPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    resetPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    resetPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(resetPasswordApiMsg.id, resetPasswordApiMsg); 
  }

  isForgotPasswordEmailSubmitValidCheck = () => {
    let flag=true;
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/;
    if(this.state.emailForForgotPassword === ""){
      this.setState({
        requiredEmailForForgotPasswordFieldError: "Required Field *"
      })
      flag=false;
    }
    if(!this.state.emailForForgotPassword?.match(validEmailRegex)){
      this.setState({
        emailForForgotPasswordPatternError: "Enter valid email"
      })
      flag=false;
    }
    return flag
  }

  handleForgotPasswordEmailSubmit = () => {
    if(!this.isForgotPasswordEmailSubmitValidCheck()){
      this.setState({
        showForgotPasswordEmailSubmitValidation: true
      })
      return
    }
    let headers = { 
      'Content-Type': 'application/json' 
    }

    
    let httpBody = {
      data: {
        attributes: {
          email: this.state.emailForForgotPassword,
        }
      }
    };

    console.log(httpBody)

    const forgotPasswordApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordAPICallId = forgotPasswordApiMsg.messageId;

    forgotPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otps"
    );

    forgotPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    forgotPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    forgotPasswordApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(forgotPasswordApiMsg.id, forgotPasswordApiMsg); 
  }

  isValidCheck = () => {
    let flag = true;
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/;
    const validPhoneNumberRegex = /^\d+$/;
    if(this.state.email === ""){
      this.setState({
        requiredEmailFieldError: "Required Field *"
      })
      flag=false;
    }
    if(!this.state.email.match(validPhoneNumberRegex) && !this.state.email.match(validEmailRegex)){
      this.setState({
        emailPatternError: "Enter a valid input"
      })
      flag=false;
    }
    if(this.state.password === ""){
      this.setState({
        requiredPasswordFieldError: "Required Field *"
      })
      flag=false;
    }
    return flag;
  }

  handleLogin = () => {
    let httpBody = {}
    if(!this.isValidCheck()){
      this.setState({
        showLoginValidation: true
      })
      return
    }
    
    let headers = { 
      'Content-Type': 'application/json' 
    }

    if(this.state.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+.[a-zA-Z])*$/)){
      httpBody = {
        data: {
            type: "email_account",
            attributes: {
                email:this.state.email,
                password: this.state.password
            }
        }
      };
    }
    if(this.state.email.match(/^\d+$/)){
      httpBody = {
        data: {
          type: "sms_account",
          attributes: {
              password: this.state.password,
              full_phone_number: this.state.email
          }
        }
      }
    }

    const loginApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailLoginAPICallId = loginApiMsg.messageId;

    loginApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );

    loginApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    loginApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    loginApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(loginApiMsg.id, loginApiMsg); 
  }

  isOtpSubmitValidCheck = () => {
    let flag=true;
    if(this.state.otpEntered === ""){
      this.setState({
        requiredOtpFieldError: "Required Field *"
      })
      flag=false;
    }
    return flag
  }

  handleOtpSubmit = () => {
    if(!this.isOtpSubmitValidCheck()){
      this.setState({
        showOtpSubmitValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json' 
    }
    
    let httpBody = {
      data: {
        attributes: {
            otp_code: this.state.otpEntered,
            token: token_localStorage
        }
      }
    };
  
    const otpSubmitApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpSubmitAPICallId = otpSubmitApiMsg.messageId;

    otpSubmitApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_forgot_password/otp_confirmations"
    );

    otpSubmitApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    otpSubmitApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    otpSubmitApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(otpSubmitApiMsg.id, otpSubmitApiMsg); 
  }

  handleSubmitProfile = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      // data: {
      //   attributes: {
      //     position_id: this.state.position,
      //     organization_id: this.state.organizationSize,
      //     no_of_people: this.state.noOfPeopleAccess,
      //     goal_id: this.state.goal,
      //     erp_software_id: this.state.ERPSoftware, 
      //   }
      // }
      data: {
        attributes: {
            position: this.state.position,
            organization: this.state.organizationSize,
            no_of_people: this.state.noOfPeopleAccess,
            goal: this.state.goal,
            erp_software: this.state.ERPSoftware,
        }
      }
    };

    const submitProfileApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitProfileAPICallId = submitProfileApiMsg.messageId;

    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "update_profile"
    );

    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(submitProfileApiMsg.id, submitProfileApiMsg); 
  }

  isValidProfileCreationCheck = () => {
    let flag=true
    if(this.state.position === ""){
      this.setState({
        requiredPositionFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.organizationSize === ""){
      this.setState({
        requiredOrganizationSizeFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.goal === ""){
      this.setState({
        requiredGoalFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.ERPSoftware === ""){
      this.setState({
        requiredERPSoftwareFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.noOfPeopleAccess === ""){
      this.setState({
        requiredNoOfPeopleAccessFieldError: "Required field *"
      })
      flag=false;
    }
    if(!this.state.noOfPeopleAccess.match(/^\d+$/)){
      this.setState({
        validOrganizationSizeInputError: "Enter a number"
      })
      flag=false;
    }
    return flag
  }

  handleViewProfile = () => {
    if(!this.isValidProfileCreationCheck()){
      this.setState({
        profileValidation: true
      })
      return  
    }
    let selectedPositionOption = this.state.allPositions.find((element: any) => {
      return element.id === this.state.position
    })
    let selectedERPSoftwareOption = this.state.allErpSoftware.find((element: any) => {
      return element.id === this.state.ERPSoftware
    })
    let selectedGoalOption = this.state.allGoals.find((element: any) => {
      return element.id === this.state.goal
    })
    let selectedOrganizationSizeOption = this.state.allOrganizationSize.find((element: any) => {
      return element.id === this.state.organizationSize
    })

    this.setState({
      selectedPosition: selectedPositionOption?.your_position,
      selectedErpSoftware: selectedERPSoftwareOption?.erp_software,
      selectedGoal: selectedGoalOption?.sourceeasy_goal,
      selectedOrganizationSize: selectedOrganizationSizeOption?.select_organization,
      showProfileCreation: false,
      showProfileDetails: true, 
    })
    // this.setState({
    //   showSignUpNext: false,
    //   showProfile: true,
    // })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }

        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.openInfoPage();
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
            this.sendLoginFailMessage();
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (this.emailLoginAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        apiResponse?.errors?.map((option: any) => {
          for(const i in option){
            if(option[i]=="Login Failed"){
              alert("Login failed, please check the password")
            }
            if(option[i]=="Account not found, or not activated"){
              alert("Account not found")
            }
          }
        })
      } else {  
        this.saveLoggedInUserData(apiResponse)
        if(apiResponse?.user?.data?.attributes?.profile_updated === true){
          this.props.navigation.navigate("Dashboard")
        }
        if(apiResponse?.user?.data?.attributes?.profile_updated === false){
          this.setState({
            name: apiResponse?.user?.data?.attributes?.user_name,
            phoneNumberFromLogin: apiResponse?.user?.data?.attributes?.full_phone_number,
            emailFromLogin: apiResponse?.user?.data?.attributes?.email,
            showLogin: false,
            showProfileCreation: true,
          })
        }
        
      }
    }

    if (this.otpSubmitAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        apiResponse?.errors?.map((option: any) => {
          for(const i in option){
            if(option[i]=="OTP has expired, please request a new one."){
              alert("OTP has expired, please request a new one.")
            }
            if(option[i]=="Invalid token"){
              alert("Invalid token")
            }
            if(option[i]=="Invalid OTP code"){
              alert("Invalid OTP Code")
            }
          }
        })
      } else {
        this.saveLoggedInUserData(apiResponse)
        this.setState({
          showOtpScreen: false,
          showResetPasswordScreen: true,
        })
      }  
    }


    if (this.forgotPasswordAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        console.log('error for Forgot Password Email Submit', apiResponse?.errors)
        apiResponse?.errors?.map((option: any) => {
          for(const i in option){
            if(option[i]=="Account not found"){
              alert("Account not found, please check the email")
            }
          }
        })
      } 
      else {
        this.saveLoggedInUserData(apiResponse)
        console.log('success for Forgot Password Email Submit', apiResponse);
        this.setState({
          otp: apiResponse?.data?.attributes?.pin,
          showForgotPasswordEmailScreen: false,
          showOtpScreen: true,
          //showOtpScreen: true,
        })
        //this.props.navigation.navigate("EmailAccountLoginBlock")
      }  
    }

    if (this.resetPasswordAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse){
        //this.saveLoggedInUserData(apiResponse)
        console.log('success for Forgot Password Email Submit', apiResponse);
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
    }

    if (this.submitProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse) {
        this.setState({
          name: '',
          phoneNumber: '',
          goal: '',
          position: '',
          organizationSize: '',
          noOfPeopleAccess: '',
          ERPSoftware: '',
        })
        this.props.navigation.navigate("Dashboard")
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}
