import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  name: string,
  confirmPassword: string,
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showSignUp: boolean;
  showSignUpNext: boolean;
  noOfPeopleAccess: string,
  position: string,
  showProfile: boolean,
  organizationSize: string,
  goal: string,
  ERPSoftware: string,
  showSignUpValidation: boolean,
  matchingConfirmPasswordFieldError: string,
  allPositions: any[],
  allGoals: any[],
  allErpSoftware: any[],
  emailPatternError: string,
  phoneNumberPatternError: string,
  allOrganizationSize: any[],
  validOrganizationSizeInputError: string,
  requiredNoOfPeopleAccessFieldError: string,
  selectedErpSoftware: string,
  selectedOrganizationSize: string,
  requiredPositionFieldError: string,
  requiredGoalFieldError: string,
  requiredOrganizationSizeFieldError: string,
  requiredERPSoftwareFieldError: string,
  profileValidation: boolean,
  selectedPosition: string,
  selectedGoal: string,
  inputTypePassword: string,
  requiredNameFieldError: string,
  requiredEmailFieldError: string,
  requiredPhoneFieldError: string,
  requiredPasswordFieldError: string,
  requiredConfirmPasswordFieldError: string,
  inputTypeConfirmPassword: string,
  emailAlreadyExistError: string,
  phoneNumberAlreadyExistError: string,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;

  signupApiCallId: string = "";
  getAllPositionOptionsAPICallId: string = "";
  getAllGoalOptionsAPICallId: string = "";
  getAllErpSoftwareOptionsAPICallId: string = "";
  getAllOrganizationSizeOptionsAPICallId: string = "";
  submitProfileAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      name: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showSignUp: true,
      showSignUpNext: false,
      position: "",
      showProfile: false,
      organizationSize: "",
      noOfPeopleAccess: "",
      goal: "",
      ERPSoftware: "",
      requiredNameFieldError: "",
      requiredEmailFieldError: "",
      requiredPhoneFieldError: "",
      requiredPasswordFieldError: "",
      requiredConfirmPasswordFieldError: "",
      emailPatternError: "",
      phoneNumberPatternError: "",
      showSignUpValidation: false,
      matchingConfirmPasswordFieldError: "",
      allPositions: [],
      allGoals: [],
      allErpSoftware: [],
      allOrganizationSize: [],
      selectedPosition: "",
      selectedGoal: "",
      selectedErpSoftware: "",
      selectedOrganizationSize: "",
      requiredPositionFieldError: "",
      requiredGoalFieldError: "",
      requiredOrganizationSizeFieldError: "",
      validOrganizationSizeInputError: "",
      requiredNoOfPeopleAccessFieldError: "",
      requiredERPSoftwareFieldError: "",
      profileValidation: false,
      inputTypePassword: "password",
      inputTypeConfirmPassword: "password",
      emailAlreadyExistError: "",
      phoneNumberAlreadyExistError: "",
      // Customizable Area End
    };

    // Customizable Area Start

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (this.signupApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        console.log('error', apiResponse?.errors)
        if(apiResponse?.errors == "Email already taken"){
          alert("Account with this Email ID already exist")
        }
        if(apiResponse?.errors == "Phone number already taken"){
          alert("Account with this Phone Number already exist")
        }
      } else {
        console.log('success', apiResponse);
        this.saveLoggedInUserData(apiResponse)
        console.log('SignUp token is ', apiResponse?.meta?.token)
        if(apiResponse?.meta?.token){
          this.handleGetAllPositionOptions()
          this.handleGetAllGoalOptions()
          this.handleGetAllErpSoftwareOptions()
          this.handleGetAllOrganizationSizeOptions()
          // this.setState({
          //   showSignUp: false,
          //   showSignUpNext: true
          // })
        }
        this.props.navigation.navigate("EmailAccountLoginBlock")
        // this.setState({
        //   showSignUp: false,
        //   showSignUpNext: true
        // })
      }
    }

    if (this.getAllPositionOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        // apiResponse?.errors![0]?.message;
        console.log('error', apiResponse?.errors)
      } else {
        console.log('success for get all positions api', apiResponse);
        this.setState({
          allPositions: apiResponse?.all_position
        })
      } 
    }

    if (this.getAllGoalOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse?.errors){
        console.log('error for get all goals api', apiResponse?.errors)
      } else {
        console.log('success for get all goals api', apiResponse);
        this.setState({
          allGoals: apiResponse?.goal
        })
      } 
    }

    if (this.getAllErpSoftwareOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse?.errors){
        console.log('error for get all erp software api', apiResponse?.errors)
      } else {
        console.log('success for get all erp software api', apiResponse);
        this.setState({
          allErpSoftware: apiResponse?.all_position
        })
      } 
    }

    if (this.getAllOrganizationSizeOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiResponse?.errors){
        console.log('error for get all organization size api', apiResponse?.errors)
      } else {
        console.log('success for get all organization size api', apiResponse);
        this.setState({
          allOrganizationSize: apiResponse?.organization_size
        })
      } 
    }

    if (this.submitProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.errors){
        console.log('submit Profile error', apiResponse?.errors)
      } else {
        console.log('submit Profile success ', apiResponse);
        this.setState({
          goal: '',
          position: '',
          organizationSize: '',
          noOfPeopleAccess: '',
          ERPSoftware: '',
        })
        this.props.navigation.navigate("Dashboard")
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
      super.componentDidMount();
      // this.handleGetAllPositionOptions()
      // this.handleGetAllGoalOptions()
      // this.handleGetAllErpSoftwareOptions()
      // this.handleGetAllOrganizationSizeOptions()
  }

  saveLoggedInUserData = (responseJson: any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.target.value
    })
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value
    })
  }

  handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phone: event.target.value
    })
  }

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value
    })
  }

  handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmPassword: event.target.value
    })
  }

  isValidCheck = () => {
    let flag=true;
    const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+.[a-zA-Z])*$/;
    const validPhoneNumberRegex = /^\d+$/;
    if(this.state.name === ""){
      this.setState({
        requiredNameFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.email === ""){
      this.setState({
        requiredEmailFieldError: "Required field *"
      })
      flag=false;
    }
    if(!this.state.email.match(validEmailRegex)){
      this.setState({
        emailPatternError: "Enter a valid email"
      })
      flag=false;
    }
    else{
      this.setState({
        emailPatternError: ""
      })
    }
    if(this.state.phone === ""){
      this.setState({
        requiredPhoneFieldError: "Required field *"
      })
      flag=false;
    }
    if(!this.state.phone.match(validPhoneNumberRegex)){
      this.setState({
        phoneNumberPatternError: "Enter a valid phone number"
      })
      flag=false;
    }
    else{
      this.setState({
        phoneNumberPatternError: ""
      })
    }
    if(this.state.password === ""){
      this.setState({
        requiredPasswordFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.confirmPassword === ""){
      this.setState({
        requiredConfirmPasswordFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.password.toLowerCase() !== this.state.confirmPassword.toLowerCase()){
      this.setState({
        matchingConfirmPasswordFieldError: "Password did not match *"
      })
      flag=false;
    }
    return flag;
  }

  handleSignUp = () => {
    if(!this.isValidCheck()){
      this.setState({
        showSignUpValidation: true
      })
      return
    }

    let headers = { 
      'Content-Type': 'application/json' 
    }

    const httpBody = {
      data: {
          type: "email_account",
          attributes: {
              user_name: this.state.name,
              email:this.state.email,
              full_phone_number: this.state.phone,
              password: this.state.password
          }
      }
    };

    const signupApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signupApiCallId = signupApiMsg.messageId;

    signupApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    signupApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    signupApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    signupApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signupApiMethodType
    );

    runEngine.sendMessage(signupApiMsg.id, signupApiMsg); 
  }

  handleChangePosition = (event: any) => {
    this.setState({
      position: event.target.value
    })
  }

  handlePasswordVisibilityIcon = (event: any) => {
    if(this.state.inputTypePassword === "password"){
      this.setState({
        inputTypePassword: "text"
      })
    }
    if(this.state.inputTypePassword === "text"){
      this.setState({
        inputTypePassword: "password"
      })
    }
  }

  handleConfirmPasswordVisibilityIcon = (event: any) => {
    if(this.state.inputTypeConfirmPassword === "password"){
      this.setState({
        inputTypeConfirmPassword: "text"
      })
    }
    if(this.state.inputTypeConfirmPassword === "text"){
      this.setState({
        inputTypeConfirmPassword: "password"
      })
    }
  }

  isValidProfileCreationCheck = () => {
    let flag=true
    if(this.state.position === ""){
      this.setState({
        requiredPositionFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.organizationSize === ""){
      this.setState({
        requiredOrganizationSizeFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.goal === ""){
      this.setState({
        requiredGoalFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.ERPSoftware === ""){
      this.setState({
        requiredERPSoftwareFieldError: "Required field *"
      })
      flag=false;
    }
    if(this.state.noOfPeopleAccess === ""){
      this.setState({
        requiredNoOfPeopleAccessFieldError: "Required field *"
      })
      flag=false;
    }
    if(!this.state.noOfPeopleAccess.match(/^\d+$/)){
      this.setState({
        validOrganizationSizeInputError: "Enter a number"
      })
      flag=false;
    }
    return flag
  }

  handleViewProfile = () => {
    if(!this.isValidProfileCreationCheck()){
      this.setState({
        profileValidation: true
      })
      return  
    }
    let selectedPositionOption = this.state.allPositions.find((element: any) => {
      return element.id === this.state.position
    })
    let selectedERPSoftwareOption = this.state.allErpSoftware.find((element: any) => {
      return element.id === this.state.ERPSoftware
    })
    let selectedGoalOption = this.state.allGoals.find((element: any) => {
      return element.id === this.state.goal
    })
    let selectedOrganizationSizeOption = this.state.allOrganizationSize.find((element: any) => {
      return element.id === this.state.organizationSize
    })

    this.setState({
      selectedPosition: selectedPositionOption?.your_position,
      selectedErpSoftware: selectedERPSoftwareOption?.erp_software,
      selectedGoal: selectedGoalOption?.sourceeasy_goal,
      selectedOrganizationSize: selectedOrganizationSizeOption?.select_organization, 
    })
    this.setState({
      showSignUpNext: false,
      showProfile: true,
    })
  }

  handleOrganizationSize = (event: any) => {
    this.setState({
      organizationSize: event.target.value
    })
  }

  handleNoOfPeopleAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      noOfPeopleAccess: event.target.value
    })
  }

  handleGoal = (event: any) => {
    this.setState({
      goal: event.target.value
    })
  }

  handleERPSoftware = (event: any) => {
    this.setState({
      ERPSoftware: event.target.value
    })
  }

  handleGoBack = () => {
    this.setState({
      showProfile: false,
      showSignUpNext: true
    })
  }

  handleGoBackToSignUp = () => {
    this.setState({
      showSignUpNext: false,
      showSignUp: true
    })
  }

  handleGetAllPositionOptions = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllPositionOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPositionOptionsAPICallId = getAllPositionOptionsApiMsg.messageId;

    getAllPositionOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/all_position"
    );

    getAllPositionOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllPositionOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      // configJSON.getAllDashboardOptionsAPiMethod
    );

    runEngine.sendMessage(getAllPositionOptionsApiMsg.id, getAllPositionOptionsApiMsg); 
  }

  handleGetAllGoalOptions = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllGoalOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllGoalOptionsAPICallId = getAllGoalOptionsApiMsg.messageId;

    getAllGoalOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/all_goal"
    );

    getAllGoalOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllGoalOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      // configJSON.getAllDashboardOptionsAPiMethod
    );

    runEngine.sendMessage(getAllGoalOptionsApiMsg.id, getAllGoalOptionsApiMsg); 
  }

  handleGetAllErpSoftwareOptions = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllErpSoftwareOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllErpSoftwareOptionsAPICallId = getAllErpSoftwareOptionsApiMsg.messageId;

    getAllErpSoftwareOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/all_erp"
    );

    getAllErpSoftwareOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllErpSoftwareOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      // configJSON.getAllDashboardOptionsAPiMethod
    );

    runEngine.sendMessage(getAllErpSoftwareOptionsApiMsg.id, getAllErpSoftwareOptionsApiMsg); 
  }

  handleGetAllOrganizationSizeOptions = async() => {
    const token_localStorage = await localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllOrganizationSizeOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOrganizationSizeOptionsAPICallId = getAllOrganizationSizeOptionsApiMsg.messageId;

    getAllOrganizationSizeOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "all_organization_size"
    );

    getAllOrganizationSizeOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllOrganizationSizeOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getAllOrganizationSizeOptionsApiMsg.id, getAllOrganizationSizeOptionsApiMsg); 
  }

  handleSubmitProfile = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          position_id: this.state.position,
          organization_id: this.state.organizationSize,
          no_of_people: this.state.noOfPeopleAccess,
          goal_id: this.state.goal,
          erp_software_id: this.state.ERPSoftware, 
        }
      }
    };

    const submitProfileApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitProfileAPICallId = submitProfileApiMsg.messageId;

    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "update_profile"
    );

    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    submitProfileApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(submitProfileApiMsg.id, submitProfileApiMsg); 
  }
  // Customizable Area End
}
