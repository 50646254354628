import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ChangeEvent } from "react";
let baseURL = require('../../../framework/src/config').baseURL

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  userProfile: string;
  accountId: any,
  active: number;
  token: string;
  errorMsg: string;
  loading: boolean;
  openDropdown: boolean;
  tabValue: number;
  subTabValue: number;
  isRowSelected: boolean;
  subTabValueCreateOrFind: number,
  checkedCreateOrFind: boolean,
  openAddRequisitionModal: boolean,
  openEditRequisitionModal: boolean,
  openCreateNewModal: boolean,
  openNextPurchaseModal:boolean,
  openNextCustomFromRequisitionModal:boolean,
  openPdfModal:boolean,
  openShowRequisitionModal: boolean,
  prAcceptRejectActionStatusMessage: string,
  uploadedFileRequestForProposals: string,
  durationDate: any,
  deliveryDate: any,
  location: string,
  department: string,
  status: any,
  products: any[],
  prProductAll: any[],
  prProduct: any[],
  productsSearch: any[],
  productPurchaseRequisition: any[],
  editedDurationDate: string,
  editedPRGen:string,
  editedDeliveryDate: string,
  editedRequester: string,
  editedLocation: string,
  editedCatalougeItemPr: any[],
  editedCatalougeItemProductRequisition: any[],
  editedNonCatalougeItemPr: any[],
  editedDepartment: string,
  editedBillingLocation: string,
  editedShippingLocation: string,
  editedBudget: string,
  editedSupplier: string,
  editedPayment: string,
  editedTaxation: string,
  editedStatus: string,
  editedProducts: any[],
  prAcceptRejectActionStatus: string,
  poAcceptRejectActionStatus: string,
  purchaseRequisitionList: any[],
  editedPurchaseRequisition: any,
  editedPrNumberDefault: string,
  showPurchaseRequisition: any,
  isShowPurchaseRequisition: boolean,
  showSettingsLocation: any[],
  showSettingsCategory: any,
  showSettingsUnitMeasurement: any[],
  productsList: any[],
  statusList : any[],
  createPurchaseRequisitionLoading: boolean,
  editPurchaseRequisitionLoading: boolean,
  selectedProductIds: string[],
  rfpList: any[],
  rfpStatus: string,
  prStatusList: any[],
  prNumberUnique: any,
  rfpNumberUnique:any,
  poNumberUnique:any,
  prPoNumberUnique: any,
  prQuantity: any,
  prNonCatalougeItems: string,
  prEditNonCatalougeItems: string,
  addRfpProducts:any[],
  addRfpProductsNonCatItemText: string,
  prProductId:any,
  prProductObj: any, 
  prNonCatalougeItemsKeydown: string,
  rfpCurrency: string,
  //rfpCreationDate: string,
  rfpCreationDate: any,
  rfpDueDate: string,
  rfpAddress: string,
  rfpDesiredDeliveryDate: string,
  rfpEmailOfSupplier: string,
  rfpEmailOfSuppliers: any[],
  rfpExistingProduct: string,
  rfpAttachment: string,
  //rfpSelectExistingSuppliers: string,
  rfpSelectExistingSuppliers: any[],
  addItemSelectExistingSuppliers: any[],
  addItemSupplier: any,
  addItemSupplierMultiple: any[],
  editedItemSupplierMultiple: any[],
  editedSupplierMultipleRequiredField: string,
  addItemSupplierNoUnique: any,
  prCatalougeItemSelectedRow: any,
  rfpFileAttachment: any,
  selectedRow: any,
  rfpSelectedRow: any,
  openAddRFPModal: boolean,
  openNextRFPMainCreateFieldsModal: boolean,
  catchRfpIdForPdfCreate: any,
  openShowRFPModal: boolean,
  openEditRFPModal: boolean,
  openRfpPdfSupplierScreenModal:boolean,
  openRfpPdfSuccessModal:boolean,
  openRfpSupplierMailSeccessModal:boolean,
  openPoPdfSupplierScreenModal:boolean,
  openPoPdfSuccessModal:boolean,
  openPoSupplierMailSeccessModal:boolean,
  showRfp: any,
  dashboardOptions: string[],
  editRfp: any,
  editedRfpId: string,
  editedRfpStatus: string,
  editedRfpCurrency: string,
  editedRfpCreationDate: string,
  editedRfpDueDate: string,
  editedRfpAddress: string,
  editedRfpDesiredDeliveryDate: string,
  editedRfpEmailOfSupplier: string,
  editedrfpMultiEmailOfSuppliersStoreArr: any,
  editedRfpExistingProduct: string,
  editedRfpAttachment: string,
  editedRfpFileAttachment: any,
  isFileEdited: boolean,
  showIsEditRFPValidation: boolean,
  editedRfpStatusRequiredError: string,
  editedRfpCurrencyRequiredError: string,
  editedRfpCreationDateRequiredError: string,
  editedRfpDueDateRequiredError: string,
  editedRfpAddressRequiredError: string,
  editedRfpDesiredDeliveryDateRequiredError: string,
  editedRfpExistingProductRequiredError: string,
  editedRfpAttachmentTypeRequiredError: string,
  editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: string,
  editedRfpFileAttachmentRequiredError: string,
  editedRfpSupplierEmailMatchError: string,
  openEditRfpShowErrorModal: boolean,
  totalPagesPurchaseRequisition: number,
  currentPagePurchaseRequisition: number,
  totalPagesPurchaseRequisitionPO: number,
  currentPagePurchaseRequisitionPO: number,
  itemsPerPage: number,
  itemsPerPageProduct: number,
  suppliersList: any[],
  totalPagesRfp: number,
  currentPageRfp: number,
  showIsCreateRFPValidation: boolean,
  showIsAddRfpProductsValidation: boolean,
  addRfpProductsRequiredError:string,
  rfpStatusRequiredError: string,
  rfpCurrencyRequiredError: string,
  rfpCreationDateRequiredError: string,
  rfpDueDateRequiredError: string,
  rfpAddressRequiredError: string,
  rfpDesiredDeliveryDateRequiredError: string,
  rfpExistingProductRequiredError: string,
  rfpAttachmentTypeRequiredError: string,
  rfpAttachmentTypeMatchError: string,
  rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: string,
  rfpFileAttachmentRequiredError: string,
  rfpSupplierEmailMatchError: string,
  openCreateRfpShowErrorModal: boolean,
  openCreatePoShowErrorModal: boolean,
  openCreatePrPoShowErrorModal: boolean,
  showIsCreatePurchaseRequisitionValidation: boolean,
  durationDateRequiredError: string,
  deliveryDateRequiredError: string,
  locationRequiredError: string,
  locationAddressRequiredError: string,
  locationIdentityCodeRequiredError: string,
  locationGstRequiredError: string,
  departmentRequiredError: string,
  prStatusListRequiredError: string,
  productsRequiredError: string,
  prQuantityRequiredError: string,
  prNonCatalougeItemsRequiredError: string,
  showIsEditPurchaseRequisitionValidation: boolean,
  editedDurationDateRequiredError: string,
  editedDeliveryDateRequiredError: string,
  editedLocationRequiredError: string,
  editedDepartmentRequiredError: string,
  editedCatalougeItemPrRequiredError: string,
  editedNonCatalougeItemPrRequiredError: string,
  editedStatusRequiredError: string,
  editedProductsRequiredError: string,
  rfpSuppliers: any,
  rfpEditedSuppliers: any,
  selectedSupplierIds: string[],
  durationDateType: string,
  deliveryDateType: string,
  editedDurationDateType: string,
  editedDeliveryDateType: string,
  rfpCreationDateType: string,
  rfpDueDateType: string,
  rfpDesiredDeliveryDateType: string,
  editedRfpCreationDateType: string,
  editedRfpDueDateType: string,
  editedRfpDesiredDeliveryDateType: string,
  showDefaultMenuValue: boolean,
  purchaseOrdersList: any[],
  totalPagesPurchaseOrder: number,
  currentPagePurchaseOrder: number,
  purchaseOrderSelectedRow: any,
  openAddPurchaseOrderModal: boolean,
  openShowPurchaseOrderModal: boolean,
  selectPoOptions: string,
  poId: string,
  poLocation: string,
  poSupplier: string,
  poOrderDate: string,
  poFobPoint: string,
  poShippingAddress: string,
  prPoShippingAddressOptional: string,
  poShippingAddressOptional: string,
  poBillingAddress: string,
  //poTaxId: string,
  poGstNumber: string,
  poModeOfTransit: string,
  poProduct: any[],
  poCommentForPO: string,
  poAmountCalculation: string,
  poDepartment: string,
  poBudget: string,
  poComment: string,
  editedPoId: string,
  editedPoNumber: any,
  editedPoLocation: string,
  editedPoSupplier: string,
  editedPoPaymentTermsName: string,
  poEditScreenActiveTabs: number,
  poViewScreenActiveTabs:number,
  editedPoOrderDate: any,
  editedPoFobPoint: string,
  editedPoShippingAddress: string,
  editedPoShippingAddressOptional: string,
  editedPoBillingAddress: string,
  // editedPoTaxId: string,
  editedPoGstNumber: string,
  editedPoModeOfTransit: string,
  editedPoProduct: any[],
  editedPoCommentForPO: string,
  editedPoAmountCalculation: string,
  editedPoDepartment: string,
  editedPoBudget: string,
  editedPoComment: string,
  poOrderDateType: string,
  openEditPurchaseOrderModal: boolean,
  editedPoOrderDateType: string,
  editedPoDeliveryDate: string,
  showPurchaseOrder: any,
  openPurchaseRequisitionListModal: boolean,
  customFromRequisitionSelectedRow: boolean,
  customFromRequisitionPoId: string,
  customFromRequisitionPoSupplier: string,
  customFromRequisitionPoOrderDate: string,
  customFromRequisitionPoFobPoint: string,
  customFromRequisitionPoShippingAddress: string,
  customFromRequisitionPoBillingAddress: string,
  customFromRequisitionPoTaxId: string,
  customFromRequisitionPoModeOfTransit: string,
  customFromRequisitionPoCommentForPO: string,
  customFromRequisitionPoAmountCalculation: string,
  customFromRequisitionPoBudget: string,
  customFromRequisitionPoComment: string,
  customFromRequisitionPoOrderDateType: string,
  openCustomFromRequisitionPoModal: boolean,
  openCustomFromRequisitionCreatePoModal: boolean,
  customFromRequisitionSelectedId: string,
  receiptsList: any[],
  totalPagesReceipt: number;
  currentPageReceipt: number;
  receiptSelectedRow: any;
  openAddReceiptModal: boolean;
  openEditReceiptModal: boolean;
  receiptSelectedPo: string;
  receiptQuantityReceived: string;
  receiptQuantityRequested: string;
  receiptReceiptDate: string;
  receiptComments: string;
  receiptAttachmentFile: any;
  editedReceiptSelectedPo: string,
  editedReceiptQuantityReceived: string;
  editedReceiptQuantityRequested: string;
  editedReceiptReceiptDate: string;
  editedReceiptComments: string;
  editedReceiptAttachmentFile: any;
  receiptDateType: string;
  editedReceiptDateType: string;
  receipt: any;
  isReceiptFileEdited: boolean;
  editedReceiptId: string;
  openShowReceiptModal: boolean;
  showReceipt: any;
  currencyList: string[];
  purchaseOrderLocationRequiredField: string,
  purchaseOrderSupplierRequiredField: string,
  purchaseOrderPaymentTermsRequiredField: string,
  purchaseOrderTaxationNameRequiredField: string,
  purchaseOrderOrderDateRequiredField: string,
  purchaseOrderFobPointRequiredField: string,
  purchaseOrderShippingAddressRequiredField: string,
  purchaseOrderBillingAddressRequiredField: string,
  purchaseOrderGstNumberRequiredField: string,
  purchaseOrderDeliveryDateRequiredField: string,
  purchaseOrderDeliveryDateErrorFormat: string,
  purchaseOrderModeOfTransitRequiredField: string,
  purchaseOrderProductsRequiredField: string,
  purchaseOrderCommentsForPoRequiredField: string,
  purchaseOrderAmountCalculationRequiredField: string,
  purchaseOrderDepartmentRequiredField: string,
  purchaseOrderBudgetRequiredField: string,
  purchaseOrderCommentRequiredField: string,
  editedPurchaseOrderLocationRequiredField: string,
  editedPurchaseOrderSupplierRequiredField: string,
  editedPurchaseOrderOrderDateRequiredField: string,
  editedPurchaseOrderDeliveryDateRequiredField: string,
  editedPurchaseOrderPaymentTermsRequiredField: string,
  editedPurchaseOrderFobPointRequiredField: string,
  editedPurchaseOrderShippingAddressRequiredField: string,
  editedPurchaseOrderBillingAddressRequiredField: string,
  editedPurchaseOrderGstNumberRequiredField: string,
  editedPurchaseOrderModeOfTransitRequiredField: string,
  editedPurchaseOrderProductsRequiredField: string,
  editedPurchaseOrderCommentsForPoRequiredField: string,
  editedPurchaseOrderAmountCalculationRequiredField: string,
  editedPurchaseOrderDeliveryDateErrorFormat: string,
  editedPurchaseOrderDepartmentRequiredField: string,
  editedPurchaseOrderBudgetRequiredField: string,
  editedPurchaseOrderCommentRequiredField: string,
  customFromRequisitionPoFobPointRequiredField: string,
  customFromRequisitionPoShippingAddressRequiredField: string,
  customFromRequisitionPoBillingAddressRequiredField: string,
  customFromRequisitionPoTaxIdRequiredField: string,
  customFromRequisitionPoModeOfTransitRequiredField: string,
  customFromRequisitionPoSupplierRequiredField: string,
  customFromRequisitionPoOrderDateRequiredField: string,
  customFromRequisitionPoCommentForPORequiredField: string,
  customFromRequisitionPoAmountCalculationRequiredField: string,
  customFromRequisitionPoBudgetRequiredField: string,
  customFromRequisitionPoCommentRequiredField: string,
  showIsCreatePurchaseOrderValidation: boolean,
  showIsEditPurchaseOrderValidation: boolean,
  showIsCreateCustomFromRequisitionValidation: boolean,
  receiptQuantityReceivedRequiredField: string;
  receiptQuantityRequestedRequiredField: string;
  receiptReceiptDateRequiredField: string;
  receiptCommentsRequiredField: string;
  receiptAttachmentFileRequiredField: any;
  editedReceiptQuantityReceivedRequiredField: string;
  editedReceiptQuantityRequestedRequiredField: string;
  editedReceiptReceiptDateRequiredField: string;
  editedReceiptCommentsRequiredField: string;
  editedReceiptAttachmentFileRequiredField: any;
  showIsCreateReceiptValidation: boolean;
  showIsEditReceiptValidation: boolean,
  openAddRFPDownloadFiles: boolean,
  dashboardOptionSelected: string,
  totalPagesSupplier: number,
  currentPageSupplier: number,
  openAddSupplierModal: boolean,
  supplierSupplierName: string,
  supplierUniqueCode: string,
  supplierTermsOfPayment: string,
  paymentTermsNameSuppliers: any[],
  supplierEmailAddress: string,
  supplierContactNumber: string,
  supplierPointOfContact: string,
  supplierAddressOfSupplierCompany: string,
  supplierGSTNumber: string,
  supplierSupplierLocation: string,
  editedSupplierSupplierName: string,
  editedSupplierUniqueCode: string,
  editedSupplierTermsOfPayment: string,
  editedSupplierEmailAddress: string,
  editedSupplierContactNumber: string,
  editedSupplierPointOfContact: string,
  editedSupplierAddressOfSupplierCompany: string,
  editedSupplierGSTNumber: string,
  editedSupplierSupplierLocation: string,
  editedSupplierStateProvince: string,
  editedSupplierZipCodePinCode: any,
  editedSupplierPaymentList: any[],
  supplierSelectedRow: any,
  openEditSupplierModal: boolean,
  editedSupplierId: string,
  showSupplier: any,
  openShowSupplierModal: boolean,
  currentPageProduct: number,
  totalPagesProduct: number,
  productSelectedRow: any,
  productName: string,
  productPartId: string,
  productDescription: string,
  productUnitMeasurement: string,
  productUnitPrice: string,
  productCategory: string,
  productType: string,
  editedProductName: string,
  editedAddNotes: string,
  editedProductPartId: string,
  editedProductDescription: string,
  editedProductUnitMeasurement: string,
  editedProductUnitPrice: string,
  editedProductCategory: string,
  editedProductType: string,
  openAddProductModal: boolean,
  openEditProductModal: boolean,
  openShowProductModal: boolean,
  editedProductId: string,
  showProduct: any,
  budgetName: string,
  budgetFor: string,
  budgetPeriod: string,
  budgetAmount: any,
  budgetComment: string,
  budgetProductCategories: string,
  editedBudgetId: string,
  editedBudgetName: string,
  editedBudgetStartingDate: string,
  editedBudgetFor: string,
  editedBudgetPeriod: string,
  editedBudgetAmount: string,
  editedBudgetComment: string,
  editedBudgetProductCategories: string,
  budgetListing: any[],
  totalPagesBudget: number,
  currentPageBudget: number,
  budgetSelectedRow: any,
  openAddBudgetModal: boolean,
  openEditBudgetModal: boolean,
  openShowBudgetModal: boolean,
  openShowSettingLocationModal: boolean,
  openEditSettingLocationModal: boolean,
  openEditSettingCategoryModal: boolean,
  openEditSettingUnitMeasurementModal: boolean,
  showBudget: any,
  showSettingsLocationSingleData: any,
  showSettingsLocationAPICallId: any,
  productNameRequiredField: string,
  productPartIdRequiredField: string,
  productDescriptionRequiredField: string,
  productUnitMeasurementRequiredField: string,
  productUnitPriceRequiredField: string,
  productCategoryRequiredField: string,
  productTypeRequiredField: string,
  editedProductNameRequiredField: string,
  editedProductPartIdRequiredField: string,
  editedProductDescriptionRequiredField: string,
  editedProductUnitMeasurementRequiredField: string,
  editedProductUnitPriceRequiredField: string,
  editedProductCategoryRequiredField: string,
  editedProductTypeRequiredField: string,
  supplierNameRequiredField: string,
  supplierUniqueCodeRequiredField: string,
  supplierTermsOfPaymentRequiredField: string,
  supplierEmailAddressRequiredField: string,
  supplierEmailAddressEmailMatchError: string,
  supplierContactNumberRequiredField: string,
  supplierContactNumberMatchError: string,
  supplierPointOfContactRequiredField: string,
  supplierAddressOfSupplierCompanyRequiredField: string,
  supplierGstNumberRequiredField: string,
  supplierStateProvinceRequiredField: string,
  supplierZipCodePinCodeRequiredField: string,
  supplierStateProvince: string,
  editSupplierStateProvince: string,
  supplierZipCodePinCode: string,
  supplierLocationRequiredField: string,
  editedSupplierNameRequiredField: string,
  editedSupplierUniqueCodeRequiredField: string,
  editedSupplierTermsOfPaymentRequiredField: string,
  editedSupplierEmailAddressRequiredField: string,
  editedSupplierEmailAddressEmailMatchError: string,
  editedSupplierContactNumberRequiredField: string,
  editedSupplierContactNumberMatchError: string,
  editedSupplierPointOfContactRequiredField: string,
  editedSupplierAddressOfSupplierCompanyRequiredField: string,
  editedSupplierGstNumberRequiredField: string,
  editedSupplierZipCodePinCodeRequiredField: string,
  editedSupplierStateProvinceRequiredField: string,
  editedSupplierLocationRequiredField: string,
  budgetNameRequiredField: string,
  budgetForRequiredField: string,
  budgetPeriodRequiredField: string,
  budgetAmountRequiredField: string,
  budgetCommentRequiredField: string,
  budgetProductCategoriesRequiredField: string,
  editedBudgetNameRequiredField: string,
  editedBudgetForRequiredField: string,
  editedBudgetPeriodRequiredField: string,
  editedBudgetAmountRequiredField: string,
  editedBudgetCommentRequiredField: string,
  editedBudgetProductCategoriesRequiredField: string,
  showIsCreateSupplierValidation: boolean,
  showIsEditSupplierValidation: boolean,
  showIsCreateProductValidation: boolean,
  showIsEditProductValidation: boolean,
  showIsCreateBudgetValidation: boolean,
  showIsEditBudgetValidation: boolean,
  customFromRequisitionSelectMessage: string,
  showSettings: boolean,
  showInformation: boolean,
  showCurrencySettings: boolean,
  showLocationSettings: boolean,
  showUsers: boolean,
  informationSettingsCompanyName: string,
  settingsLocation: string,
  settingsAddress: string,
  settingsCategory: string,
  settingsCategoryRequiredField: string,
  categorySettingCreatePost: boolean,
  settingsUnitMeasurement: string,
  unitMeasurementSettingPost: boolean,
  settingsUnitMeasurementRequiredField: string,
  settingsIdentificationCode: string,
  settingsGst: string,
  editSettingsGst: string,
  editSettingsId: string,
  createSettingsCategoryMessage:string,
  editSettingsCategoryId: string,
  editSettingsLocation: string,
  editSettingsCategorySL: string,
  editSettingsUnitMeasurementId: string,
  editSettingsUnitMeasurementSL: string,
  editSettingsCategoryName: string,
  editSettingsUnitMeasurement: string,
  editSettingsAddress: string,
  editSettingsIdentificationCode: string,
  informationSettingsPhoneNumber: string,
  informationSettingsAddress: string,
  informationSettingsEmail: string,
  informationSettingsPinCode: string,
  informationSettingsWebsite: string,
  informationSettingsGstNumber: string,
  currencySettingsCurrency: string,
  enableEditInformationSettings: boolean,
  enableEditCurrencySettings: boolean,
  informationSettings: any,
  currencySettings: any, 
  locationSettingsNameOfLocation: string,
  locationSettingsAddress: string,
  locationSettingsLocationIdentificationCode: string,
  locations: any[],
  selectedCurrencyCurrencySettings: string,
  openAddLocationsModal: boolean,
  locationsList: any[],
  showUserSettings: boolean,
  showCategorySettings: boolean,
  showUnitMeasureSettings: boolean,
  openAddUserModal: boolean,
  usersList: any[],
  userSettingsName: string,
  userSettingsRoles: string,
  userSettingsDesignation: string,
  userSettingsEmailId: string,
  userSettingsDepartment: string,
  productCategoriesList: any[],
  departmentList: any[],
  budgetList: any[],
  supplierList: any[],
  getPaymentTermListSupplier: any[],
  paymentTermsList: any[],
  taxations: any[],
  showPaymentTermsSettings: boolean,
  showTaxationSettings: boolean,
  paymentTermsName: string,
  PaymentTermDeleteMessage:string,
  unitMeasurementDeleteMessage:string,
  unitMeasurementCreateMessage: string,
  unitMeasurementUpdateMessage: string,
  creditDays: string,
  taxName: string,
  taxPercentage: string,
  editPaymentTermsName: string,
  editCreditDays: string,
  paymentTermId: any,
  editTaxName: string,
  editTaxPercentage: string,
  taxationId: any,
  showEditPaymentTermsSettingField: boolean,
  showEditPaymentTermsSettingBtn: boolean,
  showEditTaxationSettingBtn: boolean,
  showEditTaxationEditField: boolean,
  showEditTaxationEditBtn: boolean,
  rfpAttachmentDropdownDisplay: boolean,
  locationDropdownList: any[],
  purchaseRequisitionSearchLocation: string,
  receiptSelectedPOPopulatedData: any,
  rfpEmailOfSupplierEmailPatternError: boolean,
  editedRfpEmailOfSupplierEmailPatternError: boolean,
  users: any[],
  receiptAttachment: string,
  receiptAttachmentDropdownDisplay: boolean,
  editedReceiptAttachment: string,
  editedReceiptAttachmentDropdownDisplay: boolean,
  receiptSelectedPoRequiredField: string,
  rfpEmailOfSupplierEmailDuplicateError: boolean,
  editedRfpEmailOfSupplierEmailDuplicateError: boolean,
  editedReceiptAttachmentTypeMatchError: string,
  openUserPermissionsModal: boolean,
  // userPermissionsList: any[],
  userPermissionsList: {id: number, title: string, status: boolean}[],
  userPermissionsList1: any,
  dateValidationError: string,
  alreadyCreatedUsers: any[],
  userIdForUserAccessPermission: string,
  // dashboard state variables start
  dashboardMenuSelection: string,
  dropDownItemValue: string,
  dashboardShowHeader: boolean,
  currentPage: number,
  currentPagePaymentTerms: number,
  currentPageTaxations: number,
  currentPageUnitMeasure: number,
  currentPageCategory: number,
  currentPageSettingsLocation: number,
  noOfPages: number,
  noOfPagesPaymentTerms: number,
  noOfPagesTaxations: number,
  noOfPagesUnitMeasure: number,
  noOfPagesCategory: number,
  noOfPagesSettingsLocation: number,
  dashboardRecordResults: any[],
  rfpData: any,
  selectedDepartmentPRIds: number[],
  departmentsList: any[],
  editedPoSupplierId: number,
  editedPoTaxId: string,
  editedPurchaseOrderTaxIdRequiredField: string,
  selectedDepartmentPOIds: number[],
  selectedDashboardProductIds: string[],
  selectedDashboardStatus: string[],
  editedDashboardPoSupplier: string,
  editedBudgetProductCategoriesId: number,
  editedBudgetForId: number,
  saveLocationsValidation: boolean,
  saveUsersValidation: boolean,
  showIsCreateInformationValidation: boolean,
  informationSettingsCompanyNameRequiredField: string,
  informationSettingsPhoneNumberRequiredField: string,
  informationSettingsPhoneNumberMatchError: string,
  informationSettingsAddressRequiredField: string,
  informationSettingsEmailRequiredField: string,
  informationSettingsEmailMatchError: string,
  informationSettingsPinCodeRequiredField: string,
  informationSettingsPinCodeMatchError: string,
  informationSettingsWebsiteMatchError: string,
  informationSettingsWebsiteRequiredField: string,
  informationSettingsGstNumberRequiredField: string,
  showIsCreateCurrencyValidation: boolean,
  showIsCreatePaymentTermsValidation: boolean,
  paymentTermNameRequiredField: string,
  paymentTermNameRequiredFieldEdit: string,
  paymentTermCreditDaysRequiredFieldEdit: string,
  paymentTermCreditDaysRequiredField: string,
  showIsEditPaymentTermsValidation: boolean,
  showIsUpdateTaxationValidation: boolean,
  showIsCreateTaxationValidation: boolean,
  taxNameRequiredField: string,
  taxPercentageRequiredField: string,
  editTaxNameRequiredField: string,
  editTaxPercentageRequiredField: string,
  paymentTermFoundOrNotMessage: string,

  currencySettingsCurrencyRequiredField: string,
  // dashboard state variables end
  purchaseRequisitionCreateMessage: string,
  purchaseOrderDraftMessage: string,
  purchaseRequisitionEditMessage: string,
  rfpCreateMessage: string,
  rfpCreateMessagedDueDate: string,
  rfpEditMessage: string,
  poCreateMessage: string,
  poEditMessage: string,
  receiptCreateMessage: string,
  receiptSelectFileTypeMessage: string,
  receiptEditMessage: string,
  supplierCreateMessage: string,
  supplierEditMessage: string,
  productCreateMessage: string,
  productEditMessage: string,
  budgetCreateMessage: string,
  budgetEditMessage: string,
  informationSettingsEditMessage: string,
  currencySettingsEditMessage: string,
  userCreateMessage: string,
  userDeleteMessage: string,
  categoryDeleteMessage:string,
  categoryAddMessage: string,
  locationCreateMessage: string,
  locationCreateMessagePr: string,
  deliveryOrDurationDateCheckMessagePr: string,
  locationDeleteMessage: string,
  locationUpdateMessage: string,
  paymentTermsCreateMessage: string,
  paymentTermsEditMessage: string,
  paymentTermsDeleteMessage: string,
  paymentTermsFetchMessage: string,
  taxationsCreateMessage: string,
  taxationEditMessage: string,
  taxationsDeleteMessage: string,
  productTypesList: any[],
  itemTypesList: any[],
  budgetForList: any[],
  productTypeSelectedId: string,
  budgetForSelectedId: string,
  editedProductTypeSelectedId: string,
  editedBudgetForSelectedId: string,
  productUnitPriceMatchError: string,
  productSupplierRequiredField: string,
  editedProductUnitPriceMatchError: string,
  budgetAmountMatchError: string,
  editedBudgetAmountMatchError: string,
  poGSTMatchError: string,
  supplierGSTMatchError: string,
  editedPoGSTMatchError: string,
  editedSupplierGSTMatchError: string,
  rolesList: any[],
  productList: any[],
  supplierFoundOrNotMessage: string,
  productsFoundOrNotMessage: string,
  startingFromDate: any,
  startingFromDateRequiredField: string,


  // new RFP states

  rfpUniqNumberRequiredError: string,
  rfpCreateScreenActiveTabs: number,
  isRfpCreationAndApiSuccessDone: boolean,
  rfpLocation: string,
  rfpLocationRequiredError: string,
  rfpDepartments: string,
  rfpDepartmentRequiredError: string,
  rfpSelectExistingSupplier: string[],
  rfpNonCatalougeCollectedItems: string,
  filteredRfpProducts: any[],
  rfpEmailToSupplierSentOrNotErrorMessage: string,
  isRfpMailSendConfirmed: boolean,
  isRfpMailSending : boolean,
  isRfpCreating: boolean,
  rfpFinalPdfDownloadOrNotMessage: string, 
  catchRfpEmailSendToSupplier: any,

  // new PO states
  poCreateScreenActiveTabs: number,
  poEmailToSupplierSentOrNotErrorMessage: string,
  poMailSendConfirmed: boolean,
  poMailSending : boolean,
  poCreating: boolean,
  poFinalPdfDownloadOrNotMessage: string, 
  poEmailSendToSupplier: any,
  poNonCatalougeCollectedItem: string,
  editedPoNonCatalougeCollectedItem: string,
  poEditing: boolean,
  poAttachment: string,
  filteredPoProducts: any[],
  catchPoIdForPdfCreate: any,
  catchPoMailOfSupplier: any,
  isPoCreationAndApiSuccessDone: boolean,
  isPoMailSendConfirmed: boolean,
  isPoMailSending : boolean,
  isPdfDownloaded: boolean,
  poSaveDraft: boolean,
  prPoCreateMessage: string,
  editedPoProductCatalouge: any[],
  editedPoProductNonCatalouge: any[],
  isEditedPdfDownloaded:boolean,
  editedPoFinalPdfDownloadOrNotMessage:string,
  isEditedPoMailSendConfirmed:boolean,
  editedFinalPdfDownloadOrNotMessage:string,
  editedPoEmailToSupplierSentOrNotErrorMessage:string,
  isEditedPoMailSending: boolean,
  catchEditedPoMailOfSupplier: string,
  editedPoPaymentTermList: any[],
  openEditedPoPdfSupplierScreenModal:boolean,
  openEditedPoSupplierMailSeccessModal:boolean,
  editedPoNonCatlogItems:any,
  isEditedPoCreationAndApiSuccessDone: boolean,
  editedPoSuccessOrNotMessage: string,
  isDeleteRecordPO: number,

  // new pr po states
  prPoCreateScreenActiveTabs:number,
  prPoSelectedRequisitionBudgetError:string,
  prPoSelectedRequisitionSupplierError:string,
  prPoSelectedRequisitionPaymentError:string,
  prPoSelectedRequisitionTaxationError:string,
  prPoSelectedRequisitionShippingError:string,
  prPoSelectedRequisitionBillingError:string,
  prPoSelectedRequisitionDepartment:any,
  prPoSelectedRequisitionNumber:string,
  customFromRequisitionCataItemData: any[],
  customFromRequisitionNonCataItemData: any[],
  prPoSelectedRequisitionCreationDate:string,
  prPoSelectedRequisitionDeliveryDate:string,
  prPoItemsNotFoundMessage: string,
  showPurchaseOrderProductsData:string,
  showIsCreatePrPurchaseOrderValidation: boolean,
  openPrPoPdfSupplierScreenModal: boolean,
  openPrPoSupplierMailSeccessModal: boolean,
  prPoCreating: boolean,
  isPrPoMailSendConfirmed: boolean,
  catchPrPoMailOfSupplier: any,
  isPrPoMailSending: boolean,
  prPoEmailToSupplierSentOrNotErrorMessage: string,
  isPrPoCreationAndApiSuccessDone: boolean,
  prPoFinalPdfDownloadOrNotMessage: string,
  catchPrPoIdForPdfCreate: any,
  prPoSaveDraft: boolean,





  // new reciept states

  receiptNumberUnique : any,
  receiptSupplierName : string,
  receiptSupplierNameRequiredField: string,
  receiptInvoiceNumber : string,
  receiptInvoiceNumberRequiredField : string,
  receiptAttachmentRequiredField: string,
  receiptSelectedPOtSearchTerm: string,
  locationExistOrNotErrorMessage: string,
  locationExistOrNotCheckingRequiredError:string,
  recieptsPOSelectionDataArr : any[],
  receiptCatelogueItemsStore: any[],
  receiptNonCatelogueItemsStore: any[],
  receiptNumberUniqueRequiredField: string,
  openReceiptCreateShowErrorModal: boolean,
  isReceiptCreating: boolean,
  receiptCatProductsIdCatch : string,
  receiptNonCatProductsIdCatch : string,
  

  //dash rfp new states

  dashboardItemsPerPage: number,
  dashboardNoOfPages:number,
  editedExistingRfpNumber : string,
  editedRfpLocation: string,
  editedRfpDepartment: string,
  editedRfpSelectExistingSupplier: string,
  editedRfpCatelogItems: any[],
  editedRfpNonCatelogItems:any[],
  editedRfpScreenActiveTabs : number,
  rfpUpdateMessage: string,
  editedFilteredRfpProducts: any[],
  openEditedRfpPdfSupplierScreenModal:boolean,
  openEditedRfpShowErrorModal: boolean,
  isEditedRfpUpdationApiSuccessDone: boolean,
  isEditedRfpMailSendConfirmed:boolean,
  isEditedRfpMailSending:boolean,
  isRfpUpdating:boolean,
  isEditedRFPFileDownloaded:boolean,
  editedRfpLocationRequiredError:string,
  editedRfpDepartmentRequiredError:string,
  editedRfpNonCatalougeCollectedItems:string,
  editedRfpEmailToSupplierSentOrNotErrorMessage : string,
  catchEditedRfpIdForPdfUpdation: any,
  openEditedRfpSupplierMailSeccessModal:boolean,
  catchEditedRfpEmailSendToSupplier:any,
  rfpCatelogDeletedItemsArr: any[],
  rfpNonCatelogDeletedItemsArr: any[],
  dashboardNoRecordMessage: string,
  editedRfpFinalPdfDownloadOrNotMessage: string,




  // new user setting states
  userSettingAddUserName: string,
  userSettingAddUserDesignation: string,
  userSettingAddUserEmailId: string,
  userSettingAddUserRole: string,

  userSettingNameRequiredError: string,
  userSettingDesignationRequiredError: string,
  userSettingEmailRequiredError: string,
  userSettingEmailInvalidError: string,
  userSettingRoleRequiredError: string,

  catchUserSettingsCreatedUserId:any,
  selectedUserPermissions: any[],
  selectAllPermissions : boolean,
  isUserCreating : boolean,
  editedSelectedUserPermissions: any[],
  editedSelectAllPermissions : boolean,
  openEditedUserPermissionsModal : boolean,
  userSettingSelectedRow: any,
  editedCatchIdForUserPermissionsUpdation: any,
  userSettingPermissionUpdatedMessage: string,
  editedCatchCoreUserCreatedAccountId: any,
  isUserSettingPermissionsUpdating: boolean,
  isUserPermissionsCreating : boolean,
  userPermissionsCreatedMessage : string,
  userPermissionMissingIdErrorMessage: string,
  createUserPermissionsValidation: boolean,

  userLoginDetailsCatch : any,
  noDashModulePermitMessage: any,
  isDashBtnDisabled : boolean,
  isCreateOrFindTabsDisabled : boolean,
  noCreateOrFindTabModulesPermitMessage: any,
  isMyReportsDisabled : boolean,
  isSettingsDisabled:boolean,
  noMyReportsModulesPermitMessage : string,
  noSettingsModulePermitMessage:string,
  supplierContactNumberErrorMessage: boolean,
  supplierContactNumberValidationError : string,
  supplierEmailInvalidPatternError: boolean,


  settingsCategoryFeildError:string,
  supplierPointContactNumberValidationError : string,
  supplierPointOfContactNumberErrorMessage: boolean,
  categoryUpdateMessage: string,

  informationSettingEmailInvalidPatternError : boolean,
  infoSettingContactNumberErrorMessage: boolean,
  informationSettingsPhoneNumberDigitLimitError: string,
  informationSettingsComapanyNameRequiredField: string,
  informationSettingsGSTNumberRequiredField: string,
  unitMeasurementSettingsDuplicasyMessage: string,
  categoryDuplicasyErrorMessage: string,
  filterMultiItemsState : string,
  itemsSearchBySpecificValue : string,
  anchorEl: any,
  isFilterSelectOpen : boolean,
  mainSearchKeysData : {
    lable: string;
    key: string;
    selected : boolean;
  }[],
  isOpenDropdownSelectSearch : boolean,
  isMenuForSearchOpen : boolean,
  mainSearchCOFItemsKeysData : {
    lable: string;
    key: string;
    selected : boolean;
  }[],
  cofItemsFilterMultiState : string,
  isCOFItemsMenuForSearchOpen : boolean,
  anchorElForCOFItems : any,
  itemsCofSearchBySpecificValue : string




  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  dashboardAllRecordGetApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getAllDashboardOptionsAPICallId: string = "";
  getAllPRStatusesAPICallId: string = "";
  createPurchaseRequisitionAPICallId: string = "";
  editPurchaseRequisitionAPICallId = "";
  dashboardAcceptRejectActionsPurchaseRequisitionAPICallId = ""
  showPurchaseRequisitionAPICallId: string = "";
  showSettingsLocationAPICall: string = "";
  showSettingsCategoryAPICall: string = "";
  showSettingsUnitMeasurementAPICall: string = "";
  deleteSettingUnitMeasurementAPICallId:string = "";
  getUserProfileAPICallId: string = "";
  getAllPurchaseRequisitionListAPICallId: string = "";
  getAllProductsListAPICallId: string = "";
  getSearchedProductAPICallId: string = "";
  callDownloadPdfApi: string = "";
  callMailSendApi: string = "";
  getAllRFPListAPICallId: string = "";
  getRfpSupplierMailSendApiCallId :string = "";
  createRfpAPICallId: string = "";
  showRfpAPICallId: string = "";
  editRfpAPICallId: string = "";
  getAllSuppliersListAPICallId: string = "";
  getAllPurchaseOrderListAPICallId: string = "";
  createPurchaseOrderAPICallId: string = "";
  editPurchaseOrderAPICallId: string = "";
  showPurchaseOrderAPICallId: string = "";
  getPoSupplierMailSendApiCallId : string = "";
  getPrPoSupplierMailSendApiCallId: string = "";
  createPurchaseRequisitionOrderAPICallId: string = "";
  getPoEditSupplierMailSendApiCallId: string = "";
  createCustomFromRequisitionPoAPICallId: string = "";
  getAllReceiptsAPICallID: string = "";
  createReceiptAPICallID: string = "";
  editReceiptAPICallID: string = "";
  showReceiptAPICallId: string = "";
  getCurrencyListAPICallId: string = "";
  getRfpFileAPICallId: string = "";
  createSupplierAPICallId: string = "";
  editSupplierAPICallID: string = "";
  showSupplierAPICallId: string = "";
  createProductAPICallId: string = "";
  editProductAPICallId: string = "";
  showProductAPICallId: string = "";
  getBudgetListAPICallId: string = "";
  createBudgetAPICallId: string = "";
  editBudgetAPICallId: string = "";
  editSettingsLocationAPICallId: string = "";
  editSettingsCategoryAPICallId: string = "";
  editSettingsUnitMeasurementAPICallId: string = "";
  showBudgetAPICallId: string = "";
  showSettingsLocationAPICallId: string = "";
  createInformationSettingsAPICallId: string = "";
  createLocationSettingsAPICall: string = "";
  createCategorySettingsAPICall: string = "";
  createUnitMeasurementSettingsAPICall: string = "";
  createCurrencySettingsAPICallId: string = "";
  getInformationSettingsAPICallId: string = "";
  getCurrencySettingsAPICallId: string = "";
  saveLocationsAPICallId: string = "";
  getLocationsAPICallId: string = "";
  deleteLocationAPICallId: string = "";
  getUsersAPICallId: string = "";
  deleteUserAPICallId: string = "";
  deleteCategoryAPICallId:string = ""
  saveUsersAPICallId: string = "";
  purchaseRequisitionPostApiId: string = "";
  getDepartmentListAPICallId: string = "";
  getSupplierListAPICallId: string = "";
  getPaymentListAPICallId: string = "";
  paymentTermsPostApiId: string = "";
  deletePaymentTermListAPICallId:string = ""
  editPaymentTermsApiCallId: string = "";
  getTaxationListAPICallId: string = "";
  createTaxationListAPICallId: string = "";
  editTaxationListAPICallId: string = "";
  getProductCategoriesAPICallId: string = "";
  getLocationDropdownListAPICallId: string = "";
  getStatusDropdownListAPICallId:string= "";
  getreceiptSelectedPOPopulatedDataAPICallId: string = "";
  getUserAccessPermissionAPICallId: string = "";
  saveUserAccessPermissionAPICallId: string = "";
  saveAsDraftPurchaseRequisitionAPICallId = "";
  saveAsDraftRfpAPICallId = "";
  saveAsDraftPurchaseOrderAPICallId = "";
  saveAsDraftReceiptAPICallID = "";
  saveAsDraftProductAPICallId = "";
  saveAsDraftBudgetAPICallId = "";
  getProductTypesListAPICallId = "";
  getBudgetForListAPICallId = "";
  getRolesListAPICallId = "";
  getProductListAPICallId = "";
  // dashboard message ids start
  editPurchaseRequisitionSaveAsDraftAPICallId = "";
  draftPurchaseRequisitionAPICallId = "";
  editRFPListSaveAsDraftCallId = "";
  editPurchaseOrderSaveAsDraftAPICallId = "";
  editBudgetSaveAsDraftAPICallId = "";
  editReceiptSaveAsDraftAPICallID = "";
  editSupplierSaveAsDraftAPICallID = "";
  getAllDepartmentsListAPICallId = "";
  dashboardEditPurchaseRequisitionAPICallId = "";
  editDashboardRfpAPICallId = "";
  dashboardEditPurchaseOrderAPICallId = "";
  dashboardAcceptRejectActionsPurchaseOrderAPICallId="";
  dashboardEditReceiptAPICallID = "";
  dashboardEditSupplierAPICallID = "";
  dashboardEditBudgetAPICallId = "";
  saveAsDraftSupplierAPICallId = "";
  // dashboard message ids end


  getEditedRfpSupplierMailSendApiCallId: string = "";
  getAllReceiptsPurchaseOrdersForSelectionAPICallId: string = "";

  getUserModulePermissionsAPICallId: string = "";



  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      openCreateNewModal: false,
      openNextPurchaseModal:false,
      openNextCustomFromRequisitionModal:false,
      openPdfModal:false,
      // dashboard state variables start
      dashboardMenuSelection: 'My Requisitions',
      userProfile: '',
      accountId: '',
      openDropdown: false,
      active: 0,
      dropDownItemValue: 'Purchase Requisition',
      dashboardShowHeader: false,
      currentPage: 1,
      currentPagePaymentTerms: 1,
      currentPageTaxations:1,
      currentPageUnitMeasure: 1,
      currentPageCategory: 1,
      currentPageSettingsLocation: 1,
      noOfPages: 1,
      noOfPagesPaymentTerms: 1,
      noOfPagesTaxations: 1,
      noOfPagesUnitMeasure: 1,
      noOfPagesCategory: 1,
      noOfPagesSettingsLocation: 1,
      dashboardRecordResults: [],
      rfpData: {},
      selectedDepartmentPRIds: [],
      departmentsList: [],
      editedPoSupplierId: 0,
      editedPoTaxId: '',
      editedPurchaseOrderTaxIdRequiredField: '',
      selectedDepartmentPOIds: [],
      selectedDashboardProductIds: [],
      selectedDashboardStatus: [],
      editedDashboardPoSupplier: '',
      editedBudgetProductCategoriesId: 0,
      editedBudgetForId: 0,
      // dashboard state variables end
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      tabValue: 0,
      subTabValue: 0,
      isRowSelected: true,
      subTabValueCreateOrFind: 0,
      checkedCreateOrFind: false,
      openAddRequisitionModal: false,
      openEditRequisitionModal: false,
      openShowRequisitionModal: false,
      prAcceptRejectActionStatusMessage: '',
      prAcceptRejectActionStatus: '',
      poAcceptRejectActionStatus: '',
      uploadedFileRequestForProposals: '',
      durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      deliveryDate: '',
      location: '',
      department: '',
      status: '',
      products: [],
      prProductAll: [],
      prProduct:[],
      productsSearch: [],
      productPurchaseRequisition: [],
      purchaseRequisitionList: [],
      editedPurchaseRequisition: {},
      editedPrNumberDefault: '',
      editedDurationDate: '',
      editedPRGen:"",
      editedDeliveryDate: '',
      editedRequester: '',
      editedLocation: '',
      editedCatalougeItemPr: [],
      editedCatalougeItemProductRequisition: [],
      editedNonCatalougeItemPr: [],
      editedDepartment: '',
      editSettingsGst: '',
      editedBillingLocation: "",
      editedShippingLocation: "",
      editedBudget: "",
      editedSupplier: "",
      editedPayment: "",
      editedTaxation: "",
      editedStatus: '',
      editedProducts: [],
      showPurchaseRequisition: {},
      isShowPurchaseRequisition: false,
      showSettingsLocation: [],
      showSettingsCategory: [],
      showSettingsUnitMeasurement: [],
      productsList: [],
      statusList: [],
      createPurchaseRequisitionLoading: false,
      editPurchaseRequisitionLoading: false,
      selectedProductIds: [],
      rfpList: [],
      rfpStatus: '',
      prStatusList:[],
      prNumberUnique: '',
      rfpNumberUnique:'',
      poNumberUnique: '',
      prPoNumberUnique: '',
      prProductId:'',
      prProductObj: {},
      prQuantity: '',
      prNonCatalougeItems: '',
      prEditNonCatalougeItems: '',
      prNonCatalougeItemsKeydown: '',
      rfpCurrency: '',
      addRfpProducts: [],
      addRfpProductsNonCatItemText: '',
      //rfpCreationDate: '',
      rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      rfpDueDate: '',
      rfpAddress: '',
      rfpDesiredDeliveryDate: '',
      rfpEmailOfSupplier: '',
      rfpEmailOfSuppliers: [],
      rfpExistingProduct: '',
      rfpAttachment: '',
      rfpSelectExistingSuppliers: [],
      addItemSelectExistingSuppliers: [],
      addItemSupplier: '',
      addItemSupplierMultiple: [],
      editedItemSupplierMultiple: [],
      editedSupplierMultipleRequiredField: '',
      addItemSupplierNoUnique: '',
      prCatalougeItemSelectedRow: '',
      rfpFileAttachment: '',
      selectedRow: '',
      rfpSelectedRow: '',
      openAddRFPModal: false,
      openNextRFPMainCreateFieldsModal: false,
      catchRfpIdForPdfCreate: '',
      openShowRFPModal: false,
      openEditRFPModal: false,
      openRfpPdfSupplierScreenModal:false,
      openRfpPdfSuccessModal:false,
      openRfpSupplierMailSeccessModal:false,
      openPoPdfSupplierScreenModal:false,
      openPoPdfSuccessModal:false,
      openPoSupplierMailSeccessModal:false,
      showRfp: {},
      dashboardOptions: [],
      editRfp: {},
      editedRfpId: '',
      editedRfpStatus: '',
      editedRfpCurrency: '',
      editedRfpCreationDate: '',
      editedRfpDueDate: '',
      editedRfpAddress: '',
      editedRfpDesiredDeliveryDate: '',
      editedRfpEmailOfSupplier: '',
      editedrfpMultiEmailOfSuppliersStoreArr: [],
      editedRfpExistingProduct: '',
      editedRfpAttachment: '',
      editedRfpFileAttachment: '',
      editedRfpSupplierEmailMatchError: '',
      isFileEdited: false,
      showIsEditRFPValidation: false,
      editedRfpStatusRequiredError: '',
      editedRfpCurrencyRequiredError: '',
      editedRfpCreationDateRequiredError: '',
      editedRfpDueDateRequiredError: '',
      unitMeasurementDeleteMessage:"",
      unitMeasurementCreateMessage: '',
      unitMeasurementUpdateMessage: '',
      editedRfpAddressRequiredError: '',
      editedRfpDesiredDeliveryDateRequiredError: '',
      editedRfpExistingProductRequiredError: '',
      editedRfpAttachmentTypeRequiredError: '',
      editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: '',
      editedRfpFileAttachmentRequiredError: '',
      openEditRfpShowErrorModal: false,
      totalPagesPurchaseRequisition: 0,
      currentPagePurchaseRequisition: 1,
      totalPagesPurchaseRequisitionPO: 0,
      currentPagePurchaseRequisitionPO: 1,
      itemsPerPage: 6,
      itemsPerPageProduct: 2,
      suppliersList: [],
      totalPagesRfp: 0,
      currentPageRfp: 1,
      showIsCreateRFPValidation: false,
      showIsAddRfpProductsValidation:false,
      addRfpProductsRequiredError : '',
      rfpStatusRequiredError: '',
      rfpCurrencyRequiredError: '',
      rfpCreationDateRequiredError: '',
      rfpDueDateRequiredError: '',
      rfpAddressRequiredError: '',
      rfpDesiredDeliveryDateRequiredError: '',
      rfpExistingProductRequiredError: '',
      rfpAttachmentTypeRequiredError: '',
      rfpAttachmentTypeMatchError: '',
      rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: '',
      rfpFileAttachmentRequiredError: '',
      rfpSupplierEmailMatchError: '',
      openCreateRfpShowErrorModal: false,
      openCreatePoShowErrorModal: false,
      openCreatePrPoShowErrorModal: false,
      showIsCreatePurchaseRequisitionValidation: false,
      durationDateRequiredError: '',
      deliveryDateRequiredError: '',
      locationRequiredError: '',
      locationAddressRequiredError: '',
      locationIdentityCodeRequiredError: '',
      locationGstRequiredError: '',
      departmentRequiredError: '',
      prStatusListRequiredError: '',
      productsRequiredError: '',
      prQuantityRequiredError: '',
      prNonCatalougeItemsRequiredError: '',
      showIsEditPurchaseRequisitionValidation: false,
      editedDurationDateRequiredError: '',
      editedDeliveryDateRequiredError: '',
      editedLocationRequiredError: '',
      editedDepartmentRequiredError: '',
      editedCatalougeItemPrRequiredError: '',
      editedNonCatalougeItemPrRequiredError: '',
      editedStatusRequiredError: '',
      editedProductsRequiredError: '',
      rfpSuppliers: [],
      rfpEditedSuppliers: [],
      selectedSupplierIds: [],
      durationDateType: 'date',
      deliveryDateType: 'date',
      editedDurationDateType: 'text',
      editedDeliveryDateType: 'text',
      rfpCreationDateType: 'text',
      rfpDueDateType: 'text',
      rfpDesiredDeliveryDateType: 'text',
      editedRfpCreationDateType: 'text',
      editedRfpDueDateType: 'text',
      editedRfpDesiredDeliveryDateType: 'text',
      showDefaultMenuValue: false,
      purchaseOrdersList: [],
      totalPagesPurchaseOrder: 0,
      currentPagePurchaseOrder: 1,
      purchaseOrderSelectedRow: '',
      openAddPurchaseOrderModal: false,
      openShowPurchaseOrderModal: false,
      selectPoOptions: '',
      poId: '',
      poLocation: '',
      poSupplier: '',
      poOrderDate: '',
      poFobPoint: '',
      poShippingAddress: '',
      prPoShippingAddressOptional: '',
      poShippingAddressOptional: '',
      poBillingAddress: '',
      //poTaxId: '',
      poGstNumber: '',
      poModeOfTransit: '',
      poProduct: [],
      poCommentForPO: '',
      poAmountCalculation: '',
      poDepartment: '',
      poBudget: '',
      poComment: '',
      editedPoId: '',
      editedPoNumber: '',
      editedPoLocation: '',
      editedPoSupplier: '',
      editedPoPaymentTermsName: '',
      poEditScreenActiveTabs: 0,
      poViewScreenActiveTabs:0,
      editedPoOrderDate: '',
      editedPoDeliveryDate: '',
      editedPoFobPoint: '',
      editedPoShippingAddress: '',
      editedPoShippingAddressOptional: '',
      editedPoBillingAddress: '',
      editedPoGstNumber: '',
      editedPoModeOfTransit: '',
      editedPoProduct: [],
      editedPoCommentForPO: '',
      editedPoAmountCalculation: '',
      editedPoDepartment: '',
      editedPoBudget: '',
      editedPoComment: '',
      poOrderDateType: 'text',
      openEditPurchaseOrderModal: false,
      editedPoOrderDateType: 'text',
      showPurchaseOrder: {},
      openPurchaseRequisitionListModal: false,
      customFromRequisitionSelectedRow: false,
      customFromRequisitionPoId: '',
      customFromRequisitionPoSupplier: '',
      customFromRequisitionPoOrderDate: '',
      customFromRequisitionPoFobPoint: '',
      customFromRequisitionPoShippingAddress: '',
      customFromRequisitionPoBillingAddress: '',
      customFromRequisitionPoTaxId: '',
      customFromRequisitionPoModeOfTransit: '',
      customFromRequisitionPoCommentForPO: '',
      customFromRequisitionPoAmountCalculation: '',
      customFromRequisitionPoBudget: '',
      customFromRequisitionPoComment: '',
      customFromRequisitionPoOrderDateType: "text",
      openCustomFromRequisitionPoModal: false,
      openCustomFromRequisitionCreatePoModal: false,
      customFromRequisitionSelectedId: '',
      receiptsList: [],
      totalPagesReceipt: 0,
      currentPageReceipt: 1,
      receiptSelectedRow: '',
      openAddReceiptModal: false,
      openEditReceiptModal: false,
      receiptSelectedPo: '',
      receiptQuantityReceived: '',
      receiptQuantityRequested: '',
      receiptReceiptDate: '',
      receiptComments: '',
      receiptAttachmentFile: '',
      editedReceiptSelectedPo: '',
      editedReceiptQuantityReceived: '',
      editedReceiptQuantityRequested: '',
      editedReceiptReceiptDate: '',
      editedReceiptComments: '',
      editedReceiptAttachmentFile: '',
      receiptDateType: 'text',
      editedReceiptDateType: 'text',
      receipt: {},
      isReceiptFileEdited: false,
      editedReceiptId: '',
      openShowReceiptModal: false,
      showReceipt: {},
      currencyList: [],
      purchaseOrderLocationRequiredField: '',
      purchaseOrderSupplierRequiredField: '',
      purchaseOrderPaymentTermsRequiredField: '',
      purchaseOrderTaxationNameRequiredField: '',
      purchaseOrderOrderDateRequiredField: '',
      purchaseOrderFobPointRequiredField: '',
      purchaseOrderShippingAddressRequiredField: '',
      purchaseOrderBillingAddressRequiredField: '',
      purchaseOrderGstNumberRequiredField: '',
      purchaseOrderDeliveryDateRequiredField: '',
      purchaseOrderDeliveryDateErrorFormat: '',
      purchaseOrderModeOfTransitRequiredField: '',
      purchaseOrderProductsRequiredField: '',
      purchaseOrderCommentsForPoRequiredField: '',
      purchaseOrderAmountCalculationRequiredField: '',
      purchaseOrderDepartmentRequiredField: '',
      purchaseOrderBudgetRequiredField: '',
      purchaseOrderCommentRequiredField: '',
      editedPurchaseOrderLocationRequiredField: '',
      editedPurchaseOrderSupplierRequiredField: '',
      editedPurchaseOrderOrderDateRequiredField: '',
      editedPurchaseOrderDeliveryDateRequiredField: '',
      editedPurchaseOrderPaymentTermsRequiredField: '',
      editedPurchaseOrderFobPointRequiredField: '',
      editedPurchaseOrderShippingAddressRequiredField: '',
      editedPurchaseOrderBillingAddressRequiredField: '',
      editedPurchaseOrderGstNumberRequiredField: '',
      editedPurchaseOrderModeOfTransitRequiredField: '',
      editedPurchaseOrderProductsRequiredField: '',
      editedPurchaseOrderCommentsForPoRequiredField: '',
      editedPurchaseOrderAmountCalculationRequiredField: '',
      editedPurchaseOrderDeliveryDateErrorFormat: '',
      editedPurchaseOrderDepartmentRequiredField: '',
      editedPurchaseOrderBudgetRequiredField: '',
      editedPurchaseOrderCommentRequiredField: '',
      customFromRequisitionPoFobPointRequiredField: '',
      customFromRequisitionPoShippingAddressRequiredField: '',
      customFromRequisitionPoBillingAddressRequiredField: '',
      customFromRequisitionPoTaxIdRequiredField: '',
      customFromRequisitionPoModeOfTransitRequiredField: '',
      customFromRequisitionPoSupplierRequiredField: '',
      customFromRequisitionPoOrderDateRequiredField: '',
      customFromRequisitionPoCommentForPORequiredField: '',
      customFromRequisitionPoAmountCalculationRequiredField: '',
      customFromRequisitionPoBudgetRequiredField: '',
      customFromRequisitionPoCommentRequiredField: '',
      showIsCreatePurchaseOrderValidation: false,
      showIsEditPurchaseOrderValidation: false,
      showIsCreateCustomFromRequisitionValidation: false,
      receiptQuantityReceivedRequiredField: '',
      receiptQuantityRequestedRequiredField: '',
      receiptReceiptDateRequiredField: '',
      receiptCommentsRequiredField: '',
      receiptAttachmentFileRequiredField: '',
      editedReceiptQuantityReceivedRequiredField: '',
      editedReceiptQuantityRequestedRequiredField: '',
      editedReceiptReceiptDateRequiredField: '',
      editedReceiptCommentsRequiredField: '',
      editedReceiptAttachmentFileRequiredField: '',
      showIsCreateReceiptValidation: false,
      showIsEditReceiptValidation: false,
      openAddRFPDownloadFiles: false,
      dashboardOptionSelected: 'Purchase Requisition',
      totalPagesSupplier: 0,
      currentPageSupplier: 1,
      openAddSupplierModal: false,
      supplierSupplierName: '',
      supplierUniqueCode: '',
      supplierTermsOfPayment: '',
      paymentTermsNameSuppliers: [],
      supplierEmailAddress: '',
      supplierContactNumber: '',
      supplierPointOfContact: '',
      supplierAddressOfSupplierCompany: '',
      supplierGSTNumber: '',
      supplierStateProvince: '',
      supplierSupplierLocation: '',
      editedSupplierSupplierName: '',
      editedSupplierUniqueCode: '',
      editedSupplierTermsOfPayment: '',
      editedSupplierEmailAddress: '',
      editedSupplierContactNumber: '',
      editedSupplierPointOfContact: '',
      editedSupplierAddressOfSupplierCompany: '',
      editedSupplierGSTNumber: '',
      editSupplierStateProvince: '',
      supplierZipCodePinCode: '',
      editedSupplierSupplierLocation: '',
      editedSupplierStateProvince: '',
      editedSupplierZipCodePinCode: '',
      editedSupplierPaymentList: [],
      supplierSelectedRow: '',
      openEditSupplierModal: false,
      editedSupplierId: '',
      showSupplier: {},
      openShowSupplierModal: false,
      currentPageProduct: 1,
      totalPagesProduct: 0,
      productSelectedRow: '',
      productName: '',
      productPartId: '',
      productDescription: '',
      productUnitMeasurement: '',
      productUnitPrice: '',
      productCategory: '',
      productType: '',
      editedProductName: '',
      editedAddNotes: '',
      editedProductPartId: '',
      editedProductDescription: '',
      editedProductUnitMeasurement: '',
      editedProductUnitPrice: '',
      editedProductCategory: '',
      editedProductType: '',
      openAddProductModal: false,
      openEditProductModal: false,
      openShowProductModal: false,
      editedProductId: '',
      showProduct: {},
      budgetName: '',
      budgetFor: '',
      budgetPeriod: '',
      budgetAmount: '',
      budgetComment: '',
      budgetProductCategories: '',
      editedBudgetId: '',
      editedBudgetName: '',
      editedBudgetStartingDate: '',
      editedBudgetFor: '',
      editedBudgetPeriod: '',
      editedBudgetAmount: '',
      editedBudgetComment: '',
      editedBudgetProductCategories: '',
      budgetList: [],
      totalPagesBudget: 0,
      currentPageBudget: 1,
      budgetSelectedRow: '',
      openAddBudgetModal: false,
      openEditBudgetModal: false,
      openShowBudgetModal: false,
      openShowSettingLocationModal: false,
      openEditSettingLocationModal: false,
      openEditSettingCategoryModal: false,
      openEditSettingUnitMeasurementModal: false,
      showBudget: {},
      showSettingsLocationSingleData: {},
      showSettingsLocationAPICallId: {},
      productNameRequiredField: '',
      productPartIdRequiredField: '',
      productDescriptionRequiredField: '',
      productUnitMeasurementRequiredField: '',
      productUnitPriceRequiredField: '',
      productCategoryRequiredField: '',
      productTypeRequiredField: '',
      editedProductNameRequiredField: '',
      editedProductPartIdRequiredField: '',
      editedProductDescriptionRequiredField: '',
      editedProductUnitMeasurementRequiredField: '',
      editedProductUnitPriceRequiredField: '',
      editedProductCategoryRequiredField: '',
      editedProductTypeRequiredField: '',
      supplierNameRequiredField: '',
      supplierUniqueCodeRequiredField: '',
      supplierTermsOfPaymentRequiredField: '',
      supplierEmailAddressRequiredField: '',
      supplierEmailAddressEmailMatchError: '',
      supplierContactNumberRequiredField: '',
      supplierContactNumberMatchError: '',
      supplierPointOfContactRequiredField: '',
      supplierAddressOfSupplierCompanyRequiredField: '',
      supplierGstNumberRequiredField: '',
      supplierStateProvinceRequiredField: '',
      supplierZipCodePinCodeRequiredField: '',
      supplierLocationRequiredField: '',
      editedSupplierNameRequiredField: '',
      editedSupplierUniqueCodeRequiredField: '',
      editedSupplierTermsOfPaymentRequiredField: '',
      editedSupplierEmailAddressRequiredField: '',
      editedSupplierEmailAddressEmailMatchError: '',
      editedSupplierContactNumberRequiredField: '',
      editedSupplierContactNumberMatchError: '',
      editedSupplierPointOfContactRequiredField: '',
      editedSupplierAddressOfSupplierCompanyRequiredField: '',
      editedSupplierGstNumberRequiredField: '',
      editedSupplierZipCodePinCodeRequiredField: '',
      editedSupplierStateProvinceRequiredField: '',
      editedSupplierLocationRequiredField: '',
      budgetNameRequiredField: '',
      budgetForRequiredField: '',
      budgetPeriodRequiredField: '',
      budgetAmountRequiredField: '',
      budgetCommentRequiredField: '',
      budgetProductCategoriesRequiredField: '',
      editedBudgetNameRequiredField: '',
      editedBudgetForRequiredField: '',
      editedBudgetPeriodRequiredField: '',
      editedBudgetAmountRequiredField: '',
      editedBudgetCommentRequiredField: '',
      editedBudgetProductCategoriesRequiredField: '',
      showIsCreateSupplierValidation: false,
      showIsEditSupplierValidation: false,
      showIsCreateProductValidation: false,
      showIsEditProductValidation: false,
      showIsCreateBudgetValidation: false,
      showIsEditBudgetValidation: false,
      customFromRequisitionSelectMessage: '',
      showSettings: true,
      showInformation: false,
      showCurrencySettings: false,
      showLocationSettings: false,
      showUsers: false,
      informationSettingsCompanyName: '',
      settingsLocation: '',
      settingsAddress: '',
      settingsCategory: '',
      settingsCategoryRequiredField: '',
      categorySettingCreatePost: false,
      settingsUnitMeasurement: '',
      settingsUnitMeasurementRequiredField: '',
      unitMeasurementSettingPost: false,
      settingsIdentificationCode: '',
      settingsGst: '',
      editSettingsId: '',
      createSettingsCategoryMessage:'',
      editSettingsCategoryId: '',
      editSettingsLocation: '',
      editSettingsCategorySL: '',
      editSettingsUnitMeasurementId: '',
      editSettingsUnitMeasurementSL: '',
      editSettingsCategoryName: '',
      editSettingsUnitMeasurement: '',
      editSettingsAddress: '',
      editSettingsIdentificationCode: '',
      informationSettingsPhoneNumber: '',
      informationSettingsAddress: '',
      informationSettingsEmail: '',
      informationSettingsPinCode: '',
      informationSettingsWebsite: '',
      informationSettingsGstNumber: '',
      currencySettingsCurrency: '',
      enableEditInformationSettings: false,
      enableEditCurrencySettings: false,
      informationSettings: {},
      currencySettings: {}, 
      locationSettingsNameOfLocation: '',
      locationSettingsAddress: '',
      locationSettingsLocationIdentificationCode: '',
      locations: [{
        name_of_location: '',
        adderss: '',
        location_identification_code: '',
        name_of_location_validation: '',
        address_validation: '',
        location_identification_code_validation: '',
      }],
      selectedCurrencyCurrencySettings: '',
      openAddLocationsModal: false,
      locationsList: [],
      showUserSettings: false,
      showCategorySettings: false,
      showUnitMeasureSettings: false,
      openAddUserModal: false,
      usersList: [],
      userSettingsName: '',
      userSettingsRoles: '',
      userSettingsDesignation: '',
      userSettingsEmailId: '',
      userSettingsDepartment: '',
      productCategoriesList: [],
      departmentList: [],
      budgetListing: [],
      supplierList:[],
      getPaymentTermListSupplier: [],
      paymentTermsList: [],
      taxations : [],
      showPaymentTermsSettings: false,
      showTaxationSettings: false,
      paymentTermsName: '',
      PaymentTermDeleteMessage: '',
      creditDays: '',
      taxName: '',
      taxPercentage: '',
      editPaymentTermsName: '',
      editCreditDays: '',
      paymentTermId: '',
      editTaxName: '',
      editTaxPercentage: '',
      taxationId:'',
      showEditPaymentTermsSettingField: false,
      showEditPaymentTermsSettingBtn: false,
      showEditTaxationEditField: false,
      showEditTaxationEditBtn: false,
      showEditTaxationSettingBtn: true,
      rfpAttachmentDropdownDisplay: false,
      locationDropdownList: [],
      purchaseRequisitionSearchLocation: '',
      receiptSelectedPOPopulatedData: {},
      rfpEmailOfSupplierEmailPatternError: false,
      editedRfpEmailOfSupplierEmailPatternError: false,
      users: [{
        name: "",
        designation: "",
        role: "",
        email: "",
        department: "",
        name_validation: "",
        designation_validation: "",
        role_validation: "",
        email_validation: "",
        email_matching_error: "",
        department_validation: ""
      }],
      receiptAttachment: "",
      receiptAttachmentDropdownDisplay: false,
      editedReceiptAttachment: "",
      editedReceiptAttachmentDropdownDisplay: false,
      receiptSelectedPoRequiredField: '',
      rfpEmailOfSupplierEmailDuplicateError: false,
      editedRfpEmailOfSupplierEmailDuplicateError: false,
      editedReceiptAttachmentTypeMatchError: "",
      openUserPermissionsModal: false,
      userPermissionsList: [
        {
          id: 1,
          title: "Purchase Requisition",
          status: true,
        },
        {
          id: 2,
          title: "Purchase Requisitions Approval",
          status: true,
        },
        {
          id: 3,
          title: "Request for Proposals",
          status: true,
        },
        {
          id: 4,
          title: "Request for Proposals Approval",
          status: true,
        },
        {
          id: 5,
          title: "Purchase Orders",
          status: true,
        },
        {
          id: 6,
          title: "Purchase Orders Approval",
          status: true,
        },
        {
          id: 7,
          title: "Receipts",
          status: true,
        },
        {
          id: 8,
          title: "Receipts Approval",
          status: true,
        },
        {
          id: 9,
          title: "Reports",
          status: true,
        },
        {
          id: 10,
          title: "Budgets",
          status: true,
        },
        {
          id: 11,
          title: "Warehouse Manager",
          status: true,
        },
        {
          id: 12,
          title: "Supplier & Item",
          status: true,
        },
      
      ],
      userPermissionsList1: {
        purchase_requisitions: true,
        purchase_requisitions_approval: true,
        request_for_proposals: true,
        request_for_proposals_approval: true,
        purchase_orders: true,
        purchase_orders_approval: true,
        receipts: true,
        receipts_approval: true,
        reports: true,
        budgets: true,
        warehouse_manager: true,
        supplier_and_item: true
      },
      dateValidationError: "",
      alreadyCreatedUsers: [],
      userIdForUserAccessPermission: "",
      saveLocationsValidation: false,
      saveUsersValidation: false,
      showIsCreateInformationValidation: false,
      informationSettingsCompanyNameRequiredField: '',
      informationSettingsPhoneNumberRequiredField: '',
      informationSettingsPhoneNumberMatchError: '',
      informationSettingsAddressRequiredField: '',
      informationSettingsEmailRequiredField: '',
      informationSettingsEmailMatchError: '',
      informationSettingsPinCodeRequiredField: '',
      informationSettingsPinCodeMatchError: '',
      informationSettingsWebsiteMatchError: '',
      informationSettingsWebsiteRequiredField: '',
      informationSettingsGstNumberRequiredField: '',
      showIsCreateCurrencyValidation: false,
      showIsCreatePaymentTermsValidation: false,
      paymentTermNameRequiredField: '',
      paymentTermCreditDaysRequiredField: '',
      paymentTermNameRequiredFieldEdit: '',
      paymentTermCreditDaysRequiredFieldEdit: '',
      showIsEditPaymentTermsValidation: false,
      showIsUpdateTaxationValidation: false,
      showIsCreateTaxationValidation: false,
      taxNameRequiredField: '',
      taxPercentageRequiredField: '',
      editTaxNameRequiredField:'',
      editTaxPercentageRequiredField:'',
      paymentTermFoundOrNotMessage: '',
      currencySettingsCurrencyRequiredField: '',
      purchaseRequisitionCreateMessage: '',
      purchaseOrderDraftMessage: '',
      purchaseRequisitionEditMessage: '',
      rfpCreateMessage: '',
      rfpCreateMessagedDueDate: '',
      rfpEditMessage: '',
      poCreateMessage: '',
      poEditMessage: '',
      receiptCreateMessage: '',
      receiptSelectFileTypeMessage: '',
      receiptEditMessage: '',
      supplierCreateMessage: '',
      supplierEditMessage: '',
      productCreateMessage: '',
      productEditMessage: '',
      budgetCreateMessage: '',
      budgetEditMessage: '',
      informationSettingsEditMessage: '',
      currencySettingsEditMessage: '',
      userCreateMessage: '',
      userDeleteMessage: '',
      categoryDeleteMessage: '',
      categoryAddMessage: '',
      locationCreateMessage: '',
      locationCreateMessagePr: '',
      deliveryOrDurationDateCheckMessagePr: '',
      locationDeleteMessage: '',
      locationUpdateMessage: '',
      paymentTermsCreateMessage: '',
      paymentTermsEditMessage: '',
      paymentTermsDeleteMessage: '',
      paymentTermsFetchMessage: '',
      taxationsCreateMessage: '',
      taxationEditMessage: '',
      taxationsDeleteMessage: '',
      productTypesList: [{id:1, name:"Product"}, {id:2, name: "Service"}],
      itemTypesList: [{id:1, name:"Product"}, {id:2, name: "Service"}],
      budgetForList: [],
      productTypeSelectedId: '',
      budgetForSelectedId: '',
      editedProductTypeSelectedId: '',
      editedBudgetForSelectedId: '',
      productUnitPriceMatchError: '',
      productSupplierRequiredField: '',
      editedProductUnitPriceMatchError: '',
      budgetAmountMatchError: '',
      editedBudgetAmountMatchError: '',
      poGSTMatchError: '',
      supplierGSTMatchError: '',
      editedPoGSTMatchError: '',
      editedSupplierGSTMatchError: '',
      rolesList: [],
      productList: [],
      supplierFoundOrNotMessage: '',
      productsFoundOrNotMessage: '',
      startingFromDate: '',
      startingFromDateRequiredField: '',

      // new RFP states default

      rfpUniqNumberRequiredError: '',
      rfpCreateScreenActiveTabs: 0,
      isRfpCreationAndApiSuccessDone: false,
      rfpLocation:'',
      rfpLocationRequiredError:  '',
      rfpDepartments: '',
      rfpDepartmentRequiredError: '',
      rfpSelectExistingSupplier: [],
      rfpNonCatalougeCollectedItems:'',
      filteredRfpProducts: [],
      rfpEmailToSupplierSentOrNotErrorMessage: '',
      isRfpMailSendConfirmed: false,
      isRfpMailSending : false,
      isRfpCreating: false,
      rfpFinalPdfDownloadOrNotMessage: '',
      catchRfpEmailSendToSupplier: '',

      // new PO states default
      poCreateScreenActiveTabs: 0,
      poEmailToSupplierSentOrNotErrorMessage: '',
      poMailSendConfirmed: false,
      poMailSending : false,
      poCreating: false,
      poFinalPdfDownloadOrNotMessage: '', 
      poEmailSendToSupplier: '',
      poNonCatalougeCollectedItem: '',
      editedPoNonCatalougeCollectedItem: '',
      poEditing: false,
      poAttachment: '',
      filteredPoProducts: [],
      catchPoIdForPdfCreate: '',
      catchPoMailOfSupplier: '',
      isPoCreationAndApiSuccessDone: false,
      isPoMailSendConfirmed: false,
      isPoMailSending : false,
      isPdfDownloaded: false,
      poSaveDraft: false,
      prPoCreateMessage: '',
      editedPoProductCatalouge: [],
      editedPoProductNonCatalouge: [],
      editedPoNonCatlogItems: "",
      isEditedPdfDownloaded:false,
      editedPoFinalPdfDownloadOrNotMessage:'',
      isEditedPoMailSendConfirmed:false,
      editedFinalPdfDownloadOrNotMessage:'',
      editedPoEmailToSupplierSentOrNotErrorMessage:'',
      openEditedPoSupplierMailSeccessModal:false,
      isEditedPoCreationAndApiSuccessDone:false,
      isEditedPoMailSending: false,
      catchEditedPoMailOfSupplier:'',
      editedPoPaymentTermList: [],
      openEditedPoPdfSupplierScreenModal: false,
      editedPoSuccessOrNotMessage: '',
      isDeleteRecordPO: 0,

      // new pr po states
      prPoCreateScreenActiveTabs:0,
      prPoSelectedRequisitionBudgetError:'',
      prPoSelectedRequisitionSupplierError:'',
      prPoSelectedRequisitionPaymentError:'',
      prPoSelectedRequisitionTaxationError:'',
      prPoSelectedRequisitionShippingError:'',
      prPoSelectedRequisitionBillingError:'',
      prPoSelectedRequisitionDepartment:'',
      prPoSelectedRequisitionNumber:'',
      customFromRequisitionCataItemData: [],
      customFromRequisitionNonCataItemData: [],
      prPoSelectedRequisitionCreationDate:'',
      prPoSelectedRequisitionDeliveryDate:'',
      prPoItemsNotFoundMessage: '',
      showPurchaseOrderProductsData:'',
      showIsCreatePrPurchaseOrderValidation: false,
      openPrPoPdfSupplierScreenModal: false,
      openPrPoSupplierMailSeccessModal: false,
      prPoCreating: false,
      isPrPoMailSendConfirmed: false,
      catchPrPoMailOfSupplier: '',
      isPrPoMailSending: false,
      prPoEmailToSupplierSentOrNotErrorMessage: '',
      isPrPoCreationAndApiSuccessDone: false,
      prPoFinalPdfDownloadOrNotMessage: '',
      catchPrPoIdForPdfCreate: '',
      prPoSaveDraft: false,




      // new receipt states default

      receiptNumberUnique: '',
      receiptSupplierName: '',
      receiptSupplierNameRequiredField : '',
      receiptInvoiceNumber: '',
      receiptInvoiceNumberRequiredField: '',
      receiptAttachmentRequiredField: '',
      receiptSelectedPOtSearchTerm: '',
      locationExistOrNotErrorMessage: '',
      locationExistOrNotCheckingRequiredError: '',
      recieptsPOSelectionDataArr: [],
      receiptCatelogueItemsStore: [],
      receiptNonCatelogueItemsStore: [],
      receiptNumberUniqueRequiredField: '',
      openReceiptCreateShowErrorModal: false,
      isReceiptCreating: false,
      receiptCatProductsIdCatch: '',
      receiptNonCatProductsIdCatch: '',

      // new dash rfp states default

      dashboardItemsPerPage: 6,
      dashboardNoOfPages:1,
      editedExistingRfpNumber:'',
      editedRfpLocation: '',
      editedRfpDepartment: '',
      editedRfpSelectExistingSupplier: '',
      editedRfpCatelogItems: [],
      editedRfpNonCatelogItems:[],
      editedRfpScreenActiveTabs : 0,
      rfpUpdateMessage: '',
      editedFilteredRfpProducts: [],
      openEditedRfpPdfSupplierScreenModal: false,
      openEditedRfpShowErrorModal: false,
      isEditedRfpUpdationApiSuccessDone: false,
      isEditedRfpMailSendConfirmed:false,
      isEditedRfpMailSending:false,
      isRfpUpdating:false,
      isEditedRFPFileDownloaded: false,
      editedRfpLocationRequiredError: '',
      editedRfpDepartmentRequiredError: '',
      editedRfpNonCatalougeCollectedItems: '',
      editedRfpEmailToSupplierSentOrNotErrorMessage: '',
      catchEditedRfpIdForPdfUpdation:'',
      openEditedRfpSupplierMailSeccessModal: false,
      catchEditedRfpEmailSendToSupplier: '',
      rfpCatelogDeletedItemsArr: [],
      rfpNonCatelogDeletedItemsArr: [],
      dashboardNoRecordMessage: '',
      editedRfpFinalPdfDownloadOrNotMessage: '',



      // new user setting state defaults
      userSettingAddUserName: '',
      userSettingAddUserDesignation: '',
      userSettingAddUserEmailId: '',
      userSettingAddUserRole: '',
      userSettingNameRequiredError: '',
      userSettingDesignationRequiredError: '',
      userSettingEmailRequiredError: '',
      userSettingEmailInvalidError: '',
      userSettingRoleRequiredError: '',
      catchUserSettingsCreatedUserId : '',
      selectedUserPermissions: [],
      selectAllPermissions: false,
      isUserCreating: false,
      editedSelectedUserPermissions: [],
      editedSelectAllPermissions : false,
      openEditedUserPermissionsModal : false,
      userSettingSelectedRow: '',
      editedCatchIdForUserPermissionsUpdation : '',
      userSettingPermissionUpdatedMessage:'',
      editedCatchCoreUserCreatedAccountId: '',
      isUserSettingPermissionsUpdating: false,
      isUserPermissionsCreating: false,
      userPermissionsCreatedMessage: '',
      userPermissionMissingIdErrorMessage: '',
      createUserPermissionsValidation: false,

      userLoginDetailsCatch : {},
      noDashModulePermitMessage: '',
      isDashBtnDisabled : false,
      isCreateOrFindTabsDisabled : false,
      noCreateOrFindTabModulesPermitMessage: false,
      isMyReportsDisabled : false,
      isSettingsDisabled : false,
      noMyReportsModulesPermitMessage: '',
      noSettingsModulePermitMessage: '',
      supplierContactNumberErrorMessage:  false,
      supplierContactNumberValidationError : '',
      supplierEmailInvalidPatternError : false,
      settingsCategoryFeildError:'',
  supplierPointContactNumberValidationError : '',
  supplierPointOfContactNumberErrorMessage : false,
  categoryUpdateMessage : "",
  informationSettingEmailInvalidPatternError : false,
  infoSettingContactNumberErrorMessage : false,
  informationSettingsPhoneNumberDigitLimitError: '',
  informationSettingsComapanyNameRequiredField: '',
  informationSettingsGSTNumberRequiredField: '',
  unitMeasurementSettingsDuplicasyMessage: '',
  categoryDuplicasyErrorMessage : "",
  filterMultiItemsState : "",
  itemsSearchBySpecificValue : "",
  anchorEl : null,
  isFilterSelectOpen : false,
  mainSearchKeysData: [
    {
      lable : "PR Number",
      key : "purchase_requisition_number",
      selected : false
    },
    {
      lable : "Requester",
      key : "user_name",
      selected : false
    },
    {
      lable : "Creation Date",
      key : "pr_creation_date",
      selected : false
    },
    {
      lable : "Delivery Date",
      key : "delivery_date",
      selected : false
    },
    {
      lable : "Location",
      key : "name_of_location",
      selected : false
    },
    {
      lable : "Department",
      key : "department_id?.name",
      selected : false
    },
    {
      lable : "Status",
      key : "status",
      selected : false
    },
  ],
  isOpenDropdownSelectSearch : false,
  isMenuForSearchOpen  : false,
  mainSearchCOFItemsKeysData :  [
    {
      lable : "Item ID",
      key : "item_id",
      selected : false
    },
    {
      lable : "Supplier",
      key : "Supplier",
      selected : false
    },
    {
      lable : "Unit Measurement",
      key : "unit_measurement",
      selected : false
    },
    {
      lable : "Unit Price",
      key : "unit_price",
      selected : false
    },
    {
      lable : "Product Category",
      key : "product_category?.name,",
      selected : false
    },
    {
      lable : "Type",
      key : "item_type",
      selected : false
    }
  ],
  cofItemsFilterMultiState : '',
  isCOFItemsMenuForSearchOpen : false,
  anchorElForCOFItems : null,
  itemsCofSearchBySpecificValue : ""


    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start

    this.handleGetUserModulePermissions()
    this.handleGetAllDashboardOptions();
    this.getAllPurchaseRequisitionsList();
    this.getAllProductsList();
    this.getAllSuppliersList();
    this.handleGetCurrencyList();
    this.getDepartmentList();
    this.getBudgetList();
    this.getSupplierList();
    this.getPaymentTermList();
    this.getTaxationList();
    this.getProductCategories();
    this.getLocationDropdownList();
    this.getRolesList();
    this.handleGetAllPRStatuses();
    // dashboard methods start
    this.handleDashboardAllRecordsRequests();
    this.handleGetSettingsLocation();
    this.handleGetUserProfile();
    this.setState({prNumberUnique: Math.floor(Math.random() * 900000000) + 100000000})
    
    // dashboard methods end
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    
    this.handleGetAllDashboardOptionsAPICallIdResponse(message)

    this.handleCreatePurchaseRequisitionApiCallIdResponse(message)

    // Modification start

    this.handleSaveAsDraftPurchaseOrderApiCallIdResponse(message)

    this.handleSaveAsDraftBudgetAPICallIdResponse(message)

    this.showSettingsCategoryAPICallResponse(message)

    this.showSettingsLocationAPICallIdResponse(message)

    this.showSettingsLocationAPICallResponse(message)

    this.createLocationSettingsAPICallResponse(message)

    this.editSettingsLocationAPICallIdResponse(message)

    
    // Modification end

    

                 
    this.handleGetAllPurchaseRequisitionListAPICallIdResponse(message)
    
    this.handleEditPurchaseRequisitionAPICallIdResponse(message)

    this.handleShowPurchaseRequisitionAPICallIdResponse(message)

    this.handleGetUserProfileAPICallIdResponse(message)

    this.handleGetAllProductsListAPICallIdResponse(message)

    this.handleGetAllSuppliersListAPICallIdResponse(message)

    this.handleGetAllRFPListAPICallIdResponse(message)

    this.handleCreateRfpAPICallIdResponse(message)

    this.handleShowRfpAPICallIdResponse(message)

    this.handleEditRfpAPICallIdResponse(message)

    this.editSettingsCategoryAPICallIdResponse(message)

    this.editSettingsUnitMeasurementAPICallIdResponse(message)

    this.createCategorySettingsAPICallResponse(message)

    this.handleDeleteSettingUnitMeasurementAPICallIdResponse(message)

    this.handleDeleteCategoryAPICallIdResponse(message)

    this.createUnitMeasurementSettingsAPICallResponse(message)

    this.handleGetAllPurchaseOrderListAPICallIdResponse(message)      
    
    this.handleCreatePurchaseOrderAPICallIdResponse(message)

    this.handleCreatePurchaseRequisitionOrderAPICallIdResponse(message)

    this.handleEditPurchaseOrderAPICallIdResponse(message)

    this.handleShowPurchaseOrderAPICallIdResponse(message)

    this.handleCreateCustomFromRequisitionPoAPICallIdResponse(message)

    this.handleGetAllReceiptsAPICallIdResponse(message)

    this.handleCreateReceiptAPICallIdResponse(message)

    this.handleShowReceiptAPICallIdResponse(message)

    this.handleGetCurrencyListAPICallIdResponse(message)

    this.handleCreateSupplierAPICallIdResponse(message)

    this.handleEditSupplierAPICallIdResponse(message)

    this.handleShowSupplierAPICallIdResponse(message)

    this.handleCreateProductAPICallIdResponse(message)

    this.handleEditProductAPICallIdResponse(message)

    this.handleShowProductAPICallIdResponse(message)

    this.handleGetBudgetListAPICallIdResponse(message)

    this.handleCreateBudgetAPICallIdResponse(message)

    this.handleGetSupplierListAPICallIdResponse(message)

    this.handleGetPaymentTermsListAPICallIdResponse(message)

    this.handleCreatePaymentTermsListAPICallIdResponse(message)

    this.handleDeletePaymentTermListAPICallIdResponse(message)

    this.handleEditPaymentTermsListAPICallIdResponse(message)

    this.handleGetTaxationsListAPICallIdResponse(message)

    this.handleCreateTaxationAPICallIdResponse(message)

    this.handleEditTaxationAPICallIdResponse(message)

    this.handleEditBudgetAPICallIdResponse(message)

    this.handleShowBudgetAPICallIdResponse(message)

    this.handleCreateInformationSettingsAPICallIdResponse(message)

    this.handleCreateCurrencySettingsAPICallIdResponse(message)

    this.handleGetInformationSettingsAPICallIdResponse(message)

    this.handleGetCurrencySettingsAPICallIdResponse(message)

    this.handleGetLocationsAPICallIdResponse(message)

    this.handleGetLocationsAPICallIdResponse(message)

    this.handleDeleteLocationAPICallIdResponse(message)

    this.handleGetUsersAPICallIdResponse(message)

    this.handleDeleteUserAPICallIdResponse(message)

    this.handleSaveUsersAPICallIdResponse(message)

    this.handleGetDepartmentListAPICallIdResponse(message)

    this.handleGetProductCategoriesAPICallIdResponse(message)

    this.handleGetLocationDropdownListAPICallIdResponse(message)


    this.handleGetUserAccessPermissionAPICallIdResponse (message)

    this.handleSaveUserAccessPermissionAPICallIdResponse(message)


    this.handleGetBudgetForListAPICallIdResponse(message)

    this.handleGetRolesListAPICallIdResponse(message)

    this.handleGetAllSearchProductsListAPICallIdResponse(message)

    this.handleGetAllReceiptsPurchaseOrdersForSelectionAPICallIdResponse(message)

    //
    // dashboard receive methods start
    //
    
    this.handleDashboardApiCallIdResponse(message)

    this.handleDashboardEditPurchaseRequisitionAPICallIdResponse(message)

    this.handleDashboardAcceptRejectActionsPurchaseRequisitionAPICallIdResponse(message)

    this.handleEditDashboardRfpAPICallIdResponse(message)

    this.handleDashboardEditPurchaseOrderAPICallIdResponse(message)

    this.handleDashboardAcceptRejectActionsPurchaseOrderAPICallIdResponse(message)

    this.handleGetPRAllStatus(message);

    this.showSettingsUnitMeasurementAPICallResponse(message);


    // rfp reciev

    this.handleGetRfpSupplierMailSendApiCallIdResponse(message)

    this.handleGetEditedRfpSupplierMailSendApiCallIdResponse(message)

    // po recieve

    this.handleGetPoSupplierMailSendApiCallIdResponse(message)

    this.handleGetPrPoSupplierMailSendApiCallIdResponse(message)

    this.handleGetEditedPoSupplierMailSendApiCallIdResponse(message)

    this.handlegetUserModulePermissionsAPICallIdResponse(message)



    //
    // dashboard receive methods end
    //
    // Customizable Area End
  }

  // Customizable Area Start

  // api recieve methods to be added start

  handleRecieveSessionResponce = (message: Message)=> {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      console.log('Token is ',token)
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }
  }

  handleRestApiJsonDataResponseSubNestedFunc = (responseJson:any) => {
    if (responseJson.data.length === 0) {
      this.setState({
        errorMsg: "Data Not Found",
        loading: false
      });
    } else {
      this.setState({
        dashboardData: responseJson.data,
        errorMsg: "",
        loading: false
      });
    }
  }

  handleGetAllDashboardOptionsSuccessAndErrorsNestedFunc=(apiResponse:any)=>{
    if(apiResponse.errors){
      console.log('error for Dashboard Options API', apiResponse.errors)
    } else {
      this.setState({
        dashboardOptions: apiResponse?.dashboard_options
      })
    }
  }

  handleGetAllDashboardOptionsAPICallIdResponse = (message: Message) => {
    if (this.getAllDashboardOptionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetAllDashboardOptionsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreatePurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse : any) =>{
    if(apiResponse?.errors){
      console.log('error for createPurchaseRequisition API', apiResponse?.errors)
      this.setState({
        purchaseRequisitionCreateMessage: 'Purchase Requisition not created',
      })
    } else {
      // this.getAllPurchaseRequisitionsList()
      this.handleSelectedRow(apiResponse?.data?.id)
      this.setState({
        purchaseRequisitionCreateMessage: 'Purchase Requisition created',
      },()=>{
        this.handleDashboardAllRecordsRequests()
        this.getAllPurchaseRequisitionsList()
      })
      setTimeout(() => {
        this.handleAddRequisitionCloseModal()
      }, 1500);
    }
  }

  handleCreatePurchaseRequisitionApiCallIdResponse = (message: Message) => {
    if (this.createPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
      this.setState({
        location: '',
        deliveryDate: '',
        products: [],
        durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
        showIsCreatePurchaseRequisitionValidation: false,
        department: '',
        status: '',
        createPurchaseRequisitionLoading: false,
      })
    }
  }

  handleSaveAsDraftPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for saveAsDraftPurchaseOrder API', apiResponse?.errors)
      this.setState({
        purchaseOrderDraftMessage: 'Purchase Order cannot SaveAsDraft'
      })
    } else {
      this.setState({
        purchaseOrderDraftMessage: 'Purchase Order SaveAsDraft'
      })
      this.handleGetAllPurchaseOrders()
      this.handleDashboardAllRecordsRequests();
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      setTimeout(() => {
        this.handleAddPurchaseOrderCloseModal();
        this.handleClosePurchaseFromRequisitionCreatePO();
      }, 1500);
    }  
  }

  handleSaveAsDraftPurchaseOrderApiCallIdResponse = (message: Message) => {
    if (this.saveAsDraftPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      this.handleSaveAsDraftPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleSaveAsDraftBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for saveAsDraftBudget API', apiResponse?.errors)
    } else {
      this.setState({
        openAddBudgetModal: false,
        budgetAmount: '',
        budgetComment: '',
        budgetFor: '',
        budgetName: '',
        budgetPeriod: '',
        budgetProductCategories: '',
      },()=>{
        this.handleBudgetSelectedRow(apiResponse?.data?.id)
        this.getBudgetList()
      })
    } 
  }

  handleSaveAsDraftBudgetAPICallIdResponse = (message: Message) => {
    if (this.saveAsDraftBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleSaveAsDraftBudgetSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleGetAllPurchaseRequisitionDataItemsPerPageSetNestedFunc = () => {
    if(this.state.purchaseRequisitionList?.length>0 && this.state.purchaseRequisitionList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesPurchaseRequisition: this.state.purchaseRequisitionList.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseRequisition: Math.floor(this.state.purchaseRequisitionList?.length/this.state.itemsPerPage)+1
      })
    }

    if(this.state.purchaseRequisitionList?.length>0 && this.state.purchaseRequisitionList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesPurchaseRequisitionPO: this.state.purchaseRequisitionList.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseRequisitionPO: Math.floor(this.state.purchaseRequisitionList?.length/this.state.itemsPerPage)+1
      })
    }

  }

  handleGetAllPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for Get All Purchase Requisition API', apiResponse?.errors)
    } else {
      this.setState({
        purchaseRequisitionList: apiResponse?.data
      })
      this.handleGetAllPurchaseRequisitionDataItemsPerPageSetNestedFunc()
    }
  }

  handleGetAllPurchaseRequisitionListAPICallIdResponse = (message: Message) => {
    if (this.getAllPurchaseRequisitionListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editPurchaseRequisition API', apiResponse?.errors)
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition not updated"
      })
    } else {
      this.setState({
        openEditRequisitionModal: false,
        purchaseRequisitionEditMessage: "Purchase Requisition updated"
      });
      this.getAllPurchaseRequisitionsList();
    } 
  }

  handleEditPurchaseRequisitionAPICallIdResponse = (message: Message) => {
    if (this.editPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse) 
      this.setState({
        purchaseRequisitionEditMessage: '',
      })
    }
  }

  handleShowPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      this.setState({
        showPurchaseRequisition: apiResponse?.data
      })
      console.log("data1", apiResponse?.data);
    } 
  }

  handleShowPurchaseRequisitionAPICallIdResponse = (message: Message) => {
    if (this.showPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetUserProfileSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for userProfile API', apiResponse?.errors)
    } else {
      this.setState({
        userProfile: apiResponse?.data?.attributes?.user_name?.split(/\s/)
        .reduce((response: string, word: any) => (response += word.slice(0, 1)), ""),
        accountId: apiResponse?.data?.id,
      })      
    }
  }

  handleGetUserProfileAPICallIdResponse = (message: Message) => {
    if (this.getUserProfileAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleGetUserProfileSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handlegetUserModulePermissionsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for userProfile API', apiResponse?.errors)
    } else {
      this.setState({
        userLoginDetailsCatch : apiResponse
      })

    }
  }

  handlegetUserModulePermissionsAPICallIdResponse = (message: Message) => {
    if (this.getUserModulePermissionsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handlegetUserModulePermissionsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }



  handleGetAllProductsListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.productsList?.length>0 && this.state.productsList?.length%this.state.itemsPerPageProduct==0){
      this.setState({
        totalPagesProduct: this.state.productsList.length/this.state.itemsPerPageProduct
      })
    }
    else{
      this.setState({
        totalPagesProduct: Math.floor(this.state.productsList?.length/this.state.itemsPerPageProduct)+1
      })
    }
  }

  handleGetAllProductsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllProductsList API', apiResponse?.errors)
    } else {
      console.log('Response for getAllProductsList API', apiResponse);
      this.setState({
        productsList: apiResponse?.data
      })
      this.handleGetAllProductsListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllProductsListAPICallIdResponse = (message:Message) => {
    if (this.getAllProductsListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllProductsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetAllSuppliersListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.suppliersList?.length>0 && this.state.suppliersList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesSupplier: this.state.suppliersList.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesSupplier: Math.floor(this.state.suppliersList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleGetAllSuppliersListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllSuppliersList API', apiResponse?.errors)
    } else {
      this.setState({
        suppliersList: apiResponse?.data
      })
      this.handleGetAllSuppliersListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllSuppliersListAPICallIdResponse = (message:Message) => {
    if (this.getAllSuppliersListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllSuppliersListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllRFPListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.rfpList?.length>0 && this.state.rfpList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesRfp: this.state.rfpList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesRfp: Math.floor(this.state.rfpList?.length/this.state.itemsPerPage)+1
      })
    }
  }


  handleGetAllRFPListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllRFPList API', apiResponse?.errors)
    } else {
      console.log('Response for getAllRFPList API', apiResponse);
      this.setState({
        rfpList: apiResponse?.data
      })
      this.handleGetAllRFPListItemsPerPageSetSubNestedFunc()
    }  
  }

  handleGetAllRFPListAPICallIdResponse = (message:Message) => {
    if (this.getAllRFPListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetAllRFPListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateRfpSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error for create RFP API', apiResponse?.errors)
      this.setState({
        rfpCreateMessage: 'Request for Proposal is not created',
      })
      if(apiResponse?.errors[0].due_date){
        this.setState({
          rfpCreateMessagedDueDate: 'Due Date must be Small than Desired Delivery Date'
        })
      }
    } else {
      this.setState({isRfpCreating : false})
      console.log('Response for create RFP API', apiResponse);
      this.handleRfpSelectedRow(apiResponse?.data?.id);
      this.handleRfpIdForPdfCreation(apiResponse?.data?.id);
      this.handleRfpCatchMailForSuppliers(apiResponse?.data?.id);
      this.setState({
        rfpCreateMessage: 'Request for Proposal is created',
        openRfpPdfSupplierScreenModal: true,
        isRfpCreationAndApiSuccessDone: true,
        rfpCreateMessagedDueDate: '',
      })
      this.getAllRFP()
    }
  }


  handleCreateRfpAPICallIdResponse = (message: Message) => {
    this.setState({isRfpCreating : false})

    if (this.createRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleCreateRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetRfpSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error while sending mail to supplier', apiResponse?.errors)
      this.setState({
        rfpEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      console.log('Response for Successfully mail send', apiResponse);
      this.setState({
        isRfpMailSendConfirmed:false,
        openRfpPdfSupplierScreenModal: false,
        openRfpSupplierMailSeccessModal: true,
        catchRfpEmailSendToSupplier: '',
        rfpEmailToSupplierSentOrNotErrorMessage:'',
        rfpSelectExistingSuppliers: [],
      })
    } else {
      this.setState({
        rfpEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }



  handleGetRfpSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isRfpMailSending : false})

    if (this.getRfpSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetRfpSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetEditedRfpSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    this.setState({
      isEditedRfpMailSendConfirmed: false,
    })
    if (apiResponse?.errors) {
      console.log('error while sending edited mail to supplier', apiResponse?.errors)
      this.setState({
        editedRfpEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      console.log('Response for Successfully mail send', apiResponse);
      this.setState({
        openEditedRfpPdfSupplierScreenModal: false,
        isEditedRfpMailSendConfirmed:false,
        openEditedRfpSupplierMailSeccessModal:true,
        catchEditedRfpEmailSendToSupplier: '',
        editedRfpEmailToSupplierSentOrNotErrorMessage:apiResponse?.message,
      })
    } else {
      this.setState({
        editedRfpEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetEditedRfpSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isEditedRfpMailSending : false})

    if (this.getEditedRfpSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetEditedRfpSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }






  handleShowRfpSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showRFP API', apiResponse?.errors)
    } else {
      this.setState({
        showRfp: apiResponse?.data
      })
    }
  }

  handleShowRfpAPICallIdResponse = (message:Message) => {
    if (this.showRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditRfpSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editRFP API', apiResponse?.errors)
      this.setState({
        rfpEditMessage: 'Request for proposal is not updated',
      })
    } else {
      this.setState({
        openEditRFPModal: false,
        showIsEditRFPValidation: false,
        openAddRFPDownloadFiles: true,
        rfpEditMessage: 'Request for proposal is updated'
      })
      this.getAllRFP()
    } 
  }

  handleEditRfpAPICallIdResponse = (message:Message) => {
    if (this.editRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllPurchaseOrderListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.purchaseOrdersList?.length>0 && this.state.purchaseOrdersList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesPurchaseOrder: this.state.purchaseOrdersList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesPurchaseOrder: Math.floor(this.state.purchaseOrdersList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleGetAllPurchaseOrderListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllPO API', apiResponse?.errors)
    } else {
      this.setState({
        purchaseOrdersList: apiResponse?.data
      })
      this.handleGetAllPurchaseOrderListItemsPerPageSetSubNestedFunc()
    } 
  }

  handleGetAllPurchaseOrderListAPICallIdResponse = (message:Message) => {
    if (this.getAllPurchaseOrderListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllPurchaseOrderListSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleGetAllReceiptsPurchaseOrdersForSelectionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllReceiptsPurchaseOrdersForSelection API', apiResponse?.errors)
    } else {
      this.setState({
        recieptsPOSelectionDataArr: apiResponse?.data
      })
    }
  }

  handleGetAllReceiptsPurchaseOrdersForSelectionAPICallIdResponse = (message:Message) => {
    if (this.getAllReceiptsPurchaseOrdersForSelectionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllReceiptsPurchaseOrdersForSelectionSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }





  handleCreatePurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createPurchaseOrder API', apiResponse?.errors)
      this.setState({
        poCreateMessage: 'Purchase Order is not created',
      })
    } else {
      this.setState({poCreating: true, prPoCreating: true})
      
      this.handleGetAllPurchaseOrders()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      this.handlePoIdForPdfCreation(apiResponse?.data?.id);
      this.handlePoCatchMailForSuppliers(apiResponse?.data?.id);
      console.log('Created Row ID ',apiResponse?.data?.id)
      this.setState({
        poCreateMessage: 'Purchase Order is created',
        isPoMailSendConfirmed: false,
        openPoPdfSupplierScreenModal: true,
        openPrPoPdfSupplierScreenModal: true,
        isPoCreationAndApiSuccessDone: true,
        openPurchaseRequisitionListModal: false,
        openCustomFromRequisitionCreatePoModal: false,
        prPoCreateScreenActiveTabs: 0,
        currentPagePurchaseRequisitionPO: 1,
        prPoSelectedRequisitionBudgetError: '',
        prPoSelectedRequisitionSupplierError: '',
        prPoSelectedRequisitionPaymentError: '',
        prPoSelectedRequisitionTaxationError: '',
        prPoSelectedRequisitionShippingError: '',
        prPoSelectedRequisitionBillingError: '',
        purchaseOrderCommentRequiredField: '',
        customFromRequisitionSelectedRow: false,
        poCreateScreenActiveTabs: 1,
        selectPoOptions: '',
        poCreating: true,
        paymentTermsNameSuppliers: []
      })
    } 
  }

  handleCreatePurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.createPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)  
      this.setState({
        poId: '',
        poLocation: '',
        poOrderDate: '',
        poFobPoint: '',
        poDepartment: '',
        poProduct: [],
        poComment: '',
        poCommentForPO: '',
        poAmountCalculation: '',
        //poTaxId: '',
        poGstNumber: '',
        poBillingAddress: '',
        poSupplier: '',
        poBudget: '',
        poModeOfTransit: '',
        poShippingAddress: '',
        poShippingAddressOptional: '',
        taxName: '',
        openCustomFromRequisitionCreatePoModal: false,
        // poCreateScreenActiveTabs: 0,
        showIsCreatePurchaseOrderValidation: false,
        paymentTermsNameSuppliers: [],
        poCreating: false
      })
    }
  }

  handleGetPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error while sending mail to supplier', apiResponse?.errors)
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      console.log('Response for Successfully mail send', apiResponse);
      this.setState({
        isPoMailSendConfirmed:false,
        openPoPdfSupplierScreenModal: false,
        openPoSupplierMailSeccessModal: true,
        catchPoMailOfSupplier: '',
        poEmailToSupplierSentOrNotErrorMessage:'',
      })
    } else {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isPoMailSending : false})

    if (this.getPoSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetEditedPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error while sending mail to supplier', apiResponse?.errors)
      this.setState({
        editedPoEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      console.log('Response for Successfully mail send', apiResponse);
      this.setState({
        isEditedPoMailSendConfirmed: false,
        openEditedPoPdfSupplierScreenModal: false,
        openEditedPoSupplierMailSeccessModal: true,
        catchEditedPoMailOfSupplier: '',
        poEditScreenActiveTabs: 0
      })
    }
  }

  handleGetEditedPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isEditedPoMailSending : false})

    if (this.getPoEditSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetEditedPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
      this.handleDashboardAllRecordsRequests()
    }
  }

  handleCreatePurchaseRequisitionOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createPurchaseOrder API', apiResponse?.errors)
      this.setState({
        prPoCreateMessage: 'Purchase Order is not created',
      })
    } else {
      this.setState({poCreating: true, prPoCreating: true})
      
      this.handleGetAllPurchaseOrders()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
      this.handlePrPoIdForPdfCreation(apiResponse?.data?.id);
      this.handlePoCatchMailForSuppliers(apiResponse?.data?.id);
      console.log('Created Row ID ',apiResponse?.data?.id)
      this.setState({
        prPoCreateMessage: 'Purchase Order is created',
        isPoMailSendConfirmed: false,
        openPoPdfSupplierScreenModal: true,
        openPrPoPdfSupplierScreenModal: true,
        isPoCreationAndApiSuccessDone: true,
        openPurchaseRequisitionListModal: false,
        currentPagePurchaseRequisitionPO: 1,
        prPoSelectedRequisitionBudgetError: '',
        prPoSelectedRequisitionSupplierError: '',
        prPoSelectedRequisitionPaymentError: '',
        prPoSelectedRequisitionTaxationError: '',
        prPoSelectedRequisitionShippingError: '',
        prPoSelectedRequisitionBillingError: '',
        purchaseOrderCommentRequiredField: '',
        customFromRequisitionSelectedRow: false,
        selectPoOptions: '',
      })
    } 
  }

  handleCreatePurchaseRequisitionOrderAPICallIdResponse = (message:Message) => {
    if (this.createPurchaseRequisitionOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePurchaseRequisitionOrderSuccessAndErrorsNestedFunc(apiResponse)  
      this.setState({
        poId: '',
        poLocation: '',
        poOrderDate: '',
        poFobPoint: '',
        poDepartment: '',
        poProduct: [],
        poComment: '',
        poCommentForPO: '',
        poAmountCalculation: '',
        //poTaxId: '',
        poGstNumber: '',
        poBillingAddress: '',
        poSupplier: '',
        poBudget: '',
        poModeOfTransit: '',
        poShippingAddress: '',
        prPoCreating: false,
        paymentTermsNameSuppliers: [],
      })
    }
  }

  handleGetPrPoSupplierMailSendSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error while sending mail to supplier', apiResponse?.errors)
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Email Failed To Send'
      })
    } else if (apiResponse?.message) {
      this.setState({
        isPrPoMailSendConfirmed:false,
        openPrPoPdfSupplierScreenModal: false,
        openPrPoSupplierMailSeccessModal: true,
        catchPrPoMailOfSupplier: '',
        prPoEmailToSupplierSentOrNotErrorMessage:'',
      })
    } else {
      this.setState({
        poEmailToSupplierSentOrNotErrorMessage : 'Supplier Email Not Found'
      })
    }
  }

  handleGetPrPoSupplierMailSendApiCallIdResponse = (message: Message) => {
    this.setState({isPrPoMailSending : false})

    if (this.getPrPoSupplierMailSendApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetPrPoSupplierMailSendSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editPO API', apiResponse?.errors)
      this.setState({
        poEditMessage: 'Purchase order is not updated'
      })
    } else {
      this.setState({
        openEditPurchaseOrderModal: false,
        poShippingAddress: '',
        poBillingAddress: '',
        poGstNumber: '',
        poEditMessage: 'Purchase order is updated',
        //showIsEditRFPValidation: false,
      })
      this.handleGetAllPurchaseOrders()
    }  
  }

  handleEditPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.editPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleShowPurchaseOrderSuccessAndErrorsNesteFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPO API', apiResponse?.errors)
    } else {
      console.log('Response for showPO API', apiResponse?.data);
      this.setState({
        showPurchaseOrder: apiResponse?.data,
        //openShowPurchaseOrderModal: false,
      })
      
    }  
  }

  handleShowPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.showPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleShowPurchaseOrderSuccessAndErrorsNesteFunc(apiResponse)
    }
  }

  handleCreateCustomFromRequisitionPoSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createCustomFromRequisitionPO', apiResponse?.errors)
    } else {
      this.handleGetAllPurchaseOrders()
      this.handleCustomFromRequisitionPOCloseModal()
      this.handleAddCustomFromRequisitionPOCloseModal()
      this.handlePurchaseOrderSelectedRow(apiResponse?.data?.id)
    }
  }


  handleCreateCustomFromRequisitionPoAPICallIdResponse = (message:Message) => {
    if (this.createCustomFromRequisitionPoAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateCustomFromRequisitionPoSuccessAndErrorsNestedFunc(apiResponse)
      this.setState({
        poShippingAddress: '',
        poBillingAddress: '',
        poGstNumber: '',
      })
    }
  }

  handleGetAllReceiptsItemsPerPageSubNestedFunc = () => {
    if(this.state.receiptsList?.length>0 && this.state.receiptsList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesReceipt: this.state.receiptsList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesReceipt: Math.floor(this.state.receiptsList?.length/this.state.itemsPerPage)+1
      })
    }
  }


  handleGetAllReceiptsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllReceipts API', apiResponse?.errors)
    } else {
      this.setState({
        receiptsList: apiResponse?.data
      })
      this.handleGetAllReceiptsItemsPerPageSubNestedFunc()
    } 
  }

  handleGetAllReceiptsAPICallIdResponse = (message:Message) => {
    if (this.getAllReceiptsAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetAllReceiptsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateReceiptSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    this.setState({isReceiptCreating : false})
    if(apiResponse?.errors){
      console.log('error for createReceipt API', apiResponse?.errors)
      this.setState({
        receiptCreateMessage: 'Receipt is not created',
      })
    } else {
      this.setState({
        isReceiptCreating : false,
        receipt: apiResponse?.data,
        openAddReceiptModal: false,
        receiptSelectedPo: '',
        receiptAttachmentFile: '',
        receiptReceiptDate: '',
        receiptInvoiceNumber:'',
        receiptAttachment:'',
        receiptSelectedPOPopulatedData: {},
        receiptCatelogueItemsStore:[],
        receiptNonCatelogueItemsStore: [],
        showIsCreateReceiptValidation: false,
        openReceiptCreateShowErrorModal: false,
        receiptCreateMessage: 'Receipt is created',
      })
      this.handleReceiptSelectedRow(apiResponse?.data?.id)
      this.handleGetAllReceipts()
    }
  }

  handleCreateReceiptAPICallIdResponse = (message:Message) => {
    if (this.createReceiptAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateReceiptSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleShowReceiptSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editReceipt API', apiResponse?.errors)
    } else {
      console.log('Response for editReceipt API', apiResponse);
      this.setState({
        showReceipt: apiResponse?.data,


        //showIsEditRFPValidation: false,
      })
    }
  }

  handleShowReceiptAPICallIdResponse = (message:Message) => {
    if (this.showReceiptAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowReceiptSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetCurrencyListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for currencyList API', apiResponse?.errors)
    } else {
      console.log('Response for currencyList API', apiResponse);
      this.setState({
        currencyList: apiResponse?.countries,
        //showIsEditRFPValidation: false,
      })
    }
  }

  handleGetCurrencyListAPICallIdResponse = (message:Message) => {
    if (this.getCurrencyListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetCurrencyListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for craeteSupplier API', apiResponse?.errors)
      this.setState({
        supplierCreateMessage: 'Supplier is not created',
      })
    } else {
      console.log('Response for createSupplier API', apiResponse);
      this.setState({
        openAddSupplierModal: false,
        supplierStateProvince : '',
        supplierZipCodePinCode : '',
        supplierAddressOfSupplierCompany: '',
        supplierContactNumber: '',
        supplierEmailAddress: '',
        supplierGSTNumber: '',
        supplierPointOfContact: '',
        supplierSupplierLocation: '',
        supplierSupplierName: '',
        supplierTermsOfPayment: '',
        supplierUniqueCode: '',
        paymentTermsNameSuppliers: [],
        supplierCreateMessage: 'Supplier is created',
      })
      this.handleSupplierSelectedRow(apiResponse?.data?.id)
      this.getAllSuppliersList()
    }
  }

  handleCreateSupplierAPICallIdResponse = (message:Message) => {
    if (this.createSupplierAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateSupplierSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editSupplier API', apiResponse?.errors)
      this.setState({
        supplierEditMessage: 'Supplier is not updated',
      })
    } else {
      console.log('Response for editSupplier API', apiResponse);
      this.setState({
        openEditSupplierModal: false,
        supplierEditMessage: 'Supplier is updated',
        //showIsEditRFPValidation: false,
      })
      this.getAllSuppliersList()
    } 
  }

  handleEditSupplierAPICallIdResponse = (message:Message) => {
    if (this.editSupplierAPICallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditSupplierSuccessAndErrorsNestedFunc(apiResponse)  
    }
  }


  handleShowSupplierSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showSupplier API', apiResponse?.errors)
    } else {
      console.log('Response for showSupplier API', apiResponse);
      this.setState({
        showSupplier: apiResponse?.data,
        paymentTermsName: apiResponse?.data?.attributes?.terms_of_payment,
        poGstNumber: apiResponse?.data?.attributes?.gst_number,
        editedPoGstNumber: apiResponse?.data?.attributes?.gst_number,
        getPaymentTermListSupplier:apiResponse?.data?.attributes?.payment_terms?.data
        //showIsEditRFPValidation: false,
      })
    }
  }

  handleShowSupplierAPICallIdResponse = (message:Message) => {
    if (this.showSupplierAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowSupplierSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createProduct API', apiResponse?.errors)
      this.setState({
        productCreateMessage: 'Product is not created',
      })
    } else {
      console.log('Response for createProduct API', apiResponse);
      this.setState({
        openAddProductModal: false,
        productCategory: '',
        productDescription: '',
        productName: '',
        productPartId: '',
        productUnitMeasurement: '',
        productUnitPrice: '',
        productTypeSelectedId: '',
        addItemSupplier : '',
        addItemSupplierMultiple: [],
        productCreateMessage: 'Product is created',
      })
      this.handleProductSelectedRow(apiResponse?.data?.id)
      this.getAllProductsList()
    } 
  }

  handleCreateProductAPICallIdResponse = (message:Message) => {
    if (this.createProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editProduct API', apiResponse?.errors)
      this.setState({
        productEditMessage: 'Product is not updated',
      })
    } else {
      console.log('Response for editProduct API', apiResponse);
      this.setState({
        openEditProductModal: false,
        productEditMessage: 'Product is updated',
      })
      this.getAllProductsList()
    } 
  }

  handleEditProductAPICallIdResponse = (message:Message) => {
    if (this.editProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleShowProductSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showProduct API', apiResponse?.errors)
    } else {
      console.log('Response for showProduct API', apiResponse);
      this.setState({
        showProduct: apiResponse?.data,
      })
    }
  }

  handleShowProductAPICallIdResponse = (message:Message) => {
    if (this.showProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowProductSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetBudgetListItemsPerPageSetSubNestedFunc = () => {
    if(this.state.budgetList?.length>0 && this.state.budgetList?.length%this.state.itemsPerPage==0){
      this.setState({
        totalPagesBudget: this.state.budgetList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        totalPagesBudget: Math.floor(this.state.budgetList?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleGetBudgetListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      console.log('Response for getBudgetList API', apiResponse);
      this.setState({
        budgetList: apiResponse?.data,
        //showIsEditRFPValidation: false,
      })
      this.handleGetBudgetListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetBudgetListAPICallIdResponse = (message:Message) => {
    if (this.getBudgetListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetBudgetListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createBudget API', apiResponse?.errors)
      this.setState({
        budgetCreateMessage: 'Budget is not created',
      })
    } else {
      console.log('Response for createBudget API', apiResponse);
      this.setState({
        openAddBudgetModal: false,
        budgetAmount: '',
        budgetComment: '',
        budgetFor: '',
        budgetName: '',
        budgetPeriod: '',
        budgetProductCategories: '',
        budgetCreateMessage: 'Budget is created',
      })
      this.handleBudgetSelectedRow(apiResponse?.data?.id)
      this.getBudgetList()
    }  
  }

  handleCreateBudgetAPICallIdResponse = (message:Message) => {
    if (this.createBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleEditBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editBudget API', apiResponse?.errors)
      this.setState({
        budgetEditMessage: 'Budget is not updated',
      })
    } else {
      console.log('Response for editBudget API', apiResponse);
      this.setState({
        openEditBudgetModal: false,
        budgetEditMessage: 'Budget is updated',
        //showIsEditRFPValidation: false,
      })
      this.handleGetAllBudgets()
    } 
  }

  handleEditBudgetAPICallIdResponse = (message:Message) => {
    if (this.editBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleShowBudgetSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showBudget API', apiResponse?.errors)
    } else {
      console.log('Response for showBudget API', apiResponse);
      this.setState({
        showBudget: apiResponse?.data,
        //showIsEditRFPValidation: false,
      })
    }
  }

  handleShowBudgetAPICallIdResponse = (message:Message) => {
    if (this.showBudgetAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleShowBudgetSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleCreateInformationSettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createInformationSettings API', apiResponse?.errors)
      this.setState({
        informationSettingsEditMessage: 'Information Settings is not updated',
      })
    } else {
      console.log('Response for createInformationSettings API', apiResponse);
      this.setState({
        //showInformation: false,
        showIsCreateInformationValidation: false,
        informationSettingsEditMessage: 'Information Settings is updated'
      })
    }  
  }

  handleCreateInformationSettingsAPICallIdResponse = (message:Message) => {
    if (this.createInformationSettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateInformationSettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleCreateCurrencySettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for CurrencySettings API', apiResponse?.errors)
      this.setState({
        currencySettingsEditMessage: 'Currency is not updated'
      })
    } else {
      console.log('Response for CurrencySettings API', apiResponse);
      this.setState({
        currencySettingsEditMessage: 'Currency is updated'
      })
    }  
  }

  handleCreateCurrencySettingsAPICallIdResponse = (message:Message) => {
    if (this.createCurrencySettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreateCurrencySettingsSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleGetInformationSettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getInformationSettings API', apiResponse?.errors)
    } else {
      console.log('Response for getInformationSettings API', apiResponse);
      this.setState({
        informationSettings: apiResponse?.data,
        informationSettingsAddress: apiResponse?.data?.attributes?.address,
        informationSettingsCompanyName: apiResponse?.data?.attributes?.company_name,
        informationSettingsEmail: apiResponse?.data?.attributes?.email,
        informationSettingsGstNumber: apiResponse?.data?.attributes?.gst_number,
        informationSettingsPhoneNumber: apiResponse?.data?.attributes?.phone_number,
        informationSettingsPinCode: apiResponse?.data?.attributes?.pin_code,
        informationSettingsWebsite: apiResponse?.data?.attributes?.website,
        poShippingAddress: apiResponse?.data?.attributes?.address,
      })
    } 
  }

  handleGetInformationSettingsAPICallIdResponse = (message:Message) => {
    if (this.getInformationSettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetInformationSettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetCurrencySettingsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getCurrencySettings API', apiResponse?.errors)
    } else {
      console.log('Response for getCurrencySettings API', apiResponse);
      this.setState({
        currencySettingsCurrency: apiResponse?.data?.attributes?.currency,
      })
    }
  }

  handleGetCurrencySettingsAPICallIdResponse = (message:Message) => {
    if (this.getCurrencySettingsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetCurrencySettingsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetLocationsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getLocations API', apiResponse?.errors)
    } else {
      console.log('Response for getLocations API', apiResponse);
      this.setState({
        locationsList: apiResponse?.data,
        currentPage: 1,
        noOfPages: Math.ceil(apiResponse?.data?.length / 6)
      })
    } 
  }

  handleGetLocationsAPICallIdResponse = (message:Message) => {
    if (this.getLocationsAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetLocationsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleDeleteLocationSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for deleteLocation API', apiResponse?.errors)
      this.setState({
        locationDeleteMessage: 'Location is not deleted',
      })
    } else {
      console.log('Response for deleteLocation API', apiResponse);
      this.setState({
        locationDeleteMessage: 'Location is deleted',
      })
      this.handleGetSettingsLocation()
    } 
  }

  handleDeleteLocationAPICallIdResponse = (message:Message) => {
    if (this.deleteLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteLocationSuccessAndErrorsNestedFunc(apiResponse)  
    }
  }

  handleGetUsersSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getUsers API', apiResponse?.errors)
    } else {
      console.log('Response for getUsers API', apiResponse);
      this.setState({
        usersList: apiResponse?.data,
        currentPage: 1,
        noOfPages: Math.ceil(apiResponse?.data.length / 6)
      })
    }
  }

  handleGetUsersAPICallIdResponse = (message:Message) => {
    if (this.getUsersAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetUsersSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleDeleteUserSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for deleteUser API', apiResponse?.errors)
      this.setState({
        userDeleteMessage: 'User is not deleted',
      })
    } else {
      console.log('Response for deleteUser API', apiResponse);
      this.setState({
        userDeleteMessage: 'User is deleted',
      })
      this.handleGetUsers()
    }
  }

  handleDeleteUserAPICallIdResponse = (message:Message) => {
    if (this.deleteUserAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteUserSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  handleSaveUsersAlreadyCreatedUsersSubNestedFunc = (apiResponse:any) => {
    if(apiResponse?.meta?.already_created_account.length>0){
      this.setState({
        alreadyCreatedUsers: apiResponse?.meta?.already_created_account[0],
        saveUsersValidation: false,
        userSettingAddUserName: '',
        userSettingAddUserDesignation: '',
        userSettingAddUserEmailId: '',
        userSettingAddUserRole: '',
        userSettingNameRequiredError: '',
        userSettingDesignationRequiredError: '',
        userSettingEmailRequiredError: '',
        userSettingEmailInvalidError: '',
        userSettingRoleRequiredError: '',
      })
    }
    else{
      this.handleGetUsers()
      this.setState({
        saveUsersValidation: false,
        userSettingNameRequiredError: '',
        userSettingDesignationRequiredError: '',
        userSettingEmailRequiredError: '',
        userSettingEmailInvalidError: '',
        userSettingRoleRequiredError: '',
        userCreateMessage: 'User Is Created',
        catchUserSettingsCreatedUserId: apiResponse?.data?.find((item: any) => item?.id)?.id,
        openUserPermissionsModal : true,
      })
    }
  }

  handleSaveUsersSuccessAndErrorsNestedFunc =  (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for saveUser API', apiResponse?.errors)
      this.setState({
        userCreateMessage: 'User is not created',
      })
    } else {
      this.handleSaveUsersAlreadyCreatedUsersSubNestedFunc(apiResponse)
      this.setState({
        userSettingAddUserName: '',
        userSettingAddUserDesignation: '',
        userSettingAddUserEmailId: '',
        userSettingAddUserRole: '',
      })
    }
  }

  handleSaveUsersAPICallIdResponse = (message:Message) => {
  this.setState({isUserCreating : false})

    if (this.saveUsersAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleSaveUsersSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetDepartmentListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getDepartment API', apiResponse?.errors)
    } else {
      console.log('Response for getDepartment API', apiResponse);
      this.setState({
        departmentList: apiResponse
      })
    } 
  }

  handleGetDepartmentListAPICallIdResponse = (message:Message) => {
    if (this.getDepartmentListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetDepartmentListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleGetProductCategoriesSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getProductCategories API', apiResponse?.errors)
    } else {
      console.log('Response for getProductCategories API', apiResponse);
      this.setState({
        productCategoriesList: apiResponse
      })
    } 
  }

  handleGetProductCategoriesAPICallIdResponse = (message:Message) => {
    if (this.getProductCategoriesAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetProductCategoriesSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetLocationDropdownListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getLocationDropdown API', apiResponse?.errors)
    } else {
      console.log('Response for getLocationDropdown API', apiResponse);
      this.setState({
        locationDropdownList: apiResponse?.data
      })
      if(!this.state.locationDropdownList){
        this.setState({locationCreateMessagePr: 'No Location Found Create From Setting !!'})
      }
    } 
  }

  handleGetLocationDropdownListAPICallIdResponse = (message:Message) => {
    if (this.getLocationDropdownListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetLocationDropdownListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }
    


  


  handleGetUserAccessPermissionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getUserAccessPermission API', apiResponse?.errors)
      this.setState({
        userSettingPermissionUpdatedMessage: 'Error! User Permissions Not Updated!'
      })
    } else {
      console.log('Response for getUserAccessPermission API', apiResponse);
      this.setState({
        userPermissionsList1: apiResponse?.data?.attributes?.permission,
        editedCatchIdForUserPermissionsUpdation: '',
        openEditedUserPermissionsModal : false,
        createUserPermissionsValidation : false,
        isUserSettingPermissionsUpdating : false,
        editedCatchCoreUserCreatedAccountId : ''
      })
      this.handleGetUsers()
      this.setState({
        userSettingPermissionUpdatedMessage : 'User Permissions Updated!'
      })
    }
  }

  handleGetUserAccessPermissionAPICallIdResponse = (message:Message) => {
    this.setState({
      isUserSettingPermissionsUpdating : false,
    })

    if (this.getUserAccessPermissionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetUserAccessPermissionSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }


  handleSaveUserAccessPermissionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for saveUserAccessPermission API', apiResponse?.errors)
      this.setState({
        userPermissionsCreatedMessage:'Error! User Permissions Not Added'
      })
    } else {
      console.log('Response for saveUserAccessPermission API', apiResponse);
      this.handleGetUsers()
      this.setState({
        openUserPermissionsModal: false,
        selectedUserPermissions : [],
        selectAllPermissions : false,
        catchUserSettingsCreatedUserId: '',
        userPermissionsCreatedMessage:'User Permissions Added!'
      })
    }
  }

  handleSaveUserAccessPermissionAPICallIdResponse = (message:Message) => {
    this.setState({isUserPermissionsCreating: false})

    if (this.saveUserAccessPermissionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      console.log("Save Permissions Access 1", this.state.userPermissionsList1)
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleSaveUserAccessPermissionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  

  

  handleGetBudgetForListSuccessAndErrorsNestedFunc= (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetForList API', apiResponse?.errors)
    } else {
      console.log('Response for getBudgetForList API', apiResponse);
      this.setState({
        budgetForList: apiResponse
      })
    }
  }

  handleGetBudgetForListAPICallIdResponse = (message:Message) => {
    if (this.getBudgetForListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleGetBudgetForListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetRolesListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetForList API', apiResponse?.errors)
    } else {
      console.log('Response for getBudgetForList API', apiResponse);
      this.setState({
        rolesList: apiResponse
      })
    }
  }

  handleGetRolesListAPICallIdResponse = (message:Message) => {
    if (this.getRolesListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetRolesListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  //
  // dashboard receive methods definition start
  //

  handleDashboardSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.error('Error for Dashboard Records API', apiResponse?.errors);
    } else {
      console.log("dashboard all response: ", apiResponse);
      const data = apiResponse?.dashboard_records?.data?.filter((d: any) => d.attributes.status !== null);
      const len = Math.floor(data?.length / this.state.dashboardItemsPerPage);
      const dataCount = data?.length % this.state.dashboardItemsPerPage === 0 ? len : len + 1;
      this.setState({
        dashboardRecordResults: data,
        dashboardNoOfPages: dataCount,
        dashboardShowHeader: true
      })
      if(this.state.dashboardRecordResults?.length>0 && this.state.dashboardRecordResults?.length%this.state.dashboardItemsPerPage==0){
        this.setState({
          dashboardNoOfPages: this.state.dashboardRecordResults.length/this.state.dashboardItemsPerPage
        })
      }
      else{
        this.setState({
          dashboardNoOfPages: Math.floor(this.state.dashboardRecordResults?.length/this.state.dashboardItemsPerPage)+1
        })
      }


      if(!this.state.dashboardRecordResults){
        this.setState({
          dashboardNoRecordMessage: 'No record found !'
        })
      }
    }
  }


  handleDashboardApiCallIdResponse = (message: Message) => {
    if (this.dashboardApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleDashboardSuccessAndErrorsNestedFunc(apiResponse)
    }
  }



  handleDashboardEditPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editPurchaseRequisition API', apiResponse?.errors)
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition not updated"
      })
    } else {
      console.log('Response for editPurchaseRequisition API', apiResponse);
      this.setState({
        purchaseRequisitionEditMessage: "Purchase Requisition updated"
      },()=> {
        this.handleDashboardAllRecordsRequests()
        this.setState({editPurchaseRequisitionLoading: false})
        setTimeout(() => {
          this.handleEditRequisitionCloseModal()
        }, 1500);
      })
    }
  }

  handleDashboardEditPurchaseRequisitionAPICallIdResponse = (message:Message) => {
    if (this.dashboardEditPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDashboardEditPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleDashboardAcceptRejectActionsPurchaseRequisitionSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for accpetRejectActionPurchaseRequisition API: ', apiResponse?.errors)
      if(this.state.prAcceptRejectActionStatus === 'true'){
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR is not approved'
        })
      }else{
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR is not rejected'
        })
      }
    } else {
      if(this.state.prAcceptRejectActionStatus === 'true'){
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR is approved'
        },()=>{
          this.handleDashboardAllRecordsRequests();
          setTimeout(() => {
            this.handleShowRequisitionCloseModal()
          }, 1500);
        })
      }else{
        this.setState({
          prAcceptRejectActionStatusMessage: 'PR is rejected'
        },()=>{
          this.handleDashboardAllRecordsRequests();
          setTimeout(() => {
            this.handleShowRequisitionCloseModal()
          }, 1500);
        })
      }
    }
  }

  handleDashboardAcceptRejectActionsPurchaseRequisitionAPICallIdResponse = (message:Message) => {
    if (this.dashboardAcceptRejectActionsPurchaseRequisitionAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardAcceptRejectActionsPurchaseRequisitionSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleEditDashboardRfpSuccessAndErrorsNestedFunc = (apiResponse: any) => {
    if (apiResponse?.errors) {
      console.log('error for editRFP API', apiResponse?.errors)
      this.setState({
        rfpUpdateMessage: 'Request for Proposal is not updated',
      })
    } else {
      console.log('Response for editRFP API', apiResponse?.data);

      this.setState({isRfpUpdating : false})

      this.handleRfpSelectedRow(apiResponse?.data?.id);
      this.handleEditedRfpIdForPdfUpdation(apiResponse?.data?.id);
      this.handleEditedRfpCatchMailForSuppliers(apiResponse?.data?.id);

      this.setState({
        showIsEditRFPValidation: false,
        openEditedRfpShowErrorModal:false,
        rfpUpdateMessage: 'Request for Proposal is updated',
        openEditedRfpPdfSupplierScreenModal: true,
        isEditedRfpUpdationApiSuccessDone: true,
      });
      this.handleDashboardAllRecordsRequests()
    }
  }


  handleEditDashboardRfpAPICallIdResponse = (message: Message) => {
    this.setState({isRfpUpdating : false})

    if (this.editDashboardRfpAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditDashboardRfpSuccessAndErrorsNestedFunc(apiResponse)
    }
  }


  handleDashboardEditPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for Dashboard editPO API', apiResponse?.errors)
      this.setState({editedPoSuccessOrNotMessage: "Purchase Order Not Updated"})
    } else {
      console.log('giouve', apiResponse?.data);
      this.setState({
        openEditedPoPdfSupplierScreenModal:true,
        editedPoShippingAddress: '',
        editedPoBillingAddress: '',
        editedPoShippingAddressOptional: '',
        poEditing: false,
        editedPoSuccessOrNotMessage: 'Purchase Order Updated Successfully'
        //showIsEditRFPValidation: false,
      },()=> this.handleDashboardAllRecordsRequests())
    }  
  }

  handleDashboardEditPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.dashboardEditPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardEditPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)
      this.setState({
        poEditScreenActiveTabs: 0
      })
    }
  }

  handleDashboardAcceptRejectActionsPurchaseOrderSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for Dashboard PO accept-reject actions API : ', apiResponse?.errors)
    } else {
      this.setState({
        poAcceptRejectActionStatus: ''
      }, () => this.handleDashboardAllRecordsRequests());
    }
  }

  handleDashboardAcceptRejectActionsPurchaseOrderAPICallIdResponse = (message:Message) => {
    if (this.dashboardAcceptRejectActionsPurchaseOrderAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleDashboardAcceptRejectActionsPurchaseOrderSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleSettingsCategoryPagination = () => {
    if(this.state.showSettingsCategory?.length>0 && this.state.showSettingsCategory?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesCategory: this.state.showSettingsCategory?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        noOfPagesCategory: Math.floor(this.state.showSettingsCategory?.length/this.state.itemsPerPage)+1
      })
    }
  }

  showSettingsCategorySuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      console.log('Response for showPurchaseRequisition API', apiResponse);
      this.setState({
        showSettingsCategory: apiResponse?.data,
        currentPageCategory:1
      })
      this.handleSettingsCategoryPagination()
    } 
  }

  showSettingsCategoryAPICallResponse = (message:Message) => {
    if (this.showSettingsCategoryAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.showSettingsCategorySuccessErrorResponse(apiResponse)
     
    }
  }

  handleUnitMeasurePagination = () => {
    if(this.state.showSettingsUnitMeasurement?.length>0 && this.state.showSettingsUnitMeasurement?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesUnitMeasure: this.state.showSettingsUnitMeasurement?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        noOfPagesUnitMeasure: Math.floor(this.state.showSettingsUnitMeasurement?.length/this.state.itemsPerPage)+1
      })
    }
  }

  showSettingsUnitMeasurementSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      console.log('Response for showPurchaseRequisition API', apiResponse);
      this.setState({
        showSettingsUnitMeasurement: apiResponse?.data,
        currentPageUnitMeasure:1
      })
      this.handleUnitMeasurePagination()
    }

  }

  showSettingsUnitMeasurementAPICallResponse = (message:Message) => {
    if (this.showSettingsUnitMeasurementAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.showSettingsUnitMeasurementSuccessErrorResponse(apiResponse)
    }
  }

  showSettingsLocationSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for SettingsLocation API', apiResponse?.errors)
    } else {
      console.log('Response for SettingsLocation API', apiResponse);
      this.setState({
        showSettingsLocationSingleData : apiResponse.data,
      })
    }  
  }

  showSettingsLocationAPICallIdResponse = (message:Message) => {
    if (this.showSettingsLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.showSettingsLocationSuccessErrorResponse(apiResponse)
    }
  }

  SettingsLocationPagination = () => {
    if(this.state.showSettingsLocation?.length>0 && this.state.showSettingsLocation?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesSettingsLocation: this.state.showSettingsLocation?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        noOfPagesSettingsLocation: Math.floor(this.state.showSettingsLocation?.length/this.state.itemsPerPage)+1
      })
    }
  }

  showSettingsLocationIdSuccessErrorREsponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for showPurchaseRequisition API', apiResponse?.errors)
    } else {
      console.log('Response for showPurchaseRequisition API', apiResponse);
      this.setState({
        showSettingsLocation: apiResponse?.data,
        currentPageSettingsLocation: 1,
      })
      this.SettingsLocationPagination()
    } 
  }

  showSettingsLocationAPICallResponse = (message:Message) => {
    
    if (this.showSettingsLocationAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      console.log("Create Purchase Requisition Receive")
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.showSettingsLocationIdSuccessErrorREsponse(apiResponse)
    }
  }

  createLocationSettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createInformationSettings API', apiResponse?.errors)
      this.setState({
        locationCreateMessage: 'Location Settings is not created',
      })
    } else {
      console.log('Response for createInformationSettings API', apiResponse);
      this.setState({
        //showInformation: false,
        location:'',
        settingsAddress: '',
        settingsIdentificationCode: '',
        settingsGst: '',
        saveLocationsValidation: false,
        locationCreateMessage: 'Location Settings is created'
      },()=>{this.handleGetSettingsLocation()})
    }  
  }

  createLocationSettingsAPICallResponse = (message:Message) => {
    if (this.createLocationSettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      console.log("Create Information Settings Receive")
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.createLocationSettingsSuccessErrorResponse(apiResponse)
    }
  }

  editSettingsLocationSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editBudget API', apiResponse?.errors)
      this.setState({
        locationUpdateMessage: 'Location is not updated',
      })
    } else {
      console.log('Response for editBudget API', apiResponse);
      this.setState({
        openEditSettingLocationModal: false,
        locationUpdateMessage: 'Location is updated',
        //showIsEditRFPValidation: false,
      })
      this.handleGetSettingsLocation()
    } 
  }

  editSettingsLocationAPICallIdResponse = (message:Message) => {
   
    if (this.editSettingsLocationAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.editSettingsLocationSuccessErrorResponse(apiResponse)
 
    }
  }

  editSettingsCategoryAPICallIdSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editBudget API', apiResponse?.errors)
      this.setState({
        budgetEditMessage: 'Category is not updated',
        categoryUpdateMessage : 'Category is not updated'
      })
    } else {
      console.log('Response for editBudget API', apiResponse);
      this.setState({
        openEditSettingCategoryModal: false,
        budgetEditMessage: 'Category is updated',
        categoryUpdateMessage : 'Category is updated'
        //showIsEditRFPValidation: false,
      })
      this.handleGetDataCategorySettings()
    }
  }

  editSettingsCategoryAPICallIdResponse = (message:Message) => { 
    if (this.editSettingsCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.editSettingsCategoryAPICallIdSuccessErrorResponse(apiResponse)
      
    }
  }

  editSettingsUnitMeasurementAPICallIdSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for unit API', apiResponse?.errors)
      this.setState({
        unitMeasurementUpdateMessage: 'Unit is not updated',
      })
    } else {
      console.log('Response for unit API', apiResponse);
      this.setState({
        openEditSettingUnitMeasurementModal: false,
        unitMeasurementUpdateMessage: 'Unit is updated',
      },()=>{
        this.handleGetDataUnitMeasurementSettings()
      })
    }  
  }

  editSettingsUnitMeasurementAPICallIdResponse = (message:Message) => { 
    if (this.editSettingsUnitMeasurementAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.editSettingsUnitMeasurementAPICallIdSuccessErrorResponse(apiResponse)
    }
  }

  handleCreateCategorySettingsDuplicasyError= (apiResponse : any) => {
    if(apiResponse?.errors?.name){
      this.setState({
        categoryDuplicasyErrorMessage : 'Category already been taken *'
      })
    } else {
      this.setState({
        categoryAddMessage: 'Category is not Added',
        informationSettingsEditMessage: 'Information Settings is not updated',
        createSettingsCategoryMessage:"Category not created"
      })
    }
  }

  createCategorySettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.handleCreateCategorySettingsDuplicasyError(apiResponse)
      
    } else {
      console.log('Response for createInformationSettings API', apiResponse);
      this.setState({
        //showInformation: false,
        categorySettingCreatePost: false,
        categoryAddMessage: 'Category is Added',
        settingsCategoryRequiredField: '',
        settingsCategory: '',
        showIsCreateInformationValidation: false,
        informationSettingsEditMessage: 'Information Settings is updated',
        createSettingsCategoryMessage:"Category created"
      })
    }  
    this.handleGetDataCategorySettings()
  }

  createCategorySettingsAPICallResponse = (message:Message) => { 
    if (this.createCategorySettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      console.log("Create Information Settings Receive")
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.createCategorySettingsSuccessErrorResponse(apiResponse)
      
    }

  }



  handleCreateUnitMeasurementSettingsDuplicasyError = (apiResponse:any) => {
    if(apiResponse?.errors?.unit_of_measurment){
      this.setState({
        unitMeasurementSettingsDuplicasyMessage : 'Measurement already been taken *'
      })
    }
    this.setState({
      informationSettingsEditMessage: 'Information Settings is not updated',
      unitMeasurementCreateMessage:'Unit Measurement not created'
    })
  }

  createUnitMeasurementSettingsSuccessErrorResponse = (apiResponse:any) => {
    if(apiResponse?.errors){
      this.handleCreateUnitMeasurementSettingsDuplicasyError(apiResponse)
    } else {
      console.log('Response for createInformationSettings API', apiResponse);
      this.setState({
        unitMeasurementSettingPost: false,
        unitMeasurementCreateMessage: 'unit of measurement is created',
        settingsUnitMeasurementRequiredField:"",
        settingsUnitMeasurement: '',
        //showInformation: false,
        showIsCreateInformationValidation: false,
      })
    }  
    this.handleGetDataUnitMeasurementSettings()
  }

  createUnitMeasurementSettingsAPICallResponse = (message:Message) => { 
    if (this.createUnitMeasurementSettingsAPICall === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      console.log("Create Information Settings Receive")
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.createUnitMeasurementSettingsSuccessErrorResponse(apiResponse)
    }
  }

  

  //
  // dashboard receive methods definition end
  //



  //
  // Dashboard methods definitions start
  //

  handleDashboardAllRecordsRequests = (): void => {
    const token_localStorage = localStorage.getItem('authToken');

    const headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage
    };

    const dashboardAllRecordGetApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.dashboardApiCallId = dashboardAllRecordGetApiMsg.messageId;

    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/dashboards/fetch_all_records?dashboard_option=${this.state.dropDownItemValue}&dashboard_status=${this.state.dashboardMenuSelection}`);
      
    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

    dashboardAllRecordGetApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'GET');

    runEngine.sendMessage(dashboardAllRecordGetApiMsg.id, dashboardAllRecordGetApiMsg);
  };

  handleEditPRDepartment = (event: any) => {

    this.setState({
      selectedDepartmentPRIds: event.target.value
    });
    
  };

  isEditPurchaseRequisitionSaveAsDraftValidCheck = () => {
    if (this.state.selectedProductIds.length == 0) {
      this.setState({
        editedProductsRequiredError: "Select product(s) *",
      })
      return false;
    }
    return true;
  };
  
  handleEditPurchaseRequisitionSaveAsDraft = () => {
    if (!this.isEditPurchaseRequisitionSaveAsDraftValidCheck()) {
      this.setState({
        showIsEditPurchaseRequisitionValidation: true,
        // showIsCreateRFPValidation: true
      })
    }
  };

  handleAcceptRequisitionMyApprovalApiCall = () => {
    const acceptedRequisition = 'true';
    this.handleDashboardAcceptRejectPurchaseRequisition(acceptedRequisition)
  }

  handleRejectRequisitionMyApprovalApiCall = () => {
    const rejectedRequisition = 'false';
    
    this.handleDashboardAcceptRejectPurchaseRequisition(rejectedRequisition)
  }

  handleAcceptPurchaseOrderMyApprovalApiCall = (selectedData: any) => {
    this.setState({
      poAcceptRejectActionStatus : 'true'
    })
    this.handleDashboardAcceptRejectActionPurchaseOrder(selectedData,'approved', 'true')
  }

  handleRejectPurchaseOrderMyApprovalApiCall = (selectedData: any) => {
    this.setState({
      poAcceptRejectActionStatus : 'false'
    })
    this.handleDashboardAcceptRejectActionPurchaseOrder(selectedData, 'rejected', 'false')
  }

  handleEditPODepartment = (event: any) => {
    this.setState({
      selectedDepartmentPOIds: event.target.value
    });
  };


  handleEditPoTaxIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editedPoTaxId: event.target.value
    })
  };

  handleDashboardEditRequisitionOpenModal = (editedRequisition: any) => {
    console.log('In handleDashboardEditRequisitionOpenModel:', editedRequisition);
    let temp = editedRequisition?.attributes?.products?.data?.map((i: any, index: number) => {
      return i.id
    }) || [];
    this.setState({
      selectedDashboardProductIds: temp
    });
    this.setState({
        openEditRequisitionModal: true,
        editedPurchaseRequisition: editedRequisition,
        editedPrNumberDefault: editedRequisition?.attributes?.purchase_requisition_number,
        editedDurationDate: editedRequisition?.attributes?.pr_creation_date,
        editedDeliveryDate: editedRequisition?.attributes?.delivery_date,
        editedDepartment: editedRequisition?.attributes?.department_id?.id,
        editedLocation: editedRequisition?.attributes?.location_setting_id?.id,
        editedCatalougeItemPr: editedRequisition?.attributes?.requested_items_purchase_requisition,
        editedCatalougeItemProductRequisition: editedRequisition?.attributes?.product_purchase_requisitions,
        editedNonCatalougeItemPr: editedRequisition?.attributes?.non_catalogue_items,
        showIsEditPurchaseRequisitionValidation: false,
    })
    this.handleSelectedRow(editedRequisition?.id)
  };

  handleDashboardEditPurchaseRequisition = () => {
    if(!this.isDashboardEditPurchaseRequisitionValidCheck()){
      console.log("UPDATE PURCHASE REQUISITION DASHBOARD")
      this.setState({
        showIsEditPurchaseRequisitionValidation: true,
        // showIsCreateRFPValidation: true
      })
      return
    }
    this.setState({editPurchaseRequisitionLoading: true})
    const token_localStorage = localStorage.getItem('authToken') 
    
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      purchase_requisition: { 
        type: "purchase_creation",
        approved: "true",
        pr_creation_date: this.state.editedDurationDate,
        delivery_date: this.state.editedDeliveryDate,
        location: this.state.editedLocation,
        department_id: this.state.editedDepartment,
        product_purchase_requisitions_attributes: this.state.editedCatalougeItemPr.filter((value: any) => value?.id).map((value: any)=>{
          const { quantity, record_id, id } = value;
          return {
            id: record_id,
            product_id: id,
            quantity,
          }; 
        }),
        non_catalogue_items_attributes: this.state.editedNonCatalougeItemPr.filter((value: any) => value?.attributes?.isNew || value?.attributes?.is_non_catalague_item).map((value: any)=>{
          const id = value.id
          const name = value?.attributes?.name || value?.name
          const quantity = value?.quantity
          const is_non_catalague_item=  value?.attributes?.isNew || value?.is_non_catalague_item
          return {
            id,
            name,
            quantity,
            is_non_catalague_item,
          }
        }),
      }
    };
  
    const editPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardEditPurchaseRequisitionAPICallId = editPurchaseRequisitionApiMsg.messageId;
  
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${this.state.editedPurchaseRequisition.id}`
    );
  
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseRequisitionApiMsg.id, editPurchaseRequisitionApiMsg); 
  };

  handleDashboardAcceptRejectPurchaseRequisition = (options: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    this.setState({
      prAcceptRejectActionStatus: options
    })
    
    const httpBody = {
      purchase_requisition: {
        type: "purchase_creation",
        pr_creation_date: this.state.showPurchaseRequisition?.attributes?.pr_creation_date,
        delivery_date: this.state.showPurchaseRequisition?.attributes?.delivery_date,
        location_setting_id: this.state.showPurchaseRequisition?.attributes?.location_setting_id?.id,
        department_id: this.state.showPurchaseRequisition?.attributes?.department_id?.id,
        account_id: this.state.showPurchaseRequisition?.attributes?.account?.data?.id,
        approved: options,
        product_purchase_requisitions_attributes: this.state.showPurchaseRequisition?.attributes?.requested_items_purchase_requisition?.filter((value: any) => value?.id).map((value: any)=>{
          const { quantity, id, record_id } = value;
          return {
            quantity,
            product_id: id,
            id: record_id
          };
        }),
        non_catalogue_items_attributes: this.state.showPurchaseRequisition?.attributes?.non_catalogue_items?.filter((value: any) => value?.attributes?.isNew || value?.attributes?.is_non_catalague_item).map((value: any)=>{
          const id = value.id
          const name = value?.attributes?.name || value?.name
          const quantity = value?.quantity
          const is_non_catalague_item=  value?.attributes?.isNew || value?.is_non_catalague_item
          return {
            id,
            name,
            quantity,
            is_non_catalague_item,
          }
        }),
        dashboard_status: this.state.dashboardMenuSelection
      }
    };
  
    const actionsPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardAcceptRejectActionsPurchaseRequisitionAPICallId = actionsPurchaseRequisitionApiMsg.messageId;
  
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${this.state.showPurchaseRequisition?.id}`
    );
  
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    actionsPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(actionsPurchaseRequisitionApiMsg.id, actionsPurchaseRequisitionApiMsg); 
  };

  


  // rfp dashboard edit functions

  rfpAddExistingCatalogItemToFormData=(product:any, i:number, formData:any) =>{
    formData.append(`request_for_proposal_items_attributes[${i}][id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpAddNewCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`request_for_proposal_items_attributes[${i}][product_id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpDeleteCatalogItemFromFormData=(product:any, i:number, formData:any)=>{
    formData.append(`request_for_proposal_items_attributes[${i}][id]`, product?.id);
    formData.append(`request_for_proposal_items_attributes[${i}][_destroy]`, '1');
  }
  
  rfpAddExistingNonCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`rfp_non_catelogues_attributes[${i}][id]`, product?.id);
    formData.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpAddNewNonCatalogItemToFormData=(product:any, i:number, formData:any)=>{
    formData.append(`rfp_non_catelogues_attributes[${i}][name]`, product?.attributes?.name);
    formData.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
  }
  
  rfpDeleteNonCatalogItemFromFormData=(product:any, i:number, formData:any) =>{
    formData.append(`rfp_non_catelogues_attributes[${i}][id]`, product?.id);
    formData.append(`rfp_non_catelogues_attributes[${i}][_destroy]`, '1');
  }
  
  rfpAddProductToFormData=(product:any, i:number, formData:any)=>{
    switch (product?.type) {
      case "existing_catelog_items":
        this.rfpAddExistingCatalogItemToFormData(product, i, formData);
        break;
      case "newlyAdded_catelog_items":
        this.rfpAddNewCatalogItemToFormData(product, i, formData);
        break;
      case "deleted_catelog_items":
        this.rfpDeleteCatalogItemFromFormData(product, i, formData);
        break;
      case "existing_non_catelog_items":
        this.rfpAddExistingNonCatalogItemToFormData(product, i, formData);
        break;
      case "newlyAdded_non_catelog_items":
        this.rfpAddNewNonCatalogItemToFormData(product, i, formData);
        break;
      case "deleted_non_catelog_items":
        this.rfpDeleteNonCatalogItemFromFormData(product, i, formData);
        break;
      default:
        break;
    }
  }
  
  rfpAddProductsToFormDataMainDriverFunc=(products:any, formData:any) =>{
    for (let i = 0; i < products.length; i++) {
      this.rfpAddProductToFormData(products[i], i, formData);
    }
  }


  handleDashboardEditRFP = () => {
    if (!this.isEditRFPValidCheck()) {
      this.setState({
        showIsEditRFPValidation: true,
        openEditedRfpShowErrorModal:true
      })
      return
    }

    this.setState({isRfpUpdating : true})

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Edit RFP")
    let headers = {
      'token': token_localStorage,
    }

    const filteredFinalExistingcatelog  : any[] = this.state.editedRfpCatelogItems.filter((value: any) => value?.id && value?.request_for_proposal_id).map(items => ({ 
      ...items, 
      type: "existing_catelog_items" 
    }))

    const filteredFinalNewcatelog : any[] = this.state.editedRfpCatelogItems.filter((value: any) => value?.isNewlyAdded).map(items => ({ 
      ...items, 
      type: "newlyAdded_catelog_items" 
    }))

    const filteredFinalDeletedcatelog : any[] = this.state.rfpCatelogDeletedItemsArr.filter((value: any) => value?._destroy).map(items => ({
      ...items, 
      type: "deleted_catelog_items"
    }))

    const filteredFinalExistingNonCatelog  : any[] = this.state.editedRfpNonCatelogItems.filter((value: any) => value?.id && value?.request_for_proposal_id).map(items => ({
      ...items, 
      type: "existing_non_catelog_items"
    }))

    const filteredFinalNewNonCatelog : any[] = this.state.editedRfpNonCatelogItems.filter((value: any) => value?.attributes?.theInputIsNonCatalog).map(items => ({
      ...items, 
      type: "newlyAdded_non_catelog_items"
    }))

    const filteredFinalDeletedNoncatelog : any[] = this.state.rfpNonCatelogDeletedItemsArr.filter((value: any) => value?._destroy).map(items => ({
      ...items, 
      type: "deleted_non_catelog_items"
    }))


    const mainAllProductsArray : any[] = [
      ...filteredFinalExistingcatelog,
      ...filteredFinalNewcatelog,
      ...filteredFinalDeletedcatelog,
      ...filteredFinalExistingNonCatelog,
      ...filteredFinalNewNonCatelog,
      ...filteredFinalDeletedNoncatelog
    ]

    let formdata = new FormData();
    formdata.append("currency", this.state.editedRfpCurrency);
    formdata.append("creation_date", this.state.editedRfpCreationDate);
    formdata.append("due_date", this.state.editedRfpDueDate);
    formdata.append("desired_delivery_date", this.state.editedRfpDesiredDeliveryDate);
    if (this.state.editedRfpFileAttachment?.name) {
      formdata.append("file", this.state.editedRfpFileAttachment);
    }

    formdata.append("loation_setting_id", this.state.editedRfpLocation);
    formdata.append("department_id", this.state.editedRfpDepartment);
    formdata.append("supplier_id", this.state.editedRfpSelectExistingSupplier);
    formdata.append("email_of_supplier", this.state.editedRfpEmailOfSupplier);

    this.rfpAddProductsToFormDataMainDriverFunc(mainAllProductsArray, formdata)

    for (let pair of formdata.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    const editRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editDashboardRfpAPICallId = editRFPListApiMsg.messageId;

    console.log('Update ID :', this.state.editRfp?.id)
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_request_proposal/${this.state.editRfp?.id}`
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editRFPListApiMsg.id, editRFPListApiMsg);
  };


  handleDashboardAcceptRejectActionPurchaseOrder = (selectedData: any, selectedStatus: string, statusAcceptReject: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    let selectedPoProductsId: any[] = selectedData?.attributes?.products?.data?.map((product: any) => {
      return product?.id
    })

    console.log('selectedDataBudget', selectedData?.attributes?.budget);
    
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: selectedData?.attributes?.location,
            order_date: selectedData?.attributes?.order_date,
            status: selectedStatus,
            fob_point: selectedData?.attributes?.fob_point,
            shipping_address: selectedData?.attributes?.shipping_address,
            billing_address: selectedData?.attributes?.billing_address,
            gst_number: selectedData?.attributes?.tax_id,
            mode_of_transit: selectedData?.attributes?.mode_of_transit,
            comments_for_po: selectedData?.attributes?.comments_for_po,
            amount_calculation: selectedData?.attributes?.amount_calculation,
            // department_id: selectedData?.attributes?.
            budget_id: selectedData?.attributes?.budget?.id,
            comment: selectedData?.attributes?.comment,
            product_ids: selectedPoProductsId,
            approved : statusAcceptReject
        }
      }
    };

    const actionsPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardAcceptRejectActionsPurchaseOrderAPICallId = actionsPurchaseOrderApiMsg.messageId;
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${selectedData?.id}`
    );
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    actionsPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(actionsPurchaseOrderApiMsg.id, actionsPurchaseOrderApiMsg); 
  };


  handleDashboardEditPurchaseOrderOpenModal = (editedPO: any): void => {
    console.log('editedPO', editedPO)
    
    this.setState({
      openEditPurchaseOrderModal: true,
      editedPoId: editedPO?.id,
      editedPoNumber: editedPO?.attributes?.purchase_order_number,
      // editedPoAmountCalculation: editedPO?.attributes?.amount_calculation,
      editedPoBudget: editedPO?.attributes?.budget?.id,
      editedPoComment: editedPO?.attributes?.comment,
      editedPoCommentForPO: editedPO?.attributes?.comments_for_po,
      editedPoDepartment: editedPO?.attributes?.department?.id,
      editedPoLocation: editedPO?.attributes?.location,
      editedPoOrderDate: editedPO?.attributes?.order_date,
      editedPoDeliveryDate: editedPO?.attributes?.delivery_date,
      editedPoProductNonCatalouge:editedPO?.attributes?.non_catalogue_items,
      editedPoProductCatalouge:editedPO?.attributes?.product_purchase_order,
      editedPoTaxId:editedPO?.attributes?.taxation?.id,
      editedPoSupplier: editedPO?.attributes?.supplier?.data?.id,
      catchEditedPoMailOfSupplier:editedPO?.id,
      editedPoPaymentTermList: editedPO?.attributes?.supplier?.data?.attributes?.payment_terms?.data,
      editedPoShippingAddress: editedPO?.attributes?.shipping_address,
      editedPoBillingAddress: editedPO?.attributes?.billing_address,
      // editedPoBillingAddress: editedPO?.attributes?.billing_address,
      // editedPoGstNumber: editedPO?.attributes?.supplier?.data?.attributes?.gst_number,
    })
    this.handlePurchaseOrderSelectedRow(editedPO?.id)
  };

  isDashboardEditPurchaseOrderValidCheck = () => {
    let flag = true;
    if(this.state.editedPoSupplier === ""){
      this.setState({
        editedPurchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.editedPoOrderDate === ""){
      this.setState({
        editedPurchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.editedPoDeliveryDate === ""){
      this.setState({
        editedPurchaseOrderDeliveryDateRequiredField: "Select the Delivery Date *",
      })
      flag=false;
    }
    if(this.state.editedPoPaymentTermList.length === 0){
      this.setState({
        editedPurchaseOrderPaymentTermsRequiredField: "Select the Payment Terms *",
      })
      flag=false;
    }
    if(this.state.editedPoTaxId === ""){
      this.setState({
        editedPurchaseOrderTaxIdRequiredField: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.editedPoBillingAddress === ""){
      this.setState({
        editedPurchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.editedPoShippingAddress === ""){
      this.setState({
        editedPurchaseOrderShippingAddressRequiredField: "Enter the Shipping Address *",
      })
      flag=false;
    }
    if(this.state.editedPoBudget === ""){
      this.setState({
        editedPurchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.editedPoDepartment === ""){
      this.setState({
        editedPurchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.editedPoComment === ""){
      this.setState({
        editedPurchaseOrderCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.editedPoProductCatalouge.length === 0){
      this.setState({
        editedPurchaseOrderProductsRequiredField: "Select the Product *",
      })
      flag=false;
    }
    
    if(new Date(this.state.editedPoDeliveryDate)
    .getDate() < new Date(this.state.editedPoOrderDate)
    .getDate()){
      this.setState({
        editedPurchaseOrderDeliveryDateErrorFormat: 'Delivery Date must be bigger'
      })
      return flag
    }
    return flag;
  }  


  isDashboardEditPurchaseRequisitionValidCheck = () => {
    let flag=true;

    if(this.state.editedLocation === ""){
      this.setState({
        editedLocationRequiredError: "Enter location *",
      })
      flag=false;
    }
    if(this.state.editedDurationDate === ""){
      this.setState({
        editedDurationDateRequiredError: "Enter Duration date *",
      })
      flag=false;
    }
    if(this.state.editedDepartment === ""){
      this.setState({
        editedDepartmentRequiredError: "Enter department *",
      })
      flag=false;
    }
    if(this.state.editedDeliveryDate === ""){
      this.setState({
        editedDeliveryDateRequiredError: "Enter Delivery date *",
      })
      flag=false;
    }
    if(this.state.editedCatalougeItemPr?.length === 0){
      this.setState({
        editedCatalougeItemPrRequiredError: "Enter Catalouge item *",
      })
      flag=false;
    }
    return flag;
  }
  
  //
  // Dashboard methods defintions end
  //

  
  handleOpenDropdown = (): void => {
    this.setState({
      openDropdown: true
    })
  }
  
  handleCloseDropdown = (): void => {
    this.setState({
      openDropdown: false
    })
  }

  handleInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsSearchBySpecificValue : event.target.value
    })
  }
  handleCOFItemsInputChangeForSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      itemsCofSearchBySpecificValue: event.target.value
    })
  }

  handleCOFItemsClearSearchFunc = () => {
    const updatedItems = this.state.mainSearchCOFItemsKeysData?.map((item) => ({
      ...item,
      selected: false,
    }));

    this.setState({
      mainSearchCOFItemsKeysData: updatedItems,
      isCOFItemsMenuForSearchOpen : false,
      anchorElForCOFItems : null,
      itemsCofSearchBySpecificValue : '',
      cofItemsFilterMultiState : '',
    })
    this.getAllProductsList()
  }

  handleMainSearchClickFuncCall = () => {
    if(this.state.subTabValue === 1){
      this.getAllRFP()
    } else if(this.state.subTabValue === 2){
      this.handleGetAllPurchaseOrders()
    } else if(this.state.subTabValue === 3){
      this.handleGetAllReceipts()
    } else if(this.state.subTabValue === 4){
      this.getAllSuppliersList()
    } else if(this.state.subTabValue === 5){
      this.getBudgetList()
    } else {
      this.getAllPurchaseRequisitionsList()
    }
  }


  handleMainSearchClearAllClickFuncCall = () => {
    const updatedItemsOrigin = this.state.mainSearchKeysData?.map((elem) => ({
      ...elem,
      selected: false,
    }));

    this.setState({
      mainSearchKeysData : updatedItemsOrigin,
      filterMultiItemsState : "",
      itemsSearchBySpecificValue : "",
      anchorEl: null,
      isMenuForSearchOpen : false,
    }, ()=> this.handleMainSearchClickFuncCall())
  }



  handleMenuOpen = (event : any) => {
    this.setState({ isMenuForSearchOpen: true, anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ isMenuForSearchOpen: false });
  };

  handleMenuItemSelect = (value : string) => {
    const updatedItem = this.state.mainSearchKeysData?.map((elem) => ({
      ...elem,
      selected: elem.key === value,
    }));
    this.setState({
      mainSearchKeysData: updatedItem,
      filterMultiItemsState: value,
      isMenuForSearchOpen: false
    });
  };


  handleCOFItemsMenuOpen = (event : any) => {
    this.setState({ isCOFItemsMenuForSearchOpen: true, anchorElForCOFItems: event.currentTarget });
  };

  handleCOFItemsMenuItemSelectSearch = (value : string) => {
    this.setState({ cofItemsFilterMultiState: value, isCOFItemsMenuForSearchOpen: false });
  };

  handleCOFItemsMenuClose = () => {
    this.setState({ isCOFItemsMenuForSearchOpen: false });
  };

  handleCOFItemsMenuItemSelect = (selectedKey : string) => {
    const updatedItems = this.state.mainSearchCOFItemsKeysData?.map((item) => ({
      ...item,
      selected: item.key === selectedKey,
    }));
    this.setState({
      mainSearchCOFItemsKeysData: updatedItems,
      cofItemsFilterMultiState: selectedKey,
      isCOFItemsMenuForSearchOpen: false,
    });
  };

  handleDynaStatesDataChangeAfterSubTabChange = (tabValue: number) => {
    const defaultPrDataForSearch = [
      {
        lable : "PR Number",
        key : "purchase_requisition_number",
        selected : false
      },
      {
        lable : "Requester",
        key : "user_name",
        selected : false
      },
      {
        lable : "Creation Date",
        key : "pr_creation_date",
        selected : false
      },
      {
        lable : "Delivery Date",
        key : "delivery_date",
        selected : false
      },
      {
        lable : "Location",
        key : "name_of_location",
        selected : false
      },
      {
        lable : "Department",
        key : "department_id?.name",
        selected : false
      },
      {
        lable : "Status",
        key : "status",
        selected : false
      },
    ]

    const poSearchedData = [
      {
        lable : "PO Number",
        key : "purchase_order_number",
        selected : false
      },
      {
        lable : "Supplier",
        key : "supplier_name",
        selected : false
      },
      {
        lable : "Requester",
        key : "user_name",
        selected : false
      },
      {
        lable : "Due Date",
        key : "order_date",
        selected : false
      },
      {
        lable : "Location",
        key : "location",
        selected : false
      },
      {
        lable : "Department",
        key : "name",
        selected : false
      },
      {
        lable : "Status",
        key : "status",
        selected : false
      },
    ]

    const receiptSearchedData = [
      {
        lable : "Receipt Number",
        key : "receipt_number",
        selected : false
      },
      {
        lable : "PO Number",
        key : "purchase_order_number",
        selected : false
      },
      {
        lable : "Item Number",
        key : "po_number",
        selected : false
      },
      {
        lable : "Quantity Ordered",
        key : "quantity_required",
        selected : false
      },
      {
        lable : "Quantity Received",
        key : "quantity_received",
        selected : false
      },
      {
        lable : "Supplier Invoice Number",
        key : "supplier_invoice_number",
        selected : false
      },
      {
        lable : "Creation Date",
        key : "receipt_date",
        selected : false
      }
    ]

    const commonState = {
      itemsSearchBySpecificValue: '',
      filterMultiItemsState: '',
    };


   if (tabValue === 1) {
    this.setState({
      ...commonState,
      mainSearchKeysData: [
        {
          lable : "RFP Number",
          key : "rfp_number",
          selected : false
        },
        {
          lable : "Creation Date",
          key : "creation_date",
          selected : false
        },
        {
          lable : "Due Date",
          key : "due_date",
          selected : false
        },
        {
          lable : "Number of Suppliers",
          key : "number_of_supplier",
          selected : false
        },
        {
          lable : "Status",
          key : "status",
          selected : false
        }
      ],
    })
    } else if (tabValue === 2) {
      this.setState({
      ...commonState,
        mainSearchKeysData: poSearchedData
      })
    } else if (tabValue === 3) {
      this.setState({
        ...commonState,
        mainSearchKeysData: receiptSearchedData
      })
    } else if (tabValue === 4) {
      this.setState({
        ...commonState,
        itemsCofSearchBySpecificValue : '',
        cofItemsFilterMultiState : '',
        isCOFItemsMenuForSearchOpen : false,
        isMenuForSearchOpen : false, 

        mainSearchKeysData: [
          {
            lable : "Supplier Name",
            key : "supplier_name",
            selected : false
          },
          {
            lable : "Unique Code",
            key : "unique_code",
            selected : false
          },
          {
            lable : "Address of Supplier Company",
            key : "address_of_supplier_company",
            selected : false
          }
        ],
        mainSearchCOFItemsKeysData :  [
          {
            lable : "Item ID",
            key : "item_id",
            selected : false
          },
          {
            lable : "Supplier",
            key : "Supplier",
            selected : false
          },
          {
            lable : "Unit Measurement",
            key : "unit_measurement",
            selected : false
          },
          {
            lable : "Unit Price",
            key : "unit_price",
            selected : false
          },
          {
            lable : "Product Category",
            key : "product_category?.name,",
            selected : false
          },
          {
            lable : "Type",
            key : "item_type",
            selected : false
          }
        ],
      })
    } else if (tabValue === 5) {
      this.setState({
        itemsSearchBySpecificValue : '',
        filterMultiItemsState : '',
        mainSearchKeysData: [
          {
            lable : "Starting From",
            key : "starting_from",
            selected : false
          },
          {
            lable : "Name of Budget",
            key : "name",
            selected : false
          },
          {
            lable : "Budget Period",
            key : "period",
            selected : false
          },
          {
            lable : "Budget Amount",
            key : "amount",
            selected : false
          }
        ],
      })
    } else {
      this.setState({
        itemsSearchBySpecificValue : '',
        filterMultiItemsState : '',
        mainSearchKeysData: defaultPrDataForSearch
      })
    }
  }

  handleSubTabValueChange = (event: React.ChangeEvent<{}>, value: number): void => {
    this.setState({
      subTabValue: value
    }, ()=> this.handleDynaStatesDataChangeAfterSubTabChange(value))
  }

  handleGetAllDashboardOptions = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllDashboardOptionsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllDashboardOptionsAPICallId = getAllDashboardOptionsApiMsg.messageId;

    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_dashboard/dashboards/dashboard_options"
    );

    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllDashboardOptionsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getAllDashboardOptionsApiMsg.id, getAllDashboardOptionsApiMsg); 
  }

  handlePRStatusSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if (apiResponse?.errors) {
      console.error('Error for Dashboard Records API', apiResponse?.errors);
    } else {
      this.setState({
        prStatusList: apiResponse?.dashboard_options,
      })
    }
  }

  handleGetPRAllStatus = (message:Message) =>{
    if (this.getAllPRStatusesAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {

      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handlePRStatusSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  handleGetAllPRStatuses = () => {
    console.log("PR STATUS API")
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const getAllPRStatusesApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPRStatusesAPICallId = getAllPRStatusesApiMsg.messageId;

    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/status_options"
    );

    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllPRStatusesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
      // configJSON.getAllDashboardOptionsAPiMethod
    );
    runEngine.sendMessage(getAllPRStatusesApiMsg.id, getAllPRStatusesApiMsg); 
  }

  handleSubTabValueCreateOrFindChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({
        subTabValueCreateOrFind: value,
        checkedCreateOrFind: true,
    })
}

handleAddRequisitionOpenModal = () => {
  this.setState({
      openAddRequisitionModal: true,
      prNumberUnique: Math.floor(Math.random() * 900000000) + 100000000,

  })
}

handleAddReceiptOpenModal = () => {
  this.handleGetReciptsPurchaseOrdersForSelection()
  this.handleReceiptNewAutoGeneratedNumber()

  this.setState({
      openAddReceiptModal: true,
  })
}

handleAddSupplierOpenModal = () => {
  this.setState({
      openAddSupplierModal: true,
  })
}

handleAddProductOpenModal = () => {
  this.handleItemsAndSuppliersNewAutoGeneratedNumber();

  this.setState({
      openAddProductModal: true,
  })
  this.handleGetDataCategorySettings()
}

handleAddBudgetOpenModal = () => {
  this.getBudgetForList()
  this.setState({
      openAddBudgetModal: true,
  })
}

handleReceiptCreateShowErrorModalClose = () => {
  this.setState({
    openReceiptCreateShowErrorModal: false,
  })
}

handleAddReceiptCloseModal = () => {
  this.setState({
    openAddReceiptModal: false,
    showIsCreateReceiptValidation: false,
    receiptAttachmentFile: '',
    receiptSelectedPo: '',
    receiptReceiptDate: '',
    receiptInvoiceNumber:'',
    receiptAttachment:'',
    receiptSelectedPOPopulatedData: {},
    receiptCatelogueItemsStore:[],
    receiptNonCatelogueItemsStore: [],
    isReceiptCreating : false,
  })
}

handleAddRequisitionCloseModal = () => {
  this.setState({
      openAddRequisitionModal: false,
      showIsCreatePurchaseRequisitionValidation: false,
      deliveryDate: '',
      durationDate: new Date().getFullYear().toString().padStart(4, '0')+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+new Date().getDate().toString().padStart(2, '0'),
      department: '',
      location: '',
      status: '',
      products: [],
      prProduct: [],
      prNonCatalougeItems:'',
      prNumberUnique: '',
      purchaseRequisitionCreateMessage: ''
  })
}

handleAddSupplierCloseModal = () => {
  this.setState({
    openAddSupplierModal: false,
    showIsCreateSupplierValidation: false,
    supplierAddressOfSupplierCompany: '',
    supplierContactNumber: '',
    supplierEmailAddress: '',
    supplierGSTNumber: '',
    supplierPointOfContact: '',
    supplierSupplierLocation: '',
    supplierSupplierName: '',
    supplierTermsOfPayment: '',
    supplierUniqueCode: '',
    supplierStateProvince : '',
    supplierZipCodePinCode : '',
    paymentTermsNameSuppliers: []
  })
}

handleAddProductCloseModal = () => {
  this.setState({
    openAddProductModal: false,
    showIsCreateProductValidation: false,
    productCategory: '',
    productDescription: '',
    productName: '',
    productPartId: '',
    productTypeSelectedId: '',
    productUnitMeasurement: '',
    productUnitPrice: '',
    addItemSupplier : '',
    addItemSupplierMultiple: []
  })
}

handleEditPurchaseOrderCloseModal = () => {
  this.setState({
      openEditPurchaseOrderModal: false,
      openEditedPoPdfSupplierScreenModal:false,
      poEditScreenActiveTabs: 0,
      poCreateScreenActiveTabs: 0,
      poEditing: false,
      editedPoId: '',
      editedPoNumber: '',
      editedPoLocation: '',
      editedPoOrderDate: '',
      editedPoDeliveryDate: '',
      editedPoFobPoint: '',
      editedPoDepartment: '',
      editedPoProduct: [],
      editedPoComment: '',
      editedPoCommentForPO: '',
      editedPoAmountCalculation: '',
      editedPoGstNumber: '',
      editedPoBillingAddress: '',
      editedPoSupplier: '',
      editedPoPaymentTermsName: '',
      editedPoBudget: '',
      editedPoModeOfTransit: '',
      editedPoShippingAddress: '',
      editedPoShippingAddressOptional: '',
      poComment:'',
      editedPoSuccessOrNotMessage: ''
  })
}

handleAddPurchaseOrderCloseModal = () => {
  this.setState({
      openAddPurchaseOrderModal: false,
      poSaveDraft: false,
      showIsCreatePurchaseOrderValidation: false,
      openCreatePoShowErrorModal: false,
      filteredPoProducts: [],
      poCreateScreenActiveTabs: 0,
      poId: '',
      poLocation: '',
      poOrderDate: '',
      poFobPoint: '',
      poDepartment: '',
      poComment: '',
      poCommentForPO: '',
      poAmountCalculation: '',
      poGstNumber: '',
      poBillingAddress: '',
      poSupplier: '',
      poBudget: '',
      poModeOfTransit: '',
      poShippingAddress: '',
      selectPoOptions: '',
      paymentTermsName: '',
      deliveryDate: '',
      taxName: '',
      poNumberUnique: '',
      poViewScreenActiveTabs:0,
      paymentTermsNameSuppliers: [],
      openPoPdfSupplierScreenModal: false,
      openPoSupplierMailSeccessModal: false,
      purchaseOrderDraftMessage: '',
      poShippingAddressOptional: ''
  })
}

handleEditedPoSupplierMailSeccessCloseModal = () => {
  this.setState({
    isEditedPoMailSendConfirmed:false,
    editedPoOrderDate: '',
    deliveryDate: '',
    rfpFileAttachment: '',
    rfpSelectExistingSupplier: [],
    editedPoLocation: '',
    editedPoDepartment: '',
    poCreateScreenActiveTabs: 0,
    editedPoNumber: '',
    editedPoComment:'',
    openEditPurchaseOrderModal: false,
    poEditing:false,
    showIsEditPurchaseOrderValidation: false,
    // openCreatePoShowErrorModal: false,
    isEditedPoCreationAndApiSuccessDone: false,
    openEditedPoSupplierMailSeccessModal: false,
    editedPoFinalPdfDownloadOrNotMessage:'',
    isEditedPoMailSending:false,
    editedPoEmailToSupplierSentOrNotErrorMessage:'',
    // editedPoProduct: []
  })
}

handlePoSupplierMailSeccessCloseModal = () => {
  this.setState({
    poOrderDate: '',
    deliveryDate: '',
    poLocation: '',
    poDepartment: '',
    poCreateScreenActiveTabs: 0,
    poNumberUnique: '',
    filteredPoProducts:[],
    openAddPurchaseOrderModal: false,
    showIsCreatePurchaseOrderValidation: false,
    openCreatePoShowErrorModal: false,
    isPoCreationAndApiSuccessDone: false,
    isPoMailSendConfirmed:false,
    openPoSupplierMailSeccessModal: false,
    poFinalPdfDownloadOrNotMessage:'',
    isPoMailSending:false,
  })
}

handleAddPurchaseRequisitionOrderCloseModal = () => {
  this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      showIsCreatePrPurchaseOrderValidation: false,
      openCreatePoShowErrorModal: false,
      filteredPoProducts: [],
      poCreateScreenActiveTabs: 0,
      poId: '',
      poLocation: '',
      poOrderDate: '',
      poFobPoint: '',
      poDepartment: '',
      poComment: '',
      poCommentForPO: '',
      poAmountCalculation: '',
      poGstNumber: '',
      poBillingAddress: '',
      poSupplier: '',
      poBudget: '',
      poModeOfTransit: '',
      poShippingAddress: '',
      selectPoOptions: '',
      prPoNumberUnique: '',
      isPrPoCreationAndApiSuccessDone: false,
      poViewScreenActiveTabs:0

  })
}

handlePrPoSupplierMailSeccessCloseModal = () => {
  this.setState({
    poOrderDate: '',
    deliveryDate: '',
    poLocation: '',
    poDepartment: '',
    prPoCreateScreenActiveTabs: 0,
    poNumberUnique: '',
    openCustomFromRequisitionCreatePoModal: false,
    showIsCreatePrPurchaseOrderValidation: false,
    openCreatePrPoShowErrorModal: false,
    isPrPoCreationAndApiSuccessDone: false,
    isPrPoMailSendConfirmed:false,
    openPrPoSupplierMailSeccessModal: false,
    prPoFinalPdfDownloadOrNotMessage:'',
    isPrPoMailSending:false,
  })
}

handleAddCustomFromRequisitionPOCloseModal = () => {
  this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      showIsCreateCustomFromRequisitionValidation: false,
      customFromRequisitionPoId: '',
      //poLocation: '',
      customFromRequisitionPoOrderDate: '',
      customFromRequisitionPoFobPoint: '',
      //poDepartment: '',
      //poProduct: [],
      customFromRequisitionPoComment: '',
      customFromRequisitionPoCommentForPO: '',
      customFromRequisitionPoAmountCalculation: '',
      customFromRequisitionPoTaxId: '',
      customFromRequisitionPoBillingAddress: '',
      customFromRequisitionPoSupplier: '',
      customFromRequisitionPoBudget: '',
      customFromRequisitionPoModeOfTransit: '',
      customFromRequisitionPoShippingAddress: '',
      poBillingAddress: '',
      poShippingAddress: '',
      poGstNumber: '',
  })
}

handleAddBudgetCloseModal = () => {
  this.setState({
    openAddBudgetModal: false,
    showIsCreateBudgetValidation: false,
    budgetAmount: '',
    budgetComment: '',
    budgetFor: '',
    budgetName: '',
    budgetPeriod: '',
    budgetProductCategories: ''
  })
}

handleEditRequisitionOpenModal = (editedRequisition: any) => {
  let temp = editedRequisition?.attributes?.products?.data?.map((i: any, index: number) => {
    return i.id
  })
  this.setState({
    selectedProductIds: temp
  })
  this.setState({
      openEditRequisitionModal: true,
      editedPurchaseRequisition: editedRequisition,
      editedDurationDate: editedRequisition?.attributes?.pr_creation_date,
      editedDeliveryDate: editedRequisition?.attributes?.delivery_date,
      editedDepartment: editedRequisition?.attributes?.department_id?.id,
      editedLocation: editedRequisition?.attributes?.location_setting_id?.id,
      showIsEditPurchaseRequisitionValidation: false,
  })
  this.handleSelectedRow(editedRequisition?.id)
}

handleEditRequisitionCloseModal = () => {
  this.setState({
      openEditRequisitionModal: false,
  })
}

handleOpenNextPurchaseModal = () => {
  this.setState({
    openNextPurchaseModal : true,
    poCreateScreenActiveTabs: 1,
    poEditScreenActiveTabs: 1,
    poViewScreenActiveTabs:1,
  })
}

handlePoTabsScreenClose = () => {
  this.setState({
    poOrderDate: '',
    poDepartment: '',
    poComment: '',
    poCommentForPO: '',
    poAmountCalculation: '',
    poGstNumber: '',
    poBillingAddress: '',
    poSupplier: '',
    poBudget: '',
    poModeOfTransit: '',
    poShippingAddress: '',
    selectPoOptions: '',
    openAddPurchaseOrderModal: false,
    showIsCreatePurchaseOrderValidation: false,
    openCreatePoShowErrorModal: false,
    filteredPoProducts: [],
    poCreateScreenActiveTabs: 0,
    poId: '',
    poLocation: '',
    poFobPoint: '',
    poNumberUnique: '',
    openPoPdfSupplierScreenModal: false,
    openPoSupplierMailSeccessModal: false,
    poCreating: false,
    poViewScreenActiveTabs:0,
    paymentTermsNameSuppliers: [],
  })
}

handleCustomFromRequisitionCreatePONextModal = () => {
  this.setState({
    prPoCreateScreenActiveTabs : 1
  })
} 

handleEditSupplierCloseModal = () => {
  this.setState({
    openEditSupplierModal: false,
  })
}

handleEditProductCloseModal = () => {
  this.setState({
    openEditProductModal: false,
  })
}

handleEditBudgetCloseModal = () => {
  this.setState({
    openEditBudgetModal: false,
  })
}

handleRfpNewAutoGeneratedNumber = () => {
  const min = 100000000; 
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({rfpNumberUnique:  uniqueNumber });
}

checkFetchPaymentTermsOrNot = () =>{ 
  if(this.state.getPaymentTermListSupplier?.length === 0 ){
    this.setState({paymentTermsFetchMessage: 'Select Supplier First to Select PaymentTerms'})
  }else{
    this.setState({paymentTermsFetchMessage: ''})
  }
}

handleReceiptNewAutoGeneratedNumber = () => {
  const min = 100000000; 
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({receiptNumberUnique:  uniqueNumber });
}

handleItemsAndSuppliersNewAutoGeneratedNumber = () => {
  const min = 100000000;
  const max = 999999999;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const uniqueNumber = String(randomNumber).padStart(9, '0');
  this.setState({addItemSupplierNoUnique:  uniqueNumber });
}

handleAddRequestForProposalsOpenModal = () => {
  this.handleRfpNewAutoGeneratedNumber()
  
  this.setState({
      openAddRFPModal: true,
  })

  if(!this.state.locationDropdownList){
    this.setState({
      locationExistOrNotErrorMessage: 'No Existing Location Found!.. Please first create from location setting'
    })
  }
}

handleNextRFPMainCreateFieldsOpenModal = () => {
  this.setState({
    rfpCreateScreenActiveTabs: 1
  })
}


handleAddPurchaseOrderOpenModal = () => {
  this.handleGetInformationSettings()
  this.setState({
    openAddPurchaseOrderModal: true,
    poNumberUnique: Math.floor(Math.random() * 900000000) + 100000000
  })
}

handlePoLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poLocation: event.target.value
  })
}

handlePoSupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handlePoDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    console.log("valuedgie", value);
    
    this.setState({
      filteredPoProducts: [...this.state.filteredPoProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    poNonCatalougeCollectedItem: ''
  })
}


handleEditedPoProductsCatalougeQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {  
  console.log('event.target.value',event.target.value);
  let editedPoProductCatalougeStore = this.state.editedPoProductCatalouge.map((prod, idx) => {
    return product === prod ? {
    ...prod, quantity: event.target.value,
    } : prod
  })

  this.setState({editedPoProductCatalouge: editedPoProductCatalougeStore })
}

handleEditedPoProductsNonCatalougeQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  let editedPoProductNonCatalougeStore = this.state.editedPoProductNonCatalouge.map((prod, idx) => {
    return product === prod ? {
    ...prod, quantity: event.target.value,
    } : prod
  })
  this.setState({ editedPoProductNonCatalouge: editedPoProductNonCatalougeStore })
}

handleProductsEditPoChangeCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  console.log('value12121', value);
  if(value?.id){
    
    this.setState({editedPoProductCatalouge: [...this.state.editedPoProductCatalouge, {...value, quantity:'1'}]})
  }
  this.setState({editedPoNonCatlogItems:'',  isDeleteRecordPO: 0})
}

handleProductsEditPoChangeNonCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.theInputIsNonCatalog === true){
    this.setState({editedPoProductNonCatalouge: [...this.state.editedPoProductNonCatalouge, {...value, quantity:'1'}],})
  }
  this.setState({editedPoNonCatlogItems:'', isDeleteRecordPO: 0})
}

handleEditPoDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    this.setState({
      filteredPoProducts: [...this.state.filteredPoProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    editedPoNonCatalougeCollectedItem: ''
  })
}

getEditedSearchedPoNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoNonCatlogItems: event.target.value,
    editedPoNonCatalougeCollectedItem: event.target.value
  })
}

handlePoProductsQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const poProductsStore = [...this.state.filteredPoProducts]
  poProductsStore[index] = { ...this.state.filteredPoProducts[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ filteredPoProducts: poProductsStore })
}

handleEditedPoProductsDelete = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  this.editedPoProductDeleteHandler(product,index)
}

editedPoProductDeleteHandler = (product:any,index:number)=>{
  const filterEditedProductsAfterDelete = this.state.editedPoProductCatalouge?.map((pro: any, filteredIndex: number) => {
    if(Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 1};
    }else if(pro?._destroy === undefined || Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 0};
    }else{
      return {...pro};
    }
  });
  this.setState({
    editedPoProductCatalouge:filterEditedProductsAfterDelete
  })
}

handleEditedPoNonCataProductsDelete = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {

  const filterEditedProductsAfterDelete = this.state.editedPoProductNonCatalouge?.map((pro: any, filteredIndex: number) => {
    console.log("ioer",product, pro);
    
    if(Number(pro?.id) === Number(product?.id)){
      return {...pro,_destroy: 1};
    }else if(pro?._destroy === undefined){
      return {...pro,_destroy: 0};
    }else{
      return {...pro};
    }
  });

  this.setState({
    editedPoProductNonCatalouge: filterEditedProductsAfterDelete,
  })
}

handlePrPoProductsCataQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const prPoProductsStore = [...this.state.customFromRequisitionCataItemData]
  prPoProductsStore[index] = { ...this.state.customFromRequisitionCataItemData[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ customFromRequisitionCataItemData: prPoProductsStore})
}

handlePrPoProductsNonCataQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const prPoProductsStore = [...this.state.customFromRequisitionNonCataItemData]
  prPoProductsStore[index] = { ...this.state.customFromRequisitionNonCataItemData[index], ...product, product_id: product?.id, quantity: event.target.value }
  this.setState({ customFromRequisitionNonCataItemData: prPoProductsStore })
}

getSearchedPoNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poNonCatalougeCollectedItem: event.target.value,
    editedPoNonCatalougeCollectedItem: event.target.value
  })
}

handlePoProductsDelete = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  this.CommonPoProductDelete(index)
}

CommonPoProductDelete =(index:number)=>{
  const filteredProductsAfterDelete = this.state.filteredPoProducts?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  this.setState({
    filteredPoProducts: filteredProductsAfterDelete,
  })
}

handlePoOrderDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poOrderDate: event.target.value,
    deliveryDate: ''
  })
}

handlePoFobPointChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poFobPoint: event.target.value
  })
}

handlePoShippingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poShippingAddress: event.target.value
  })
}

handlePoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poShippingAddressOptional: event.target.value
  })
}

handlePrPoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    prPoShippingAddressOptional: event.target.value
  })
}

handleEditPoShippingAddressOptionalChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoShippingAddressOptional: event.target.value
  })
}

handlePoBillingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poBillingAddress: event.target.value
  })
}

handlePoGstNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    //poTaxId: event.target.value
    poGstNumber: event.target.value
  })
}

handlePoModeOfTransitChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poModeOfTransit: event.target.value
  })
}

handlePoProductChange = (event: any) => {
  this.setState({
    poProduct: event.target.value
  })
}

handlePoCommentForPoChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poCommentForPO: event.target.value
  })
}

handlePoAmountCalculationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poAmountCalculation: event.target.value
  })
}

handlePoDepartmentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poDepartment: event.target.value
  })
}

handlePoBudgetChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poBudget: event.target.value
  })
}

handlePoCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    poComment: event.target.value
  })
}


handleEditPoLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoLocation: event.target.value
  })
}

handleEditPoSupplierChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handleEditPoOrderDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoOrderDate: event.target.value,
    editedPoDeliveryDate: ''
  })
}

handleEditPoDeliveryDateChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoDeliveryDate: event.target.value,
    editedPoOrderDate: (this.state.editedPoOrderDate && this.state.editedPoOrderDate > event.target.value) ? '' : this.state.editedPoOrderDate
  })
}

handleEditPoPaymentTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoPaymentTermsName: event.target.value
  })
}

handleEditPoFobPointChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoFobPoint: event.target.value
  })
}

handleEditPoShippingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoShippingAddress: event.target.value
  })
}

handleEditPoBillingAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoBillingAddress: event.target.value
  })
}

handleEditPoGstNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoGstNumber: event.target.value
  })
}

handleEditPoModeOfTransitChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoModeOfTransit: event.target.value
  })
}

handleEditPoProductChange = (event: any) => {
  this.setState({
    editedPoProduct: event.target.value
  })
}

handleEditPoCommentForPoChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoCommentForPO: event.target.value
  })
}

handleEditPoAmountCalculationChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoAmountCalculation: event.target.value
  })
}

handleEditPoDepartmentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoDepartment: event.target.value
  })
}

handleEditPoBudgetChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoBudget: event.target.value
  })
}

handleEditPoCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoComment: event.target.value
  })
}

handlePurchaseRequisitionSearchLocationChnage = (event: ChangeEvent<HTMLInputElement>) => {
  this.setState({
    purchaseRequisitionSearchLocation: event.target.value
  })
}

handleAddRFPProductsScreenCloseModal = () => {
  this.setState({
    rfpDesiredDeliveryDate: '',
    rfpSelectExistingSupplier: [],
    rfpCurrency: '',
    rfpCreateScreenActiveTabs: 0,
    rfpDueDate: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpFileAttachment: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    filteredRfpProducts: [],
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSending:false,
    isRfpMailSendConfirmed:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    openAddRFPModal: false,
  })
}


handleAddRequestForProposalsCloseModal = () => {
  this.setState({
    rfpCurrency: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpSelectExistingSupplier: [],
    rfpFileAttachment: '',
    rfpCreateScreenActiveTabs: 0,
    filteredRfpProducts: [],
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSending:false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSendConfirmed:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    openAddRFPModal: false,
  })
}


handleRfpPdfSuccessCloseModal = () => {
  this.setState({
    openRfpPdfSuccessModal:false
  })
}

handleRfpSupplierMailSeccessCloseModal = () => {
  this.setState({
    rfpCurrency: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpFileAttachment: '',
    rfpSelectExistingSupplier: [],
    rfpLocation: '',
    rfpDepartments: '',
    rfpCreateScreenActiveTabs: 0,
    openAddRFPModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    rfpAttachmentDropdownDisplay: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSendConfirmed:false,
    openRfpSupplierMailSeccessModal: false,
    rfpFinalPdfDownloadOrNotMessage:'',
    isRfpMailSending:false,
  })
}


handleEditRFPOpenModalPdfAttachNestedFunc = (editedRFP:any) => {
  if(editedRFP?.attributes?.pdf_attach === null){
    this.setState({
      editedRfpAttachment: ''
    })
  }
  else{
    this.setState({
      editedRfpAttachment: editedRFP?.attributes?.pdf_attach
    })
  }
}

handleEditRFPOpenModal = (editedRFP: any): void => {

  let editedCatelogList = editedRFP?.attributes?.request_for_proposal_products?.map((catelog: any, index: number) => {
    return catelog
  })

  let editedNonCatelogList = editedRFP?.attributes?.rfp_non_catelogues?.map((nonCatelog: any, index: number) => {
    return nonCatelog
  })

  console.log("editedCatelogList", editedCatelogList);
  

  this.setState({
    isFileEdited: false,
    openEditRFPModal: true,
    editRfp: editedRFP,
    editedRfpId: editedRFP?.id,
    editedRfpStatus: editedRFP?.attributes?.status,
    editedExistingRfpNumber: editedRFP?.attributes?.rfp_number,
    editedRfpCurrency: editedRFP?.attributes?.currency,
    editedRfpCreationDate: editedRFP?.attributes?.creation_date,
    editedRfpDueDate: editedRFP?.attributes?.due_date,
    editedRfpDesiredDeliveryDate: editedRFP?.attributes?.desired_delivery_date,
    editedRfpLocation: editedRFP?.attributes?.location_setting?.id,
    editedRfpDepartment : editedRFP?.attributes?.department?.id,
    editedRfpSelectExistingSupplier: editedRFP?.attributes?.supplier?.id,
    editedRfpEmailOfSupplier: editedRFP?.attributes?.email_of_supplier !== null && editedRFP?.attributes?.email_of_supplier !== undefined ? editedRFP?.attributes?.email_of_supplier : '',
    editedRfpFileAttachment: editedRFP?.attributes?.file,
    editedRfpCatelogItems: editedCatelogList,
    editedRfpNonCatelogItems : editedNonCatelogList,
  })

  this.handleRfpSelectedRow(editedRFP?.id)
}


handleCloseAlreadyCreatedUsersSnackbar = () => {
  this.setState({
    alreadyCreatedUsers: []
  })
}

handleEditPurchaseOrderOpenModal = (editedPO: any): void => {
  console.log('editedPO ', editedPO)
  let editedPoProductsId: any[] = editedPO?.attributes?.products?.data?.map((product: any) => {
    return product?.id
  })

  this.setState({
    openEditPurchaseOrderModal: true,
    editedPoId: editedPO?.id,
    editedPoAmountCalculation: editedPO?.attributes?.amount_calculation,
    editedPoBudget: editedPO?.attributes?.budget,
    editedPoComment: editedPO?.attributes?.comment,
    editedPoLocation: editedPO?.attributes?.location,
    //editedPoBillingAddress: editedPO?.attributes?.billing_address,
    editedPoModeOfTransit: editedPO?.attributes?.mode_of_transit,
    editedPoOrderDate: editedPO?.attributes?.order_date,
    editedPoProduct: editedPoProductsId,
    editedPoSupplier: editedPO?.attributes?.supplier?.data?.id,
    editedPoPaymentTermsName: editedPO?.attributes?.payment,
    poShippingAddress: editedPO?.attributes?.shipping_address,
    poGstNumber: editedPO?.attributes?.gst_number,
    editedPoCommentForPO: editedPO?.attributes?.comments_for_po,
    editedPoDepartment: editedPO?.attributes?.department?.id,
    editedPoFobPoint: editedPO?.attributes?.fob_point,
  })
  this.handlePurchaseOrderSelectedRow(editedPO?.id)
}

handleEditReceiptOpenModalPdfAttachNestedFunc = (editedReceipt:any) => {
  if(editedReceipt?.attributes?.pdf_attach === null){
    this.setState({
      editedReceiptAttachment: ''
    })
  }
  else{
    this.setState({
      editedReceiptAttachment: editedReceipt?.attributes?.pdf_attach
    })
  }
}

handleEditReceiptOpenModal = (editedReceipt: any): void => {
  this.handleGetAllPurchaseOrders()

  this.handleEditReceiptOpenModalPdfAttachNestedFunc(editedReceipt)
  this.setState({
    isReceiptFileEdited: false,
    openEditReceiptModal: true,
    editedReceiptId: editedReceipt?.id,
    editedReceiptSelectedPo: editedReceipt?.attributes?.purchase_order?.id,
    editedReceiptQuantityReceived: editedReceipt?.attributes?.quantity_received,
    editedReceiptQuantityRequested: editedReceipt?.attributes?.quantity_requested,
    editedReceiptComments: editedReceipt?.attributes?.comments,
    editedReceiptReceiptDate: editedReceipt?.attributes?.receipt_date,
    editedReceiptAttachmentFile: editedReceipt?.attributes?.file,
    //editedReceiptAttachment: editedReceipt?.attributes?.pdf_attach,
  })
  this.handleReceiptSelectedRow(editedReceipt?.id)
}

handleEditSupplierOpenModal = (editedSupplier: any): void => {
  console.log('editedSupplier', editedSupplier);
  
  this.setState({
    openEditSupplierModal: true,
    editedSupplierId: editedSupplier?.id,
    editedSupplierAddressOfSupplierCompany: editedSupplier?.attributes?.address_of_supplier_company,
    editedSupplierContactNumber: editedSupplier?.attributes?.contact_number,
    editedSupplierEmailAddress: editedSupplier?.attributes?.email_address,
    editedSupplierGSTNumber: editedSupplier?.attributes?.gst_number,
    editedSupplierPointOfContact: editedSupplier?.attributes?.point_of_contact,
    editedSupplierSupplierName: editedSupplier?.attributes?.supplier_name,
    editedSupplierTermsOfPayment: editedSupplier?.attributes?.terms_of_payment,
    editedSupplierUniqueCode: editedSupplier?.attributes?.unique_code,
    editedSupplierStateProvince: editedSupplier?.attributes?.state,
    editedSupplierZipCodePinCode: editedSupplier?.attributes?.zip_code,
    editedSupplierPaymentList: editedSupplier?.attributes?.payment_terms?.data
  })
  this.handleSupplierSelectedRow(editedSupplier?.id)
}

handleEditProductOpenModal = (editedProduct: any): void => {
  console.log('editedProduct', editedProduct);
  
  this.setState({
    openEditProductModal: true,
    editedProductId: editedProduct?.id,
    editedProductCategory: editedProduct?.attributes?.product_category?.id,
    editedProductDescription: editedProduct?.attributes?.description,
    editedProductName: editedProduct?.attributes?.name,
    editedAddNotes: editedProduct?.attributes?.name,
    editedProductPartId: editedProduct?.attributes?.part_id,
    editedProductTypeSelectedId: editedProduct?.attributes?.product_type?.id,
    editedProductUnitMeasurement: editedProduct?.attributes?.unit_measurement,
    editedProductUnitPrice: editedProduct?.attributes?.unit_price,
    editedItemSupplierMultiple: editedProduct?.attributes?.supplier?.data

  })
  this.handleProductSelectedRow(editedProduct?.id)
}

handleEditBudgetOpenModal = (editedBudget: any): void => {
  this.setState({
    openEditBudgetModal: true,
    editedBudgetId: editedBudget?.id,
    editedBudgetFor: editedBudget?.attributes?.budget_for?.name,
    editedBudgetForSelectedId: editedBudget?.attributes?.budget_for?.id,
    editedBudgetProductCategories: editedBudget?.attributes?.product_category?.id,
    
    editedBudgetStartingDate: editedBudget?.attributes?.starting_from,
    editedBudgetName: editedBudget?.attributes?.name,
    editedBudgetProductCategoriesId: editedBudget?.attributes?.product_category?.id,
    editedBudgetPeriod: editedBudget?.attributes?.period,
    editedBudgetAmount: editedBudget?.attributes?.amount,
    editedBudgetComment: editedBudget?.attributes?.comment,

  })
  this.handleBudgetSelectedRow(editedBudget?.id)
}

handleEditSettingLocationOpenModal = (data: any): void => {
  console.log(data,"oooo")
  this.setState({
    openEditSettingLocationModal: true,
    editSettingsLocation:data.attributes?.name_of_location,
    editSettingsAddress:data.attributes?.adderss,
    editSettingsIdentificationCode:data.attributes?.location_identification_code,
    editSettingsGst:data.attributes?.gst_number,
    editSettingsId : data.id
  })
  this.handleShowSettingsLocation(data?.id)
}

handleEditSettingCategoryOpenModal = (data: any): void => {
  this.setState({
    openEditSettingCategoryModal: true,
    editSettingsCategoryName:data.attributes?.name,
    editSettingsCategorySL:data.attributes?.serial_number,
    editSettingsCategoryId : data.id
  })
  // this.handleShowSettingsLocation(data?.id)
}

handleEditSettingUnitMeasurementOpenModal = (data: any): void => {
  this.setState({
    openEditSettingUnitMeasurementModal: true,
    editSettingsUnitMeasurement:data.attributes?.unit_of_measurment,
    editSettingsUnitMeasurementSL:data.attributes?.sr_number,
    editSettingsUnitMeasurementId : data.id
  })
  this.handleShowSettingsLocation(data?.id)
}

handleEditRFPCloseModal = () => {
  this.setState({
    openEditRFPModal: false,
    showIsEditRFPValidation: false,
  })
}

handleEditReceiptCloseModal = () => {
  this.setState({
    openEditReceiptModal: false,
  })
}

handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
      checkedCreateOrFind: false
  })
}

handleFile = (event: any) => {
  this.setState({
    uploadedFileRequestForProposals: event.target.file[0]
  })
}

handleDurationDateChange = (event: any) => {
  this.setState({
    durationDate: event.target.value,
    deliveryDate: ''
  })
}

handleDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    deliveryDate: event.target.value,
    poOrderDate: (this.state.poOrderDate && this.state.poOrderDate > event.target.value) ? '' : this.state.poOrderDate,
  })
}

handlePrDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    deliveryDate: event.target.value,
    durationDate: (this.state.durationDate && this.state.durationDate > event.target.value) ? '' : this.state.durationDate,
  })
}

handleDeliveryDateTypeFocus = () => {
  this.setState({
    deliveryDateType: 'date'
  })
}

handleDeliveryDateTypeBlur = () => {
  this.setState({
    deliveryDateType: 'text'
  })
}

handleDurationDateTypeFocus = () => {
  this.setState({
    durationDateType: 'date'
  })
}

handleDurationDateTypeBlur = () => {
  this.setState({
    durationDateType: 'text'
  })
}

handlePoOrderDateTypeFocus = () => {
  this.setState({
    poOrderDateType: 'date'
  })
}

handlePoOrderDateTypeBlur = () => {
  this.setState({
    poOrderDateType: 'text'
  })
}

handleReceiptReceiptDateTypeFocus = () => {
  this.setState({
    receiptDateType: 'date'
  })
}

handleSelectPoOptions = (event: React.ChangeEvent<HTMLSelectElement>) => {
  this.setState({selectPoOptions: event.target.value})
}

handleReceiptReceiptDateTypeBlur = () => {
  this.setState({
    receiptDateType: 'text'
  })
}

handleEditedReceiptReceiptDateTypeFocus = () => {
  this.setState({
    editedReceiptDateType: 'date'
  })
}

handleEditedReceiptReceiptDateTypeBlur = () => {
  this.setState({
    editedReceiptDateType: 'text'
  })
}

handleEditPoOrderDateTypeFocus = () => {
  this.setState({
    editedPoOrderDateType: 'date'
  })
}

handleEditPoOrderDateTypeBlur = () => {
  this.setState({
    editedPoOrderDateType: 'text'
  })
}

handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');
  this.setState({
    location: restrictedTextValue
  });
}

handlePrLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 this.setState({
    location: event.target.value
  })
}

handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    department: event.target.value
  })
}

handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    status: event.target.value
  })
}

handlePrNonCatalougeItemsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    prNonCatalougeItems: event.target.value
  })
}

handlePrQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const temp = [...this.state.prProduct]
  temp[index] = {...this.state.prProduct[index],...product,product_id: product?.id, quantity: event.target.value}
  this.setState({ prProduct: temp })
}
handleEditPrQuantityNonCatalouge = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  // NON CATALOUGE ITEM QUANTITY
  const temp2 = [...this.state.editedNonCatalougeItemPr]
  temp2[index] = {...this.state.editedNonCatalougeItemPr[index],...product, quantity: event.target.value}
  this.setState({ editedNonCatalougeItemPr: temp2 })
}

handleEditPrQuantityCatalouge = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  // CATALOUGE ITEM QUANTITY
  const temp1 = [...this.state.editedCatalougeItemPr]
  temp1[index] = {...this.state.editedCatalougeItemPr[index],...product, _destroy: 1, quantity: event?.target?.value}
  this.setState({ editedCatalougeItemPr: temp1 })
}

handleProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value){
    this.setState({
      prProduct: [...this.state.prProduct,{...value, quantity:'1'}],
    })
  }
  this.setState({prNonCatalougeItems: ''})
}

handleProductsChangeCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.id){
    this.setState({editedCatalougeItemPr: [...this.state.editedCatalougeItemPr, {...value, quantity:'1',_destroy: 1}]})
  }
  this.setState({prEditNonCatalougeItems:''})
  if(this.state.productsList.length === 0){
    this.setState({productsFoundOrNotMessage: 'No Product Found Create It'})
  }else{
    this.setState({productsFoundOrNotMessage: ''})
  }
}

handleProductsChangeNonCatalouge = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.isNew === true){
    this.setState({editedNonCatalougeItemPr: [...this.state.editedNonCatalougeItemPr, {...value, quantity:'1'}],})
  }
  this.setState({prEditNonCatalougeItems:''})
}

removeTableCellPR = (event:React.MouseEvent<HTMLButtonElement>,product: any, index: number) => {
  this.prTableCellRemoveHandler(index)
}

prTableCellRemoveHandler = (index:number)=>{
  const filteredProducts = this.state.prProduct?.filter((pro:any, proIndex: number)=>(index !== proIndex))
  this.setState({
    prProduct:filteredProducts,
  })
}

removeTableCellPRNonCatalouge = (event:React.MouseEvent<HTMLButtonElement>,product: any, index: number) => {
  this.handleRemoveTableCellPrNonCatalouge(index)
}

handleRemoveTableCellPrNonCatalouge = (index:number)=>{
  const filteredProductsPrNonCatalouge = this.state.editedNonCatalougeItemPr?.filter((pro:any, proIndex: number)=>(index !== proIndex))
  this.setState({
    editedNonCatalougeItemPr: filteredProductsPrNonCatalouge,
  })
}

removeTableCellPRCatalouge = (event:React.MouseEvent<HTMLButtonElement>,product: any, index: number) =>{ 
  this.handleRemoveTableCellPrCatalouge(index)
}
handleRemoveTableCellPrCatalouge = (index:number)=>{
  const filteredProductsPrCatalouge = this.state.editedCatalougeItemPr?.filter((pro:any, proIndex: number)=>(index !== proIndex))
  this.setState({
    editedCatalougeItemPr:filteredProductsPrCatalouge,
  })
}
handleProductSelected = (e: React.ChangeEvent<HTMLInputElement>, product?: any) =>{  
  if(product?.id === e.target.value){
    this.setState({
      prCatalougeItemSelectedRow: e.target.checked 
    })
  }
}

handleEditDurationDate = (event: any) => {
  this.setState({
    editedDurationDate: event.target.value,
    editedDeliveryDate: '',
  })
}

handleStartingFromDate = (event: any) => {
  this.setState({
    startingFromDate: event.target.value
  })
}

handleEditBudgetStartingDate = (event: any) => {
  this.setState({
    editedBudgetStartingDate: event.target.value
  })
}

handleEditBudgetName = (event: any) => {
  this.setState({
    editedBudgetName: event.target.value
  })
}

handleEditBudgetProductCategoriesId = (event: any) => {
  this.setState({
    editedBudgetProductCategoriesId: event.target.value
  })
}

handleEditBudgetPeriod = (event: any) => {
  this.setState({
    editedBudgetPeriod: event.target.value
  })
}

handleEditBudgetAmount = (event: any) => {
  this.setState({
    editedBudgetAmount: event.target.value
  })
}

handleEditBudgetComment = (event: any) => {
  this.setState({
    editedBudgetComment: event.target.value
  })
}

handleEditDeliveryDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedDeliveryDate: event.target.value,
    editedDurationDate: (this.state.editedDurationDate && this.state.editedDurationDate > event.target.value) ? '' : this.state.editedDurationDate,
  })
}

handleEditRequester = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRequester: event.target.value
  })
}

handleEditDurationDateTypeFocus = () => {
  this.setState({
    editedDurationDateType: 'date'
  })
}

handleEditDeliveryDateTypeFocus = () => {
  this.setState({
    editedDeliveryDateType: 'date'
  })
}

handleEditDurationDateTypeBlur = () => {
  this.setState({
    editedDurationDateType: 'text'
  })
}

handleEditDeliveryDateTypeBlur = () => {
  this.setState({
    editedDeliveryDateType: 'text'
  })
}

handleEditLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedLocation: event.target.value
  })
}

handleEditStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedStatus: event.target.value
  })
}

handleEditDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedDepartment: event.target.value
  })
}

handleEditBudgetapi = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudget: event.target.value
  })
}

handleEditSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplier: event.target.value
  })
}

handleEditPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPayment: event.target.value
  })
}

handleEditTaxation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedTaxation: event.target.value
  })
}

handleEditShippingLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedShippingLocation: event.target.value
  })
}

handleEditBillingLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBillingLocation: event.target.value
  })
}

handleEditProducts = (event: any) => {
  this.setState({
    selectedProductIds: event.target.value
  })
}

handleShowRequisitionOpenModal = (purchaseRequisitionId: string) => {
  this.setState({
    openShowRequisitionModal: true
  })
  this.handleShowPurchaseRequisition(purchaseRequisitionId)
  this.handleSelectedRow(purchaseRequisitionId)
}

handleShowReceiptOpenModal = (receiptId: number, productId: string, type: string) => {
  this.setState({receiptCatProductsIdCatch : '', receiptNonCatProductsIdCatch: ''})

  this.setState({
    openShowReceiptModal: true,
  })
  if(type === "catelog_show"){
    this.setState({
      receiptCatProductsIdCatch: productId,
    })
  } else if(type === "non_catelog_show"){
    this.setState({
      receiptNonCatProductsIdCatch: productId,
    })
  }
  this.handleShowReceipt(receiptId)
  this.handleReceiptSelectedRow(receiptId)
}

handleShowSupplierOpenModal = (supplierId: string) => {
  this.setState({
    openShowSupplierModal: true,
  })
  this.handleShowSupplier(supplierId)
  this.handleSupplierSelectedRow(supplierId)
}

handleShowProductOpenModal = (productId: number) => {
  this.setState({
    openShowProductModal: true,
  })
  this.handleShowProduct(productId)
  this.handleProductSelectedRow(productId)
}

handleShowBudgetOpenModal = (budgetId: number) => {
  this.setState({
    openShowBudgetModal: true,
  })
  this.handleShowBudget(budgetId)
  this.handleBudgetSelectedRow(budgetId)
}

handleShowSettingLocationOpenModal = (data: number) => {
  this.setState({
    openShowSettingLocationModal: true,
  })
  this.handleShowSettingsLocation(data)
  // this.handleBudgetSelectedRow(budgetId)
}





// rfp change events
handleRfpScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    rfpCreateScreenActiveTabs: value,
  });
}

handleRfpLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpLocation: event.target.value
  })
}

handleRfpDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDepartments: event.target.value
  })
}

handleRfpDropdownProductsChange = (event: React.ChangeEvent<{}>, value: any) => {
  if (value) {
    this.setState({
      filteredRfpProducts: [...this.state.filteredRfpProducts, { ...value, quantity: '1' }],
    })
  }
  this.setState({
    rfpNonCatalougeCollectedItems: ''
  })
}


getSearchedNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpNonCatalougeCollectedItems: event.target.value
  })
}

handlerfpProductsQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  let qtyValue: any = numericValue;
  if (qtyValue === '0') {
    event.preventDefault();
    qtyValue = '1';
    event.target.value = qtyValue;
  }

  const rfpProductsStore = [...this.state.filteredRfpProducts];
  rfpProductsStore[index] = { ...this.state.filteredRfpProducts[index], ...product, product_id: product?.id, quantity: qtyValue };
  this.setState({ filteredRfpProducts: rfpProductsStore });
}


handleRfpCatchMailForSuppliers = (email: any) => {
  this.setState({
    catchRfpEmailSendToSupplier: email
  })
}

handleEditedRfpScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    editedRfpScreenActiveTabs: value,
  });
}

handleEditedRfpLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpLocation: event.target.value
  })
}

handleEditedRfpDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDepartment: event.target.value
  })
}

handleEditedRFPScreenFieldsOpenModal = () => {
  this.setState({
    editedRfpScreenActiveTabs: 1
  })
}

handleEditedRfpCatelogProductChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.id){
    this.setState({editedRfpCatelogItems: [...this.state.editedRfpCatelogItems, {...value, quantity:'1', isNewlyAdded: true}]})
  }

  this.setState({
    editedRfpNonCatalougeCollectedItems: ''
  })
  if(this.state.productsList.length === 0){
    this.setState({productsFoundOrNotMessage: 'No Product Found Create It'})
  }else{
    this.setState({productsFoundOrNotMessage: ''})
  }
}


handleEditedRfpNonCatelogProductChange = (event: React.ChangeEvent<{}>, value: any) => {
  if(value?.attributes?.theInputIsNonCatalog){
    this.setState({editedRfpNonCatelogItems: [...this.state.editedRfpNonCatelogItems, {...value, quantity:'1', isNewlyAdded: true}]})
  }

  this.setState({
    editedRfpNonCatalougeCollectedItems: ''
  })
}

getEditedAndSearchedNonCatelogProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpNonCatalougeCollectedItems : event.target.value
  })
}

handleEditedRfpCatelogQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {

  const numericValue = event.target.value.replace(/\D/g, "");
  let qtyValue : any = numericValue;
  if (qtyValue === '0') {
    event.preventDefault();
    qtyValue = '1';
    event.target.value = qtyValue
  }

  const editedRfpCatStore = [...this.state.editedRfpCatelogItems]
  editedRfpCatStore[index] = { ...this.state.editedRfpCatelogItems[index], ...product, quantity: event.target.value }
  this.setState({ editedRfpCatelogItems: editedRfpCatStore })
 
}

handleEditedRfpNonCatelogItemsQuantityChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, product: any, index: number) => {
  let nonCatQtyValue : any = event.target.value;
  if (nonCatQtyValue === '0') {
    event.preventDefault(); 
    nonCatQtyValue = '1';
    event.target.value = nonCatQtyValue
  }
  const editedRfpNonCatStore = [...this.state.editedRfpNonCatelogItems]
  editedRfpNonCatStore[index] = { ...this.state.editedRfpNonCatelogItems[index], ...product, quantity: nonCatQtyValue }
  this.setState({ editedRfpNonCatelogItems: editedRfpNonCatStore })
}










handleRfpMailSendConfirmation = () => {
  this.setState({
    isRfpMailSendConfirmed:true
  })
}

handleEditedRfpMailSendConfirmation = () => {
  this.setState({
    isEditedRfpMailSendConfirmed:true
  })
}

handleRfpProductsDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, index: number) => {
  const filteredProductsAfterDelet = this.state.filteredRfpProducts?.filter((pro: any, filteredIndex: number) => (filteredIndex !== index))
  this.setState({
    filteredRfpProducts: filteredProductsAfterDelet,
  })
}

handleEditedRfpCatelogRecordDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, coreIndex: number) => {
  this.editedRfpCatelogRecDeleteHandler(coreIndex)
}
editedRfpCatelogRecDeleteHandler = (coreIndex:number)=>{
  const filteredCatelogAfterDelet = this.state.editedRfpCatelogItems?.filter((pro: any, filteredIndex: number) => (filteredIndex !== coreIndex))
  const getDeletedItems = this.state.editedRfpCatelogItems?.find((pro: any, filteredIndex: number) => (filteredIndex === coreIndex))
  
  this.setState({
    editedRfpCatelogItems: filteredCatelogAfterDelet,
  })

  if(getDeletedItems){
    this.setState({
      rfpCatelogDeletedItemsArr: [...this.state.rfpCatelogDeletedItemsArr, { ...getDeletedItems, _destroy: '1'}],
    })
  }
}

handleEditedRfpNonCatelogItemsRecordDelet = (event:React.MouseEvent<HTMLButtonElement>, product: any, coreIndex: number) => {
  this.editedRfpNonCatelogItemsRecDelete(coreIndex)
}

editedRfpNonCatelogItemsRecDelete = (coreIndex:number)=>{
  const filteredNonCatelogAfterDelet = this.state.editedRfpNonCatelogItems?.filter((pro: any, filteredIndex: number) => (filteredIndex !== coreIndex))
  const getNonCatDeletedItems = this.state.editedRfpNonCatelogItems?.find((pro: any, filteredIndex: number) => (filteredIndex === coreIndex))

  this.setState({
    editedRfpNonCatelogItems: filteredNonCatelogAfterDelet,
  })

  if (getNonCatDeletedItems) {
    const updatedItem = { ...getNonCatDeletedItems, _destroy: '1' }
    this.setState({
      rfpNonCatelogDeletedItemsArr: [...this.state.rfpNonCatelogDeletedItemsArr, updatedItem],
    })
  }
}

handleEditedRfpIdForPdfUpdation = (id: number) => {
  this.setState({
    catchEditedRfpIdForPdfUpdation: id
  })
}

handleEditedRfpCatchMailForSuppliers = (email: any) => {
  this.setState({
    catchEditedRfpEmailSendToSupplier: email
  })
}









handleRfpTabsScreenClose = () => {
  const rfpScreenStatesCleared = {
    rfpCurrency: '',
    rfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    rfpDueDate: '',
    rfpDesiredDeliveryDate: '',
    rfpLocation: '',
    rfpDepartments: '',
    rfpSelectExistingSupplier: [],
    rfpFileAttachment: '',
    rfpCreateScreenActiveTabs: 0,
    filteredRfpProducts: [],
    rfpEmailOfSupplier:'',
    productsSearch: [],
    openRfpPdfSupplierScreenModal: false,
    showIsCreateRFPValidation: false,
    openCreateRfpShowErrorModal: false,
    isRfpCreationAndApiSuccessDone: false,
    isRfpMailSendConfirmed:false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSending:false,
    rfpFinalPdfDownloadOrNotMessage:'',
    rfpEmailOfSupplierEmailPatternError:false,
    rfpEmailOfSupplierEmailDuplicateError:false,
    rfpSupplierEmailMatchError:'',
    locationExistOrNotCheckingRequiredError: '',
    openAddRFPModal: false,
  }

  this.setState(rfpScreenStatesCleared)
}

handleRfpMailSendConfirmationCloseBar = () => {
  this.setState({
    isRfpMailSendConfirmed:false
  })
}


handleEditedRfpTabsScreenClose = () => {
  const initialState = {
    editedExistingRfpNumber: '',
    editedRfpCurrency: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: '',
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    editedFilteredRfpProducts: [],
    editedRfpEmailOfSupplier: '',
    productsSearch: [],
    rfpNonCatelogDeletedItemsArr: [],
    rfpCatelogDeletedItemsArr: [],
    openEditedRfpPdfSupplierScreenModal: false,
    showIsEditRFPValidation: false,
    openEditedRfpShowErrorModal: false,
    isEditedRfpUpdationApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isRfpMailSendConfirmed: false,
    isEditedRfpMailSendConfirmed: false,
    isEditedRfpMailSending: false,
    isRfpUpdating: false,
    openEditRfpShowErrorModal: false,
    isEditedRFPFileDownloaded: false,
    editedRfpFinalPdfDownloadOrNotMessage: '',
    openEditRFPModal: false,
  };

  this.setState(initialState);
}

handleEditedRFPProductsScreenCloseModal = () => {
  const editedRFPProducts = {
    editedExistingRfpNumber: '',
    editedRfpCurrency: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: '',
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    editedFilteredRfpProducts: [],
    editedRfpEmailOfSupplier:'',
    productsSearch: [],
    rfpNonCatelogDeletedItemsArr: [],
    rfpCatelogDeletedItemsArr: [],
    openEditedRfpPdfSupplierScreenModal: false,
    showIsEditRFPValidation: false,
    openEditRfpShowErrorModal:false,
    openEditedRfpShowErrorModal: false,
    isEditedRfpUpdationApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isEditedRfpMailSending:false,
    isRfpMailSendConfirmed:false,
    isRfpUpdating:false,
    isEditedRfpMailSendConfirmed:false,
    isEditedRFPFileDownloaded:false,
    editedRfpFinalPdfDownloadOrNotMessage:'',
    openEditRFPModal: false,
  }

  this.setState(editedRFPProducts)
}

handleEditedRequestForProposalsCloseModal = () => {
  const editedRFPClosedStates = {
    editedExistingRfpNumber:'',
    editedRfpCurrency: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: '',
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    editedFilteredRfpProducts: [],
    editedRfpEmailOfSupplier:'',
    productsSearch: [],
    rfpNonCatelogDeletedItemsArr:[],
    rfpCatelogDeletedItemsArr:[],
    openEditedRfpPdfSupplierScreenModal: false,
    isRfpUpdating:false,
    showIsEditRFPValidation: false,
    openEditedRfpShowErrorModal: false,
    isRfpMailSendConfirmed:false,
    isEditedRfpMailSendConfirmed:false,
    isEditedRfpUpdationApiSuccessDone: false,
    openRfpSupplierMailSeccessModal: false,
    isEditedRfpMailSending:false,
    isEditedRFPFileDownloaded:false,
    editedRfpFinalPdfDownloadOrNotMessage:'',
    openEditRFPModal: false
  }
  this.setState(editedRFPClosedStates)
}


handleEditedRfpSupplierEditedMailSeccessCloseModal = () => {
  this.setState({
    editedExistingRfpNumber: '',
    editedRfpCurrency: '',
    editedRfpCreationDate: new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'),
    editedRfpDueDate: '',
    editedRfpDesiredDeliveryDate: '',
    editedRfpLocation: '',
    editedRfpDepartment: '',
    editedRfpSelectExistingSupplier: '',
    editedRfpFileAttachment: '',
    editedRfpScreenActiveTabs: 0,
    rfpUpdateMessage:'',
    editedFilteredRfpProducts: [],
    editedRfpEmailOfSupplier:'',
    productsSearch: [],
    rfpNonCatelogDeletedItemsArr: [],
    rfpCatelogDeletedItemsArr: [],
    openEditedRfpPdfSupplierScreenModal: false,
    isRfpUpdating:false,
    showIsEditRFPValidation: false,
    openEditedRfpShowErrorModal: false,
    isRfpMailSendConfirmed:false,
    isEditedRfpMailSendConfirmed:false,
    isEditedRfpUpdationApiSuccessDone: false,
    openEditedRfpSupplierMailSeccessModal: false,
    isEditedRfpMailSending:false,
    isEditedRFPFileDownloaded:false,
    editedRfpFinalPdfDownloadOrNotMessage:'',
    openEditRFPModal: false
  })
}

handleEditedRfpMailSendConfirmationCloseBar = () => {
  this.setState({
    isEditedRfpMailSendConfirmed:false
  })
}

handleCloseEditedRfpErrorModal = () => {
  this.setState({
    openEditedRfpShowErrorModal: false,
  })
}






handleEditedRfpFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({
    editedRfpFinalPdfDownloadOrNotMessage: '',
  })
}



handleShowSettingLocationCloseModal = () => {
  this.setState({
    openShowSettingLocationModal: false,
  })
}

handleEditSettingsLocationCloseModal = () => {
  this.setState({
    openEditSettingLocationModal: false,
  })
}

handleEditSettingsCategoryCloseModal = () => {
  this.setState({
    openEditSettingCategoryModal: false,
  })
}

handleEditSettingsUnitMeasurementCloseModal = () => {
  this.setState({
    openEditSettingUnitMeasurementModal: false,
  })
}

handleShowRequisitionCloseModal = () => {
  this.setState({
    openShowRequisitionModal: false,
    // prAcceptRejectActionStatusMessage: ''
  })
}

handleShowPurchaseOrderCloseModal = () => {
  this.setState({
    openShowPurchaseOrderModal: false,
    poBillingAddress: '',
    poGstNumber: '',
    poShippingAddress: '',
    poViewScreenActiveTabs:0
  })
}

handlePurchaseRequisitionListOpenModal = () => {
  this.setState({
    openPurchaseRequisitionListModal: true
  })
}

handleRfpStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpStatus: event.target.value
  })
}


handleRFPProductsChange = (event: any) => {
  this.setState({
    addRfpProducts: event.target.value
  })
}

handleRFPProductsScreenNonCatItemTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    addRfpProductsNonCatItemText: event.target.value
  })
}

handleRfpCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpCurrency: event.target.value
  })
}


handleRfpCreationDateChange = (event : React.ChangeEvent<HTMLInputElement>) => {
  const newCreationDate = event.target.value;
  const newDueDate = newCreationDate > this.state.rfpDueDate ? '' : this.state.rfpDueDate;
  const newDeliveryDate = newCreationDate > this.state.rfpDesiredDeliveryDate ? '' : this.state.rfpDesiredDeliveryDate;
  
  this.setState({
    rfpCreationDate: newCreationDate,
    rfpDueDate: newDueDate,
    rfpDesiredDeliveryDate : newDeliveryDate
  });
};



handleRfpDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDueDate: event.target.value
  })
}

handleRfpAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpAddress: event.target.value
  })
}

handleRfpDesiredDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpDesiredDeliveryDate: event.target.value
  })
}



handleRfpCreationDateTypeFocus = () => {
  this.setState({
    rfpCreationDateType: 'date'
  })
}

handleRfpCreationDateTypeBlur = () => {
  this.setState({
    rfpCreationDateType: 'text'
  })
}

handleRfpDueDateTypeFocus = () => {
  this.setState({
    rfpDueDateType: 'date'
  })
}

handleRfpDueDateTypeBlur = () => {
  const creationDate = this.state.rfpCreationDate;
  const dueDate = this.state.rfpDueDate;
  
  if (creationDate && dueDate < creationDate) {
    this.setState({ rfpDueDate: '' });
  }

  this.setState({
    rfpDueDateType: 'text'
  })
}

handleRfpDesiredDeliveryDateTypeFocus = () => {
  this.setState({
    rfpDesiredDeliveryDateType: 'date'
  })
}

handleRfpDesiredDeliveryDateTypeBlur = () => {
  const creationDate = this.state.rfpCreationDate;
  const delDate = this.state.rfpDesiredDeliveryDate;
  
  if (creationDate && delDate < creationDate) {
    this.setState({ rfpDesiredDeliveryDate: '' });
  }
  this.setState({
    rfpDesiredDeliveryDateType: 'text'
  });
}

handleEditRfpCreationDateTypeFocus = () => {
  this.setState({
    editedRfpCreationDateType: 'date'
  })
}

handleEditRfpCreationDateTypeBlur = () => {
  this.setState({
    editedRfpCreationDateType: 'text'
  })
}

handleEditRfpDueDateTypeFocus = () => {
  this.setState({
    editedRfpDueDateType: 'date'
  })
}

handleEditRfpDueDateTypeBlur = () => {
  const creationDate = this.state.editedRfpCreationDate;
  const dueDate = this.state.editedRfpDueDate;
  
  if (creationDate && dueDate < creationDate) {
    this.setState({ editedRfpDueDate: '' });
  }

  this.setState({
    editedRfpDueDateType: 'text'
  })
}

handleEditRfpDesiredDeliveryDateTypeFocus = () => {
  this.setState({
    editedRfpDesiredDeliveryDateType: 'date'
  })
}

handleEditRfpDesiredDeliveryDateTypeBlur = () => {
  const creationDate = this.state.editedRfpCreationDate;
  const delDate = this.state.editedRfpDesiredDeliveryDate;
  
  if (creationDate && delDate < creationDate) {
    this.setState({editedRfpDesiredDeliveryDate: '' });
  }

  this.setState({
    editedRfpDesiredDeliveryDateType: 'text'
  })
}

handleRfpEmailOfSupplierChangeEmailMatchNestedFunc = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(!event.target.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      rfpEmailOfSupplierEmailPatternError: true
    })
  }
  else{
    this.setState({
      rfpEmailOfSupplierEmailPatternError: false
    }) 
  }
}

handleRfpEmailOfSupplierChangeEmailDuplicateNestedFunc = (emailDuplicate:any) => {
  if(emailDuplicate){
    this.setState({
      rfpEmailOfSupplierEmailDuplicateError: true
    })
  }
  else{
    this.setState({
      rfpEmailOfSupplierEmailDuplicateError: false
    })
  }
}

handleRfpEmailOfSupplierKeydown = (evt:any) => {
  if (["Enter", "Tab", ","].includes(evt.key)) {
    evt.preventDefault();

    let test = this.state.rfpEmailOfSupplier.trim(); 
    if(this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.state.rfpEmailOfSuppliers.push(test)
      this.setState({rfpEmailOfSupplier: ''})
    }
  }
};

handleDeleteEmailOfSuppliers = (item:any) => {
  console.log("handleDelete", item)
  const result = this.state.rfpEmailOfSuppliers.filter((val: any) => val !== item)
  this.setState({rfpEmailOfSuppliers: result})
};

handleEmailItemEdit = (item:any) =>{
  console.log("handleItemEdit", item)
  const result = this.state.rfpEmailOfSuppliers.filter(i => i !== item)
  this.setState({rfpEmailOfSuppliers: result})
  this.setState({rfpEmailOfSupplier: item})
  console.log("value", this.state.rfpEmailOfSupplier)
  
};

handleRfpEmailOfSupplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpEmailOfSupplier: event.target.value
  })
  this.handleRfpEmailOfSupplierChangeEmailMatchNestedFunc(event)
  const emailDuplicate = this.state.rfpEmailOfSuppliers.find((email: string) => email === event.target.value)
  this.handleRfpEmailOfSupplierChangeEmailDuplicateNestedFunc(emailDuplicate)
}

handleEditedRfpEmailOfSupplierChangeEmailMatchNestedFunc = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(!event.target.value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      editedRfpEmailOfSupplierEmailPatternError: true
    })
  }
  else{
    this.setState({
      editedRfpEmailOfSupplierEmailPatternError: false
    }) 
  }
}

handleEditedRfpEmailOfSupplierChangeEmailDuplicateNestedFunc = (emailDuplicate:any) => {
  if(emailDuplicate){
    this.setState({
      editedRfpEmailOfSupplierEmailDuplicateError: true
    })
  }
  else{
    this.setState({
      editedRfpEmailOfSupplierEmailDuplicateError: false
    })
  }
}



handleEditedRfpEmailOfSupplierChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpEmailOfSupplier: event.target.value
  })
  this.handleEditedRfpEmailOfSupplierChangeEmailMatchNestedFunc(event)
}

handleRfpEmailOfSupplierKeyDownEventNestedFunc = (event: any) => {
  if(event.target.value===""){
    this.setState({
      rfpEmailOfSupplierEmailPatternError: false,
    })
  }
}
handleRfpEmailOfSupplierKeyDownMatchNestedFunc = (emailDuplicate:any) => {
  if(this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) && !emailDuplicate){
    this.setState({
      rfpEmailOfSuppliers: [...this.state.rfpEmailOfSuppliers, this.state.rfpEmailOfSupplier],
      rfpEmailOfSupplier: '',
      rfpEmailOfSupplierEmailPatternError: false,
      rfpEmailOfSupplierEmailDuplicateError: false,
    })
  }
}

handleRfpEmailOfSupplierKeyDown = (event: any) => {
  this.handleRfpEmailOfSupplierKeyDownEventNestedFunc(event)

  const emailDuplicate = this.state.rfpEmailOfSuppliers.find((email: string) => email==this.state.rfpEmailOfSupplier)
  if (event.key === "Enter") {
    event.preventDefault();
    this.handleRfpEmailOfSupplierKeyDownMatchNestedFunc(emailDuplicate)
  }
}

handleEditedRfpEmailOfSupplierKeyDownEventNestedFunc = (event:any) => {
  if(event.target.value===""){
    this.setState({
      editedRfpEmailOfSupplierEmailPatternError: false,
    })
  }
}

handleEditedRfpEmailOfSupplierKeyDownMatchNestedFunc = (emailDuplicate:any) => {
  if(this.state.editedRfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/) && !emailDuplicate){
    this.setState({
      editedRfpEmailOfSupplier: '',
      editedRfpEmailOfSupplierEmailPatternError: false,
      editedRfpEmailOfSupplierEmailDuplicateError: false,
    })
  }
}



handleRfpEmailOfSupplierDelete = (emailToBeDeleted: string) => {
  console.log('Delete Suppliers', emailToBeDeleted)
  let deletedEmailOfSupplier = this.state.rfpEmailOfSuppliers?.filter((email: any) => email!=emailToBeDeleted)
  this.setState({
    rfpEmailOfSuppliers: deletedEmailOfSupplier
  })
  
}

handleRfpExistingProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpExistingProduct: event.target.value
  })
}

handleRfpAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpAttachment: event.target.value,
  })
} 

handleRfpAttachmentDropdownDisplay = () => {
  this.setState({
    rfpAttachmentDropdownDisplay: true,
  })
}

handleReceiptFileTypeAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptAttachment: event.target.value,
  })
} 

handleReceiptAttachmentDropdownDisplay = () => {
  this.setState({
    receiptAttachmentDropdownDisplay: true,
  })
}

handleEditedReceiptFileTypeAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptAttachment: event.target.value,
  })
} 

handleEditedReceiptAttachmentDropdownDisplay = () => {
  this.setState({
    editedReceiptAttachmentDropdownDisplay: true,
  })
}

handleRfpExistingSuppliersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    rfpSelectExistingSupplier: [...event.target.value]
  })
}


handleAddItemExistingSuppliersChange = (event: any) => {
  this.setState({
    addItemSupplier: event.target.value
  })
}

handleAddItemMultipleSuppliersChange = (event: any) => {
  this.setState({
    addItemSupplierMultiple: event.target.value
  })
}

handleEditedItemMultipleSuppliersChange = (event: any) => {
  this.setState({
    editedItemSupplierMultiple: event.target.value
  })
}

handleRfpFileAttachmentChange = (event: any) => {
  this.setState({
    rfpFileAttachment: event.target.files[0]
  })
}

handleReceiptAttachmentChange = (event: any) => {
  if(this.state.receiptAttachment === ''){
    this.setState({
      receiptSelectFileTypeMessage: 'Please Select Attachment Type **'
    })
  }else{
    this.setState({
      receiptAttachmentFile: event.target.files[0],
    })
  }
}

handleSelectedRow = (id: string) => {
  this.setState({
    selectedRow: id
  })
}

handleRfpSelectedRow = (id: number) => {
  this.setState({
    rfpSelectedRow: id
  })
}

handleRfpIdForPdfCreation = (id: number) => {
  this.setState({
    catchRfpIdForPdfCreate: id
  })
}

handlePurchaseOrderSelectedRow = (id: number) => {
  this.setState({
    purchaseOrderSelectedRow: id
  })
}

handlePoScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poCreateScreenActiveTabs: value,
  });
}
handlePoViewScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poViewScreenActiveTabs: value,
  });
}
handlePrPoScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    prPoCreateScreenActiveTabs: value,
    openNextCustomFromRequisitionModal : false,
  });
}

handlePoEditScreenTabsChange = (event: React.ChangeEvent<{}>, value: number) => {
  this.setState({
    poEditScreenActiveTabs: value,
  });
}

handlePoIdForPdfCreation = (id: number) => {
  this.setState({
    catchPoIdForPdfCreate: id
  })
}

handlePrPoIdForPdfCreation = (id: number) => {
  this.setState({
    catchPrPoIdForPdfCreate: id
  })
}

handlePoCatchMailForSuppliers = (email: any) => {
  this.setState({catchPoMailOfSupplier: email})
}

handlePrPoCatchMailForSuppliers = (email: any) => {
  this.setState({catchPrPoMailOfSupplier: email})
}

handlePoMailSendConfirmation = () => {
  this.setState({
    isPoMailSendConfirmed:true
  })
}

handlePrPoMailSendConfirmation = () => {
  this.setState({
    isPrPoMailSendConfirmed:true
  })
}

handleReceiptSelectedRow = (id: number) => {
  this.setState({
    receiptSelectedRow: id
  })
}

handleSupplierSelectedRow = (id: string) => {
  this.setState({
    supplierSelectedRow: id
  })
}

handleProductSelectedRow = (id: number) => {
  this.setState({
    productSelectedRow: id
  })
}

handleBudgetSelectedRow = (id: number) => {
  this.setState({
    budgetSelectedRow: id
  })
}

handleShowRFPOpenModal = (rfpId: number) => {
  this.setState({
    openShowRFPModal: true
  })
  this.handleRfpSelectedRow(rfpId)
  this.handleShowRFP(rfpId)
}

handleShowPurchaseOrderOpenModal = (purchaseOrderId: number) => {
  this.setState({
    openShowPurchaseOrderModal: true,
    poViewScreenActiveTabs: 0
  })
  this.handlePurchaseOrderSelectedRow(purchaseOrderId)
  this.handleShowPurchaseOrder(purchaseOrderId)
}

handleShowRFPCloseModal = () => {
  this.setState({
    openShowRFPModal: false
  })
}



handleShowReceiptCloseModal = () => {
  this.setState({
    openShowReceiptModal: false
  })
}

handleShowSupplierCloseModal = () => {
  this.setState({
    openShowSupplierModal: false
  })
}

handleShowProductCloseModal = () => {
  this.setState({
    openShowProductModal: false
  })
}

handleShowBudgetCloseModal = () => {
  this.setState({
    openShowBudgetModal: false
  })
}

handleEditedRfpStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpStatus: event.target.value
  })
}

handleEditedRfpCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpCurrency: event.target.value
  })
}

handleEditedRfpCreationDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const newCreationDate = event.target.value;
  const newDueDate = newCreationDate > this.state.editedRfpDueDate ? '' : this.state.editedRfpDueDate;
  const newDeliveryDate = newCreationDate > this.state.editedRfpDesiredDeliveryDate ? '' : this.state.editedRfpDesiredDeliveryDate;
  
  this.setState({
    editedRfpCreationDate: newCreationDate,
    editedRfpDueDate: newDueDate,
    editedRfpDesiredDeliveryDate : newDeliveryDate
  });

 
}

handleEditedRfpDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDueDate: event.target.value
  })
}

handleEditedRfpAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpAddress: event.target.value
  })
}

handleEditedRfpDesiredDeliveryDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpDesiredDeliveryDate: event.target.value
  })
}

handleEditedRfpExistingProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpExistingProduct: event.target.value
  })
}

handleEditedRfpAttachmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedRfpAttachment: event.target.value
  })
}

handleEditedPoMailSendConfirmationCloseBar = () => {
  this.setState({
    isEditedPoMailSendConfirmed:false
  })
}

handleEditedRfpExistingSuppliersChange = (event: any) => {
  this.setState({
    editedRfpSelectExistingSupplier: event.target.value
  })
}

handleEditedRfpFileAttachmentChange = (event: any) => {
  this.setState({
    editedRfpFileAttachment: event.target.files[0],
    isFileEdited: true,
  })
}

handleCloseEditRfpErrorModal = () => {
  this.setState({
    openEditRfpShowErrorModal: false,
  })
}

handleCloseCreateRfpErrorModal = () => {
  this.setState({
    openCreateRfpShowErrorModal: false,
  })
}

handleCloseCreatePoErrorModal = () => {
  this.setState({
    openCreatePoShowErrorModal: false,
  })
}

handlePoMailSendConfirmationCloseBar = () => {
  this.setState({
    isPoMailSendConfirmed:false
  })
}

handlePrPoMailSendConfirmationCloseBar = () => {
  this.setState({
    isPrPoMailSendConfirmed: false,
  })
}

handleCloseCreatePrPoErrorModal = () => {
  this.setState({
    openCreatePrPoShowErrorModal: false,
  })
}

handlePurchaseRequisitionPoPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseRequisitionPO: value
  })
}

handlePurchaseRequisitionPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseRequisition: value
  })
}

handleRfpPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageRfp: value
  })
}

handlePurchaseOrderPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePurchaseOrder: value
  })
}

handleSupplierPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageSupplier: value
  })
}

handleProductPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageProduct: value
  })
} 

handleCustomFromRequisitionSelectedRow = (data: any) => {
  this.setState({
    customFromRequisitionSelectedRow: data?.id,
    customFromRequisitionSelectedId: data?.id,
    customFromRequisitionCataItemData: data?.attributes?.requested_items_purchase_requisition,
    customFromRequisitionNonCataItemData: data?.attributes?.non_catalogue_items,
    prPoSelectedRequisitionNumber:data?.attributes?.purchase_requisition_number,
    prPoSelectedRequisitionCreationDate:data?.attributes?.pr_creation_date,
    prPoSelectedRequisitionDeliveryDate:data?.attributes?.delivery_date,
    prPoSelectedRequisitionDepartment:data?.attributes?.department_id,
  })
} 

handleReceiptPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageReceipt: value
  })
}

handleBudgetPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageBudget: value
  })
}

handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPage: value
  })
}

handlePaymentTermsPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPagePaymentTerms: value
  })
}

handleTaxationsPageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageTaxations: value
  })
}

handleUnitMeasurePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageUnitMeasure: value
  })
}

handlePageChangeCategory = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageCategory: value
  })
}

handlePageChangeSettingsLocation = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState({
    currentPageSettingsLocation: value
  })
}

handleCustomFromRequisitionPOCloseModal = () => {
  this.setState({
    openPurchaseRequisitionListModal: false,
    currentPagePurchaseRequisitionPO: 1,
    selectPoOptions: '',
  })
  this.handleCustomFromRequisitionSelectedRow(false)
}

handleCustomFromRequisitionPoSupplier = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoSupplier: event.target.value
  })
  this.handleShowSupplier(event.target.value)
}

handleCustomFromRequisitionPoOrderDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoOrderDate: event.target.value
  })
}

handleCustomFromRequisitionPoFobPoint = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoFobPoint: event.target.value
  })
}

handleCustomFromRequisitionPoShippingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoShippingAddress: event.target.value
  })
}

handleCustomFromRequisitionPoBillingAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoBillingAddress: event.target.value
  })
}

handleCustomFromRequisitionPoTaxId = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoTaxId: event.target.value
  })
}

handleCustomFromRequisitionPoModeOfTransit = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoModeOfTransit: event.target.value
  })
}

handleCustomFromRequisitionPoCommentsForPO = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoCommentForPO: event.target.value
  })
}

handleCustomFromRequisitionPoAmountCalculation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoAmountCalculation: event.target.value
  })
}

handleCustomFromRequisitionPoBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoBudget: event.target.value
  })
}

handleCustomFromRequisitionPoComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    customFromRequisitionPoComment: event.target.value
  })
}

handleReceiptCatelogueItemsStore = (selectedData:any) => {
  if(selectedData?.attributes?.product_purchase_order){
    this.setState({
      receiptCatelogueItemsStore: selectedData?.attributes?.product_purchase_order?.map((items : any) => ({ ...items, quantity_received : '1', comment_received: '' }) ),
    })
  }
}


handleReceiptNonCatelogueItemsStore = (selectedData:any) => {
  if(selectedData?.attributes?.non_catalogue_items){
    this.setState({
      receiptNonCatelogueItemsStore: selectedData?.attributes?.non_catalogue_items?.map((items : any) => ({ ...items, quantity_received : '1', comment_received : ''}) ),
    })
  }
}


handleReceiptSelectedPo = (event: React.ChangeEvent<{}>, value: any) => {
  this.setState({receiptSelectedPOPopulatedData: {},
    receiptCatelogueItemsStore: [],
    receiptNonCatelogueItemsStore: [],
  })
  console.log("receiptSelectedPo value check : ", value)

  if(value){
    this.setState({
      receiptSelectedPo: value?.id,
      receiptSelectedPOPopulatedData: value
    })

    this.handleReceiptCatelogueItemsStore(value)
    this.handleReceiptNonCatelogueItemsStore(value)
  }

}

handleReceiptQuantityRecievedChange = (event: React.ChangeEvent<HTMLInputElement>, items:any,  index: number) => {
  let qtyValue : any = event.target.value;
  if (qtyValue === '0') {
    event.preventDefault(); 
    qtyValue = '1';
    event.target.value = qtyValue
  }
  const receiptCatQtyStore = [...this.state.receiptCatelogueItemsStore]
  receiptCatQtyStore[index] = { ...this.state.receiptCatelogueItemsStore[index], ...items, quantity_received: qtyValue}
  this.setState({ receiptCatelogueItemsStore: receiptCatQtyStore })
}

handleReceiptNonCatelogQuantityRecievedChange = (event: React.ChangeEvent<HTMLInputElement>, items:any, index: number) => {
  let nonCatQtyValue : any = event.target.value;
  if (nonCatQtyValue === '0') {
    event.preventDefault(); 
    nonCatQtyValue = '1';
    event.target.value = nonCatQtyValue
  }
  const receiptNonCatQtyStore = [...this.state.receiptNonCatelogueItemsStore]
  receiptNonCatQtyStore[index] = { ...this.state.receiptNonCatelogueItemsStore[index], ...items, quantity_received : nonCatQtyValue}
  this.setState({ receiptNonCatelogueItemsStore: receiptNonCatQtyStore })
}

handleReceiptSelectedPOtSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptQuantityReceived: event.target.value
  })
}

handleReceiptQuantityRequested = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptQuantityRequested: event.target.value
  })
}

handleReceiptReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptReceiptDate: event.target.value
  })
}

handleReceiptInvoiceNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    receiptInvoiceNumber: event.target.value
  })
}

handleReceiptCommentsChange = (event: React.ChangeEvent<HTMLInputElement>, items : any, index: number) => {
  const receiptCatCommentStore = [...this.state.receiptCatelogueItemsStore]
  receiptCatCommentStore[index] = { ...this.state.receiptCatelogueItemsStore[index], ...items, comment_received : event.target.value}
  this.setState({ receiptCatelogueItemsStore: receiptCatCommentStore })
}

handleReceiptNonCatelogCommentsChange = (event: React.ChangeEvent<HTMLInputElement>, items :any, index: number) => {
  const receiptNonCatCommentStore = [...this.state.receiptNonCatelogueItemsStore]
  receiptNonCatCommentStore[index] = { ...this.state.receiptNonCatelogueItemsStore[index], ...items, comment_received : event.target.value}
  this.setState({ receiptNonCatelogueItemsStore: receiptNonCatCommentStore })
}




handleReceiptFileAttachment = (event: any) => {
  this.setState({
    receiptAttachmentFile: event.target.files[0]
  })
}

handleEditedReceiptQuantityRecieved = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptQuantityReceived: event.target.value
  })
}

handleEditedReceiptQuantityRequested = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptQuantityRequested: event.target.value
  })
}

handleEditedReceiptReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptReceiptDate: event.target.value
  })
}

handleEditedReceiptComments = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptComments: event.target.value
  })
}

handleEditedReceiptDate = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedReceiptReceiptDate: event.target.value
  })
}

handleEditedReceiptAttachmentFile = (event: any) => {
  this.setState({
    editedReceiptAttachmentFile: event.target.files[0],
    isReceiptFileEdited: true,
  })
}

handleCreateReceiptCloseModal = () => {
  this.setState({
    openAddReceiptModal: false,
    //showIsEditRFPValidation: false,
  })
}

handleSupplierSupplierName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierSupplierName: event.target.value
  })
}

handleSupplierUniqueCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierUniqueCode: event.target.value
  })
}

handleSupplierTermsOfPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierTermsOfPayment: event.target.value
  })
}

handleSupplierPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    paymentTermsNameSuppliers: [...event.target.value]
  })
}


handleEditedSupplierPaymentTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedPoPaymentTermList: [...event.target.value]
  })
}

handleSupplierValidEmailCheckFunc = (event: React.ChangeEvent<HTMLInputElement>) => {

  if(!RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.supplierEmailAddress)){
    this.setState({
      supplierEmailInvalidPatternError: true
    });
  } else {
    this.setState({
      supplierEmailInvalidPatternError: false
    });
  }
}




handleSupplierEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierEmailAddress: event.target.value
  })

  this.handleSupplierValidEmailCheckFunc(event)
}


handleContactNumberValidationErrorMessage = (event : React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value.length > 10 || event.target.value.length < 10 ){
    this.setState({
      supplierContactNumberErrorMessage : true
    })
  } else {
    this.setState({
      supplierContactNumberErrorMessage : false
    })
  }
}


handleSupplierContactNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  const contactNumber = event.target.value;
  const formattedContactNumber = contactNumber.replace(/\D/g, '');
  this.setState({ supplierContactNumber: formattedContactNumber })

  this.handleContactNumberValidationErrorMessage(event)
};

handlePointOfContactNumberValidationErrorMessageFunc = (event : React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value.length > 10 || event.target.value.length < 10 ){
    this.setState({
      supplierPointOfContactNumberErrorMessage : true
    })
  } else {
    this.setState({
      supplierPointOfContactNumberErrorMessage : false
    })
  }
}
handleSupplierPointOfContact = (event: React.ChangeEvent<HTMLInputElement>) => {
  const contactNumber = event.target.value;
  const formattedContactNumber = contactNumber.replace(/\D/g, '');
  this.setState({
    supplierPointOfContact: formattedContactNumber
  })
  this.handlePointOfContactNumberValidationErrorMessageFunc(event)
}

handleSupplierAddressOfSupplierCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierAddressOfSupplierCompany: event.target.value
  })
}

handleSupplierGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierGSTNumber: event.target.value
  })
}

handleSupplierStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierStateProvince: event.target.value
  })
}

handleSupplierZipCodePinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierZipCodePinCode: event.target.value
  })
}

handleSupplierSupplierLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    supplierSupplierLocation: event.target.value
  })
}

handleEditedSupplierSupplierName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierSupplierName: event.target.value
  })
}

handleEditedSupplierUniqueCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierUniqueCode: event.target.value
  })
}

handleEditedSupplierTermsOfPayment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierTermsOfPayment: event.target.value
  })
}

handleEditedSupplierPaymentTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierPaymentList: [...event.target.value]
  })
}

handleEditedSupplierEmailAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierEmailAddress: event.target.value
  })
}

handleEditedSupplierContactNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierContactNumber: event.target.value
  })
}

handleEditedSupplierPointOfContact = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierPointOfContact: event.target.value
  })
}

handleEditedSupplierAddressOfSupplierCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierAddressOfSupplierCompany: event.target.value
  })
}

handleEditedSupplierGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierGSTNumber: event.target.value
  })
}

handleEditedSupplierSupplierLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierSupplierLocation: event.target.value
  })
}

handleEditedSupplierStateProvince = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierStateProvince: event.target.value
  })
}

handleEditedSupplierZipCodePinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedSupplierZipCodePinCode: event.target.value
  })
}

handleProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productName: event.target.value
  })
}

handleProductDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productDescription: event.target.value
  })
}

handleProductPartId = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productPartId: event.target.value
  })
}

handleProductUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    productUnitMeasurement: event.target.value
  })
}

handleProductUnitPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");

  this.setState({
    productUnitPrice: numericValue,
  })
}

handleProductCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log('event.target.value',event.target.value );
  
  this.setState({
    productCategory: event.target.value
  })
}

handleProductType = (productId: string) => {
  this.setState({
    productTypeSelectedId: productId,
  })
}

handleEditedProductName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductName: event.target.value
  })
}

handleEditedAddNotes = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedAddNotes: event.target.value
  })
}

handleEditedProductDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductDescription: event.target.value
  })
}

handleEditedProductPartId = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductPartId: event.target.value
  })
}

handleEditedProductUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductUnitMeasurement: event.target.value
  })
}

handleEditedProductUnitPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    editedProductUnitPrice: numericValue
  })
}

handleEditedProductCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedProductCategory: event.target.value
  })
}


handleEditedProductType = (event: React.ChangeEvent<HTMLInputElement>, productId: string) => {
  this.setState({
    editedProductTypeSelectedId: productId,
  })
}


handleBudgetName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetName: event.target.value
  })
}

handleBudgetFor = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetFor: event.target.value,
  })
}

handleBudgetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
  if(Number(event.target.value) > 0){
    this.setState({
      budgetAmount: Number(event.target.value)
    })
  }else{
    this.setState({
      budgetAmount: ''
    })
  }
}

handleBudgetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetComment: event.target.value
  })
}

handleBudgetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetPeriod: event.target.value
  })
}

handleBudgetProductCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    budgetProductCategories: event.target.value
  })
}

handleEditedBudgetName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetName: event.target.value
  })
}

handleEditedBudgetFor = (budgetId: string) => {
  this.setState({
    editedBudgetForSelectedId: budgetId
  })
}

handleEditedBudgetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetAmount: event.target.value
  })
}

handleEditedBudgetComment = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetComment: event.target.value
  })
}

handleEditedBudgetPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetPeriod: event.target.value
  })
}

handleEditedBudgetProductCategories = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editedBudgetProductCategories: event.target.value
  })
}

handleInformationSettingsCompanyName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsCompanyName: event.target.value
  })
}

handleSettingsLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsLocation: event.target.value
  })
}

handleSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsAddress: event.target.value
  })
}

handleSettingsCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  if(restrictedTextValue){
    this.setState({
      settingsCategoryFeildError:''
    })
  }
  this.setState({
    settingsCategory: restrictedTextValue
  })
}

handleSettingsUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  const restrictedTextValue = event.target.value.replace(/[^a-z\s]/gi, '');

  this.setState({
    settingsUnitMeasurement: restrictedTextValue
  })
}

handleSettingsIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsIdentificationCode: event.target.value
  })
}

handleSettingsGst = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    settingsGst: event.target.value
  })
}

handleEditSettingsGst = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsGst: event.target.value
  })
}

handleEditSettingsCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsCategoryName: event.target.value
  })
}

handleEditSettingsUnitMeasurement = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsUnitMeasurement: event.target.value
  })
}

handleEditSettingsLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsLocation: event.target.value
  })
}

handleEditSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsAddress: event.target.value
  })
}

handleEditSettingsIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editSettingsIdentificationCode: event.target.value
  })
}

handleInformationSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsAddress: event.target.value
  })
}

handleInformationSettingValidEmailCheckFunc = (event: React.ChangeEvent<HTMLInputElement>) => {

  if(!RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.informationSettingsEmail)){
    this.setState({
      informationSettingEmailInvalidPatternError: true
    });
  } else {
    this.setState({
      informationSettingEmailInvalidPatternError: false
    });
  }
}

handleInformationSettingsEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsEmail: event.target.value
  })

  this.handleInformationSettingValidEmailCheckFunc(event)
}

handleInformationSettingsWebsiteInpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsWebsite: event.target.value
  })
}


handleInfoContactNumberValidationErrorMessageFunc = (event : React.ChangeEvent<HTMLInputElement>) => {
  if(event.target.value.length > 10 || event.target.value.length < 10 ){
    this.setState({
      infoSettingContactNumberErrorMessage : true
    })
  } else {
    this.setState({
      infoSettingContactNumberErrorMessage : false
    })
  }
}

handleInformationSettingsPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    informationSettingsPhoneNumber: numericValue
  })

  this.handleInfoContactNumberValidationErrorMessageFunc(event)
}

handleInformationSettingsPinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    informationSettingsPinCode: numericValue
  })
}

handleInformationSettingsGstNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    informationSettingsGstNumber: event.target.value
  })
}

handleCurrencySettingsCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    currencySettingsCurrency: event.target.value
  })
}

handleLocationSettingsAddress = (event: React.ChangeEvent<HTMLInputElement>, index:number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.adderss = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationSettingsLocationIdentificationCode = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.location_identification_code = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationGSTNumber = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.location_gst_number = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleLocationSettingsNameOfLocation = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.locations];
  let item = {...items[index]}
  item.name_of_location = event.target.value
  items[index] = item
  this.setState({
    locations: items
  })
}

handleUserSettingsNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserName: event.target.value
  })
}

handleUserSettingsEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserEmailId : event.target.value
  })
}

handleUserSettingsRole = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserRole: event.target.value
  }) 
}

handleUserSettingsDepartment = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  let items = [...this.state.users];
  let item = {...items[index]}
  item.department = event.target.value
  items[index] = item
  this.setState({
    users: items
  })
}

handleUserSettingsDesignation = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    userSettingAddUserDesignation: event.target.value
  })
}

handleShowInformationSettings = () => {
  this.setState({
    showSettings: false,
    showInformation: true,
  })
  this.handleGetInformationSettings()
}

handleGoBackFromInformationSettings = () => {
  this.setState({
    showSettings: true,
    showInformation: false,
  })
}

handleShowCurrencySettings = () => {
  this.setState({
    showSettings: false,
    showCurrencySettings: true,
  })
  this.handleGetCurrencySettings()
}

handleGoBackFromCurrencySettings = () => {
  this.setState({
    showSettings: true,
    showCurrencySettings: false,
    currencySettingsCurrency: '',
  })
}

handleShowLocationSettings = () => {
  this.handleGetLocations()
  this.setState({
    showSettings: false,
    showLocationSettings: true,
  })
}

handleGoBackFromLocationSettings = () => {
  this.setState({
    showSettings: true,
    showLocationSettings: false,
    location: '',
    settingsAddress: '',
    settingsIdentificationCode: '',
    settingsGst: '',
    locationRequiredError: '',
    locationAddressRequiredError: '',
    locationIdentityCodeRequiredError: '',
    locationGstRequiredError: '',
  })
}

handleShowUserSettings = () => {
  this.handleGetUsers()
  this.setState({
    showSettings: false,
    showUserSettings: true,
  })
}

handleShowCategorySettings = () => {
  this.setState({
    showSettings: false,
    showCategorySettings: true,
  })
}

handleShowUnitMeasurementSettings = () => {
  this.setState({
    showSettings: false,
    showUnitMeasureSettings: true,
  })
}

handleGoBackFromCategorySettings = () => {
  this.setState({
    showSettings: true,
    showCategorySettings: false,
    categorySettingCreatePost: false,
    settingsCategoryRequiredField: ''
  })
}

handleGoBackFromUnitMeasurementSettings = () => {
  this.setState({
    showSettings: true,
    showUnitMeasureSettings: false,
    settingsUnitMeasurement: '',
    unitMeasurementSettingPost: false,
    settingsUnitMeasurementRequiredField: '',
  })
}

handleGoBackFromUserSettings = () => {
  this.setState({
    showSettings: true,
    showUserSettings: false,
  })
}

handleShowPaymentTermsSettings = () => {
  this.setState({
    showSettings: false,
    showPaymentTermsSettings: true,
  })
}

handleGoBackFromPaymentTermsSettings = () => {
  this.setState({
    showSettings: true,
    showPaymentTermsSettings: false,
    editCreditDays:'',
    editPaymentTermsName:'',
    paymentTermsName:'',
    creditDays:'',
    currentPagePaymentTerms:1,
    paymentTermNameRequiredField: '',
    paymentTermCreditDaysRequiredField: '',
    paymentTermNameRequiredFieldEdit: '',
    paymentTermCreditDaysRequiredFieldEdit:''
  })
}

handleCancelPaymentTermsSettings = () => {
  this.setState({ 
    showPaymentTermsSettings: false,
    showSettings: true,
    editCreditDays:'',
    editPaymentTermsName:'',
    paymentTermsName:'',
    creditDays:'',
    currentPagePaymentTerms:1,
    paymentTermNameRequiredField: '',
    paymentTermCreditDaysRequiredField: '',
    paymentTermNameRequiredFieldEdit: '',
    paymentTermCreditDaysRequiredFieldEdit:''
  })
}

handleShowTaxationSettings = () => {
  this.setState({
    showSettings: false,
    showTaxationSettings: true,
  })
}

handleGoBackFromTaxationSettings = () => {
  this.setState({
    showSettings: true,
    showTaxationSettings: false,
    taxName:'',
    taxPercentage:'',
    editTaxName:'',
    editTaxPercentage:'',
    currentPageTaxations: 1,
    taxNameRequiredField:'',
    taxPercentageRequiredField: '',
    editTaxNameRequiredField:'',
    editTaxPercentageRequiredField:'',
  })
}

handleCancelTaxationsSettings = () => {
  this.setState({ 
    showTaxationSettings: false,
    showSettings: true,
    taxName:'',
    taxPercentage:'',
    currentPageTaxations: 1,
    editTaxName:'',
    editTaxPercentage:'',
    taxNameRequiredField:'',
    taxPercentageRequiredField: '',
    editTaxNameRequiredField:'',
    editTaxPercentageRequiredField:'',
  })
}

handlePaymentTermsChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({
    paymentTermsName: event.target.value
  })
  if(event.target.value === ''){
    this.setState({paymentTermFoundOrNotMessage: 'Please create Payment term'})
  }else{
    this.setState({paymentTermFoundOrNotMessage: ''})
  }
}

handleCreditDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    creditDays: numericValue
  })
}

handleEditPaymentTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editPaymentTermsName: event.target.value
  })
}

handleEditCreditDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, "");
  this.setState({
    editCreditDays: numericValue
  })
}

handleTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    taxName: event.target.value
  })
}
handleTaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/[^0-9.]/g, "");

  this.setState({
    taxPercentage: numericValue
  })
}

handleEditTaxNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    editTaxName: event.target.value
  })
}

handleEditTaxPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/[^0-9.]/g, "");

  this.setState({
    editTaxPercentage: numericValue
  });
}

handleAddLocation = () => {
  this.setState({
    locations: [...this.state.locations, {
        name_of_location: '',
        adderss: '',
        location_identification_code: ''
    }]
  })
}

handleAddUser = () => {
  this.setState({
    users: [...this.state.users, {
      name: "",
      designation: "",
      role: "",
      email: "",
      department: "",
    }]
  })
}

handleOpenUserModal = () => {
  this.setState({
    openAddUserModal: true
  })
}

handleCloseLocationModal = () => {
  this.setState({
    openAddLocationsModal: false,
    locations: [{
      name_of_location: '',
      adderss: '',
      location_identification_code: ''
    }],
    saveLocationsValidation: false,
  })
}

handleCloseUserModal = () => {
  this.setState({
    userSettingAddUserName: '',
    userSettingAddUserDesignation: '',
    userSettingAddUserEmailId: '',
    userSettingAddUserRole: '',
    saveUsersValidation: false,
    userSettingNameRequiredError: '',
    userSettingDesignationRequiredError: '',
    userSettingEmailRequiredError: '',
    userSettingEmailInvalidError: '',
    userSettingRoleRequiredError: '',
    isUserCreating: false,
  })
}

handleCloseUserAccessPermissionsModal = () => {
  this.setState({
    openUserPermissionsModal: false,
    catchUserSettingsCreatedUserId: '',
    selectedUserPermissions: [],
    selectAllPermissions: false,
  })
}

handleEditedCloseUserAccessPermissionsModal = () => {
  this.setState({
    openEditedUserPermissionsModal: false,
    createUserPermissionsValidation : false,
    editedSelectedUserPermissions: [],
    editedSelectAllPermissions: false,
    editedCatchIdForUserPermissionsUpdation: '',
    isUserSettingPermissionsUpdating : false,
    editedCatchCoreUserCreatedAccountId : '',
    userPermissionMissingIdErrorMessage: '',
  })
}


handleUserSettingsSelectedRow = (id:string) => {
  this.setState({
    userSettingSelectedRow: id
  })
}


handleEditedUserAccessPermissions = (data:any) => {
  this.setState({
    createUserPermissionsValidation : false
  })

  if(data?.attributes?.permission_alloted[0]?.permission){
    const permissionsAlloted = JSON.parse(data?.attributes?.permission_alloted[0]?.permission);
      permissionsAlloted?.map((item: any) => {
        return item.toLowerCase()
    });
    this.setState({
      editedSelectedUserPermissions: permissionsAlloted,
      editedSelectAllPermissions : false,
      editedCatchIdForUserPermissionsUpdation : data?.attributes?.user_permission,
      editedCatchCoreUserCreatedAccountId : data?.attributes?.permission_alloted?.account_id
    })
  } else {
    this.setState({
      editedSelectedUserPermissions: [],
      editedSelectAllPermissions : false,
      editedCatchIdForUserPermissionsUpdation : '',
      editedCatchCoreUserCreatedAccountId : data?.attributes?.permission_alloted?.account_id
    })
  }

  this.handleUserSettingsSelectedRow(data?.id)

  this.setState({
    openEditedUserPermissionsModal: true,
  })

}

handleUsersSettingSelectAllCheckboxChange(checkBoxPermissionsData:any) {
  if (this.state.selectAllPermissions) {
    
    this.setState({
      selectedUserPermissions : [],
      selectAllPermissions: false,
    });
  } else {
    const allCheckboxValues = checkBoxPermissionsData.map((checkbox:any) => checkbox.name);
    this.setState({
      selectedUserPermissions: allCheckboxValues,
      selectAllPermissions: true,
    });
  }
}

handleUsersSettingCheckboxChange(value:any) {
  if (this.state.selectedUserPermissions.includes(value)) {
    this.setState({
      selectedUserPermissions: this.state.selectedUserPermissions.filter((checkbox) => checkbox !== value),
    });
  } else {
    this.setState({
      selectedUserPermissions: [...this.state.selectedUserPermissions, value],
    });
  }
}

handleEditedUsersSettingSelectAllCheckboxUpdationChange = (event: any, USER_PERMISSIONS_ARRAY:any) => {
  const { checked } = event.target;
  const allPermissionNames = USER_PERMISSIONS_ARRAY.map((permission : any) => permission.name);

  this.setState({
    editedSelectedUserPermissions: checked ? allPermissionNames : [],
    editedSelectAllPermissions: checked,
  });
};

handleEditedUsersSettingCheckboxUpdationChange = (permissionName:any, USER_PERMISSIONS_ARRAY : any) => {
  this.setState((prevState) => {
    let updatedPermissions = [...prevState.editedSelectedUserPermissions];
    if (updatedPermissions.includes(permissionName)) {
      updatedPermissions = updatedPermissions.filter((name) => name !== permissionName);
    } else {
      updatedPermissions.push(permissionName);
    }

    const allPermissionsSelected = updatedPermissions.length === USER_PERMISSIONS_ARRAY.length;

    return { editedSelectedUserPermissions: updatedPermissions, editedSelectAllPermissions : allPermissionsSelected };
  });
};





handleRemoveLocationRow = (index: number) => {
  this.state.locations.splice(index, 1)
  this.setState({
    locations: this.state.locations
  })
}
 
isCreatePurchaseRequisitionValidCheckCreateOrFind = () => {
  let flag=true;
  if(this.state.department === ""){
    this.setState({
      departmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.durationDate === ""){
    this.setState({
      durationDateRequiredError: "Enter Duration date *",
    })
    flag=false;
  }
  if(this.state.deliveryDate === ""){
    this.setState({
      deliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.location === ""){
    this.setState({
      locationRequiredError: "Enter location *",
    })
    flag=false;
  }
  if(this.state.prProduct.length === 0){
    this.setState({
      productsRequiredError: "Select Products *",
    })
    flag=false;
  }
  let deliveryDate = new Date(this.state.deliveryDate).getTime();
  let durationDate = new Date(this.state.durationDate).getTime();
  if(deliveryDate < durationDate){
    this.setState({deliveryOrDurationDateCheckMessagePr: 'Delivery date must be Greater or Equal !!'})
    flag = false;
  }
  return flag;
}
isCreatePurchaseRequisitionValidCheckDashboard = () => {
  let flag=true;
  if(this.state.department === ""){
    this.setState({
      departmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.durationDate === ""){
    this.setState({
      durationDateRequiredError: "Enter Duration date *",
    })
    flag=false;
  }
  if(this.state.deliveryDate === ""){
    this.setState({
      deliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.prProduct.length === 0){
    this.setState({
      productsRequiredError: "Select Products *",
    })
    flag=false;
  }
  if(this.state.location === ""){
    this.setState({
      locationRequiredError: "Enter location *",
    })
    flag=false;
  }
  let deliveryDate = new Date(this.state.deliveryDate).getTime();
  let durationDate = new Date(this.state.durationDate).getTime();
  if(deliveryDate < durationDate){
    this.setState({deliveryOrDurationDateCheckMessagePr: 'Delivery date must be Greater or Equal !!'})
    flag = false;
  }
  return flag;
}

handleCreatePurchaseRequisition = () => {
  if(!this.isCreatePurchaseRequisitionValidCheckCreateOrFind() && window.location.pathname === "/CreateOrFind"){
    console.log("Create PURCHASE REQUISITION CREATE OR FIND")
    this.setState({
      showIsCreatePurchaseRequisitionValidation: true,
      // showIsCreateRFPValidation: true
    })
    return
  }
  if(!this.isCreatePurchaseRequisitionValidCheckDashboard() && window.location.pathname === "/Dashboard"){
    console.log("Create PURCHASE REQUISITION DASHBOARD")
    this.setState({
      showIsCreatePurchaseRequisitionValidation: true,
      // showIsCreateRFPValidation: true
    })
    return
  }
  this.setState({
    createPurchaseRequisitionLoading: true,
  })
  const token_localStorage = localStorage.getItem('authToken') 
  console.log("Handle Create Purchase Requisition")
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    purchase_requisition:{
      type: "draft_creation",
      pr_creation_date: this.state.durationDate,
      delivery_date: this.state.deliveryDate,
      location_setting_id: this.state.location,
      department_id: this.state.department,
      account_id: this.state.accountId,
      status: 'draft',
      purchase_requisition_number: this.state.prNumberUnique,
      product_purchase_requisitions_attributes: this.state.prProduct.filter((value: any) => value?.id).map((value: any)=>{
        const { quantity, id: product_id } = value;
        return {
          quantity,
          product_id
        };
      }),
      non_catalogue_items_attributes: this.state.prProduct.filter((value: any) => value?.attributes?.isNew).map((value: any)=>{
        const name = value?.attributes?.name
        const quantity = value?.quantity
        return {
          name,
          quantity,
          is_non_catalague_item: value?.attributes?.isNew
        }
      })
    }
  };

  console.log('data of httpBody ', httpBody)

  const createPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createPurchaseRequisitionAPICallId = createPurchaseRequisitionApiMsg.messageId;

  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_requisitions"
  );

  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  console.log('HTTP Body ',httpBody)
  
  createPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(createPurchaseRequisitionApiMsg.id, createPurchaseRequisitionApiMsg); 
}

// Modification Start

handleSaveAsDraftPurchaseRequisition = () => {
  if(!this.isSaveAsDraftPurchaseRequisitionValidCheck()){
    console.error("Save As Draft Purchase Requisition validation failed");
    this.setState({
      showIsCreatePurchaseRequisitionValidation: true,
      // showIsCreateRFPValidation: true
    })
    return
  }
  this.setState({
    createPurchaseRequisitionLoading: true,
  })
  const token_localStorage = localStorage.getItem('authToken') 
  console.log("Handle Save As Draft Purchase Requisition")
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    data: {
      attributes: {
          type: "draft_creation",
          pr_creation_date: this.state.durationDate,
          delivery_date: this.state.deliveryDate,
          location: this.state.location,
          department_id: this.state.department,
          product_ids: this.state.products,
          status : '',
          purchase_requisition_number : ''
      }
    }
  };

  const saveAsDraftPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseRequisitionAPICallId = saveAsDraftPurchaseRequisitionApiMsg.messageId;

  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_requisitions"
  );

  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  console.log('HTTP Body ',httpBody)
  
  saveAsDraftPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftPurchaseRequisitionApiMsg.id, saveAsDraftPurchaseRequisitionApiMsg); 
};

isSaveAsDraftPurchaseRequisitionValidCheck = () => {
  return true;
};

isSaveAsDraftRFPValidCheck = () => {
  let flag=true;
  
  if(this.state.rfpEmailOfSupplier && !this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
    this.setState({
      rfpSupplierEmailMatchError: "Enter a valid email",
    })
    flag=false;
  }

  return flag;
};

isSaveAsDraftPurchaseOrderValidCheck = () => {
  return true;
};

handleSaveAsDraftPurchaseOrder = () => {
  if(!this.isSaveAsDraftPurchaseOrderValidCheck()){
    console.error("Save As Draft PO validation failed!");
    this.setState({
      showIsCreatePurchaseOrderValidation: true,
    })
    return
  }
  this.setState({poSaveDraft: true})
  const token_localStorage = localStorage.getItem('authToken') 
  console.log("Handle Save as Draft PO")
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const nonCatalogueItemsCatched = this.state.filteredPoProducts
    .filter(val => val?.attributes?.theInputIsNonCatalog)
    .map(value => ({
      name: value?.attributes?.name,
      quantity: value?.quantity,
      is_non_catalague_item: value?.attributes?.theInputIsNonCatalog,
    }));

    const productPurchaseOrdersCatched = this.state.filteredPoProducts
    .filter(val => val?.id)
    .map(val => ({
      product_id: val?.id,
      quantity: val?.quantity,
      product_price: val?.attributes?.unit_price.replace(/[$]/g, ''),
      name: val?.attributes?.name,
    }));

  const httpBody = {
    data: {
      attributes: {
          type: "draft_creation",               // pass "order_creation" or "draft_creation"
          status: "draft",
          location: this.state.poShippingAddress,
          purchase_order_number: this.state.poNumberUnique,
          budget_id: this.state.poBudget,
          supplier_id: this.state.poSupplier,
          order_date: this.state.poOrderDate,
          delivery_date: this.state.deliveryDate,
          shipping_address: this.state.poShippingAddressOptional? this.state.poShippingAddressOptional:  this.state.poShippingAddress,
          billing_address: this.state.poBillingAddress,
          comment: this.state.poComment,
          taxation_id: this.state.taxName,
          department_id: this.state.poDepartment,
          payment_term_id: this.state.paymentTermsNameSuppliers.map((value: any)=>{return value?.id}),
          account_id: this.state.accountId,
          non_catalogue_items_attributes:nonCatalogueItemsCatched,
          product_purchase_orders_attributes: productPurchaseOrdersCatched
      }
  }
  };

  const saveAsDraftAddPOPostApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseOrderAPICallId = saveAsDraftAddPOPostApiMsg.messageId;

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_orders"
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  saveAsDraftAddPOPostApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftAddPOPostApiMsg.id, saveAsDraftAddPOPostApiMsg); 
};

handleSaveAsDraftPrPurchaseOrder = () => {
  if(!this.isSaveAsDraftPurchaseOrderValidCheck()){
    console.error("Save As Draft PR PO validation failed!");
    this.setState({
      showIsCreatePrPurchaseOrderValidation: true,
    })
    return
  }
  this.setState({prPoSaveDraft: true})
  const token_localStorage = localStorage.getItem('authToken') 
  console.log("Handle Save as Draft PR PO")
  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    data: {
      attributes: {
          type: "draft_creation",               // pass "order_creation" or "draft_creation"
          status: 'draft',
          location: this.state.poBillingAddress,
          purchase_order_number: this.state.prPoNumberUnique,
          budget_id: this.state.poBudget,
          supplier_id: this.state.poSupplier,
          order_date: this.state.prPoSelectedRequisitionCreationDate,
          delivery_date: this.state.prPoSelectedRequisitionDeliveryDate,
          shipping_address: this.state.prPoShippingAddressOptional? this.state.prPoShippingAddressOptional:  this.state.poShippingAddress,
          billing_address: this.state.poBillingAddress,
          comment: this.state.poComment,
          taxation_id: this.state.taxName,
          department_id: this.state.prPoSelectedRequisitionDepartment?.id,
          payment_term_id: this.state.paymentTermsName,
          account_id: this.state.accountId,
          non_catalogue_items_attributes:this.state.customFromRequisitionNonCataItemData.filter((val)=>{
            return val?.attributes?.theInputIsNonCatalog}).map((value)=>{
            const name = value?.attributes?.name
            const quantity = value?.quantity
            return {
              name,
              quantity,
              is_non_catalague_item: value?.attributes?.theInputIsNonCatalog
            }
          }),
          product_purchase_orders_attributes: this.state.customFromRequisitionCataItemData.filter((val)=>{
            return val?.id;
          }).map((val)=>{
            return {
              product_id: val?.id,
              quantity: val?.quantity,
              product_price: val?.attributes?.unit_price.replace(/[$]/g, ''),
              name: val?.attributes?.name,
            }
          })
      }
  }
  };

  console.log('Save As Draft PR PO details ', httpBody)

  const saveAsDraftPurchaseOrderApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftPurchaseOrderAPICallId = saveAsDraftPurchaseOrderApiMsg.messageId;

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_my_forms/my_forms/purchase_orders"
  );

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  saveAsDraftPurchaseOrderApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  runEngine.sendMessage(saveAsDraftPurchaseOrderApiMsg.id, saveAsDraftPurchaseOrderApiMsg); 
};


isSaveAsDraftSupplierValidCheck = () => {
  let flag = true;
  if(this.state.supplierEmailAddress && !RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.supplierEmailAddress)){
    this.setState({
      supplierEmailAddressEmailMatchError: "Enter valid Email",
    })
    flag=false;
  }
  return flag;
};

isSaveAsDraftProductValidCheck = () => {
 return true;
};

handleSaveAsDraftBudget = () => {
  if(!this.isSaveAsDraftBudgetValidCheck()){
    console.error("Save As Draft Budget validation failed");
    this.setState({
      showIsCreateBudgetValidation: true
    })
    return
  }
  const token_localStorage = localStorage.getItem('authToken') 

  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const httpBody = {
    data: {
      attributes: {
        type: "draft_creation", // pass "budget_creation" or "draft_creation"
        name: this.state.budgetName,
        amount: this.state.budgetAmount,
        period: this.state.budgetPeriod,       // pass "Annually", "Monthly" or "Quarterly"
        comment: this.state.budgetComment,
        product_category_id: this.state.budgetProductCategories,
        budget_for_id: this.state.budgetFor
      }
    }
  };

  console.log('Http Body Save As Draft Budget', httpBody)

  const saveAsDraftBudgetApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.saveAsDraftBudgetAPICallId = saveAsDraftBudgetApiMsg.messageId;

  saveAsDraftBudgetApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/budgets`
  );

  saveAsDraftBudgetApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  saveAsDraftBudgetApiMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  
  saveAsDraftBudgetApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );
  
  runEngine.sendMessage(saveAsDraftBudgetApiMsg.id, saveAsDraftBudgetApiMsg);
};

isSaveAsDraftBudgetValidCheck = () => {
  return true;
};

// Modification end

getAllPurchaseRequisitionsList = () => {
  const token_localStorage = localStorage.getItem('authToken') 

  let headers = { 
    'Content-Type': 'application/json',
    'token': token_localStorage,
  }

  const getAllPurchaseRequisitionApiMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getAllPurchaseRequisitionListAPICallId = getAllPurchaseRequisitionApiMsg.messageId;

  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_my_forms/my_forms/purchase_requisitions?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`
  );

  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  
  getAllPurchaseRequisitionApiMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(getAllPurchaseRequisitionApiMsg.id, getAllPurchaseRequisitionApiMsg); 
}

isEditPurchaseRequisitionValidCheck = () => {
  let flag=true;
  if(this.state.editedDepartment === ""){
    this.setState({
      editedDepartmentRequiredError: "Enter department *",
    })
    flag=false;
  }
  if(this.state.selectedProductIds.length == 0){
    this.setState({
      editedProductsRequiredError: "Select product(s) *",
    })
    flag=false;
  }
  if(this.state.editedDeliveryDate === ""){
    this.setState({
      editedDeliveryDateRequiredError: "Enter Delivery date *",
    })
    flag=false;
  }
  if(this.state.editedDurationDate === ""){
    this.setState({
      editedDurationDateRequiredError: "Enter Duration date *",
    })
    flag=false;
  }
  if(this.state.editedLocation === ""){
    this.setState({
      editedLocationRequiredError: "Enter location *",
    })
    flag=false;
  }
  return flag;
}
  
  handleShowPurchaseRequisition = (purchaseRequisitionId: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Show Purchase Requisition")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showPurchaseRequisitionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showPurchaseRequisitionAPICallId = showPurchaseRequisitionApiMsg.messageId;
  
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisitions/${purchaseRequisitionId}`
    );
  
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showPurchaseRequisitionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showPurchaseRequisitionApiMsg.id, showPurchaseRequisitionApiMsg); 
  }
  
  handleGetSettingsLocation = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Show Purchase Requisition")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSettingsLocationListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsLocationAPICall = showSettingsLocationListing.messageId;
  
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings`
    );
  
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsLocationListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsLocationListing.id, showSettingsLocationListing); 
  }
  
  handleGetDataCategorySettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Show Purchase Requisition")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSettingsCategoryListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsCategoryAPICall = showSettingsCategoryListing.messageId;
  
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings`
    );
  
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsCategoryListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsCategoryListing.id, showSettingsCategoryListing); 
  }
  
  handleGetDataUnitMeasurementSettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSettingsUnitMeasurementListing = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsUnitMeasurementAPICall = showSettingsUnitMeasurementListing.messageId;
  
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings`
    );
  
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    showSettingsUnitMeasurementListing.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsUnitMeasurementListing.id, showSettingsUnitMeasurementListing); 
  }
  handleDeleteSettingUnitMeasurement = (id: number) => {
    console.log("UnitMeasurement ID ", id)
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Delete UnitMeasurement")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deleteSettingUnitMeasurementApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteSettingUnitMeasurementAPICallId = deleteSettingUnitMeasurementApiMsg.messageId;
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings/${id}`
    );
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteSettingUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteSettingUnitMeasurementApiMsg.id, deleteSettingUnitMeasurementApiMsg);

  }
  handleDeleteSettingUnitMeasurementSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for deleteUnitMeasurement API', apiResponse?.errors)
      this.setState({
        unitMeasurementDeleteMessage: 'Unit Measurement is not deleted',
      })
    } else {
      console.log('Response for deleteLocation API', apiResponse);
      this.setState({
        unitMeasurementDeleteMessage: 'Unit Measurement is deleted',
      })
      this.handleGetDataUnitMeasurementSettings()
    }
  }

  handleDeleteSettingUnitMeasurementAPICallIdResponse = (message:Message) => {
    if (this.deleteSettingUnitMeasurementAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteSettingUnitMeasurementSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  getAllProductsList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get products List")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllProductsListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllProductsListAPICallId = getAllProductsListApiMsg.messageId;
  
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/products?column=${this.state.cofItemsFilterMultiState}&query=${this.state.itemsCofSearchBySpecificValue}`
    );
  
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllProductsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllProductsListApiMsg.id, getAllProductsListApiMsg); 
  }

  handleGetSearchedProductsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getAllProductsList API', apiResponse?.errors)
    } else {
      console.log('Response for getAllProductsList API', apiResponse);
      this.setState({
        productsSearch: apiResponse
      })
      this.handleGetAllProductsListItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetAllSearchProductsListAPICallIdResponse = (message:Message) => {
    if (this.getSearchedProductAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetSearchedProductsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getSearchedNonCatalougeItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      prNonCatalougeItems: event.target.value
    })
  }

  getSearchedEditedNonCatalougeItems = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      prEditNonCatalougeItems: event.target.value
    })
  }

  getAllSuppliersList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get suppliers List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllSuppliersListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllSuppliersListAPICallId = getAllSuppliersListApiMsg.messageId;
  
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_suppliers?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`
    );
  
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllSuppliersListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllSuppliersListApiMsg.id, getAllSuppliersListApiMsg); 
  }

  handleRfpRecordSupplierMailSend = () => {
    this.setState({isRfpMailSending : true})

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Rfp supplier mail send")

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRfpSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `send_email_to_supplier?id=${this.state.catchRfpEmailSendToSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }


  handleEditedRfpRecordSupplierMailSend = () => {
    this.setState({isEditedRfpMailSending : true})

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Edited Rfp supplier mail send")

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleEditedSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditedRfpSupplierMailSendApiCallId = handleEditedSupplierMailSendApi.messageId;

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `send_email_to_supplier?id=${this.state.catchEditedRfpEmailSendToSupplier}`
    );

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleEditedSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleEditedSupplierMailSendApi.id, handleEditedSupplierMailSendApi);
  }





  getAllRFP = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get RFP")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllRFPListAPICallId = getAllRFPListApiMsg.messageId;
  
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `list_of_proposal?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`,
    );
  
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllRFPListApiMsg.id, getAllRFPListApiMsg); 
  }


  isCreateRFPValidCheck = () => {
    let flag = true;
    if (this.state.rfpNumberUnique === "") {
      this.setState({
        rfpUniqNumberRequiredError: "RFP Number Is Not Generated *",
      })
      flag = false;
    }
    if (this.state.rfpCurrency === "") {
      this.setState({
        rfpCurrencyRequiredError: "Enter Currency *",
      })
      flag = false;
    }
    if (this.state.rfpDueDate === "") {
      this.setState({
        rfpDueDateRequiredError: "Enter Due Date *",
      })
      flag = false;
    }
    if (this.state.rfpCreationDate === "") {
      this.setState({
        rfpCreationDateRequiredError: "Enter Creation Date *",
      })
      flag = false;
    }
    if (this.state.rfpDesiredDeliveryDate === "") {
      this.setState({
        rfpDesiredDeliveryDateRequiredError: "Enter Desired Delivery Date *",
      })
      flag = false;
    }
    if (this.state.filteredRfpProducts.length === 0) {
      this.setState({
        rfpExistingProductRequiredError: "Select Products *",
      })
      flag = false;
    }
    if (this.state.rfpFileAttachment === "") {
      this.setState({
        rfpFileAttachmentRequiredError: "Select File *",
      })
      flag = false;
    }
    if (this.state.locationDropdownList && this.state.rfpLocation === "") {
      this.setState({
        rfpLocationRequiredError: "Select Location *",
      })
      flag = false;
    }
    if (this.state.rfpDepartments === "") {
      this.setState({
        rfpDepartmentRequiredError: "Select Department *",
      })
      flag = false;
    }
    if (this.state.rfpSelectExistingSupplier.length === 0) {
      this.setState({
        rfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: "Select supplier *",
        // openCreateRfpShowErrorModal: true,
      })
      flag = false;
    }
    if(this.state.rfpEmailOfSupplier && !this.state.rfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        rfpSupplierEmailMatchError: "Enter a valid email",
      })
      flag=false;
    }
    if (!this.state.locationDropdownList) {
      this.setState({
        locationExistOrNotCheckingRequiredError: "location can't be select! Create location from setting first *",
      })
      flag = false;
    }
    return flag;
  }


  handleRFPPdfSupplierScreenOpenModal = () => {
    this.setState({
      openRfpPdfSupplierScreenModal:true
    })
  }
  
  handleCreateRFP = async () => {
    if (!this.isCreateRFPValidCheck()) {
      console.log("Create RFP")
      this.setState({
        showIsCreateRFPValidation: true,
        openCreateRfpShowErrorModal: true,
      })
      return
    }

    this.setState({isRfpCreating : true})

    const token_localStorage = localStorage.getItem('authToken')

    let headers = {
      'token': token_localStorage,
    }

    const filteredFinalcatelog : any[] = this.state.filteredRfpProducts.filter((value: any) => value?.id).map((value: any)=>{
          const { quantity, id: product_id } = value;
          return {
            product_id,
            quantity 
          };
    })

    const filteredFinalNonCatelog : any[] = this.state.filteredRfpProducts.filter((value: any) => value?.attributes?.theInputIsNonCatalog).map((value: any)=>{
          const name = value?.attributes?.name
          const quantity = value?.quantity
          return {
            name,
            quantity,
            is_non_catalague_item: value?.attributes?.theInputIsNonCatalog
          }
    })

    let formdata = new FormData();
    formdata.append("type", "draft_creation");
    formdata.append("status", "draft");
    formdata.append("currency", this.state.rfpCurrency);
    formdata.append("creation_date", this.state.rfpCreationDate);
    formdata.append("due_date", this.state.rfpDueDate);
    formdata.append("desired_delivery_date", this.state.rfpDesiredDeliveryDate);
    formdata.append("file", this.state.rfpFileAttachment);
    formdata.append("location_setting_id", this.state.rfpLocation);
    formdata.append("department_id", this.state.rfpDepartments);
    formdata.append("account_id", this.state.accountId);
    formdata.append("rfp_number", this.state.rfpNumberUnique);

    for(let i=0; i<this.state.rfpSelectExistingSupplier.length;i++){
      const suppliers = this.state.rfpSelectExistingSupplier[i];
      formdata.append(`supplier_id[${i}][]`,suppliers)
    }
    
    for(let i=0; i<filteredFinalcatelog.length; i++){
      const product = filteredFinalcatelog[i];
      formdata.append(`request_for_proposal_items_attributes[${i}][product_id]`, product?.product_id);
      formdata.append(`request_for_proposal_items_attributes[${i}][quantity]`, product?.quantity);
    }

    for(let i=0; i<filteredFinalNonCatelog.length; i++){
      const product = filteredFinalNonCatelog[i];
      formdata.append(`rfp_non_catelogues_attributes[${i}][name]`, product?.name);
      formdata.append(`rfp_non_catelogues_attributes[${i}][quantity]`, product?.quantity);
    }

    const createRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createRfpAPICallId = createRFPListApiMsg.messageId;

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "request_for_proposal"
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    createRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(createRFPListApiMsg.id, createRFPListApiMsg);
  }


  handleShowRFP = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Get RFP List")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const showRfpApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showRfpAPICallId = showRfpApiMsg.messageId;

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_rpf?id=${id}`
    );

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    showRfpApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(showRfpApiMsg.id, showRfpApiMsg);
  }

  isEditRFPValidCheck = (): boolean => {
    let flag = true;
    if (this.state.editedRfpCurrency === "") {
      this.setState({
        editedRfpCurrencyRequiredError: "Select Currency *",
      })
      flag = false;
    }
    if (this.state.editedRfpCreationDate === "") {
      this.setState({
        editedRfpCreationDateRequiredError: "Enter Creation date *",
      })
      flag = false;
    }
    if (this.state.editedRfpDueDate === "") {
      this.setState({
        editedRfpDueDateRequiredError: "Enter Due date *",
      })
      flag = false;
    }
    if (this.state.editedRfpDesiredDeliveryDate === "") {
      this.setState({
        editedRfpDesiredDeliveryDateRequiredError: "Enter Desired date *",
      })
      flag = false;
    }
    if (this.state.editedRfpLocation === "") {
      this.setState({
        editedRfpLocationRequiredError: "Select Location *",
      })
      flag = false;
    }
    if (this.state.editedRfpDepartment === "") {
      this.setState({
        editedRfpDepartmentRequiredError: "Select Department *",
      })
      flag = false;
    }
    if (this.state.editedRfpSelectExistingSupplier === '') {
      this.setState({
        editedRfpSelectExistingSuppliersOrEmailOfSupplierRequiredError: "Select Supplier *",
      })
      flag = false;
    }
    if (this.state.editedRfpFileAttachment === "") {
      this.setState({
        editedRfpFileAttachmentRequiredError: "Select File *",
      })
      flag = false;
    }

    if (this.state.editedRfpCatelogItems.length === 0) {
      this.setState({
        editedRfpExistingProductRequiredError: "Select Products *",
      })
      flag = false;
    }
    if(this.state.editedRfpEmailOfSupplier && !this.state.editedRfpEmailOfSupplier.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        rfpSupplierEmailMatchError: "Enter a valid email",
      })
      flag=false;
    }
    

    return flag;
  }


  handleRfpShowActionTriggered = (result : {}) => {
    this.setState({
      rfpData: result,
      openShowRFPModal: true
    });
  }


  handleEditRFP = () => {
    if(!this.isEditRFPValidCheck()){
      this.setState({
        showIsEditRFPValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Edit RFP")
    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("currency", this.state.editedRfpCurrency);
    formdata.append("due_date", this.state.editedRfpDueDate);
    formdata.append("desired_delivery_date", this.state.editedRfpDesiredDeliveryDate);
    formdata.append("address", this.state.editedRfpAddress);
    formdata.append("creation_date", this.state.editedRfpCreationDate);
    if(this.state.editedRfpFileAttachment?.name){
      formdata.append("file", this.state.editedRfpFileAttachment);
    }
    formdata.append("pdf_attach", this.state.editedRfpAttachment);
    formdata.append("product_id", this.state.editedRfpExistingProduct);
    console.log('After Editing RFP Selected Existing Suppliers ',this.state.editedRfpSelectExistingSupplier)
    let a = this.state.editedRfpSelectExistingSupplier
    
    for (let supplierId of a) {
      formdata.append("supplier_id[]", supplierId);
    }

    for (let pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }

    const editRFPListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editRfpAPICallId = editRFPListApiMsg.messageId;

    console.log('Update ID :' ,this.state.editRfp?.id)
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_request_proposal/${this.state.editRfp?.id}`
    );
  
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    editRFPListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editRFPListApiMsg.id, editRFPListApiMsg); 
  }

  handleGetAllPurchaseOrders = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllPurchaseOrderListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllPurchaseOrderListAPICallId = getAllPurchaseOrderListApiMsg.messageId;
  
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`
    );
  
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllPurchaseOrderListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllPurchaseOrderListApiMsg.id, getAllPurchaseOrderListApiMsg);
  }

  isCreatePurchaseOrderValidCheck = () => {
    let flag = true
    if(this.state.paymentTermsName === ""){
      this.setState({
        purchaseOrderPaymentTermsRequiredField: "Select the PaymentTerms *",
      })
      flag=false;
    }
    if(this.state.taxName === ""){
      this.setState({
        purchaseOrderTaxationNameRequiredField: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.poSupplier === ""){
      this.setState({
        purchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.poOrderDate === ""){
      this.setState({
        purchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.poBillingAddress === ""){
      this.setState({
        purchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.poBudget === ""){
      this.setState({
        purchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.poDepartment === ""){
      this.setState({
        purchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.poComment === ""){
      this.setState({
        purchaseOrderCommentRequiredField: "Enter the Notes *",
      })
      flag=false;
    }
    if(this.state.filteredPoProducts.length === 0){
      this.setState({
        purchaseOrderProductsRequiredField: "Select the Products *",
      })
      flag=false;
    }

    if(this.state.deliveryDate === ""){
      this.setState({
        purchaseOrderDeliveryDateRequiredField: "Enter the Delivery Date *",
      })
      flag=false;
    }
    if(new Date(this.state.deliveryDate)
    .getDate() < new Date(this.state.poOrderDate)
    .getDate()){
      this.setState({
        purchaseOrderDeliveryDateErrorFormat: 'Delivery Date must be bigger'
      })
      return flag
    }
    return flag
  }

  handlePOPdfSupplierScreenOpenModal = () => {
    this.setState({
      openPoPdfSupplierScreenModal:true
    })
  }

  handleCreatePurchaseOrder = () => {
    if(!this.isCreatePurchaseOrderValidCheck()){
      console.log("CREATE PURCHASE ORDER");
      
      this.setState({
        showIsCreatePurchaseOrderValidation: true,
        openCreatePoShowErrorModal: true
      })
      return;
    }
    this.handlePOPdfSupplierScreenOpenModal();

    const refactoredFuncShippingAddressPO = () =>{
      if(this.state.poShippingAddressOptional){
        return this.state.poShippingAddressOptional;
      }else{
        return this.state.poShippingAddress
      }
    }

    const nonCatalogueItemsAddPOCatched = this.state.filteredPoProducts
    .filter(val => val?.attributes?.theInputIsNonCatalog)
    .map(value => ({
      name: value?.attributes?.name,
      quantity: value?.quantity,
      is_non_catalague_item: value?.attributes?.theInputIsNonCatalog,
    }));

    const productPurchaseOrdersAddCatched = this.state.filteredPoProducts
    .filter(val => val?.id)
    .map(val => ({
      product_id: val?.id,
      quantity: val?.quantity,
      product_price: val?.attributes?.unit_price.replace(/[$]/g, ''),
      name: val?.attributes?.name,
    }));
    
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Create PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: this.state.poBillingAddress,
            purchase_order_number: this.state.poNumberUnique,
            budget_id: this.state.poBudget,
            supplier_id: this.state.poSupplier,
            order_date: this.state.poOrderDate,
            delivery_date: this.state.deliveryDate,
            shipping_address: refactoredFuncShippingAddressPO(),
            billing_address: this.state.poBillingAddress,
            comment: this.state.poComment,
            taxation_id: this.state.taxName,
            department_id: this.state.poDepartment,
            account_id: this.state.accountId,
            non_catalogue_items_attributes: nonCatalogueItemsAddPOCatched,
            product_purchase_orders_attributes: productPurchaseOrdersAddCatched
        }
    }
    };

    console.log('Created PO details', httpBody)
  
    const createPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createPurchaseOrderAPICallId = createPurchaseOrderApiMsg.messageId;
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/purchase_orders"
    );
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createPurchaseOrderApiMsg.id, createPurchaseOrderApiMsg); 
  }

  refactoredEditedFuncShippingAddress = () =>{
    if(this.state.editedPoShippingAddressOptional){
      return this.state.editedPoShippingAddressOptional;
    }else{
      return this.state.editedPoShippingAddress
    }
  }

  isEditedNonCatalougeType = (val: any) =>{
    if(val?.attributes?.theInputIsNonCatalog === true){
      return val?.attributes?.theInputIsNonCatalog
    }else if(val?.is_non_catalague_item === true){
      return val?.is_non_catalague_item 
    }else{
      return Number(val?.id)
    }
  }

  nonCatalougeNameVal = (value: any) =>{
    if(value?.attributes?.name){
      return value?.attributes?.name
    }else{
      return value?.name
    }
  }

  isNonCata = (value: any) =>{
    if(value?.attributes?.theInputIsNonCatalog){
      return value?.attributes?.theInputIsNonCatalog
    }else{
      return value?.theInputIsNonCatalog
    }
  }

  catalougeProductId = (val: any) =>{
    if(Number(val?.product_id)){
      return Number(val?.product_id)
    }else{
      return Number(val?.id)
    }
  }

  productPriceCatalouge = (val: any) => {
    return val?.attributes?.unit_price.replace(/[$]/g, '') || val?.product_price
  }

  editedPoNonCatalougeItemValue=  () =>{
   return this.state.editedPoProductNonCatalouge.filter((value)=> {
      return this.isEditedNonCatalougeType(value)
    }).map((value)=>{
       const name = this.nonCatalougeNameVal(value)
       const quantity = value?.quantity
       if(value?._destroy === 0 || value?._destroy || value?.is_non_catalague_item === true || value?.id){ 
        return {
          id: value?.id,
          name,
          quantity,
          is_non_catalague_item: this.isNonCata(value),
          _destroy: value?._destroy
        }
       }else if(value?.attributes?.theInputIsNonCatalog === true){
         return {
           name,
           quantity,
           is_non_catalague_item: this.isNonCata(value),
         }
       }
    })
  }

  productPoAttributesValue = () =>{
    return this.state.editedPoProductCatalouge.filter((val)=>{
      return Number(val?.product_id)?Number(val?.product_id):Number(val?.id)
    }).map((val)=>{
      console.log("data",val);
      
      if(val?._destroy === 0 || val?._destroy){
          return {
            id: val?.id,
            product_id: this.catalougeProductId(val),
            quantity: val?.quantity,
            product_price: this.productPriceCatalouge(val),
            name: val?.attributes?.name || val?.name,
            _destroy: val?._destroy
        }
      }else if(Number(val?.product_id)){
        return{
          id: val?.id,
            product_id: this.catalougeProductId(val),
            quantity: val?.quantity,
            product_price: this.productPriceCatalouge(val),
            name: val?.attributes?.name || val?.name,
            _destroy: 0
        }
      }else if(Number(val?.id)){
        return{
          product_id: this.catalougeProductId(val),
          quantity: val?.quantity,
          product_price: this.productPriceCatalouge(val),
          name: val?.attributes?.name || val?.name,
        }
      }
    })
  }

  handleDashboardEditPurchaseOrder = () => {
    
    console.log("====>",this.state.editedPoProductCatalouge)
    console.log("gvirhor",this.state.editedPoProductNonCatalouge);
    if(!this.isDashboardEditPurchaseOrderValidCheck()){
      this.setState({
        showIsEditPurchaseOrderValidation: true,
      })
      return
    }

    this.setState({poEditing: true})
    
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",               // pass "order_creation" or "draft_creation"
            status: "open",
            supplier_id: this.state.editedPoSupplier,
            order_date: this.state.editedPoOrderDate,
            delivery_date: this.state.editedPoDeliveryDate,
            shipping_address: this.refactoredEditedFuncShippingAddress(),
            billing_address: this.state.editedPoBillingAddress,
            comments_for_po: this.state.editedPoCommentForPO,
            amount_calculation: this.state.editedPoAmountCalculation,
            department_id: this.state.editedPoDepartment,
            budget_id: this.state.editedPoBudget,
            comment: this.state.editedPoComment,
            taxation_id: this.state.editedPoTaxId,
            payment_term_id: this.state.editedPoPaymentTermsName,
            approved : this.state.poAcceptRejectActionStatus,
            non_catalogue_items_attributes: this.editedPoNonCatalougeItemValue(),
            product_purchase_orders_attributes: this.productPoAttributesValue()
        }
      }
    };

    console.log("HTTP ", httpBody.data.attributes.product_purchase_orders_attributes)

    const editPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.dashboardEditPurchaseOrderAPICallId = editPurchaseOrderApiMsg.messageId;
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${this.state.editedPoId}`
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseOrderApiMsg.id, editPurchaseOrderApiMsg); 
  };


  downloadPOPdfBlob = (blob : Blob) => {
    const url = window.URL.createObjectURL(blob);
  
    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
    );
  
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  handleGetDownloadPdfPo = async () => {
    this.setState({ isPdfDownloaded: true });
  
    const token_localStorage: any = await localStorage.getItem('authToken');
    console.log("Download PO Pdf");
    this.setState({
      poFinalPdfDownloadOrNotMessage: ``
    });
  
    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.catchPoIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      this.downloadPOPdfBlob(blob);
  
      this.setState({
        poFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
        isPdfDownloaded: false
      });
    })
    .catch(error => {
      this.setState({
        poFinalPdfDownloadOrNotMessage: error?.message,
        isPdfDownloaded: true
      });
    });
  };


  downloadEditedPOPdfBlob = (blob: Blob) => {
    const url = window.URL.createObjectURL(blob);

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        document.body.appendChild(link);
        link.click()
        link.parentNode.removeChild(link);
  }

  handleEditedGetDownloadPdfPo = async () => {
    this.setState({isEditedPdfDownloaded: true})
    const token_localStorage: any = await localStorage.getItem('authToken')

    this.setState({
      editedPoFinalPdfDownloadOrNotMessage: ``
    })
    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.editedPoId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        this.downloadEditedPOPdfBlob(blob);

        this.setState({
          editedPoFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
          isEditedPdfDownloaded: false
        })
      })
      .catch(error => {
        this.setState({
          editedPoFinalPdfDownloadOrNotMessage: error?.message,
          isEditedPdfDownloaded: true
        })
      });
  }


  downloadPRPOPdfBlob = (blob : Blob) => {
    const url = window.URL.createObjectURL(blob);

    const link: any = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `PO_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
    );

    document.body.appendChild(link);
    link.click()
    link.parentNode.removeChild(link);
  }

  handleGetDownloadPdfPrPo = async () => {
    this.setState({isPdfDownloaded: true})

    const token_localStorage: any = await localStorage.getItem('authToken')
    console.log("Download PO Pdf")
    this.setState({
      prPoFinalPdfDownloadOrNotMessage: ``
    })

    fetch(`${baseURL}/bx_block_my_forms/my_forms/generate_purchase_order_pdf?purchase_order=${this.state.catchPrPoIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
    .then((response) =>{
      return response.blob()
      })
      .then((blob) => {
        this.downloadPRPOPdfBlob(blob)
        this.setState({
          prPoFinalPdfDownloadOrNotMessage: `PO file is downloaded`,
          isPdfDownloaded: false
        })
      })
      .catch(error => {
        this.setState({
          prPoFinalPdfDownloadOrNotMessage: error?.message,
          isPdfDownloaded: true
        })
      });
  }


  handleEditedPoMailSendConfirmation = () => {
    this.setState({
      isEditedPoMailSendConfirmed:true
    })
  }

  handlePoRecordSupplierMailSend = () => {
    this.setState({isPoMailSending: true, })

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Rfp supplier mail send")

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/download_pdf/${this.state.catchPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }

  handleEditedPoRecordSupplierMailSend = () => {
    console.log("catchEditedPoMailOfSupplier",this.state.catchEditedPoMailOfSupplier)
    this.setState({isPoMailSending : true, editedPoEmailToSupplierSentOrNotErrorMessage:'Email send Succesfully'})

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Rfp supplier mail send")

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPoEditSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/download_pdf/${this.state.catchEditedPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }


  handlePrPoRecordSupplierMailSend = () => {
    this.setState({isPrPoMailSending: true, })

    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Rfp supplier mail send")

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const handleSupplierMailSendApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrPoSupplierMailSendApiCallId = handleSupplierMailSendApi.messageId;

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/download_pdf/${this.state.catchPoMailOfSupplier}`
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    handleSupplierMailSendApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(handleSupplierMailSendApi.id, handleSupplierMailSendApi);
  }

  isCreatePurchaseRequistionOrderValidCheck = () => {
    let flag = true
    if(this.state.paymentTermsName === ""){
      this.setState({
        prPoSelectedRequisitionPaymentError: "Select the PaymentTerms *",
      })
      flag=false;
    }
    if(this.state.taxName === ""){
      this.setState({
        prPoSelectedRequisitionTaxationError: "Select the Taxations *",
      })
      flag=false;
    }
    if(this.state.poSupplier === ""){
      this.setState({
        prPoSelectedRequisitionSupplierError: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.poBillingAddress === ""){
      this.setState({
        prPoSelectedRequisitionBillingError: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.poBudget === ""){
      this.setState({
        prPoSelectedRequisitionBudgetError: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.poComment === ""){
      this.setState({
        purchaseOrderCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    return flag
  }

  handleCreateRequisitionPurchaseOrder = () => {
    if(!this.isCreatePurchaseRequistionOrderValidCheck()){
      console.log("CREATE PURCHASE Rquisition  ORDER");
      this.setState({
        showIsCreatePrPurchaseOrderValidation: true,
        openCreatePrPoShowErrorModal: true
      })
      return;
    }

    this.setState({prPoCreating: true})
    
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Create PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",
            location: this.state.poBillingAddress,
            purchase_order_number: this.state.prPoNumberUnique,
            budget_id: this.state.poBudget,
            supplier_id: this.state.poSupplier,
            order_date: this.state.prPoSelectedRequisitionCreationDate,
            delivery_date: this.state.prPoSelectedRequisitionDeliveryDate,
            shipping_address: this.state.prPoShippingAddressOptional?this.state.prPoShippingAddressOptional: this.state.poShippingAddress,
            billing_address: this.state.poBillingAddress,
            comment: this.state.poComment,
            taxation_id: this.state.taxName,
            department_id: this.state.prPoSelectedRequisitionDepartment?.id,
            payment_term_id: this.state.paymentTermsNameSuppliers,
            account_id: this.state.accountId,
            product_purchase_orders_attributes:this.state.customFromRequisitionCataItemData.filter((value: any) => value?.id).map((value: any)=>{
              const { quantity, id: product_id } = value;
              return {
                quantity,
                product_id
              };
            }),
            non_catalogue_items_attributes:this.state.customFromRequisitionCataItemData.filter((value: any) => value?.attributes?.isNew).map((value: any)=>{
              const name = value?.attributes?.name
              const quantity = value?.quantity
              return {
                name,
                quantity,
                is_non_catalague_item: value?.attributes?.isNew
              }
            })
        }
    }
    };

    console.log('Created PO details', httpBody)
  
    const createPurchaseRequisitionOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createPurchaseRequisitionOrderAPICallId = createPurchaseRequisitionOrderApiMsg.messageId;
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_requisition/${this.state.customFromRequisitionSelectedId}/purchase_order_from_requisition`
    );
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createPurchaseRequisitionOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createPurchaseRequisitionOrderApiMsg.id, createPurchaseRequisitionOrderApiMsg); 
  }

  isEditPurchaseOrderValidCheck = () => {
    let flag = true;
    if(this.state.editedPoLocation === ""){
      this.setState({
        editedPurchaseOrderLocationRequiredField: "Enter the Location *",
      })
      flag=false;
    }
    if(this.state.editedPoSupplier === ""){
      this.setState({
        editedPurchaseOrderSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    if(this.state.editedPoOrderDate === ""){
      this.setState({
        editedPurchaseOrderOrderDateRequiredField: "Select the Order Date *",
      })
      flag=false;
    }
    if(this.state.editedPoBillingAddress === ""){
      this.setState({
        editedPurchaseOrderBillingAddressRequiredField: "Enter the Billing Address *",
      })
      flag=false;
    }
    if(this.state.editedPoShippingAddress === ""){
      this.setState({
        editedPurchaseOrderShippingAddressRequiredField: "Enter the Shipping Address *",
      })
      flag=false;
    }
    if(this.state.editedPoAmountCalculation === ""){
      this.setState({
        editedPurchaseOrderAmountCalculationRequiredField: "Enter the Amount Calculation *",
      })
      flag=false;
    }
    if(this.state.editedPoFobPoint === ""){
      this.setState({
        editedPurchaseOrderFobPointRequiredField: "Enter the FOB Point *",
      })
      flag=false;
    }
    if(this.state.editedPoBudget === ""){
      this.setState({
        editedPurchaseOrderBudgetRequiredField: "Enter the Budget *",
      })
      flag=false;
    }
    if(this.state.editedPoDepartment === ""){
      this.setState({
        editedPurchaseOrderDepartmentRequiredField: "Select the Department *",
      })
      flag=false;
    }
    if(this.state.editedPoCommentForPO === ""){
      this.setState({
        editedPurchaseOrderCommentsForPoRequiredField: "Enter the Comment for PO *",
      })
      flag=false;
    }
    if(this.state.editedPoComment === ""){
      this.setState({
        editedPurchaseOrderCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.editedPoModeOfTransit === ""){
      this.setState({
        editedPurchaseOrderModeOfTransitRequiredField: "Enter the Mode of Transit *",
      })
      flag=false;
    }
    if(this.state.editedPoProduct.length === 0){
      this.setState({
        editedPurchaseOrderProductsRequiredField: "Select the Products *",
      })
      flag=false;
    }
    if(this.state.editedPoGstNumber === ""){
      this.setState({
        editedPurchaseOrderGstNumberRequiredField: "Enter the GST Number *",
      })
      flag=false;
    }
    return flag;
  }  

  handleEditPurchaseOrder = () => {
    if(!this.isEditPurchaseOrderValidCheck()){
      this.setState({
        showIsEditPurchaseOrderValidation: true,
      })
      return
    }

    this.setState({poEditing: true})
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Edit PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const httpBody = {
      data: {
        attributes: {
            type: "order_creation",               // pass "order_creation" or "draft_creation"
            location: this.state.editedPoLocation,
            supplier_id: this.state.editedPoSupplier,
            order_date: this.state.editedPoOrderDate,
            fob_point: this.state.editedPoFobPoint,
            shipping_address: this.state.poShippingAddress,
            billing_address: this.state.poBillingAddress,
            gst_number: this.state.poGstNumber,
            mode_of_transit: this.state.editedPoModeOfTransit,
            comments_for_po: this.state.editedPoCommentForPO,
            amount_calculation: this.state.editedPoAmountCalculation,
            department_id: this.state.editedPoDepartment,
            budget: this.state.editedPoBudget,
            comment: this.state.editedPoComment,
            product_ids: this.state.editedPoProduct,
            approved : this.state.poAcceptRejectActionStatus
        }
      }
    };

    console.log("HTTP ", httpBody)

    const editPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editPurchaseOrderAPICallId = editPurchaseOrderApiMsg.messageId;
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${this.state.editedPoId}`
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    runEngine.sendMessage(editPurchaseOrderApiMsg.id, editPurchaseOrderApiMsg); 
  }

  handleShowPurchaseOrder = (purchaseOrderId: any) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Show PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const showPurchaseOrderApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showPurchaseOrderAPICallId = showPurchaseOrderApiMsg.messageId;
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/purchase_orders/${purchaseOrderId}`
    );
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showPurchaseOrderApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showPurchaseOrderApiMsg.id, showPurchaseOrderApiMsg); 
  }

  handleCustomFromRequisitionCreatePO = () => {
    this.handleGetInformationSettings()
    if(this.state.customFromRequisitionSelectedRow){
      this.setState({
        openCustomFromRequisitionCreatePoModal: true,
        prPoNumberUnique: Math.floor(Math.random() * 900000000) + 100000000,
      })
    }
    if(!this.state.customFromRequisitionSelectedRow){
      this.setState({
        customFromRequisitionSelectMessage: 'Please select a Requisition',
      })
    }
  }

  handleCloseCustomFromRequisitionCreatePO = () => {
    this.setState({
      openCustomFromRequisitionCreatePoModal: false
    })
  }

  handleClosePurchaseFromRequisitionCreatePO = () => {
    this.setState({
      openCustomFromRequisitionCreatePoModal: false,
      prPoSelectedRequisitionBudgetError:'',
      prPoSelectedRequisitionSupplierError:'',
      prPoSelectedRequisitionPaymentError:'',
      prPoSelectedRequisitionTaxationError:'',
      prPoSelectedRequisitionShippingError:'',
      prPoSelectedRequisitionBillingError:'',
      prPoSelectedRequisitionDepartment:'',
      prPoSelectedRequisitionNumber:'',
      prPoSelectedRequisitionCreationDate:'',
      prPoSelectedRequisitionDeliveryDate:'',
      purchaseOrderCommentRequiredField: '',
      poBudget:'',
      poSupplier:'',
      paymentTermsName:'',
      taxName:'',
      poShippingAddress:'',
      poBillingAddress:'',
      prPoCreateScreenActiveTabs: 0,
      poComment: '',
      openPrPoPdfSupplierScreenModal: false,
      openPrPoSupplierMailSeccessModal: false,
      prPoSaveDraft: false,
    })
  }

  handleCustomFromRequisitionSnackbarClose = () => {
    this.setState({
      customFromRequisitionSelectMessage: '',
    })
  }

  handlePrPoNotItemsFoundMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoItemsNotFoundMessage: '',
    })
  }

  handlePurchaseRequisitionCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseRequisitionCreateMessage: '',
    })
  }

  handlePurchaseRequisitionEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseRequisitionEditMessage: '',
    })
  }

  handleDashboardNoRecordMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      dashboardNoRecordMessage: '',
    })
  }


  handleNoDashModulePermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noDashModulePermitMessage: '',
    })
  }

  handleNoCreateOrFindTabModulesPermitMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      noCreateOrFindTabModulesPermitMessage: '',
    })
  }


  handleRfpCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpCreateMessage: '',
    })
  }

  handleRfpCreateDueDateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpCreateMessagedDueDate: '',
    })
  }


  handleRfpEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpEditMessage: '',
    })
  }

  handlePoCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poCreateMessage: '',
    })
  }


  handlePaymentTermsFetchSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsFetchMessage: '',
    })
  }

  handlePrPoCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoCreateMessage: '',
    })
  }

  handlePoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poFinalPdfDownloadOrNotMessage: '',
    })
  }

  handlePrPoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoFinalPdfDownloadOrNotMessage: '',
    })
  }

  handlePoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handlePrPoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      prPoEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handlePoEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      poEditMessage: '',
    })
  }

  handleReceiptCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptCreateMessage: '',
    })
  }

  handleReceiptFileTypeSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptSelectFileTypeMessage: '',
    })
  }

  handleReceiptEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      receiptEditMessage: '',
    })
  }

  handleSupplierCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierCreateMessage: '',
    })
  }

  handleSupplierEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierEditMessage: '',
    })
  }

  handleProductCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productCreateMessage: '',
    })
  }

  handleProductEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productEditMessage: '',
    })
  }

  handleBudgetCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      budgetCreateMessage: '',
    })
  }

  handleBudgetEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      budgetEditMessage: '',
    })
  }

  handleInformationSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      informationSettingsEditMessage: '',
    })
  }

  handleCurrencySettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      currencySettingsEditMessage: '',
    })
  }

  handleLocationSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationCreateMessage: '',
    })
  }

  handleEditedPoEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleEditedPoSuccessOrNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoSuccessOrNotMessage: '',
    })
  }


  handleSupplierFoundNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      supplierFoundOrNotMessage: '',
    })
  }

  handlePurchaseOrderDraftSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      purchaseOrderDraftMessage: '',
    })
  }

  handleProductFoundNotSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      productsFoundOrNotMessage: '',
    })
  }

  handleEditedPoFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedPoFinalPdfDownloadOrNotMessage: '',
    })
  }

  handleLocationSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationDeleteMessage: '',
    })
  }

  handleLocationSettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationUpdateMessage: '',
    })
  }
  
  handleUnitMeasurementDeleteSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementDeleteMessage: '',
    })
  }


  handleUnitMeasurementDeleteSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementCreateMessage: '',
    })
  }

  handleUnitMeasurementDeleteSettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementUpdateMessage: '',
    })
  }

  handleUnitMeasurementSettingsDuplicasySnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      unitMeasurementSettingsDuplicasyMessage: '',
    })
  }

  handleCategorySettingsAddSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryAddMessage: '',
    })
  }

  handleCategorySettingsUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryUpdateMessage: '',
    })
  }


  handleCategorySettingsDuplicasyErrorSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryDuplicasyErrorMessage : '',
    })
  }


  handleCategorySettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      categoryDeleteMessage: '',
    })
  }

  handleUserSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userCreateMessage: '',
    })
  }



  handlePaymentTermsSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsCreateMessage: '',
    })
  }

  handlePaymentTermsSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      paymentTermsEditMessage: '',
    })
  }


  handlePaymentTermsSettingsDeletSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      PaymentTermDeleteMessage: '',
    })
  }

  handleTaxationSettingsCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      taxationsCreateMessage: '',
    })
  }

  handleTaxationSettingsEditSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      taxationEditMessage: '',
    })
  }

  handleUserSettingsDeleteSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userDeleteMessage: '',
    })
  }


  handleRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleRfpFinalPdfDownloadOrNotMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpFinalPdfDownloadOrNotMessage: '',
    })
  }


  handleRfpUpdateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      rfpUpdateMessage: '',
    })
  }

  handleEditedRfpEmailToSupplierSendOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      editedRfpEmailToSupplierSentOrNotErrorMessage: '',
    })
  }

  handleLocationPrCreateSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationCreateMessagePr: '',
    })
  }

  handleDeliveryOrDurationDateCheckPrSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      deliveryOrDurationDateCheckMessagePr: '',
    })
  }




  handleLocationExistOrNotErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      locationExistOrNotErrorMessage: '',
    })
  }


  handleUserSettingsPermissionsUpdationSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userSettingPermissionUpdatedMessage: '',
    })
  }

  handleUserSettingsPermissionsCreatedSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userPermissionsCreatedMessage: '',
    })
  }



  handleUserPermissionMissingIdErrorMessageSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      userPermissionMissingIdErrorMessage: '',
    })
  }


  handleGetAllReceipts = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get All Receipts")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllReceiptsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllReceiptsAPICallID = getAllReceiptsApiMsg.messageId;
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_receipt?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`
    );
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getAllReceiptsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getAllReceiptsApiMsg.id, getAllReceiptsApiMsg);
  }

  handleGetUserProfile = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get User Profile")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserProfileAPICallId = getUserProfile.messageId;
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/user_profile`
    );
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserProfile.id, getUserProfile);
  }



  handleGetUserModulePermissions = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get User module Permissions")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUserPermissions = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserModulePermissionsAPICallId = getUserPermissions.messageId;
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/view_account`
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUserPermissions.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getUserPermissions.id, getUserPermissions);
  }

  isCreateReceiptValidCheck = () => {
    let flag = true;
    if(this.state.receiptNumberUnique === ""){
      this.setState({
        receiptNumberUniqueRequiredField: "Receipt number is not generated *",
      })
      flag=false;
    }
    if(this.state.receiptSelectedPo === ""){
      this.setState({
        receiptSelectedPoRequiredField: "Select the Purchase Order *",
      })
      flag=false;
    }
    if(this.state.receiptReceiptDate === ""){
      this.setState({
        receiptReceiptDateRequiredField: "Enter the Receipt Date *",
      })
      flag=false;
    }
    if(this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.attributes?.supplier_name === ""){
      this.setState({
        receiptSupplierNameRequiredField: "Failed to Fetch Supplier Name *",
      })
      flag=false;
    }
    if(this.state.receiptInvoiceNumber === ""){
      this.setState({
        receiptInvoiceNumberRequiredField: "Required Invoice Number *",
      })
      flag=false;
    }

    if(this.state.receiptAttachmentFile === ""){
      this.setState({
        receiptAttachmentFileRequiredField: "Select the file *",
      })
      flag=false;
    }
    if(this.state.receiptAttachment === ""){
      this.setState({
        receiptAttachmentRequiredField: "Select Attachment Type *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateReceipt = () => {
    if(!this.isCreateReceiptValidCheck()){
      console.log("Create Receipt Validation Fail")
      this.setState({
        showIsCreateReceiptValidation: true,
        openReceiptCreateShowErrorModal: true
      })
      return
    }

    this.setState({isReceiptCreating : true})

    const catQuantitiesReceived = this.state.receiptCatelogueItemsStore?.map(elem => elem?.quantity_received) ?? []
    const nonCatQuantitiesReceived = this.state.receiptNonCatelogueItemsStore?.map(elem => elem?.quantity_received) ?? []

    const totalQtyReceived = [...catQuantitiesReceived, ...nonCatQuantitiesReceived]
    .map(Number)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    console.log("totalQtyReceived", totalQtyReceived);


    const catQuantitiesRequired = this.state.receiptCatelogueItemsStore?.map(elem => elem?.quantity) ?? []
    const nonCatQuantitiesRequired = this.state.receiptNonCatelogueItemsStore?.map(elem => elem?.quantity) ?? []

    const totalQtyRequired = [...catQuantitiesRequired, ...nonCatQuantitiesRequired]
    .map(Number)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    console.log("totalQtyRequired", totalQtyRequired);



    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("total_quantity_received", JSON.stringify(totalQtyReceived));
    formdata.append("total_quantity_required", JSON.stringify(totalQtyRequired));
    formdata.append("receipt_date", this.state.receiptReceiptDate);
    formdata.append("file", this.state.receiptAttachmentFile);
    formdata.append("pdf_attach", this.state.receiptAttachment);
    formdata.append("purchase_order_number", this.state.receiptSelectedPOPopulatedData?.attributes?.purchase_order_number);
    formdata.append("supplier_id", this.state.receiptSelectedPOPopulatedData?.attributes?.supplier?.data?.id);
    formdata.append("account_id", this.state.receiptSelectedPOPopulatedData?.attributes?.account?.id);
    formdata.append("receipt_invoice_number", this.state.receiptInvoiceNumber);
    formdata.append("receipt_number", this.state.receiptNumberUnique);
    
    if(this.state.receiptCatelogueItemsStore && this.state.receiptCatelogueItemsStore.length > 0){
      for (let i = 0; i < this.state.receiptCatelogueItemsStore.length; i++) {
        formdata.append(`receipt_purchase_order_products_attributes[${i}][product_id]`, this.state.receiptCatelogueItemsStore[i]?.product_id)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][quantity_received]`, this.state.receiptCatelogueItemsStore[i]?.quantity_received)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][quantity_required]`, this.state.receiptCatelogueItemsStore[i]?.quantity)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][comments]`, this.state.receiptCatelogueItemsStore[i]?.comment_received)
        formdata.append(`receipt_purchase_order_products_attributes[${i}][purchase_order_id]`, this.state.receiptCatelogueItemsStore[i]?.purchase_order_id)
      }
    }

    if(this.state.receiptNonCatelogueItemsStore && this.state.receiptNonCatelogueItemsStore.length > 0){
      for (let i = 0; i < this.state.receiptNonCatelogueItemsStore.length; i++) {
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][non_cat_id]`, this.state.receiptNonCatelogueItemsStore[i]?.id)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][name]`, this.state.receiptNonCatelogueItemsStore[i]?.name)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][quantity]`, this.state.receiptNonCatelogueItemsStore[i]?.quantity)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][quantity_received]`, this.state.receiptNonCatelogueItemsStore[i]?.quantity_received)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][is_non_catalogue_item]`, this.state.receiptNonCatelogueItemsStore[i]?.is_non_catalague_item)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][comments]`, this.state.receiptNonCatelogueItemsStore[i]?.comment_received)
        formdata.append(`receipt_non_catalogue_products_attributes[${i}][purchase_order_id]`, this.state.receiptNonCatelogueItemsStore[i]?.purchase_order_id)
      }
    }

    
    for (let pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]);
    }

    const createReceiptApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createReceiptAPICallID = createReceiptApiMsg.messageId;
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'receipt'
    );
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
  
    createReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
  
    runEngine.sendMessage(createReceiptApiMsg.id, createReceiptApiMsg);
  }

  isEditReceiptValidCheck = () => {
    let flag = true;
    let fileValidate = this.state.isReceiptFileEdited ? this.state.editedReceiptAttachmentFile?.name?.split('.').pop().trim() : this.state.editedReceiptAttachmentFile?.split('.').pop().trim()
    if(this.state.editedReceiptAttachment?.toLowerCase() != fileValidate?.toString().toLowerCase() && this.state.editedReceiptAttachment !== ""){
      this.setState({
        editedReceiptAttachmentTypeMatchError: "Check the file attachment type"
      })
      flag=false;
    }
    if(this.state.editedReceiptQuantityReceived === ""){
      this.setState({
        editedReceiptQuantityReceivedRequiredField: "Enter the Quantity Received *",
      })
      flag=false;
    }
    if(this.state.editedReceiptQuantityRequested === ""){
      this.setState({
        editedReceiptQuantityRequestedRequiredField: "Enter the Quantity Requested *",
      })
      flag=false;
    }
    if(this.state.editedReceiptReceiptDate === ""){
      this.setState({
        editedReceiptReceiptDateRequiredField: "Enter the Receipt Date *",
      })
      flag=false;
    }
    if(this.state.editedReceiptComments === ""){
      this.setState({
        editedReceiptCommentsRequiredField: "Enter the Comments *",
      })
      flag=false;
    }
    return flag;
  }

  handleShowReceipt = (receiptId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle show Receipts")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showReceiptApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showReceiptAPICallId = showReceiptApiMsg.messageId;
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_receipt/${receiptId}`
    );
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showReceiptApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showReceiptApiMsg.id, showReceiptApiMsg);
  }

  handleGetCurrencyList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Currency List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getCurrencyListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getCurrencyListAPICallId = getCurrencyListApiMsg.messageId;
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_dashboard/request_for_proposals/currency_codes`
    );
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getCurrencyListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getCurrencyListApiMsg.id, getCurrencyListApiMsg);
  }

  handleDownloadRFP = async () => {
    const token_localStorage: any = await localStorage.getItem('authToken')
    console.log("Download RFP Pdf")
    this.setState({
      rfpFinalPdfDownloadOrNotMessage: ``
    })

    fetch(`${baseURL}/generate_request_for_proposals?request_for_proposals=${this.state.catchRfpIdForPdfCreate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `RFP_Details-Created-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click()

        this.setState({
          rfpFinalPdfDownloadOrNotMessage: `RFP file is downloaded`
        })

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        this.setState({
          rfpFinalPdfDownloadOrNotMessage: error?.message
        })
      });
  }

  handleEditedDownloadRFP = async () => {
    const token_localStorage: any = await localStorage.getItem('authToken')
    console.log("Edited Download RFP Pdf")

    this.setState({
      editedRfpFinalPdfDownloadOrNotMessage:'',
      isEditedRFPFileDownloaded: true,
    })

    fetch(`${baseURL}/generate_request_for_proposals?request_for_proposals=${this.state.catchEditedRfpIdForPdfUpdation}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'token': token_localStorage,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );

        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `RFP_Details-Edited-Date-${new Date().getFullYear().toString().padStart(4, '0') + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')}.pdf`,
        );

        document.body.appendChild(link);

        link.click()


        this.setState({
          editedRfpFinalPdfDownloadOrNotMessage: `Edited RFP file is downloaded`
        })
        this.setState({
          isEditedRFPFileDownloaded: false,
        })

        link.parentNode.removeChild(link);

      })
      .catch(error => {
        this.setState({
          editedRfpFinalPdfDownloadOrNotMessage: error?.message
        })
      });
  }

  supplierZipCode = () =>{
    let flag = true
    if(this.state.supplierZipCodePinCode === ""){
      this.setState({
        supplierZipCodePinCodeRequiredField: "Enter the Zip Code/ Pin Code *",
      })
      flag=false;
    }
    return flag
  }

  supplierStateProvince = () =>{
    let flag1 = true
    if(this.state.supplierStateProvince === ""){
      this.setState({
        supplierStateProvinceRequiredField: "Enter the State / Province *",
      })
      flag1=false;
    }
    return flag1
  }


  isCreateSupplierValidCheck = () => {
    let flag = true;
    if(this.state.supplierAddressOfSupplierCompany === ""){
      this.setState({
        supplierAddressOfSupplierCompanyRequiredField: "Enter the Address of Supplier Company *",
      })
      flag=false;
    }
    if(this.state.supplierContactNumber === ""){
      this.setState({
        supplierContactNumberRequiredField: "Enter the Contact Number *",
      })
      flag=false;
    }
    if(!this.state.supplierContactNumber.match(/^\d+$/)){
      this.setState({
        supplierContactNumberMatchError: "Enter the valid Contact Number",
      })
      flag=false;
    }
    if(this.state.supplierEmailAddress === ""){
      this.setState({
        supplierEmailAddressRequiredField: "Enter the Email *",
      })
      flag=false;
    }
    if (!RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/).exec(this.state.supplierEmailAddress)) {
      this.setState({
        supplierEmailAddressEmailMatchError: "Enter valid Email",
      });
      flag = false;
    }
    if(this.state.supplierGSTNumber === ""){
      this.setState({
        supplierGstNumberRequiredField: "Enter the GST Number *",
      })
      flag=false;
    }
    if(this.state.supplierSupplierName === ""){
      this.setState({
        supplierNameRequiredField: "Enter the Supplier Name *",
      })
      flag=false;
    }
    if(this.state.supplierPointOfContact === ""){
      this.setState({
        supplierPointOfContactRequiredField: "Enter the Point of Contact *",
      })
      flag=false;
    }
    if(!this.state.supplierPointOfContact.match(/^\d+$/)){
      this.setState({
        supplierPointContactNumberValidationError: "Enter the valid Contact Number",
      })
      flag=false;
    }
    if(this.state.paymentTermsNameSuppliers.length === 0){
      this.setState({
        supplierTermsOfPaymentRequiredField: "Select the Terms of Payment *",
      })
      flag=false;
    }

    this.supplierStateProvince();

    this.supplierZipCode()

    if((this.state.supplierContactNumber !=='' && this.state.supplierContactNumber.length > 10) || (this.state.supplierContactNumber !=='' && this.state.supplierContactNumber.length < 10)){
      this.setState({
        supplierContactNumberValidationError: "Contact Number is wrong *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateSupplier = () => {
    if(!this.isCreateSupplierValidCheck()){
      console.log("Create SupplierValidation")
      this.setState({
        showIsCreateSupplierValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("supplier[supplier_name]", this.state.supplierSupplierName);
    formdata.append("supplier[email_address]", this.state.supplierEmailAddress);
    formdata.append("supplier[contact_number]", this.state.supplierContactNumber);
    formdata.append("supplier[point_of_contact]", this.state.supplierPointOfContact);
    formdata.append("supplier[address_of_supplier_company]", this.state.supplierAddressOfSupplierCompany);
    formdata.append("supplier[gst_number]", this.state.supplierGSTNumber);
    formdata.append("supplier[state]", this.state.supplierStateProvince);
    formdata.append("supplier[zip_code]", this.state.supplierZipCodePinCode);


    for (const paymentTerm of this.state.paymentTermsNameSuppliers) {
      formdata.append(`supplier[payment_term_ids][]`, paymentTerm);
    }

    for (let pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }

    const createSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createSupplierAPICallId = createSupplierApiMsg.messageId;
  
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "add_supplier"
    );
  
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    createSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createSupplierApiMsg.id, createSupplierApiMsg); 
  }

  isEditSupplierValidCheck = () => {
    let flag = true;
    if(this.state.editedSupplierAddressOfSupplierCompany === ""){
      this.setState({
        editedSupplierAddressOfSupplierCompanyRequiredField: "Enter the Address of Supplier Company *",
      })
      flag=false;
    }
    if(this.state.editedSupplierContactNumber === ""){
      this.setState({
        editedSupplierContactNumberRequiredField: "Enter the Contact Number *",
      })
      flag=false;
    }
    if(!this.state.editedSupplierContactNumber.match(/^\d+$/)){
      console.log("SupplierContactNumberMatchError")
      this.setState({
        editedSupplierContactNumberMatchError: "Enter the valid contact number",
      })
      flag=false;
    }
    if(this.state.editedSupplierEmailAddress === ""){
      this.setState({
        editedSupplierEmailAddressRequiredField: "Enter the email *",
      })
      flag=false;
    }

    if(!this.state.editedSupplierEmailAddress.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        editedSupplierEmailAddressEmailMatchError: "Enter valid Email",
      })
      flag=false;
    }
    if(this.state.editedSupplierGSTNumber === ""){
      this.setState({
        editedSupplierGstNumberRequiredField: "Enter the GST Number *",
      })
      flag=false;
    }
    if(this.state.editedSupplierStateProvince === ""){
      this.setState({
        editedSupplierStateProvinceRequiredField: "Enter the State *",
      })
      flag=false;
    }
    if(this.state.editedSupplierZipCodePinCode === ""){
      this.setState({
        editedSupplierZipCodePinCodeRequiredField: "Enter the Zip Code *",
      })
      flag=false;
    }
    if(this.state.editedSupplierSupplierName === ""){
      this.setState({
        editedSupplierNameRequiredField: "Enter the Supplier Name *",
      })
      flag=false;
    }
    if(this.state.editedSupplierPointOfContact === ""){
      this.setState({
        editedSupplierPointOfContactRequiredField: "Enter the Point of Contact *",
      })
      flag=false;
    }
    if(this.state.editedSupplierPaymentList.length === 0){
      this.setState({
        editedSupplierTermsOfPaymentRequiredField: "Select the Terms of Payment *",
      })
      flag=false;
    }
    return flag;
  }

  handleEditSupplierapi = () => {
    if(!this.isEditSupplierValidCheck()){
      console.log("Supplier validation failed");
      this.setState({
        showIsEditSupplierValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'token': token_localStorage,
    }

    let formdata = new FormData();
    formdata.append("supplier[gst_number]", this.state.editedSupplierGSTNumber);
    formdata.append("supplier[email_address]", this.state.editedSupplierEmailAddress);
    formdata.append("supplier[contact_number]", this.state.editedSupplierContactNumber);
    formdata.append("supplier[address_of_supplier_company]", this.state.editedSupplierAddressOfSupplierCompany);
    formdata.append("supplier[supplier_name]", this.state.editedSupplierSupplierName);
    formdata.append("supplier[terms_of_payment]", this.state.editedSupplierTermsOfPayment);
    formdata.append("supplier[point_of_contact]", this.state.editedSupplierPointOfContact);
    formdata.append("supplier[state]", this.state.editedSupplierStateProvince);
    formdata.append("supplier[zip_code]", this.state.editedSupplierZipCodePinCode);

    for (const paymentTerm of this.state.paymentTermsNameSuppliers) {
      formdata.append(`supplier[payment_term_ids][]`, paymentTerm);
    }

    for (let pair of formdata.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }

    const editSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSupplierAPICallID = editSupplierApiMsg.messageId;
  
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `update_supplier/${this.state.editedSupplierId}`
    );
  
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    editSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editSupplierApiMsg.id, editSupplierApiMsg); 
  }

  handleShowSupplier = (supplierId: string) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Show Supplier")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSupplierApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSupplierAPICallId = showSupplierApiMsg.messageId;
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `show_supplier/${supplierId}`
    );
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showSupplierApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSupplierApiMsg.id, showSupplierApiMsg);
  }

  isCreateProductValidCheck = () => {
    let flag = true;
    if(this.state.showSettingsCategory?.length === 0){
      this.setState({
        productCategoryRequiredField: "Select the Product Category *",
      })
      flag=false;
    }
    if(this.state.productName === ""){
      this.setState({
        productNameRequiredField: "Enter the Product Name *",
      })
      flag=false;
    }
    if(this.state.productTypeSelectedId === ""){
      this.setState({
        productTypeRequiredField: "Enter the Product Type *",
      })
      flag=false;
    }
    if(this.state.productUnitMeasurement === ""){
      this.setState({
        productUnitMeasurementRequiredField: "Enter the Unit Measurement *",
      })
      flag=false;
    }
    if(this.state.productUnitPrice === ""){
      this.setState({
        productUnitPriceRequiredField: "Enter the Unit Price *",
      })
      flag=false;
    }
    if(this.state.productUnitPrice && !this.state.productUnitPrice.match(/^\d+$/)){
      this.setState({
        productUnitPriceMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.addItemSupplierMultiple.length === 0){
      this.setState({
        productSupplierRequiredField: "Select the Supplier *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateProduct = () => {
    if(!this.isCreateProductValidCheck()){
      console.log("Create Product")
      this.setState({
        showIsCreateProductValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    this.getAllSuppliersList()

    const httpBody = {
      data: {
        attributes: {
          item_id: this.state.addItemSupplierNoUnique,
          name: this.state.productName,
          unit_measurement: this.state.productUnitMeasurement,
          unit_price: this.state.productUnitPrice,
          item_type: this.state.productTypeSelectedId,
          product_category_id: this.state.productCategory,
          supplier_ids:this.state.addItemSupplierMultiple
        }
      }
    };

    console.log('Http Body Product Creation', httpBody)

    const createProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createProductAPICallId = createProductApiMsg.messageId;
  
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/products"
    );
  
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createProductApiMsg.id, createProductApiMsg); 
  }

  isEditProductValidCheck = () => {
    let flag = true;
    if(this.state.editedProductCategory === ""){
      this.setState({
        editedProductCategoryRequiredField: "Select the Product Category *",
      })
      flag=false;
    }
    if(this.state.editedProductDescription === ""){
      this.setState({
        editedProductDescriptionRequiredField: "Enter the Description *",
      })
      flag=false;
    }
    if(this.state.editedProductName === ""){
      this.setState({
        editedProductNameRequiredField: "Enter the Product Name *",
      })
      flag=false;
    }
    if(this.state.editedProductPartId === ""){
      this.setState({
        editedProductPartIdRequiredField: "Enter Part ID *",
      })
      flag=false;
    }
    if(this.state.editedProductTypeSelectedId === ""){
      this.setState({
        editedProductTypeRequiredField: "Enter the Product Type *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitMeasurement === ""){
      this.setState({
        editedProductUnitMeasurementRequiredField: "Enter the Unit Measurement *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitPrice === ""){
      this.setState({
        editedProductUnitPriceRequiredField: "Enter the Unit Price *",
      })
      flag=false;
    }
    if(this.state.editedProductUnitPrice && !this.state.editedProductUnitPrice.match(/^\d+$/)){
      this.setState({
        editedProductUnitPriceMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.editedItemSupplierMultiple.length === 0){
      this.setState({
        editedSupplierMultipleRequiredField: "Select Supplier"
      })
      flag=false;
    }
    return flag;
  }

  handleEditProduct = () => {
    if(!this.isEditProductValidCheck()){
      console.log("Edit Product")
      this.setState({
        showIsEditProductValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Edit Product")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    console.log('mmmm ', this.state.editedProductType)
    const httpBody = {
      data: {
        attributes: {
          type: "product_creation",
          name: this.state.editedProductName,
          unit_measurement: this.state.editedProductUnitMeasurement,
          unit_price: this.state.editedProductUnitPrice,
          product_category_id: this.state.editedProductCategory,
          item_type: this.state.editedProductTypeSelectedId,
        }
      }
    };

    const editProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log('editedProductId ', this.state.editedProductId)
  
    this.editProductAPICallId = editProductApiMsg.messageId;
  
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/products/${this.state.editedProductId}`
    );
  
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editProductApiMsg.id, editProductApiMsg);
  }

  handleShowProduct = (productId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Show Product")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showProductApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showProductAPICallId = showProductApiMsg.messageId;
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/products/${productId}`
    );
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showProductApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showProductApiMsg.id, showProductApiMsg);
  } 

  handleGetAllBudgets = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get All Budgets")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showBudgetAPICallId = getBudgetApiMsg.messageId;
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/show_budgets/${this.state.editedDepartment}`
    );
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getBudgetApiMsg.id, getBudgetApiMsg);
  }

  isCreateBudgetValidCheck = () => {
    let flag = true;
    if(this.state.budgetAmount === ""){
      this.setState({
        budgetAmountRequiredField: "Enter the Amount *",
      })
      flag=false;
    }
    if(this.state.startingFromDate === ""){
      this.setState({
        startingFromDateRequiredField: "Enter the StaringFrom Date *",
      })
      flag=false;
    }
    if(this.state.budgetAmount === ""){
      this.setState({
        budgetAmountMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.budgetComment === ""){
      this.setState({
        budgetCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.budgetName === ""){
      this.setState({
        budgetNameRequiredField: "Enter for Budget Name *",
      })
      flag=false;
    }
    if(this.state.budgetPeriod === ""){
      this.setState({
        budgetPeriodRequiredField: "Enter the Budget Period *",
      })
      flag=false;
    }
    if(this.state.budgetProductCategories === ""){
      this.setState({
        budgetProductCategoriesRequiredField: "Enter the Product Category *",
      })
      flag=false;
    }
    if(this.state.budgetFor === ""){
      this.setState({
        budgetForRequiredField: "Select Budget For *",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateBudget = () => {
    if(!this.isCreateBudgetValidCheck()){
      console.error("Create Budget validation failed");
      this.setState({
        showIsCreateBudgetValidation: true
      })
      return
    }

    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          "type": "budget_creation", // pass "budget_creation" or "draft_creation"
          starting_from: this.state.startingFromDate,
          name: this.state.budgetName,
          amount: this.state.budgetAmount,
          period: this.state.budgetPeriod,       // pass "Annually", "Monthly" or "Quarterly"
          // budget_for: this.state.budgetFor,   // pass "Location" or "Department"
          comment: this.state.budgetComment,
          product_category_id: this.state.budgetProductCategories,
          budget_for_id: this.state.budgetFor,
        }
      }
    };

    const createBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createBudgetAPICallId = createBudgetApiMsg.messageId;
  
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets`
    );
  
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createBudgetApiMsg.id, createBudgetApiMsg);
  }

  isEditBudgetValidCheck = () => {
    let flag = true;
    if(this.state.editedBudgetAmount === ""){
      this.setState({
        editedBudgetAmountRequiredField: "Enter the Amount *",
      })
      flag=false;
    }
    if(this.state.editedBudgetAmount && !this.state.editedBudgetAmount.match(/^\d+$/)){
      this.setState({
        editedBudgetAmountMatchError: "Enter a valid input",
      })
      flag=false;
    }
    if(this.state.editedBudgetComment === ""){
      this.setState({
        editedBudgetCommentRequiredField: "Enter the Comment *",
      })
      flag=false;
    }
    if(this.state.editedBudgetForSelectedId === ""){
      this.setState({
        editedBudgetForRequiredField: "Enter Budget For *",
      })
      flag=false;
    }
    if(this.state.editedBudgetName === ""){
      this.setState({
        editedBudgetNameRequiredField: "Enter for Budget Name *",
      })
      flag=false;
    }
    if(this.state.editedBudgetPeriod === ""){
      this.setState({
        editedBudgetPeriodRequiredField: "Enter the Budget Period *",
      })
      flag=false;
    }
    if(this.state.editedBudgetProductCategories === ""){
      this.setState({
        editedBudgetProductCategoriesRequiredField: "Enter the Product Category *",
      })
      flag=false;
    }
    return flag;
  }

  handleEditBudget = () => {
    if(!this.isEditBudgetValidCheck()){
      console.error("Budget Validation failed");
      this.setState({
        showIsEditBudgetValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          type: "budget_creation",
          starting_from: this.state.editedBudgetStartingDate,
          name: this.state.editedBudgetName,
          product_category_id: this.state.editedBudgetProductCategories,
          period: this.state.editedBudgetPeriod, 
          amount: this.state.editedBudgetAmount,
          comment: this.state.editedBudgetComment,
        }
      }
    };

    console.log('Http Body Edit Budget', httpBody)

    const editBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editBudgetAPICallId = editBudgetApiMsg.messageId;
  
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets/${this.state.editedBudgetId}`
    );
  
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editBudgetApiMsg.id, editBudgetApiMsg);
  }

  handleUpdateSettingsLocation = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Edit Budget")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      location_setting: {
        name_of_location: this.state.editSettingsLocation,
        location_identification_code: this.state.editSettingsIdentificationCode,
        adderss: this.state.editSettingsAddress,
        gst_number: this.state.editSettingsGst
    }
    };

    const editsettingsLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsLocationAPICallId = editsettingsLocationApiMsg.messageId;
  
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/${this.state.editSettingsId}`
    );
  
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsLocationApiMsg.id, editsettingsLocationApiMsg);
  }

  handleUpdateSettingsCategoryData = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Edit Budget")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      categories_setting:{
        name: this.state.editSettingsCategoryName
    }
    };

    const editsettingsCategoryApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsCategoryAPICallId = editsettingsCategoryApiMsg.messageId;
  
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings/${this.state.editSettingsCategoryId}`
    );
  
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsCategoryApiMsg.id, editsettingsCategoryApiMsg);
  }

  handleUpdateSettingsUnitMeasurementData = () => {

    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Edit Budget")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      unit_of_measure_settings:{
        unit_of_measurment: this.state.editSettingsUnitMeasurement
    }
    };

    const editsettingsUnitMeasurementApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editSettingsUnitMeasurementAPICallId = editsettingsUnitMeasurementApiMsg.messageId;
  
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings/${this.state.editSettingsUnitMeasurementId}`
    );
  
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    editsettingsUnitMeasurementApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(editsettingsUnitMeasurementApiMsg.id, editsettingsUnitMeasurementApiMsg);
  }

  handleShowBudget = (budgetId: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Show Budget")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showBudgetApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showBudgetAPICallId = showBudgetApiMsg.messageId;
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets/${budgetId}`
    );
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showBudgetApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showBudgetApiMsg.id, showBudgetApiMsg);
  } 

  handleShowSettingsLocation = (data: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Show Budget")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const showSettingsLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.showSettingsLocationAPICallId = showSettingsLocationApiMsg.messageId;
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/${data}`
    );
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    showSettingsLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(showSettingsLocationApiMsg.id, showSettingsLocationApiMsg);
  } 

  isCreateInformationValidCheck = () => {
    let flag = true;
    if(this.state.informationSettingsCompanyName === ""){
      this.setState({
        informationSettingsComapanyNameRequiredField: "Enter the Company Name *",
      })
      flag=false;
    }
    if(this.state.informationSettingsAddress === ""){
      this.setState({
        informationSettingsAddressRequiredField: "Enter the Address *",
      })
      flag=false;
    }
    if(this.state.informationSettingsPhoneNumber === ""){
      this.setState({
        informationSettingsPhoneNumberRequiredField: "Enter the Phone Number *",
      })
      flag=false;
    }
    if(this.state.informationSettingsPinCode === ""){
      this.setState({
        informationSettingsPinCodeRequiredField: "Enter the PIN code *",
      })
      flag=false;
    }
    if(this.state.informationSettingsEmail === ""){
      this.setState({
        informationSettingsEmailRequiredField: "Enter the Email *",
      })
      flag=false;
    }
    if(this.state.informationSettingsEmail && !this.state.informationSettingsEmail.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        informationSettingsEmailMatchError: "Enter a valid email",
      })
      flag=false;
    }
    if(this.state.informationSettingsPhoneNumber && !this.state.informationSettingsPhoneNumber.match(/^\d+$/)){
      this.setState({
        informationSettingsPhoneNumberMatchError: "Enter a valid phone number"
      })
      flag=false;
    }
    if(this.state.informationSettingsWebsite && !this.state.informationSettingsWebsite.match(/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/)){
      this.setState({
        informationSettingsWebsiteMatchError: "Enter a valid website"
      })
      flag=false;
    }
    if(this.state.informationSettingsPinCode && !this.state.informationSettingsPinCode.match(/^\d+$/)){
      this.setState({
        informationSettingsPinCodeMatchError: "Enter a valid Pin Code"
      })
      flag=false;
    }

    if((this.state.informationSettingsPhoneNumber !=='' && this.state.informationSettingsPhoneNumber.length > 10) || (this.state.informationSettingsPhoneNumber !=='' && this.state.informationSettingsPhoneNumber.length < 10)){
      this.setState({
        informationSettingsPhoneNumberDigitLimitError: "Contact Number is wrong *",
      })
      flag=false;
    }
    if(this.state.informationSettingsGstNumber === ""){
      this.setState({
        informationSettingsGSTNumberRequiredField: "Enter GST Number *",
      })
      flag=false;
    }

    return flag;
  }

  handleCreateInformationSettings = () => {
    if(!this.isCreateInformationValidCheck()){
      this.setState({
        showIsCreateInformationValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          company_name: this.state.informationSettingsCompanyName,
          phone_number: this.state.informationSettingsPhoneNumber,
          address: this.state.informationSettingsAddress,
          email: this.state.informationSettingsEmail,
          pin_code: this.state.informationSettingsPinCode,
          website: this.state.informationSettingsWebsite,
          gst_number: this.state.informationSettingsGstNumber,
        }
      }
    };

    console.log('Info http body:', httpBody);

    const createInformationSettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createInformationSettingsAPICallId = createInformationSettingsApiMsg.messageId;
  
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/information_settings/update_information`
    );
  
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(createInformationSettingsApiMsg.id, createInformationSettingsApiMsg);
  }

  isLocationSettingsValidCheck = () => {
    let flag=true;

    if(this.state.location === ""){
      this.setState({
        locationRequiredError: "Enter location *",
      })
      flag=false;
    }
    const pattern = /^[A-Za-z]+$/;
    if(!pattern.test(this.state.location)){
      this.setState({
        locationRequiredError: "Location only allows alphabets *",
      })
      flag=false;
    }
    if(this.state.settingsAddress === ""){
      this.setState({
        locationAddressRequiredError: "Enter Location Address *",
      })
      flag=false;
    }
    if(this.state.settingsIdentificationCode === ""){
      this.setState({
        locationIdentityCodeRequiredError: "Enter Location IdentificationCode *",
      })
      flag=false;
    }
    if(this.state.settingsGst === ""){
      this.setState({
        locationGstRequiredError: "Enter Location SettingsGst *",
      })
      flag=false;
    }
    return flag;
  }

  handleLocationSettings = () => {
    if(!this.isLocationSettingsValidCheck()){
      console.log("CREATE LOCATION SETTING")
      this.setState({
        saveLocationsValidation: true,
      })
      return
    }
    
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Create Information Settings")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      location_setting: {
        name_of_location: this.state.location,
        location_identification_code: this.state.settingsIdentificationCode,
        adderss: this.state.settingsAddress,
        gst_number: this.state.settingsGst
      }
    };

    console.log('Info http body:', httpBody);

    const createLocationApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createLocationSettingsAPICall = createLocationApiCall.messageId;
  
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings`
    );
  
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createLocationApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createLocationApiCall.id, createLocationApiCall);
  }

  isPostCategorySettingsValidCheck = () => {
    let flag = true
    if(this.state.settingsCategory === ""){
      this.setState({
        settingsCategoryRequiredField: "Select the PaymentTerms *",
      })
      flag=false;
    }
    return flag
  }

  handlePostCategorySettings = () => {
    if(!this.isPostCategorySettingsValidCheck()){
      this.setState({categorySettingCreatePost: true});
      return true
    }
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Create Information Settings")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    // this.setState({
    //   enableEditInformationSettings: true,
    // })

    const httpBody = {
      categories_setting:
      {
           name: this.state.settingsCategory,
           serial_number: Math.floor((Math.random()*1000000000)+1)
      }
    };

    console.log('Info http body:', httpBody);

    const createCategoryApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createCategorySettingsAPICall = createCategoryApiCall.messageId;
  
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings`
    );
  
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createCategoryApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createCategoryApiCall.id, createCategoryApiCall);
  }

  isPostUnitMeasurementSettingsValidCheck = () => {
    let flag = true
    if(this.state.settingsUnitMeasurement === ""){
      this.setState({
        settingsUnitMeasurementRequiredField: "Enter Unit Measurement *",
      })
      flag=false;
    }
    return flag
  }

  handlePostUnitMeasurementSettings = () => {
    if(!this.isPostUnitMeasurementSettingsValidCheck()){
      this.setState({
        unitMeasurementSettingPost: true
      })
      return true;
    }

    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Create Information Settings")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    // this.setState({
    //   enableEditInformationSettings: true,
    // })

    const httpBody = {
      unit_of_measure_settings:
      {
        unit_of_measurment: this.state.settingsUnitMeasurement,
      }
    };

    console.log('Info http body:', httpBody);

    const createUnitMeasurementApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createUnitMeasurementSettingsAPICall = createUnitMeasurementApiCall.messageId;
  
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/unit_of_measure_settings`
    );
  
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createUnitMeasurementApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(createUnitMeasurementApiCall.id, createUnitMeasurementApiCall);
  }

  

  handleCancelCurrencySettings = () => {
    this.setState({ 
      showCurrencySettings: false,
      showSettings: true,
      currencySettingsCurrency: '',
    })
  }

  isCreateCurrencyValidCheck = () => {
    let flag = true;
    if(this.state.currencySettingsCurrency === ""){
      this.setState({
        currencySettingsCurrencyRequiredField: "Enter the Currency",
      })
      flag=false;
    }
    return flag;
  }

  handleCreateCurrencySettings = () => {
    if(!this.isCreateCurrencyValidCheck()){
      console.log("Create Budget")
      this.setState({
        showIsCreateCurrencyValidation: true
      })
      return
    }
    const token_localStorage = localStorage.getItem('authToken') 

    console.log("Handle Create Currency Settings")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      data: {
        attributes: {
          currency: this.state.currencySettingsCurrency
        }
      }
    };

    const createCurrencySettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createCurrencySettingsAPICallId = createCurrencySettingsApiMsg.messageId;
  
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/currency_lists/update_currency`
    );
  
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    createCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    
    runEngine.sendMessage(createCurrencySettingsApiMsg.id, createCurrencySettingsApiMsg);
  }

  handleGetInformationSettings = () => {
    if(typeof window !== 'undefined'){

      const token_localStorage = localStorage.getItem('authToken') 
      console.log("Get Information Settings")
      let headers = { 
        'Content-Type': 'application/json',
        'token': token_localStorage,
      }
      
      const getInformationSettingsApiMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        
    this.getInformationSettingsAPICallId = getInformationSettingsApiMsg.messageId;
    
    getInformationSettingsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/information_settings/show_information`
      );
      
      getInformationSettingsApiMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );
        
        getInformationSettingsApiMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
    );
    
    runEngine.sendMessage(getInformationSettingsApiMsg.id, getInformationSettingsApiMsg);
  }
  }

  handleGetCurrencySettings = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Currency Settings")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    
    const getCurrencySettingsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.getCurrencySettingsAPICallId = getCurrencySettingsApiMsg.messageId;
      
      getCurrencySettingsApiMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/currency_lists/show_currency`
    );
  
    getCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getCurrencySettingsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
  
    runEngine.sendMessage(getCurrencySettingsApiMsg.id, getCurrencySettingsApiMsg);
  }

  isSaveLocationValidCheck = () => {
    let flag = true;
    this.state.locations.forEach((i: any) => {
      if(i.name_of_location === ""){
        i.name_of_location_validation = 'Enter the Name of the Location'
        flag=false;
      }
      if(i.adderss === ""){
        i.address_validation = 'Enter the Address'
        flag=false;
      }
      if(i.location_identification_code === ""){
        i.location_identification_code_validation = 'Enter the Location Identification Code'
        flag=false;
      }
    })
    return flag;
  }

  selectFewerProperties = (show: any) => {
    const {name_of_location, adderss, location_identification_code} = show;
    return {name_of_location, adderss, location_identification_code};
  }

  handleGetLocations = () => {
    const token_localStorage = localStorage.getItem('authToken') 

    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getLocationsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getLocationsAPICallId = getLocationsApiMsg.messageId;
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings/all_locations`
    );
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLocationsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getLocationsApiMsg.id, getLocationsApiMsg);

  }

  handleDeleteLocation = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Delete Location")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const deleteLocationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteLocationAPICallId = deleteLocationApiMsg.messageId;
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `delete_location/${id}`
    );
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteLocationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteLocationApiMsg.id, deleteLocationApiMsg);

  }

  handleGetUsers = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Users")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getUsersApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUsersAPICallId = getUsersApiMsg.messageId;
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/all_users`
    );
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getUsersApiMsg.id, getUsersApiMsg);

  }

  handleDeleteUser = (id: number) => {
    console.log("User ID ", id)
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Delete User")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const deleteUserApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteUserAPICallId = deleteUserApiMsg.messageId;
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/${id}`
    );
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteUserApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteUserApiMsg.id, deleteUserApiMsg);

  }

  

  isSaveUsersValidCheck = () => {
    let flag = true;
    if(this.state.userSettingAddUserName === ""){
      this.setState({
        userSettingNameRequiredError : 'Enter the Name *'
      })
      flag=false;
    }
    if(this.state.userSettingAddUserDesignation === ""){
      this.setState({
        userSettingDesignationRequiredError : 'Enter the Designation *'
      })
      flag=false;
    }
    if(this.state.userSettingAddUserEmailId === ""){
      this.setState({
        userSettingEmailRequiredError : 'Enter the Email *'
      })
      flag=false;
    }

    if(this.state.userSettingAddUserRole === ""){
      this.setState({
        userSettingRoleRequiredError : 'Enter the Role *',
      })
      flag=false;
    }
    
    if(this.state.userSettingAddUserEmailId && !this.state.userSettingAddUserEmailId.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+.[a-zA-Z]*$/)){
      this.setState({
        userSettingEmailInvalidError : 'Email is invalid *'
      })
      flag=false;
    }

    return flag;
  }

  handleSaveUsers = () => {
    this.setState({isUserCreating : true})

    const token_localStorage = localStorage.getItem('authToken') 

    this.setState({loading:true})

    if(!this.isSaveUsersValidCheck()){
      console.error("Save Users Validation failed");
      this.setState({
        saveUsersValidation: true,
      })
      return
    }

    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      name: this.state.userSettingAddUserName,
      designation : this.state.userSettingAddUserDesignation,
      role : this.state.userSettingAddUserRole,
      email : this.state.userSettingAddUserEmailId
    };

    console.log('Http Body Save Locations', httpBody)

    const saveUsersApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.saveUsersAPICallId = saveUsersApiMsg.messageId;
  
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/add_user_to_organization`
    );
  
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    saveUsersApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(saveUsersApiMsg.id, saveUsersApiMsg);
  }

  getProductCategories = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Product Categories")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getProductCategoriesApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getProductCategoriesAPICallId = getProductCategoriesApiMsg.messageId;
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/product_categories`
    );
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getProductCategoriesApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getProductCategoriesApiMsg.id, getProductCategoriesApiMsg);
  }
  handleDeleteCategory = (id: number) => {
    console.log("User ID ", id)
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Delete Category")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deleteCategoryApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deleteCategoryAPICallId = deleteCategoryApiMsg.messageId;
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/category_settings/${id}`
    );
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deleteCategoryApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deleteCategoryApiMsg.id, deleteCategoryApiMsg);

  }
  handleDeleteCategorySuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for deleteCategory API', apiResponse?.errors)
      this.setState({
        categoryDeleteMessage: 'Category is not deleted',
      })
    } else {
      console.log('Response for deleteCategory API', apiResponse);
      this.setState({
        categoryDeleteMessage: 'Category is deleted',
      })
      this.handleGetDataCategorySettings()
    }
  }

  handleDeleteCategoryAPICallIdResponse = (message:Message) => {
    if (this.deleteCategoryAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeleteCategorySuccessAndErrorsNestedFunc(apiResponse) 
    }
  }

  getDepartmentList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getDepartmentListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getDepartmentListAPICallId = getDepartmentListApiMsg.messageId;
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/departments`
    );
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getDepartmentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getDepartmentListApiMsg.id, getDepartmentListApiMsg);
  }

  getBudgetList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetListAPICallId = getBudgetListApiMsg.messageId;
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budgets?column=${this.state.filterMultiItemsState}&query=${this.state.itemsSearchBySpecificValue}`
    );
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBudgetListApiMsg.id, getBudgetListApiMsg);
  }

  handleGetSupplierListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      console.log('Response for getBudgetList API', apiResponse);
      this.setState({
        supplierList: apiResponse?.data,
        //showIsEditRFPValidation: false,
      })
      if(this.state.supplierList?.length === 0){
        this.setState({supplierFoundOrNotMessage: `Supplier Not Found first Create it`})
      }else{
        this.setState({supplierFoundOrNotMessage: ``})
      }
    }
  }

  handleGetSupplierListAPICallIdResponse = (message:Message) => {
    if (this.getSupplierListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetSupplierListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getSupplierList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getSupplierListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSupplierListAPICallId = getSupplierListApiMsg.messageId;
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_suppliers`
    );
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getSupplierListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSupplierListApiMsg.id, getSupplierListApiMsg);
  }

  handleGetPaymentTermsItemsPerPageSetSubNestedFunc = () => {
    if(this.state.paymentTermsList?.length>0 && this.state.paymentTermsList?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesPaymentTerms: this.state.paymentTermsList?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        noOfPagesPaymentTerms: Math.floor(this.state.paymentTermsList?.length/this.state.itemsPerPage)+1
      })
    }
  }
  
  handleGetPaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
    } else {
      console.log('Response for getBudgetList API', apiResponse);
      this.setState({
        paymentTermsList: apiResponse?.data,
      })
      this.handleGetPaymentTermsItemsPerPageSetSubNestedFunc()
    }
  }

  handleGetPaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.getPaymentListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleGetPaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getPaymentTermList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getPaymentListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getPaymentListAPICallId = getPaymentListApiMsg.messageId;
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms`
    );
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getPaymentListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getPaymentListApiMsg.id, getPaymentListApiMsg);
  }

  handleDeletePaymentTermList = (id: number) => {
    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const deletePaymentTermListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.deletePaymentTermListAPICallId = deletePaymentTermListApiMsg.messageId;
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms/${id}`
    );
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    deletePaymentTermListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletePaymentTermListApiMsg.id, deletePaymentTermListApiMsg);

  }
  handleDeletePaymentTermListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for delete PaymentTermList API', apiResponse?.errors)
      this.setState({
        PaymentTermDeleteMessage: 'Payment Term is not deleted',
      
      })
    } else {
      console.log('Response for delete PaymentTermList API', apiResponse);
      this.setState({
        PaymentTermDeleteMessage: 'PaymentTerm is deleted',
      })
      this.getPaymentTermList()
    }
  }

  handleDeletePaymentTermListAPICallIdResponse = (message:Message) => {
    if (this.deletePaymentTermListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleDeletePaymentTermListSuccessAndErrorsNestedFunc(apiResponse) 
    }
  }
  handleCreatePaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for getBudgetList API', apiResponse?.errors)
      this.setState({
        paymentTermsCreateMessage: 'Payment Terms not created',
      })
    } else {
      console.log('Response for getBudgetList API', apiResponse);
      this.setState({
        paymentTermsName:"",
        creditDays:"",
        paymentTermsCreateMessage: 'Payment Terms created',
        currentPagePaymentTerms: 1,
      })
      this.getPaymentTermList()
    }
  }

  handleCreatePaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.paymentTermsPostApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleCreatePaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }
  
  isCreatePaymentTermsValidCheck = () => {
    let flag = true;
    if(this.state.paymentTermsName === ""){
      this.setState({
        paymentTermNameRequiredField: "Enter the PaymentTerms Name",
      })
      flag=false;
    }

    if(this.state.creditDays === "" && !this.state.creditDays.match(/^[0-9 .!?"-]+$/)){
      this.setState({
        paymentTermCreditDaysRequiredField: "Enter the Credit Days",
      })
      flag=false;
    }
    return flag;
  }
  
  handleCreatePaymentTermSettings = () => {
    if(!this.isCreatePaymentTermsValidCheck()){
      this.setState({
        showIsCreatePaymentTermsValidation: true
      })
    }else{
      this.setState({
        showIsCreatePaymentTermsValidation: false,
      })
    }

    const token_localStorage = localStorage.getItem('authToken') 
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      payment_term: {
        payment_term_name: this.state.paymentTermsName,
        credit_days: this.state.creditDays,
        is_activated: null
      }
    };

    console.log('Create PaymentTerms details ', httpBody)

    const paymentTermsPostApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.paymentTermsPostApiId = paymentTermsPostApi.messageId;

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_my_forms/my_forms/payment_terms"
    );

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    paymentTermsPostApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.setState({
      openCreateNewModal : false
    })
  
    runEngine.sendMessage(paymentTermsPostApi.id, paymentTermsPostApi);
  }

  handleEditPaymentTerms = (editedPaymentTerms: any): void => {
    this.setState({ 
      showEditPaymentTermsSettingField: true,
      showEditPaymentTermsSettingBtn: true,
      editPaymentTermsName: editedPaymentTerms?.attributes?.payment_term_name,
      editCreditDays: editedPaymentTerms?.attributes?.credit_days,
      paymentTermId: editedPaymentTerms?.id
    })
  }

  handleEditPaymentTermsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for editPaymentTerms API', apiResponse?.errors)
      this.setState({
        paymentTermsEditMessage: 'Payment Terms not updated',
      })
    } else {
      console.log('Response for editPaymentTerms API', apiResponse);
      this.setState({
        editPaymentTermsName:"",
        editCreditDays:"",
        paymentTermsEditMessage: 'Payment Terms updated',
      })
      this.getPaymentTermList()
    }
  }

  handleEditPaymentTermsListAPICallIdResponse = (message:Message) => {
    if (this.editPaymentTermsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      this.handleEditPaymentTermsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isUpdatePaymentTermsValidCheck = () => {
    let flag = true;
    if(this.state.editPaymentTermsName === ""){
      this.setState({
        paymentTermNameRequiredFieldEdit: "Enter the PaymentTerms Name",
      })
      flag=false;
    }
    if(this.state.editCreditDays === ""){
      this.setState({
        paymentTermCreditDaysRequiredFieldEdit: "Enter the Credit Days",
      })
      flag=false;
    }
    return flag;
  }

  handleEditPaymentTermsSettings = () =>{
    if(!this.isUpdatePaymentTermsValidCheck()){
      this.setState({
        showIsEditPaymentTermsValidation: true
      })
    }else{
      this.setState({
        showIsEditPaymentTermsValidation: true,
        paymentTermNameRequiredField:'',
        paymentTermCreditDaysRequiredField:'',
      })
    }   

    this.setState({
      showEditPaymentTermsSettingBtn: false,
      editCreditDays: '',
      editPaymentTermsName:'',
      currentPagePaymentTerms: 1
    })
    
    const token_localStorage = localStorage.getItem('authToken')
    console.log("Handle Edit PAYMENT TERMS edit")
    let headers = {
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      payment_term: {
        payment_term_name: this.state.editPaymentTermsName,
        credit_days: this.state.editCreditDays
      }
    };

    const editPaymentTermsApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPaymentTermsApiCallId = editPaymentTermsApiMsg.messageId;

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/payment_terms/${this.state.paymentTermId}`
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    editPaymentTermsApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editPaymentTermsApiMsg.id, editPaymentTermsApiMsg);
  }

  handleGetTaxationsListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(this.state.taxations?.length>0 && this.state.taxations?.length%this.state.itemsPerPage==0){
      this.setState({
        noOfPagesTaxations: this.state.taxations?.length/this.state.itemsPerPage
      })
    }
    else{
      this.setState({
        noOfPagesTaxations: Math.floor(this.state.taxations?.length/this.state.itemsPerPage)+1
      })
    }
  }

  handleGetTaxationsListAPICallIdResponse = (message:Message) => {
    if (this.getTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        taxations: apiResponse?.data?.filter((value: any, index: number, arr: any)=> value?.attributes?.tax_percentage !== null),
        currentPageTaxations: 1,
      })      
      this.handleGetTaxationsListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  getTaxationList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getTaxationListAPICallId = getTaxationListApiMsg.messageId;
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations`
    );
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTaxationListApiMsg.id, getTaxationListApiMsg);
  }

  handleCreateTaxationsSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for createTaxation API', apiResponse?.errors)
      this.setState({
        taxationsCreateMessage: 'Taxation not created',
      })
    } else {
      console.log('Response for createTaxation API', apiResponse);
      this.setState({
        taxationsCreateMessage: 'Taxation created',
      })
      this.getTaxationList()
    }
  }

  handleCreateTaxationAPICallIdResponse = (message:Message) => {
    if (this.createTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleCreateTaxationsSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isCreateTaxationValidCheck = () => {
    let flag = true;
    if(this.state.taxName === ""){
      this.setState({
        taxNameRequiredField: "Enter the Tax Name",
      })
      flag=false;
    }
    if(this.state.taxPercentage === ""){
      this.setState({
        taxPercentageRequiredField: "Enter the Tax %",
      })
      flag=false;
    }
    return flag;
  }

  createTaxationSettings = () => {
    if(!this.isCreateTaxationValidCheck()){
      this.setState({
        showIsCreateTaxationValidation: true
      })
    }else{
      this.setState({
        taxName:'',
        taxPercentage:'',
        currentPageTaxations: 1,
      })
    }
    
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const httpBody = {
      taxation: {
        tax_name: this.state.taxName,
        tax_percentage: this.state.taxPercentage,
        is_activated: null,
        text_type: 'percentage'
      }
    };

    const createTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.createTaxationListAPICallId = createTaxationListApiMsg.messageId;
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations`
    );
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    createTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(createTaxationListApiMsg.id, createTaxationListApiMsg);
  }

  handleEditTaxationModal = (editedTaxations: any): void => {
    this.setState({ 
      showEditTaxationEditField: true,
      showEditTaxationSettingBtn: false,
      editTaxName: editedTaxations?.attributes?.tax_name,
      editTaxPercentage: editedTaxations?.attributes?.tax_percentage,
      taxationId: editedTaxations?.id
    })
  }

  handleEditTaxationListSuccessAndErrorsNestedFunc = (apiResponse:any) => {
    if(apiResponse?.errors){
      console.log('error for taxationEdit API', apiResponse?.errors)
      this.setState({
        taxationEditMessage: 'Taxation not Update',
      })
    } else {
      console.log('Response for taxationEdit API', apiResponse);
      this.setState({
        taxationEditMessage: 'Taxation Update',
      })
      this.getTaxationList()
    }
  }

  handleEditTaxationAPICallIdResponse = (message:Message) => {
    if (this.editTaxationListAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleEditTaxationListSuccessAndErrorsNestedFunc(apiResponse)
    }
  }

  isUpdateTaxationValidCheck = () => {
    let flag = true;
    if(this.state.editTaxName === ""){
      this.setState({
        editTaxNameRequiredField: "Enter the Tax Name",
      })
      flag=false;
    }
    if(this.state.editTaxPercentage === ""){
      this.setState({
        editTaxPercentageRequiredField: "Enter the Tax %",
      })
      flag=false;
    }
    return flag;
  }
  
  editTaxationSettings = () => {
    if(!this.isUpdateTaxationValidCheck()){
      this.setState({
        showIsUpdateTaxationValidation: true
      })
    }else{
      this.setState({
        showEditTaxationSettingBtn: true,
        editTaxName: '',
        editTaxPercentage:'',
        currentPageTaxations: 1,
        showEditTaxationEditField:false
      })
    }
    
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Department List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
    const httpBody = {
      taxation: {
        tax_name: this.state.editTaxName,
        tax_percentage: this.state.editTaxPercentage,
      }
    };
  
    const editTaxationListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editTaxationListAPICallId = editTaxationListApiMsg.messageId;
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/taxations/${this.state.taxationId}`
    );
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    editTaxationListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(editTaxationListApiMsg.id, editTaxationListApiMsg);
  }

  getLocationDropdownList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Location Dropdown List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getLocationDropdownListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getLocationDropdownListAPICallId = getLocationDropdownListApiMsg.messageId;
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/location_settings`
    );
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getLocationDropdownListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getLocationDropdownListApiMsg.id, getLocationDropdownListApiMsg);
  }


  handleGetReciptsPurchaseOrdersForSelection = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Handle Get PO")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getAllReceiptsPurchaseOrdersForSelectionApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAllReceiptsPurchaseOrdersForSelectionAPICallId = getAllReceiptsPurchaseOrdersForSelectionApiMsg.messageId;
  
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "purchase_order_status_open"
    );
  
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    
    getAllReceiptsPurchaseOrdersForSelectionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    
    runEngine.sendMessage(getAllReceiptsPurchaseOrdersForSelectionApiMsg.id, getAllReceiptsPurchaseOrdersForSelectionApiMsg);
  }


  isUserCreatPermissionsValidCheck = () => {
    let flag = true;
    if(this.state.editedCatchIdForUserPermissionsUpdation === ""){
      this.setState({
        userPermissionMissingIdErrorMessage : 'User permission Id not Exist. Cant be edit!'
      })
      flag=false;
    }

    return flag
  }

  getEditedUserAccessPermissionUpdation = (permissionsId: string) => {
    if(!this.isUserCreatPermissionsValidCheck()){
      this.setState({
        createUserPermissionsValidation: true,
      })
      return
    }

    this.setState({
      isUserSettingPermissionsUpdating : true,
    })

    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get User Permission Access")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
      user_permission : {
        permission : this.state.editedSelectedUserPermissions,
        account_id : this.state.editedCatchCoreUserCreatedAccountId,
        user_settings_id : this.state.editedCatchCoreUserCreatedAccountId,
      }
    };
  
    const getUserAccessPermissionApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserAccessPermissionAPICallId = getUserAccessPermissionApiMsg.messageId;
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user_permission/user_permissions/${permissionsId}`
    );
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    getUserAccessPermissionApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(getUserAccessPermissionApiMsg.id, getUserAccessPermissionApiMsg);
  }

  handleSaveUserAccessPermissions = () => {
    this.setState({isUserPermissionsCreating:true})
    const token_localStorage = localStorage.getItem('authToken')

    console.log("Handle Save User Access Permissions")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }

    const httpBody = {
        user_permission: {
          permission : this.state.selectedUserPermissions,
          account_id : this.state.catchUserSettingsCreatedUserId,
          user_settings_id : this.state.catchUserSettingsCreatedUserId,
      }
    }
  

    console.log('Http Body Save User Access Permissions', httpBody)

    const saveUserAccessPermissionMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.saveUserAccessPermissionAPICallId = saveUserAccessPermissionMsg.messageId;
  
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_user_permission/user_permissions`
    );
  
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    
    saveUserAccessPermissionMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(saveUserAccessPermissionMsg.id, saveUserAccessPermissionMsg);
  }

  getBudgetForList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Budget For List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getBudgetForListApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBudgetForListAPICallId = getBudgetForListApiMsg.messageId;
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_my_forms/my_forms/budget_fors`
    );
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getBudgetForListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getBudgetForListApiMsg.id, getBudgetForListApiMsg);
  }

  getRolesList = () => {
    const token_localStorage = localStorage.getItem('authToken') 
    console.log("Get Roles List")
    let headers = { 
      'Content-Type': 'application/json',
      'token': token_localStorage,
    }
  
    const getRolesListApiMsg  = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getRolesListAPICallId = getRolesListApiMsg.messageId;
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/user_settings/role_list`
    );
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    getRolesListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getRolesListApiMsg.id, getRolesListApiMsg);
  }

  
  
  // Customizable Area End

}
